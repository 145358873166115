// Libs
import { Description, Field, Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { InputSearch } from '~/components';
// Others
import { DEFAULT_SELECT_HEIGHT, DEFAULT_SELECT_WIDTH } from '~/utils/constants/component';
import { IBaseOption } from '~/utils/interface/common';
import { ASTERISK_SYMBOL } from '~/utils/constants/common';
import { InputTypeStyleEnum } from '~/utils/enum';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './SelectTask.module.scss';

type Props = {
  height?: number | string;
  width?: number | string;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  options: IBaseOption[];
  name?: string;
  value?: string;
  required?: boolean;
  onChange?: (value: IBaseOption, name?: string) => void;
};

const cx = classNames.bind(styles);

const SelectTask = (props: Props) => {
  //#region Destructuring Props
  const {
    width = DEFAULT_SELECT_WIDTH,
    height = DEFAULT_SELECT_HEIGHT,
    label,
    placeholder,
    errorMessage,
    options,
    name,
    value,
    required,
    onChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [selectOption, setSelectOption] = useState<IBaseOption | null>(null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!value) return;

    const optionMatchWithValue = options?.find((item) => item.value === value);
    setSelectOption(optionMatchWithValue || null);
  }, [options, value]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleOptionChange = (value: IBaseOption) => {
    setSelectOption(value);
    onChange && onChange(value, name ?? '');
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    // TODO: Handle Change Logic Later
  };
  //#endregion Handle Function

  return (
    <Field id='baseSelectComponent' className={cx('container')} style={{ width }}>
      {label && (
        <Label className={cx('label')}>
          {label} {required && <span>{ASTERISK_SYMBOL}</span>}
        </Label>
      )}

      <Listbox value={selectOption} onChange={handleOptionChange}>
        <ListboxButton className={cx('btnSelect')} style={{ height }}>
          {({ open }) => (
            <>
              {placeholder && !selectOption ? (
                <span className={cx('btnPlaceholder')}>{placeholder}</span>
              ) : (
                <span className={cx('btnText')}>{selectOption?.label}</span>
              )}
              <img
                src={icons.commonIconArrowBottom}
                className={cx(open ? 'iconActive' : '')}
                alt={t('common_img_text_alt')}
              />
            </>
          )}
        </ListboxButton>

        <ListboxOptions className={cx('optionList')} transition anchor={{ to: 'bottom', gap: '8px' }}>
          <div className={cx('optionItemHeader')}>
            <h3 className={cx('titleOption')}>Select Task</h3>
            <InputSearch
              typeStyle={InputTypeStyleEnum.SELECT}
              height={32}
              placeholder={t('common_placeholder_search')}
              onChange={handleChangeSearch}
            />
          </div>
          <div key={'hi'} className={cx('optionItems')}>
            {options.length > 0 ? (
              <>
                {options.map((option, index) => (
                  <React.Fragment key={option.value}>
                    <ListboxOption
                      key={option.value}
                      value={option}
                      className={({ selected, focus }) =>
                        cx(
                          'optionItem',
                          (selected || selectOption?.value === option.value) && 'optionActive',
                          focus && 'optionHover'
                        )
                      }
                    >
                      {option.label}
                    </ListboxOption>

                    <p className={cx('line')}></p>
                  </React.Fragment>
                ))}
              </>
            ) : (
              <div className={cx('optionNoData')}>{t('common_label_no_data_available')}</div>
            )}
          </div>
        </ListboxOptions>
      </Listbox>

      {errorMessage && <Description className={cx('errMessage')}>{errorMessage}</Description>}
    </Field>
  );
};

export default SelectTask;
