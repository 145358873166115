// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseButton } from '~/components';
// Others
import { ButtonTypeEnum } from '~/utils/enum';
import { TEAL950 } from '~/utils/constants/color';
// Styles, images, icons
import styles from './ConfirmModal.module.scss';
import { icons } from '~/assets';

type Props = {
  title: string;
  titleCancel?: string;
  titleAction?: string;
  onCancel: () => void;
  onAction: () => void;
  description?: string;
};

const cx = classNames.bind(styles);

const ConfirmModal = (props: Props) => {
  //#region Destructuring Props
  const { title, titleCancel, titleAction, onCancel, onAction } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='confirmModalComponent' className={cx('container')}>
      <div className={cx('modal')}>
        <img src={icons.commonIconModalDelete} className={cx('iconDelete')} alt={t('common_img_text_alt')} />

        <div className={cx('modalBody')}>{title && <p className={cx('title')}>{title}</p>}</div>

        <div className={cx('actions')}>
          <BaseButton
            text={titleCancel ? titleCancel : t('common_cancel_label')}
            width={135}
            onClick={onCancel}
            color={TEAL950}
          />

          <BaseButton
            text={titleAction ? titleAction : t('common_button_save')}
            width={135}
            onClick={onAction}
            typeStyle={ButtonTypeEnum.DELETE}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
