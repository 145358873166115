// Libs
import classNames from 'classnames/bind';
// Components, Layouts, Pages
import { CareAlertsShowAllTemplate } from '~/pages';
// Others
import { AccountRoleCodesEnum } from '~/utils/enum';

// Styles, images, icons
import styles from './CareAlertsShowAll.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CareAlertsShowAll = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='careAlertsShowAllPage' className={cx('careAlertsShowAllContainer')}>
      <CareAlertsShowAllTemplate role={AccountRoleCodesEnum.ADMIN} />
    </div>
  );
};

export default CareAlertsShowAll;
