// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
// Components, Layouts, Pages
import { MessageItem, InputSearch, Spinner } from '~/components';
// Others
import { InputTypeStyleEnum } from '~/utils/enum';
import { IPayloadChangeConversation } from '~/utils/interface/message';
import { IConversation } from '~/utils/interface/conversation';
import useDebounce from '~/utils/customHook';
import {
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_ZERO,
  DEFAULT_TIMEOUT_SEARCH,
  EMPTY_STRING,
} from '~/utils/constants/common';
// Styles, images, icons
import styles from './MessageList.module.scss';

type Props = {
  allConversation: IConversation[];
  onChangeConversation?: (payload: IPayloadChangeConversation) => void;
  onLoadMore?: () => void;
  onSearch?: (textSearch: string) => void;
  isLoadingMore?: boolean;
  isSearching?: boolean;
};

const cx = classNames.bind(styles);

const MessageList = (props: Props) => {
  //#region Destructuring Props
  const { allConversation, onChangeConversation, onLoadMore, isLoadingMore, onSearch, isSearching } = props;
  //#endregion Destructuring Props

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const conversationsRef = useRef<HTMLDivElement | null>(null);
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey, DEFAULT_DELAY_TIME);
  //#endregion Declare State

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Implement Hook
  useEffect(() => {
    onSearch && onSearch(debouncedSearchKey);
  }, [debouncedSearchKey]);

  useEffect(() => {
    const conversationsElement = conversationsRef.current;

    if (conversationsElement) {
      conversationsElement.addEventListener('scroll', handleScroll);

      return () => {
        conversationsElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleScroll = () => {
    if (conversationsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = conversationsRef.current;

      if (scrollTop + clientHeight === scrollHeight) {
        onLoadMore && onLoadMore();
      }
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimeout(() => {
      setSearchKey(event.target.value);
    }, DEFAULT_TIMEOUT_SEARCH);
  };

  const handleClick = (id: string) => {
    onChangeConversation && onChangeConversation({ conversationId: id });
  };

  const renderConversations = () => {
    if (isSearching) {
      return (
        <div className={cx('containerCenter')}>
          <Spinner />
        </div>
      );
    }

    if (allConversation?.length > DEFAULT_NUMBER_ZERO) {
      return (
        <>
          {allConversation.map((item) => {
            return <MessageItem key={item.id} data={item} onClick={handleClick} />;
          })}
          <div className={cx('containerSpinner')}>{isLoadingMore && <Spinner />}</div>
        </>
      );
    }

    return <div className={cx('containerCenter')}>{t('common_empty_data')}</div>;
  };
  //#endregion Handle Function

  return (
    <div id='messagesList' className={cx('messagesList')}>
      <div className={cx('messagesListHeader')}>
        <InputSearch
          typeStyle={InputTypeStyleEnum.SEARCH_SERVICE}
          placeholder={t('message_search_service_placeholder')}
          onChange={handleSearchChange}
        />
      </div>

      <div className={cx('messagesItems')} ref={conversationsRef}>
        {renderConversations()}
      </div>
    </div>
  );
};

export default MessageList;
