// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
// Components, Layouts, Pages
import { CheckboxSingle } from '~/components';
// Others
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
// Styles, images, icons
import styles from './QuickLinkDayCenterClient.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const QuickLinkDayCenterClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { control } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='quickLinkDayCenterClientComponent' className={cx('container')}>
      <Controller
        name='quickLink.CARE_NEEDS'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_care_needs')}
            value={value}
            onChange={(checked: boolean) => onChange(checked)}
          />
        )}
      />

      <Controller
        name='quickLink.DEMOGRAPHICS'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_demographics')}
            value={value}
            onChange={(checked: boolean) => onChange(checked)}
          />
        )}
      />

      <Controller
        name='quickLink.ALLERGIES'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_allergies')}
            value={value}
            onChange={(checked: boolean) => onChange(checked)}
          />
        )}
      />

      <Controller
        name='quickLink.ICD10_CODES'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_icd_10_codes')}
            value={value}
            onChange={(checked: boolean) => onChange(checked)}
          />
        )}
      />

      <Controller
        name='quickLink.PRIMARY_DIAGNOSIS'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_primary_diagnosis')}
            value={value}
            onChange={(checked: boolean) => onChange(checked)}
          />
        )}
      />

      <Controller
        name='quickLink.MEDICAL_CONDITIONS'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_medical_conditions')}
            value={value}
            onChange={(checked: boolean) => onChange(checked)}
          />
        )}
      />

      <Controller
        name='quickLink.MENTAL_BEHAVIOR_CONDITIONS'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_mental_behavior')}
            value={value}
            onChange={(checked: boolean) => onChange(checked)}
          />
        )}
      />

      <Controller
        name='quickLink.MEDICATIONS'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_medications')}
            value={value}
            onChange={(checked: boolean) => onChange(checked)}
          />
        )}
      />

      <Controller
        name='quickLink.ELIMINATION'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_elimination')}
            value={value}
            onChange={(checked: boolean) => onChange(checked)}
          />
        )}
      />

      <Controller
        name='quickLink.AMBULATION'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_ambulation')}
            value={value}
            onChange={(checked: boolean) => onChange(checked)}
          />
        )}
      />

      <Controller
        name='quickLink.MEALS'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_meal_diet')}
            value={value}
            onChange={(checked: boolean) => onChange(checked)}
          />
        )}
      />

      <Controller
        name='quickLink.ACTIVITY'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_activity')}
            value={value}
            onChange={(checked: boolean) => onChange(checked)}
          />
        )}
      />

      <Controller
        name='quickLink.TRANSPORT'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_transport')}
            value={value}
            onChange={(checked: boolean) => onChange(checked)}
          />
        )}
      />

      <Controller
        name='quickLink.REVIEW'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_review')}
            value={value}
            onChange={(checked: boolean) => onChange(checked)}
          />
        )}
      />
    </div>
  );
};

export default QuickLinkDayCenterClient;
