// Libs
// Components, Layouts, Pages
// Others
import { GREEN_PEA700 } from '~/utils/constants/color';
import { IconSvgProps } from '~/utils/interface/common';
// Styles, images, icons

const CommonIconPhoneNumber = (props: IconSvgProps) => {
  //#region Destructuring Props
  const { width = 14, height = 14, strokePath = GREEN_PEA700, fill = 'none' } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <svg width={width} height={height} viewBox='0 0 15 14' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.3159 10.6926C13.3159 10.9026 13.2692 11.1184 13.1701 11.3284C13.0709 11.5384 12.9426 11.7367 12.7734 11.9234C12.4876 12.2384 12.1726 12.4659 11.8167 12.6117C11.4667 12.7576 11.0876 12.8334 10.6792 12.8334C10.0842 12.8334 9.44841 12.6934 8.77758 12.4076C8.10675 12.1217 7.43591 11.7367 6.77091 11.2526C6.10008 10.7626 5.46425 10.2201 4.85758 9.61925C4.25675 9.01258 3.71425 8.37675 3.23008 7.71175C2.75175 7.04675 2.36675 6.38175 2.08675 5.72258C1.80675 5.05758 1.66675 4.42175 1.66675 3.81508C1.66675 3.41841 1.73675 3.03925 1.87675 2.68925C2.01675 2.33341 2.23841 2.00675 2.54758 1.71508C2.92091 1.34758 3.32925 1.16675 3.76091 1.16675C3.92425 1.16675 4.08758 1.20175 4.23341 1.27175C4.38508 1.34175 4.51925 1.44675 4.62425 1.59841L5.97758 3.50591C6.08258 3.65175 6.15841 3.78591 6.21091 3.91425C6.26341 4.03675 6.29258 4.15925 6.29258 4.27008C6.29258 4.41008 6.25175 4.55008 6.17008 4.68425C6.09425 4.81841 5.98341 4.95841 5.84341 5.09841L5.40008 5.55925C5.33591 5.62341 5.30675 5.69925 5.30675 5.79258C5.30675 5.83925 5.31258 5.88008 5.32425 5.92675C5.34175 5.97341 5.35925 6.00841 5.37091 6.04341C5.47591 6.23591 5.65675 6.48675 5.91341 6.79008C6.17591 7.09341 6.45591 7.40258 6.75925 7.71175C7.07425 8.02091 7.37758 8.30675 7.68675 8.56925C7.99008 8.82591 8.24091 9.00091 8.43925 9.10591C8.46841 9.11758 8.50341 9.13508 8.54425 9.15258C8.59091 9.17008 8.63758 9.17591 8.69008 9.17591C8.78925 9.17591 8.86508 9.14091 8.92925 9.07675L9.37258 8.63925C9.51841 8.49341 9.65841 8.38258 9.79258 8.31258C9.92675 8.23091 10.0609 8.19008 10.2067 8.19008C10.3176 8.19008 10.4342 8.21341 10.5626 8.26591C10.6909 8.31841 10.8251 8.39425 10.9709 8.49341L12.9017 9.86425C13.0534 9.96925 13.1584 10.0917 13.2226 10.2376C13.2809 10.3834 13.3159 10.5292 13.3159 10.6926Z'
        stroke={strokePath}
        strokeMiterlimit='10'
      />
      <path
        d='M11.2917 5.25016C11.2917 4.90016 11.0175 4.3635 10.6092 3.926C10.2358 3.5235 9.74 3.2085 9.25 3.2085'
        stroke={strokePath}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.3333 5.25008C13.3333 2.99258 11.5075 1.16675 9.25 1.16675'
        stroke={strokePath}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CommonIconPhoneNumber;
