// Libs
import { IconSvgProps } from '~/utils/interface/common';
// Components, Layouts, Pages
// Others
// Styles, images, icons

const CommonIconClose = (props: IconSvgProps) => {
  //#region Destructuring Props
  const { width = 14, height = 14, strokePath, fill = 'none' } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <svg width={width} height={height} viewBox='0 0 12 13' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_174_11830)'>
        <path
          d='M0.585681 12.4998C0.469777 12.4999 0.356471 12.4655 0.260094 12.4011C0.163717 12.3367 0.0885992 12.2452 0.044242 12.1381C-0.000115215 12.0311 -0.0117189 11.9132 0.0108987 11.7996C0.0335162 11.6859 0.089339 11.5815 0.171306 11.4995L10.9994 0.671396C11.1093 0.561497 11.2584 0.499756 11.4138 0.499756C11.5692 0.499756 11.7183 0.561497 11.8282 0.671396C11.9381 0.781295 11.9998 0.93035 11.9998 1.08577C11.9998 1.24119 11.9381 1.39025 11.8282 1.50015L1.00006 12.3283C0.945689 12.3828 0.881096 12.426 0.809984 12.4554C0.738873 12.4848 0.662646 12.4999 0.585681 12.4998Z'
          fill={strokePath}
        />
        <path
          d='M11.4139 12.4998C11.3369 12.4999 11.2607 12.4848 11.1896 12.4554C11.1185 12.426 11.0539 12.3828 10.9995 12.3283L0.171396 1.50015C0.0614965 1.39025 -0.000244141 1.24119 -0.000244141 1.08577C-0.000244141 0.93035 0.0614965 0.781295 0.171396 0.671396C0.281295 0.561497 0.43035 0.499756 0.585771 0.499756C0.741191 0.499756 0.890247 0.561497 1.00015 0.671396L11.8283 11.4995C11.9102 11.5815 11.9661 11.6859 11.9887 11.7996C12.0113 11.9132 11.9997 12.0311 11.9553 12.1381C11.911 12.2452 11.8359 12.3367 11.7395 12.4011C11.6431 12.4655 11.5298 12.4999 11.4139 12.4998Z'
          fill={strokePath}
        />
      </g>
      <defs>
        <clipPath id='clip0_174_11830'>
          <rect width={width} height={height} fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CommonIconClose;
