// Libs
// Components, Layouts, Pages
import { TasksTemplate } from '~/components';
// Others
import { AccountRoleCodesEnum } from '~/utils/enum';
// Styles, images, icons

type Props = {};

const Tasks = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return <TasksTemplate role={AccountRoleCodesEnum.ADMIN} />;
};

export default Tasks;
