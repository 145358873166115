// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
import prospectApi from '~/apis/crm/prospect';
// Others
import { GET_LIST_PROSPECT } from '~/utils/constants/actionType';
import { IListQueryParamsProspect } from '~/utils/interface/crm/prospect';

export const getListProspect = createAsyncThunk(
  GET_LIST_PROSPECT,
  async (payload: IListQueryParamsProspect, { rejectWithValue }) => {
    try {
      const res = await prospectApi.getListProspect(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
