export enum EnvironmentEnum {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  STAG = 'stag',
  PROD = 'prod',
}

export enum RolesEnum {
  ADMIN = 'ADMIN',
  STAFF = 'STAFF',
  CAREGIVER = 'CAREGIVER',
}

export enum StorageEnum {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  USER = 'user',
  STATUS_LOGIN = 'statusLogin',
  ROLE = 'role',
  USER_ID = 'userId',
  EMAIL = 'email',
  REMEMBER_ME = 'rememberMe',
  DEVICE_ID = 'deviceId',
  ACCOUNT_ID = 'accountId',
}

export enum LanguageEnum {
  EN = 'en',
}

export enum ButtonTypeEnum {
  PRIMARY = 'primary',
  ONLY_TEXT = 'onlyText',
  OUT_LINE = 'outline',
  WHITE = 'white',
  DELETE = 'delete',
}

export enum AccountRoleCodesEnum {
  ADMIN = '00001',
  EMPLOYEE = '00002',
  CAREGIVER = '00003',
  APPLICANT = '00004',
  MANAGER = '00005',
}

export enum ImageCircleTypeEnum {
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
}

// Router key translate
export enum MenuNameTransKeyEnum {
  HOME = 'menu_home',
  EMPLOYEES = 'menu_employees',
  HR = 'menu_hr',
  CAREGIVER = 'menu_caregiver',
  CAREGIVERS = 'menu_caregivers',
  CAREGIVER_REFERRAL = 'menu_caregiver_referral',
  CAREGIVER_REQUEST_APPLICANT = 'menu_request_applicant',
  CRM = 'menu_crm',
  TIME_CLOCK = 'menu_time_clock',
  REPORTING = 'menu_reporting',
  ACCOUNTING = 'menu_accounting',
  MESSAGE = 'menu_message',
  SCHEDULE = 'menu_schedule',
  TASK = 'menu_task',
  FORM = 'menu_form',
  DOCUMENT = 'menu_document',
  INVOICE = 'menu_invoice',
  LIVE_VIEW = 'menu_schedule_live',
  DAILY_CLIENTS = 'menu_schedule_live_daily_clients',
  DAILY_CAREGIVERS = 'menu_schedule_live_daily_caregivers',
  WEEKLY_CLIENTS = 'menu_schedule_live_weekly_clients',
  WEEKLY_CAREGIVERS = 'menu_schedule_live_weekly_caregivers',
  OFFICE_CALENDAR = 'menu_schedule_office_calendar',
  OPEN_SHIFT_CALENDAR = 'menu_schedule_open_shifts_calendar',
  CLIENT = 'menu_client',
  PROSPECT = 'menu_prospect',
  REFERRAL_SOURCE = 'menu_referral_source',
}

// Tab key translate
export enum TabNameTransKeyEnum {
  DAILY_CARE = 'tab_daily_care',
  SCHEDULE = 'tab_schedule',
  CARE_PLAN = 'tab_care_plan',
  DOCUMENTS = 'tab_documents',
  SHIFT_SCHEDULE = 'tab_shift_schedule',
  ACTIVITIES = 'tab_activities',
  ACTIVITY = 'tab_activity',
  FILES = 'tab_files',
  SKILL = 'tab_skill',
  PAYROLL = 'tab_payroll',
  CHECK = 'tab_check',
  TRAINING = 'tab_training',
  COMMENTS = 'tab_comments',
  SUMMARY = 'tab_summary',
  NOTES = 'tab_notes',
  EDIT = 'tab_edit',
  COPY = 'tab_copy',
  DELETE = 'tab_delete',
  INTEGRATION = 'tab_integration',
}

export enum TabQueryValueEnum {
  DAILY_CARE = 'daily-care',
  SCHEDULE = 'schedule',
  CLIENT = 'client',
  PROSPECT = 'prospect',
  REFERRAL_SOURCE = 'referral_source',
  ACTIVITIES = 'activities',
  FILES = 'files',
  SKILL = 'skill',
  PAYROLL = 'payroll',
  CHECK = 'check',
  TRAINING = 'training',
}

export enum statusEnum {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  CONVERT = 'CONVERT',
  COMPLETE = 'COMPLETE',
  IN_COMPLETE = 'INCOMPLETE',
}

export enum statusLabelEnum {
  COMPLETE = 'common_complete_label',
  IN_COMPLETE = 'common_in_complete_label',
}

export enum InputTypeEnum {
  TEXT = 'text',
  NUMBER = 'number',
  EMAIL = 'email',
  PASSWORD = 'password',
}

export enum InputTypeStyleEnum {
  SEARCH_SERVICE = 'service',
  SELECT = 'select',
}

export enum TranslationEnum {
  TRANSLATION = 'translation',
}

export enum TimeFormatEnum {
  HOUR_MINUTE_AM_PM = 'h:mm A',
  HH_MM = 'HH:mm',
  YYYY_MM_DD_HH_MM = 'YYYY-MM-DD HH:mm',
  MM_DD_YYYY_HH_AM_PM = 'MM/DD/YYYY h:mm A',
  MMMM_D_YYYY = 'MMMM D, YYYY',
  MMMM_DD_YYYY = 'MMMM DD, YYYY',
  DD_MMM_YYYY = 'DD MMM YYYY',
  YYYY_MM_DD = 'YYYY-MM-DD',
  MMMM_D_YYYY_NORMAL = 'MMMM D YYYY',
  HOUR_MINUTE_AM_PM_NORMAL = 'h:mm a',
  D_MMM_YYYY = 'D MMM, YYYY',
  MM_DD_YY = 'MM/DD/YY',
  MMMM_D_HH_MM_A = 'MMMM D, hh:mm A',
  MM_DD_YYYY = 'MM/DD/YYYY',
}

export enum DateFormatEnum {
  D_MMM_YYYY = 'D MMM, YYYY',
  YYYY_MM_DD = 'YYYY-MM-DD',
  DDD = 'ddd',
  DD_MM_YYYY = 'DD MM YYYY',
}

export enum StatusTypeEnum {
  ONLINE = 'common_status_online_label',
  OFFLINE = 'common_status_offline_label',
}

export enum ScheduleNavigateActionEnum {
  PREV = 'PREV',
  NEXT = 'NEXT',
  TODAY = 'TODAY',
  DATE = 'DATE',
}

export enum StatusEnum {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  CONVERT = 'CONVERT',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  OUTSTANDING = 'OUTSTANDING',
  IN_COMPLETE = 'INCOMPLETE',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  NEW_LEAD = 'New Lead',
  INITIAL_PHONE_CALL = 'Initial Phone Call',
  ASSESSMENT_SCHEDULED = 'ASSESSMENT_SCHEDULED',
  ASSESSMENT_PERFORMED = 'ASSESSMENT_PERFORMED',
  BREAK = 'BREAK',
  TODO = 'TODO',
  DONE = 'DONE',
  EXPIRED = 'EXPIRED',
  MISSED_CHECK_IN = 'MISSED_CHECK_IN',
  MISSED_CHECK_OUT = 'MISSED_CHECK_OUT',
  OPEN_SHIFT = 'OPEN_SHIFT',
  CANCELLED_BY_CLIENT = 'CANCELLED_BY_CLIENT',
  CANCELLED_BY_CAREGIVER = 'CANCELLED_BY_CAREGIVER',
  CANCELLED_BY_OFFICE = 'CANCELLED_BY_OFFICE',
  NOT_SCHEDULED = 'NOT_SCHEDULED',
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  ATTENTION_REQUIRED = 'ATTENTION_REQUIRED',
  SHOW_ALL_TYPES = 'SHOW_ALL_TYPES',
  GENERAL = 'GENERAL',
  MEETING = 'MEETING',
  VACATION_TIME_OFF = 'VACATION_TIME_OFF',
  HOLIDAY = 'HOLIDAY',
  TASK = 'TASK',
  PAID = 'PAID',
  PENDING = 'PENDING',
  REFUND = 'REFUND',
}

export enum SelectTypeEnum {
  PRIMARY = 'primary',
}

// Type
export enum ResponseStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  ERROR = 'error',
}

export enum HTMLEventEnum {
  KEYDOWN = 'keydown',
  ESCAPE = 'Escape',
  ENTER = 'Enter',
  RESIZE = 'resize',
  SCROLL = 'scroll',
}

// Toast Message
export enum ToastTypeEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

export enum MethodRequestServerTypeEnum {
  GET = 'get',
}

export enum ToastPositionTypeEnum {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

// Message
export enum MessageStatusEnum {
  DELIVERED = 'DELIVERED',
  SEEN = 'SEEN',
}

export enum MessageTypeEnum {
  TEXT = 'TEXT',
  VIDEO = 'VIDEO',
  PHOTO = 'PHOTO',
  AUDIO = 'AUDIO',
  FILE = 'FILE',
  EMOTION = 'EMOTION',
  LOCATION = 'LOCATION',
}

export enum SignInNameEnum {
  EMAIL = 'email',
  PASSWORD = 'password',
}

export enum WebSocketEvent {
  LOGIN = 'LOGIN',
  OFFLINE = 'OFFLINE',
  CONNECT_SUCCESS = 'CONNECT_SUCCESS',
  TYPING = 'TYPING',
  STOP_TYPING = 'STOP_TYPING',
  JOIN_LIST_CONVERSATION = 'JOIN_LIST_CONVERSATION',
  LEAVE_LIST_CONVERSATION = 'LEAVE_LIST_CONVERSATION',
  JOIN_CONVERSATION = 'JOIN_CONVERSATION',
  LEAVE_CONVERSATION = 'LEAVE_CONVERSATION',
  SEND_MESSAGE = 'SEND_MESSAGE',
  SEEN_MESSAGE = 'SEEN_MESSAGE',
  GET_MESSAGE_UNSEEN = 'GET_MESSAGE_UNSEEN',
  NEW_NOTIFICATION = 'NEW_NOTIFICATION',
}

export enum WebSocketType {
  CHAT = 'CHAT',
  USE_APP = 'USE_APP',
}

export enum ForgotPasswordNameEnum {
  EMAIL = 'email',
}

export enum NewPasswordNameEnum {
  NEW_PASSWORD = 'newPassword',
  CONFIRM_NEW_PASSWORD = 'confirmNewPassword',
}

export enum CLientStatusEnum {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}

export enum TabQueryClientDetailEnum {
  CARE_PLAN = 'care-plan',
  SHIFT_SCHEDULE = 'shift_schedule',
  DOCUMENTS = 'documents',
  ACTIVITIES = 'activities',
}

export enum TabQueryEventEnum {
  SUMMARY = 'summary',
  NOTES = 'notes',
  EDIT = 'edit',
  COPY = 'copy',
  DELETE = 'delete',
}

export enum AddCRMItemEnum {
  CLIENT = '1',
  PROSPECT = '2',
  REFERRAL_SOURCE = '3',
}

export enum HRCarInsuranceEnum {
  YES = 'YES',
  NO = 'NO',
}

export enum ButtonStatusEnum {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export enum TypeDocumentsEnum {
  PDF = 'PDF',
  FILE_FOLDER = 'FILE_FOLDER',
}

export enum CRMEnum {
  CLIENT = 'CLIENT',
  REFERRAL = 'REFERRAL',
  PROSPECT = 'PROSPECT',
}

export enum ReferrerEnum {
  None = 'None',
  Self = 'Self',
}

export enum InputCRMEnum {
  POSTAL_CODE = 'postalCode',
  HOME_PHONE = 'homePhone',
  MOBILE_PHONE = 'mobilePhone',
  WORK_PHONE = 'workPhone',
  FAX_NUMBER = 'faxNumber',
  POSTAL_CODE_INDIVIDUAL = 'postalCodeIndividual',
  HOME_PHONE_INDIVIDUAL = 'homePhoneIndividual',
  MOBILE_PHONE_INDIVIDUAL = 'mobilePhoneIndividual',
  WORK_PHONE_INDIVIDUAL = 'workPhoneIndividual',
  FAX_NUMBER_INDIVIDUAL = 'faxNumberIndividual',
  POSTAL_CODE_ORGANIZATION = 'postalCodeOrganization',
  HOME_PHONE_ORGANIZATION = 'homePhoneOrganization',
  MOBILE_PHONE_ORGANIZATION = 'mobilePhoneOrganization',
  WORK_PHONE_ORGANIZATION = 'workPhoneOrganization',
  FAX_NUMBER_ORGANIZATION = 'faxNumberOrganization',
}

export enum RadioCrmEnum {
  INDIVIDUAL = 'INDIVIDUAL',
  ORGANIZATION = 'ORGANIZATION',
  HOURLY = 'HOURLY',
  PER_VISIT = 'PER_VISIT',
  LIVE_IN = 'LIVE_IN',
  NOT_BILLABLE = 'NOT_BILLABLE',
  NOT_PAYABLE = 'NOT_PAYABLE',
}

export enum TabQueryTaskDetailEnum {
  COMMENTS = 'comments',
  ACTIVITY = 'activity',
}

export enum FormAddTaskEnum {
  ACCOUNT_ID = 'accountId',
  NAME = 'name',
  DESCRIPTION = 'description',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  TAGS = 'tags',
  PRIORITY = 'priority',
  STATUS = 'status',
  ASSIGNEE = 'assignee',
}

export enum TagsEnum {
  DESIGN = 'Design',
  PRODUCT = 'Product',
  MARKETING = 'Marketing',
  DESIGN_SYSTEM = 'Design System',
}

export enum TYPE_FILE_SIZE {
  GB = 'GB',
  MB = 'MB',
  KB = 'KB',
  B = 'B',
}

export enum TYPE_FILE {
  PDF = 'PDF',
  XLSX = 'XLSX',
  XLS = 'XLS',
  XLSM = 'XLSM',
  XLTX = 'XLTX',
  XLTM = 'XLTM',
  XLAM = 'XLAM',
  XLSB = 'XLSB',
  DOCX = 'DOCX',
  DOC = 'DOC',
  DOTX = 'DOTX',
  DOCM = 'DOCM',
  DOTM = 'DOTM',
  PPTX = 'PPTX',
  PPT = 'PPT',
  POTM = 'POTM',
  PPTM = 'PPTM',
  POTX = 'POTX',
  PPSX = 'PPSX',
  PPSM = 'PPSMs',
  IMAGE = 'Image',
  FOLDER = 'File Folder',
  VIDEO = 'Video',
  AUDIO = 'Audio',
}

export enum StatusTimeClockTypeEnum {
  CHECK_IN = 'CHECK_IN',
  CHECK_OUT = 'CHECKOUT',
  BREAK = 'BREAK',
  RESUME = 'RESUME',
  IN_PROGRESS = 'IN_PROGRESS',
  TODO = 'TODO',
  DONE = 'DONE',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
}

export enum EditTaskModalEnum {
  ACCOUNT_ID = 'AccountId',
  NAME = 'Name',
  DESCRIPTION = 'Description',
  START_DATE = 'Start Date',
  END_DATE = 'End Date',
  TAGS = 'Tags',
  PRIORITY = 'Priority',
  STATUS = 'Status',
  ASSIGNEE = 'Assignee',
}

export enum AddScheduleModalEnum {
  ACCOUNT_ID = 'accountId',
  NAME_TASK = 'nameTask',
  ASSIGNEES = 'assignees',
  ALL_DAY = 'isAllDay',
  TIME_ZONE = 'timeZone',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  RECURRENCE = 'recurrence',
  CAREGIVER = 'caregiver',
  CLIENT_NAME = 'clientName',
}

export enum AllDayAddScheduleEnum {
  YES = 'YES',
  NO = 'NO',
}

export enum FormTimeClockKeyEnum {
  USER_NAME = 'userName',
  TASK_ID = 'taskId',
  TASK_NAME = 'taskName',
  PROJECT_NAME = 'projectName',
  LOCATION = 'location',
  CHECK_IN = 'checkIn',
  CHECK_OUT = 'checkOut',
  TOTAL_BREAK = 'totalBreak',
  DURATION = 'duration',
}

export enum FormEmployeeKeyEnum {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  ROLE = 'role',
}

export enum TypeScheduleEnum {
  MY = 'MY',
  FULL = 'FULL',
}

export enum TaskIncludeInEnum {
  CALENDARS = 'CALENDARS',
  CAREGIVER_PORTAL = 'CAREGIVER_PORTAL',
  PRINTED_CALENDARS = 'PRINTED_CALENDARS',
  FAMILY_ROOM = 'FAMILY_ROOM',
}

export enum TypeFilesDocumentEnum {
  CLIENT_DOCUMENT = 'CLIENT_DOCUMENT',
  CAREGIVER_DOCUMENT = 'CAREGIVER_DOCUMENT',
}

export enum UpdateClientModalEnum {
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  ADDRESS = 'Address',
  CITY = 'City',
  STATE = 'State',
  POST_CODE = 'Post Code',
  MOBILE_PHONE = 'Mobile Phone',
  LOCATION = 'Location',
  REFERRER = 'Referrer',
  TIME_ZONE = 'Time Zone',
}

export enum UpdateClientNameModalEnum {
  AVATAR_ACCOUNT = 'avatarAccount',
}

export enum AddCaregiverNameModalEnum {
  AVATAR_ACCOUNT = 'avatarAccount',
}

export enum AddCaregiverModalEnum {
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  EMAIL = 'Email',
}

export enum TabNotesEnum {
  NOTE = 'note',
}

export enum PageViewScheduleEnum {
  CLIENTS = 'clients',
  CAREGIVER = 'caregiver',
  OFFICE = 'office',
  OPEN_SHIFT = 'open-shift',
}

export enum ViewScheduleEnum {
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
}

export enum ViewCalendarEnum {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
}

export enum FieldTypeEnum {
  ASSIGNEE = 'ASSIGNEE',
  DESCRIPTION = 'DESCRIPTION',
  DUE_DATE = 'DUE_DATE',
  RELATES = 'RELATES',
}

export enum FieldTypeEmployeesEnum {
  NAME = 'NAME',
  ID = 'ID',
  EMAIL = 'EMAIL',
  JOIN_DATE = 'JOIN_DATE',
  ROLE = 'ROLE',
}

export enum FieldTypeApplicantEnum {
  NAME = 'NAME',
  ADDRESS = 'ADDRESS',
  EMAIL = 'EMAIL',
  SOURCE = 'SOURCE',
  PHONE_NUMBER = 'PHONE_NUMBER',
}

export enum ActivitiesBusinessTypeEnum {
  UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT',
  CREATE_SCHEDULE = 'CREATE_SCHEDULE',
  NEW_TASK = 'NEW_TASK',
}

export enum RelatesClientTypeEnum {
  CLIENT = 'CLIENT',
  PROSPECT = 'PROSPECT',
  REFERRAL = 'REFERRAL',
}

export enum FilterParamTaskEnum {
  FIELDS = 'fields',
}

export enum FilterApplicantEnum {
  FIELDS = 'fields',
}

export enum RecurrenceEnum {
  NONE = 'NONE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum FormTaskDateTypeEnum {
  NEVER = 'NEVER',
  UNTIL = 'UNTIL',
}

export enum DayOfWeekEnum {
  SUN = 0,
  MON = 1,
  TUE = 2,
  WED = 3,
  THU = 4,
  FRI = 5,
  SAT = 6,
}

export enum MonthlyOccurrenceEnum {
  DAY_OF_MONTH = 'DAY_OF_MONTH',
}

export enum ModeTextEditorEnum {
  VIEW = 'view',
  CREATE = 'create',
  EDIT = 'edit',
}

export enum ThemeTextEditorEnum {
  SNOW = 'snow',
  BUBBLE = 'bubble',
}

export enum ActivityTypeEnum {
  CREATE_TASK = 'CREATE_TASK',
  UPDATE_TASK = 'UPDATE_TASK',
  UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT',
  SEND_EMAIL = 'SEND_EMAIL',
}

export enum BusinessModelEnum {
  TASK = 'TASK',
  DOCUMENT = 'DOCUMENT',
  EMAIL = 'EMAIL',
}

export enum FormTaskEnum {
  STATUS = 'status',
  RECURRENCE = 'recurrence',
  END_TYPE = 'endType',
  DESCRIPTION = 'description',
  REPEAT_MONTH_ON = 'repeatMonthOn',
  UNTIL_DATE = 'untilDate',
  DUE_DATE = 'dueDate',
  TO_DATE = 'toDate',
  FROM_DATE = 'fromDate',
  ASSIGNEE = 'assignee',
  RELATES = 'relates',
  REPEAT_EVERY = 'repeatEvery',
  REPEAT_WEEK_ON = 'repeatWeekOn',
  SEND_REMINDER_EMAIL = 'isSendReminderEmail',
}

export enum ActivitySendEmailDetailEnum {
  CONTENT = 'CONTENT',
  FILES = 'FILES',
}

export enum CarePlanKeyEnum {
  MATCH_CRITERIA = 'MATCH_CRITERIA',
  CARE_NEEDS = 'CARE_NEEDS',
  DEMOGRAPHICS = 'DEMOGRAPHICS',
  ADLS = 'ADLS',
  IADLS = 'IADLS',
  ICD9_CODES = 'ICD9_CODES',
  ICD10_CODES = 'ICD10_CODES',
  UB_04_CODES = 'UB_04_CODES',
  MEDICAL_CONDITIONS = 'MEDICAL_CONDITIONS',
  MENTAL_BEHAVIOR_CONDITIONS = 'MENTAL_BEHAVIOR_CONDITIONS',
  ALLERGIES = 'ALLERGIES',
  ELIMINATIONS = 'ELIMINATIONS',
  MEDICATIONS = 'MEDICATIONS',
  AMBULATIONS = 'AMBULATIONS',
  TRANSFERS = 'TRANSFERS',
  BATHING_GROOMING = 'BATHING_GROOMING',
  MEALS = 'MEALS',
  DRIVING = 'DRIVING',
  EXERCISES = 'EXERCISES',
  SLEEP_PATTERNS = 'SLEEP_PATTERNS',
  EQUIPMENT = 'EQUIPMENT',
  PET_CARE = 'PET_CARE',
  DAILY_ROUTINE = 'DAILY_ROUTINE',
}

export enum CarePlanQuickLinkEnum {
  CARE_NEEDS = 'CARE_NEEDS',
  DEMOGRAPHICS = 'DEMOGRAPHICS',
  MEDICAL_CONDITIONS = 'MEDICAL_CONDITIONS',
  MENTAL_BEHAVIOR_CONDITIONS = 'MENTAL_BEHAVIOR_CONDITIONS',
  ALLERGIES = 'ALLERGIES',
  ELIMINATION = 'ELIMINATION',
  AMBULATION = 'AMBULATION',
  MEALS = 'MEALS',
  EXERCISE = 'EXERCISE',
  DAILY_ROUTINE = 'DAILY_ROUTINE',
}

export enum DRNDemographicEnum {
  YES = 'YES',
  NO = 'NO',
  UNKNOWN = 'UNKNOWN',
}

export enum MedicalConditionEnum {
  GOOD = 'GOOD',
  POOR = 'POOR',
  NONE = 'NONE',
  DEAF = 'DEAF',
  BLIND = 'BLIND',
  HEARING_AID = 'HEARING_AID',
  NONVERBAL = 'NONVERBAL',
  SIGN_LANGUAGE = 'SIGN_LANGUAGE',
  GLASSES = 'GLASSES',
  DYSPHAGIA_DX = 'DYSPHAGIA_DX',
  SMOKER = 'SMOKER',
  ON_OXYGEN = 'ON_OXYGEN',
  FEEDING_TUBE = 'FEEDING_TUBE',
  COLOSTOMY = 'COLOSTOMY',
  URINARY_CATHETER_ANY = 'URINARY_CATHETER_ANY',
  TRACHESTOMY = 'TRACHESTOMY',
}

export enum CarePlanPrognosisEnum {
  POOR = 'POOR',
  GUARDED = 'GUARDED',
  FAIR = 'FAIR',
  GOOD = 'GOOD',
  EXCELLENT = 'EXCELLENT',
}

export enum AmbulationFallRiskEnum {
  FALL_RISK = 'FALL_RISK',
  NO_HISTORY = 'NO_HISTORY',
  POOR_BALANCE = 'POOR_BALANCE',
}

export enum AmbulationAidsEnum {
  CANE = 'CANE',
  WALKER = 'WALKER',
  WHEEL_CHAIR = 'WHEEL_CHAIR',
  GERI_CHAIR = 'GERI_CHAIR',
  SCOOTER = 'SCOOTER',
}

export enum AmbulationArmsOrHandsEnum {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum ActivityDetailEMAILEnum {
  TO = 'to',
  FROM = 'from',
  SUBJECT = 'subject',
  CONTENT = 'content',
  FILES = 'files',
}

export enum KeyboardEnum {
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  TAB = 'Tab',
}

export enum ConnectionStatusEnum {
  CONNECTED = 'connected',
  CONNECTING = 'connecting',
  DISCONNECTED = 'disconnected',
}

export enum TabQuerySettingsEnum {
  INTEGRATION = 'integration',
}

export enum CurrencyEnum {
  USD = 'USD',
}

export enum QuickBooksStatusEnum {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export enum CareTypeClientEnum {
  OTHER = 'OTHER',
  HOME_CARE = 'HOME_CARE',
  DAY_CENTER = 'DAY_CENTER',
  TRANSPORTATION = 'TRANSPORTATION',
}

export enum RadioCheckboxTypeEnum {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
}

export enum StatusNotificationEnum {
  READ = 'READ',
  UNREAD = 'UNREAD',
}

export enum BusinessNotificationTypeEnum {
  TASK = 'TASK',
}

export enum NotificationStatusEnum {
  READ = 'READ',
  UNREAD = 'UNREAD',
}
