// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import employeeApi from '~/apis/employee';
import { CREATE_EMPLOYEE, GET_LIST_EMPLOYEE, UPDATE_EMPLOYEE } from '~/utils/constants/actionType';
import { IListQueryParams } from '~/utils/interface/common';
import { IFormAddEmployee, IFormUpdateEmployee } from '~/utils/interface/employees';

export const getListEmployee = createAsyncThunk(
  GET_LIST_EMPLOYEE,
  async (payload: IListQueryParams, { rejectWithValue }) => {
    try {
      const res = await employeeApi.getList(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createEmployee = createAsyncThunk(CREATE_EMPLOYEE, async (body: IFormAddEmployee, { rejectWithValue }) => {
  try {
    const res = await employeeApi.createEmployee(body);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateEmployee = createAsyncThunk(
  UPDATE_EMPLOYEE,
  async (body: IFormUpdateEmployee, { rejectWithValue }) => {
    try {
      const res = await employeeApi.updateEmployee(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
