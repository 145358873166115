// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useRef, useState } from 'react';
// Components, Layouts, Pages
// Others
import {
  ACCEPT_UPLOAD_DOCUMENT_PURCHASE_ORDER,
  ASTERISK_SYMBOL,
  EMPTY_STRING,
  FILE_IMAGE_TYPE,
  FILE_VIDEO_TYPE,
} from '~/utils/constants/common';
// Styles, images, icons
import styles from './UploadFileSendMail.module.scss';
import { icons } from '~/assets';

type Props = {
  label?: string;
  name?: string;
  multiple?: boolean;
  content?: string;
  accept?: string;
  errorIndex?: number[];
  errorMessage?: string;
  required?: boolean;
  onFilesChange?: (files: File[]) => void;
};

const cx = classNames.bind(styles);

const UploadFileSendMail = (props: Props) => {
  //#region Destructuring Props
  const {
    label,
    name,
    multiple = false,
    content,
    accept = ACCEPT_UPLOAD_DOCUMENT_PURCHASE_ORDER,
    errorIndex,
    errorMessage,
    required,
    onFilesChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [uploadFiles, setUploadFile] = useState<File[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? Array.from(event.target.files) : [];

    if (!files) return;

    setUploadFile((prevState) => [...prevState, ...files]);

    if (onFilesChange) {
      onFilesChange([...uploadFiles, ...files]);
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = EMPTY_STRING;
    }
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = uploadFiles.filter((_, i) => i !== index);
    setUploadFile(newFiles);

    if (onFilesChange) {
      onFilesChange(newFiles);
    }
  };

  const renderIconFile = (file: File) => {
    switch (true) {
      case file.type.startsWith(FILE_IMAGE_TYPE):
        return <img src={URL.createObjectURL(file)} alt={t('common_img_text_alt')} className={cx('fileImg')} />;

      case file.type.startsWith(FILE_VIDEO_TYPE):
        return <img src={icons.commonIconVideoDefault} alt={t('common_img_text_alt')} className={cx('fileImg')} />;

      default:
        return <img src={icons.commonIconDocsFile} alt={t('common_img_text_alt')} className={cx('fileImg')} />;
    }
  };
  //#endregion Handle Function

  return (
    <div id='uploadFileSendMailComponent' className={cx('container')}>
      {label && (
        <div className={cx('label')}>
          {label} {required && <span className={cx('required')}>{ASTERISK_SYMBOL}</span>}
        </div>
      )}

      <div className={cx('filesUpload')}>
        {uploadFiles &&
          uploadFiles?.length > 0 &&
          uploadFiles?.map((file, index) => (
            <div className={cx('file', { error: errorIndex?.includes(index) })} key={index}>
              <div className={cx('fileContent')}>{renderIconFile(file)}</div>

              <div className={cx('fileClose')} onClick={() => handleRemoveFile(index)}>
                <img src={icons.commonIconModalClose} alt={t('common_img_text_alt')} className={cx('fileCloseImg')} />
              </div>
            </div>
          ))}

        <label htmlFor={name} className={cx('uploadContainer')}>
          <input
            ref={fileInputRef}
            type='file'
            name={name}
            id={name}
            className={cx('uploadInput')}
            multiple={multiple}
            accept={accept}
            onChange={handleUploadFile}
          />

          <div className={cx('uploadContentGroup')}>
            <img src={icons.commonIconUpload} alt={t('common_img_text_alt')} className={cx('uploadIcon')} />

            <span className={cx('uploadContent')}>{content || t('common_component_upload_label')}</span>
          </div>
        </label>
      </div>

      {errorMessage && <div className={cx('errorMessage')}>{errorMessage}</div>}
    </div>
  );
};

export default UploadFileSendMail;
