// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
// Components, Layouts, Pages
import { MultipleCheckbox, BaseTextarea } from '~/components';
// Others
import { activitiesPermittedOptions } from '~/mockData';
import { EMPTY_STRING } from '~/utils/constants/common';
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
// Styles, images, icons
import styles from './CarePlanDailyRoutine.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CarePlanDailyRoutine = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='carePlanDailyRoutine' className={cx('container')}>
      <Controller
        name='dailyRoutine.activitiesPermitted'
        control={control}
        render={({ field: { value, onChange } }) => (
          <div className={cx('carePlanDailyRoutine')}>
            <label className={cx('label')}>{t('care_plan_daily_routine_activities_permitted_label')}</label>
            <MultipleCheckbox
              value={value || []}
              id='activitiesPermitted'
              options={activitiesPermittedOptions}
              col={3}
              onChange={onChange}
              messageError={errors.dailyRoutine?.activitiesPermitted?.message}
            />
          </div>
        )}
      />

      <div className={cx('carePlanDailyRoutine')}>
        <label className={cx('label')}>{t('care_plan_daily_routine_label')}</label>

        <Controller
          name='dailyRoutine.morningDailyRoutine'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseTextarea
              id='morningDailyRoutine'
              value={value || EMPTY_STRING}
              onChange={onChange}
              label={t('care_plan_daily_routine_morning_label')}
              messageError={errors.dailyRoutine?.morningDailyRoutine?.message}
              height={90}
            />
          )}
        />

        <Controller
          name='dailyRoutine.afternoonDailyRoutine'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseTextarea
              id='afternoonDailyRoutine'
              value={value || EMPTY_STRING}
              onChange={onChange}
              label={t('care_plan_daily_routine_afternoon_label')}
              messageError={errors.dailyRoutine?.afternoonDailyRoutine?.message}
              height={90}
            />
          )}
        />

        <Controller
          name='dailyRoutine.eveningDailyRoutine'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseTextarea
              id='eveningDailyRoutine'
              value={value || EMPTY_STRING}
              onChange={onChange}
              label={t('care_plan_daily_routine_evening_label')}
              messageError={errors.dailyRoutine?.eveningDailyRoutine?.message}
              height={90}
            />
          )}
        />
      </div>

      <div className={cx('carePlanDailyRoutine')}>
        <label className={cx('label')}>{t('care_plan_daily_routine_activities_label')}</label>

        <Controller
          name='dailyRoutine.activitiesAtHome'
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className={cx('activitiesControl')}>
              <label htmlFor='activitiesAtHome' className={cx('labelControl')}>
                {t('care_plan_daily_routine_activity_at_home_label')}
                <span>{t('care_plan_daily_routine_activity_at_home_eg_label')}</span>
              </label>
              <BaseTextarea
                id='activitiesAtHome'
                value={value || EMPTY_STRING}
                onChange={onChange}
                messageError={errors.dailyRoutine?.activitiesAtHome?.message}
                height={90}
              />
            </div>
          )}
        />

        <Controller
          name='dailyRoutine.activitiesAwayFromHome'
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className={cx('activitiesControl')}>
              <label htmlFor='activitiesAtHome' className={cx('labelControl')}>
                {t('care_plan_daily_routine_activity_away_from_home_label')}
                <span>{t('care_plan_daily_routine_activity_away_from_home_eg_label')}</span>
              </label>
              <BaseTextarea
                id='activitiesAwayFromHome'
                value={value || EMPTY_STRING}
                onChange={onChange}
                messageError={errors.dailyRoutine?.activitiesAwayFromHome?.message}
                height={90}
              />
            </div>
          )}
        />

        <Controller
          name='dailyRoutine.favoriteRestaurantsShops'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseTextarea
              id='favoriteRestaurantsShops'
              value={value || EMPTY_STRING}
              onChange={onChange}
              label={t('care_plan_daily_routine_activity_favorite_restaurant_label')}
              messageError={errors.dailyRoutine?.favoriteRestaurantsShops?.message}
              height={90}
            />
          )}
        />

        <Controller
          name='dailyRoutine.relationship'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseTextarea
              id='relationship'
              value={value || EMPTY_STRING}
              onChange={onChange}
              label={t('care_plan_daily_routine_activity_people_label')}
              messageError={errors.dailyRoutine?.relationship?.message}
              height={90}
            />
          )}
        />
      </div>
    </div>
  );
};

export default CarePlanDailyRoutine;
