// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseTextarea, MultipleCheckbox, RadioCrm } from '~/components';
// Others
import { EMPTY_STRING } from '~/utils/constants/common';
import { AmbulationFallRiskEnum } from '~/utils/enum';
import { optionCheckBoxAids, optionCheckBoxArmsOrHands } from '~/mockData';
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
// Styles, images, icons
import styles from './Ambulation.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const Ambulation = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='ambulationComponent' className={cx('container')}>
      <div className={cx('abulationItem')}>
        <label className={cx('label')}>{t('care_plan_ambulation_aids_label')}</label>
        <div className={cx('ambulationControl')}>
          <Controller
            name='ambulation.aids'
            control={control}
            render={({ field: { value, onChange } }) => (
              <MultipleCheckbox
                id='aids'
                options={optionCheckBoxAids}
                value={value || []}
                onChange={onChange}
                type='flex-col'
                messageError={errors.ambulation?.aids?.message}
              />
            )}
          />
        </div>
      </div>

      <div className={cx('viewRow')}>
        <div className={cx('viewRadio')}>
          <span className={cx('label')}>{t('care_plan_ambulation_fall_risk_label')}</span>
          <Controller
            name='ambulation.fallRisk'
            control={control}
            render={({ field: { value, onChange } }) => (
              <div className={cx('viewFallRiskRadio')}>
                <RadioCrm
                  labelClassName={cx('radioLabel')}
                  id={'fallRisk'}
                  name={'fallRisk'}
                  label={t('care_plan_ambulation_fall_risk_radio')}
                  value={AmbulationFallRiskEnum.FALL_RISK}
                  checked={value === AmbulationFallRiskEnum.FALL_RISK}
                  onChange={onChange}
                  fontSize={12}
                />
                <RadioCrm
                  labelClassName={cx('radioLabel')}
                  id={'noHistory'}
                  name={'noHistory'}
                  label={t('care_plan_ambulation_no_history_radio')}
                  value={AmbulationFallRiskEnum.NO_HISTORY}
                  checked={value === AmbulationFallRiskEnum.NO_HISTORY}
                  onChange={onChange}
                  fontSize={12}
                />
                <RadioCrm
                  labelClassName={cx('radioLabel')}
                  id={'poorBalance'}
                  name={'poorBalance'}
                  label={t('care_plan_ambulation_poor_balance_radio')}
                  value={AmbulationFallRiskEnum.POOR_BALANCE}
                  checked={value === AmbulationFallRiskEnum.POOR_BALANCE}
                  onChange={onChange}
                  fontSize={12}
                />
              </div>
            )}
          />
        </div>

        <div className={cx('abulationItem')}>
          <label className={cx('label')}>{t('care_plan_ambulation_arms_hands_label')}</label>
          <div className={cx('ambulationControl')}>
            <Controller
              name='ambulation.armsHands'
              control={control}
              render={({ field: { value, onChange } }) => (
                <MultipleCheckbox
                  id='armsOrHands'
                  options={optionCheckBoxArmsOrHands}
                  value={value || []}
                  onChange={onChange}
                  type='flex-col'
                  messageError={errors.ambulation?.armsHands?.message}
                />
              )}
            />
          </div>
        </div>
      </div>

      <Controller
        name='ambulation.notes'
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <BaseTextarea
            id='notes'
            height={90}
            label={t('care_plan_ambulation_note_label')}
            onChange={onChange}
            name={name}
            value={value || EMPTY_STRING}
            messageError={errors.ambulation?.notes?.message}
          />
        )}
      />
    </div>
  );
};

export default Ambulation;
