// Libs
import classNames from 'classnames/bind';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import {
  Modal,
  BaseButton,
  BaseInput,
  BaseUploadImage,
  BaseTextarea,
  BaseSelect,
  BaseDatePicker,
  Loading,
} from '~/components';
// Others
import { ButtonTypeEnum, CRMEnum, InputCRMEnum, ReferrerEnum, StorageEnum } from '~/utils/enum';
import { IBaseOption, IListDataResponse, IUploadImage, Option } from '~/utils/interface/common';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { mockDataState, mockOptionLocation, mockOptionReferrer, mockOptionsTimezone } from '~/mockData';
import { convertCamelCaseToTitleCase, getUserName, isEmptyObject, validateFormatNumber } from '~/utils/helper';
import { useAppDispatch } from '~/redux/hooks';
import { clientActions } from '~/thunks/crm/clients/clientsSlice';
import { createClients } from '~/thunks/crm/clients/clientsThunk';
import { IFormCreateClientCrm } from '~/utils/interface/crm/clients';
import { CrmClientModalType } from '~/utils/type/common';
import { getListReferrals } from '~/thunks/crm/referral/referralThunk';
import { IListReferrals } from '~/utils/interface/crm/referral';
// Styles, images, icons
import styles from './CrmAddModal.module.scss';

type Props = {
  type: CrmClientModalType;
  isOpen: boolean;
  onClose: () => void;
};

const cx = classNames.bind(styles);

const CrmAddModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, type } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const accountId = localStorage.getItem(StorageEnum.USER_ID);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataFormClientCrm, setDataFormClientCrm] = useState<IFormCreateClientCrm>({
    firstName: EMPTY_STRING,
    lastName: EMPTY_STRING,
    address: EMPTY_STRING,
    city: EMPTY_STRING,
    state: EMPTY_STRING,
    postalCode: EMPTY_STRING,
    mobilePhone: EMPTY_STRING,
    location: EMPTY_STRING,
    referrerId: EMPTY_STRING,
    timeZone: EMPTY_STRING,
  });
  const [formErrors, setFormErrors] = useState<IFormCreateClientCrm>({
    firstName: EMPTY_STRING,
    lastName: EMPTY_STRING,
    address: EMPTY_STRING,
    city: EMPTY_STRING,
    state: EMPTY_STRING,
    postalCode: EMPTY_STRING,
    mobilePhone: EMPTY_STRING,
    location: EMPTY_STRING,
    referrerId: EMPTY_STRING,
    timeZone: EMPTY_STRING,
  });
  const [files, setFiles] = useState<File[]>([]);
  const [listReferralSelect, setListReferralSelect] = useState<IBaseOption[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetReferral();
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetReferral = () => {
    // TODO Handle Logic Later
    setIsLoading(true);

    dispatch(getListReferrals({}))
      .unwrap()
      .then((res) => {
        const { responses, pagination }: IListDataResponse<IListReferrals[]> = res?.data;

        const options = responses.map((data) => {
          return {
            label: data.organization ? data.organization : getUserName(data.firstName, data.middleName, data.lastName),
            value: data.id,
          };
        });

        setListReferralSelect([...mockOptionReferrer, ...options]);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getUpdatedValue = (name: string, value: string): string => {
    switch (name) {
      case InputCRMEnum.HOME_PHONE:
        return value === EMPTY_STRING || validateFormatNumber(value)
          ? value
          : dataFormClientCrm.homePhone || EMPTY_STRING;

      case InputCRMEnum.MOBILE_PHONE:
        return value === EMPTY_STRING || validateFormatNumber(value)
          ? value
          : dataFormClientCrm.mobilePhone || EMPTY_STRING;

      case InputCRMEnum.POSTAL_CODE:
        return value === EMPTY_STRING || validateFormatNumber(value)
          ? value
          : dataFormClientCrm.postalCode || EMPTY_STRING;

      case InputCRMEnum.WORK_PHONE:
        return value === EMPTY_STRING || validateFormatNumber(value)
          ? value
          : dataFormClientCrm.workPhone || EMPTY_STRING;
      default:
        return value;
    }
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    if (value.startsWith(' ')) {
      return;
    }

    const updatedValue = getUpdatedValue(name, value);

    setDataFormClientCrm((prevState) => {
      const updatedState = { ...prevState, [name]: updatedValue };

      if (!value) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [name]: t('common_error_message_required', {
            message: convertCamelCaseToTitleCase(name),
          }),
        }));
      } else {
        setFormErrors((prevErrors) => {
          const updatedError = {
            ...prevErrors,
          };
          delete updatedError[name as keyof IFormCreateClientCrm];
          return updatedError;
        });
      }

      return updatedState;
    });
  };

  const handleChangeSelect = (option: Option, name?: string) => {
    const { value } = option;
    setDataFormClientCrm((prevState) => {
      const updatedState = { ...prevState, [name as string]: value };

      if (!value) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [name as string]: t('common_error_message_required', {
            message: convertCamelCaseToTitleCase(name as string),
          }),
        }));
      } else {
        setFormErrors((prevErrors) => {
          const updatedError = {
            ...prevErrors,
          };
          delete updatedError[name as keyof IFormCreateClientCrm];
          return updatedError;
        });
      }

      return updatedState;
    });
  };

  const handleUploadChange = (imageUploadList: IUploadImage[]) => {
    const fileList: File[] = [];
    imageUploadList.forEach((imageUploadItem: IUploadImage) => {
      if (!imageUploadItem?.file) return;
      fileList.push(imageUploadItem.file);
    });
    setFiles(fileList);
  };

  const handleSave = () => {
    const formData = new FormData();
    if (files?.length > DEFAULT_NUMBER_ZERO) {
      files.forEach((file: File) => {
        formData.append('avatarAccount', file);
      });
    }
    if (type) {
      formData.append('type', type);
    }

    const updatedDataFormClientCrm: IFormCreateClientCrm = { ...dataFormClientCrm };

    if (dataFormClientCrm.referrerId === ReferrerEnum.Self) {
      updatedDataFormClientCrm.referrerId = accountId || EMPTY_STRING;
    } else if (dataFormClientCrm.referrerId === ReferrerEnum.None) {
      updatedDataFormClientCrm.referrerId = ReferrerEnum.None.toUpperCase();
    }

    Object.keys(updatedDataFormClientCrm).forEach((key: string) => {
      if (!updatedDataFormClientCrm[key as keyof IFormCreateClientCrm]) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [key]: t('common_error_message_required', {
            message: convertCamelCaseToTitleCase(key),
          }),
        }));

        return;
      } else {
        setFormErrors((prevErrors) => {
          const updatedError = {
            ...prevErrors,
          };
          delete updatedError[key as keyof IFormCreateClientCrm];
          return updatedError;
        });
        formData.append(key, updatedDataFormClientCrm[key as keyof IFormCreateClientCrm] as string);
      }
    });

    if (isEmptyObject(formErrors)) {
      handleAdd(formData);
    }
  };

  const handleAdd = (data: FormData) => {
    setIsLoading(true);

    dispatch(createClients(data))
      .unwrap()
      .then((response) => {
        onClose && onClose();
        dispatch(clientActions.setRefreshList(true));
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSelectDate = (date: string, name?: string) => {
    if (!name) return;
    setDataFormClientCrm({ ...dataFormClientCrm, [name]: date });
  };

  const renderTitleModal = (type: CrmClientModalType) => {
    switch (type) {
      case CRMEnum.CLIENT:
        return t('crm_add_client_modal_title');
      case CRMEnum.PROSPECT:
        return t('crm_add_prospect_modal_title');
      default:
        return EMPTY_STRING;
    }
  };
  //#endregion Handle Function

  return (
    <Modal title={renderTitleModal(type)} isOpen={isOpen} onClose={onClose}>
      <div id='crmClientModalComponent' className={cx('crmClientModalComponent')}>
        <div className={cx('contentModal')}>
          <BaseUploadImage
            label={t('crm_add_modal_profile_image_label')}
            textBtn={t('crm_add_modal_profile_image_placeholder')}
            height={64}
            onChange={handleUploadChange}
          />

          <div className={cx('threeCol')}>
            <BaseInput
              id='firstName'
              label={t('crm_add_modal_first_name_label')}
              name='firstName'
              //placeholder={t('crm_add_modal_first_name_label')}
              value={dataFormClientCrm?.firstName || EMPTY_STRING}
              onChange={handleChangeInput}
              messageError={formErrors.firstName}
              required={true}
            />
            <BaseInput
              id='middleName'
              label={t('crm_add_modal_middle_name_label')}
              name='middleName'
              //placeholder={t('crm_add_modal_middle_name_label')}
              value={dataFormClientCrm?.middleName || EMPTY_STRING}
              onChange={handleChangeInput}
            />
            <BaseInput
              id='lastName'
              label={t('crm_add_modal_last_name_label')}
              name='lastName'
              //placeholder={t('crm_add_modal_last_name_label')}
              value={dataFormClientCrm?.lastName || EMPTY_STRING}
              onChange={handleChangeInput}
              messageError={formErrors.lastName}
              required={true}
            />
          </div>
          {type === CRMEnum.CLIENT && (
            <BaseDatePicker
              label={t('crm_add_modal_date_of_birth_label')}
              placeholderText={t('common_select_placeholder')}
              name='dob'
              onDateSelected={handleSelectDate}
            />
          )}

          <div className={cx('twoCol')}>
            <BaseInput
              id='address'
              label={t('crm_add_modal_address_label')}
              name='address'
              //placeholder={t('crm_add_modal_address_label')}
              value={dataFormClientCrm?.address || EMPTY_STRING}
              onChange={handleChangeInput}
              messageError={formErrors.address}
              required={true}
            />

            <BaseInput
              id='secondaryAddress'
              label={t('crm_add_modal_secondary_address_label')}
              name='secondaryAddress'
              //placeholder={t('crm_add_modal_secondary_address_label')}
              value={dataFormClientCrm?.secondaryAddress || EMPTY_STRING}
              onChange={handleChangeInput}
            />
          </div>

          <div className={cx('threeCol')}>
            <BaseInput
              id='city'
              label={t('crm_add_modal_city_label')}
              name='city'
              //placeholder={t('crm_add_modal_city_label')}
              value={dataFormClientCrm?.city || EMPTY_STRING}
              onChange={handleChangeInput}
              messageError={formErrors.city}
              required={true}
            />
            <BaseSelect
              options={mockDataState || []}
              name='state'
              label={t('crm_add_modal_state_label')}
              placeholder={t('common_select_placeholder')}
              onChange={handleChangeSelect}
              errorMessage={formErrors.state}
              value={dataFormClientCrm?.state || EMPTY_STRING}
              required={true}
            />
            <BaseInput
              id='postalCode'
              label={t('crm_add_modal_postal_code_label')}
              name='postalCode'
              //placeholder={t('crm_add_modal_postal_code_label')}
              value={dataFormClientCrm?.postalCode || EMPTY_STRING}
              onChange={handleChangeInput}
              messageError={formErrors.postalCode}
              required={true}
            />
          </div>

          <BaseTextarea
            id='notes'
            label={t('crm_add_modal_notes_label')}
            name='notes'
            //placeholder={t('crm_add_modal_notes_placeholder')}
            value={dataFormClientCrm?.notes || EMPTY_STRING}
            onChange={handleChangeInput}
          />

          <BaseInput
            id='ssn'
            label={t('crm_add_modal_ssn_label')}
            name='ssn'
            //placeholder={t('crm_add_modal_ssn_label')}
            value={dataFormClientCrm?.ssn || EMPTY_STRING}
            onChange={handleChangeInput}
          />

          <div className={cx('twoCol')}>
            <BaseInput
              id='homePhone'
              label={t('crm_add_modal_home_phone_label')}
              name='homePhone'
              //placeholder={t('crm_add_modal_home_phone_label')}
              value={dataFormClientCrm?.homePhone || EMPTY_STRING}
              onChange={handleChangeInput}
            />
            <BaseInput
              id='mobilePhone'
              label={t('crm_add_modal_mobile_phone_label')}
              name='mobilePhone'
              //placeholder={t('crm_add_modal_mobile_phone_label')}
              value={dataFormClientCrm?.mobilePhone || EMPTY_STRING}
              onChange={handleChangeInput}
              messageError={formErrors.mobilePhone}
              required={true}
            />
          </div>

          <div className={cx('twoCol')}>
            <BaseInput
              id='workPhone'
              label={t('crm_add_modal_work_phone_label')}
              name='workPhone'
              //placeholder={t('crm_add_modal_work_phone_label')}
              value={dataFormClientCrm?.workPhone || EMPTY_STRING}
              onChange={handleChangeInput}
            />

            <BaseInput
              id='email'
              label={t('crm_add_modal_email_label')}
              name='email'
              //placeholder={t('crm_add_modal_email_label')}
              value={dataFormClientCrm?.email || EMPTY_STRING}
              onChange={handleChangeInput}
            />
          </div>

          <div className={cx('twoCol')}>
            <BaseSelect
              options={mockOptionLocation || []}
              name='location'
              label={t('crm_add_modal_location_label')}
              placeholder={t('common_select_placeholder')}
              onChange={handleChangeSelect}
              value={dataFormClientCrm?.location || EMPTY_STRING}
              errorMessage={formErrors.location}
              required={true}
            />
            <BaseSelect
              options={listReferralSelect}
              name='referrerId'
              label={t('crm_add_modal_referrer_label')}
              placeholder={t('common_select_placeholder')}
              onChange={handleChangeSelect}
              errorMessage={formErrors?.referrerId}
              value={dataFormClientCrm?.referrerId || EMPTY_STRING}
              required={true}
            />
          </div>

          {type === CRMEnum.CLIENT ? (
            <div className={cx('twoCol')}>
              <BaseInput
                id='externalId'
                label={t('crm_add_modal_external_id_label')}
                name='externalId'
                //placeholder={t('crm_add_modal_external_id_label')}
                value={dataFormClientCrm?.externalId || EMPTY_STRING}
                onChange={handleChangeInput}
              />
              <BaseSelect
                options={mockOptionsTimezone || []}
                name='timeZone'
                label={t('crm_add_modal_time_zone_label')}
                placeholder={t('common_select_placeholder')}
                onChange={handleChangeSelect}
                value={dataFormClientCrm?.timeZone || EMPTY_STRING}
                errorMessage={formErrors.timeZone}
                required={true}
              />
            </div>
          ) : (
            <BaseSelect
              options={mockOptionsTimezone || []}
              name='timeZone'
              label={t('crm_add_modal_time_zone_label')}
              placeholder={t('common_select_placeholder')}
              onChange={handleChangeSelect}
              errorMessage={formErrors.timeZone}
              required={true}
            />
          )}
        </div>
        <div className={cx('footerButton')}>
          <BaseButton text={t('common_cancel_label')} width={65} onClick={onClose} />
          <BaseButton
            text={t('common_save_label')}
            typeStyle={ButtonTypeEnum.PRIMARY}
            width={80}
            onClick={handleSave}
          />
        </div>
      </div>

      {isLoading && <Loading />}
    </Modal>
  );
};

export default CrmAddModal;
