// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
// Components, Layouts, Pages
// Others
import { IActivityByUser } from '~/utils/interface/activity';
import { EMPTY_STRING, EMPTY_VALUE } from '~/utils/constants/common';
import { ActivitiesBusinessTypeEnum, TimeFormatEnum } from '~/utils/enum';
import { convertTime, getUserName, convertDateToFormatTime } from '~/utils/helper';
// Styles, images, icons
import styles from './ActivitiesTimeLineItem.module.scss';

type Props = {
  data: IActivityByUser;
};

const cx = classNames.bind(styles);

const ActivitiesTimeLineItem = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const renderActivityContent = () => {
    switch (data?.actionType) {
      case ActivitiesBusinessTypeEnum.NEW_TASK:
        return (
          <>
            <div className={cx('activityDotGroup')}>
              <div className={cx('dot')} />

              <div className={cx('activityContentGroup')}>
                <span className={cx('activityContentLabel')}>{t('activities_assigned_to')}</span>

                <p className={cx('activityContentItem')}>
                  <Tooltip id='assignees' />

                  <span
                    data-tooltip-id='assignees'
                    data-tooltip-content={data?.task?.assignees
                      ?.map((item) => getUserName(item?.firstName, item?.lastName))
                      ?.join(', ')}
                  >
                    {data?.task?.assignees?.map((item) => getUserName(item?.firstName, item?.lastName))?.join(', ')}
                  </span>
                </p>
              </div>
            </div>

            {data?.task?.description && (
              <div className={cx('activityContentGroup')}>
                <p className={cx('activityContentItem')}>
                  <Tooltip id='description' />

                  <span data-tooltip-id='description' data-tooltip-content={data?.task?.description}>
                    {data?.task?.description}
                  </span>
                </p>
              </div>
            )}

            <div className={cx('activityContentGroup')}>
              <span className={cx('activityContentLabel')}>{t('activities_related_to')}</span>

              <p className={cx('activityContentItem')}>
                <Tooltip id='relates' />

                <span
                  data-tooltip-id='relates'
                  data-tooltip-content={data?.task?.relates
                    ?.map((item) => getUserName(item?.firstName, item?.lastName))
                    ?.join(', ')}
                >
                  {data?.task?.relates?.map((item) => getUserName(item?.firstName, item?.lastName))?.join(', ')}
                </span>
              </p>
            </div>
          </>
        );

      default:
        return EMPTY_STRING;
    }
  };
  //#endregion Handle Function

  return (
    <div id='activitiesTimeLineComponent' className={cx('activitiesTimeLineComponent')}>
      <div className={cx('content')}>
        <div className={cx('contentHeadGroup')}>
          <div className={cx('contentBody')}>
            {renderActivityContent && <div className={cx('contentActivities')}>{renderActivityContent()}</div>}
          </div>
        </div>

        <div className={cx('contentDate')}>
          {t('activities_time_line_crm_time_label', {
            date: data?.createdAt ? convertDateToFormatTime(data.createdAt, TimeFormatEnum.MM_DD_YYYY) : EMPTY_VALUE,
            time: data?.createdAt ? convertTime(data.createdAt, TimeFormatEnum.HOUR_MINUTE_AM_PM_NORMAL) : EMPTY_VALUE,
          })}
        </div>
      </div>
    </div>
  );
};

export default ActivitiesTimeLineItem;
