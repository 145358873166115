// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseSelect, BaseTextarea, CheckboxSingle } from '~/components';
// Others
import { selectExerciseOptions } from '~/mockData';
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
import { Controller, useFormContext } from 'react-hook-form';
import { IBaseOption } from '~/utils/interface/common';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './CarePlanExercise.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CarePlanExercise = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='exercise' className={cx('container')}>
      <div className={cx('exerciseItem')}>
        <div className={cx('label')}>{t('exercise_importance_label')}</div>
        <div className={cx('threeCol')}>
          <Controller
            name='exercise.importance'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                options={selectExerciseOptions}
                name='importance'
                placeholder={t('common_select_placeholder')}
                onChange={(optionSelected: IBaseOption) => {
                  onChange(optionSelected?.value);
                }}
                value={value?.toString()}
                errorMessage={errors.exercise?.importance?.message}
              />
            )}
          />

          <Controller
            name='exercise.isSpecificRegimen'
            control={control}
            render={({ field: { value, onChange } }) => (
              <CheckboxSingle
                name='isSpecificRegimen'
                label={t('exercise_specific_regimen_label')}
                value={value}
                onChange={onChange}
                fontSize={12}
                messageError={errors.exercise?.isSpecificRegimen?.message}
              />
            )}
          />

          <Controller
            name='exercise.isEncourageExercises'
            control={control}
            render={({ field: { value, onChange } }) => (
              <CheckboxSingle
                name='isEncourageExercises'
                value={value}
                label={t('exercise_encourage_exercises_label')}
                onChange={onChange}
                fontSize={12}
                messageError={errors.exercise?.isEncourageExercises?.message}
              />
            )}
          />
        </div>
      </div>

      <Controller
        name='exercise.notes'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseTextarea
            id='notes'
            name='notes'
            label={t('exercise_note_title_label')}
            onChange={onChange}
            value={value || EMPTY_STRING}
            height={90}
            messageError={errors.exercise?.notes?.message}
          />
        )}
      />
    </div>
  );
};

export default CarePlanExercise;
