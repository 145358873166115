// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { getListActivityByCaregiver, getListActivityByClient } from './activityThunk';

export interface activityState {}

const initialState: activityState = {};

const activitySlice = createSlice({
  name: 'activityState',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    // Get List Activity By Client
    builder
      .addCase(getListActivityByClient.pending, (state) => {})
      .addCase(getListActivityByClient.fulfilled, (state) => {})
      .addCase(getListActivityByClient.rejected, (state) => {});

    // Get List Activity By Caregiver
    builder
      .addCase(getListActivityByCaregiver.pending, (state) => {})
      .addCase(getListActivityByCaregiver.fulfilled, (state) => {})
      .addCase(getListActivityByCaregiver.rejected, (state) => {});
  },
});

// Actions
export const activityActions = activitySlice.actions;

// Selectors

// Reducer
const activityReducer = activitySlice.reducer;
export default activityReducer;
