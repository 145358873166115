// Libs
import classNames from 'classnames/bind';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// Components, Layouts, Pages
import { BaseButton, BaseInput, BaseUploadImage, Loading, Modal } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { caregiverActions } from '~/thunks/caregiver/caregiverSlice';
import { createCaregiver } from '~/thunks/caregiver/caregiverThunk';
import { AddCaregiverModalEnum, AddCaregiverNameModalEnum, ButtonTypeEnum, InputTypeEnum } from '~/utils/enum';
import { createFormData } from '~/utils/helper';
import { IFormAddCaregiver } from '~/utils/interface/caregiver';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './AddCaregiverModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    firstName: yup.string().required(
      t('common_error_message_required', {
        message: AddCaregiverModalEnum.FIRST_NAME,
      })
    ),
    lastName: yup.string().required(
      t('common_error_message_required', {
        message: AddCaregiverModalEnum.LAST_NAME,
      })
    ),
    email: yup.string().required(
      t('common_error_message_required', {
        message: AddCaregiverModalEnum.EMAIL,
      })
    ),
  });
};

const AddCaregiverModal = (props: Props) => {
  //#region Destructuring Props
  const { onClose, isOpen } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormAddCaregiver>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      email: EMPTY_STRING,
      firstName: EMPTY_STRING,
      lastName: EMPTY_STRING,
    },
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleClose = () => {
    onClose?.();
    reset();
  };

  const handleAddCaregiver = async (data: IFormAddCaregiver) => {
    const convertData: IFormAddCaregiver = { ...data, email: data.email?.toLowerCase() };
    const formData = await createFormData(convertData, AddCaregiverNameModalEnum.AVATAR_ACCOUNT);

    setIsLoading(true);
    dispatch(createCaregiver(formData))
      .unwrap()
      .then((res) => {
        if (!res) return;

        dispatch(caregiverActions.setRefreshList(true));
        handleClose();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  //#endregion Handle Function

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title={t('caregiver_modal_add_caregiver_title')}>
      <form id='addCaregiverModal' className={cx('addCaregiverModal')} onSubmit={handleSubmit(handleAddCaregiver)}>
        <div className={cx('contentModal')}>
          <div className={cx('bodyModal')}>
            <div className={cx('inputContent')}>
              <Controller
                name='firstName'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <BaseInput
                    label={t('caregiver_modal_add_caregiver_first_name_label')}
                    id='addCaregiverFirstName'
                    //placeholder={t('caregiver_modal_add_caregiver_first_name_placeholder')}
                    onChange={onChange}
                    value={value}
                    required
                    messageError={errors.firstName?.message}
                  />
                )}
              />

              <Controller
                name='lastName'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <BaseInput
                    label={t('caregiver_modal_add_caregiver_last_name_label')}
                    id='addCaregiverLastName'
                    //placeholder={t('caregiver_modal_add_caregiver_last_name_placeholder')}
                    onChange={onChange}
                    value={value}
                    required
                    messageError={errors.lastName?.message}
                  />
                )}
              />
            </div>

            <Controller
              name='email'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  label={t('caregiver_modal_add_caregiver_email_label')}
                  id='addCaregiverEmail'
                  type={InputTypeEnum.EMAIL}
                  //placeholder={t('caregiver_modal_add_caregiver_email_placeholder')}
                  onChange={onChange}
                  value={value}
                  messageError={errors.email?.message}
                  required
                />
              )}
            />
            {/* <div className={cx('uploadImage')}>
              <label htmlFor='uploadImage' className={cx('uploadLabel')}>
                {t('caregiver_modal_add_caregiver_upload_image_label')}
              </label>
              <label htmlFor='uploadImage' className={cx('customUploadButton')}>
                <img src={icons.commonIconUpload} alt={t('common_img_text_alt')} className={cx('uploadIcon')} />
                {fileName}
              </label>
              <input
                ref={fileInputRef}
                id='uploadImage'
                type='file'
                accept='image/*'
                className={cx('uploadInput')}
                onChange={handleFileChange}
              />
            </div> */}
            <Controller
              name={'avatarAccount'}
              control={control}
              render={({ field }) => (
                <BaseUploadImage
                  label={t('caregiver_modal_add_caregiver_upload_image_label')}
                  textBtn={t('caregiver_modal_add_caregiver_choose_file_label')}
                  height={64}
                  onChange={field.onChange}
                />
              )}
            />
          </div>

          <div className={cx('footerModal')}>
            <BaseButton text={t('common_cancel_label')} width={65} onClick={handleClose} />
            <BaseButton text={t('common_save_label')} typeStyle={ButtonTypeEnum.PRIMARY} width={80} type='submit' />
          </div>
        </div>
      </form>

      {isLoading && <Loading />}
    </Modal>
  );
};

export default AddCaregiverModal;
