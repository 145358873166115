// Libs
import classNames from 'classnames/bind';
import { useState } from 'react';
// Components, Layouts, Pages
import { ImageCircle } from '~/components';
// Others
import { ImageCircleTypeEnum, MessageTypeEnum, StorageEnum, TimeFormatEnum } from '~/utils/enum';
import { convertToRelativeTime, convertUTCTimeToLocalTime, getUserName } from '~/utils/helper';
import { IMessage } from '~/utils/interface/message';
// Styles, images, icons
import styles from './ConversationMessage.module.scss';

type Props = {
  data: IMessage;
};

const cx = classNames.bind(styles);

const ConversationMessage = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  const accountId = localStorage.getItem(StorageEnum.USER_ID);
  //#endregion Destructuring Props

  //#region Declare Hook
  const [isMyMessage] = useState<boolean>(() => {
    if (!accountId) {
      return false;
    }
    return data.senderId === accountId;
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const renderContentMessage = () => {
    switch (data.type) {
      case MessageTypeEnum.TEXT:
        return (
          <div className={cx('textMessageContent')}>
            <p>{data.content}</p>
            <p className={cx('messageTimeWrap', 'conversationMessageTime')}>
              {convertUTCTimeToLocalTime(data?.createdAt as string, TimeFormatEnum.HOUR_MINUTE_AM_PM)}
            </p>
          </div>
        );
      case MessageTypeEnum.PHOTO:
        return <img src={data?.url} alt='image message' className={cx('photoMessage')} />;
      case MessageTypeEnum.VIDEO:
        return (
          <video className={cx('videoMessage')} controls>
            <source src={data?.url} type='video/mp4' />
            {/* <p>Your browser cannot play the provided video file.</p> */}
          </video>
        );
    }
  };
  //#endregion Handle Function

  return (
    <div
      id='conversationMessageComponent'
      className={cx(isMyMessage ? 'messageIsMe' : 'message')}
      data-message-id={data.id.toString()}
    >
      <div className={cx('conversationMessageContent')}>
        <div className={cx('avatarFirstLastName')}>
          <ImageCircle
            type={data.senderAvatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
            imageUrl={data.senderAvatarUrl}
            width={40}
            height={40}
            fontSize={20}
            firstName={data.senderFirstName}
          />

          <div className={cx('firstLastName')}>
            <span className={cx('memberUserName')}>{getUserName(data?.senderFirstName, data?.senderLastName)}</span>
            <p className={cx('dateTime')}>{convertToRelativeTime(data?.createdAt)}</p>
          </div>
        </div>
        {renderContentMessage()}
      </div>
    </div>
  );
};

export default ConversationMessage;
