// Base Select
export const DEFAULT_SELECT_WIDTH = '100%';
export const DEFAULT_SELECT_HEIGHT = 34;

// Base Upload Image
export const DEFAULT_UPLOAD_IMAGE_WIDTH = '100%';
export const DEFAULT_UPLOAD_IMAGE_HEIGHT = 35;
export const DEFAULT_UPLOAD_IMAGE_LIMIT = 1;
export const DEFAULT_UPLOAD_IMAGE_LIST = [];

// Base Upload File
export const DEFAULT_UPLOAD_FILE_WIDTH = 'w-fit';
export const DEFAULT_UPLOAD_FILE_HEIGHT = 35;
export const DEFAULT_UPLOAD_FILE_LIMIT = 1;
export const DEFAULT_UPLOAD_FILE_LIST = [];

// Date Picker
export const DEFAULT_DATE_PICKER_WIDTH = '100%';
export const DEFAULT_DATE_PICKER_HEIGHT = 34;

// Time Picker
export const DEFAULT_TIME_PICKER_WIDTH = '100%';
export const DEFAULT_TIME_PICKER_HEIGHT = 34;

// Schedule Timeline
export const DEFAULT_SCHEDULE_TIMELINE_VIEWS = {
  week: 'week',
  day: 'day',
};
export const DEFAULT_SCHEDULE_TIMELINE_VISIBLE_HOUR = 8;
export const DEFAULT_SCHEDULE_TIMELINE_MIN_HOUR = 8;
export const DEFAULT_SCHEDULE_TIMELINE_MAX_HOUR = 19;

// Schedule
export const DEFAULT_SCHEDULE_EVENT_COLOR = '#DFF3E4';
export const DEFAULT_SCHEDULE_BACKGROUND_EVENT_COLOR = '#932B2B';

// Event Assignee
export const DEFAULT_ASSIGNEE_SLICE_START = 0;
export const DEFAULT_ASSIGNEE_MAX_DISPLAY_ITEM = 3;
export const DEFAULT_ASSIGNEE_NUMBER_MORE = 0;
export const DEFAULT_ASSIGNEE_NUMBER_POSITION_LEFT = 18;

// Base Filter
export const DEFAULT_FILTER_WIDTH_BTN = 67;
export const DEFAULT_FILTER_HEIGHT_BTN = 36;
