// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { CheckboxSingle } from '~/components';
// Others
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
// Styles, images, icons
import styles from './QuickLinkHomeCareClient.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const QuickLinkHomeCareClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { control } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='quickLinkHomeCareClientComponent' className={cx('container')}>
      <Controller
        name='quickLink.IADLS'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_iadls_home_care_client')}
            value={value}
            onChange={(checked: boolean) => onChange(checked)}
          />
        )}
      />

      <Controller
        name='quickLink.ADLS'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_adls')}
            value={value}
            onChange={(checked: boolean) => onChange(checked)}
          />
        )}
      />

      <Controller
        name='quickLink.CARE_GOALS'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_care_goals')}
            value={value}
            onChange={(checked: boolean) => onChange(checked)}
          />
        )}
      />

      <Controller
        name='quickLink.TRANSFERS'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_transfers')}
            value={value}
            onChange={(checked: boolean) => onChange(checked)}
          />
        )}
      />

      <Controller
        name='quickLink.MANDATE_PRIMARY_DIAGNOSIS'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_mandate_primary')}
            value={value}
            onChange={(checked: boolean) => onChange(checked)}
          />
        )}
      />
    </div>
  );
};

export default QuickLinkHomeCareClient;
