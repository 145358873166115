// Libs
import classNames from 'classnames/bind';
import ReactQuill, { Quill } from 'react-quill';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Description, Field, Label } from '@headlessui/react';
import ImageResize from 'quill-image-resize-module-react';
// Components, Layouts, Pages
// Others
import {
  ASTERISK_SYMBOL,
  DEFAULT_VALUE_EMPTY_RICH_EDITOR,
  EMPTY_STRING,
  toolbarOptionsRichEditor,
} from '~/utils/constants/common';
import { ModeTextEditorEnum, ThemeTextEditorEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './TextEditor.module.scss';
import './TextEditor.scss';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

Quill.register('modules/imageResize', ImageResize);

type Props = {
  width?: number | string;
  height?: number | string;
  label?: string;
  placeholder?: string;
  theme?: ThemeTextEditorEnum;
  mode: string;
  value?: string;
  required?: boolean;
  errorMessage?: string;
  onChange?: (value: string) => void;
};

const cx = classNames.bind(styles);

const TextEditor = (props: Props) => {
  //#region Destructuring Props
  const {
    width,
    height,
    label,
    placeholder,
    theme = ThemeTextEditorEnum.SNOW,
    mode,
    value = EMPTY_STRING,
    required = false,
    errorMessage,
    onChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const reactQuillRef = useRef<ReactQuill>(null);

  const imageHandler = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      if (input.files && input.files[0]) {
        const file = input.files[0];
        const quill = reactQuillRef.current?.getEditor();
        if (quill) {
          const reader = new FileReader();
          reader.readAsDataURL(file);

          reader.onload = () => {
            const image = reader.result;
            const range = quill.getSelection();
            if (range && image) {
              quill.insertEmbed(range.index, 'image', image);
            }
          };

          reader.onerror = (error) => {};
        }
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: toolbarOptionsRichEditor,
        handlers: {
          image: imageHandler,
        },
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize'],
      },
    }),

    [imageHandler]
  );
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [textEditorValue, setTextEditorValue] = useState<string>(EMPTY_STRING);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!value) return;

    setTextEditorValue(value);
  }, [value]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleChange = useCallback(
    (content: string) => {
      if (content === DEFAULT_VALUE_EMPTY_RICH_EDITOR) {
        onChange && onChange(EMPTY_STRING);
        return setTextEditorValue(EMPTY_STRING);
      }
      setTextEditorValue(content);
      onChange && onChange(content);
    },
    [onChange]
  );
  //#endregion Handle Function

  return (
    <div id='textEditorComponent'>
      {theme === ThemeTextEditorEnum.SNOW && (
        <Field className={cx('container')}>
          {label && (
            <Label className={cx('label')}>
              {label} {required && <span className={cx('required')}>{ASTERISK_SYMBOL}</span>}
            </Label>
          )}

          <div className={cx('quillEditorGroup')} style={{ width, height }} data-text-editor='editor'>
            <ReactQuill
              ref={reactQuillRef}
              theme={ThemeTextEditorEnum.SNOW}
              readOnly={mode === ModeTextEditorEnum.VIEW}
              modules={modules}
              value={textEditorValue}
              onChange={handleChange}
              placeholder={placeholder}
              className={cx('quill')}
              bounds={'[data-text-editor="editor"]'}
            />
          </div>

          {errorMessage && <Description className={cx('error-text')}>{errorMessage}</Description>}
        </Field>
      )}

      {theme === ThemeTextEditorEnum.BUBBLE && (
        <div className={cx('bubbleEditor')}>
          <ReactQuill ref={reactQuillRef} theme={ThemeTextEditorEnum.BUBBLE} readOnly={true} value={value} />
        </div>
      )}
    </div>
  );
};

export default memo(TextEditor);
