//Libs
import moment from 'moment';
import { toast } from 'react-toastify';
//Other
import {
  COMMA_SEPARATOR,
  COUNT_FORMATS,
  EMPTY_STRING,
  RANDOM_RANGE,
  MONTHS_THREE_CHARACTER,
  timeCloseToast,
  DEFAULT_NUMBER_ZERO,
  fileTypeIcons,
  RegExp,
  VALID_HEX,
  NUMBER_BINARY_SYSTEM_FILE,
  MS_IN_SECOND,
  SECONDS_IN_MINUTE,
  MINUTES_IN_HOUR,
  HOURS_UNIT,
  DEFAULT_NUMBER_ONE,
  SECOND,
  HOUR,
  MIN,
  TIME_CLOSE_TOAST,
  TIME_OUT_SHOW_TOAST_ERROR,
  TIME_OUT_SHOW_TOAST_SUCCESS,
  DEFAULT_QUALITY_IMAGE,
  DEFAULT_MAX_WIDTH_IMAGE,
  DEFAULT_MAX_HEIGHT_IMAGE,
  OBJECT,
} from '~/utils/constants/common';
import {
  AccountRoleCodesEnum,
  CurrencyEnum,
  DateFormatEnum,
  TYPE_FILE,
  TYPE_FILE_SIZE,
  TimeFormatEnum,
  ToastPositionTypeEnum,
  ToastTypeEnum,
} from '~/utils/enum';
import { AppDispatch } from '~/redux/store';
import { IShowToastMessage, IToastDispatch } from './interface/toast';
import { toastActions } from '~/thunks/toast/toastSlice';
import { IFileType } from '~/mockData/mockInterface';
import { IRouteModel, RouteAbsolute } from './interface/common';
import { adminRouteAbsolute, caregiverRouteAbsolute, staffRouteAbsolute } from './constants/route';
// Styles
import 'react-toastify/dist/ReactToastify.css';
import Compressor from 'compressorjs';

/**
 * Gets the initials of a person's name.
 * @param {string} firstName (optional) The person's first name.
 * @param {string} lastName (optional) The person's last name.
 * @returns {string} The initials of the name, or an empty string if no name is provided.
 */
export const getInitialsName = (firstName?: string, lastName?: string) => {
  if (!firstName && !lastName) return EMPTY_STRING;

  const firstInitial = firstName?.charAt(0).toUpperCase();
  const lastInitial = lastName?.charAt(0).toUpperCase();

  if (firstName && !lastName) {
    return `${firstInitial}`;
  }

  if (lastName && !firstName) {
    return `${lastInitial}`;
  }

  return `${firstInitial}${lastInitial}`;
};

/**
 * Gets user name.
 * @param {string} firstName (optional) The person's first name.
 * @param {string} middleName (optional) The person's middle name.
 * @param {string} lastName (optional) The person's last name.
 * @returns {string} The name of user, or an empty string if no name is provided.
 */
export const getUserName = (firstName?: string, middleName?: string, lastName?: string) => {
  const parts = [firstName, middleName, lastName].filter(Boolean);
  return parts.length ? parts.join(' ') : EMPTY_STRING;
};

/**
 * Converts a role code to its corresponding name.
 * @param {string} roleCode (optional) The code representing the user's role.
 * @returns {string} The name of the role, or an empty string if the code is unknown.
 */
export const getRoleFromCode = (roleCode?: string): string => {
  switch (roleCode) {
    case AccountRoleCodesEnum.ADMIN:
      return 'Admin';
    case AccountRoleCodesEnum.EMPLOYEE:
      return 'Staff';
    case AccountRoleCodesEnum.CAREGIVER:
      return 'Caregiver';
    case AccountRoleCodesEnum.APPLICANT:
      return 'Applicant';
    case AccountRoleCodesEnum.MANAGER:
      return 'Manager';
    default:
      return EMPTY_STRING;
  }
};

/**
 * Converts any string to a lowercase string with an uppercase first letter.
 * @param {string} str (optional) string to be converted.
 * @returns {string} lowercase strings have the first letter capitalized.
 */
export const convertToTitleCase = (str: string) => {
  const newStr = str
    ?.toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  return newStr;
};

/**
 * Convert date string to AM/PM format.
 * @param {string} time date string.
 * @param {TimeFormatEnum} formatDate - The desired time format.
 * @returns {string} Time in AM/PM format.
 */
export const convertDateToFormatTime = (time: string, formatDate: TimeFormatEnum) => {
  const timeFormat = moment(time).format(formatDate);
  return timeFormat;
};

/**
 * Convert ISO date string to the format "D MMM, YYYY".
 * @param {string} isoDate ISO date string.
 * @returns {string} Formatted date string.
 */
export const convertIsoDateToFormattedDate = (isoDate: string) => {
  const formattedDate = moment(isoDate).format(DateFormatEnum.D_MMM_YYYY);
  return formattedDate;
};

export const formatCount = (number: number) => {
  const format = COUNT_FORMATS.find((format) => number < format.limit);

  if (format) {
    number = (1000 * number) / format.limit;
    number = Math.round(number * 100) / 100;
    return number + format.letter;
  }
};

/**
 * Converts a boolean value to a string.
 * @param {boolean | undefined} value The boolean value to be converted.
 * @returns {string} The string representation of the boolean value ("true" or "false"). Returns an empty string if the value is undefined.
 */
export const convertBooleanToString = (value?: boolean): string => {
  switch (value) {
    case true:
      return 'true';
    case false:
      return 'false';
    default:
      return EMPTY_STRING;
  }
};

/**
 * Converts an enum value to a human-readable string with each word capitalized.
 * @param {string} enumValue (optional) The enum value to be converted.
 * @returns {string} The converted string with each word's first letter capitalized and separated by spaces.
 */
export const convertEnumToString = (enumValue?: string) => {
  let lowerCaseEnum = enumValue?.toLowerCase();

  let words = lowerCaseEnum?.split('_');

  let convertedEnum = words?.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

  return convertedEnum;
};

/**
 * Formats a given input time string into a human-readable date string.
 * @param {string} inputTime The input time string to be formatted.
 * @returns {string} The formatted date string in the format "MMM DD, YYYY".
 */
export const formattedTime = (inputTime: string) => {
  const dateObj = new Date(inputTime);

  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const year = String(dateObj.getFullYear());

  const month = MONTHS_THREE_CHARACTER[monthIndex];

  return `${month} ${day}${COMMA_SEPARATOR} ${year}`;
};

/**
 * Dispatches a toast notification to be shown and automatically removed after a specified time.
 * @param {AppDispatch} dispatch The dispatch function from the Redux store.
 * @param {IToastDispatch} toast The toast notification object to be dispatched.
 */
export const showToast = (dispatch: AppDispatch, toast: IToastDispatch) => {
  dispatch(toastActions.addToast(toast));
  setTimeout(() => {
    dispatch(toastActions.removeToast());
  }, timeCloseToast);
};

/**
 * Dispatches a toast notification with a specific message, type, and position.
 * @param {Object} params The parameters for showing the toast message.
 * @param {AppDispatch} params.dispatch The dispatch function from the Redux store.
 * @param {string} params.message The message to be displayed in the toast notification.
 * @param {ToastTypeEnum} [params.type=ToastTypeEnum.SUCCESS] The type of the toast notification (e.g., success, error).
 * @param {ToastPositionTypeEnum} [params.position=ToastPositionTypeEnum.CENTER] The position where the toast notification should appear.
 */
export const handleShowToastMessage = ({
  dispatch,
  message,
  type = ToastTypeEnum.SUCCESS,
  position = ToastPositionTypeEnum.CENTER,
}: IShowToastMessage) => {
  showToast(dispatch, {
    allowClose: true,
    type: type,
    message: {
      title: message,
    },
    position: position,
  });
};

/**
 * Generates a unique identifier string.
 * @param randomRange The range within which the random number is generated. Default is 1000.
 * @returns A unique identifier string composed of the current timestamp and a random number.
 */
export const generateUniqueId = (randomRange: number = RANDOM_RANGE): string => {
  return `${Date.now()}-${Math.floor(Math.random() * randomRange)}`;
};

/**
 * Converts a given time to a specified format.
 * @param time The time to be converted. It can be a string or a Date object.
 * @param format The format in which to convert the time. Default is TimeFormatEnum.HOUR_MINUTE_AM_PM.
 * @returns The time converted to the specified format as a string.
 */
export const convertTime = (time: string | Date, format?: TimeFormatEnum) => {
  const formatType = format ? format : TimeFormatEnum.HOUR_MINUTE_AM_PM;
  const formattedTime = moment(time).format(formatType);
  return formattedTime;
};

/**
 * Validate value with regex.
 * @param value is the validate value.
 * @param regex is the regex used to check.
 * @returns true if regex is satisfied and vice versa.
 */
export const validateWithRegex = (value: string, regex: RegExp): boolean => {
  return regex.test(value);
};

/**
 * Converts a UTC timestamp string to a formatted local time string.
 * @param utcTimestamp - The UTC timestamp string in ISO format (e.g., "2024-06-15T09:07:18.140Z").
 * @param localFormat - (Optional) The desired format for the local time. Defaults to the browser's locale time format. If provided, it should follow the Moment.js format syntax (https://momentjs.com/).
 * @returns The formatted local time string in the specified format (or the browser's locale format if none is provided).
 */
export const convertUTCTimeToLocalTime = (utcTimestamp: string, localFormat?: TimeFormatEnum): string => {
  const localTime = moment.utc(utcTimestamp).local();
  const formattedLocalTime = localTime.format(localFormat);

  return formattedLocalTime;
};

/**
 * Converts a date string to a relative time string (e.g., "30 minutes ago").
 * @param {string | Date} date - The date string to convert.
 * @returns {string} - The relative time string.
 */
export const convertToRelativeTime = (date: string | Date): string => {
  return moment(date).fromNow();
};

/**
 * Convert camelCase string to a human-readable format with capitalized words.
 * @param str - The camelCase string to convert.
 * @returns The converted string with spaces and capitalized words.
 */
export const convertCamelCaseToTitleCase = (str: string): string => {
  // Split the string by uppercase letters and insert a space before each uppercase letter
  const result = str.replace(/([A-Z])/g, ' $1');
  // Capitalize the first letter of each word
  return result.replace(/\b\w/g, (char) => char.toUpperCase());
};

/**
 * Check if an object is empty.
 * @param obj - The object to check.
 * @returns true if the object is empty, false otherwise.
 */
export const isEmptyObject = (obj: object): boolean => {
  return Object.keys(obj).length === DEFAULT_NUMBER_ZERO;
};

export const checkFileType = (fileType: IFileType) => {
  if (!fileType || !fileType.name) {
    return EMPTY_STRING;
  }

  const fileExtension = fileType.name.split('.').pop()?.toLowerCase();
  if (!fileExtension) {
    return EMPTY_STRING;
  }

  return fileTypeIcons[fileExtension] || fileTypeIcons.default;
};

/**
 * Validates whether the given string consists only of digits.
 * @param number - The string to validate.
 * @returns true if the string contains only digits, false otherwise.
 */
export const validateFormatNumber = (number: string) => {
  const statusValidate = RegExp.ONLY_DIGITS_REGEX.test(number);
  return statusValidate;
};

/**
 * Calculate the age based on the date of birth.
 * @param dateOfBirth - The date of birth in string format.
 * @returns The calculated age in years.
 */
export const calculateAge = (dateOfBirth: string): number => {
  const dob = moment(dateOfBirth);
  const today = moment();
  const age = today.diff(dob, 'years');

  return age;
};

/**
 * Get the absolute route paths based on the user's role.
 * @param role - The role of the user, which can be one of the predefined account role codes (e.g., 'ADMIN', 'EMPLOYEE').
 * @returns The corresponding absolute route paths for the specified role. If the role does not match any case,
 * an empty object is returned, cast to the `RouteAbsolute` type.
 */
export const getRoutesByRole = (role: string | null): RouteAbsolute => {
  switch (role) {
    case AccountRoleCodesEnum.ADMIN:
      return adminRouteAbsolute;
    case AccountRoleCodesEnum.EMPLOYEE:
      return staffRouteAbsolute;
    case AccountRoleCodesEnum.CAREGIVER:
      return caregiverRouteAbsolute;

    default:
      return {} as RouteAbsolute;
  }
};

/**
 * Adjust the opacity of a hex color.
 *
 * @param hex - The hex color code (e.g., '#FF5733').
 * @param opacity - The desired opacity level (0.0 to 1.0).
 * @returns The color in RGBA format with the specified opacity.
 */
export const hexToRgba = (hex: string, opacity: number): string => {
  const match = hex.replace('#', EMPTY_STRING).match(VALID_HEX);

  if (!match) {
    throw new Error('Invalid hex color format');
  }

  const r = parseInt(match[1], 16);
  const g = parseInt(match[2], 16);
  const b = parseInt(match[3], 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

/**
 * Format size file
 * @param sizeInBytes - Byte value type number
 * @returns File size formatted.
 */
export const formatSizeFile = (sizeInBytes?: number): string => {
  if (!sizeInBytes) return EMPTY_STRING;

  switch (true) {
    case sizeInBytes >= NUMBER_BINARY_SYSTEM_FILE * NUMBER_BINARY_SYSTEM_FILE * NUMBER_BINARY_SYSTEM_FILE:
      return `${(
        sizeInBytes /
        (NUMBER_BINARY_SYSTEM_FILE * NUMBER_BINARY_SYSTEM_FILE * NUMBER_BINARY_SYSTEM_FILE)
      ).toFixed(2)} ${TYPE_FILE_SIZE.GB}`;
    case sizeInBytes >= NUMBER_BINARY_SYSTEM_FILE * NUMBER_BINARY_SYSTEM_FILE:
      return `${(sizeInBytes / (NUMBER_BINARY_SYSTEM_FILE * NUMBER_BINARY_SYSTEM_FILE)).toFixed(2)} ${
        TYPE_FILE_SIZE.MB
      }`;
    case sizeInBytes >= NUMBER_BINARY_SYSTEM_FILE:
      return `${(sizeInBytes / NUMBER_BINARY_SYSTEM_FILE).toFixed(2)} ${TYPE_FILE_SIZE.KB}`;
    default:
      return `${sizeInBytes} ${TYPE_FILE_SIZE.B}`;
  }
};

/**
 * Format mime type file
 * @param mimeType - Params mimeType type string
 * @returns mime type formatted.
 */
export const formatMimeType = (mimeType?: string): string => {
  if (!mimeType) return EMPTY_STRING;

  switch (mimeType) {
    case 'application/pdf':
      return TYPE_FILE.PDF;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return TYPE_FILE.XLSX;
    case 'application/vnd.ms-excel':
      return TYPE_FILE.XLS;
    case 'application/vnd.ms-excel.sheet.macroEnabled.12':
      return TYPE_FILE.XLSM;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
      return TYPE_FILE.XLTX;
    case 'application/vnd.ms-excel.template.macroEnabled.12':
      return TYPE_FILE.XLTM;
    case 'application/vnd.ms-excel.addin.macroEnabled.12':
      return TYPE_FILE.XLAM;
    case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
      return TYPE_FILE.XLSB;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return TYPE_FILE.DOCX;
    case 'application/msword':
      return TYPE_FILE.DOC;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
      return TYPE_FILE.DOTX;
    case 'application/vnd.ms-word.document.macroEnabled.12':
      return TYPE_FILE.DOCM;
    case 'application/vnd.ms-word.template.macroEnabled.12':
      return TYPE_FILE.DOTM;
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return TYPE_FILE.PPTX;
    case 'application/vnd.ms-powerpoint':
      return TYPE_FILE.PPT;
    case 'application/vnd.ms-powerpoint.template.macroEnabled.12':
      return TYPE_FILE.POTM;
    case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
      return TYPE_FILE.PPTM;
    case 'application/vnd.openxmlformats-officedocument.presentationml.template':
      return TYPE_FILE.POTX;
    case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
      return TYPE_FILE.PPSX;
    case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
      return TYPE_FILE.PPSM;
    case 'image/png':
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/webp':
    case 'image/svg':
    case 'image/gif':
      return TYPE_FILE.IMAGE;
    case 'audio/mpeg':
    case 'audio/wav':
    case 'audio/ogg':
    case 'audio/acc':
    case 'audio/flac':
    case 'audio/webm':
    case 'audio/x-wav':
    case 'audio/midi':
    case 'audio/opus':
      return TYPE_FILE.AUDIO;
    case 'video/mp4':
    case 'video/webm':
    case 'video/ogg':
    case 'video/x-msvideo':
    case 'video/quicktime':
    case 'video/x-flv':
    case 'video/mpeg':
    case 'video/3gpp':
    case 'video/x-matroska':
    case 'video/h264':
      return TYPE_FILE.VIDEO;
    default:
      return TYPE_FILE.FOLDER;
  }
};

/**
 * Converts the difference between two date strings to hours.
 * @param {string} startTime - The start time in ISO string format.
 * @param {string} endTime - The end time in ISO string format.
 * @returns {string} - The difference in hours with 'hours' as the unit.
 */
export const convertToHours = (startTime: string, endTime: string): string => {
  if (!endTime || !startTime) return EMPTY_STRING;

  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  const differenceInMs = endDate.getTime() - startDate.getTime();
  const hours = (differenceInMs / (MS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_IN_HOUR)).toFixed(1);

  return `${hours} ${HOURS_UNIT}`;
};

/**
 * Convert duration to hour and mins
 * @param seconds is duration
 * @returns the format duration
 */

export const convertSeconds = (seconds: number) => {
  const duration = moment.duration(seconds, 'seconds');
  const hours = duration.hours();
  const minutes = duration.minutes();

  if (hours > DEFAULT_NUMBER_ZERO) {
    return `${hours} ${HOUR}${hours > DEFAULT_NUMBER_ONE ? SECOND : ''} ${minutes} ${MIN}${
      minutes > DEFAULT_NUMBER_ONE ? SECOND : ''
    }`;
  } else {
    return `${minutes} ${MIN}${minutes > DEFAULT_NUMBER_ONE ? SECOND : ''}`;
  }
};

/**
 * Gets the user's current time zone identifier.
 * @returns {string} The time zone identifier in the IANA Time Zone Database format.
 */
export const getTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * Compares two objects and returns a partial object containing the updated values.
 * @param originalData - The original data object.
 * @param editedData - The edited data object.
 * @returns An object containing keys from editedData with values that differ from originalData.
 */
export const compareDataUpdate = (
  originalData: { [key: string]: any },
  editedData: { [key: string]: any }
): { [key: string]: any } => {
  let result: { [key: string]: any } = {};

  Object.entries(originalData).forEach(([key, value]) => {
    if (editedData.hasOwnProperty(key) && JSON.stringify(value) !== JSON.stringify(editedData[key])) {
      result[key] = editedData[key];
    }
  });

  return result;
};

export const convertTimeToAmPm = (time: string | Date | undefined): string => {
  if (!time) return EMPTY_STRING;
  return moment(time).format(TimeFormatEnum.HOUR_MINUTE_AM_PM);
};

/**
 * Handle show toast message.
 * @param type is option of reducer.
 * @param message is option of reducer.
 * @returns modal toast by status
 */
export const customToast = (type: ToastTypeEnum, message: string) => {
  switch (type) {
    case ToastTypeEnum.ERROR:
      setTimeout(() => {
        toast.error(message, {
          autoClose: TIME_CLOSE_TOAST,
        });
      }, TIME_OUT_SHOW_TOAST_ERROR);
      break;
    case ToastTypeEnum.SUCCESS:
      setTimeout(() => {
        toast.success(message, {
          autoClose: TIME_CLOSE_TOAST,
        });
      }, TIME_OUT_SHOW_TOAST_SUCCESS);
      break;
    case ToastTypeEnum.WARNING:
      toast.warning(message, {
        autoClose: TIME_CLOSE_TOAST,
      });
      break;
    default:
      toast(message, {
        autoClose: TIME_CLOSE_TOAST,
      });
      break;
  }
};

/**
 * Convert time to date ios type
 * @param time is time value string
 * @returns date ios string
 */
export const convertToISO = (time: string): string => {
  if (RegExp.ISO_8601.test(time) && !isNaN(Date.parse(time))) {
    return time;
  }

  const today = new Date();
  const [hours, minutes] = time.split(':').map(Number);

  today.setHours(hours);
  today.setMinutes(minutes);
  today.setSeconds(0);
  today.setMilliseconds(0);

  return today.toISOString();
};

export const convertDurationToHoursAndMinutes = (totalMinutes?: number) => {
  if (!totalMinutes) return `${DEFAULT_NUMBER_ZERO} min`;
  if (totalMinutes < 60) return `${totalMinutes} min`;

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes - hours * 60;

  return `${hours} hr` + (minutes ? ` ${minutes} min` : '');
};

export const formatMinutesToHourMinute = (totalMinutes?: string) => {
  const duration = moment.duration(totalMinutes, 'minutes');
  const hours = Math.floor(duration.asHours()).toString().padStart(2, '0');
  const minutes = duration.minutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

/**
 * Creates a FormData object from the provided data, compressing image files if needed.
 * @param {Record<string, any>} data - The data object
 * @param {string} imageKey - The key in the data object that contains the image file to be compressed.
 * @param quality is image quality
 * @returns {Promise<FormData>}
 */
export const createFormData = async (
  data: Record<string, any>,
  imageKey: string,
  quality: number = DEFAULT_QUALITY_IMAGE
): Promise<FormData> => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    if (value === undefined || value === null) continue;

    if (key === imageKey && Array.isArray(value) && value.length > DEFAULT_NUMBER_ZERO) {
      for (const item of value) {
        if (item.file) {
          const compressedFile = await new Promise<File | Blob>((resolve, reject) => {
            new Compressor(item.file, {
              quality,
              maxWidth: DEFAULT_MAX_WIDTH_IMAGE,
              maxHeight: DEFAULT_MAX_HEIGHT_IMAGE,
              success(result) {
                resolve(result);
              },
              error(err) {
                reject(err);
              },
            });
          });

          formData.append(key, compressedFile);
        }
      }
    } else if (Array.isArray(value) || typeof value === OBJECT) {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value as string);
    }
  }

  return formData;
};

/**
 * Function to convert minute to hh:mm.
 * @param minutes is minutes.
 * @returns hh:mm ex: 02:15.
 */
export const convertMinuteToHours = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainMinutes = minutes % 60;

  return `${hours.toString().padStart(2, '0')}:${remainMinutes.toString().padStart(2, '0')}`;
};

/**
 * Function to convert a date string to the day of the week.
 * @param dateString - The date string in ISO format (e.g., '2024-08-03T06:00:00Z').
 * @param locale - The locale string to format the day of the week (default is 'en-US').
 * @returns The day of the week in full (e.g., 'Saturday').
 */
export const convertDateToDayOfWeek = (dateString: string, locale: string = 'en-US'): string => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date);
};

/**
 * Checks if a route has an active child route based on the provided location.pathname.
 * @param route The route object to check.
 * @param locationPathname The pathname of the current location.
 * @returns True if the route has an active child route, false otherwise.
 */
export const hasActiveChild = (route: IRouteModel, locationPathname: string): boolean => {
  return (
    route.children?.some(
      (child) =>
        child.path === locationPathname ||
        isNestedRoute(child.path, locationPathname) ||
        hasActiveChild(child, locationPathname)
    ) || route.path === locationPathname
  );
};

/**
 * Determines if the current path is a nested route of the parent path.
 * @param parentPath The parent route path to check against.
 * @param currentPath The current route path to be evaluated.
 * @returns True if the current path is a nested route of the parent path and not exactly the same as the parent path, false otherwise.
 */
export const isNestedRoute = (parentPath: string, currentPath: string): boolean => {
  return currentPath.startsWith(parentPath) && currentPath !== parentPath;
};

/**
 * Time format from 24 hour to other time formats.
 * @param time is the time that needs to be formatted.
 * @param format is the format that needs to be formatted.
 * @param inputFormat is the type of the passed value.
 * @returns time has been formatted.
 */
export const formatTime = (time: string, format: TimeFormatEnum, inputFormat?: TimeFormatEnum): string => {
  if (inputFormat) {
    return moment(time, inputFormat).format(format);
  }

  return moment(time).format(format);
};

/**
 * format numbers into currency.
 *
 * @param currency Currency unit that in CurrencyEnum
 * @param value The number value needs to be formatted
 * @returns The string value has been formatted as currency
 */

export const formatCurrency = (currency: CurrencyEnum, value: number) => {
  if (!value) return 0;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(value);
};
