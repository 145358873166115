// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { InputTypeEnum, InputTypeStyleEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './InputSearch.module.scss';
import { icons } from '~/assets';

type Props = {
  width?: string | number;
  height?: string | number;
  fontSize?: string | number;
  placeholder?: string;
  typeStyle?: InputTypeStyleEnum;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
};

const cx = classNames.bind(styles);

const InputSearch = (props: Props) => {
  //#region Destructuring Props
  const { width = '100%', height, placeholder, fontSize, typeStyle, onChange, value } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
  };
  //#endregion Handle Function

  return (
    <div id='inputSearchComponent'>
      <div style={{ width, height }} className={cx('inputSearchWrapper', typeStyle)}>
        <input
          style={{ width, height, fontSize }}
          type={InputTypeEnum.TEXT}
          placeholder={placeholder}
          defaultValue={value}
          onChange={handleChange}
          className={cx('inputSearch', typeStyle)}
        />
        <div className={cx('iconInputSearch', typeStyle)}>
          {typeStyle ? (
            <img src={icons.commonIconSearchService} alt={t('common_img_text_alt')} />
          ) : (
            <img src={icons.commonIconSearch} alt={t('common_img_text_alt')} />
          )}
        </div>
      </div>
    </div>
  );
};

export default InputSearch;
