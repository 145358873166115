// Libs
import classNames from 'classnames/bind';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { ModalUnderDevelopment, ButtonThreeDot } from '~/components';
// Others
import { TEAL_24604D } from '~/utils/constants/color';
import { CommonIconClose, CommonIconPhoneNumber, CommonIconSearch } from '~/assets/svgComponents';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './ConversationActions.module.scss';

type Props = {
  onSearch: (searchKey: string) => void;
  onCall: () => void;
};

const cx = classNames.bind(styles);

const ConversationActions = (props: Props) => {
  //#region Destructuring Props
  const { onSearch, onCall } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const searchRef = useRef<HTMLButtonElement>(null);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isOpen, setIsOpen] = useState<boolean>(false); // Update Later
  const [isOpenSearch, setIsOpenSearch] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleClickThreeDot = () => {
    // Handle Update Later
    setIsOpen(!isOpen);
  };

  const handleSearch = () => {
    if (isOpenSearch) {
      onSearch(EMPTY_STRING);
      setSearchKey(EMPTY_STRING);
    }
    setIsOpenSearch(!isOpenSearch);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
      setIsOpenSearch(false);
      onSearch(EMPTY_STRING);
      setSearchKey(EMPTY_STRING);
    }
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchKey(value);
    onSearch(value);
  };
  //#endregion Handle Function

  return (
    <div id='conversationActions' className={cx('conversationActions')}>
      <button className={cx('btnActionSearch')} ref={searchRef}>
        <input
          type='text'
          value={searchKey}
          className={cx('inputSearch', isOpenSearch && 'open')}
          placeholder={t('message_placeholder_search_message')}
          onChange={handleChangeInput}
        />
        <div className={cx('icon')} onClick={handleSearch}>
          {isOpenSearch ? (
            <CommonIconClose width={14} height={14} fill={TEAL_24604D} />
          ) : (
            <CommonIconSearch width={20} height={20} />
          )}
        </div>
      </button>

      <button className={cx('btnAction')} onClick={handleClickThreeDot}>
        <CommonIconPhoneNumber width={20} height={20} />
      </button>

      <ButtonThreeDot width={40} height={40} borderRadius={100} />
      {isOpen && <ModalUnderDevelopment onClose={handleClickThreeDot} />}
    </div>
  );
};

export default ConversationActions;
