// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseButton } from '~/components';
// Others
import { ButtonTypeEnum, StatusTimeClockTypeEnum, TimeFormatEnum } from '~/utils/enum';
import { convertTime } from '~/utils/helper';
import { ICurrentTimeCLock } from '~/utils/interface/timeClock';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './CurrentTask.module.scss';
import { icons } from '~/assets';

type Props = {
  data: ICurrentTimeCLock;
  onView: (id: string) => void;
  onBreak: () => void;
  onCheckOut: () => void;
  onContinue: () => void;
};

const cx = classNames.bind(styles);

const CurrentTask = (props: Props) => {
  //#region Destructuring Props
  const { data, onView, onBreak, onCheckOut, onContinue } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='currentTaskComponent' className={cx('currentTaskComponent')}>
      <div className={cx('currentTaskGroup')}>
        <h3 className={cx('title')}>{t('time_clock_page_current_task_title')}</h3>

        <p className={cx('taskName')}>{data.task?.name || EMPTY_STRING}</p>
      </div>
      <div className={cx('durationGroup')}>
        <h3 className={cx('title')}>{t('time_clock_page_duration_title')}</h3>

        <div className={cx('durationTime')}>
          {data.task?.startTime && data.task?.endTime ? (
            <>
              {`${convertTime(data.task.startTime, TimeFormatEnum.HOUR_MINUTE_AM_PM)} -  ${convertTime(
                data.task.endTime,
                TimeFormatEnum.HOUR_MINUTE_AM_PM
              )}`}
            </>
          ) : (
            EMPTY_STRING
          )}
        </div>
      </div>
      <div className={cx('actionCurrentTask')}>
        <BaseButton
          iconWidthHeight={14}
          iconLeft={icons.commonIconView}
          text={t('common_action_view')}
          height={36}
          onClick={() => onView(data.id)}
        />

        {data.status === StatusTimeClockTypeEnum.IN_PROGRESS ? (
          <>
            <BaseButton
              iconWidthHeight={14}
              iconLeft={icons.commonIconBreak}
              text={t('time_clock_page_current_task_break_action')}
              height={36}
              onClick={onBreak}
              typeStyle={ButtonTypeEnum.OUT_LINE}
            />

            <BaseButton
              iconWidthHeight={14}
              text={t('time_clock_page_current_task_check_out')}
              height={36}
              typeStyle={ButtonTypeEnum.PRIMARY}
              onClick={onCheckOut}
            />
          </>
        ) : (
          <>
            {/* <BaseButton
              iconWidthHeight={14}
              text={t('modal_add_time_clock_continue_btn')}
              height={36}
              typeStyle={ButtonTypeEnum.PRIMARY}
              onClick={onContinue}
            /> */}
            <BaseButton
              iconWidthHeight={14}
              text={t('modal_add_time_clock_end_break_btn')}
              height={36}
              typeStyle={ButtonTypeEnum.PRIMARY}
              onClick={onContinue}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default CurrentTask;
