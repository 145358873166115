// Libs
import classNames from 'classnames/bind';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import { useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import {
  Tab,
  DailyCare,
  SkillTab,
  Schedule,
  ImageCircle,
  FilesTab,
  PayrollTab,
  CheckTab,
  TrainingTab,
  AddFiles,
  ModalUnderDevelopment,
  DetailActivities,
} from '~/components';
// Others
import { EMPTY_STRING, QUERY_KEY_TAB, caregiverDetailTabs } from '~/utils/constants/common';
import { AccountRoleCodesEnum, ImageCircleTypeEnum, StorageEnum, TabQueryValueEnum } from '~/utils/enum';
import { useAppDispatch } from '~/redux/hooks';
import { getDetailCaregiver } from '~/thunks/caregiver/caregiverThunk';
import { LoadingData } from '~/context';
import { ICaregiver } from '~/utils/interface/caregiver';
import { getRoutesByRole, getUserName } from '~/utils/helper';
import { adminRouteAbsolute, staffRouteAbsolute } from '~/utils/constants/route';
import { ICreateConversationReq } from '~/utils/interface/conversation';
import { createConversation } from '~/thunks/conversation/conversationThunk';
// Styles, images, icons
import styles from './CaregiverDetail.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const CaregiverDetail = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get(QUERY_KEY_TAB);
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  const role = localStorage.getItem(StorageEnum.ROLE);
  const navigate = useNavigate();
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const { caregiverId } = useParams();
  const [caregiverDetail, setCaregiverDetail] = useState<ICaregiver>();
  const [isOpenUnderDevelopmentModal, setIsOpenUnderDevelopmentModal] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetDetailCaregiver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const renderTabContent = useMemo(() => {
    const tabToRender = currentTab || TabQueryValueEnum.DAILY_CARE;
    switch (tabToRender) {
      case TabQueryValueEnum.DAILY_CARE:
        return <DailyCare />;
      case TabQueryValueEnum.SCHEDULE:
        return <Schedule mode='my' userId={caregiverId} />;
      case TabQueryValueEnum.ACTIVITIES:
        return <DetailActivities />;
      case TabQueryValueEnum.FILES:
        return <FilesTab />;
      case TabQueryValueEnum.SKILL:
        return <SkillTab />;
      case TabQueryValueEnum.PAYROLL:
        return <PayrollTab />;
      case TabQueryValueEnum.CHECK:
        return <CheckTab />;
      case TabQueryValueEnum.TRAINING:
        return <TrainingTab />;

      default:
        // Handle Return Tab Later
        return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
    }
  }, [currentTab, caregiverId, t]);

  const handleGetDetailCaregiver = () => {
    if (!caregiverId) return;
    loading?.show();

    dispatch(getDetailCaregiver(caregiverId))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        setCaregiverDetail(res.data);
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleBack = () => {
    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(adminRouteAbsolute.caregiverShowAll);
        break;
      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(staffRouteAbsolute.caregiverShowAll);
    }
  };

  const handleMessage = () => {
    if (!caregiverDetail || !caregiverDetail.id) return;

    const payload: ICreateConversationReq = {
      accountIds: [Number(caregiverDetail.id)],
    };

    loading?.show();

    dispatch(createConversation(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { conversationId } = res.data;

        const params = new URLSearchParams({ conversationId: conversationId });
        navigate(`${getRoutesByRole(role)?.messages ?? EMPTY_STRING}?${params.toString()}`);
      })
      .catch((error) => {})
      .finally(() => loading?.hide());
  };

  const handleCall = () => {
    //ToDo : @khai handle logic Call
    setIsOpenUnderDevelopmentModal(!isOpenUnderDevelopmentModal);
  };

  const handleDocument = () => {
    //ToDo : @khai handle logic Document
    setIsOpenUnderDevelopmentModal(!isOpenUnderDevelopmentModal);
  };

  const handleUnderDevelopmentModal = () => {
    setIsOpenUnderDevelopmentModal(!isOpenUnderDevelopmentModal);
  };
  //#endregion Handle Function

  return (
    <div id='caregiverDetailPage' className={cx('container')}>
      <div className={cx('mainBody')}>
        <span className={cx('backTag')}>
          <img src={icons.commonIconPrev} alt={t('common_img_text_alt')} />
          <span className={cx('textBack')} onClick={handleBack}>
            {t('common_text_back')}
          </span>
        </span>

        <section className={cx('headGroup')}>
          <div className={cx('basicInfoGroup')}>
            <ImageCircle
              imageUrl={caregiverDetail?.avatarUrl}
              firstName={caregiverDetail?.firstName}
              lastName={caregiverDetail?.lastName}
              type={caregiverDetail?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
              height={91}
              width={91}
            />

            <div className={cx('basicInfo')}>
              <div className={cx('nameGroup')}>
                <div>{getUserName(caregiverDetail?.firstName, caregiverDetail?.lastName)}</div>
                <div className={cx('icons')}>
                  <span className={cx('iconMessage')}>
                    <img src={icons.commonIconNewMessageGray} alt={t('common_img_text_alt')} onClick={handleMessage} />
                  </span>
                  <span>
                    <img src={icons.commonIconPhoneGray} alt={t('common_img_text_alt')} onClick={handleCall} />
                  </span>
                  <span>
                    <img src={icons.commonIconDocumentGray} alt={t('common_img_text_alt')} onClick={handleDocument} />
                  </span>
                </div>
              </div>

              <div className={cx('jobRoleGroup')}>{EMPTY_STRING}</div>
            </div>
          </div>

          <div className={cx('navTabGroup')}>
            <Tab items={caregiverDetailTabs} paddingBottom={30} />
          </div>
        </section>

        <section className={cx('tabContent')}>{renderTabContent}</section>
      </div>

      {currentTab === TabQueryValueEnum.FILES && <AddFiles accountId={caregiverDetail?.id} />}
      {isOpenUnderDevelopmentModal && <ModalUnderDevelopment onClose={handleUnderDevelopmentModal} />}
    </div>
  );
};

export default CaregiverDetail;
