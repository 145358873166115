// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
// Components, Layouts, Pages
import { BaseDatePicker, BaseSelect, BaseSelectMultiple, BaseTextarea } from '~/components';
// Others
import {
  clientLeftAloneOptions,
  codeStatusOptions,
  desiredOutcomeOptions,
  outingsAllowedOptions,
  photosAllowedOptions,
} from '~/mockData';
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './CareNeedsDayCenterClient.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CareNeedsDayCenterClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='careNeedsDayCenterClient' className={cx('container')}>
      <div className={cx('viewGroupInput')}>
        <Controller
          name='careNeed.startDate'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseDatePicker
              label={t('care_needs_day_center_client_start_date_title')}
              placeholderText={t('common_placeholder_select')}
              value={value || EMPTY_STRING}
              onDateSelected={onChange}
              errorMessage={errors.careNeed?.startDate?.message}
            />
          )}
        />

        <Controller
          name='careNeed.beLeftAlone'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              label={t('care_needs_day_center_client_be_left_alone_title')}
              options={clientLeftAloneOptions}
              placeholder={t('common_placeholder_select')}
              value={value || EMPTY_STRING}
              onChange={({ value }) => {
                onChange(value);
              }}
              errorMessage={errors.careNeed?.beLeftAlone?.message}
            />
          )}
        />

        <Controller
          name='careNeed.outingsAllowed'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              options={outingsAllowedOptions}
              placeholder={t('common_placeholder_select')}
              label={t('care_needs_day_center_client_outings_allowed_title')}
              value={value || EMPTY_STRING}
              onChange={({ value }) => {
                onChange(value);
              }}
              errorMessage={errors.careNeed?.outingsAllowed?.message}
            />
          )}
        />

        <Controller
          name='careNeed.photosAllowed'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              options={photosAllowedOptions}
              placeholder={t('common_placeholder_select')}
              label={t('care_needs_day_center_client_photos_allowed_title')}
              value={value || EMPTY_STRING}
              onChange={({ value }) => {
                onChange(value);
              }}
              errorMessage={errors.careNeed?.photosAllowed?.message}
            />
          )}
        />

        <Controller
          name='careNeed.codeStatus'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              options={codeStatusOptions}
              placeholder={t('common_placeholder_select')}
              label={t('care_needs_day_center_client_code_status_title')}
              value={value || EMPTY_STRING}
              onChange={({ value }) => {
                onChange(value);
              }}
              errorMessage={errors.careNeed?.codeStatus?.message}
            />
          )}
        />

        <Controller
          name='careNeed.desiredOutcome'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectMultiple
              options={desiredOutcomeOptions}
              placeholder={t('common_placeholder_select')}
              label={t('care_needs_day_center_client_desired_outcome_title')}
              value={value || []}
              onChange={onChange}
              errorMessage={errors.careNeed?.desiredOutcome?.message}
            />
          )}
        />
      </div>

      <Controller
        name='careNeed.goals'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseTextarea
            label={t('care_needs_day_center_client_goals_title')}
            height={90}
            id='careNeedGoals'
            value={value || EMPTY_STRING}
            onChange={onChange}
            messageError={errors.careNeed?.goals?.message}
          />
        )}
      />
    </div>
  );
};

export default CareNeedsDayCenterClient;
