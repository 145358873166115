// Libs
// Components, Layouts, Pages
// Others
import { GREEN_PEA700 } from '~/utils/constants/color';
import { IconSvgProps } from '~/utils/interface/common';
// Styles, images, icons

const CommonIconEmail = (props: IconSvgProps) => {
  //#region Destructuring Props
  const { width = 24, height = 24, strokePath = GREEN_PEA700, fill = 'none' } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <svg width={width} height={height} viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.79265 8.64012L8.93475 10.1705C9.57201 10.6258 10.4281 10.6258 11.0653 10.1705L13.2074 8.64012M2.40453 12.6411C1.97632 10.9166 1.97632 9.11347 2.40453 7.38897C2.96508 5.13154 4.75505 3.38613 7.02551 2.88303L7.40373 2.79922C9.11388 2.42028 10.8862 2.42028 12.5964 2.79922L12.9746 2.88303C15.245 3.38613 17.035 5.13155 17.5955 7.38898C18.0238 9.11347 18.0238 10.9166 17.5955 12.6411C17.035 14.8985 15.245 16.6439 12.9746 17.147L12.5964 17.2308C10.8862 17.6097 9.11387 17.6097 7.40373 17.2308L7.0255 17.147C4.75505 16.6439 2.96508 14.8985 2.40453 12.6411Z'
        stroke={strokePath}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default CommonIconEmail;
