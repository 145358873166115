// Libs
import * as yup from 'yup';
import { TFunction } from 'i18next';
// Components, Layouts, Pages
import {
  DocumentsTab,
  CarePlanAllergies,
  CarePlanExercise,
  CarePlanDemographic,
  CarePlanDailyRoutine,
  CarePlanMeals,
  MentalBehaviorCondition,
  MedicalCondition,
  CareNeeds,
  CarePlanElimination,
  Ambulation,
  CarePlanMatchCriteria,
  CarePlanAdls,
  CarePlanIadls,
  CarePlanIcdNineCodes,
  CarePlanIcdTenCodes,
  CarePlanUbFourCodes,
  CarePlanTransfers,
  CarePlanBathingGrooming,
  CarePlanDriving,
  CarePlanSleepPatterns,
  CarePlanEquipment,
  CarePlanPetCare,
  CarePlanMedications,
} from '~/components';
// Others
import { IFormCarePlan, IQuickLinkItem } from '~/utils/interface/crm/carePlan';
// Care plan schema
export const carePlanSchema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      quickLink: yup
        .object()
        .shape({
          CARE_NEEDS: yup.boolean().optional(),
          DEMOGRAPHICS: yup.boolean().optional(),
          MEDICAL_CONDITIONS: yup.boolean().optional(),
          METAL_BEHAVIOR_CONDITIONS: yup.boolean().optional(),
          ALLERGIES: yup.boolean().optional(),
          ELIMINATION: yup.boolean().optional(),
          AMBULATION: yup.boolean().optional(),
          MEALS: yup.boolean().optional(),
          EXERCISE: yup.boolean().optional(),
          DAILY_ROUTINE: yup.boolean().optional(),
        })
        .optional(),
      careNeed: yup
        .object()
        .shape({
          initialContact: yup.string().nullable().optional(),
          startDate: yup.string().nullable().optional(),
          prognosis: yup.string().nullable().optional(),
          careGoals: yup.string().nullable().optional(),
          proposedSchedule: yup.string().nullable().optional(),
          careCoordinationNotes: yup.string().nullable().optional(),
        })
        .nullable()
        .optional(),
      demographic: yup
        .object()
        .shape({
          dateOfBirth: yup.string().nullable().optional(),
          height: yup.string().nullable().optional(),
          weight: yup.string().nullable().optional(),
          dateOfDeath: yup.string().nullable().optional(),
          gender: yup.string().nullable().optional(),
          maritalStatus: yup.string().nullable().optional(),
          spouseName: yup.string().nullable().optional(),
          livesWith: yup.array().of(yup.string().required()).nullable().optional(),
          religion: yup.string().nullable().optional(),
          attendsServices: yup.string().nullable().optional(),
          drn: yup.string().nullable().optional(),
          languages: yup.array().of(yup.string().required()).nullable().optional(),
          pastProfession: yup.string().nullable().optional(),
        })
        .nullable()
        .optional(),
      medicalCondition: yup
        .object()
        .shape({
          chronicAcuteConditions: yup.string().nullable().optional(),
          hearing: yup.array().of(yup.string().required()).nullable().optional(),
          speech: yup.array().of(yup.string().required()).nullable().optional(),
          vision: yup.array().of(yup.string().required()).nullable().optional(),
          swallowing: yup.array().of(yup.string().required()).nullable().optional(),
          other: yup.array().of(yup.string().required()).nullable().optional(),
          functionalLimitations: yup.array().of(yup.string().required()).nullable().optional(),
        })
        .nullable()
        .optional(),
      mentalBehaviorCondition: yup
        .object()
        .shape({
          diagnosedDisordersMedications: yup.string().nullable().optional(),
          mentalHealthIssues: yup.array().of(yup.string().required()).nullable().optional(),
          alone: yup.string().nullable().optional(),
          wanderer: yup.string().nullable().optional(),
          dementia: yup.string().nullable().optional(),
          symptoms: yup.array().of(yup.string().required()).nullable().optional(),
          triggers: yup.string().nullable().optional(),
        })
        .nullable()
        .optional(),
      allergies: yup
        .object()
        .shape({
          allergies: yup.array().of(yup.string().required()).nullable().optional(),
          notes: yup.string().nullable().optional(),
        })
        .nullable()
        .optional(),
      elimination: yup
        .object()
        .shape({
          incontinence: yup.array().of(yup.string().required()).nullable().optional(),
          issues: yup.array().of(yup.string().required()).nullable().optional(),
          wearsBriefs: yup.string().nullable().optional(),
          notes: yup.string().nullable().optional(),
        })
        .nullable()
        .optional(),
      ambulation: yup
        .object()
        .shape({
          aids: yup.array().of(yup.string().required()).nullable().optional(),
          fallRisk: yup.string().nullable().optional(),
          armsHands: yup.array().of(yup.string().required()).nullable().optional(),
          notes: yup.string().nullable().optional(),
        })
        .nullable()
        .optional(),
      meal: yup
        .object()
        .shape({
          assistance: yup.array().of(yup.string().required()).nullable().optional(),
          appetite: yup.string().nullable().optional(),
          diet: yup.array().of(yup.string().required()).nullable().optional(),
          shoppingBy: yup.string().nullable().optional(),
          specialDiet: yup.string().nullable().optional(),
          times: yup
            .array()
            .of(
              yup
                .object()
                .shape({
                  meal: yup.string().optional(),
                  time: yup.string().optional(),
                })
                .required()
            )
            .nullable()
            .optional(),
          other: yup.array().of(yup.string().required()).nullable().optional(),
          favoriteBreakfastFoods: yup.string().nullable().optional(),
          favoriteLunchFoods: yup.string().nullable().optional(),
          favoriteSnackFoods: yup.string().nullable().optional(),
          favoriteDinnerFoods: yup.string().nullable().optional(),
          notes: yup.string().nullable().optional(),
        })
        .nullable()
        .optional(),
      exercise: yup
        .object()
        .shape({
          importance: yup.number().nullable().optional(),
          isRegimen: yup.boolean().optional(),
          isEncourageExercises: yup.boolean().optional(),
          notes: yup.string().nullable().optional(),
        })
        .nullable()
        .optional(),
      dailyRoutine: yup
        .object()
        .shape({
          activitiesPermitted: yup.array().of(yup.string().required()).nullable().optional(),
          morningDailyRoutine: yup.string().nullable().optional(),
          afternoonDailyRoutine: yup.string().nullable().optional(),
          eveningDailyRoutine: yup.string().nullable().optional(),
          activitiesAtHome: yup.string().nullable().optional(),
          activitiesAwayFromHome: yup.string().nullable().optional(),
          favoriteRestaurantsShops: yup.string().nullable().optional(),
          relationship: yup.string().nullable().optional(),
        })
        .nullable()
        .optional(),
      matchCriteria: yup.object().shape({}).nullable().optional(),
      adls: yup.object().shape({}).nullable().optional(),
      iadls: yup.object().shape({}).nullable().optional(),
      icd9Codes: yup.object().shape({}).nullable().optional(),
      icd10Codes: yup.object().shape({}).nullable().optional(),
      ub04Codes: yup.object().shape({}).nullable().optional(),
      medications: yup.object().shape({}).nullable().optional(),
      transfers: yup.object().shape({}).nullable().optional(),
      bathingGrooming: yup.object().shape({}).nullable().optional(),
      driving: yup.object().shape({}).nullable().optional(),
      sleepPatterns: yup.object().shape({}).nullable().optional(),
      equipment: yup.object().shape({}).nullable().optional(),
      petCare: yup.object().shape({}).nullable().optional(),
    })
    .required();
};

export const carePlanDefaultValues: IFormCarePlan = {
  quickLink: {
    CARE_NEEDS: false,
    DEMOGRAPHICS: false,
    MEDICAL_CONDITIONS: false,
    MENTAL_BEHAVIOR_CONDITIONS: false,
    ALLERGIES: false,
    ELIMINATION: false,
    AMBULATION: false,
    MEALS: false,
    EXERCISE: false,
    DAILY_ROUTINE: false,
    MATCH_CRITERIA: false,
    ADLS: false,
    IADLS: false,
    ICD9_CODES: false,
    ICD10_CODES: false,
    UB04_CODES: false,
    MEDICATIONS: false,
    TRANSFERS: false,
    BATHING_GROOMING: false,
    DRIVING: false,
    SLEEP_PATTERNS: false,
    EQUIPMENT: false,
    PET_CARE: false,
  },
  careNeed: {
    initialContact: null,
    startDate: null,
    prognosis: null,
    careGoals: null,
    proposedSchedule: null,
    careCoordinationNotes: null,
  },
  demographic: {
    dateOfBirth: null,
    height: null,
    weight: null,
    dateOfDeath: null,
    gender: null,
    maritalStatus: null,
    spouseName: null,
    livesWith: null,
    religion: null,
    attendsServices: null,
    dnr: null,
    languages: null,
    pastProfession: null,
  },
  medicalCondition: {
    chronicAcuteConditions: null,
    hearing: null,
    speech: null,
    vision: null,
    swallowing: null,
    other: null,
    functionalLimitations: null,
  },
  mentalBehaviorCondition: {
    diagnosedDisordersMedications: null,
    mentalHealthIssues: null,
    alone: null,
    wanderer: null,
    dementia: null,
    symptoms: null,
    triggers: null,
  },
  allergies: {
    allergies: null,
    notes: null,
  },
  elimination: {
    incontinence: null,
    issues: null,
    wearsBriefs: null,
    notes: null,
  },
  ambulation: {
    aids: null,
    fallRisk: null,
    armsHands: null,
    notes: null,
  },
  meal: {
    assistance: null,
    appetite: null,
    diet: null,
    shoppingBy: null,
    specialDiet: null,
    times: null,
    other: null,
    favoriteBreakfastFoods: null,
    favoriteLunchFoods: null,
    favoriteSnackFoods: null,
    favoriteDinnerFoods: null,
    notes: null,
  },
  exercise: {
    importance: null,
    isSpecificRegimen: false,
    isEncourageExercises: false,
    notes: null,
  },
  dailyRoutine: {
    activitiesPermitted: null,
    morningDailyRoutine: null,
    afternoonDailyRoutine: null,
    eveningDailyRoutine: null,
    activitiesAtHome: null,
    activitiesAwayFromHome: null,
    favoriteRestaurantsShops: null,
    relationship: null,
  },
};

export const quickLinkItems: IQuickLinkItem[] = [
  {
    key: 'MATCH_CRITERIA',
    defaultValue: 'matchCriteria',
    title: 'care_plan_quick_link_title_match_criteria',
    // component: CarePlanMatchCriteria,
  },
  {
    key: 'CARE_NEEDS',
    defaultValue: 'careNeed',
    title: 'care_plan_quick_link_title_care_needs',
    component: CareNeeds,
  },
  {
    key: 'DEMOGRAPHICS',
    defaultValue: 'demographic',
    title: 'care_plan_quick_link_title_demographics',
    component: CarePlanDemographic,
  },
  {
    key: 'ADLS',
    defaultValue: 'adls',
    title: 'care_plan_quick_link_title_adls',
    // component: CarePlanAdls,
  },
  {
    key: 'IADLS',
    defaultValue: 'iadls',
    title: 'care_plan_quick_link_title_iadls',
    // component: CarePlanIadls,
  },
  {
    key: 'ICD9_CODES',
    defaultValue: 'icd9Codes',
    title: 'care_plan_quick_link_title_icd9_codes',
    // component: CarePlanIcdNineCodes,
  },
  {
    key: 'ICD10_CODES',
    defaultValue: 'icd10Codes',
    title: 'care_plan_quick_link_title_icd10_codes',
    // component: CarePlanIcdTenCodes,
  },
  {
    key: 'UB04_CODES',
    defaultValue: 'ub04Codes',
    title: 'care_plan_quick_link_title_ub_04_codes',
    // component: CarePlanUbFourCodes,
  },
  {
    key: 'MEDICAL_CONDITIONS',
    defaultValue: 'medicalCondition',
    title: 'care_plan_quick_link_title_medical_conditions',
    component: MedicalCondition,
  },
  {
    key: 'MENTAL_BEHAVIOR_CONDITIONS',
    defaultValue: 'mentalBehaviorCondition',
    title: 'care_plan_quick_link_title_mental_behavior',
    component: MentalBehaviorCondition,
  },
  {
    key: 'ALLERGIES',
    defaultValue: 'allergies',
    title: 'care_plan_quick_link_title_allergies',
    component: CarePlanAllergies,
  },
  {
    key: 'ELIMINATION',
    defaultValue: 'elimination',
    title: 'care_plan_quick_link_title_elimination',
    component: CarePlanElimination,
  },
  {
    key: 'MEDICATIONS',
    defaultValue: 'medication',
    title: 'care_plan_quick_link_title_medications',
    // component: CarePlanMedications,
  },
  {
    key: 'AMBULATION',
    defaultValue: 'ambulation',
    title: 'care_plan_quick_link_title_ambulation',
    component: Ambulation,
  },
  {
    key: 'TRANSFERS',
    defaultValue: 'transfers',
    title: 'care_plan_quick_link_title_transfers',
    // component: CarePlanTransfers,
  },
  {
    key: 'BATHING_GROOMING',
    defaultValue: 'bathingGrooming',
    title: 'care_plan_quick_link_title_bathing_grooming',
    // component: CarePlanBathingGrooming,
  },
  {
    key: 'MEALS',
    defaultValue: 'meal',
    title: 'care_plan_quick_link_title_meals',
    component: CarePlanMeals,
  },
  {
    key: 'DRIVING',
    defaultValue: 'driving',
    title: 'care_plan_quick_link_title_driving',
    // component: CarePlanDriving,
  },
  {
    key: 'EXERCISE',
    defaultValue: 'exercise',
    title: 'care_plan_quick_link_title_exercise',
    component: CarePlanExercise,
  },
  {
    key: 'SLEEP_PATTERNS',
    defaultValue: 'sleepPatterns',
    title: 'care_plan_quick_link_title_sleep_patterns',
    // component: CarePlanSleepPatterns,
  },
  {
    key: 'EQUIPMENT',
    defaultValue: 'equipment',
    title: 'care_plan_quick_link_title_equipment',
    // component: CarePlanEquipment,
  },
  {
    key: 'PET_CARE',
    defaultValue: 'petCare',
    title: 'care_plan_quick_link_title_pet_care',
    // component: CarePlanPetCare,
  },
  {
    key: 'DAILY_ROUTINE',
    defaultValue: 'dailyRoutine',
    title: 'care_plan_quick_link_title_daily_routine',
    component: CarePlanDailyRoutine,
  },
];
