// Libs
import { CSSProperties, useEffect, useState } from 'react';
import { ReactCalendarItemRendererProps } from 'react-calendar-timeline';
import { Views } from 'react-big-calendar';
import { Tooltip } from 'react-tooltip';
// Components, Layouts, Pages
import { ReactPortal, ScheduleAssignee } from '~/components';
// Others
import { DEFAULT_NUMBER_ONE, DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { DEFAULT_SCHEDULE_BACKGROUND_EVENT_COLOR, DEFAULT_SCHEDULE_EVENT_COLOR } from '~/utils/constants/component';
import { IScheduleTimelineEvent, ITimelineEvent } from '~/utils/interface/schedule';
import { StatusEnum } from '~/utils/enum';
import { statusColor } from '~/utils/constants/color';
import { generateGuid } from '~/utils/helpers/common';
import { getUserName } from '~/utils/helper';
// Styles, images, icons
import './EventDailyTimeline.scss';

type Props = Omit<ReactCalendarItemRendererProps, 'item'> & {
  eventData: ITimelineEvent;
  viewType: string;
  onSelectEvent: (data: IScheduleTimelineEvent) => void;
};

const CollapseEvent = (props: {
  resourceData: IScheduleTimelineEvent;
  onClickEvent: (data: IScheduleTimelineEvent) => void;
}) => {
  const { resourceData, onClickEvent } = props;
  const { tasks } = resourceData;
  const eventColor = DEFAULT_SCHEDULE_EVENT_COLOR;
  const eventName = tasks?.[0]?.name || EMPTY_STRING;
  const id = generateGuid();
  return (
    <div
      className='eventGroup collapseEventContainer'
      style={{ borderWidth: 1, backgroundColor: eventColor }}
      onClick={() => onClickEvent(resourceData)}
      data-tooltip-id={`taskName-${id}`}
      data-tooltip-place='left'
    >
      <div className='eventName'>{eventName}</div>
      <ReactPortal wrapperId='taskTooltip'>
        <Tooltip id={`taskName-${id}`} className={'tooltipWrap'}>
          <div className='tooltip'>
            {tasks?.length > DEFAULT_NUMBER_ZERO
              ? tasks?.map((task, index) => (
                  <span className='tooltipText' key={index}>
                    {task?.name || EMPTY_STRING}
                  </span>
                ))
              : EMPTY_STRING}
          </div>
        </Tooltip>
      </ReactPortal>
    </div>
  );
};

const ExpandEvent = (props: {
  resourceData: IScheduleTimelineEvent;
  onClickEvent: (data: IScheduleTimelineEvent) => void;
  viewType: string;
}) => {
  const { resourceData, viewType, onClickEvent } = props;
  const { tasks, assignees, status } = resourceData;
  const eventColor = DEFAULT_SCHEDULE_BACKGROUND_EVENT_COLOR;
  const eventName = tasks?.[0]?.name || EMPTY_STRING;
  const id = generateGuid();
  const handleColor = (value: string): string => {
    switch (value) {
      case StatusEnum.IN_PROGRESS:
        return statusColor.YELLOW_500;
      case StatusEnum.COMPLETED:
        return statusColor.GREEN_800;
      case StatusEnum.MISSED_CHECK_IN:
        return statusColor.ROSE_400;
      case StatusEnum.MISSED_CHECK_OUT:
        return statusColor.ROSE_600;
      case StatusEnum.OPEN_SHIFT:
        return statusColor.FINN900;
      case StatusEnum.CANCELLED_BY_CLIENT:
      case StatusEnum.MEETING:
        return statusColor.INDIGO_400;
      case StatusEnum.CANCELLED_BY_CAREGIVER:
      case StatusEnum.HOLIDAY:
        return statusColor.ORANGE_300;
      case StatusEnum.CANCELLED_BY_OFFICE:
        return statusColor.ORANGE_400;
      case StatusEnum.NOT_SCHEDULED:
        return statusColor.RED_800;
      case StatusEnum.PENDING_CONFIRMATION:
      case StatusEnum.VACATION_TIME_OFF:
        return statusColor.EMERALD_300;
      case StatusEnum.GENERAL:
        return statusColor.LIME_100;
      case StatusEnum.TASK:
        return statusColor.SKY_300;
      default:
        //StatusEnum.ATTENTION_REQUIRED
        return statusColor.ROSE_500;
    }
  };
  return (
    <div
      className={`eventGroup expandEventContainer ${viewType === Views.DAY && 'expandEventDay'}`}
      style={{ borderWidth: 1, backgroundColor: handleColor(status ? status : statusColor.ROSE_500) }}
      onClick={() => onClickEvent(resourceData)}
    >
      {viewType === Views.WEEK && (
        <div className='expandWeek'>
          <div className='assignees'>
            {assignees && (
              <ScheduleAssignee
                assignees={assignees}
                eventColor={handleColor(status ? status : statusColor.ROSE_500)}
                width={32}
                height={32}
              />
            )}
          </div>

          <div className='right'>
            <div className='eventName' data-tooltip-id={`taskName-${id}`} data-tooltip-place='left'>
              {getUserName(assignees?.[0].firstName, assignees?.[0].lastName)}
            </div>
            <div className='eventTime'>
              {resourceData.startTime} - {resourceData.endTime}
            </div>
          </div>
        </div>
      )}

      {viewType === Views.DAY && (
        <div className='expandDay'>
          <div className='assigneesDay'>
            {assignees && (
              <ScheduleAssignee
                assignees={assignees}
                eventColor={handleColor(status ? status : statusColor.ROSE_500)}
                width={30}
                height={30}
              />
            )}
          </div>

          <div className='eventName' data-tooltip-id={`taskName-${id}`} data-tooltip-place='left'>
            {getUserName(assignees?.[0].firstName, assignees?.[0].lastName)}
          </div>
        </div>
      )}

      <ReactPortal wrapperId='taskTooltip'>
        <Tooltip id={`taskName-${id}`} className={'tooltipWrap'}>
          <div className='tooltip'>
            {assignees && assignees?.length > DEFAULT_NUMBER_ZERO
              ? assignees?.map((assignee, index) => (
                  <span className='tooltipText' key={index}>
                    {getUserName(assignee.firstName, assignee.lastName)}
                  </span>
                ))
              : EMPTY_STRING}
          </div>
        </Tooltip>
      </ReactPortal>
    </div>
  );
};

const EventDailyTimeline = (props: Props) => {
  //#region Destructuring Props
  const { eventData, viewType, getItemProps, onSelectEvent } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [initStyle, setInitStyle] = useState<CSSProperties>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!eventData) return;

    const newStyle: CSSProperties = {};
    if (eventData?.resource?.length && eventData.resource.length > 1) {
      newStyle.overflowY = 'auto';
    }

    setInitStyle(newStyle);
  }, [eventData]);
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='eventDailyTimeline'>
      <div
        {...getItemProps({
          style: { ...initStyle },
          className: 'eventContainer',
        })}
        key={eventData.id}
      >
        {eventData?.resource?.map((data: IScheduleTimelineEvent, index: number) => (
          <div key={index} className='eventWrap'>
            {eventData?.resource?.length && eventData.resource.length > DEFAULT_NUMBER_ONE ? (
              <CollapseEvent resourceData={data} onClickEvent={onSelectEvent} />
            ) : (
              <ExpandEvent resourceData={data} onClickEvent={onSelectEvent} viewType={viewType} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventDailyTimeline;
