// Libs
import moment from 'moment';
import classNames from 'classnames/bind';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { DEFAULT_MIN_YEAR } from '~/utils/constants/common';
import { useOutsideClick } from '~/utils/customHook';
// Styles, images, icons
import styles from './CustomDateHeader.module.scss';
import { icons } from '~/assets';

type Props = {
  date: Date;
  onChangeYear: (year: number) => void;
  onChangeMonth: (month: number) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
};

const cx = classNames.bind(styles);

const CustomDateHeader = (props: Props) => {
  //#region Destructuring Props
  const {
    date,
    onChangeYear,
    onChangeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isOpenOptionMonth, setIsOpenOptionMonth] = useState<boolean>(false);
  const [isOpenOptionYear, setIsOpenOptionYear] = useState<boolean>(false);
  const monthRef = useOutsideClick<HTMLDivElement>(() => setIsOpenOptionMonth(false));
  const yearRef = useOutsideClick<HTMLDivElement>(() => setIsOpenOptionYear(false));
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangeMonth = (month: number) => {
    onChangeMonth(month);
    setIsOpenOptionMonth(!isOpenOptionMonth);
  };

  const handleChangeYear = (year: number) => {
    onChangeYear(year);
    setIsOpenOptionYear(!isOpenOptionYear);
  };

  const handleOpenOptionMonth = () => {
    setIsOpenOptionMonth(!isOpenOptionMonth);
  };

  const handleOpenOptionYear = () => {
    setIsOpenOptionYear(!isOpenOptionYear);
  };

  const getYear = () => {
    const currentYear = new Date().getFullYear();

    const years = [];

    for (let year = DEFAULT_MIN_YEAR; year <= currentYear; year++) {
      years.push(year);
    }

    return years;
  };

  //#endregion Handle Function

  return (
    <div id='customDateHeader' className={cx('container')}>
      <div className={cx('customDateHeader')}>
        <button type='button' onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className={cx('prevMonthBtn')}>
          <img src={icons.commonIconArrowBottom} alt={t('common_img_text_alt')} className={cx('iconControl')} />
        </button>

        <span className={cx('dateLabel')}>{moment(date).format('MMMM YYYY')}</span>

        <button type='button' onClick={increaseMonth} disabled={nextMonthButtonDisabled} className={cx('nextMonthBtn')}>
          <img src={icons.commonIconArrowBottom} alt={t('common_img_text_alt')} className={cx('iconControl')} />
        </button>
      </div>

      <div className={cx('customDateSelect')}>
        <div ref={monthRef} className={cx('customMonthSelector')}>
          <div className={cx('selectBtn')} onClick={handleOpenOptionMonth}>
            <span className={cx('value')}>{moment(date).format('MMMM')}</span>
            <img src={icons.commonIconArrowBottom} alt={t('common_img_text_alt')} />
          </div>
          {isOpenOptionMonth && (
            <ul className={cx('options')}>
              <div className={cx('listOption')}>
                {moment.months().map((month, index) => (
                  <li key={month} className={cx('option', index === moment(date).month() && 'active')}>
                    <button className={cx('changeMonthBtn')} onClick={() => handleChangeMonth(index)}>
                      {month}
                    </button>
                  </li>
                ))}
              </div>
            </ul>
          )}
        </div>

        <div ref={yearRef} className={cx('customYearSelector')}>
          <div className={cx('selectBtn')} onClick={handleOpenOptionYear}>
            <span className={cx('value')}>{moment(date).format('YYYY')}</span>
            <img src={icons.commonIconArrowBottom} alt={t('common_img_text_alt')} />
          </div>

          {isOpenOptionYear && (
            <ul className={cx('options')}>
              <div className={cx('listOption')}>
                {getYear().map((year) => (
                  <li key={year} className={cx('option', year === moment(date).year() && 'active')}>
                    <button className={cx('changeYearBtn')} onClick={() => handleChangeYear(year)}>
                      {year}
                    </button>
                  </li>
                ))}
              </div>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomDateHeader;
