import { urlApiEmployee } from '~/utils/constants/api';
import { BaseResponse, IListDataResponse, IListQueryParams } from '~/utils/interface/common';
import axiosClient from '~/apis/axiosClient';
import { IFormAddEmployee, IEmployee, IFormUpdateEmployee } from '~/utils/interface/employees';

const employeeApi = {
  getList(params: IListQueryParams) {
    const url = `${urlApiEmployee.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IEmployee[]>>>(url, { params });
  },

  createEmployee(body: IFormAddEmployee) {
    const url = `${urlApiEmployee.createEmployee}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  updateEmployee({ employeeId, body }: IFormUpdateEmployee) {
    const url = `${urlApiEmployee.updateEmployee(employeeId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },
};

export default employeeApi;
