// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import { createApplicant } from '~/thunks/applicant/applicantThunk';
import { getListApplicant } from '~/thunks/applicant/applicantThunk';

export interface ApplicantState {
  isRefreshApplicantList: boolean;
}

const initialState: ApplicantState = {
  isRefreshApplicantList: false,
};

const applicantSlice = createSlice({
  name: 'applicantState',
  initialState: initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshApplicantList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list Applicant
    builder
      .addCase(getListApplicant.pending, (state) => {})
      .addCase(getListApplicant.fulfilled, (state) => {})
      .addCase(getListApplicant.rejected, (state) => {});

    // Create Applicant
    builder
      .addCase(createApplicant.pending, (state) => {})
      .addCase(createApplicant.fulfilled, (state) => {})
      .addCase(createApplicant.rejected, (state) => {});
  },
});

// Actions
export const applicantActions = applicantSlice.actions;

// Selectors
export const selectIsRefreshApplicantList = (state: RootState) => state.applicantState.isRefreshApplicantList;

// Reducer
const applicantReducer = applicantSlice.reducer;
export default applicantReducer;
