// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogPanel } from '@headlessui/react';
// Components, Layouts, Pages
import { ImageCircle, SummaryTab, Tab, TabCopy, TabDelete, TabEdit, TabNotes } from '~/components';
// Others
import { DIV_ELEMENT, EMPTY_STRING, eventPopupTabs } from '~/utils/constants/common';
import { TabQueryEventEnum } from '~/utils/enum';
import { useAppDispatch } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { getUserName } from '~/utils/helper';
import { CommonIconEmail, CommonIconPhoneNumber } from '~/assets/svgComponents';
import { CORDUROY_500 } from '~/utils/constants/color';
import { locationMapMockData } from '~/mockData';
import { GOOGLE_MAPS_URL, BLANK_GOOGLE_MAP } from '~/utils/constants/common';
import { IScheduleTimelineEvent } from '~/utils/interface/schedule';
import { IAddAssignee } from '~/utils/interface/common';
// Styles, images, icons
import styles from './EventPopup.module.scss';
import { icons } from '~/assets';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  // Update Data Later
  data?: IScheduleTimelineEvent;
};

const cx = classNames.bind(styles);

const EventPopup = (props: Props) => {
  //#region Destructuring Props
  const { onClose, data, isOpen } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [eventDetail, setEventDetail] = useState<IAddAssignee[]>([]);
  const [currentTab, setCurrentTab] = useState<string>(TabQueryEventEnum.SUMMARY);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!data || !isOpen) return;
    handleGetDetail();
    setCurrentTab(TabQueryEventEnum.SUMMARY);
  }, [isOpen, data]);
  //#endregion Implement Hook

  //#region Handle Function
  const renderTabContent = useMemo(() => {
    switch (currentTab) {
      case TabQueryEventEnum.SUMMARY:
        return <SummaryTab />;
      case TabQueryEventEnum.NOTES:
        return <TabNotes />;
      case TabQueryEventEnum.EDIT:
        return <TabEdit />;
      case TabQueryEventEnum.COPY:
        return <TabCopy />;
      case TabQueryEventEnum.DELETE:
        return <TabDelete />;

      default:
        // Handle Return Tab Later
        return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
    }
  }, [currentTab, t]);

  const handleGetDetail = () => {
    // Update Handle Get Detail Later
    if (data?.assignees) {
      setEventDetail(data?.assignees);
    }
  };

  const handleMap = () => {
    // Handle Map Later
    const googleMapUrl = GOOGLE_MAPS_URL(locationMapMockData);
    window.open(googleMapUrl, BLANK_GOOGLE_MAP);
  };

  const handleTabChange = (newTab: string) => {
    setCurrentTab(newTab);
  };

  const handleClose = () => {
    onClose && onClose();
  };
  //#endregion Handle Function

  return (
    <Dialog as={DIV_ELEMENT} open={isOpen} onClose={handleClose}>
      <div className={cx('container')}>
        <DialogPanel transition className={cx('modalEvent')}>
          <div id='eventPopup' className={cx('eventPopup')}>
            <div className={cx('mainBody')}>
              <div className={cx('headGroup')}>
                <div className={cx('infoGroup')}>
                  <div className={cx('nameGroup')}>
                    <ImageCircle
                      firstName={eventDetail?.[0]?.firstName}
                      lastName={eventDetail?.[0]?.lastName}
                      imageUrl={eventDetail?.[0]?.avatarUrl}
                      width={90}
                      height={90}
                      fontSize={45}
                    />

                    <div className={cx('firstLastName')}>
                      <div className={cx('name')}>
                        <span>{getUserName(eventDetail?.[0]?.firstName, eventDetail?.[0]?.lastName)}</span>
                        <span className={cx('textSchedule')}>{t('common_text_schedule')}</span>
                      </div>
                      <div className={cx('textInfoGroup')}>
                        <CommonIconPhoneNumber strokePath={CORDUROY_500} width={16} height={16} />
                        <span className={cx('textInfo')}>{eventDetail?.[0]?.phoneNumber || EMPTY_STRING}</span>
                      </div>
                      <div className={cx('textInfoGroup')}>
                        <CommonIconEmail strokePath={CORDUROY_500} width={16} height={16} />
                        <span className={cx('textInfo')}>{eventDetail?.[0]?.email || EMPTY_STRING}</span>
                      </div>
                    </div>
                  </div>
                  <button className={cx('btnMap')} onClick={handleMap}>
                    <img className={cx('iconMap')} src={icons.commonIconMap} alt={t('common_img_text_alt')} />
                    {t('common_btn_map')}
                  </button>
                </div>
                <div className={cx('navTabGroup')}>
                  <Tab items={eventPopupTabs} paddingBottom={16} enableUrlSync={false} onChange={handleTabChange} />
                </div>
              </div>

              <div className={cx('tabContent')}>{renderTabContent}</div>
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default EventPopup;
