// Libs
import classNames from 'classnames/bind';
import { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
// Components, Layouts, Pages
import {
  Modal,
  BaseButton,
  BaseSelect,
  BaseDatePicker,
  BaseTimePicker,
  BaseInput,
  RadioAddSchedule,
  ImageCircle,
} from '~/components';
// Others
import { LoadingContext } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { AddScheduleModalEnum, ImageCircleTypeEnum, RadioCrmEnum } from '~/utils/enum';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { optionsRecurrenceSchedule } from '~/mockData';
import { getUserName } from '~/utils/helper';
import { getDetailSchedule } from '~/thunks/schedule/scheduleThunk';
import { IDetailSchedule } from '~/utils/interface/schedule';
// Styles, images, icons
import styles from './DetailScheduleModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  scheduleId?: string;
};

const cx = classNames.bind(styles);

const DetailScheduleModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, scheduleId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingContext = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  const {
    control,
    reset,
    formState: { errors },
  } = useForm<IDetailSchedule>({
    defaultValues: {
      startDate: EMPTY_STRING,
      endDate: EMPTY_STRING,
      startTime: EMPTY_STRING,
      endTime: EMPTY_STRING,
      isAllDay: false,
      recurrence: optionsRecurrenceSchedule[DEFAULT_NUMBER_ZERO].value,
      assignees: [],
      id: EMPTY_STRING,
    },
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetDetailSchedule(scheduleId);
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetailSchedule = (scheduleId?: string) => {
    loadingContext?.show();

    if (!scheduleId) return;

    dispatch(getDetailSchedule(scheduleId))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        reset(res.data);
      })
      .catch((err) => {})
      .finally(() => loadingContext?.hide());
  };
  //#endregion Handle Function

  return (
    <Modal title={t('crm_client_detail_schedule_tab_detail_schedule_title')} isOpen={isOpen} onClose={onClose}>
      <form id='crmDetailScheduleModalComponent' className={cx('crmDetailScheduleModal')}>
        <div className={cx('contentModal')}>
          <div className={cx('fourItemRow')}>
            <div className={cx('twoItemRow')}>
              <Controller
                name={AddScheduleModalEnum.START_DATE}
                control={control}
                render={({ field: { value } }) => (
                  <BaseDatePicker
                    label={t('crm_client_detail_schedule_tab_start_date_label')}
                    placeholderText={t('common_select_placeholder')}
                    value={value}
                    disable
                  />
                )}
              />

              <Controller
                name={AddScheduleModalEnum.START_TIME}
                control={control}
                render={({ field: { value } }) => (
                  <BaseTimePicker
                    label={t('crm_client_detail_schedule_tab_start_time_label')}
                    placeholderText={t('crm_client_detail_schedule_tab_time_placeholder')}
                    value={value}
                    errorMessage={errors.startTime?.message}
                    disable
                  />
                )}
              />
            </div>

            <div className={cx('textTo')}>{t('crm_client_detail_schedule_tab_text_to_label')}</div>

            <div className={cx('twoItemRow')}>
              <Controller
                name={AddScheduleModalEnum.END_DATE}
                control={control}
                render={({ field: { value } }) => (
                  <BaseDatePicker
                    label={t('crm_client_detail_schedule_tab_end_date_label')}
                    placeholderText={t('common_select_placeholder')}
                    value={value}
                    disable
                  />
                )}
              />

              <Controller
                name={AddScheduleModalEnum.END_TIME}
                control={control}
                render={({ field: { value } }) => (
                  <BaseTimePicker
                    label={t('crm_client_detail_schedule_tab_end_time_label')}
                    placeholderText={t('crm_client_detail_schedule_tab_time_placeholder')}
                    value={value}
                    disable
                  />
                )}
              />
            </div>
          </div>

          <Controller
            name={'client'}
            control={control}
            render={({ field: { name, value } }) => (
              <BaseInput
                label={t('crm_client_detail_schedule_tab_schedule_client_name_label')}
                id={name}
                //placeholder={t('crm_client_detail_schedule_tab_schedule_client_name_label')}
                value={getUserName(value?.firstName, value?.lastName)}
                disabled
              />
            )}
          />

          <div className={cx('twoCol')}>
            <div className={cx('twoRowCheckbox')}>
              <div className={cx('textAllDay')}>{t('crm_client_schedule_form_all_day_label')}</div>
              <Controller
                name={AddScheduleModalEnum.ALL_DAY}
                control={control}
                render={({ field }) => (
                  <input id='allDay' type='checkbox' className={cx('inputCheckbox')} checked={field.value} disabled />
                )}
              />
            </div>

            <Controller
              name={AddScheduleModalEnum.RECURRENCE}
              control={control}
              render={({ field: { value } }) => (
                <BaseSelect
                  options={optionsRecurrenceSchedule}
                  label={t('crm_client_detail_schedule_tab_recurrence_label')}
                  placeholder={t('common_select_placeholder')}
                  value={value}
                  disabled
                />
              )}
            />
          </div>

          <div className={cx('contentWrap')}>
            <Controller
              name={'assignees'}
              control={control}
              render={({ field: { name, value } }) => (
                <div>
                  <BaseInput
                    id={name}
                    label={t('crm_client_detail_schedule_tab_caregiver_label')}
                    //placeholder={t('common_placeholder_select')}
                    disabled
                  />
                  <div className={cx('listCaregiverItem')}>
                    {value &&
                      value.map((assignee, index) => (
                        <div key={index} className={cx('caregiverItem')}>
                          <ImageCircle
                            imageUrl={assignee.avatarUrl}
                            firstName={assignee.firstName}
                            lastName={assignee.lastName}
                            type={assignee.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
                            width={20}
                            height={20}
                          />
                          <span className={cx('nameCaregiver')}>
                            {getUserName(assignee.firstName, assignee.lastName)}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            />

            <div className={cx('twoCol')}>
              <div className={cx('textLabel')}>
                {t('crm_client_detail_schedule_tab_or_label')}
                <span className={cx('textLabelBold')}>{t('crm_client_detail_schedule_tab_check_schedule_label')}</span>
                {t('crm_client_detail_schedule_tab_availability_label')}
              </div>
              <div className={cx('twoRow')}>
                <Controller
                  name={'isAllDay'}
                  control={control}
                  render={({ field }) => (
                    <input
                      id='caregiverConfirmationId'
                      type='checkbox'
                      name='caregiverConfirmation'
                      className={cx('inputCheckbox')}
                      checked={field.value}
                      disabled
                    />
                  )}
                />
                <div className={cx('textLabel')}>{t('crm_client_schedule_form_caregiver_confirm_label')}</div>
              </div>
            </div>
          </div>

          <div className={cx('wrapRadio')}>
            <div className={cx('textLabel')}>{t('crm_client_schedule_form_caregiver_rate_label')}</div>

            <div className={cx('contentWrapRadio')}>
              <Controller
                name={'clientRate'}
                control={control}
                render={({ field: { value } }) => (
                  <div className={cx('radioGroup')}>
                    <RadioAddSchedule
                      id={'hourlySelectCaregiverId'}
                      name={'radioOptionSelectCaregiver'}
                      label={t('crm_client_schedule_form_hourly_radio_value')}
                      value={RadioCrmEnum.HOURLY}
                      checked={value === RadioCrmEnum.HOURLY}
                      disabled
                    />
                    <RadioAddSchedule
                      id={'perVisitSelectCaregiverId'}
                      name={'radioOptionSelectCaregiver'}
                      label={t('crm_client_schedule_form_per_visit_radio_value')}
                      value={RadioCrmEnum.PER_VISIT}
                      checked={value === RadioCrmEnum.PER_VISIT}
                      disabled
                    />
                    <RadioAddSchedule
                      id={'liveInSelectCaregiverId'}
                      name={'radioOptionSelectCaregiver'}
                      label={t('crm_client_schedule_form_live_radio_value')}
                      value={RadioCrmEnum.LIVE_IN}
                      checked={value === RadioCrmEnum.LIVE_IN}
                      disabled
                    />
                    <RadioAddSchedule
                      id={'notBillableSelectCaregiverId'}
                      name={'radioOptionSelectCaregiver'}
                      label={t('crm_client_schedule_form_not_billable_radio_value')}
                      value={RadioCrmEnum.NOT_BILLABLE}
                      checked={value === RadioCrmEnum.NOT_BILLABLE}
                      disabled
                    />
                  </div>
                )}
              />
            </div>
          </div>

          <Controller
            name={AddScheduleModalEnum.RECURRENCE}
            control={control}
            render={({ field: { value } }) => (
              <BaseSelect
                options={optionsRecurrenceSchedule}
                value={value}
                errorMessage={errors.recurrence?.message}
                disabled
              />
            )}
          />

          <div className={cx('wrapRadio')}>
            <div className={cx('textLabel')}>{t('crm_client_schedule_form_caregiver_rate_label')}</div>
            <div className={cx('contentWrapRadio')}>
              <Controller
                name={'caregiverRate'}
                control={control}
                render={({ field: { value } }) => (
                  <div className={cx('radioGroup')}>
                    <RadioAddSchedule
                      id={'hourlyCaregiverRateId'}
                      name={'radioOptionCaregiverRate'}
                      label={t('crm_client_schedule_form_hourly_radio_value')}
                      value={RadioCrmEnum.HOURLY}
                      checked={value === RadioCrmEnum.HOURLY}
                      disabled
                    />
                    <RadioAddSchedule
                      id={'perVisitCaregiverRateId'}
                      name={'radioOptionCaregiverRate'}
                      label={t('crm_client_schedule_form_per_visit_radio_value')}
                      value={RadioCrmEnum.PER_VISIT}
                      checked={value === RadioCrmEnum.PER_VISIT}
                      disabled
                    />
                    <RadioAddSchedule
                      id={'liveInCaregiverRateId'}
                      name={'radioOptionCaregiverRate'}
                      label={t('crm_client_schedule_form_live_radio_value')}
                      value={RadioCrmEnum.LIVE_IN}
                      checked={value === RadioCrmEnum.LIVE_IN}
                      disabled
                    />
                    <RadioAddSchedule
                      id={'notPayableCaregiverRateId'}
                      name={'radioOptionCaregiverRate'}
                      label={t('crm_client_schedule_form_not_billable_radio_value')}
                      value={RadioCrmEnum.NOT_PAYABLE}
                      checked={value === RadioCrmEnum.NOT_PAYABLE}
                      disabled
                    />
                  </div>
                )}
              />
            </div>
          </div>

          <div className={cx('twoCol')}>
            <div className={cx('textButton')}>{t('crm_client_schedule_form_add_mileage_title')}</div>
            <div className={cx('textButton')}>{t('crm_client_schedule_form_expense_credit_title')}</div>
            <div className={cx('textButton')}>{t('crm_client_schedule_form_add_bill_pay_title')}</div>
          </div>
        </div>

        <div className={cx('footerButton')}>
          <BaseButton text={t('common_cancel_label')} width={65} onClick={onClose} type='button' />
        </div>
      </form>
    </Modal>
  );
};

export default DetailScheduleModal;
