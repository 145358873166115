// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Components, Layouts, Pages
import {
  BaseButton,
  BasePagination,
  BaseTable,
  FormSendEmailModal,
  InputSearch,
  ModalUnderDevelopment,
  RingCentralConfirmModal,
  StackedAvatars,
  ThreeDotTableOptions,
  ToolBar,
} from '~/components';
// Others
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  CLientStatusEnum,
  ConnectionStatusEnum,
  CRMEnum,
  StorageEnum,
  TranslationEnum,
} from '~/utils/enum';
import { ColumnTableType, IListDataResponse, IPaginationResponse } from '~/utils/interface/common';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_LIMIT_PAGE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { convertEnumToString, getUserName } from '~/utils/helper';
import { IListClient, IListQueryParamsClients, IPayloadChangeStatusClient } from '~/utils/interface/crm/clients';
import { changeStatusClient, getListClients } from '~/thunks/crm/clients/clientsThunk';
import { useAppDispatch } from '~/redux/hooks';
import { LoadingData, RingCentralContext } from '~/context';
import { useSearchParams } from 'react-router-dom';
import useDebounce from '~/utils/customHook';
import { adminRouteAbsolute, caregiverRouteAbsolute, staffRouteAbsolute } from '~/utils/constants/route';
import { clientActions, selectIsRefreshClientList } from '~/thunks/crm/clients/clientsSlice';
// Styles, images, icons
import styles from './CRMClient.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  handleCall: (record: IListClient) => void,
  handleSendEmail: (record: IListClient) => void,
  handleViewDetails: (id: string) => void,
  handleSendMessage: (record: IListClient) => void,
  handleChangeStatus: (record: IListClient) => void
): ColumnTableType<IListClient>[] => {
  return [
    {
      key: 'clientName',
      title: t('crm_client_table_title_name'),
      render: (_, record: IListClient) => {
        // return !record.firstName && !record.lastName ? (
        //   <>{EMPTY_STRING}</>
        // ) : (
        //   <>
        //     <ImageCircle
        //       type={record?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
        //       imageUrl={record?.avatarUrl}
        //       firstName={record?.firstName}
        //       lastName={record?.lastName}
        //       width={24}
        //       height={24}
        //       fontSize={14}
        //       margin={'0 6px 0 0'}
        //     />
        //     <span>{getUserName(record?.firstName, record?.lastName)}</span>
        //   </>
        // );
        return <>{getUserName(record?.firstName, record?.middleName, record?.lastName)}</>;
      },
    },
    {
      key: 'phoneNumber',
      title: t('crm_client_table_title_phone_number'),
      dataIndex: 'phoneNumber',
      render(value, record, index) {
        return <>{record.phoneNumber || EMPTY_STRING}</>;
      },
    },

    {
      key: 'location',
      title: t('crm_client_table_title_location'),
      dataIndex: 'location',
      render(value, record, index) {
        return <>{record.location || EMPTY_STRING}</>;
      },
    },
    {
      key: 'types',
      title: t('crm_client_table_title_types'),
      render(value, record, index) {
        return <>{record.careClientType ? convertEnumToString(record.careClientType) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'caregivers',
      title: t('crm_client_table_title_caregivers'),
      dataIndex: 'caregivers',
      render(value, record, index) {
        if (!record.caregivers || record.caregivers.length === DEFAULT_NUMBER_ZERO) {
          return EMPTY_STRING;
        }

        return <StackedAvatars color='green' avatars={record.caregivers} id={record.id} />;
      },
    },
    {
      key: 'primaryManager',
      title: t('crm_client_table_title_primary_manager'),
      dataIndex: 'primaryManager',
      render: (value, record) => {
        return <>{EMPTY_STRING}</>;

        // return !record.primaryManager ? (
        //   <>{EMPTY_STRING}</>
        // ) : (
        //   <>
        //      <ImageCircle
        //       type={record?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
        //       imageUrl={record?.avatarUrl}
        //       firstName={record?.firstName}
        //       lastName={record?.lastName}
        //       width={24}
        //       height={24}
        //       fontSize={14}
        //       margin={'0 6px 0 0'}
        //     />
        //     <span>{getUserName(record?.firstName, record?.lastName)}</span>
        //   </>
        // );
      },
    },
    {
      key: 'action',
      title: t('crm_client_table_title_action'),
      render(value, record: IListClient, index) {
        return (
          <ThreeDotTableOptions
            data={record}
            permissions={{
              isCall: true,
              isSendMessage: true,
              isSendEmail: true,
              isEdit: false,
              isDelete: false,
              isDeactivate: true,
            }}
            onView={() => handleViewDetails(record.id)}
            onCall={() => handleCall(record)}
            onSendEmail={() => handleSendEmail(record)}
            onSendMessage={() => handleSendMessage(record)}
            onDeactivate={() => handleChangeStatus(record)}
          />
        );
      },
      width: 50,
    },
  ];
};

const CRMClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const navigate = useNavigate();
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  // const { ringCentralStatus, executeCall, sendSMS } = useContext(RingCentralContext);
  //#endregion Declare Hook

  //#region Selector
  const isRefreshClientsList = useSelector(selectIsRefreshClientList);
  //#endregion Selector

  //#region Declare State
  const [clientList, setClientList] = useState<IListClient[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  const [hasInteracted, setHasInteracted] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const renderSearchKey = useMemo(() => {
    if (!hasInteracted && textSearchParams) {
      return textSearchParams;
    }

    if (hasInteracted && searchKey === EMPTY_STRING) {
      return EMPTY_STRING;
    }

    if (searchKey) {
      return searchKey;
    }

    return EMPTY_STRING;
  }, [searchKey, textSearchParams, hasInteracted]);
  const debouncedSearchKey = useDebounce<string>(renderSearchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  const [paramObject, setParamObject] = useState<IListQueryParamsClients>({
    page: pageSelected,
    limit: DEFAULT_LIMIT_PAGE,
  });
  const [isShowUpdateClientModal, setIsShowUpdateClientModal] = useState<boolean>(false);
  const [clientSelected, setClientSelected] = useState<IListClient | null>(null);

  const [isShowSendMail, setIsShowSendMail] = useState<boolean>(false);

  // const [openDisconnectConfirm, setOpenDisconnectConfirm] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const newParamObject: IListQueryParamsClients = {
      ...paramObject,
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      type: [CRMEnum.CLIENT],
    };

    handleGetListClients(newParamObject);

    const newParam = debouncedSearchKey
      ? {
          ...params,
          page: paramObject.page!.toString(),
          limit: DEFAULT_LIMIT_PAGE.toString(),
          textSearch: debouncedSearchKey,
        }
      : { page: paramObject.page.toString(), limit: DEFAULT_LIMIT_PAGE.toString() };

    setSearchParams(newParam);
    isRefreshClientsList && dispatch(clientActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramObject, isRefreshClientsList]);

  useEffect(() => {
    if (!pagination) return;
    setParamObject({
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleViewDetails = (id: string) => {
    if (!id) return;

    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.crmClient}/${id}`);
        return;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.crmClient}/${id}`);
        return;

      case AccountRoleCodesEnum.CAREGIVER:
        navigate(`${caregiverRouteAbsolute.crmClient}/${id}`);
        return;

      default:
        return;
    }
  };

  const handleCall = async (record: IListClient) => {
    // if (ringCentralStatus !== ConnectionStatusEnum.CONNECTED) {
    //   setOpenDisconnectConfirm(true);
    //   return;
    // }

    // if (record?.phoneNumber) {
    //   executeCall && executeCall(record?.phoneNumber);
    // }

    setIsShowModalUnderDevelopment(true);
  };

  const handleSendMessage = (record: IListClient) => {
    // if (ringCentralStatus !== ConnectionStatusEnum.CONNECTED) {
    //   setOpenDisconnectConfirm(true);
    //   return;
    // }

    // if (record?.phoneNumber) {
    //   sendSMS && sendSMS(record?.phoneNumber);
    // }

    setIsShowModalUnderDevelopment(true);
  };

  const handleShowSendEmail = (record: IListClient) => {
    if (!record) return;

    setClientSelected(record);
    setIsShowSendMail(true);
  };

  const handleCloseSendEmail = () => {
    setClientSelected(null);
    setIsShowSendMail(false);
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IListQueryParamsClients = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({ ...params, page: page.toString(), limit: `${DEFAULT_LIMIT_PAGE}` });
  };

  const handleGetListClients = (params: IListQueryParamsClients) => {
    loading?.show();

    dispatch(getListClients(params))
      .unwrap()
      .then((res) => {
        const { responses, pagination }: IListDataResponse<IListClient[]> = res?.data;
        setClientList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => loading?.hide());
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
    setHasInteracted(true);
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleSortBy = () => {
    // TODO - HuyPahmGRF-ABD: Handle Sort By
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleFilter = () => {
    // TODO - HuyPahmGRF-ABD: Handle Filter
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleChangeStatus = (record: IListClient) => {
    loading?.show();

    const payloadClientStatus: IPayloadChangeStatusClient = {
      clientId: record.id,
      body: {
        status:
          record.status && record.status === CLientStatusEnum.ACTIVE
            ? CLientStatusEnum.INACTIVE
            : CLientStatusEnum.ACTIVE,
      },
    };
    dispatch(changeStatusClient(payloadClientStatus))
      .unwrap()
      .then((res) => {
        dispatch(clientActions.setRefreshList(true));
      })
      .catch((_err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleAddClient = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  // const handleCloseDisconnectConfirm = () => {
  //   setOpenDisconnectConfirm(false);
  // };

  // const handleNavigateToRingCentral = () => {
  //   const role = localStorage.getItem(StorageEnum.ROLE);

  //   switch (role) {
  //     case AccountRoleCodesEnum.ADMIN:
  //       navigate(adminRouteAbsolute.ringCentral);
  //       break;

  //     case AccountRoleCodesEnum.EMPLOYEE:
  //       break;

  //     case AccountRoleCodesEnum.CAREGIVER:
  //       break;

  //     default:
  //       break;
  //   }
  // };

  const handleClientRowClick = (data: IListClient) => {
    if (!data || !data?.id) return;

    handleViewDetails(data?.id);
  };
  //#endregion Handle Function

  return (
    <div id='CRMClientTemplate' className={cx('CRMClientComponent')}>
      <ToolBar
        title={
          <div className={cx('crmTitle')}>
            <span>{t('crm_client_title')}</span>

            {/* {ringCentralStatus !== ConnectionStatusEnum.CONNECTED && (
              <span className={cx('crmRingCentralStatus')} onClick={handleNavigateToRingCentral}>
                {t('ring_central_confirm_disconnect_title')}
              </span>
            )} */}
          </div>
        }
      >
        {/* <BaseButton
          iconLeft={icons.commonIconSort}
          text={t('common_text_sort')}
          width={84}
          height={36}
          onClick={handleSortBy}
        />
        <BaseButton
          iconLeft={icons.commonIconSort}
          text={t('common_text_filter')}
          width={67}
          height={36}
          onClick={handleFilter}
        /> */}
        <InputSearch
          height={36}
          placeholder={t('common_placeholder_search')}
          onChange={handleChangeSearch}
          value={textSearchParams}
        />
        <BaseButton
          typeStyle={ButtonTypeEnum.PRIMARY}
          iconLeft={icons.commonIconPlus}
          text={t('crm_add_client_title')}
          height={36}
          onClick={handleAddClient}
        />
      </ToolBar>

      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable
            columns={columns(
              t,
              handleCall,
              handleShowSendEmail,
              handleViewDetails,
              handleSendMessage,
              handleChangeStatus
            )}
            dataSource={clientList ?? []}
            onClickRow={handleClientRowClick}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            defaultCurrentPage={pageSelected}
            totalItems={pagination?.totalItems}
            totalPages={pagination?.totalPages}
          />
        </div>
      </div>

      {isShowSendMail && (
        <FormSendEmailModal isOpen={isShowSendMail} dataDefault={clientSelected} onClose={handleCloseSendEmail} />
      )}

      {/* {openDisconnectConfirm && (
        <RingCentralConfirmModal
          isOpen={openDisconnectConfirm}
          title={t('ring_central_confirm_disconnect_title')}
          description={t('ring_central_confirm_disconnect_description')}
          confirm={t('ring_central_confirm_disconnect_confirm')}
          onClose={handleCloseDisconnectConfirm}
          onConfirm={handleNavigateToRingCentral}
        />
      )} */}

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default CRMClient;
