// Libs
import classNames from 'classnames/bind';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
// Components, Layouts, Pages
import { BaseButton, BaseTextarea, CheckboxSingle, ModalUnderDevelopment } from '~/components';
// Others
import { ITabNotes } from '~/mockData/mockInterface';
import { mockDataTabNotes } from '~/mockData';
import { EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum, TabNotesEnum, TimeFormatEnum } from '~/utils/enum';
import { convertTime } from '~/utils/helper';
// Styles, images, icons
import styles from './TabNotes.module.scss';

type Props = {
  onClose?: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    note: yup.string().optional(),
  });
};

const defaultValues: ITabNotes = {
  date: EMPTY_STRING,
  description: EMPTY_STRING,
  createdBy: EMPTY_STRING,
  note: EMPTY_STRING,
};

const TabNotes = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    setValue,
    trigger,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<ITabNotes>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isOpenDevelopment, setIsOpenDevelopment] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleCheckboxSingle = (value: boolean, name?: keyof ITabNotes) => {
    if (name) {
      setValue(name, value, { shouldDirty: true });
      trigger(name);
    }
  };

  const handleCloseDevelopment = () => {
    setIsOpenDevelopment(false);
  };

  const handleSubmitForm = () => {
    //TODO: Handle logic save
    setIsOpenDevelopment(true);
  };

  const handleClockIn = () => {
    //TODO: Handle logic clock in
    setIsOpenDevelopment(true);
  };
  //#endregion Handle Function

  return (
    <form id='TabNotesComponent' className={cx('TabNotesComponent')} onSubmit={handleSubmit(handleSubmitForm)}>
      <div className={cx('container')}>
        <p className={cx('tabNotesTitle')}>
          {t('tab_notes_title', { date: convertTime(mockDataTabNotes.date, TimeFormatEnum.MM_DD_YY) })}
        </p>
        <div className={cx('tabNotesContainer')}>
          <div className={cx('tabNotesContent')}>
            <span>{mockDataTabNotes.description}</span>
            <div>
              <span>{t('tab_notes_modal_created_by_label')}</span>
              <span>{mockDataTabNotes.createdBy}</span>
            </div>
          </div>
          <div className={cx('tabNotesContent')}>
            <div className={cx('tabNotes')}>
              <Controller
                name={TabNotesEnum.NOTE}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseTextarea
                    id='notes'
                    label={t('tab_notes_modal_notes_label')}
                    placeholder={t('tab_notes_modal_notes_placeholder')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    height={80}
                  />
                )}
              />
            </div>
          </div>

          <Controller
            name='isBillingPage'
            control={control}
            render={({ field: { name, value } }) => (
              <CheckboxSingle
                label={t('tab_notes_modal_display_on_billing_page')}
                value={value}
                onChange={(value) => handleCheckboxSingle(value, name)}
              />
            )}
          />
        </div>
      </div>

      <div className={cx('border')}></div>

      <div className={cx('footerButton')}>
        <BaseButton
          text={t('tab_notes_button_clock_in')}
          width={80}
          type='button'
          typeStyle={ButtonTypeEnum.OUT_LINE}
          color='primary'
          onClick={handleClockIn}
        />
        <BaseButton text={t('common_save_label')} typeStyle={ButtonTypeEnum.PRIMARY} width={80} type='submit' />
      </div>
      {isOpenDevelopment && <ModalUnderDevelopment onClose={handleCloseDevelopment} />}
    </form>
  );
};

export default TabNotes;
