// Libs
import classNames from 'classnames/bind';
import { useState } from 'react';
// Components, Layouts, Pages
import { Schedule } from '~/components';
// Context
// Others
import { IClientDetail } from '~/utils/interface/crm/clients';
// Styles, images, icons
import styles from './ShiftScheduleTab.module.scss';

type Props = {
  clientId: string;
  data?: IClientDetail;
};

const cx = classNames.bind(styles);

const ShiftScheduleTab = (props: Props) => {
  //#region Destructuring Props
  const { clientId, data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isOpenAddScheduleModal, setIsAddScheduleModal] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleShowModalAddSchedule = () => {
    setIsAddScheduleModal(!isOpenAddScheduleModal);
  };
  //#endregion Handle Function

  return (
    <div id='ShiftScheduleTab' className={cx('shiftScheduleTabComponent')}>
      <Schedule mode='my' userId={clientId} data={data} isClient />
    </div>
  );
};

export default ShiftScheduleTab;
