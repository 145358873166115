// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { useContext, useEffect } from 'react';
// Components, Layouts, Pages
import { NotificationScroll } from '~/components';
// Others
import { getListNotifications } from '~/thunks/notifications/notificationsThunk';
import { IQueryBase } from '~/utils/interface/common';
import { DEFAULT_NUMBER_ZERO, MAX_LENGTH_NOTIFICATION } from '~/utils/constants/common';
import { WebSocketEvent } from '~/utils/enum';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { notificationActions } from '~/thunks/notifications/notificationsSlice';
import { WebSocketContext } from '~/context';
// Styles, images, icons
import styles from './Notifications.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const Notifications = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { message: messageWSS } = useContext(WebSocketContext);
  //#endregion Declare Hook

  //#region Selector
  const { queryParams, refreshNotificationList, currentPage, totalPages, isLoading, notificationsUnread } =
    useAppSelector((state: RootState) => state.notifications);
  //#endregion Selector

  //#region Declare State

  //#endregion Declare State

  //#region Declare useMemo
  //#endregion Declare useMemo

  //#region Implement Hook
  useEffect(() => {
    handleGetListNotifications(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  useEffect(() => {
    if (!refreshNotificationList) return;
    handleGetListNotifications(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshNotificationList]);

  useEffect(() => {
    if (!messageWSS) {
      return;
    }

    switch (messageWSS.type) {
      case WebSocketEvent.NEW_NOTIFICATION:
        dispatch(notificationActions.refreshData(true));
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageWSS]);
  //#endregion Implement Hook

  //#region Handle Function
  const renderTotalNNotification = (total: number) => {
    if (total <= DEFAULT_NUMBER_ZERO) return String(DEFAULT_NUMBER_ZERO);

    if (total <= MAX_LENGTH_NOTIFICATION) return String(total);

    return `${String(MAX_LENGTH_NOTIFICATION)}+`;
  };

  const handleGetListNotifications = (params: IQueryBase) => {
    dispatch(getListNotifications(params))
      .unwrap()
      .then(() => {})
      .catch(() => {});
  };

  const loadMore = () => {
    if (!isLoading && currentPage < totalPages) {
      handleGetListNotifications({ ...queryParams, page: currentPage + 1 });
    }
  };
  //#endregion Handle Function

  return (
    <div id='notificationsComponent' className={cx('container')}>
      <Popover>
        <div className={cx('notificationGroup')}>
          <PopoverButton className={cx('notificationIcon')}>
            <img src={icons.commonNotification} alt={t('common_img_text_alt')} className={cx('notificationIconImg')} />
          </PopoverButton>

          {notificationsUnread > DEFAULT_NUMBER_ZERO && (
            <div className={cx('notificationQuantity')}>
              <span>{renderTotalNNotification(notificationsUnread)}</span>
            </div>
          )}
        </div>

        <PopoverPanel
          transition
          anchor={{ to: 'bottom end', gap: '10px', padding: '20px' }}
          className={cx('notificationList')}
        >
          {({ close }) => (
            <div className={cx('receivedNotificationContainer')}>
              <NotificationScroll close={close} loadMore={loadMore} />
            </div>
          )}
        </PopoverPanel>
      </Popover>
    </div>
  );
};

export default Notifications;
