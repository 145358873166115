// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import { useEffect, useState } from 'react';
import { ApexOptions } from 'apexcharts';
// Components, Layouts, Pages
// Others
import { configLineChart } from '~/utils/constants/configChart';
// Styles, images, icons
import styles from './LineChart.module.scss';
import './LineChart.scss';

type Props = {
  data: ApexOptions['series'];
  categories: string[];
  colors: string[];
};

const cx = classNames.bind(styles);

const LineChart = (props: Props) => {
  //#region Destructuring Props
  const { data, categories, colors } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [configChart, setConfigChart] = useState<ApexOptions | null>(null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!data || !categories) {
      return;
    }

    const config = configLineChart({
      categories,
      colors: colors,
    });

    setConfigChart(config);
  }, [data, categories]);
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='lineChartComponent' className={cx('container')}>
      {configChart && (
        <ReactApexChart series={data} options={configChart} type={'line'} height={'100%'} width={'100%'} />
      )}
    </div>
  );
};

export default LineChart;
