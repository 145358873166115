// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';

// Components, Layouts, Pages
import { BaseButton, InputSearch, ImageCircle } from '~/components';

// Others
import { IAddAssignee } from '~/utils/interface/common';
import {
  ASTERISK_SYMBOL,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  LIMIT_TAG_ASSIGNEE,
  PLUS_CHARACTER,
} from '~/utils/constants/common';
import { ButtonTypeEnum, ImageCircleTypeEnum } from '~/utils/enum';
import { RED_FF0000 } from '~/utils/constants/color';
import { getUserName } from '~/utils/helper';

// Styles, images, icons
import styles from './AssigneePopup.module.scss';
import { icons } from '~/assets';

type Props = {
  label?: string;
  placeholder?: string;
  name?: string;
  assigneeList: IAddAssignee[];
  required?: boolean;
  value?: string[];
  errorMessage?: string;
  onChange?: (dataListId: string[], dataAssignee?: IAddAssignee[], name?: string) => void;
  disabled?: boolean;
  icon?: string;
};

const cx = classNames.bind(styles);

const AssigneePopup = (props: Props) => {
  //#region Destructuring Props
  const {
    assigneeList,
    required,
    errorMessage,
    name,
    value,
    label,
    placeholder,
    disabled = false,
    icon = icons.crmIconPlus,
    onChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [filteredAssignees, setFilteredAssignees] = useState<IAddAssignee[]>([]);
  const [listSelectedAssign, setListSelectedAssign] = useState<IAddAssignee[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!assigneeList) return;

    let updatedAssignees = assigneeList;

    if (value && value.length > DEFAULT_NUMBER_ZERO) {
      updatedAssignees = assigneeList.map((assignee) => ({
        ...assignee,
        isSelected: value.includes(assignee.id.toString()),
      }));
    }

    const selectedAssignees = updatedAssignees.filter((assignee) => assignee.isSelected);

    setFilteredAssignees(updatedAssignees);
    setListSelectedAssign(selectedAssignees);
  }, [value, assigneeList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleAddAssignee = (assignee: IAddAssignee) => {
    const newAssignee = [...listSelectedAssign, assignee];
    const listAssigneeId = newAssignee.map((item) => item.id);

    onChange && onChange(listAssigneeId, newAssignee, name);

    setListSelectedAssign(newAssignee);
    setFilteredAssignees((prev) =>
      prev.map((item) => (item.id === assignee.id ? { ...item, isSelected: true } : item))
    );
  };

  const handleCloseAssignee = (assignee: IAddAssignee) => {
    const newAssignee = listSelectedAssign.filter(
      (selectedAssignee) => selectedAssignee.id.toString() !== assignee.id.toString()
    );
    const listAssigneeId = newAssignee.map((item) => item.id);

    onChange && onChange(listAssigneeId, newAssignee, name);

    setListSelectedAssign(newAssignee);
    setFilteredAssignees((prev) =>
      prev.map((item) => (item.id.toString() === assignee.id.toString() ? { ...item, isSelected: false } : item))
    );
  };

  const handleSearchAssignee = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const filtered = assigneeList
      .filter((assignee) =>
        `${assignee.firstName?.toLowerCase()} ${assignee.lastName?.toLowerCase()}`.includes(value.toLowerCase())
      )
      .filter((assignee) => !listSelectedAssign.some((selected) => selected.id === assignee.id));

    setFilteredAssignees(filtered);
  };
  //#endregion Handle Function

  return (
    <Popover>
      <div id='addAssigneeModalComponent'>
        {label && (
          <h3 className={cx('titleSelect')}>
            {label || t('task_modal_task_assignees_label')} {required && <span>{ASTERISK_SYMBOL}</span>}
          </h3>
        )}

        <PopoverButton className={cx('selectAssign', disabled && 'bgDisable')} type='button' disabled={disabled}>
          <span className={cx('titleAssignee')}>{placeholder || t('task_modal_task_assignees_placeholder')}</span>
          <img src={icon} alt={t('common_img_text_alt')} />
        </PopoverButton>

        {errorMessage && <div className={cx('errMessage')}>{errorMessage}</div>}

        {listSelectedAssign.length > DEFAULT_NUMBER_ZERO && (
          <div className={cx('assigneeSelected')}>
            {listSelectedAssign.slice(DEFAULT_NUMBER_ZERO, LIMIT_TAG_ASSIGNEE).map((assigneeItem, index) => {
              return (
                <div
                  className={cx('tagAssigneeSelected')}
                  key={index}
                  data-tooltip-id={`singleAvatar-${index}`}
                  data-tooltip-place='top'
                  data-tooltip-content={getUserName(assigneeItem.firstName, assigneeItem.lastName)}
                >
                  <ImageCircle
                    width={16}
                    height={16}
                    fontSize={8}
                    imageUrl={assigneeItem.avatarUrl}
                    firstName={assigneeItem.firstName}
                    lastName={assigneeItem.lastName}
                    type={assigneeItem.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
                  />
                  <span className={cx('tagFirstName')}>{assigneeItem.firstName}</span>
                  <img
                    src={icons.commonIconModalClose}
                    className={cx('iconCloseAssignee')}
                    onClick={() => handleCloseAssignee(assigneeItem)}
                    alt={t('common_img_text_alt')}
                  />
                  <Tooltip id={`singleAvatar-${index}`} className={cx('tooltipText')} />
                </div>
              );
            })}
            {listSelectedAssign.length > LIMIT_TAG_ASSIGNEE && (
              <div className={cx('tagMore')} data-tooltip-id='multipleAssignee' data-tooltip-place='top'>
                <span>{`${PLUS_CHARACTER}${listSelectedAssign.length - LIMIT_TAG_ASSIGNEE}`}</span>
                <Tooltip id='multipleAssignee' className={cx('tooltipText')}>
                  <div className={cx('tooltip')}>
                    {listSelectedAssign.slice(LIMIT_TAG_ASSIGNEE).map((assignee, index) => (
                      <span key={index}>
                        {assignee.firstName} {assignee.lastName}
                      </span>
                    ))}
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        )}

        <PopoverPanel
          transition
          anchor={{ to: 'bottom start', gap: listSelectedAssign.length > DEFAULT_NUMBER_ZERO ? '45px' : '8px' }}
          className={cx('popupAssigneeContainer')}
        >
          <div className={cx('container')}>
            <div className={cx('search')}>
              <InputSearch placeholder={t('common_placeholder_search')} onChange={handleSearchAssignee} />
            </div>
            <div className={cx('assignee')}>
              {filteredAssignees.length > DEFAULT_NUMBER_ZERO ? (
                filteredAssignees.map((assigneeItem, index) => {
                  return (
                    <div
                      key={index}
                      className={cx(
                        'assigneeItem',
                        assigneeItem.isSelected && 'selected',
                        index !== filteredAssignees.length - DEFAULT_NUMBER_ONE && 'lineBottom'
                      )}
                    >
                      <div className={cx('avatarFirstLastName')}>
                        <ImageCircle
                          fontSize={23}
                          width={46}
                          height={46}
                          firstName={assigneeItem.firstName}
                          lastName={assigneeItem.lastName}
                          imageUrl={assigneeItem.avatarUrl}
                          type={assigneeItem.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
                        />

                        <div className={cx('nameGroup')}>
                          <div className={cx('firstLastName')}>
                            {getUserName(assigneeItem.firstName, assigneeItem.lastName)}
                          </div>
                          <div className={cx('email')}>{assigneeItem.email}</div>
                        </div>
                      </div>

                      <BaseButton
                        text={assigneeItem.isSelected ? t('common_delete_label') : t('common_btn_add')}
                        typeStyle={ButtonTypeEnum.WHITE}
                        width={assigneeItem.isSelected ? 60 : 45}
                        height={24}
                        color={assigneeItem.isSelected ? RED_FF0000 : EMPTY_STRING}
                        onClick={() =>
                          assigneeItem.isSelected ? handleCloseAssignee(assigneeItem) : handleAddAssignee(assigneeItem)
                        }
                      />
                    </div>
                  );
                })
              ) : (
                <p className={cx('textNoData')}>{t('common_empty_data')}</p>
              )}
            </div>
          </div>
        </PopoverPanel>
      </div>
    </Popover>
  );
};

export default AssigneePopup;
