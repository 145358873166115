// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TFunction } from 'i18next';
import moment from 'moment';
// Components, Layouts, Pages
import { TextEditor, BaseTable } from '~/components';
// Others
import {
  BusinessModelEnum,
  ModeTextEditorEnum,
  ActivitySendEmailDetailEnum,
  ThemeTextEditorEnum,
  TranslationEnum,
  FormTaskEnum,
  TimeFormatEnum,
} from '~/utils/enum';
import { ColumnTableType } from '~/utils/interface/common';
import {
  IActivityByUser,
  IAssigneeRelateActivity,
  IDetailActivity,
  IDocumentDetailActivity,
  IFormTaskActivity,
} from '~/utils/interface/activity';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { IFormTask } from '~/utils/interface/task';
import {
  convertBooleanToString,
  convertCamelCaseToTitleCase,
  convertDateToFormatTime,
  convertEnumToString,
  convertToTitleCase,
  getUserName,
} from '~/utils/helper';
import { repeatWeekOptions } from '~/mockData';
// Styles, images, icons
import styles from './TableDetailActivity.module.scss';
import './TableDetail.scss';

type Props = {
  detailActivity: IActivityByUser;
};

const cx = classNames.bind(styles);

const columnsDocument = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<IDetailActivity>[] => {
  return [
    {
      key: 'name',
      title: t('activities_detail_table_name_title'),
      dataIndex: 'name',
      render(_, record) {
        return <span className={cx('name')}>{convertCamelCaseToTitleCase(record.name) || EMPTY_STRING}</span>;
      },
      width: '20%',
    },
    {
      key: 'oldValue',
      title: t('activities_detail_table_name_old_value'),
      render(_, record) {
        const oldValue = record?.oldValue as IDocumentDetailActivity;
        return (
          <Link to={oldValue?.url} className={cx('link')} target='_blank'>
            {oldValue?.name}
          </Link>
        );
      },
      width: '40%',
    },
    {
      key: 'newValue',
      title: t('activities_detail_table_name_new_value'),
      render(_, record) {
        const newValue = record?.newValue as IDocumentDetailActivity;
        return (
          <Link to={newValue?.url} className={cx('link')} target='_blank'>
            {newValue?.name}
          </Link>
        );
      },
      width: '40%',
    },
  ];
};

const columnsTask = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<IDetailActivity>[] => {
  const getDayLabel = (day: number): string => {
    const option = repeatWeekOptions.find((opt) => opt.value === day);
    return option ? option.label : EMPTY_STRING;
  };

  const handleDataRender = <K extends keyof IFormTaskActivity>(key: K, value: IFormTaskActivity[K]) => {
    let fromDate;
    switch (key) {
      case FormTaskEnum.RECURRENCE:
      case FormTaskEnum.STATUS:
      case FormTaskEnum.END_TYPE:
      case FormTaskEnum.DESCRIPTION:
        return <>{value ? convertEnumToString(value as string) : EMPTY_STRING}</>;

      case FormTaskEnum.REPEAT_MONTH_ON:
        const formattedDay = moment(fromDate).format('Do');
        return (
          <>{value ? t('task_modal_repeat_month_label', { dayMonth: formattedDay || EMPTY_STRING }) : EMPTY_STRING}</>
        );

      case FormTaskEnum.UNTIL_DATE:
      case FormTaskEnum.DUE_DATE:
      case FormTaskEnum.TO_DATE:
      case FormTaskEnum.FROM_DATE:
        fromDate = value;
        return <>{value ? convertDateToFormatTime(value as string, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>;

      case FormTaskEnum.ASSIGNEE:
      case FormTaskEnum.RELATES:
        if (Array.isArray(value)) {
          const userNames = value
            .map((user: IAssigneeRelateActivity) => getUserName(user?.firstName, user?.middleName, user?.lastName))
            .filter(Boolean);

          return <>{userNames.length > DEFAULT_NUMBER_ZERO ? userNames.join(', ') : EMPTY_STRING}</>;
        }

        return <>{EMPTY_STRING}</>;

      case FormTaskEnum.REPEAT_EVERY:
        return <>{value ?? EMPTY_STRING}</>;

      case FormTaskEnum.REPEAT_WEEK_ON:
        if (Array.isArray(value)) {
          const days = value.map((dayIndex) => getDayLabel(Number(dayIndex))).join(', ');
          return <>{days || EMPTY_STRING}</>;
        }
        return <>{EMPTY_STRING}</>;

      case FormTaskEnum.SEND_REMINDER_EMAIL:
        return <>{convertToTitleCase(convertBooleanToString(value as boolean))}</>;

      default:
        return EMPTY_STRING;
    }
  };

  return [
    {
      key: 'name',
      title: t('activities_detail_table_name_title'),
      dataIndex: 'name',
      render(_, record) {
        return <span className={cx('name')}>{convertCamelCaseToTitleCase(record.name) || EMPTY_STRING}</span>;
      },
      width: '20%',
    },
    {
      key: 'oldValue',
      title: t('activities_detail_table_name_old_value'),
      render(_, record) {
        return <>{handleDataRender(record.name as keyof IFormTask, record.oldValue) || EMPTY_STRING}</>;
      },
      width: '40%',
    },
    {
      key: 'newValue',
      title: t('activities_detail_table_name_new_value'),
      render(_, record) {
        return <>{handleDataRender(record.name as keyof IFormTask, record.newValue) || EMPTY_STRING}</>;
      },
      width: '40%',
    },
  ];
};

const columnsSendEmail = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<IDetailActivity>[] => {
  return [
    {
      key: 'name',
      title: t('activities_detail_table_name_title'),
      dataIndex: 'name',
      width: '20%',
      render(_, record) {
        return <span className={cx('name')}>{convertCamelCaseToTitleCase(record.name) || EMPTY_STRING}</span>;
      },
    },
    {
      key: 'oldValue',
      dataIndex: 'oldValue',
      title: t('activities_detail_table_name_old_value'),
      width: '40%',
      render(_, record) {
        const renderValue = () => {
          switch (record?.name?.toUpperCase()) {
            case ActivitySendEmailDetailEnum.CONTENT:
              return (
                <div className={cx('textEditor')}>
                  <TextEditor
                    mode={ModeTextEditorEnum.VIEW}
                    theme={ThemeTextEditorEnum.BUBBLE}
                    value={record?.oldValue}
                  />
                </div>
              );

            case ActivitySendEmailDetailEnum.FILES:
              const oldValue = record?.oldValue as { name: string; url: string }[];
              return (
                <div className={cx('filesGroup')}>
                  {oldValue &&
                    Array.isArray(oldValue) &&
                    oldValue?.map((item, index) => (
                      <Link key={index} className={cx('link')} to={item?.url} target='_blank'>
                        {item?.name}
                      </Link>
                    ))}
                </div>
              );

            default:
              return <span className={cx('tableValue')}>{record?.oldValue}</span>;
          }
        };

        return <>{renderValue()}</>;
      },
    },
    {
      key: 'newValue',
      dataIndex: 'newValue',
      title: t('activities_detail_table_name_new_value'),
      width: '40%',
      render(_, record) {
        const renderValue = () => {
          switch (record?.name?.toUpperCase()) {
            case ActivitySendEmailDetailEnum.CONTENT:
              return (
                <div className={cx('textEditor')}>
                  <TextEditor
                    mode={ModeTextEditorEnum.VIEW}
                    theme={ThemeTextEditorEnum.BUBBLE}
                    value={record?.newValue}
                  />
                </div>
              );

            case ActivitySendEmailDetailEnum.FILES:
              const newValue = record?.newValue as { name: string; url: string }[];
              return (
                <div className={cx('filesGroup')}>
                  {newValue &&
                    Array.isArray(newValue) &&
                    newValue?.map((item, index) => (
                      <Link key={index} className={cx('link')} to={item?.url} target='_blank'>
                        {item?.name}
                      </Link>
                    ))}
                </div>
              );

            default:
              return <span className={cx('tableValue')}>{record?.newValue}</span>;
          }
        };

        return <>{renderValue()}</>;
      },
    },
  ];
};

const TableDetailActivity = (props: Props) => {
  //#region Destructuring Props
  const { detailActivity } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const renderColumnsTable = () => {
    switch (detailActivity.businessModel) {
      case BusinessModelEnum.TASK:
        return columnsTask(t);

      case BusinessModelEnum.EMAIL:
        return columnsSendEmail(t);

      case BusinessModelEnum.DOCUMENT:
        return columnsDocument(t);

      default:
        return [];
    }
  };
  //#endregion Handle Function

  return (
    <div id='tableDetailActivityComponent' className={cx('tableDetail')}>
      <BaseTable columns={renderColumnsTable()} dataSource={detailActivity.detail || []} />
    </div>
  );
};

export default TableDetailActivity;
