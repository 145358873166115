// Libs
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
// Components, Layouts, Pages
import { AuthLayout, FreeLayout } from '~/layouts';
import { NotFoundPage, ProtectedRoute } from '~/components';
// Others
import {
  adminRoute,
  authRoutes,
  caregiverRoute,
  privateAdminRoutes,
  privateCaregiverRoutes,
  privateStaffRoutes,
  publicRoute,
  publicRoutes,
  staffRoute,
} from './utils/constants/route';
import { AccountRoleCodesEnum } from './utils/enum';
import { EMPTY_STRING } from './utils/constants/common';

type Props = {};

const App = (props: Props) => {
  return (
    <BrowserRouter>
      <ToastContainer />

      <Routes>
        <Route path={publicRoute.base} element={<Navigate to={`${adminRoute.base}`} />} />

        <Route element={<AuthLayout />}>
          {authRoutes.map((route, index) => {
            const Page = route.component || '';
            return (
              <Route key={index} path={route.path} element={<Page />}>
                {route.children &&
                  route.children.length > 0 &&
                  route.children.map((childRoute, index) => {
                    const ChildComponent = childRoute.component;
                    return (
                      <Route
                        key={index}
                        path={childRoute.path}
                        index={childRoute.index ?? false}
                        element={<ChildComponent />}
                      />
                    );
                  })}
              </Route>
            );
          })}
        </Route>

        {/* Public routes  */}
        <Route element={<FreeLayout />}>
          {publicRoutes.map((route, index) => {
            const Page = route.component || '';
            return (
              <Route key={index} path={route.path} element={<Page />}>
                {route.children &&
                  route.children.length > 0 &&
                  route.children.map((childRoute, index) => {
                    const ChildComponent = childRoute.component;
                    return (
                      <Route
                        key={index}
                        path={childRoute.path}
                        index={childRoute.index ?? false}
                        element={<ChildComponent />}
                      />
                    );
                  })}
              </Route>
            );
          })}
        </Route>

        {/* ADMIN - Protected these routes */}
        <Route element={<ProtectedRoute roles={AccountRoleCodesEnum.ADMIN} />}>
          <Route path={adminRoute.base} element={<FreeLayout />}>
            {privateAdminRoutes.map((route, index) => {
              const Page = route.component || '';
              return (
                <Route key={index} path={route.path} element={<Page />}>
                  {route.children &&
                    route.children.length > 0 &&
                    route.children.map((childRoute, index) => {
                      const ChildComponent = childRoute.component;
                      return (
                        <Route key={index} path={childRoute.path} element={<ChildComponent />}>
                          {childRoute.children &&
                            childRoute.children.length > 0 &&
                            childRoute.children.map((nestedRoute, index) => {
                              const ChildComponent = nestedRoute.component || EMPTY_STRING;

                              return (
                                <Route
                                  index={nestedRoute.index}
                                  key={index}
                                  path={nestedRoute.path}
                                  element={<ChildComponent />}
                                />
                              );
                            })}
                        </Route>
                      );
                    })}
                </Route>
              );
            })}
          </Route>
        </Route>

        {/* STAFF - Protected these routes */}
        <Route element={<ProtectedRoute roles={AccountRoleCodesEnum.EMPLOYEE} />}>
          <Route path={staffRoute.base} element={<FreeLayout />}>
            {privateStaffRoutes.map((route, index) => {
              const Page = route.component || '';
              return (
                <Route key={index} path={route.path} element={<Page />}>
                  {route.children &&
                    route.children.length > 0 &&
                    route.children.map((childRoute, index) => {
                      const ChildComponent = childRoute.component;
                      return (
                        <Route key={index} path={childRoute.path} element={<ChildComponent />}>
                          {childRoute.children &&
                            childRoute.children.length > 0 &&
                            childRoute.children.map((nestedRoute, index) => {
                              const ChildComponent = nestedRoute.component || EMPTY_STRING;

                              return (
                                <Route
                                  index={nestedRoute.index}
                                  key={index}
                                  path={nestedRoute.path}
                                  element={<ChildComponent />}
                                />
                              );
                            })}
                        </Route>
                      );
                    })}
                </Route>
              );
            })}
          </Route>
        </Route>

        {/* CAREGIVER - Protected these routes */}
        <Route element={<ProtectedRoute roles={AccountRoleCodesEnum.CAREGIVER} />}>
          <Route path={caregiverRoute.base} element={<FreeLayout />}>
            {privateCaregiverRoutes.map((route, index) => {
              const Page = route.component || '';
              return (
                <Route key={index} path={route.path} element={<Page />}>
                  {route.children &&
                    route.children.length > 0 &&
                    route.children.map((childRoute, index) => {
                      const ChildComponent = childRoute.component;
                      return (
                        <Route
                          key={index}
                          path={childRoute.path}
                          index={childRoute.index ?? false}
                          element={<ChildComponent />}
                        />
                      );
                    })}
                </Route>
              );
            })}
          </Route>
        </Route>

        {/* Catch all */}
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
