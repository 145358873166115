// Libs
import { GoogleMap, Marker } from '@react-google-maps/api';
import classNames from 'classnames/bind';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
// Components, Layouts, Pages
import { BaseInput } from '~/components';
// Others
import {
  DEFAULT_MAP_ZOOM,
  DEFAULT_NUMBER_ZERO,
  DEFAULT_STATUS_OK,
  EMPTY_STRING,
  WIDTH_FULL,
} from '~/utils/constants/common';
import { IPosition } from '~/utils/interface/common';
// Styles, images, icons
import styles from './BaseGoogleMap.module.scss';

type Props = {
  position?: IPosition;
  onGetAddress: (value: string) => void;
  onGetPosition: (value: IPosition) => void;
};

const cx = classNames.bind(styles);

const BaseGoogleMap = (props: Props) => {
  //#region Destructuring Props
  const { position, onGetAddress, onGetPosition } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [positionSelected, setPositionSelected] = useState<IPosition>({
    lat: DEFAULT_NUMBER_ZERO,
    lng: DEFAULT_NUMBER_ZERO,
  });
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();
  const [options, setOptions] = useState<{ id: string; value: string }[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (navigator.geolocation && !position) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setPositionSelected({ lat: latitude, lng: longitude });
        onGetPosition && onGetPosition({ lat: latitude, lng: longitude });
      });
    }

    if (position) {
      setPositionSelected(position);
      onGetPosition && onGetPosition(position);
    }
  }, [position]);
  //#endregion Implement Hook

  //#region Handle Function
  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleSelect = async (address: string) => {
    setValue(address, false);
    onGetAddress && onGetAddress(address);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setPositionSelected({ lat, lng });
    onGetPosition && onGetPosition({ lat, lng });
  };

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setValue(value);
    if (value === EMPTY_STRING) {
      clearSuggestions();
      setOptions([]);
      onGetAddress && onGetAddress(value);
      return;
    }
    onGetAddress && onGetAddress(value);

    if (status === DEFAULT_STATUS_OK) {
      const options = data.map(({ place_id: placeId, description }) => ({
        id: placeId,
        value: description,
      }));
      setOptions(options);
    }
  };

  const renderSuggestions = () =>
    options.map((suggestion) => {
      const { id, value } = suggestion;

      return (
        <li key={id} onClick={() => handleSelect(suggestion.value)} className={cx('itemLocation')}>
          {value}
        </li>
      );
    });
  //#endregion Handle Function

  return (
    <div id='baseGoogleMapComponent' className={cx('container')}>
      {/* <div ref={ref} className={cx('locationInput')}>
        <BaseInput
          id='location'
          name='location'
          label={t('modal_add_time_clock_location_label')}
          placeholder={t('modal_add_time_clock_location_label')}
          onChange={handleSearch}
          value={value}
        />
        {status === DEFAULT_STATUS_OK && <ul className={cx('locationList')}>{renderSuggestions()}</ul>}
      </div> */}

      <div className={cx('label')}>{t('modal_add_time_clock_map_view_label')}</div>

      <GoogleMap
        mapContainerStyle={{ width: WIDTH_FULL, height: 340 }}
        center={positionSelected}
        zoom={DEFAULT_MAP_ZOOM}
      >
        {positionSelected && <Marker position={positionSelected} />}
      </GoogleMap>
    </div>
  );
};

export default BaseGoogleMap;
