import axiosClient from '~/apis/axiosClient';
import { BaseResponse } from '~/utils/interface/common';
import { urlApiQuickBook } from '~/utils/constants/api';
import { IQueryGetTokenQuickBook, IResponseGetAuthURL } from '~/utils/interface/quickBook';

const quickBookApi = {
  getAuthUrl() {
    const url = `${urlApiQuickBook.getAuthUrl}`;
    return axiosClient.get<BaseResponse<IResponseGetAuthURL>>(url);
  },

  disconnect() {
    const url = `${urlApiQuickBook.disconnect}`;
    return axiosClient.post<BaseResponse>(url);
  },

  getTokenQuickBook(payload: IQueryGetTokenQuickBook) {
    const { code, state, realmId } = payload;
    const url = `${urlApiQuickBook.getToken}?code=${code}&state=${state}&realmId=${realmId}`;
    return axiosClient.get<BaseResponse>(url);
  },
};

export default quickBookApi;
