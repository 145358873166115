// Libs
import classNames from 'classnames/bind';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
// Components, Layouts, Pages
import {
  AssigneePopup,
  BaseButton,
  BaseSelect,
  BaseTextarea,
  CheckboxSingle,
  Loading,
  Modal,
  ModalUnderDevelopment,
  RecurrenceTaskForm,
} from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import {
  CODE_MESSAGE_TASK_DELETED,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ONE,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { IFormTask, IPayloadUpdateTask } from '~/utils/interface/task';
import { ErrorData, IAddAssignee, IBaseOption, IListQueryParams } from '~/utils/interface/common';
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  FormTaskDateTypeEnum,
  RecurrenceEnum,
  RelatesClientTypeEnum,
} from '~/utils/enum';
import { optionsMockProjectStatus } from '~/mockData';
import { createTask, getTaskDetail, updateTask } from '~/thunks/task/taskThunk';
import { getListAssignee } from '~/thunks/user/userThunk';
import { getListRelates } from '~/thunks/crm/clients/clientsThunk';
import { taskActions } from '~/thunks/task/taskSlice';
import { scheduleActions } from '~/thunks/schedule/scheduleSlice';
import { IRelatesQueryParams } from '~/utils/interface/crm/clients';
import { TFormTaskModalType } from '~/utils/type/common';
// Styles, images, icons
import styles from './FormTaskModal.module.scss';
import { icons } from '~/assets';

type Props = {
  taskId?: string;
  isOpen: boolean;
  type?: TFormTaskModalType;
  onClose: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      recurrence: yup.string().required(t('error_message_recurrence_required')),
      repeatEvery: yup
        .number()
        .nullable()
        .min(DEFAULT_NUMBER_ONE, t('error_message_repeat_every_min_valid'))
        .typeError(t('error_message_repeat_every_number_valid'))
        .when('recurrence', {
          is: (recurrence: string) =>
            recurrence === RecurrenceEnum.DAILY ||
            recurrence === RecurrenceEnum.WEEKLY ||
            recurrence === RecurrenceEnum.MONTHLY,
          then: (schema) => schema.required(t('error_message_repeat_every_required')),
          otherwise: (schema) => schema.optional(),
        }),
      fromDate: yup
        .string()
        .nullable()
        .when('recurrence', {
          is: (recurrence: string) =>
            recurrence === RecurrenceEnum.DAILY ||
            recurrence === RecurrenceEnum.WEEKLY ||
            recurrence === RecurrenceEnum.MONTHLY,
          then: (schema) => schema.required(t('error_message_from_required')),
          otherwise: (schema) => schema.optional(),
        }),
      toDate: yup
        .string()
        .nullable()
        .when('recurrence', {
          is: (recurrence: string) =>
            recurrence === RecurrenceEnum.DAILY ||
            recurrence === RecurrenceEnum.WEEKLY ||
            recurrence === RecurrenceEnum.MONTHLY,
          then: (schema) => schema.required(t('error_message_to_required')),
          otherwise: (schema) => schema.optional(),
        }),
      repeatWeekOn: yup
        .array()
        .nullable()
        .when('recurrence', {
          is: (recurrence: string) => recurrence === RecurrenceEnum.WEEKLY,
          then: (schema) =>
            schema
              .of(yup.number())
              .min(DEFAULT_NUMBER_ONE, t('error_message_repeat_week_on_required'))
              .required(t('error_message_repeat_week_on_required')),
          otherwise: (schema) => schema.optional(),
        }),
      repeatMonthOn: yup
        .string()
        .nullable()
        .when('recurrence', {
          is: (recurrence: string) => recurrence === RecurrenceEnum.MONTHLY,
          then: (schema) => schema.required(t('error_message_repeat_month_on_required')),
          otherwise: (schema) => schema.optional(),
        }),
      endType: yup
        .string()
        .nullable()
        .when('recurrence', {
          is: (recurrence: string) =>
            recurrence === RecurrenceEnum.DAILY ||
            recurrence === RecurrenceEnum.WEEKLY ||
            recurrence === RecurrenceEnum.MONTHLY,
          then: (schema) => schema.required(t('error_message_end_type_required')),
          otherwise: (schema) => schema.optional(),
        }),
      untilDate: yup
        .string()
        .nullable()
        .when('endType', {
          is: (endType: string) => endType === FormTaskDateTypeEnum.UNTIL,
          then: (schema) => schema.required(t('error_message_until_date_required')),
          otherwise: (schema) => schema.optional(),
        }),
      dueDate: yup
        .string()
        .nullable()
        .when('recurrence', {
          is: (recurrence: string) => recurrence === RecurrenceEnum.NONE,
          then: (schema) => schema.required(t('common_error_message_due_date_required')),
          otherwise: (schema) => schema.optional(),
        }),
      status: yup.string().optional(),
      assignee: yup.array().nullable().optional(),
      relates: yup.array().nullable().optional(),
      description: yup.string().required(t('common_error_message_description_required')),
      isSendReminderEmail: yup.boolean().optional(),
    })
    .required();
};

const defaultValues: IFormTask = {
  recurrence: RecurrenceEnum.NONE,
  repeatEvery: DEFAULT_NUMBER_ONE,
  fromDate: null,
  toDate: null,
  repeatWeekOn: null,
  repeatMonthOn: null,
  endType: null,
  untilDate: null,
  dueDate: null,
  status: optionsMockProjectStatus[DEFAULT_NUMBER_ONE].value,
  assignee: null,
  relates: null,
  description: EMPTY_STRING,
  isSendReminderEmail: false,
};

const FormTaskModal = (props: Props) => {
  //#region Destructuring Props
  const { taskId, isOpen, type = 'TASK', onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
    trigger,
    formState: { errors, isDirty },
  } = useForm<IFormTask>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });

  const watchRecurrence = watch('recurrence');
  const watchEndType = watch('endType');
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isDevelopment, setIsDevelopment] = useState<boolean>(false);
  // const [initialTaskData, setInitialTaskData] = useState<IFormTask>(defaultValues);
  const [listAssignee, setListAssignee] = useState<IAddAssignee[]>([]);
  const [listRelates, setListRelates] = useState<IAddAssignee[]>([]);
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTaskDeleted, setIsTaskDeleted] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (watchRecurrence !== RecurrenceEnum.NONE) {
      setValue('dueDate', null);
      !watchEndType && setValue('endType', FormTaskDateTypeEnum.NEVER);
    }

    if (watchRecurrence === RecurrenceEnum.NONE) {
      setValue('repeatEvery', null);
      setValue('fromDate', null);
      setValue('toDate', null);
      setValue('endType', null);
      setValue('untilDate', null);
      setValue('repeatWeekOn', null);
      setValue('repeatMonthOn', null);
    }

    if (watchRecurrence !== RecurrenceEnum.WEEKLY) {
      setValue('repeatWeekOn', null);
    }

    if (watchRecurrence !== RecurrenceEnum.MONTHLY) {
      setValue('repeatMonthOn', null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchRecurrence]);

  useEffect(() => {
    if (watchEndType === FormTaskDateTypeEnum.NEVER) {
      setValue('untilDate', null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchEndType]);

  useEffect(() => {
    const payload: IListQueryParams = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      roles: AccountRoleCodesEnum.EMPLOYEE,
    };

    handleGetListAssignee(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newParamObject: IRelatesQueryParams = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      clientTypes: [RelatesClientTypeEnum.CLIENT, RelatesClientTypeEnum.REFERRAL],
    };

    handleGetListRelates(newParamObject);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!taskId) return;

    handleGetTaskDetail(taskId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListAssignee = (params: IListQueryParams) => {
    dispatch(getListAssignee(params))
      .unwrap()
      .then((response) => {
        if (!response) return;

        const { responses } = response.data;
        setListAssignee(responses);
      })
      .catch((_error) => {});
  };

  const handleGetListRelates = (params: IRelatesQueryParams) => {
    dispatch(getListRelates(params))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { responses } = res?.data;

        const dataConverted: IAddAssignee[] = responses?.map((item) => ({ ...item }));

        setListRelates(dataConverted);
      })
      .catch((_error) => {})
      .finally(() => {});
  };

  const handleGetTaskDetail = (taskId: string) => {
    setIsLoading(true);

    dispatch(getTaskDetail(taskId))
      .unwrap()
      .then((_res) => {
        if (!_res) return;

        const taskDetail = _res?.data;

        reset({
          recurrence: taskDetail?.recurrence,
          repeatEvery: taskDetail?.repeatEvery,
          fromDate: taskDetail?.fromDate,
          toDate: taskDetail?.toDate,
          endType: taskDetail?.endType,
          untilDate: taskDetail?.untilDate,
          repeatWeekOn: taskDetail?.repeatWeekOn,
          repeatMonthOn: taskDetail?.repeatMonthOn,
          dueDate: taskDetail?.dueDate,
          status: taskDetail?.status,
          assignee: taskDetail?.assignees?.map((item) => item?.id),
          relates: taskDetail?.relates?.map((item) => item?.id),
          description: taskDetail?.description,
          isSendReminderEmail: taskDetail?.isSendReminderEmail,
        });
      })
      .catch((error) => {
        const { code } = error?.response?.data as ErrorData;

        if (code === CODE_MESSAGE_TASK_DELETED) setIsTaskDeleted(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseDevelopment = () => {
    setIsDevelopment(!isDevelopment);
  };

  const handleSubmitForm = (data: IFormTask) => {
    if (taskId) {
      // const dataChanges = compareDataUpdate(initialTaskData, data);
      const payload: IPayloadUpdateTask = {
        taskId: taskId,
        body: data,
      };
      handleEditTask(payload);
    } else {
      handleCreateTask(data);
    }
  };

  const handleEditTask = (payload: IPayloadUpdateTask) => {
    setIsLoading(true);

    dispatch(updateTask(payload))
      .unwrap()
      .then((_res) => {
        handleCloseFormTask();

        if (type === 'SCHEDULE') {
          return dispatch(scheduleActions.setRefreshOfficeSchedule(true));
        }

        return dispatch(taskActions.setRefreshList(true));
      })
      .catch((_error) => {})
      .finally(() => setIsLoading(false));
  };

  const handleCreateTask = (data: IFormTask) => {
    setIsLoading(true);

    dispatch(createTask(data))
      .unwrap()
      .then((_res) => {
        handleCloseFormTask();

        return dispatch(taskActions.setRefreshList(true));
      })
      .catch((_error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseFormTask = () => {
    reset(defaultValues);
    setIsTaskDeleted(false);
    onClose();
  };

  const renderTitleModal = () => {
    if (isTaskDeleted) return EMPTY_STRING;

    return taskId ? t('modal_edit_task_title') : t('task_modal_create_task_title');
  };
  //#endregion Handle Function

  return (
    <Modal isOpen={isOpen} onClose={handleCloseFormTask} title={renderTitleModal()}>
      {isTaskDeleted ? (
        <div className={cx('textDevelopment')}>{`${t('task_modal_task_deleted_label')}`}</div>
      ) : (
        <FormProvider
          {...({
            control,
            watch,
            setValue,
            trigger,
            formState: { errors },
          } as UseFormReturn<IFormTask>)}
        >
          <form id='taskModalComponent' className={cx('taskModalComponent')} onSubmit={handleSubmit(handleSubmitForm)}>
            <div className={cx('contentModal')}>
              <RecurrenceTaskForm />

              <div className={cx('twoCol')}>
                <Controller
                  name='assignee'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <AssigneePopup
                      label={t('task_modal_task_assignees_label')}
                      placeholder={t('common_placeholder_select')}
                      value={value || []}
                      assigneeList={listAssignee}
                      onChange={(value) => onChange(value)}
                      errorMessage={errors.assignee?.message}
                    />
                  )}
                />

                <Controller
                  name='status'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseSelect
                      value={value}
                      options={optionsMockProjectStatus}
                      label={t('task_modal_task_status_label')}
                      placeholder={t('common_select_placeholder')}
                      onChange={(optionSelected: IBaseOption) => {
                        onChange(optionSelected?.value);
                      }}
                    />
                  )}
                />
              </div>

              <Controller
                name='relates'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <AssigneePopup
                    label={t('task_modal_task_related_to_label')}
                    value={value || []}
                    assigneeList={listRelates}
                    placeholder={t('common_placeholder_select')}
                    onChange={(value) => onChange(value)}
                    errorMessage={errors.relates?.message}
                  />
                )}
              />

              <Controller
                name='description'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseTextarea
                    id='description'
                    label={t('task_modal_task_label_description')}
                    //placeholder={t('task_modal_task_description_placeholder')}
                    value={value}
                    onChange={onChange}
                    height={100}
                    required
                    messageError={errors.description?.message}
                  />
                )}
              />

              <Controller
                name='isSendReminderEmail'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CheckboxSingle
                    label={t('task_modal_task_send_reminder_email')}
                    value={value}
                    onChange={(checked: boolean, name?: string) => {
                      onChange(checked);
                    }}
                  />
                )}
              />
            </div>

            <div className={cx('footerButton')}>
              <BaseButton type='button' text={t('common_cancel_label')} width={65} onClick={handleCloseFormTask} />

              <BaseButton
                type='submit'
                text={taskId ? t('common_update_label') : t('common_save_label')}
                typeStyle={ButtonTypeEnum.PRIMARY}
                width={80}
                disabled={taskId ? !isDirty : false}
              />
            </div>
          </form>
        </FormProvider>
      )}

      {isDevelopment && <ModalUnderDevelopment onClose={handleCloseDevelopment} />}

      {isLoading && <Loading />}
    </Modal>
  );
};

export default FormTaskModal;
