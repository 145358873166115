import { StorageEnum, WebSocketEvent, WebSocketType } from '../enum';
import { IPayloadWSS } from '../interface/wss';

export const login = async (webSocket: WebSocket) => {
  const accountId = localStorage.getItem(StorageEnum.USER_ID);
  const deviceId = localStorage.getItem(StorageEnum.DEVICE_ID);
  if (!accountId || !deviceId) {
    return;
  }
  const payload: IPayloadWSS = {
    typeFunction: WebSocketType.USE_APP,
    data: {
      event: WebSocketEvent.LOGIN,
      accountId: +accountId,
      deviceId: deviceId,
    },
  };
  webSocket.send(JSON.stringify(payload));
};

export const joinConversation = async (websocket: WebSocket, conversationId: number) => {
  const payload: IPayloadWSS = {
    typeFunction: WebSocketType.CHAT,
    data: {
      event: WebSocketEvent.JOIN_CONVERSATION,
      conversationId,
    },
  };
  websocket.send(JSON.stringify(payload));
};

export const leaveConversation = async (websocket: WebSocket, conversationId: number) => {
  const accountId = localStorage.getItem(StorageEnum.USER_ID);
  const deviceId = localStorage.getItem(StorageEnum.DEVICE_ID);
  if (!accountId || !deviceId) {
    return;
  }
  const payload: IPayloadWSS = {
    typeFunction: WebSocketType.CHAT,
    data: {
      event: WebSocketEvent.LEAVE_CONVERSATION,
      conversationId,
    },
  };
  websocket.send(JSON.stringify(payload));
};

export const joinListConversation = async (websocket: WebSocket) => {
  const payload: IPayloadWSS = {
    typeFunction: WebSocketType.CHAT,
    data: {
      event: WebSocketEvent.JOIN_LIST_CONVERSATION,
    },
  };

  websocket.send(JSON.stringify(payload));
};

export const leaveListConversation = async (websocket: WebSocket) => {
  const payload: IPayloadWSS = {
    typeFunction: WebSocketType.CHAT,
    data: {
      event: WebSocketEvent.LEAVE_LIST_CONVERSATION,
    },
  };
  websocket.send(JSON.stringify(payload));
};

export const typing = async (websocket: WebSocket, conversationId: number) => {
  const payload: IPayloadWSS = {
    typeFunction: WebSocketType.CHAT,
    data: {
      event: WebSocketEvent.TYPING,
      conversationId,
    },
  };
  websocket.send(JSON.stringify(payload));
};

export const stopTyping = async (websocket: WebSocket, conversationId: number) => {
  const payload: IPayloadWSS = {
    typeFunction: WebSocketType.CHAT,
    data: {
      event: WebSocketEvent.STOP_TYPING,
      conversationId,
    },
  };
  websocket.send(JSON.stringify(payload));
};

export const seenMessage = async (websocket: WebSocket, conversationId: number, messageIds: number[]) => {
  const payload: IPayloadWSS = {
    typeFunction: WebSocketType.CHAT,
    data: {
      event: WebSocketEvent.SEEN_MESSAGE,
      conversationId,
      messageIds,
    },
  };
  websocket.send(JSON.stringify(payload));
};
