// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
// Components, Layouts, Pages
import { ActivitiesTimeLineItem, Spinner } from '~/components';
// Others
import { LoadingContext } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { IActivityByUser, IParamsGetListActivity } from '~/utils/interface/activity';
import { getListActivityByCaregiver, getListActivityByClient } from '~/thunks/activity/activityThunk';
import { DEFAULT_CURRENT_PAGE, DEFAULT_NUMBER_RECORD_TO_FETCH, DEFAULT_NUMBER_ZERO } from '~/utils/constants/common';
// Styles, images, icons
import styles from './ActivitiesTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const ActivitiesTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  const { clientId, caregiverId } = useParams();
  const activitiesRef = useRef<HTMLDivElement | null>(null);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [activities, setActivities] = useState<IActivityByUser[]>([]);
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [totalPage, setTotalPage] = useState<number>(DEFAULT_CURRENT_PAGE);

  const isLoadMoreRef = useRef(isLoadMore);
  const activitiesDataRef = useRef(activities);
  const currentPageRef = useRef(currentPage);
  const totalPageRef = useRef(totalPage);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    isLoadMoreRef.current = isLoadMore;
    activitiesDataRef.current = activities;
    currentPageRef.current = currentPage;
    totalPageRef.current = totalPage;
  }, [isLoadMore, activities, currentPage, totalPage]);

  useEffect(() => {
    if (!clientId && !caregiverId) return;

    const payload: IParamsGetListActivity = {
      userId: clientId || caregiverId!,
      params: {
        page: DEFAULT_CURRENT_PAGE,
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
      },
    };

    loading?.show();

    clientId && handleGetListActivityByClient(payload);
    caregiverId && handleGetListActivityByCaregiver(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, caregiverId]);

  useEffect(() => {
    const activitiesElement = activitiesRef.current;

    if (activitiesElement) {
      activitiesElement.addEventListener('scroll', handleScroll);

      return () => {
        activitiesElement.removeEventListener('scroll', handleScroll);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleScroll = () => {
    if (activitiesRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = activitiesRef.current;

      if (scrollTop + clientHeight >= scrollHeight) {
        handleLoadMore();
      }
    }
  };

  const handleLoadMore = () => {
    if (!clientId && !caregiverId) return;

    if (!isLoadMoreRef.current && currentPageRef.current < totalPageRef.current) {
      setIsLoadMore(true);
      const params: IParamsGetListActivity = {
        userId: clientId || caregiverId!,
        params: {
          page: currentPageRef.current + 1,
          limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
        },
      };

      clientId && handleGetListActivityByClient(params);
      caregiverId && handleGetListActivityByCaregiver(params);
    }
  };

  const handleGetListActivityByClient = (payload: IParamsGetListActivity) => {
    dispatch(getListActivityByClient(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { pagination, responses } = res?.data;

        setTotalPage(pagination?.totalPages);

        if (isLoadMoreRef.current) {
          setCurrentPage(pagination?.page);
          setActivities([...activitiesDataRef.current, ...responses]);
          return;
        }

        setActivities(responses);
      })
      .catch((_error) => {})
      .finally(() => {
        loading?.hide();
        setIsLoadMore(false);
      });
  };

  const handleGetListActivityByCaregiver = (payload: IParamsGetListActivity) => {
    dispatch(getListActivityByCaregiver(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { pagination, responses } = res?.data;

        setTotalPage(pagination?.totalPages);

        if (isLoadMoreRef.current) {
          setCurrentPage(pagination?.page);
          setActivities([...activitiesDataRef.current, ...responses]);
          return;
        }

        setActivities(responses);
      })
      .catch((_error) => {})
      .finally(() => {
        loading?.hide();
        setIsLoadMore(false);
      });
  };
  //#endregion Handle Function

  return (
    <div id='activitiesTabComponent' className={cx('activitiesTabComponent')}>
      <div className={cx('bodyWrap')} ref={activitiesRef}>
        {activities && activities?.length > DEFAULT_NUMBER_ZERO ? (
          <>
            {activities?.map((item, index) => (
              <ActivitiesTimeLineItem key={index} data={item} />
            ))}
            <div className={cx('containerSpinner', currentPage >= totalPage && 'hideSpinner')}>
              {isLoadMore && <Spinner />}
            </div>
          </>
        ) : (
          <div className={cx('noData')}>{t('common_empty_data')}</div>
        )}
      </div>
    </div>
  );
};

export default ActivitiesTab;
