// Libs
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// Others
import { DEFAULT_CURRENT_PAGE, DEFAULT_NUMBER_RECORD_TO_FETCH } from '~/utils/constants/common';
import { IQueryBase } from '~/utils/interface/common';
import { INotification } from '~/utils/interface/notification';
import { getListNotifications, markedReadNotification } from './notificationsThunk';
import { StatusNotificationEnum } from '~/utils/enum';

export interface NotificationsState {
  notificationsUnread: number;
  notificationList: INotification[];
  currentPage: number;
  totalPages: number;
  refreshNotificationList: boolean;
  isLoading: boolean;
  queryParams: IQueryBase;
}

const initialState: NotificationsState = {
  notificationsUnread: 0,
  notificationList: [],
  currentPage: 0,
  totalPages: 0,
  refreshNotificationList: false,
  isLoading: false,
  queryParams: {
    page: DEFAULT_CURRENT_PAGE,
    limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
  },
};

const notificationsSlice = createSlice({
  name: 'notificationsState',
  initialState,
  reducers: {
    setParamsNotification(state, action: PayloadAction<IQueryBase>) {
      state.queryParams = { ...state.queryParams, ...action.payload };
    },
    setUnreadNumberNotification(state, action: PayloadAction<number>) {
      state.notificationsUnread = action.payload;
    },
    refreshData(state, action: PayloadAction<boolean>) {
      state.refreshNotificationList = action.payload;
      state.queryParams = initialState.queryParams;
    },
  },
  extraReducers(builder) {
    // Get list notifications
    builder
      .addCase(getListNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getListNotifications.fulfilled, (state, action) => {
        state.isLoading = false;
        const { responses, countNotificationUnRead, pagination } = action.payload.data;
        const page = pagination.page;

        state.notificationList =
          page === DEFAULT_CURRENT_PAGE ? [...responses] : [...state.notificationList, ...responses];

        state.notificationsUnread = countNotificationUnRead ?? 0;
        state.currentPage = pagination.page;
        state.totalPages = pagination.totalPages;
        state.refreshNotificationList = false;
      })
      .addCase(getListNotifications.rejected, (state) => {
        state.isLoading = false;
      });

    // Marked read notification
    builder
      .addCase(markedReadNotification.pending, (state) => {})
      .addCase(markedReadNotification.fulfilled, (state, action) => {
        const index = state.notificationList.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state.notificationList[index].status = StatusNotificationEnum.READ;
        }
        state.notificationsUnread = action.payload.data.countNotificationUnRead;
      })
      .addCase(markedReadNotification.rejected, (state) => {});
  },
});

export const notificationActions = notificationsSlice.actions;

const notificationsReducer = notificationsSlice.reducer;
export default notificationsReducer;
