import { urlApiCareAlert } from '~/utils/constants/api';
import { BaseResponse, IListDataResponse, IListQueryParams } from '~/utils/interface/common';
import axiosClient from '~/apis/axiosClient';
import { ICareAlerts } from '~/utils/interface/careAlert';

const careAlertApi = {
  getList(params: IListQueryParams) {
    const url = `${urlApiCareAlert.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<ICareAlerts[]>>>(url, { params });
  },
};

export default careAlertApi;
