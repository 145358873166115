import moment from 'moment';
import { Event, View, Views } from 'react-big-calendar';
import { statusColor } from '~/utils/constants/color';
import { EMPTY_STRING, SCHEDULE_MODE_FULL } from '~/utils/constants/common';
import { DEFAULT_SCHEDULE_TIMELINE_VIEWS } from '~/utils/constants/component';
import { StatusEnum } from '~/utils/enum';
import { hexToRgba } from '~/utils/helper';
import {
  IOfficeSchedule,
  IScheduleEvent,
  IScheduleTimelineData,
  IScheduleTimelineEvent,
  ITimelineEvent,
  ITimelineGroup,
  ITimelinePreparedData,
} from '~/utils/interface/schedule';
import { OpacityRange } from '~/utils/type/common';

export const prepareMonthEventsData = (events: IScheduleEvent[]): Event[] => {
  const monthEvents: Event[] = [];
  for (let event of events) {
    let monthEvent = monthEvents.find((monthEvent: Event) => {
      return moment(monthEvent.start).isSame(moment(event.startDate), 'date');
    });

    if (!monthEvent) {
      monthEvent = {
        start: moment(event.startDate).toDate(),
        end: moment(event.startDate).endOf('date').toDate(),
        resource: [],
      };
      monthEvents.push(monthEvent);
    }

    monthEvent.resource.push(event);
  }
  return monthEvents;
};

export const prepareTimelineEventsData = (events: IScheduleTimelineData[], view: View): ITimelinePreparedData => {
  const timelineEvent: ITimelineEvent[] =
    view === Views.WEEK ? getWeekItemTimelineEvent(events) : getDayItemTimelineEvent(events);
  const timelineGroup: ITimelineGroup[] = getGroupTimelineEvent(events);
  return { event: timelineEvent, group: timelineGroup };
};

export const getWeekItemTimelineEvent = (events: IScheduleTimelineData[]): ITimelineEvent[] => {
  if (events.length <= 0) return [];
  const result: ITimelineEvent[] = [];

  for (let eventItem of events) {
    const { id, events } = eventItem;
    const groupedSchedules: { [key: string]: ITimelineEvent } = {};

    if (!events) return [];
    events.forEach((scheduleItem: IScheduleTimelineEvent) => {
      const groupKey = moment(scheduleItem.startDate).format('YYYY-MM-DD');
      const start_time = moment(scheduleItem.startDate).startOf('day');
      const end_time = moment(scheduleItem.startDate).endOf('day');

      if (!groupedSchedules[groupKey]) {
        groupedSchedules[groupKey] = {
          id: `${scheduleItem.id}-${id}`,
          group: id,
          start_time,
          end_time,
          resource: [],
        };
      }

      const scheduleItemResource: IScheduleEvent = {
        ...scheduleItem,
        startTime: moment(scheduleItem.startTime).format('hh:mm A'),
        endTime: moment(scheduleItem.endTime).format('hh:mm A'),
      };

      groupedSchedules[groupKey]?.resource?.push(scheduleItemResource);
    });

    result.push(...Object.values(groupedSchedules));
  }

  return result;
};

export const getDayItemTimelineEvent = (events: IScheduleTimelineData[]): ITimelineEvent[] => {
  if (events.length <= 0) return [];
  const result: ITimelineEvent[] = [];

  for (let eventItem of events) {
    const { id, events } = eventItem;
    const groupedSchedules: { [key: string]: ITimelineEvent } = {};

    if (!events) return [];
    events.forEach((scheduleItem: IScheduleEvent) => {
      const startTimeString = moment(scheduleItem.startTime).format('HH:mm:ss');
      const endTimeString = moment(scheduleItem.endTime).format('HH:mm:ss');

      const groupKey = moment(`${scheduleItem.startDate} ${startTimeString}`)
        .startOf('hour')
        .format('YYYY-MM-DDTHH:mm:ss');

      const startDateTime = `${scheduleItem.startDate} ${startTimeString}`;
      const endDateTime = `${scheduleItem.endDate} ${endTimeString}`;

      const start_time = moment(startDateTime).startOf('hour');
      const end_time = moment(endDateTime).endOf('hour');

      if (!groupedSchedules[groupKey]) {
        groupedSchedules[groupKey] = {
          id: `${scheduleItem.id}-${id}`,
          group: id,
          start_time,
          end_time,
          resource: [],
        };
      }

      const scheduleItemResource: IScheduleEvent = {
        ...scheduleItem,
        startTime: moment(scheduleItem.startTime).format('hh:mm A'),
        endTime: moment(scheduleItem.endTime).format('hh:mm A'),
      };

      groupedSchedules[groupKey]?.resource?.push(scheduleItemResource);
    });

    result.push(...Object.values(groupedSchedules));
  }

  return result;
};

export const getGroupTimelineEvent = (events: IScheduleTimelineData[]): ITimelineGroup[] => {
  if (events.length <= 0) return [];
  const result: ITimelineGroup[] = [];

  for (let event of events) {
    const { id, avatarUrl, firstName, lastName, events } = event;

    let newGroupItem = result.find((groupAvailable: ITimelineGroup) => groupAvailable.id === id.toString());

    if (!newGroupItem) {
      newGroupItem = {
        id: id,
        title: `${firstName ?? EMPTY_STRING} ${lastName ?? EMPTY_STRING}`,
        avatarUrl: avatarUrl ?? '',
        events: events,
      };

      result?.push(newGroupItem);
    }
  }

  return result;
};

export const prepareMonthOfficeEventsData = (events: IOfficeSchedule[]): Event[] => {
  const monthEvents: Event[] = [];
  for (let event of events) {
    let monthEvent = monthEvents.find((monthEvent: Event) => {
      return moment(monthEvent.start).isSame(moment(event.startDate), 'date');
    });

    if (!monthEvent) {
      monthEvent = {
        start: moment(event.startDate).toDate(),
        end: moment(event.startDate).endOf('date').toDate(),
        resource: [],
      };
      monthEvents.push(monthEvent);
    }

    monthEvent.resource.push(event);
  }
  return monthEvents;
};

export const capitalizeFirstLetter = (view: string) => {
  return view.charAt(0).toUpperCase() + view.slice(1).toLowerCase();
};

/**
 * Checks if the current calendar view is 'week' or 'day' and the schedule mode is 'full'.
 * @param calendarView The current calendar view ('week', 'day', 'month').
 * @param scheduleMode The current schedule mode ('full', 'my').
 * @returns True if the conditions are met, false otherwise.
 */
export const checkTimelineMode = (calendarView: string, scheduleMode: string): boolean => {
  return (
    (calendarView === DEFAULT_SCHEDULE_TIMELINE_VIEWS.week || calendarView === DEFAULT_SCHEDULE_TIMELINE_VIEWS.day) &&
    scheduleMode === SCHEDULE_MODE_FULL
  );
};

export const handleBackgroundColor = ({ bgOpacity = 1, label }: { bgOpacity?: OpacityRange; label?: string }) => {
  switch (label) {
    case StatusEnum.GENERAL:
      return hexToRgba(statusColor.LIME_100, bgOpacity);
    case StatusEnum.MEETING:
      return hexToRgba(statusColor.INDIGO_400, bgOpacity);
    case StatusEnum.HOLIDAY:
      return hexToRgba(statusColor.ORANGE_300, bgOpacity);
    case StatusEnum.TASK:
      return hexToRgba(statusColor.SKY_300, bgOpacity);
    case StatusEnum.VACATION_TIME_OFF:
      return hexToRgba(statusColor.EMERALD_300, bgOpacity);
    default:
      return hexToRgba(statusColor.INDIGO_400, bgOpacity);
  }
};
