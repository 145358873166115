// Libs
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
// Others
import { QUERY_KEY_TAB } from '~/utils/constants/common';
import { ITabItem } from '~/utils/interface/common';
import { TTabsType } from '~/utils/type/common';
// Styles, images, icons
import styles from './Tab.module.scss';

type Props = {
  items: ITabItem[];
  paddingBottom?: string | number;
  enableUrlSync?: boolean;
  type?: TTabsType;
  onChange?: (tab: string) => void;
};

const cx = classNames.bind(styles);

const Tab = (props: Props) => {
  //#region Destructuring Props
  const { items, paddingBottom = 10, enableUrlSync = true, type = 'default', onChange } = props;
  //#endregion Destructuring Props

  //#region Declare
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get(QUERY_KEY_TAB);

  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [tabSelected, setTabSelected] = useState<ITabItem>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!items.length || items.length === 0) return;

    const existedTab = items.find((item) => item.tabParam === currentTab);

    setTabSelected(existedTab ?? items[0]);
  }, [currentTab]);

  useEffect(() => {
    let currentTabParam = currentTab ?? items[0].tabParam;

    if (tabSelected?.tabParam === currentTabParam && tabSelected?.disable) {
      const firstTabEnable = items.find((item) => !item.disable);
      firstTabEnable && setParams(firstTabEnable.tabParam, true);
    }
  }, [tabSelected, currentTab]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleTabChange = (tabSelected: ITabItem) => {
    setTabSelected(tabSelected);
    enableUrlSync && setParams(tabSelected.tabParam);
    onChange && onChange(tabSelected.tabParam);
  };

  const setParams = (tabParam: string, replace: boolean = false) => {
    if (!tabParam) return;

    const currentParams = new URLSearchParams();
    currentParams.set(QUERY_KEY_TAB, tabParam);

    if (replace) {
      setSearchParams(currentParams, { replace: true, preventScrollReset: true });
      return;
    }

    setSearchParams(currentParams, { preventScrollReset: true });
  };
  //#endregion Handle Function

  return (
    <div id='tabComponent' className={cx('container', type)}>
      {items.map((tabItem, index) => (
        <div
          key={index}
          className={cx('tab', tabSelected?.tabParam === tabItem.tabParam && 'activeTab', tabItem.disable && 'disable')}
          style={{ paddingBottom }}
          onClick={() => !tabItem.disable && handleTabChange(tabItem)}
        >
          {t(tabItem.tabName)}
        </div>
      ))}
    </div>
  );
};

export default Tab;
