// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TFunction } from 'i18next';
// Components, Layouts, Pages
import { BaseButton, BasePagination, BaseSelect, BaseSelectMultiple, BaseTable, ToolBar } from '~/components';
// Others
import { ButtonTypeEnum, TimeFormatEnum, TranslationEnum } from '~/utils/enum';
import { DEFAULT_CURRENT_PAGE, DEFAULT_LIMIT_PAGE, EMPTY_STRING } from '~/utils/constants/common';
import { useAppDispatch } from '~/redux/hooks';
import { ColumnTableType, IBaseOption, IListQueryParams, IPaginationResponse } from '~/utils/interface/common';
import { selectIsRefreshCareAlertList } from '~/thunks/careAlert/careAlertSlice';
import { convertDateToFormatTime, getRoutesByRole } from '~/utils/helper';
import { IMockListCareAlert } from '~/mockData/mockInterface';
import { listCareAlertMock, mockOptionAlert, mockOptionTimeCareAlert } from '~/mockData';
// Styles, images, icons
import styles from './CareAlertsShowAll.module.scss';
import { icons } from '~/assets';

type Props = {
  role: string;
};

const cx = classNames.bind(styles);

const columns = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<IMockListCareAlert>[] => {
  return [
    {
      key: 'date',
      title: t('care_alert_date_label'),
      render(value, record, index) {
        return <>{record.date ? convertDateToFormatTime(record.date, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'time',
      title: t('care_alert_time_label'),
      render(value, record, index) {
        return (
          <>{record.time ? convertDateToFormatTime(record.time, TimeFormatEnum.HOUR_MINUTE_AM_PM) : EMPTY_STRING}</>
        );
      },
    },
    {
      key: 'alert',
      title: t('care_alert_alert_label'),
      render(value, record, index) {
        return <>{record.alert || EMPTY_STRING}</>;
      },
    },
  ];
};

const CareAlertsShowAll = (props: Props) => {
  //#region Destructuring Props
  const { role } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  //#endregion Declare Hook

  //#region Selector
  const isRefreshCareAlertList = useSelector(selectIsRefreshCareAlertList);
  //#endregion Selector

  //#region Declare State
  const [careAlertList, setCareAlertList] = useState<IMockListCareAlert[]>(listCareAlertMock);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [paramObject, setParamObject] = useState<IListQueryParams>({
    page: pageSelected,
    limit: DEFAULT_LIMIT_PAGE,
  });
  const [careAlert, setCareAlert] = useState<IBaseOption>(mockOptionTimeCareAlert[0]);
  const [optionAlert, setOptionAlert] = useState<string[]>([mockOptionAlert[0].value]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListCareAlert(paramObject);
  }, [params?.page, params?.limit]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListCareAlert = (params: IListQueryParams) => {
    // Handle Get List Care Alert Later
  };

  const handleBack = () => {
    navigate(getRoutesByRole(role)?.caregiver ?? EMPTY_STRING);
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IListQueryParams = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({ ...params, page: page.toString(), limit: `${DEFAULT_LIMIT_PAGE}` });
  };

  const handleChangeDateTime = (value: IBaseOption) => {
    // TODO: Handle Logic Later
  };

  const handleChangeOptionAlert = (value: string[]) => {
    setOptionAlert(value);
  };

  const handleGoBackHome = () => {
    navigate(-1);
  };
  //#endregion Handle Function

  return (
    <div id='careAlertsShowAllPage' className={cx('careAlertsShowAllContainer')}>
      <BaseButton
        onClick={handleGoBackHome}
        typeStyle={ButtonTypeEnum.ONLY_TEXT}
        iconLeft={icons.commonIconPrev}
        text={t('common_text_back')}
      />

      <div className={cx('headerToolBar')}>
        <ToolBar title={t('care_alerts_show_all_care_alerts_title')}>
          <BaseSelectMultiple
            options={mockOptionAlert}
            isCheckbox
            width={250}
            onChange={(value: string[]) => {
              handleChangeOptionAlert(value);
            }}
            placeholder={t('common_select_placeholder')}
            value={optionAlert}
          />
          <div>
            <BaseSelect
              height={36}
              options={mockOptionTimeCareAlert}
              value={careAlert.value ? careAlert.value.toString() : EMPTY_STRING}
              onChange={(value: IBaseOption) => handleChangeDateTime(value)}
            />
          </div>
        </ToolBar>
      </div>

      <div className={cx('bodyContent')}>
        <BaseTable columns={columns(t)} dataSource={careAlertList} />
      </div>

      <div className={cx('paginationTable')}>
        <BasePagination
          onChange={handlePaginationChange}
          // Update Remove DEFAULT_CURRENT_DATE Later
          defaultCurrentPage={pageSelected || DEFAULT_CURRENT_PAGE}
          totalItems={pagination?.totalItems || DEFAULT_CURRENT_PAGE}
          totalPages={pagination?.totalPages || DEFAULT_CURRENT_PAGE}
        />
      </div>
    </div>
  );
};

export default CareAlertsShowAll;
