// Libs
import classNames from 'classnames/bind';
import { Field, Label, Listbox, ListboxButton, ListboxOptions, ListboxOption, Description } from '@headlessui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseButton } from '~/components';
// Others
import { ENTER_KEY, ASTERISK_SYMBOL, TIME_OPTIONS, EMPTY_STRING } from '~/utils/constants/common';
import { formatTime } from '~/utils/helper';
import { ButtonTypeEnum, TimeFormatEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './SelectTime.module.scss';
import { icons } from '~/assets';

type Props = {
  name?: string;
  label?: string;
  value?: string;
  labelOptions?: string;
  placeholder?: string;
  width?: number | string;
  height?: number | string;
  widthOptions?: number | string;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (timeValue: string) => void;
};

const cx = classNames.bind(styles);

const SelectTime = (props: Props) => {
  //#region Destructuring Props
  const {
    name,
    label,
    value,
    labelOptions,
    placeholder,
    width,
    height,
    widthOptions,
    errorMessage,
    required,
    disabled = false,
    onChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [timeValue, setTimeValue] = useState<string>('');
  const [timeDisplay, setTimeDisplay] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!value) return;

    setTimeValue(value);
    setTimeDisplay(value);
  }, [value, isOpen]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleOptionKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ENTER_KEY) {
      handleSaveTime();
    }
  };

  const handleSaveTime = () => {
    setTimeDisplay(timeValue);
    onChange && onChange(timeValue);
  };

  const handleCancelChooseTime = () => {
    setTimeValue(timeDisplay);
  };

  const handleTimeSelected = (value: string) => {
    setTimeValue(value);
  };

  const renderTimeDisplay = () => {
    if (placeholder && !timeDisplay) {
      return placeholder;
    }

    if (!placeholder && !timeDisplay) {
      return EMPTY_STRING;
    }

    return formatTime(timeDisplay, TimeFormatEnum.HOUR_MINUTE_AM_PM, TimeFormatEnum.HH_MM);
  };

  const handleOpenOptions = (open: boolean) => {
    setIsOpen(open);
  };

  const handleTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setTimeValue(value);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ENTER_KEY) {
      handleSaveTime();
    }
  };
  //#endregion Handle Function

  return (
    <Field id='timePickerComponent' className={cx('container')} style={{ width }}>
      {label && (
        <Label className={cx('timePickerLabel')}>
          {label} {required && <span className={cx('required')}>{ASTERISK_SYMBOL}</span>}
        </Label>
      )}

      <Listbox>
        <ListboxButton disabled={disabled} className={cx('btnSelect', disabled && 'bgDisable')} style={{ height }}>
          <span className={cx('timeSelected')}>{renderTimeDisplay()}</span>

          <img src={icons.commonIconTimePicker} alt={t('common_img_alt')} className={cx('timePickerIcon')} />
        </ListboxButton>

        <ListboxOptions
          className={cx('options')}
          transition
          anchor={{ to: 'bottom start', gap: '4px' }}
          style={{ width: widthOptions }}
        >
          <div className={cx('optionsHead')}>
            {labelOptions && <p className={cx('labelOptions')}>{labelOptions}</p>}

            <div className={cx('inputBox')}>
              <img src={icons.commonIconClock} alt={t('common_img_alt')} className={cx('inputClockIcon')} />

              <input
                type='time'
                name={name}
                id={name}
                className={cx('inputTime')}
                value={timeValue || timeDisplay}
                onChange={handleTimeChange}
                onKeyDown={handleInputKeyDown}
              />
            </div>
          </div>

          <div className={cx('chooseTimeBox')}>
            <p className={cx('chooseTimeLabel')}>{t('common_choose_time_label')}</p>

            <div className={cx('timeOptions')}>
              {TIME_OPTIONS.map((item, index) => (
                <div
                  tabIndex={index}
                  className={cx('timeOption', timeValue === item && 'active')}
                  key={index}
                  onClick={() => handleTimeSelected(item)}
                  onKeyDown={handleOptionKeyDown}
                >
                  <span className={cx('timeOptionContent', timeValue === item && 'active')}>
                    {formatTime(item, TimeFormatEnum.HOUR_MINUTE_AM_PM, TimeFormatEnum.HH_MM)}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div className={cx('timeActions')}>
            <ListboxOption value={''} className={cx('button')}>
              <BaseButton
                width={'100%'}
                height={36}
                text={t('common_cancel_label')}
                onClick={handleCancelChooseTime}
                type='button'
              />
            </ListboxOption>

            <ListboxOption value={''} className={cx('button')}>
              <BaseButton
                width={'100%'}
                type='button'
                typeStyle={ButtonTypeEnum.PRIMARY}
                height={36}
                text={t('common_save_label')}
                onClick={handleSaveTime}
              />
            </ListboxOption>
          </div>
        </ListboxOptions>
      </Listbox>

      {errorMessage && <Description className={cx('errMessage')}>{errorMessage}</Description>}
    </Field>
  );
};

export default SelectTime;
