// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useSearchParams } from 'react-router-dom';
import { useState, useEffect, useContext, useMemo } from 'react';
// Components, Layouts, Pages
import { BasePagination, BaseTable, ConfirmModal, ModalUnderDevelopment } from '~/components';
// Others
import { TimeFormatEnum, TranslationEnum, TypeFilesDocumentEnum } from '~/utils/enum';
import { BaseResponse, ColumnTableType, IListDataResponse, IListQueryParams } from '~/utils/interface/common';
import { IListDocument, IListQueryParamsDocument } from '~/utils/interface/file';
import { convertDateToFormatTime, formatMimeType, formatSizeFile } from '~/utils/helper';
import {
  A_ELEMENT,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_PAGE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  OBJECT,
} from '~/utils/constants/common';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { deleteDocument, downloadDocument, getListDocuments } from '~/thunks/file/fileThunk';
import { LoadingData } from '~/context';
import { fileActions, selectIsRefreshFileList } from '~/thunks/file/fileSlice';
// Styles, images, icons
import styles from './DocumentsTab.module.scss';
import { icons } from '~/assets';

type Props = {
  clientId: string;
};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  onDelete: (record: IListDocument) => void,
  onDownload: (record: IListDocument) => void
): ColumnTableType<IListDocument>[] => {
  return [
    {
      key: 'name',
      title: t('document_tab_crm_table_name_label'),
      dataIndex: 'name',
      render(value, record, index) {
        return <p>{record?.name || EMPTY_STRING}</p>;
      },
      width: '35%',
    },
    {
      key: 'updatedAt',
      title: t('document_tab_crm_table_updated_time_label'),
      dataIndex: 'updatedAt',
      render(value, record, index) {
        return (
          <>
            {record.updatedAt
              ? convertDateToFormatTime(record.updatedAt, TimeFormatEnum.MM_DD_YYYY_HH_AM_PM)
              : EMPTY_STRING}
          </>
        );
      },
    },
    {
      key: 'type',
      title: t('document_tab_crm_table_type_label'),
      dataIndex: 'mimetype',
      render(value, record, index) {
        return <>{formatMimeType(record?.mimetype)}</>;
      },
      width: '18%',
    },
    {
      key: 'size',
      title: t('document_tab_crm_table_size_label'),
      dataIndex: 'size',
      render(value, record, index) {
        return <>{formatSizeFile(Number(record?.size))}</>;
      },
    },
    {
      key: 'action',
      title: t('document_tab_crm_table_action_label'),
      render(value, record, index) {
        return (
          <div className={cx('btnAction')}>
            <button onClick={() => onDownload(record)}>
              <img src={icons.commonIconDownload} alt={t('common_img_text_alt')} />
            </button>
            <button onClick={() => onDelete(record)}>
              <img src={icons.commonIconTrash} alt={t('common_img_text_alt')} />
            </button>
          </div>
        );
      },
      width: '5%',
    },
  ];
};

const DocumentTab = (props: Props) => {
  //#region Destructuring Props
  const { clientId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  const isRefreshListDocument = useAppSelector(selectIsRefreshFileList);
  //#endregion Selector

  //#region Declare State
  const [isDevelopment, setIsDevelopment] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const [paramObject, setParamObject] = useState<IListQueryParams>({
    page: DEFAULT_CURRENT_PAGE,
    limit: DEFAULT_LIMIT_PAGE,
  });
  const [listDocumentResp, setListDocumentResp] = useState<BaseResponse<IListDataResponse<IListDocument[]>>>();
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [documentTableRecord, setDocumentTableRecord] = useState<IListDocument>({ id: EMPTY_STRING });
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!clientId) return;

    const paramListDocument: IListQueryParamsDocument = {
      businessId: clientId,
      ...paramObject,
      businessType: TypeFilesDocumentEnum.CLIENT_DOCUMENT,
    };

    handleGetListDocument(paramListDocument);

    isRefreshListDocument && dispatch(fileActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramObject, isRefreshListDocument]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleDelete = () => {
    if (documentTableRecord) {
      loading?.show();

      dispatch(deleteDocument(documentTableRecord?.id))
        .unwrap()
        .then((res) => {
          dispatch(fileActions.setRefreshList(true));
          setIsOpenDelete(false);
        })
        .catch((error) => {})
        .finally(() => loading?.hide());
    }
  };

  const handleDownLoad = (fileInfo: IListDocument) => {
    loading?.show();

    dispatch(downloadDocument(fileInfo.id))
      .unwrap()
      .then((response) => {
        const data: ArrayBuffer | undefined = response.data;
        const fileExtension = fileInfo.mimetype;

        if (data && typeof data === OBJECT && Object.keys(data).length > DEFAULT_NUMBER_ZERO) {
          const array: number[] = Object.values(data);
          const uint8Array: Uint8Array = new Uint8Array(array);
          const blob: Blob = new Blob([uint8Array], { type: fileExtension });

          const url: string = window.URL.createObjectURL(blob);
          const link: HTMLAnchorElement = document.createElement(A_ELEMENT);
          link.href = url;
          link.download = fileInfo.name || EMPTY_STRING;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          window.URL.revokeObjectURL(url);
        }

        dispatch(fileActions.setRefreshList(true));
        setIsOpenDelete(false);
      })
      .catch((error) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleOpenDevelopment = () => {
    setIsDevelopment(!isDevelopment);
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IListQueryParams = { ...paramObject, page };

    setParamObject(newParamObject);
    setSearchParams({ ...params, page: page.toString(), limit: `${DEFAULT_LIMIT_PAGE}` });
  };

  const handleGetListDocument = (params: IListQueryParamsDocument) => {
    loading?.show();

    dispatch(getListDocuments(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setListDocumentResp(res);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleShowDelete = (record?: IListDocument) => {
    if (record) {
      setDocumentTableRecord(record);
    }
    setIsOpenDelete(!isOpenDelete);
  };

  const handleDocumentRowClick = (data: IListDocument) => {
    setIsDevelopment(true);
  };
  //#endregion Handle Function

  return (
    <div id='documentsTabComponent' className={cx('documentsTabComponent')}>
      <div className={cx('tableContainer')}>
        <BaseTable
          columns={columns(t, handleShowDelete, handleDownLoad)}
          dataSource={listDocumentResp?.data.responses || []}
          onClickRow={handleDocumentRowClick}
        />
      </div>
      <div className={cx('paginationTable')}>
        <BasePagination
          onChange={handlePaginationChange}
          defaultCurrentPage={pageSelected}
          totalItems={listDocumentResp?.data.pagination.totalItems}
          totalPages={listDocumentResp?.data.pagination.totalPages}
        />
      </div>
      {isDevelopment && <ModalUnderDevelopment onClose={handleOpenDevelopment} />}

      {isOpenDelete && (
        <ConfirmModal
          title={t('common_confirm_delete_title', {
            name: documentTableRecord?.name,
          })}
          description={t('modal_confirm_delete_description')}
          titleAction={t('common_delete_label')}
          onCancel={handleShowDelete}
          onAction={handleDelete}
        />
      )}
    </div>
  );
};

export default DocumentTab;
