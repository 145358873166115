// Libs
// Others
import { urlApiCrm, urlApiReferral } from '~/utils/constants/api';
import axiosClient from '~/apis/axiosClient';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import { IListReferrals, IQueryReferrals } from '~/utils/interface/crm/referral';
import { IFormUpdateReferrals, IReferralsDetail } from '~/utils/interface/crm/referralSource';
import { CONTENT_TYPE_FORM_DATA } from '~/utils/constants/common';

const referralApi = {
  getListReferrals(params: IQueryReferrals) {
    const url = `${urlApiCrm.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IListReferrals[]>>>(url, { params });
  },

  createReferrals(body: FormData) {
    const url = `${urlApiReferral.createReferrals}`;
    return axiosClient.post<BaseResponse>(url, body, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  updateReferrals({ accountId, body }: IFormUpdateReferrals) {
    const url = `${urlApiReferral.updateReferrals(accountId)}`;
    return axiosClient.put<BaseResponse>(url, body, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  getDetailReferrals(organizationId: string) {
    const url = `${urlApiReferral.getDetailReferrals(organizationId)}`;
    return axiosClient.get<BaseResponse<IReferralsDetail>>(url);
  },
};

export default referralApi;
