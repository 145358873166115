// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
// Components, Layouts, Pages
import { BaseButton, BaseInput, BaseSelect, Loading, Modal } from '~/components';
// Context
// Others
import { ButtonTypeEnum, FormEmployeeKeyEnum, InputTypeEnum } from '~/utils/enum';
import { optionsRoleEmployee } from '~/mockData';
import { IFormAddEmployee } from '~/utils/interface/employees';
import { EMPTY_STRING, RegExp } from '~/utils/constants/common';
import { useAppDispatch } from '~/redux/hooks';
import { createEmployee, updateEmployee } from '~/thunks/employee/employeeThunk';
import { employeeActions } from '~/thunks/employee/employeeSlice';
import { getDetailUser } from '~/thunks/user/userThunk';
import { compareDataUpdate } from '~/utils/helper';
// Styles, images, icons
import styles from './FormEmployeesModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  employeeId?: string;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    firstName: yup.string().required(t('common_error_message_first_name_required')),
    lastName: yup.string().required(t('common_error_message_last_name_required')),
    email: yup
      .string()
      .nullable()
      .required(t('common_error_message_email_required'))
      .matches(RegExp.EMAIL, t('common_validate_invalid_email')),
    role: yup.string().required(t('common_error_message_role_required')),
  });
};

const defaultValues: IFormAddEmployee = {
  firstName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  email: EMPTY_STRING,
  role: EMPTY_STRING,
};

const FormEmployeesModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, employeeId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<IFormAddEmployee>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  const dispatch = useAppDispatch();
  //#endregion Selector

  //#region Declare State
  const [originalData, setOriginalData] = useState<IFormAddEmployee>(defaultValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!employeeId) {
      return;
    }

    handleGetDetail(employeeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetail = (id: string) => {
    setIsLoading(true);

    dispatch(getDetailUser(id))
      .unwrap()
      .then((res) => {
        const data = res?.data;
        if (!data) return;

        reset(data);
        setOriginalData(data);
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
  };

  const handleClose = () => {
    onClose?.();
    reset();
  };

  const handleCreateUser = (data: IFormAddEmployee) => {
    setIsLoading(true);
    dispatch(createEmployee(data))
      .unwrap()
      .then((res) => {
        handleClose();
        dispatch(employeeActions.setRefreshList(true));
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpdateUser = (data: IFormAddEmployee) => {
    if (!employeeId) return;
    setIsLoading(true);
    const body = compareDataUpdate(originalData, data);
    dispatch(updateEmployee({ employeeId, body }))
      .unwrap()
      .then((res) => {
        handleClose();
        dispatch(employeeActions.setRefreshList(true));
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const normalizeEmployeeEmail = (data: IFormAddEmployee): IFormAddEmployee => {
    if (data.email) {
      return {
        ...data,
        email: data.email.toLowerCase(),
      };
    }
    return data;
  };

  const submitEmployee = (data: IFormAddEmployee) => {
    const normalizedData = normalizeEmployeeEmail(data);

    if (employeeId) {
      return handleUpdateUser(normalizedData);
    }

    handleCreateUser(normalizedData);
  };
  //#endregion Handle Function

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={t(employeeId ? 'employee_modal_edit_employee_title' : 'employee_modal_add_employee_title')}
    >
      <form id='adminAddEmployeesModal' className={cx('container')} onSubmit={handleSubmit(submitEmployee)}>
        <div className={cx('contentModal')}>
          <div className={cx('inputContent')}>
            <Controller
              name={FormEmployeeKeyEnum.FIRST_NAME}
              control={control}
              render={({ field }) => (
                <BaseInput
                  width={352}
                  height={34}
                  label={t('employee_modal_add_employee_first_name_label')}
                  id='addEmployeesFirstName'
                  //placeholder={t('employee_modal_add_employee_first_name_placeholder')}
                  onChange={field.onChange}
                  value={field.value}
                  messageError={errors.firstName?.message}
                  required
                />
              )}
            />
            <Controller
              name={FormEmployeeKeyEnum.LAST_NAME}
              control={control}
              render={({ field }) => (
                <BaseInput
                  width={352}
                  height={34}
                  label={t('employee_modal_add_employee_last_name_label')}
                  id='addEmployeesLastName'
                  //placeholder={t('employee_modal_add_employee_last_name_placeholder')}
                  onChange={field.onChange}
                  value={field.value}
                  messageError={errors.lastName?.message}
                  required
                />
              )}
            />
          </div>
          <div className={cx('inputContent')}>
            <Controller
              name={FormEmployeeKeyEnum.EMAIL}
              control={control}
              render={({ field }) => (
                <BaseInput
                  width={352}
                  height={34}
                  label={t('employee_modal_add_employee_email_label')}
                  id='addEmployeesEmail'
                  type={InputTypeEnum.EMAIL}
                  //placeholder={t('employee_modal_add_employee_email_placeholder')}
                  onChange={field.onChange}
                  value={field.value}
                  messageError={errors.email?.message}
                  required
                />
              )}
            />
            <div className={cx('inputContentGroup')}>
              <Controller
                name={FormEmployeeKeyEnum.ROLE}
                control={control}
                render={({ field }) => (
                  <BaseSelect
                    options={optionsRoleEmployee}
                    name='role'
                    label={t('employee_modal_add_employee_role_label')}
                    placeholder={t('common_select_placeholder')}
                    onChange={(item) => field.onChange(item.value)}
                    value={field.value}
                    errorMessage={errors.role?.message}
                    required
                    disabled={employeeId ? true : false}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className={cx('footerModal')}>
          <BaseButton text={t('common_cancel_label')} width={65} onClick={handleClose} />
          <BaseButton
            text={employeeId ? t('common_update_label') : t('common_save_label')}
            typeStyle={ButtonTypeEnum.PRIMARY}
            width={80}
            type='submit'
            disabled={employeeId ? !isDirty : false}
          />
        </div>
      </form>

      {isLoading && <Loading />}
    </Modal>
  );
};

export default FormEmployeesModal;
