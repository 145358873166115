// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import activityApi from '~/apis/activity';
import { GET_LIST_ACTIVITY_BY_CAREGIVER, GET_LIST_ACTIVITY_BY_CLIENT } from '~/utils/constants/actionType';
import { IParamsGetListActivity } from '~/utils/interface/activity';

export const getListActivityByClient = createAsyncThunk(
  GET_LIST_ACTIVITY_BY_CLIENT,
  async (payload: IParamsGetListActivity, { rejectWithValue }) => {
    try {
      const res = await activityApi.getListActivityByClient(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListActivityByCaregiver = createAsyncThunk(
  GET_LIST_ACTIVITY_BY_CAREGIVER,
  async (payload: IParamsGetListActivity, { rejectWithValue }) => {
    try {
      const res = await activityApi.getListActivityByCaregiver(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
