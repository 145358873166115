// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ReactNode, useEffect, useState } from 'react';
// Components, Layouts, Pages
import { ReactPortal } from '~/components';
// Others
import { HTMLEventEnum } from '~/utils/enum';
import { DEFAULT_NUMBER_ZERO, HIDDEN, PIXELS, UNSET } from '~/utils/constants/common';
// Styles, images, icons
import styles from './BaseDrawerModal.module.scss';
import { icons } from '~/assets';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  width?: string | number;
};

const cx = classNames.bind(styles);

const BaseDrawerModal = (props: Props) => {
  //#region Destructuring Props
  const { onClose, children, isOpen, width } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [closing, setClosing] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) => {
      if (e.key === HTMLEventEnum.ESCAPE && isOpen) {
        handleClose();
      }
    };
    document.body.addEventListener(HTMLEventEnum.KEYDOWN, closeOnEscapeKey);

    return (): void => {
      document.body.removeEventListener(HTMLEventEnum.KEYDOWN, closeOnEscapeKey);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    document.body.style.marginRight = `${window.innerWidth - document.documentElement.clientWidth}${PIXELS}`;
    document.body.style.overflow = HIDDEN;
    return (): void => {
      document.body.style.overflow = UNSET;
      document.body.style.marginRight = `${DEFAULT_NUMBER_ZERO}${PIXELS}`;
    };
  }, [isOpen]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleClose = () => {
    if (closing) return;

    setClosing(true);
    setTimeout(() => {
      onClose && onClose();
      setClosing(false);
    }, 300);
  };

  const handleModalClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };
  //#endregion Handle Function

  if (!isOpen) {
    return null;
  }

  return (
    <ReactPortal wrapperId='reactPortalModalContainer'>
      <div id='baseDrawerModal' className={cx('container')} onClick={handleClose}>
        <div style={{ width }} className={cx('containerModal', { closing })} onClick={handleModalClick}>
          <div className={cx('iconCloseModal')} onClick={handleClose}>
            <img src={icons.commonIconModalClose} alt={t('common_img_text_alt')} />
          </div>
          <div className={cx('bodyModal')}>{children}</div>
        </div>
      </div>
    </ReactPortal>
  );
};

export default BaseDrawerModal;
