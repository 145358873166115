// Libs
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
// Components, Layouts, Pages
// Others
import { ButtonTypeEnum } from '~/utils/enum';
import { TButtonType } from '~/utils/type/common';
// Styles, images, icons
import styles from './Button.module.scss';

type Props = {
  width?: number | string;
  minWidth?: number | string;
  height?: number;
  typeStyle?: ButtonTypeEnum;
  text: string;
  color?: string;
  iconLeft?: string;
  iconRight?: string;
  iconWidthHeight?: string | number;
  onClick?: () => void;
  borderColor?: string;
  type?: TButtonType;
  disabled?: boolean;
};

const cx = classNames.bind(styles);

const Button = (props: Props) => {
  //#region Destructuring Props
  const {
    width = 'fit-content',
    minWidth,
    height = 36,
    text,
    color,
    typeStyle,
    iconLeft,
    iconRight,
    iconWidthHeight,
    onClick,
    borderColor,
    type,
    disabled = false,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleClick = () => {
    onClick && onClick();
  };
  //#endregion Handle Function

  return (
    <button
      id='buttonComponent'
      style={{ width, height, minWidth, color: color, borderColor: borderColor }}
      onClick={handleClick}
      className={cx('originStyle', typeStyle, disabled && 'disableButton')}
      type={type}
      disabled={disabled}
    >
      {iconLeft && (
        <img
          src={iconLeft}
          alt={t('common_img_text_alt')}
          style={{ width: iconWidthHeight, height: iconWidthHeight }}
          className={cx('iconLeft')}
        />
      )}
      {text}
      {iconRight && (
        <img
          src={iconRight}
          alt={t('common_img_text_alt')}
          style={{ width: iconWidthHeight, height: iconWidthHeight }}
          className={cx('iconRight')}
        />
      )}
    </button>
  );
};

export default Button;
