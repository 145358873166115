// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect } from 'react';
// Components, Layouts, Pages
import { BaseSelect, BaseDatePicker, BaseInput, RepeatOn } from '~/components';
// Others
import { optionsMockSelect, optionsMockRecurrence } from '~/mockData';
import { IBaseOption } from '~/utils/interface/common';
import { IFormTask } from '~/utils/interface/task';
import { FormTaskDateTypeEnum, RecurrenceEnum } from '~/utils/enum';
import { EMPTY_STRING, RegExp } from '~/utils/constants/common';
// Styles, images, icons
import styles from './RecurrenceTaskForm.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const RecurrenceTaskForm = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext<IFormTask>();

  const watchRecurrence = watch('recurrence');
  const watchEndType = watch('endType');
  const watchFromDate = watch('fromDate');
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (watchFromDate) {
      setValue('toDate', watchFromDate);
      trigger('toDate');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchFromDate]);
  //#endregion Implement Hook

  //#region Handle Function
  const renderUnitRepeatEvery = () => {
    switch (watchRecurrence) {
      case RecurrenceEnum.DAILY:
        return t('form_task_repeat_every_day_label');

      case RecurrenceEnum.WEEKLY:
        return t('form_task_repeat_every_week_label');

      case RecurrenceEnum.MONTHLY:
        return t('form_task_repeat_every_month_label');

      default:
        return;
    }
  };
  //#endregion Handle Function

  return (
    <div id='recurrenceTaskForm' className={cx('container')}>
      <div className={cx('twoCol')}>
        <Controller
          name='recurrence'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              value={value}
              options={optionsMockRecurrence}
              label={t('task_modal_task_recurrence_label')}
              placeholder={t('common_select_placeholder')}
              onChange={(optionSelected: IBaseOption) => {
                onChange(optionSelected?.value);
              }}
            />
          )}
        />

        {watchRecurrence === RecurrenceEnum.NONE && (
          <Controller
            name='dueDate'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseDatePicker
                label={t('task_modal_task_due_date_label')}
                placeholderText={t('common_select_placeholder')}
                value={value || EMPTY_STRING}
                onDateSelected={(date: string) => {
                  onChange(date);
                }}
                errorMessage={errors.dueDate?.message}
                required
              />
            )}
          />
        )}

        {watchRecurrence !== RecurrenceEnum.NONE && (
          <Controller
            name='repeatEvery'
            control={control}
            render={({ field: { value, onChange } }) => (
              <div className={cx('repeatEvery')}>
                <div className={cx('inputRepeatEvery')}>
                  <BaseInput
                    id='repeatEvery'
                    value={value || EMPTY_STRING}
                    label={t('task_modal_task_repeat_every_label')}
                    //placeholder={t('task_modal_task_repeat_every_label')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const inputValue = e.target.value;

                      if (RegExp.NUMBER.test(inputValue)) {
                        onChange(inputValue);
                      }
                    }}
                    required
                    messageError={errors.repeatEvery?.message}
                  />
                </div>

                <span className={cx('repeatEveryUnit')}>{renderUnitRepeatEvery()}</span>
              </div>
            )}
          />
        )}
      </div>

      {watchRecurrence !== RecurrenceEnum.NONE && (
        <>
          <div className={cx('twoCol')}>
            <Controller
              name='fromDate'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseDatePicker
                  label={t('task_modal_task_from_label')}
                  placeholderText={t('common_select_placeholder')}
                  value={value || EMPTY_STRING}
                  onDateSelected={(date: string) => {
                    onChange(date);
                  }}
                  errorMessage={errors.fromDate?.message}
                  required
                />
              )}
            />

            <Controller
              name='toDate'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseDatePicker
                  label={t('task_modal_task_to_label')}
                  placeholderText={t('common_select_placeholder')}
                  value={value || EMPTY_STRING}
                  onDateSelected={(date: string) => {
                    onChange(date);
                  }}
                  errorMessage={errors.toDate?.message}
                  disable
                  required
                />
              )}
            />
          </div>

          {(watchRecurrence === RecurrenceEnum.WEEKLY || watchRecurrence === RecurrenceEnum.MONTHLY) && <RepeatOn />}

          <div className={cx('twoCol')}>
            <Controller
              name='endType'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  value={value || EMPTY_STRING}
                  options={optionsMockSelect}
                  label={t('task_modal_task_end_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={(optionSelected: IBaseOption) => {
                    onChange(optionSelected?.value);
                  }}
                />
              )}
            />

            {watchEndType === FormTaskDateTypeEnum.UNTIL && (
              <Controller
                name='untilDate'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseDatePicker
                    label={t('task_modal_task_until_date_label')}
                    placeholderText={t('common_select_placeholder')}
                    value={value || EMPTY_STRING}
                    onDateSelected={(date: string) => {
                      onChange(date);
                    }}
                    errorMessage={errors.untilDate?.message}
                    required
                  />
                )}
              />
            )}
          </div>
        </>
      )}

      {/* TODO: Handle Update Later */}
    </div>
  );
};

export default RecurrenceTaskForm;
