// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { ImageCircle } from '~/components';
// Others
import { MAX_VISIBLE_IMAGE, MIN_VISIBLE_IMAGE, PLUS } from '~/utils/constants/common';
import { ImageCircleTypeEnum } from '~/utils/enum';
import { ColorStackedAvatar } from '~/utils/type/common';
import { IAddAssignee } from '~/utils/interface/common';
// Styles, images, icons
import styles from './AvatarOverlap.module.scss';

type Props = {
  assignees: IAddAssignee[];
  maxVisibleImage?: number;
  backgroundColor?: string;
  color?: ColorStackedAvatar;
};

const cx = classNames.bind(styles);

const AvatarOverlap = (props: Props) => {
  //#region Destructuring Props
  const { assignees, maxVisibleImage, backgroundColor, color } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='avatarOverlapComponent' className={cx('container')}>
      {assignees
        .slice(MIN_VISIBLE_IMAGE, maxVisibleImage ? maxVisibleImage : MAX_VISIBLE_IMAGE)
        ?.map((assignee, index) => {
          return (
            <div className={cx('avatar', color)} key={index}>
              <ImageCircle
                width={26}
                height={26}
                imageUrl={assignee.avatarUrl}
                type={assignee.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
                firstName={assignee.firstName}
                lastName={assignee.lastName}
              />
            </div>
          );
        })}
      {assignees.length > MAX_VISIBLE_IMAGE && (
        <div className={cx('avatarMore', color)} style={{ backgroundColor }}>
          {PLUS}
          {assignees.slice(MAX_VISIBLE_IMAGE).length}
        </div>
      )}
    </div>
  );
};

export default AvatarOverlap;
