// Libs
import moment from 'moment';
import { memo, useCallback, useState } from 'react';
import { Calendar, View, Views, momentLocalizer, stringOrDate } from 'react-big-calendar';
// Components, Layouts, Pages
// Others
import { BaseCalendarProps } from '~/utils/type/schedule';
import { HEIGHT_FULL } from '~/utils/constants/common';
// Styles, images, icons
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './BaseSchedule.scss';

const localizer = momentLocalizer(moment);

const BaseSchedule = (props: BaseCalendarProps) => {
  //#region Destructuring Props
  const {
    views = { month: true, week: true, day: true },
    formats = { dateFormat: 'D', weekdayFormat: 'dddd', dayFormat: 'dddd', timeGutterFormat: 'h A' },
    ...restProps
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [view, setView] = useState<View>(restProps.view || Views.MONTH);
  const [date, setDate] = useState<stringOrDate>(restProps.date || moment().toDate());
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const onView = useCallback((newView: View) => setView(newView), [setView]);
  const onNavigate = useCallback((newDate: Date) => setDate(newDate), [setDate]);
  //#endregion Handle Function

  return (
    <Calendar
      localizer={localizer}
      startAccessor='start'
      endAccessor='end'
      events={restProps.events}
      style={{ height: HEIGHT_FULL }}
      views={views}
      view={view}
      date={date}
      formats={formats}
      dayLayoutAlgorithm={view === 'day' ? 'no-overlap' : 'overlap'}
      onView={onView}
      onNavigate={onNavigate}
      {...restProps}
    />
  );
};

export default memo(BaseSchedule);
