// Libs
// Components, Layouts, Pages
// Others
import { GREEN_PEA700 } from '~/utils/constants/color';
import { IconSvgProps } from '~/utils/interface/common';
// Styles, images, icons

const CommonIconSearch = (props: IconSvgProps) => {
  //#region Destructuring Props
  const { width = 14, height = 14, strokePath = GREEN_PEA700, fill = 'none' } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <svg width={width} height={height} viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.76311 11.4992L3.49329 11.3279L2.76311 11.4992ZM2.76311 6.95168L3.49329 7.12296L2.76311 6.95168ZM15.6578 6.95168L16.3879 6.78041L15.6578 6.95168ZM15.6578 11.4992L16.3879 11.6705L15.6578 11.4992ZM11.4842 15.6728L11.3129 14.9426L11.4842 15.6728ZM6.93667 15.6728L6.76539 16.403L6.93667 15.6728ZM6.93667 2.77812L6.76539 2.04794V2.04794L6.93667 2.77812ZM11.4842 2.77812L11.6555 2.04794L11.4842 2.77812ZM16.9697 18.0453C17.2626 18.3382 17.7374 18.3382 18.0303 18.0453C18.3232 17.7525 18.3232 17.2776 18.0303 16.9847L16.9697 18.0453ZM3.49329 11.3279C3.1689 9.94505 3.1689 8.50585 3.49329 7.12296L2.03293 6.7804C1.65569 8.38861 1.65569 10.0623 2.03293 11.6705L3.49329 11.3279ZM14.9276 7.12296C15.252 8.50585 15.252 9.94505 14.9276 11.3279L16.3879 11.6705C16.7652 10.0623 16.7652 8.38861 16.3879 6.78041L14.9276 7.12296ZM11.3129 14.9426C9.93004 15.267 8.49084 15.267 7.10795 14.9426L6.76539 16.403C8.3736 16.7802 10.0473 16.7802 11.6555 16.403L11.3129 14.9426ZM7.10794 3.5083C8.49084 3.18392 9.93004 3.18392 11.3129 3.5083L11.6555 2.04794C10.0473 1.67071 8.3736 1.67071 6.76539 2.04794L7.10794 3.5083ZM7.10795 14.9426C5.31441 14.5219 3.914 13.1215 3.49329 11.3279L2.03293 11.6705C2.58373 14.0187 4.41721 15.8522 6.76539 16.403L7.10795 14.9426ZM11.6555 16.403C14.0037 15.8522 15.8371 14.0187 16.3879 11.6705L14.9276 11.3279C14.5069 13.1215 13.1065 14.5219 11.3129 14.9426L11.6555 16.403ZM11.3129 3.5083C13.1065 3.92901 14.5069 5.32942 14.9276 7.12296L16.3879 6.78041C15.8371 4.43223 14.0037 2.59875 11.6555 2.04794L11.3129 3.5083ZM6.76539 2.04794C4.41721 2.59875 2.58373 4.43222 2.03293 6.7804L3.49329 7.12296C3.914 5.32942 5.31441 3.92901 7.10794 3.5083L6.76539 2.04794ZM13.9156 14.9913L16.9697 18.0453L18.0303 16.9847L14.9763 13.9306L13.9156 14.9913Z'
        fill={strokePath}
      />
    </svg>
  );
};

export default CommonIconSearch;
