// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { ImageCircle } from '~/components';
// Others
import { ImageCircleTypeEnum } from '~/utils/enum';
import { getUserName } from '~/utils/helper';
import { IConversationMember } from '~/utils/interface/conversation';
// Styles, images, icons
import styles from './ConversationUserInfo.module.scss';

type Props = {
  data?: IConversationMember;
  isTyping: boolean;
};

const cx = classNames.bind(styles);

const ConversationUserInfo = (props: Props) => {
  //#region Destructuring Props
  const { data, isTyping } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='conversationUserInfoComponent' className={cx('conversationUserInfo')}>
      <ImageCircle
        type={data?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
        width={40}
        height={40}
        firstName={data?.firstName}
        fontSize={20}
        lastName={data?.lastName}
        imageUrl={data?.avatarUrl}
      />

      <div className={cx('conversationUserContent')}>
        <span className={cx('conversationUserName')}>{getUserName(data?.firstName, data?.lastName)}</span>

        {data?.isActive && (
          <div className={cx('conversationUserStatus')}>
            <span className={cx('conversationDot')}></span>
            <span className={cx('conversationStatus')}>{t('common_status_active_label')}</span>
          </div>
        )}

        <span className={cx('conversationUserTyping')}>
          {isTyping &&
            `${t('message_user_info_typing', {
              nameUser: getUserName(data?.firstName, data?.lastName),
            })}`}
        </span>
      </div>
    </div>
  );
};

export default ConversationUserInfo;
