// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseButton } from '~/components';
// Others
import { publicRoute } from '~/utils/constants/route';
import { ButtonTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './ChangePasswordSuccess.module.scss';
import { images } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const ChangePasswordSuccess = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleBackToHome = () => {
    navigate(publicRoute.base);
  };
  //#endregion Handle Function

  return (
    <div id='changePasswordSuccessComponent' className={cx('container')}>
      <div className={cx('imageEmoji')}>
        <img src={images.changePasswordImageEmoji} alt={t('common_img_text_alt')} />
      </div>

      <div className={cx('changePasswordSuccess')}>
        <h3 className={cx('title')}>{t('change_password_success_title')}</h3>

        <p className={cx('desc')}>{t('change_password_success_desc')}</p>

        <div className={cx('btnGroup')}>
          <BaseButton
            text={t('change_password_success_btn_back_to_sign_in')}
            width={149}
            typeStyle={ButtonTypeEnum.PRIMARY}
            onClick={handleBackToHome}
          />
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordSuccess;
