// Libs
import classNames from 'classnames/bind';
// Components, Layouts, Pages
// Others
// Styles, images, icons
import styles from './RadioAddSchedule.module.scss';

type Props = {
  id: string;
  name: string;
  checked?: boolean;
  label?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

const cx = classNames.bind(styles);

const RadioAddSchedule = (props: Props) => {
  //#region Destructuring Props
  const { id, checked = false, name, label, onChange, value, disabled } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
  };
  //#endregion Handle Function

  return (
    <div id='radioCrm'>
      <div className={cx('radioCrmContainer')}>
        <div className={cx('radioCrmWrap')}>
          <input
            type='radio'
            name={name}
            className={cx('radioInput')}
            id={id}
            value={value}
            checked={checked}
            onChange={handleChangeRadio}
            disabled={disabled}
          />
        </div>

        <label htmlFor={id} className={cx('radioCrmLabel', disabled && 'disabled')}>
          {label}
        </label>
      </div>
    </div>
  );
};

export default RadioAddSchedule;
