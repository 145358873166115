// Libs
import * as yup from 'yup';
// Components, Layouts, Pages
// Others
import { IFormCarePlan, IQuickLinkItem } from '~/utils/interface/crm/carePlan';

export const carePlanHomeCareClientSchema = () => {
  return yup
    .object()
    .shape({
      quickLink: yup
        .object()
        .shape({
          IADLS: yup.boolean().optional(),
          ADLS: yup.boolean().optional(),
          CARE_GOALS: yup.boolean().optional(),
          TRANSFERS: yup.boolean().optional(),
          MANDATE_PRIMARY_DIAGNOSIS: yup.boolean().optional(),
        })
        .optional(),

      iadls: yup.object().shape({}).nullable().optional(),

      adls: yup.object().shape({}).nullable().optional(),

      careGoals: yup.object().shape({}).nullable().optional(),

      transfers: yup.object().shape({}).nullable().optional(),

      mandatePrimaryDiagnosis: yup.object().shape({}).nullable().optional(),
    })
    .required();
};

export const carePlanHomeCareClientDefaultValues: IFormCarePlan = {
  quickLink: {
    IADLS: false,
    ADLS: false,
    CARE_GOALS: false,
    TRANSFERS: false,
    MANDATE_PRIMARY_DIAGNOSIS: false,
  },
};

export const quickLinkHomeCareClientItems: IQuickLinkItem[] = [
  {
    key: 'IADLS',
    defaultValue: 'iadls',
    title: 'care_plan_quick_link_title_iadls_home_care_client',
    // Update Component Later
  },
  {
    key: 'ADLS',
    defaultValue: 'adls',
    title: 'care_plan_quick_link_title_adls',
    // Update Component Later
  },
  {
    key: 'CARE_GOALS',
    defaultValue: 'careGoals',
    title: 'care_plan_quick_link_title_care_goals',
    // Update Component Later
  },
  {
    key: 'TRANSFERS',
    defaultValue: 'transfers',
    title: 'care_plan_quick_link_title_transfers',
    // Update Component Later
  },
  {
    key: 'MANDATE_PRIMARY_DIAGNOSIS',
    defaultValue: 'mandatePrimaryDiagnosis',
    title: 'care_plan_quick_link_title_mandate_primary',
    // Update Component Later
  },
];
