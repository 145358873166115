// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
// Components, Layouts, Pages
import { BaseSelectMultiple } from '~/components';
// Others
import { transportOptions } from '~/mockData';
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
// Styles, images, icons
import styles from './TransportDayCenterClient.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const TransportDayCenterClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='transportDayCenterClient' className={cx('container')}>
      <Controller
        name='transport.transportProvided'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseSelectMultiple
            value={value || []}
            onChange={onChange}
            label={t('transport_day_center_client_provided_by_title')}
            placeholder={t('common_placeholder_select')}
            options={transportOptions}
            errorMessage={errors.transport?.transportProvided?.message}
          />
        )}
      />
    </div>
  );
};

export default TransportDayCenterClient;
