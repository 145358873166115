// Libs
// Components, Layouts, Pages
// Others
import { GREEN_PEA700 } from '~/utils/constants/color';
import { IconSvgProps } from '~/utils/interface/common';
// Styles, images, icons

interface Props extends IconSvgProps {
  strokeWidth?: string;
}
const CommonIconCheck = (props: Props) => {
  //#region Destructuring Props
  const { width = 24, height = 24, strokePath = GREEN_PEA700, fill = 'none', strokeWidth = 3 } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18 7L9.42857 17L6 13'
        stroke={strokePath}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CommonIconCheck;
