// Libs
import classNames from 'classnames/bind';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TFunction } from 'i18next';
// Components, Layouts, Pages
import { BaseButton, BaseInput, BaseUploadFile, Modal } from '~/components';
// Context
import { LoadingContext } from '~/context';
// Others
import { ButtonTypeEnum } from '~/utils/enum';
import { ALLOWED_FILE_TYPE, DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { useAppDispatch } from '~/redux/hooks';
import { IFormUpdateDocument, IPayloadUpdateDocument } from '~/utils/interface/file';
import { getDetailDocument, updateDocument } from '~/thunks/file/fileThunk';
import { fileActions } from '~/thunks/file/fileSlice';
import { IUploadFile } from '~/utils/interface/common';
// Styles, images, icons
import styles from './UpdateDocumentModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  fileId: string;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({});
};

const DEFAULT_VALUE: IFormUpdateDocument = {
  files: [],
  name: EMPTY_STRING,
};

const UpdateDocumentModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, fileId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<IFormUpdateDocument>({
    resolver: yupResolver(schema(t)),
    defaultValues: DEFAULT_VALUE,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [initialData, setInitialData] = useState<IFormUpdateDocument>(DEFAULT_VALUE);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!fileId) return;
    handleGetDetailDocument(fileId);
  }, [fileId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetailDocument = (fileId: string) => {
    // Handle Logic Later
    loading?.show();
    dispatch(getDetailDocument(fileId))
      .unwrap()
      .then((res) => {
        // Update Later
        const { data } = res;
        const formatted: IFormUpdateDocument = {
          name: data.name || EMPTY_STRING,
          files: data.url ? [{ name: data.name }] : [],
        };
        reset(formatted);
        setInitialData(formatted);
      })
      .catch((error) => {})
      .finally(() => loading?.hide());
  };

  const handleSubmitForm = (data: IFormUpdateDocument) => {
    if (fileId) {
      loading?.show();
      const formData = new FormData();
      const newFileList: File[] = [];

      data?.files?.forEach((uploadItem: IUploadFile) => {
        if (uploadItem?.file) {
          newFileList.push(uploadItem.file);
        }
      });

      data.name && formData.append('name', data.name);
      if (newFileList.length > DEFAULT_NUMBER_ZERO) {
        newFileList.forEach((file: File) => {
          formData.append('files', file);
        });
      }

      const payload: IPayloadUpdateDocument = {
        fileId: fileId,
        body: formData,
      };

      dispatch(updateDocument(payload))
        .unwrap()
        .then((res) => {
          onClose && onClose();
          dispatch(fileActions.setRefreshList(true));
        })
        .catch((error) => {})
        .finally(() => loading?.hide());
    }
  };
  //#endregion Handle Function

  return (
    <Modal title={t('edit_file_title')} isOpen={isOpen} onClose={onClose}>
      <form id='crmUpdateDocumentModal' className={cx('container')} onSubmit={handleSubmit(handleSubmitForm)}>
        <div className={cx('contentModal')}>
          <Controller
            name='name'
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <BaseInput
                id={name}
                label={t('edit_file_name_label')}
                //placeholder={t('edit_file_name_label')}
                value={value}
                messageError={errors.name?.message}
                onChange={onChange}
              />
            )}
          />

          <Controller
            name='files'
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <BaseUploadFile
                label={t('edit_update_file_label')}
                height={64}
                defaultFileList={value}
                acceptFile={ALLOWED_FILE_TYPE}
                onChange={(item) => onChange(item)}
                errorMessage={errors.files?.message}
                id='updateFiles'
              />
            )}
          />
        </div>
        <div className={cx('footerModal')}>
          <BaseButton type='button' text={t('common_cancel_label')} width={65} onClick={onClose} />
          <BaseButton
            type='submit'
            text={t('common_save_label')}
            typeStyle={ButtonTypeEnum.PRIMARY}
            width={80}
            disabled={!isDirty}
          />
        </div>
      </form>
    </Modal>
  );
};

export default UpdateDocumentModal;
