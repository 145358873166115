// Libs
import * as yup from 'yup';
// Components, Layouts, Pages
import {
  ActivityDayCenterClient,
  AmbulationDayCenterClient,
  CareNeedsDayCenterClient,
  MedicationsDayCenterClient,
  EliminationDayCenterClient,
  MealsDietDayCenterClient,
  ReviewDayCenterClient,
  TransportDayCenterClient,
  MentalBehaviorDayCenterClient,
  CarePlanIcdTenCodesDayCenterClient,
  DemographicsDayCenterClient,
  MedicalConditionDayCenterClient,
  CarePlanAllergiesDayCenterClient,
  PrimaryDiagnosisDayCenterClient,
} from '~/components';
import { DEFAULT_NUMBER_ZERO, OBJECT } from '~/utils/constants/common';
// Others
import { IFormCarePlan, IQuickLinkItem } from '~/utils/interface/crm/carePlan';

export const carePlanDayCenterClientSchema = () => {
  return yup
    .object()
    .shape({
      quickLink: yup
        .object()
        .shape({
          CARE_NEEDS: yup.boolean().optional(),
          DEMOGRAPHICS: yup.boolean().optional(),
          ALLERGIES: yup.boolean().optional(),
          ICD10_CODES: yup.boolean().optional(),
          PRIMARY_DIAGNOSIS: yup.boolean().optional(),
          MEDICAL_CONDITIONS: yup.boolean().optional(),
          METAL_BEHAVIOR_CONDITIONS: yup.boolean().optional(),
          MEDICATIONS: yup.boolean().optional(),
          ELIMINATION: yup.boolean().optional(),
          AMBULATION: yup.boolean().optional(),
          MEALS: yup.boolean().optional(),
          ACTIVITY: yup.boolean().optional(),
          TRANSPORT: yup.boolean().optional(),
          REVIEW: yup.boolean().optional(),
        })
        .optional(),

      careNeed: yup
        .object()
        .shape({
          startDate: yup.string().nullable().optional(),
          beLeftAlone: yup.string().nullable().optional(),
          outingsAllowed: yup.string().nullable().optional(),
          photosAllowed: yup.string().nullable().optional(),
          codeStatus: yup.string().nullable().optional(),
          desiredOutcome: yup.array().of(yup.string().required()).nullable().optional(),
          goals: yup.string().nullable().optional(),
        })
        .nullable()
        .optional(),

      demographic: yup
        .object()
        .shape({
          dateOfBirth: yup.string().nullable().optional(),
          gender: yup.string().nullable().optional(),
          maritalStatus: yup.string().nullable().optional(),
          race: yup.array().of(yup.string().required()).nullable().optional(),
          religion: yup.string().nullable().optional(),
          livesWith: yup.array().of(yup.string().required()).nullable().optional(),
        })
        .nullable()
        .optional(),

      allergies: yup
        .object()
        .shape({
          notes: yup.string().nullable().optional(),
        })
        .nullable()
        .optional(),

      icd10Codes: yup
        .object()
        .shape({
          value: yup.string().nullable().optional(),
        })
        .nullable()
        .optional(),

      primaryDiagnosis: yup
        .object()
        .shape({
          value: yup.string().nullable().optional(),
        })
        .nullable()
        .optional(),

      medicalCondition: yup
        .object()
        .shape({
          secondaryDiagnosis: yup.string().nullable().optional(),
          hearing: yup.array().of(yup.string().required()).nullable().optional(),
          speech: yup.array().of(yup.string().required()).nullable().optional(),
          vision: yup.array().of(yup.string().required()).nullable().optional(),
          swallowing: yup.array().of(yup.string().required()).nullable().optional(),
          other: yup.array().of(yup.string().required()).nullable().optional(),
        })
        .nullable()
        .optional(),

      mentalBehaviorCondition: yup
        .object()
        .shape({
          diagnosedDisordersMedications: yup.string().nullable().optional(),
          behaviors: yup.array().of(yup.string().required()).nullable().optional(),
          exitSeeking: yup.string().nullable().optional(),
          dementia: yup.string().nullable().optional(),
        })
        .nullable()
        .optional(),

      medication: yup
        .object()
        .shape({
          medicationTakenAtCenter: yup.string().nullable().optional(),
          takesMedication: yup.array().of(yup.string().required()).nullable().optional(),
        })
        .nullable()
        .optional(),

      elimination: yup
        .object()
        .shape({
          incontinence: yup.array().of(yup.string().required()).nullable().optional(),
          toileting: yup.array().of(yup.string().required()).nullable().optional(),
          notes: yup.string().nullable().optional(),
        })
        .nullable()
        .optional(),

      ambulation: yup
        .object()
        .shape({
          ambulationLevel: yup.array().of(yup.string().required()).nullable().optional(),
          devices: yup.array().of(yup.string().required()).nullable().optional(),
          assistance: yup.array().of(yup.string().required()).nullable().optional(),
          transfers: yup.array().of(yup.string().required()).nullable().optional(),
          fallRisk: yup.string().nullable().optional(),
          elopementRisk: yup.string().nullable().optional(),
        })
        .nullable()
        .optional(),

      meal: yup
        .object()
        .shape({
          diet: yup.array().of(yup.string().required()).nullable().optional(),
          texture: yup.array().of(yup.string().required()).nullable().optional(),
          assistance: yup.array().of(yup.string().required()).nullable().optional(),
          restrictions: yup.string().nullable().optional(),
          lunchSnackProvided: yup.array().of(yup.string().required()).nullable().optional(),
          restrictionsDislikes: yup.string().nullable().optional(),
          favoriteFoods: yup.string().nullable().optional(),
        })
        .nullable()
        .optional(),

      activity: yup
        .object()
        .shape({
          encourageParticipate: yup.array().of(yup.string().required()).nullable().optional(),
          enjoysGroupActivities: yup.string().nullable().optional(),
          assistance: yup.array().of(yup.string().required()).nullable().optional(),
          favoriteActivities: yup.string().nullable().optional(),
          activityGoal: yup.string().nullable().optional(),
        })
        .nullable()
        .optional(),

      transport: yup
        .object()
        .shape({
          transportProvided: yup.array().of(yup.string().required()).nullable().optional(),
        })
        .nullable()
        .optional(),
      review: yup
        .object()
        .shape({
          carePlanReviewed: yup.array().of(yup.string().required()).nullable().optional(),
          memberRightsReviewed: yup.array().of(yup.string().required()).nullable().optional(),
          grievanceReviewed: yup.array().of(yup.string().required()).nullable().optional(),
        })
        .nullable()
        .optional(),
    })
    .required();
};

export const carePlanDayCenterClientDefaultValues: IFormCarePlan = {
  quickLink: {
    CARE_NEEDS: false,
    DEMOGRAPHICS: false,
    ALLERGIES: false,
    ICD10_CODES: false,
    PRIMARY_DIAGNOSIS: false,
    MEDICAL_CONDITIONS: false,
    MENTAL_BEHAVIOR_CONDITIONS: false,
    MEDICATIONS: false,
    ELIMINATION: false,
    AMBULATION: false,
    MEALS: false,
    ACTIVITY: false,
    TRANSPORT: false,
    REVIEW: false,
  },
  careNeed: {
    startDate: null,
    beLeftAlone: null,
    outingsAllowed: null,
    photosAllowed: null,
    codeStatus: null,
    desiredOutcome: null,
    goals: null,
  },
  demographic: {
    dateOfBirth: null,
    gender: null,
    maritalStatus: null,
    race: null,
    religion: null,
    livesWith: null,
  },
  allergies: {
    notes: null,
  },
  icd10Codes: {
    value: null,
  },
  primaryDiagnosis: {
    value: null,
  },
  medicalCondition: {
    secondaryDiagnosis: null,
    hearing: null,
    speech: null,
    vision: null,
    swallowing: null,
    other: null,
  },
  mentalBehaviorCondition: {
    diagnosedDisordersMedications: null,
    behaviors: null,
    exitSeeking: null,
    dementia: null,
  },
  medication: {
    medicationTakenAtCenter: null,
    takesMedication: null,
  },
  elimination: {
    incontinence: null,
    toileting: null,
    notes: null,
  },
  ambulation: {
    ambulationLevel: null,
    devices: null,
    assistance: null,
    transfers: null,
    fallRisk: null,
    elopementRisk: null,
  },
  meal: {
    diet: null,
    texture: null,
    assistance: null,
    restrictions: null,
    lunchSnackProvided: null,
    restrictionsDislikes: null,
    favoriteFoods: null,
  },
  activity: {
    encourageParticipate: null,
    enjoysGroupActivities: null,
    assistance: null,
    favoriteActivities: null,
    activityGoal: null,
  },
  transport: {
    transportProvided: null,
  },
  review: {
    carePlanReviewed: null,
    memberRightsReviewed: null,
    grievanceReviewed: null,
  },
};

export const quickLinkDayCenterClientItems: IQuickLinkItem[] = [
  {
    key: 'CARE_NEEDS',
    defaultValue: 'careNeed',
    title: 'care_plan_quick_link_title_care_needs',
    component: CareNeedsDayCenterClient,
  },
  {
    key: 'DEMOGRAPHICS',
    defaultValue: 'demographic',
    title: 'care_plan_quick_link_title_demographics',
    component: DemographicsDayCenterClient,
  },
  {
    key: 'ALLERGIES',
    defaultValue: 'allergies',
    title: 'care_plan_quick_link_title_allergies',
    component: CarePlanAllergiesDayCenterClient,
  },
  {
    key: 'ICD10_CODES',
    defaultValue: 'icd10Codes',
    title: 'care_plan_quick_link_title_icd_10_codes',
    component: CarePlanIcdTenCodesDayCenterClient,
  },
  {
    key: 'PRIMARY_DIAGNOSIS',
    defaultValue: 'primaryDiagnosis',
    title: 'care_plan_quick_link_title_primary_diagnosis',
    component: PrimaryDiagnosisDayCenterClient,
  },
  {
    key: 'MEDICAL_CONDITIONS',
    defaultValue: 'medicalCondition',
    title: 'care_plan_quick_link_title_medical_conditions',
    component: MedicalConditionDayCenterClient,
  },
  {
    key: 'MENTAL_BEHAVIOR_CONDITIONS',
    defaultValue: 'mentalBehaviorCondition',
    title: 'care_plan_quick_link_title_mental_behavior',
    component: MentalBehaviorDayCenterClient,
  },
  {
    key: 'MEDICATIONS',
    defaultValue: 'medication',
    title: 'care_plan_quick_link_title_medications',
    component: MedicationsDayCenterClient,
  },
  {
    key: 'ELIMINATION',
    defaultValue: 'elimination',
    title: 'care_plan_quick_link_title_elimination',
    component: EliminationDayCenterClient,
  },
  {
    key: 'AMBULATION',
    defaultValue: 'ambulation',
    title: 'care_plan_quick_link_title_ambulation',
    component: AmbulationDayCenterClient,
  },
  {
    key: 'MEALS',
    defaultValue: 'meal',
    title: 'care_plan_quick_link_title_meal_diet',
    component: MealsDietDayCenterClient,
  },
  {
    key: 'ACTIVITY',
    defaultValue: 'activity',
    title: 'care_plan_quick_link_title_activity',
    component: ActivityDayCenterClient,
  },
  {
    key: 'TRANSPORT',
    defaultValue: 'transport',
    title: 'care_plan_quick_link_title_transport',
    component: TransportDayCenterClient,
  },
  {
    key: 'REVIEW',
    defaultValue: 'review',
    title: 'care_plan_quick_link_title_review',
    component: ReviewDayCenterClient,
  },
];

export const removeNullFields = (obj: Record<string, any>): Record<string, any> => {
  return Object.entries(obj)
    .filter(([_, value]) => value !== null)
    .reduce((acc, [key, value]) => {
      if (typeof value === OBJECT && !Array.isArray(value)) {
        const nestedObj = removeNullFields(value);
        if (Object.keys(nestedObj).length > DEFAULT_NUMBER_ZERO) {
          acc[key] = nestedObj;
        }
      } else {
        acc[key] = value;
      }
      return acc;
    }, {} as Record<string, any>);
};
