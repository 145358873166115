// Libs
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseButton, BaseInput, BaseUploadImage } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum, InputTypeEnum } from '~/utils/enum';
import { IUploadImage } from '~/utils/interface/common';
import { ICaregiverReferral, IFormCaregiverReferralModal } from '~/mockData/mockInterface';
import { RED_FF0000 } from '~/utils/constants/color';
// Styles, images, icons
import styles from './CaregiverReferralModal.module.scss';
import { icons } from '~/assets';

type Props = {
  onClose: () => void;
  data?: ICaregiverReferral;
};

const cx = classNames.bind(styles);

const CaregiverReferralModal = (props: Props) => {
  //#region Destructuring Props
  const { onClose, data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [formState, setFormState] = useState<IFormCaregiverReferralModal>({
    firstName: EMPTY_STRING,
    avatarUrl: EMPTY_STRING,
    referralID: EMPTY_STRING,
    address: EMPTY_STRING,
    email: EMPTY_STRING,
    phoneNumber: EMPTY_STRING,
  });
  const [files, setFiles] = useState<File[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!data) return;

    setFormState(data);
  }, [data]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleAddCaregiverReferral = () => {
    const formData = new FormData();
    if (files?.length > DEFAULT_NUMBER_ZERO) {
      files.forEach((file: File) => {
        formData.append('avatarUrl', file);
      });
    }

    Object.keys(formState).forEach((key: string) => {
      if (!formState[key as keyof IFormCaregiverReferralModal]) return;
      formData.append(key, formState[key as keyof IFormCaregiverReferralModal] as string);
    });

    // Handle Update Later
  };

  const handleEditCaregiverReferral = () => {
    // Handle Update Later
  };

  const handleDeleteCaregiverReferral = () => {
    // Handle Update later
  };

  const handleUploadChange = (imageUploadList: IUploadImage[]) => {
    if (!imageUploadList?.length) return;

    const fileList: File[] = [];
    imageUploadList.forEach((imageUploadItem: IUploadImage) => {
      if (!imageUploadItem?.file) return;
      fileList.push(imageUploadItem.file);
    });
    setFiles(fileList);
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };
  //#endregion Handle Function

  return (
    <div id='addCaregiverReferralModal' className={cx('addCaregiverReferralModal')}>
      <div className={cx('bodyModal')}>
        <BaseInput
          label={t('caregiver_modal_add_referral_label_name')}
          id='firstName'
          name='firstName'
          //placeholder={t('caregiver_modal_add_referral_label_name')}
          value={formState.firstName}
          onChange={handleChangeInput}
        />

        <BaseUploadImage
          label={t('caregiver_modal_add_referral_label_profile_image')}
          textBtn={t('caregiver_modal_add_referral_placeholder_upload_profile_image')}
          height={64}
          onChange={handleUploadChange}
        />

        <div className={cx('inputContent')}>
          <BaseInput
            label={t('caregiver_modal_add_referral_label_referral_id')}
            id='referralID'
            name='referralID'
            //placeholder={t('caregiver_modal_add_referral_label_referral_id')}
            onChange={handleChangeInput}
            value={formState.referralID}
          />

          <BaseInput
            label={t('caregiver_modal_add_referral_label_address')}
            id='address'
            name='address'
            //placeholder={t('caregiver_modal_add_referral_label_address')}
            onChange={handleChangeInput}
            value={formState.address}
          />
        </div>

        <div className={cx('inputContent')}>
          <BaseInput
            label={t('caregiver_modal_add_referral_label_email')}
            id='email'
            type={InputTypeEnum.EMAIL}
            name='email'
            //placeholder={t('caregiver_modal_add_referral_label_email')}
            onChange={handleChangeInput}
            value={formState.email}
          />

          <BaseInput
            label={t('caregiver_modal_add_referral_label_phone_number')}
            id='phoneNumber'
            name='phoneNumber'
            //placeholder={t('caregiver_modal_add_referral_label_phone_number')}
            onChange={handleChangeInput}
            value={formState.phoneNumber}
          />
        </div>
      </div>

      <div className={cx('footerModal')}>
        {data && (
          <BaseButton
            iconLeft={icons.commonIconTrash}
            iconWidthHeight={16}
            text={t('common_delete_label')}
            typeStyle={ButtonTypeEnum.ONLY_TEXT}
            color={RED_FF0000}
            width={65}
            onClick={onClose}
          />
        )}
        <BaseButton text={t('common_cancel_label')} width={65} onClick={onClose} />
        <BaseButton
          text={data ? t('common_update_label') : t('common_save_label')}
          typeStyle={ButtonTypeEnum.PRIMARY}
          width={80}
          onClick={handleAddCaregiverReferral}
        />
      </div>
    </div>
  );
};

export default CaregiverReferralModal;
