// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';

// Components, Layouts, Pages
import { BaseButton, InputSearch, ReactPortal, ImageCircle } from '~/components';

// Others
import { IAddAssignee } from '~/utils/interface/common';
import {
  ASTERISK_SYMBOL,
  CURRENT,
  DEFAULT_HEIGHT_ASSIGNEE_MODAL,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  LIMIT_TAG_ASSIGNEE,
  PIXELS,
  PLUS_CHARACTER,
} from '~/utils/constants/common';
import { ButtonTypeEnum, HTMLEventEnum, ImageCircleTypeEnum } from '~/utils/enum';
import { RED_FF0000 } from '~/utils/constants/color';
import { generateGuid } from '~/utils/helpers/common';

// Styles, images, icons
import styles from './AddAssigneeModal.module.scss';
import { icons } from '~/assets';

type Props = {
  label?: string;
  placeholder?: string;
  name?: string;
  assigneeList: IAddAssignee[];
  required?: boolean;
  value?: string[];
  errorMessage?: string;
  onAdd: (assignee: IAddAssignee, name?: string) => void;
  onCloseAssignee: (assignee: IAddAssignee, name?: string) => void;
  disabled?: boolean;
  icon?: string;
};

const cx = classNames.bind(styles);

const AdminAddAssigneeModal = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  //#region Destructuring Props
  const {
    assigneeList,
    required,
    errorMessage,
    onAdd,
    onCloseAssignee,
    name,
    value,
    label,
    placeholder,
    disabled = false,
    icon = icons.crmIconPlus,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const selectRef = useRef<HTMLDivElement | null>(null);
  const multipleAssigneeId = generateGuid();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [position, setPosition] = useState({ top: DEFAULT_NUMBER_ZERO, left: DEFAULT_NUMBER_ZERO });
  const [filteredAssignees, setFilteredAssignees] = useState<IAddAssignee[]>([]);
  const [listSelectedAssign, setListSelectedAssign] = useState<IAddAssignee[]>([]);
  const [isShow, setIsShow] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!assigneeList) return;

    let updatedAssignees = assigneeList;

    if (value && value.length > DEFAULT_NUMBER_ZERO) {
      updatedAssignees = assigneeList.map((assignee) => ({
        ...assignee,
        isSelected: value.includes(assignee.id.toString()),
      }));
    }

    const selectedAssignees = updatedAssignees.filter((assignee) => assignee.isSelected);

    setFilteredAssignees(updatedAssignees);
    setListSelectedAssign(selectedAssignees);
  }, [value, assigneeList]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const updatePosition = useCallback(() => {
    if (modalRef && CURRENT in modalRef && modalRef.current) {
      const rect = modalRef.current.getBoundingClientRect();
      const modalHeight = selectRef.current?.clientHeight || DEFAULT_HEIGHT_ASSIGNEE_MODAL;
      const windowHeight = window.innerHeight;

      let topPosition = rect.bottom;
      if (modalHeight && topPosition + modalHeight > windowHeight) {
        topPosition = rect.top - modalHeight;
      }

      setPosition({ top: topPosition, left: rect.left });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalRef, isShow, selectRef, filteredAssignees]);

  useEffect(() => {
    if (isShow) {
      updatePosition();
      window.addEventListener(HTMLEventEnum.RESIZE, updatePosition);
      window.addEventListener(HTMLEventEnum.SCROLL, updatePosition);
    } else {
      window.removeEventListener(HTMLEventEnum.RESIZE, updatePosition);
      window.removeEventListener(HTMLEventEnum.SCROLL, updatePosition);
    }

    return () => {
      window.removeEventListener(HTMLEventEnum.RESIZE, updatePosition);
      window.removeEventListener(HTMLEventEnum.SCROLL, updatePosition);
    };
  }, [isShow, updatePosition]);

  //#endregion Implement Hook

  //#region Handle Function
  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node) &&
      modalRef.current &&
      !modalRef.current.contains(event.target as Node)
    ) {
      setIsShow(false);
    }
  };

  const handleShowAssign = () => {
    setIsShow(!isShow);
  };

  const handleAddAssignee = (assignee: IAddAssignee) => {
    onAdd(assignee, name);

    setListSelectedAssign((prevAssignees) => [...prevAssignees, assignee]);
    setFilteredAssignees((prev) =>
      prev.map((item) => (item.id === assignee.id ? { ...item, isSelected: true } : item))
    );
  };

  const handleCloseAssignee = (assignee: IAddAssignee) => {
    onCloseAssignee(assignee, name);

    setListSelectedAssign((prev) =>
      prev.filter((selectedAssignee) => selectedAssignee.id.toString() !== assignee.id.toString())
    );
    setFilteredAssignees((prev) =>
      prev.map((item) => (item.id.toString() === assignee.id.toString() ? { ...item, isSelected: false } : item))
    );
  };

  const handleSearchAssignee = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const filtered = assigneeList
      .filter((assignee) =>
        `${assignee.firstName?.toLowerCase()} ${assignee.lastName?.toLowerCase()}`.includes(value.toLowerCase())
      )
      .filter((assignee) => !listSelectedAssign.some((selected) => selected.id === assignee.id));

    setFilteredAssignees(filtered);
  };
  //#endregion Handle Function

  return (
    <div ref={modalRef} id='addAssigneeModalComponent'>
      {label && (
        <h3 className={cx('titleSelect')}>
          {label || t('task_modal_task_assignees_label')} {required && <span>{ASTERISK_SYMBOL}</span>}
        </h3>
      )}

      <button
        className={cx('selectAssign', disabled && 'bgDisable')}
        onClick={handleShowAssign}
        type='button'
        disabled={disabled}
      >
        <span className={cx('titleAssignee')}>{placeholder || t('task_modal_task_assignees_placeholder')}</span>
        <img src={icon} alt={t('common_img_text_alt')} />
      </button>
      {errorMessage && <div className={cx('errMessage')}>{errorMessage}</div>}
      {listSelectedAssign.length > DEFAULT_NUMBER_ZERO && (
        <div className={cx('assigneeSelected')}>
          {listSelectedAssign.slice(DEFAULT_NUMBER_ZERO, LIMIT_TAG_ASSIGNEE).map((assigneeItem, index) => {
            return (
              <div
                className={cx('tagAssigneeSelected')}
                key={index}
                data-tooltip-id={`singleAvatar-${index}`}
                data-tooltip-place='top'
                data-tooltip-content={`${assigneeItem.firstName} ${assigneeItem.lastName}`}
              >
                <ImageCircle
                  width={16}
                  height={16}
                  fontSize={8}
                  imageUrl={assigneeItem.avatarUrl}
                  firstName={assigneeItem.firstName}
                  lastName={assigneeItem.lastName}
                  type={assigneeItem.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
                />
                <span className={cx('tagFirstName')}>{assigneeItem.firstName}</span>
                <img
                  src={icons.commonIconModalClose}
                  className={cx('iconCloseAssignee')}
                  onClick={() => handleCloseAssignee(assigneeItem)}
                  alt={t('common_img_text_alt')}
                />
                <Tooltip id={`singleAvatar-${index}`} className={cx('tooltipText')} />
              </div>
            );
          })}
          {listSelectedAssign.length > LIMIT_TAG_ASSIGNEE && (
            <div
              className={cx('tagMore')}
              data-tooltip-id={`multipleAssignee-${multipleAssigneeId}`}
              data-tooltip-place='top'
            >
              <span>{`${PLUS_CHARACTER}${listSelectedAssign.length - LIMIT_TAG_ASSIGNEE}`}</span>
              <Tooltip id={`multipleAssignee-${multipleAssigneeId}`} className={cx('tooltipText')}>
                <div className={cx('tooltip')}>
                  {listSelectedAssign.slice(LIMIT_TAG_ASSIGNEE).map((assignee, index) => (
                    <span key={index}>
                      {assignee.firstName} {assignee.lastName}
                    </span>
                  ))}
                </div>
              </Tooltip>
            </div>
          )}
        </div>
      )}

      {isShow && (
        <ReactPortal wrapperId='reactPortalModalAddAssignee'>
          <div
            style={{ top: `${position.top}${PIXELS}`, left: `${position.left}${PIXELS}` }}
            className={cx('addAssigneeContainer')}
            ref={selectRef}
          >
            <div className={cx('container')}>
              <div className={cx('search')}>
                <InputSearch placeholder={t('common_placeholder_search')} onChange={handleSearchAssignee} />
              </div>
              <div className={cx('assignee')}>
                {filteredAssignees.length > DEFAULT_NUMBER_ZERO ? (
                  filteredAssignees.map((assigneeItem, index) => {
                    return (
                      <div
                        key={index}
                        className={cx(
                          'assigneeItem',
                          assigneeItem.isSelected && 'selected',
                          index !== filteredAssignees.length - DEFAULT_NUMBER_ONE && 'lineBottom'
                        )}
                      >
                        <div className={cx('avatarFirstLastName')}>
                          <ImageCircle
                            fontSize={23}
                            width={46}
                            height={46}
                            firstName={assigneeItem.firstName}
                            lastName={assigneeItem.lastName}
                            imageUrl={assigneeItem.avatarUrl}
                            type={assigneeItem.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
                          />

                          <div className={cx('nameGroup')}>
                            <div className={cx('firstLastName')}>
                              {`${assigneeItem.firstName} ${assigneeItem.lastName}`}
                            </div>
                            <div className={cx('email')}>{assigneeItem.email}</div>
                          </div>
                        </div>

                        <BaseButton
                          text={assigneeItem.isSelected ? t('common_delete_label') : t('common_btn_add')}
                          typeStyle={ButtonTypeEnum.WHITE}
                          width={assigneeItem.isSelected ? 60 : 45}
                          height={24}
                          color={assigneeItem.isSelected ? RED_FF0000 : ''}
                          onClick={() =>
                            assigneeItem.isSelected
                              ? handleCloseAssignee(assigneeItem)
                              : handleAddAssignee(assigneeItem)
                          }
                        />
                      </div>
                    );
                  })
                ) : (
                  <p className={cx('textNoData')}>{t('common_empty_data')}</p>
                )}
              </div>
            </div>
          </div>
        </ReactPortal>
      )}
    </div>
  );
});

export default AdminAddAssigneeModal;
