// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import { getListTask, getTaskDetail, deleteTask, createTask, updateTask, getSelectTask } from '~/thunks/task/taskThunk';

export interface TaskState {
  isRefreshTaskList: boolean;
}

const initialState: TaskState = {
  isRefreshTaskList: false,
};

const taskSlice = createSlice({
  name: 'taskState',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshTaskList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list task
    builder
      .addCase(getListTask.pending, (state) => {})
      .addCase(getListTask.fulfilled, (state) => {})
      .addCase(getListTask.rejected, (state) => {});

    // Get Task Detail
    builder
      .addCase(getTaskDetail.pending, (state) => {})
      .addCase(getTaskDetail.fulfilled, (state) => {})
      .addCase(getTaskDetail.rejected, (state) => {});

    //Delete task
    builder
      .addCase(deleteTask.pending, (state) => {})
      .addCase(deleteTask.fulfilled, (state) => {})
      .addCase(deleteTask.rejected, (state) => {});

    // Create task
    builder
      .addCase(createTask.pending, (state) => {})
      .addCase(createTask.fulfilled, (state) => {})
      .addCase(createTask.rejected, (state) => {});

    // Update Task
    builder
      .addCase(updateTask.pending, (state) => {})
      .addCase(updateTask.fulfilled, (state) => {})
      .addCase(updateTask.rejected, (state) => {});

    // Get Select Task
    builder
      .addCase(getSelectTask.pending, (state) => {})
      .addCase(getSelectTask.fulfilled, (state) => {})
      .addCase(getSelectTask.rejected, (state) => {});
  },
});

// Actions
export const taskActions = taskSlice.actions;

// Selectors
export const selectIsRefreshTaskList = (state: RootState) => state.taskState.isRefreshTaskList;

// Reducer
const taskReducer = taskSlice.reducer;
export default taskReducer;
