// Libs
import classNames from 'classnames/bind';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Fragment, MutableRefObject, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import { NotificationItem } from '~/components';
// Others
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { markedReadNotification } from '~/thunks/notifications/notificationsThunk';
import { INotification } from '~/utils/interface/notification';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING, NEW_NOTIFICATION_THRESHOLD } from '~/utils/constants/common';
import { adminRouteAbsolute, caregiverRouteAbsolute, staffRouteAbsolute } from '~/utils/constants/route';
import {
  AccountRoleCodesEnum,
  BusinessNotificationTypeEnum,
  StatusNotificationEnum,
  StorageEnum,
  TimeFormatEnum,
} from '~/utils/enum';
// Styles, images, icons
import styles from './NotificationScroll.module.scss';

type Props = {
  close: (focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>) => void;
  loadMore: () => void;
};

const cx = classNames.bind(styles);

const NotificationScroll = (props: Props) => {
  //#region Destructuring Props
  const { close, loadMore } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const role = localStorage.getItem(StorageEnum.ROLE);
  //#endregion Declare Hook

  //#region Selector
  const { notificationList } = useAppSelector((state: RootState) => state.notifications);
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function

  const newNotification = useMemo(() => {
    const list = notificationList.filter((notificationItem: INotification) => {
      const createdAt = moment(notificationItem.createdAt);
      const diffInMinutes = moment().diff(moment(createdAt), 'minutes');
      const isToday = moment(createdAt.toDate()).isSame(moment(), 'day');

      return isToday && diffInMinutes < NEW_NOTIFICATION_THRESHOLD;
    });

    return list;
  }, [notificationList]);

  const todayNotification = useMemo(() => {
    const list = notificationList.filter((notificationItem: INotification) => {
      const createdAt = moment(notificationItem.createdAt);
      const diffInMinutes = moment().diff(moment(createdAt), 'minutes');
      const isToday = moment(createdAt.toDate()).isSame(moment(), 'day');

      return isToday && diffInMinutes >= NEW_NOTIFICATION_THRESHOLD;
    });

    return list;
  }, [notificationList]);

  const groupedOtherNotification = useMemo(() => {
    const grouped: Record<string, typeof notificationList> = {};

    notificationList.forEach((notificationItem) => {
      const createdAt = moment(notificationItem.createdAt);
      const formattedDate = moment(createdAt).format(TimeFormatEnum.MM_DD_YYYY);
      const isToday = moment(createdAt.toDate()).isSame(moment(), 'day');

      if (isToday) return;

      if (!grouped[formattedDate]) {
        grouped[formattedDate] = [];
      }
      grouped[formattedDate].push(notificationItem);
    });

    return Object.entries(grouped).map(([date, notifications]) => ({ date, notifications }));
  }, [notificationList]);

  const handleShowDetail = (notification: INotification) => {
    if (!notification || !role) return;
    const { businessId, businessType } = notification;

    let route = EMPTY_STRING;
    let state = {};
    switch (businessType) {
      case BusinessNotificationTypeEnum.TASK:
        state = { taskId: businessId };
        if (role === AccountRoleCodesEnum.ADMIN) {
          route = adminRouteAbsolute.task;
        } else if (role === AccountRoleCodesEnum.EMPLOYEE) {
          route = staffRouteAbsolute.task;
        } else if (role === AccountRoleCodesEnum.CAREGIVER) {
          route = caregiverRouteAbsolute.task;
        }
        break;
      default:
        break;
    }

    route && state && navigate(route, { state });
  };

  const handleClickNotification = (notification: INotification, closeFn: Function) => {
    if (!notification?.id) return;

    if (notification.status === StatusNotificationEnum.UNREAD) {
      dispatch(markedReadNotification(notification.id)).unwrap();
    }

    handleShowDetail(notification);
    closeFn();
  };
  //#endregion Handle Function

  return (
    <div id='notificationScrollComponent'>
      <InfiniteScroll
        dataLength={notificationList.length}
        next={loadMore}
        hasMore={true}
        height={450}
        loader={EMPTY_STRING}
      >
        <div className={cx('receivedNotificationScroll')}>
          {notificationList.length > DEFAULT_NUMBER_ZERO ? (
            <div className={cx('notificationGroupList')}>
              {newNotification.length > DEFAULT_NUMBER_ZERO && (
                <div className={cx('notificationWrap')}>
                  <div className={cx('notificationGroupTitle')}>{t('common_notification_new')}</div>
                  <div className={cx('receivedNotificationList')}>
                    {newNotification.map((notification: INotification, index: number) => (
                      <NotificationItem
                        key={index}
                        data={notification}
                        onClick={(notification) => handleClickNotification(notification, close)}
                      />
                    ))}
                  </div>
                </div>
              )}

              {todayNotification.length > 0 && (
                <div className={cx('notificationWrap')}>
                  <div className={cx('notificationGroupTitle')}>{t('common_notification_today')}</div>
                  <div className={cx('receivedNotificationList')}>
                    {todayNotification.map((notification: INotification, index: number) => (
                      <NotificationItem
                        key={index}
                        data={notification}
                        onClick={(notification) => handleClickNotification(notification, close)}
                      />
                    ))}
                  </div>
                </div>
              )}

              {groupedOtherNotification.map(({ date, notifications }) => (
                <Fragment key={date}>
                  <div className={cx('notificationWrap')}>
                    <div className={cx('notificationGroupTitle')}>{date}</div>
                    <div className={cx('receivedNotificationList')}>
                      {notifications.map((notification: INotification, index: number) => (
                        <NotificationItem
                          key={index}
                          data={notification}
                          onClick={(notification) => handleClickNotification(notification, close)}
                        />
                      ))}
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>
          ) : (
            <div className={cx('emptyData')}>{t('common_no_notification')}</div>
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default NotificationScroll;
