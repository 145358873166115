// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import authApi from '~/apis/auth';
import {
  IBodyChangePassword,
  IForgotPasswordPayload,
  ILoginPayload,
  INewPasswordReq,
  IVerifyCode,
} from '~/utils/interface/auth';
import {
  CHANGE_PASSWORD,
  CREATE_NEW_PASSWORD,
  FORGOT_PASSWORD,
  LOGIN,
  VERIFY_CODE,
} from '~/utils/constants/actionType';

export const handleLogin = createAsyncThunk(LOGIN, async (payload: ILoginPayload, { rejectWithValue }) => {
  try {
    const res = await authApi.login(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const forgotPassword = createAsyncThunk(
  FORGOT_PASSWORD,
  async (payload: IForgotPasswordPayload, { rejectWithValue }) => {
    try {
      const res = await authApi.forgotPassword(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createNewPassword = createAsyncThunk(
  CREATE_NEW_PASSWORD,
  async (payload: INewPasswordReq, { rejectWithValue }) => {
    try {
      const res = await authApi.createNewPassword(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  CHANGE_PASSWORD,
  async (payload: IBodyChangePassword, { rejectWithValue }) => {
    try {
      const res = await authApi.changePassword(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyCode = createAsyncThunk(VERIFY_CODE, async (payload: IVerifyCode, { rejectWithValue }) => {
  try {
    const resp = await authApi.verifyCode(payload);
    return resp.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
