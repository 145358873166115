export const generateGuid = (): string => {
  const generateSegment = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };

  const guid =
    generateSegment() +
    generateSegment() +
    '-' +
    generateSegment() +
    '-' +
    generateSegment() +
    '-' +
    generateSegment() +
    '-' +
    generateSegment() +
    generateSegment() +
    generateSegment();

  return guid;
};
