import { urlApiApplicant } from '~/utils/constants/api';
import { BaseResponse, IListQueryParams, IListDataResponse } from '~/utils/interface/common';
import axiosClient from '~/apis/axiosClient';
import { CONTENT_TYPE_FORM_DATA } from '~/utils/constants/common';
import { IApplicant } from '~/utils/interface/applicant';

const applicantApi = {
  getList(params: IListQueryParams) {
    const url = `${urlApiApplicant.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IApplicant[]>>>(url, { params });
  },

  createApplicant(body: FormData) {
    const url = `${urlApiApplicant.createApplicant}`;
    return axiosClient.post<BaseResponse>(url, body, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },
};

export default applicantApi;
