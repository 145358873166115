// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Tooltip } from 'react-tooltip';
import { ReactCalendarGroupRendererProps, ReactCalendarItemRendererProps } from 'react-calendar-timeline';
import { EventProps, View, Views } from 'react-big-calendar';
// Components, Layouts, Pages
import {
  BaseSchedule,
  ScheduleTimeline,
  EventTimeline,
  GroupTimeline,
  ScheduleAssignee,
  ReactPortal,
} from '~/components';
// Others
import { convertTimeToAmPm } from '~/utils/helper';
import { DEFAULT_NUMBER_ZERO, DEFAULT_SCHEDULE_EVENT_COLOR, EMPTY_STRING } from '~/utils/constants/common';
import { BaseCalendarProps } from '~/utils/type/schedule';
import {
  IScheduleEvent,
  IScheduleTimelineData,
  IScheduleTimelineEvent,
  ITimelineEvent,
  ITimelineGroup,
  ITimelinePreparedData,
} from '~/utils/interface/schedule';
import { prepareMonthEventsData, prepareTimelineEventsData } from '../helper';
// Styles, images, icons
import styles from './FullSchedule.module.scss';

type Props = Omit<BaseCalendarProps, 'view' | 'date' | 'onSelectEvent' | 'events'> & {
  view: View;
  date: string;
  events: IScheduleEvent[] | IScheduleTimelineData[];
  onSelectEvent?: (event: IScheduleEvent | IScheduleTimelineEvent) => void;
};

const cx = classNames.bind(styles);

const CollapseMonthView = (props: { event: IScheduleEvent }) => {
  const { event } = props;
  const eventColor = event.color || DEFAULT_SCHEDULE_EVENT_COLOR;
  const eventName = event.tasks?.[0]?.name || EMPTY_STRING;
  const id = event.tasks?.[0]?.id || EMPTY_STRING;

  return (
    <div
      className={cx('eventContainer', 'eventContainerCollapse')}
      style={{ borderWidth: 1, borderColor: eventColor, backgroundColor: eventColor }}
      data-tooltip-id={`taskName-${id}`}
      data-tooltip-place='top'
    >
      <div className={cx('eventName')}>{eventName}</div>
      <ReactPortal wrapperId='taskTooltip'>
        <Tooltip id={`taskName-${id}`} className={cx('tooltipWrap')}>
          <div className={cx('tooltip')}>
            {event.tasks?.length > DEFAULT_NUMBER_ZERO
              ? event.tasks?.map((task, index) => (
                  <span className={cx('tooltipText')} key={index}>
                    {task?.name || EMPTY_STRING}
                  </span>
                ))
              : EMPTY_STRING}
          </div>
        </Tooltip>
      </ReactPortal>
    </div>
  );
};

const ExpandMonthView = (props: { event: IScheduleEvent }) => {
  const { event } = props;
  const eventColor = event?.color || DEFAULT_SCHEDULE_EVENT_COLOR;
  const eventName = event.tasks?.[0]?.name || EMPTY_STRING;
  const id = event.tasks?.[0]?.id || EMPTY_STRING;
  return (
    <div
      className={cx('eventContainer', 'eventContainerExpand')}
      style={{ borderWidth: 1, borderColor: eventColor, backgroundColor: eventColor }}
    >
      <div className={cx('eventName')} data-tooltip-id={`taskName-${id}`} data-tooltip-place='top'>
        {eventName}
      </div>
      <div className={cx('eventTime')}>
        {convertTimeToAmPm(event.startTime)} - {convertTimeToAmPm(event.endTime)}
      </div>

      <div className={cx('assigneeContainer')}>
        {event.assignees && <ScheduleAssignee assignees={event.assignees} eventColor={eventColor} />}
      </div>
      <ReactPortal wrapperId='taskTooltip'>
        <Tooltip id={`taskName-${id}`} className={cx('tooltipWrap')}>
          <div className={cx('tooltip')}>
            {event.tasks?.length > DEFAULT_NUMBER_ZERO
              ? event.tasks?.map((task, index) => (
                  <span className={cx('tooltipText')} key={index}>
                    {task?.name || EMPTY_STRING}
                  </span>
                ))
              : EMPTY_STRING}
          </div>
        </Tooltip>
      </ReactPortal>
    </div>
  );
};

const FullSchedule = (props: Props) => {
  //#region Destructuring Props
  const { ref, view, date, events, onSelectEvent, ...restProps } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const preparedMonthEvents = useMemo(() => {
    if (view !== Views.MONTH) return;

    return prepareMonthEventsData(props.events as IScheduleEvent[]);
  }, [view, events]);

  const preparedTimelineEvents: ITimelinePreparedData | undefined = useMemo(() => {
    if (view !== Views.WEEK && view !== Views.DAY) return;
    return prepareTimelineEventsData(props.events as IScheduleTimelineData[], view);
  }, [view, events]);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function
  return (
    <div id='fullScheduleComponent' className={cx('container')}>
      {preparedMonthEvents && view === Views.MONTH && (
        <div className={cx({ 'h-[780px]': view === Views.MONTH })}>
          {
            <BaseSchedule
              {...restProps}
              events={preparedMonthEvents}
              selectable={true}
              toolbar={false}
              date={date}
              view={view}
              components={{
                event: ({ event }: EventProps) => {
                  return (
                    <>
                      {view === Views.MONTH && (
                        <div className={cx('monthEventWrap')}>
                          {event?.resource?.map((eventData: IScheduleEvent, index: number) => (
                            <div key={index} onClick={() => onSelectEvent && onSelectEvent(eventData)}>
                              {event.resource.length > 1 ? (
                                <CollapseMonthView event={eventData} />
                              ) : (
                                <ExpandMonthView event={eventData} />
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  );
                },
              }}
            />
          }
        </div>
      )}

      {preparedTimelineEvents && (view === Views.WEEK || view === Views.DAY) && (
        <ScheduleTimeline
          date={date}
          view={view}
          items={preparedTimelineEvents.event}
          groups={preparedTimelineEvents.group}
          itemRenderer={(props: ReactCalendarItemRendererProps<ITimelineEvent>) => (
            <>
              <EventTimeline
                eventData={props.item}
                viewType={view}
                onSelectEvent={(data) => onSelectEvent && onSelectEvent(data)}
                {...props}
              />
            </>
          )}
          groupRenderer={(props: ReactCalendarGroupRendererProps<ITimelineGroup>) => (
            <GroupTimeline {...props} groupData={props?.group} />
          )}
        />
      )}
    </div>
  );
};

export default FullSchedule;
