// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
// Components, Layouts, Pages
import { BaseButton, BaseSelect, ModalUnderDevelopment } from '~/components';
// Others
import { IListSummary } from '~/mockData/mockInterface';
import { dataSummaryMock } from '~/mockData';
import { ButtonTypeEnum, TimeFormatEnum } from '~/utils/enum';
import { convertTime } from '~/utils/helper';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './SummaryTab.module.scss';

type Props = {};

const SummaryTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  const cx = classNames.bind(styles);
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [dataSummary, setDataSummary] = useState<IListSummary>(dataSummaryMock);
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleClockIn = () => {
    setIsShowModalUnderDevelopment(true);
  };
  //#endregion Handle Function

  return (
    <div id='summaryTabComponent' className={cx('summaryTabComponent')}>
      <div className={cx('contentBody')}>
        <div className={cx('contentLabelValue')}>
          <div className={cx('contentLabel')}>{t('summary_schedule_form_dates_label')}</div>
          <div className={cx('contentValueDates')}>
            {t('summary_schedule_form_dates_value', {
              startDate: dataSummary.dates
                ? convertTime(dataSummary.dates, TimeFormatEnum.MMMM_D_HH_MM_A)
                    .replace('AM', 'A.M.')
                    .replace('PM', 'P.M.')
                : EMPTY_STRING,
              endDate: dataSummary.dates
                ? convertTime(dataSummary.dates, TimeFormatEnum.MMMM_D_HH_MM_A)
                    .replace('AM', 'A.M.')
                    .replace('PM', 'P.M.')
                : EMPTY_STRING,
            })}
          </div>
        </div>

        <div className={cx('contentLabelValue')}>
          <div className={cx('contentLabel')}>{t('summary_schedule_form_status_label')}</div>
          <span className={cx('contentValue')}>{dataSummary.status}</span>
        </div>

        <div className={cx('contentLabelValue')}>
          <div className={cx('contentLabel')}>{t('summary_schedule_form_tasks_label')}</div>
          <div className={cx('tasksContainer')}>
            {dataSummary.tasks?.map((value, index) => (
              <div className={cx('contentValueNameTasks')} key={index}>
                <span className={cx('label')} />
                {value.nameTask}
                <span className={cx('contentValue')}>{value.descriptionTask}</span>
              </div>
            ))}
          </div>
        </div>

        <div className={cx('contentLabelValue')}>
          <div className={cx('contentLabel')}>{t('summary_schedule_form_schedule_label')}</div>
          <div className={cx('contentValue')}>{dataSummary.schedule}</div>
        </div>

        <div className={cx('contentLabelValue')}>
          <div className={cx('contentLabel')}>{t('summary_schedule_form_Authorization_label')}</div>
          <div className={cx('contentValue')}>{dataSummaryMock.authorization}</div>
        </div>

        <div className={cx('contentLabelValue')}>
          <div className={cx('contentLabel')}>{t('summary_schedule_form_rate_name_label')}</div>
          <div className={cx('contentValue')}>{dataSummaryMock.rateName}</div>
        </div>
      </div>

      <div className={cx('line')} />

      <div className={cx('button')}>
        <BaseButton
          onClick={handleClockIn}
          typeStyle={ButtonTypeEnum.OUT_LINE}
          text={t('summary_tab_clock_in_button')}
          minWidth={80}
        />
      </div>

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default SummaryTab;
