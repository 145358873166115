// Libs
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import Timeline, { ReactCalendarTimelineProps, TimelineHeaders } from 'react-calendar-timeline';
// Components, Layouts, Pages
import { HeaderTimeline } from '~/components';
// Others
import {
  DEFAULT_SCHEDULE_TIMELINE_MAX_HOUR,
  DEFAULT_SCHEDULE_TIMELINE_MIN_HOUR,
  DEFAULT_SCHEDULE_TIMELINE_VIEWS,
} from '~/utils/constants/component';
import { ITimelineEvent, ITimelineGroup } from '~/utils/interface/schedule';
import { TCalendarViewMode } from '~/utils/type/schedule';
// Styles, images, icons
import 'react-calendar-timeline/lib/Timeline.css';
import './ScheduleTimeline.scss';

export type ScheduleTimelineProps = ReactCalendarTimelineProps<ITimelineEvent, ITimelineGroup> & {
  date: string | Date;
  view: TCalendarViewMode;
};

const ScheduleTimeline = (props: ScheduleTimelineProps) => {
  //#region Destructuring Props
  const { date, view, ...restProps } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Declare Memo
  const minTime = useMemo(
    () => moment(date).startOf('day').add(DEFAULT_SCHEDULE_TIMELINE_MIN_HOUR, 'hours').valueOf(),
    [date]
  );
  const maxTime = useMemo(
    () => moment(date).startOf('day').add(DEFAULT_SCHEDULE_TIMELINE_MAX_HOUR, 'hours').valueOf(),
    [date]
  );
  //#endregion Declare Memo

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [visibleTimeStart, setVisibleTimeStart] = useState<number>(moment().startOf('week').valueOf());
  const [visibleTimeEnd, setVisibleTimeEnd] = useState<number>(moment().endOf('week').valueOf());
  const defaultTimeRange = maxTime - minTime;
  //#endregion Declare

  //#region Implement Hook
  useEffect(() => {
    if (!date) return;
    if (view === DEFAULT_SCHEDULE_TIMELINE_VIEWS.week) {
      const startTime = moment(date).startOf('week').valueOf();
      const endTime = moment(date).endOf('week').valueOf();
      setVisibleTimeStart(startTime);
      setVisibleTimeEnd(endTime);
      return;
    }
    if (view === DEFAULT_SCHEDULE_TIMELINE_VIEWS.day) {
      const startTime = moment(date).startOf('day').add(DEFAULT_SCHEDULE_TIMELINE_MIN_HOUR, 'hours').valueOf();
      const endTime = moment(date).startOf('day').add(DEFAULT_SCHEDULE_TIMELINE_MAX_HOUR, 'hours').valueOf();
      setVisibleTimeStart(startTime);
      setVisibleTimeEnd(endTime);
      return;
    }
  }, [date, view]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleLimitHorizontalScroll = (
    visibleTimeStart: number,
    visibleTimeEnd: number,
    updateScrollCanvas: (start: number, end: number) => void
  ) => {
    if (!view || view !== DEFAULT_SCHEDULE_TIMELINE_VIEWS.day) return;

    const duration = visibleTimeEnd - visibleTimeStart;
    if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
      updateScrollCanvas(minTime, maxTime);
      setVisibleTimeStart(minTime);
      setVisibleTimeEnd(maxTime);
    } else if (visibleTimeStart < minTime) {
      updateScrollCanvas(minTime, minTime + duration);
      setVisibleTimeStart(minTime);
      setVisibleTimeEnd(minTime + duration);
    } else if (visibleTimeEnd > maxTime) {
      updateScrollCanvas(maxTime - duration, maxTime);
      setVisibleTimeStart(maxTime - duration);
      setVisibleTimeEnd(maxTime);
    } else {
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
      setVisibleTimeStart(visibleTimeStart);
      setVisibleTimeEnd(visibleTimeEnd);
    }
  };
  //#endregion Handle Function

  return (
    <div id='scheduleTimeline'>
      <Timeline
        {...restProps}
        visibleTimeStart={visibleTimeStart}
        visibleTimeEnd={visibleTimeEnd}
        canMove={false}
        canChangeGroup={false}
        canResize={false}
        stackItems
        lineHeight={135}
        itemHeightRatio={0.7}
        sidebarWidth={173}
        onTimeChange={handleLimitHorizontalScroll}
        minZoom={defaultTimeRange}
        maxZoom={defaultTimeRange}
      >
        <TimelineHeaders>
          <HeaderTimeline height={58} view={view} />
        </TimelineHeaders>
      </Timeline>
    </div>
  );
};

export default ScheduleTimeline;
