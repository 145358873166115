// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { Views } from 'react-big-calendar';
import moment from 'moment';
// Components, Layouts, Pages
import { EventPopup, ModalUnderDevelopment, ViewSchedule } from '~/components';
// Others
import { TCalendarViewMode, TPageViewSchedule } from '~/utils/type/schedule';
import { DateFormatEnum } from '~/utils/enum';
import { mockDataCalendar } from '~/mockData';
import { CommonIconNext, CommonIconPrev } from '~/assets/svgComponents';
import { WHITE } from '~/utils/constants/color';
import {
  IOfficeSchedule,
  IScheduleEvent,
  IScheduleTimelineData,
  IScheduleTimelineEvent,
} from '~/utils/interface/schedule';
// Styles, images, icons
import styles from './NewScheduleComponent.module.scss';

type Props = {
  view: TCalendarViewMode;
  onNavigate: () => void;
  pageView: TPageViewSchedule;
};

const cx = classNames.bind(styles);

const NewScheduleComponent = (props: Props) => {
  //#region Destructuring Props
  const { view, onNavigate, pageView } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [timelineEvents, setTimelineEvents] = useState<IScheduleTimelineData[]>(mockDataCalendar);
  const [dataEvent, setDataEvent] = useState<IScheduleTimelineEvent>();
  const [currentTime, setCurrentTime] = useState<string>(moment().format(DateFormatEnum.YYYY_MM_DD));
  const timeValue = useMemo(() => {
    switch (view) {
      case Views.WEEK:
        const startOfWeek = moment(currentTime).startOf('week');
        const endOfWeek = moment(currentTime).endOf('week');
        const formattedWeek = `${startOfWeek.format('MMMM DD')} - ${endOfWeek.format('DD, YYYY')}`;
        return formattedWeek;
      case Views.DAY:
        return moment(currentTime).format('dddd, MMMM DD, YYYY');
      default:
        return moment(currentTime).format('MMMM, YYYY');
    }
  }, [currentTime]);

  const [isOpenModalUnderDevelop, setIsOpenModalUnderDevelop] = useState<boolean>(false);
  const [isShowEvent, setIsShowEvent] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListSchedule = () => {
    //TODO: HuyPahmGRF-ABD: Handle logic here
  };

  const handleGetSchedule = () => {
    //TODO: HuyPahmGRF-ABD: Handle logic here
  };

  const goPreviousTime = () => {
    const previousTime = moment(currentTime).subtract(1, view);
    setCurrentTime(previousTime.format(DateFormatEnum.YYYY_MM_DD));
  };

  const goNextTime = () => {
    const nextTime = moment(currentTime).add(1, view);
    setCurrentTime(nextTime.format(DateFormatEnum.YYYY_MM_DD));
  };

  const goToday = () => {
    setCurrentTime(moment().format(DateFormatEnum.YYYY_MM_DD));
  };

  const handleChangeCalendarView = () => {
    //TODO: HuyPahmGRF-ABD: Handle logic here
    onNavigate();
  };

  const handleCloseModalUnderDevelop = () => {
    setIsOpenModalUnderDevelop(!isOpenModalUnderDevelop);
  };

  const handleShowEvent = (event?: IScheduleEvent | IScheduleTimelineEvent | IOfficeSchedule) => {
    if (event) setDataEvent(event as IScheduleTimelineEvent);
    setIsShowEvent(!isShowEvent);
  };
  //#endregion Handle Function

  return (
    <div id='dailyScheduleComponent' className={cx('scheduleComponent')}>
      <div className={cx('bodyWrap')}>
        <div className={cx('toolbarWrapper')}>
          <div className={cx('toolbarLeft')}>
            <div className={cx('dateControl')}>
              <button className={cx('dateControlBtn')} onClick={goPreviousTime}>
                <CommonIconPrev strokePath={WHITE} />
              </button>
              <button className={cx('dateControlBtn')} onClick={goNextTime}>
                <CommonIconNext strokePath={WHITE} />
              </button>
            </div>
            <button className={cx('dateControlBtnToday')} onClick={goToday}>
              {t('common_text_today')}
            </button>
          </div>

          <div className={cx('label')}>{timeValue}</div>

          <div className={cx('toolbarRight')}>
            {view === Views.DAY && (
              <div className={cx('modeViewCalendar')}>
                <button className={cx('btnCalendarView')} onClick={() => handleChangeCalendarView()}>
                  {t('crm_client_detail_schedule_btn_view_week')}
                </button>
              </div>
            )}
          </div>
        </div>

        <div className={cx('scheduleWrap')}>
          <ViewSchedule
            view={view}
            pageView={pageView}
            date={currentTime}
            events={Array.isArray(timelineEvents) ? timelineEvents : []}
            onSelectEvent={(events) => handleShowEvent(events)}
          />
        </div>
      </div>

      {isOpenModalUnderDevelop && <ModalUnderDevelopment onClose={handleCloseModalUnderDevelop} />}

      <EventPopup isOpen={isShowEvent} onClose={handleShowEvent} data={dataEvent} />
    </div>
  );
};

export default NewScheduleComponent;
