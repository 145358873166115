import { THttpResponse } from '~/utils/type/common';

export const httpResponse: THttpResponse = {
  // Applicant
  16000: 'api_response_applicant_create_success',

  // Employee
  14000: 'api_response_employee_create_success',

  // Caregiver
  15000: 'api_response_caregiver_create_success',

  // Auth
  13005: 'api_response_auth_failed',

  // Task
  24007: 'api_response_task_get_detail_success',
  24000: 'api_response_task_create_success',
  24001: 'api_response_task_get_detail_fail',

  // Time Clock
  25000: 'api_response_time_clock_create_success',
  25007: 'api_response_time_clock_change_status_success',
  25011: 'api_response_time_clock_delete_success',
};

// Array number HttpResponse don't show toast
export const skipHttpResponseArray: number[] = [10000, 10013, 19000, 20000, 10005, 32002];

// Array number HttpResponse show toast for api GET
export const acceptHttpGetArray: number[] = [24001];

export const httpStatusCode = {
  SUCCESS: 200,
  CREATED: 201,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};
