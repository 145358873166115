// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { ISkill } from '~/mockData/mockInterface';
// Styles, images, icons
import styles from './SkillItem.module.scss';
import { icons } from '~/assets';

type Props = {
  data: ISkill;
  onDelete: (id: string) => void;
};

const cx = classNames.bind(styles);

const SkillItem = (props: Props) => {
  //#region Destructuring Props
  const { data, onDelete } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='skillItemComponent' className={cx('skillItemComponent')}>
      <p className={cx('skillName')}>{data.skillName}</p>
      <button className={cx('btnDelete')} onClick={() => onDelete(data.id)}>
        <img src={icons.commonIconModalClose} alt={t('common_img_text_alt')} />
      </button>
    </div>
  );
};

export default SkillItem;
