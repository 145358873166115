// Libs
import classNames from 'classnames/bind';
import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { ModalUnderDevelopment } from '~/components';
// Others
import { ISubmitMessage } from '~/utils/interface/message';
import { DEFAULT_HEIGHT_INPUT_MESSAGE } from '~/utils/constants/common';
import { HTMLEventEnum, MessageTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './ConversationInputMessage.module.scss';
import { icons } from '~/assets';

type Props = {
  onSubmit: (data: ISubmitMessage) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

const cx = classNames.bind(styles);

const ConversationInputMessage = (props: Props) => {
  //#region Destructuring Props
  const { onSubmit, onFocus, onBlur } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [inputValue, setInputValue] = useState<string>('');
  const [inputHeight, setInputHeight] = useState<number | null>(null);
  const [isOpenModalUnderDevelop, setIsOpenModalUnderDevelop] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleFocus = () => {
    onFocus && onFocus();
  };

  const handleBlur = () => {
    onBlur && onBlur();
  };

  const handleSubmit = () => {
    if (!inputValue.trim()) {
      return;
    }

    const data = {
      content: inputValue.trim(),
      type: MessageTypeEnum.TEXT,
    };

    onSubmit(data);
    setInputValue('');
    setInputHeight(null);
  };

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value) {
      setInputHeight(event.target.scrollHeight);
    } else {
      setInputHeight(null);
    }

    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.shiftKey && event.key === HTMLEventEnum.ENTER) {
      return;
    }

    if (event.key === HTMLEventEnum.ENTER) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleAdd = () => {
    handleCloseModalUnderDevelop();
    // TODO: Handle logic action add
  };

  const handleVoiceChat = () => {
    handleCloseModalUnderDevelop();
    // TODO: Handle logic action voice chat
  };

  const handleCloseModalUnderDevelop = () => {
    setIsOpenModalUnderDevelop(!isOpenModalUnderDevelop);
  };
  //#endregion Handle Function

  return (
    <div id='conversationInputMessage' className={cx('container')}>
      <div className={cx('inputMessageAction')}>
        <img
          src={icons.inputMessageIconAdd}
          alt={t('common_img_text_alt')}
          className={cx('inputMessageActionItem')}
          onClick={handleAdd}
        />

        <img
          src={icons.inputMessageIconVoiceChat}
          alt={t('common_img_text_alt')}
          className={cx('inputMessageActionItem')}
          onClick={handleVoiceChat}
        />
      </div>

      <textarea
        id='inputMessageInput'
        className={cx('inputMessageInput')}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleInputChange}
        //placeholder={t('common_conversation_input_message_placeholder')}
        value={inputValue}
        style={{ height: inputHeight ?? DEFAULT_HEIGHT_INPUT_MESSAGE }}
        onKeyDown={handleInputKeyDown}
      />

      <button className={cx('inputMessageSend')} onClick={handleSubmit}>
        <img src={icons.commonIconSendMessage} alt={t('common_img_text_alt')} className={cx('inputMessageSendIcon')} />
      </button>

      {isOpenModalUnderDevelop && <ModalUnderDevelopment onClose={handleCloseModalUnderDevelop} />}
    </div>
  );
};

export default ConversationInputMessage;
