// Libs
// Others
import { urlApiConversation } from '~/utils/constants/api';
import axiosClient from '~/apis/axiosClient';
import { BaseResponse, IListDataResponse, IListQueryParams } from '~/utils/interface/common';
import { IConversation, IDetailConversationRes, IPayloadGetDetailConversation } from '~/utils/interface/conversation';
import { ICreateConversationReq, ICreateConversationRes } from '~/utils/interface/conversation';
import { IBodyCreateMessage, ICreateMessageRes } from '~/utils/interface/message';

const conversationApi = {
  getList(params: IListQueryParams) {
    const url = `${urlApiConversation.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IConversation[]>>>(url, { params });
  },

  createConversation(payload: ICreateConversationReq) {
    const url = `${urlApiConversation.create}`;
    return axiosClient.post<BaseResponse<ICreateConversationRes>>(url, payload);
  },

  getDetailConversation(payload: IPayloadGetDetailConversation) {
    const url = `${urlApiConversation.getDetail(payload.conversationId)}`;
    return axiosClient.get<BaseResponse<IDetailConversationRes>>(url, { params: payload.query });
  },

  createMessage(payload: IBodyCreateMessage) {
    const { conversationId, ...restPayload } = payload;
    const url = `${urlApiConversation.createMessage(conversationId)}`;
    return axiosClient.post<BaseResponse<ICreateMessageRes>>(url, restPayload);
  },
};

export default conversationApi;
