// Libs
import classNames from 'classnames/bind';
import { Outlet } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
// Components, Layouts, Pages
import { NavBar } from '~/components';
// Others
import packageJson from '../../../package.json';
import { LoadingDataProvider, RingCentralContext } from '~/context';
import { StorageEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './Free.module.scss';

const cx = classNames.bind(styles);

const Free = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { setIsJoinPage } = useContext(RingCentralContext);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isLoggedIn] = useState<string | null>(localStorage.getItem(StorageEnum.ACCESS_TOKEN)! || null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (isLoggedIn) {
      setIsJoinPage && setIsJoinPage(true);
    }

    return () => {
      setIsJoinPage && setIsJoinPage(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='freeLayout' className={cx('container')}>
      <div className={cx('mainContent')}>
        <div className={cx('headerWrap')}>
          <NavBar />
        </div>

        <div className={cx('bodyWrap')}>
          <LoadingDataProvider>
            <Outlet />
          </LoadingDataProvider>
        </div>
      </div>
      <div className={cx('versionText')}>{packageJson.version}</div>
    </div>
  );
};

export default Free;
