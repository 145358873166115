// Libs
import classNames from 'classnames/bind';
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  FormEmployeesModal,
  BaseButton,
  BaseDrawerModal,
  BasePagination,
  BaseTable,
  ConfirmModal,
  EmployeeDetailModalItem,
  ImageCircle,
  InputSearch,
  ThreeDotTableOptions,
  ToolBar,
  ModalUnderDevelopment,
  BaseFilter,
} from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { getListEmployee } from '~/thunks/employee/employeeThunk';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_FILTER_EMPLOYEES,
  DEFAULT_LIMIT_PAGE,
  EMPTY_STRING,
} from '~/utils/constants/common';
import {
  ButtonTypeEnum,
  FieldTypeEmployeesEnum,
  FieldTypeEnum,
  FilterParamTaskEnum,
  ImageCircleTypeEnum,
  TimeFormatEnum,
  TranslationEnum,
} from '~/utils/enum';
import { convertDateToFormatTime, convertEnumToString, getRoleFromCode, getUserName } from '~/utils/helper';
import {
  ColumnTableType,
  IFilterValueChange,
  IListDataResponse,
  IListQueryParams,
  IPaginationResponse,
} from '~/utils/interface/common';
import { IDefaultFilterEmployees, IEmployee, IQueryListEmployees } from '~/utils/interface/employees';
import { LoadingData } from '~/context';
import useDebounce from '~/utils/customHook';
import { employeeActions, selectIsRefreshEmployeeList } from '~/thunks/employee/employeeSlice';
import { deleteUser } from '~/thunks/user/userThunk';
// Styles, images, icons
import styles from './ManageEmployee.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  handleEdit: (id: string) => void,
  handleViewDetail: (id: string) => void,
  handleDelete: (item: IEmployee) => void
): ColumnTableType<IEmployee>[] => {
  return [
    {
      key: 'employeeName',
      title: t('employee_table_title_name'),
      render: (_, record: IEmployee) => {
        return !record.firstName && !record.lastName ? (
          <>{EMPTY_STRING}</>
        ) : (
          <>
            <ImageCircle
              type={record?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
              imageUrl={record?.avatarUrl}
              firstName={record?.firstName}
              lastName={record?.lastName}
              width={24}
              height={24}
              fontSize={10}
              margin={'0 6px 0 0'}
            />
            <span>{getUserName(record?.firstName, record?.lastName)}</span>
          </>
        );
      },
    },
    {
      key: 'id',
      title: t('employee_table_title_id'),
      dataIndex: 'id',
      render(value, record, index) {
        return <>{record.id ? record.id : EMPTY_STRING}</>;
      },
    },

    {
      key: 'email',
      title: t('employee_table_title_email'),
      dataIndex: 'email',
      render(value, record, index) {
        return <>{record.email ? record.email : EMPTY_STRING}</>;
      },
      width: '25%',
    },
    {
      key: 'joinDate',
      title: t('employee_table_title_join_date'),
      dataIndex: 'joinDate',
      render(value, record, index) {
        return (
          <>{record.joinDate ? convertDateToFormatTime(record.joinDate, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>
        );
      },
      width: '20%',
    },
    {
      key: 'role',
      title: t('employee_table_title_role'),
      dataIndex: 'role',
      render: (value) => {
        return <span>{getRoleFromCode(value)}</span>;
      },
      width: '18%',
    },
    {
      key: 'action',
      title: t('time_clock_page_table_action_label'),
      render: (_, record) => {
        return (
          <ThreeDotTableOptions
            data={record}
            onView={() => handleViewDetail(record.id)}
            onEdit={() => handleEdit(record.id)}
            onDelete={() => handleDelete(record)}
          />
        );
      },
      width: 50,
    },
  ];
};

const ManageEmployee = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  //#endregion Declare Hook

  //#region Selector
  const isRefreshEmployeeList = useSelector(selectIsRefreshEmployeeList);
  //#endregion Selector

  //#region Declare State
  const [hasInteracted, setHasInteracted] = useState<boolean>(false);
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [employeeList, setEmployeeList] = useState<IEmployee[]>([]);
  const [isShowEmployeeModal, setIsShowEmployeeModal] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<IQueryListEmployees>(DEFAULT_FILTER_EMPLOYEES);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const renderSearchKey = useMemo(() => {
    if (!hasInteracted && textSearchParams) {
      return textSearchParams;
    }

    if (hasInteracted && searchKey === EMPTY_STRING) {
      return EMPTY_STRING;
    }

    if (searchKey) {
      return searchKey;
    }

    return EMPTY_STRING;
  }, [searchKey, textSearchParams, hasInteracted]);
  const debouncedSearchKey = useDebounce<string>(renderSearchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  const [accountId, setAccountId] = useState<string>(EMPTY_STRING);
  const [itemEmployeeData, setItemEmployeeData] = useState<IEmployee>();
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [paramObject, setParamObject] = useState<IListQueryParams>({
    page: pageSelected,
    limit: DEFAULT_LIMIT_PAGE,
  });
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);

  const fieldsText = useMemo(() => {
    return filterParams.fields
      ? filterParams.fields
          .map((field) =>
            field === FieldTypeEmployeesEnum.NAME ? t('employee_table_title_name') : convertEnumToString(field)
          )
          .join(', ')
      : EMPTY_STRING;
  }, [filterParams.fields, t]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const newParamObject: IListQueryParams = {
      ...paramObject,
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
    };

    handleGetListEmployee(newParamObject);
    const newParam = debouncedSearchKey
      ? {
          ...params,
          page: paramObject.page.toString(),
          limit: DEFAULT_LIMIT_PAGE.toString(),
          //textSearch: debouncedSearchKey,
        }
      : { page: paramObject.page.toString(), limit: DEFAULT_LIMIT_PAGE.toString() };

    setSearchParams(newParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramObject]);

  useEffect(() => {
    if (!pagination) return;
    setParamObject({
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
      ...(filterParams ? { ...filterParams } : {}),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshEmployeeList) {
      return;
    }

    handleGetListEmployee(paramObject);
    dispatch(employeeActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshEmployeeList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListEmployee = (params: IListQueryParams) => {
    loading?.show();

    dispatch(getListEmployee(params))
      .unwrap()
      .then((res) => {
        const { responses, pagination }: IListDataResponse<IEmployee[]> = res?.data;
        setEmployeeList(responses);
        setPagination(pagination);
        loading?.hide();
      })
      .catch((error) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
    setHasInteracted(true);
  };

  // Add Employee
  const handleAddEmployeesModal = () => {
    accountId && setAccountId(EMPTY_STRING);
    setIsShowEmployeeModal(true);
  };

  const handleCloseAddEmployeesModal = () => {
    setIsShowEmployeeModal(false);
  };

  // Open Drawer Detail
  const handleVisibleDrawer = () => {
    setIsOpenDrawer(!isOpenDrawer);
  };

  const handleViewDetails = (id: string) => {
    setAccountId(id);
    handleVisibleDrawer();
  };

  const handleEditInfo = () => {
    handleVisibleDrawer();
    setIsShowEmployeeModal(true);
  };

  const handleEdit = (id: string) => {
    setAccountId(id);
    setIsShowEmployeeModal(true);
  };

  // Handle Delete Employee
  const toggleDelete = (item: IEmployee) => {
    setItemEmployeeData(item);
    setIsShowConfirmDelete(true);
  };

  const handleCloseModalConfirmDelete = () => {
    setAccountId(EMPTY_STRING);
    setIsShowConfirmDelete(false);
  };

  const handleDeleteEmployee = () => {
    loading?.show();
    if (!itemEmployeeData?.id) return;
    dispatch(deleteUser(itemEmployeeData?.id))
      .unwrap()
      .then((res) => {
        handleCloseModalConfirmDelete();
        dispatch(employeeActions.setRefreshList(true));
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  // Handle Change Pagination
  const handlePaginationChange = (page: number) => {
    const newParamObject: IListQueryParams = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({ ...params, page: page.toString(), limit: `${DEFAULT_LIMIT_PAGE}` });
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleFilterChange = (appliedFilter: IDefaultFilterEmployees | undefined) => {
    if (!appliedFilter) {
      setFilterParams({});
      return;
    }

    setFilterParams({
      ...appliedFilter,
    });

    if (debouncedSearchKey)
      setParamObject({
        ...paramObject,
        ...appliedFilter,
      });
  };

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    onChange: (data: IFilterValueChange<IDefaultFilterEmployees>) => void,
    valueFilter: IDefaultFilterEmployees | undefined
  ) => {
    const { name, value, checked } = event.target;
    const newFields = {
      ...filterParams,
      fields: checked
        ? [...(valueFilter?.fields ?? []), value]
        : (valueFilter?.fields ?? []).filter((field) => field !== value),
    };

    if (name === FilterParamTaskEnum.FIELDS) {
      onChange({ name: FilterParamTaskEnum.FIELDS, value: newFields.fields });
    }
  };

  const handleTableRowClick = (data: IEmployee) => {
    if (!data || !data?.id) return;

    handleViewDetails(data?.id);
  };
  //#endregion Handle Function

  return (
    <div id='manageEmployeePage' className={cx('container')}>
      <div className={cx('headerToolBar')}>
        <ToolBar title={t('employee_title_page')}>
          {/* <BaseButton
            iconLeft={icons.commonIconSort}
            text={t('common_text_filter')}
            width={67}
            height={36}
            onClick={handleClickUnderDevelop}
          /> */}
          <InputSearch
            placeholder={t('employee_search_label', {
              params: fieldsText,
            })}
            height={36}
            onChange={handleChangeSearch}
            //value={textSearchParams}
          />

          <BaseFilter<IDefaultFilterEmployees>
            onApply={handleFilterChange}
            defaultValue={DEFAULT_FILTER_EMPLOYEES}
            valueFilter={filterParams}
          >
            {({ valueFilter, onChange }) => {
              return (
                <div className={cx('filterWrap')}>
                  <div className={cx('filterItemCheckbox')}>
                    <input
                      id='id'
                      type='checkbox'
                      name='fields'
                      onChange={(e) => {
                        handleCheckboxChange(e, onChange, valueFilter);
                      }}
                      value={FieldTypeEmployeesEnum.ID}
                      checked={valueFilter?.fields?.includes(FieldTypeEmployeesEnum.ID) || false}
                    />
                    <label htmlFor='id' className={cx('labelCheckbox')}>
                      {t('employee_btn_filter_id_label')}
                    </label>
                  </div>

                  <div className={cx('filterItemCheckbox')}>
                    <input
                      id='email'
                      type='checkbox'
                      name='fields'
                      onChange={(e) => {
                        handleCheckboxChange(e, onChange, valueFilter);
                      }}
                      value={FieldTypeEmployeesEnum.EMAIL}
                      checked={valueFilter?.fields?.includes(FieldTypeEmployeesEnum.EMAIL) || false}
                    />
                    <label htmlFor='email' className={cx('labelCheckbox')}>
                      {t('employee_btn_filter_email_label')}
                    </label>
                  </div>

                  <div className={cx('filterItemCheckbox')}>
                    <input
                      id='joinDate'
                      type='checkbox'
                      name='fields'
                      onChange={(e) => {
                        handleCheckboxChange(e, onChange, valueFilter);
                      }}
                      value={FieldTypeEmployeesEnum.JOIN_DATE}
                      checked={valueFilter?.fields?.includes(FieldTypeEmployeesEnum.JOIN_DATE) || false}
                    />
                    <label htmlFor='joinDate' className={cx('labelCheckbox')}>
                      {t('employee_btn_filter_join_date_label')}
                    </label>
                  </div>

                  <div className={cx('filterItemCheckbox')}>
                    <input
                      id='role'
                      type='checkbox'
                      name='fields'
                      onChange={(e) => {
                        handleCheckboxChange(e, onChange, valueFilter);
                      }}
                      value={FieldTypeEmployeesEnum.ROLE}
                      checked={valueFilter?.fields?.includes(FieldTypeEmployeesEnum.ROLE) || false}
                    />
                    <label htmlFor='role' className={cx('labelCheckbox')}>
                      {t('employee_btn_filter_role_label')}
                    </label>
                  </div>
                </div>
              );
            }}
          </BaseFilter>

          <BaseButton
            typeStyle={ButtonTypeEnum.PRIMARY}
            iconLeft={icons.commonIconPlus}
            text={t('employee_modal_add_employee_title')}
            onClick={handleAddEmployeesModal}
            width={132}
            height={36}
          />
        </ToolBar>
      </div>
      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable
            columns={columns(t, handleEdit, handleViewDetails, toggleDelete)}
            dataSource={employeeList ?? []}
            onClickRow={handleTableRowClick}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            defaultCurrentPage={pageSelected}
            totalItems={pagination?.totalItems}
            totalPages={pagination?.totalPages}
          />
        </div>
      </div>

      {isShowEmployeeModal && (
        <FormEmployeesModal
          isOpen={isShowEmployeeModal}
          employeeId={accountId}
          onClose={handleCloseAddEmployeesModal}
        />
      )}

      {isShowConfirmDelete && (
        <ConfirmModal
          title={t('common_confirm_delete_title', {
            name: getUserName(itemEmployeeData?.firstName, itemEmployeeData?.lastName),
          })}
          titleAction={t('common_delete_label')}
          onCancel={handleCloseModalConfirmDelete}
          onAction={handleDeleteEmployee}
        />
      )}

      <BaseDrawerModal isOpen={isOpenDrawer} onClose={handleVisibleDrawer}>
        <EmployeeDetailModalItem accountId={accountId} onEditInfo={handleEditInfo} />
      </BaseDrawerModal>

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default ManageEmployee;
