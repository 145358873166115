// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
// Components, Layouts, Pages
import { BaseSelectMultiple, BaseTextarea } from '~/components';
// Others
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
import { EMPTY_STRING } from '~/utils/constants/common';
import { mockOptionAllergies } from '~/mockData';
// Styles, images, icons
import styles from './CarePlanAllergies.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CarePlanAllergies = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='allergiesComponent' className={cx('container')}>
      <Controller
        name='allergies.allergies'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseSelectMultiple
            label={t('care_plan_allergies_title_label')}
            options={mockOptionAllergies}
            onChange={onChange}
            value={value || []}
            placeholder={t('common_placeholder_select')}
            errorMessage={errors.allergies?.allergies?.message}
          />
        )}
      />

      <Controller
        name='allergies.notes'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseTextarea
            name='notes'
            id='notes'
            label={t('care_plan_allergies_title_notes_label')}
            onChange={onChange}
            value={value || EMPTY_STRING}
            messageError={errors.allergies?.notes?.message}
            height={90}
          />
        )}
      />
    </div>
  );
};

export default CarePlanAllergies;
