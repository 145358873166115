// Libs
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import classNames from 'classnames/bind';
import React from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
// Styles, images, icons
import styles from './BaseButtonDropdown.module.scss';

type Props = {
  widthBtn?: string | number;
  heightBtn?: string | number;
  text: string;
  iconLeft?: string;
  iconRight?: string;
  children?: React.ReactNode;
  onClose?: () => void;
  onShowButtonModal: () => void;
};

const cx = classNames.bind(styles);

const BaseButtonDropdown = (props: Props) => {
  //#region Destructuring Props
  const { children, widthBtn = 180, heightBtn = 36, text, iconLeft, iconRight, onClose, onShowButtonModal } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleClosePopover = (closeFn: Function) => {
    onClose && onClose();
    closeFn();
  };
  //#endregion Handle Function

  return (
    <div id='baseButtonDropdownComponent'>
      <Popover className='relative'>
        <PopoverButton
          className={cx('btnWrap')}
          style={{ height: heightBtn, minWidth: widthBtn }}
          onClick={onShowButtonModal}
        >
          <div className={cx('btnGroup')}>
            <img src={iconLeft} alt={t('common_img_text_alt')} />
            <div className={cx('btnLabel')}>{text}</div>
          </div>
          <img src={iconRight} alt={t('common_img_text_alt')} />
        </PopoverButton>

        <PopoverPanel transition anchor={{ to: 'bottom end', gap: '4px' }} className={cx('popupContainer')}>
          {({ close }) => (
            <div onClick={() => handleClosePopover(close)} className={cx('popContent')}>
              {children}
            </div>
          )}
        </PopoverPanel>
      </Popover>
    </div>
  );
};

export default BaseButtonDropdown;
