// Libs
import { createSlice } from '@reduxjs/toolkit';
import { getTotalDashboard } from '~/thunks/dashboard/dashboardThunk';
// Others

export interface DashboardState {}

const initialState: DashboardState = {};

const dashboardSlice = createSlice({
  name: 'dashboardState',
  initialState,
  reducers: {},
  extraReducers(builder) {
    // Get total dashboard
    builder
      .addCase(getTotalDashboard.pending, (state) => {})
      .addCase(getTotalDashboard.fulfilled, (state) => {})
      .addCase(getTotalDashboard.rejected, (state) => {});
  },
});

// Actions
export const dashboardActions = dashboardSlice.actions;

// Selectors

// Reducer
const dashboardReducer = dashboardSlice.reducer;
export default dashboardReducer;
