import { createAsyncThunk } from '@reduxjs/toolkit';
import shiftScheduleApi from '~/apis/crm/shiftSchedule';
import scheduleApi from '~/apis/schedule';
import {
  CREATE_SHIFT_SCHEDULE,
  GET_LIST_SCHEDULE,
  DELETE_SCHEDULE,
  GET_LIST_SHIFT_SCHEDULE,
  GET_DETAIL_SCHEDULE,
  UPDATE_SCHEDULE,
  GET_SCHEDULE_CLIENT,
  GET_OFFICE_SCHEDULE,
} from '~/utils/constants/actionType';
import { IFormCreateSchedule, IQueryParamGetListSchedule } from '~/utils/interface/crm/shiftSchedule';
import { IParamGetOfficeSchedule, IParamsGetScheduleClient, IPayloadEditSchedule } from '~/utils/interface/schedule';

export const createShiftSchedule = createAsyncThunk(
  CREATE_SHIFT_SCHEDULE,
  async (payload: IFormCreateSchedule, { rejectWithValue }) => {
    try {
      const res = await shiftScheduleApi.createShiftSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListShiftSchedule = createAsyncThunk(
  GET_LIST_SHIFT_SCHEDULE,
  async (payload: IQueryParamGetListSchedule, { rejectWithValue }) => {
    try {
      const res = await shiftScheduleApi.getListShiftSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListSchedule = createAsyncThunk(
  GET_LIST_SCHEDULE,
  async (params: IQueryParamGetListSchedule, { rejectWithValue }) => {
    try {
      const res = await shiftScheduleApi.getListSchedule(params);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSchedule = createAsyncThunk(DELETE_SCHEDULE, async (scheduleId: string, { rejectWithValue }) => {
  try {
    const res = await scheduleApi.deleteSchedule(scheduleId);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDetailSchedule = createAsyncThunk(
  GET_DETAIL_SCHEDULE,
  async (scheduleId: string, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getDetailSchedule(scheduleId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSchedule = createAsyncThunk(
  UPDATE_SCHEDULE,
  async (payload: IPayloadEditSchedule, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.updateSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getScheduleClient = createAsyncThunk(
  GET_SCHEDULE_CLIENT,
  async (payload: IParamsGetScheduleClient, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getScheduleClient(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOfficeSchedule = createAsyncThunk(
  GET_OFFICE_SCHEDULE,
  async (payload: IParamGetOfficeSchedule, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getOfficeSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
