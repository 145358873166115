// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import { createReferrals, getDetailReferrals, getListReferrals, updateReferrals } from './referralThunk';

export interface ReferralState {
  isRefreshReferralsList: boolean;
}

const initialState: ReferralState = {
  isRefreshReferralsList: false,
};

const referralSlice = createSlice({
  name: 'referralState',
  initialState,
  reducers: {
    setRefreshReferralsList(state, action) {
      state.isRefreshReferralsList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get List Organization Referral
    builder
      .addCase(getListReferrals.pending, (state) => {})
      .addCase(getListReferrals.fulfilled, (state) => {})
      .addCase(getListReferrals.rejected, (state) => {});

    // Create Referrals
    builder
      .addCase(createReferrals.pending, (state) => {})
      .addCase(createReferrals.fulfilled, (state) => {})
      .addCase(createReferrals.rejected, (state) => {});

    // Update Organization Referral
    builder
      .addCase(updateReferrals.pending, (state) => {})
      .addCase(updateReferrals.fulfilled, (state) => {})
      .addCase(updateReferrals.rejected, (state) => {});

    // Get Detail Referrals
    builder
      .addCase(getDetailReferrals.pending, (state) => {})
      .addCase(getDetailReferrals.fulfilled, (state) => {})
      .addCase(getDetailReferrals.rejected, (state) => {});
  },
});

// Actions
export const referralActions = referralSlice.actions;

// Selectors
export const selectIsRefreshReferrals = (state: RootState) => state.referralState.isRefreshReferralsList;

// Reducers
const referralReducer = referralSlice.reducer;
export default referralReducer;
