// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

// Components, Layouts, Pages
import { BaseSelect } from '~/components';

// Others
import { optionPrimaryDiagnosisDayCenterClient } from '~/mockData';
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
import { EMPTY_STRING } from '~/utils/constants/common';

// Styles, images, icons
import styles from './PrimaryDiagnosisDayCenterClient.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const PrimaryDiagnosisDayCenterClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='primaryDiagnosisDayCenterClientComponent' className={cx('container')}>
      <Controller
        name='primaryDiagnosis.value'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseSelect
            options={optionPrimaryDiagnosisDayCenterClient}
            placeholder={t('common_placeholder_select')}
            value={value || EMPTY_STRING}
            onChange={({ value }) => {
              onChange(value);
            }}
            errorMessage={errors.primaryDiagnosis?.value?.message}
          />
        )}
      />
    </div>
  );
};

export default PrimaryDiagnosisDayCenterClient;
