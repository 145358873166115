// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
// Components, Layouts, Pages
import { BaseSelect, MultipleCheckbox, BaseInput, BaseTextarea, CheckboxSingle, SelectTime } from '~/components';
// Others
import { appetiteMealOptions, assistanceMealOptions, dietMealOption, othersMealOptions } from '~/mockData';
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
import { EMPTY_STRING, TIME_MEAL_CARE_PLAN } from '~/utils/constants/common';
import { convertToTitleCase } from '~/utils/helper';
// Styles, images, icons
import styles from './CarePlanMeals.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CarePlanMeals = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='carePlanMealsComponent' className={cx('container')}>
      <div className={cx('mealsItem')}>
        <label className={cx('label')}>{t('care_plan_meal_assistance_label')}</label>
        <div className={cx('mealsControl')}>
          <Controller
            name='meal.assistance'
            control={control}
            render={({ field: { value, onChange } }) => (
              <MultipleCheckbox
                id='assistance'
                options={assistanceMealOptions}
                type='flex-col'
                value={value || []}
                onChange={onChange}
                messageError={errors.meal?.assistance?.message}
              />
            )}
          />
        </div>
      </div>

      <div className={cx('twoCol')}>
        <Controller
          name='meal.diet'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              placeholder={t('common_placeholder_select')}
              options={dietMealOption}
              label={t('care_plan_meal_diet_label')}
              value={value?.[0] || EMPTY_STRING}
              onChange={({ value }) => onChange([value])}
              errorMessage={errors.meal?.diet?.message}
            />
          )}
        />

        <Controller
          name='meal.appetite'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              placeholder={t('common_placeholder_select')}
              options={appetiteMealOptions}
              label={t('care_plan_meal_appetite_label')}
              value={value || EMPTY_STRING}
              onChange={({ value }) => onChange(value)}
              errorMessage={errors.meal?.appetite?.message}
            />
          )}
        />
      </div>

      <div className={cx('twoCol')}>
        <Controller
          name='meal.specialDiet'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseInput
              id='specialDiet'
              label={t('care_plan_meal_special_diet_label')}
              value={value || EMPTY_STRING}
              onChange={onChange}
              messageError={errors.meal?.specialDiet?.message}
            />
          )}
        />

        <Controller
          name='meal.shoppingBy'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseInput
              id='shoppingBy'
              label={t('care_plan_meal_shopping_by_label')}
              value={value || EMPTY_STRING}
              onChange={onChange}
              messageError={errors.meal?.shoppingBy?.message}
            />
          )}
        />
      </div>

      <Controller
        name='meal.times'
        control={control}
        render={({ field: { value, onChange } }) => (
          <div className={cx('timeWrap')}>
            <label className={cx('label')}>{t('care_plan_meal_times_label')}</label>

            <div className={cx('timeGroup')}>
              {TIME_MEAL_CARE_PLAN.map((mealTime) => {
                const mealEntry = value?.find((entry) => entry.meal === mealTime.value);
                const isChecked = !!mealEntry;

                return (
                  <div key={mealTime.value} className={cx('timeControl')}>
                    <CheckboxSingle
                      fontSize={12}
                      label={t(`${mealTime.label}`)}
                      value={isChecked}
                      onChange={(timeMeal) => {
                        const newValue = timeMeal
                          ? [...(value ?? []), { meal: mealTime.value }]
                          : (value ?? []).filter((entry) => entry.meal !== mealTime.value);
                        onChange(newValue);
                      }}
                    />

                    <SelectTime
                      placeholder={t('common_placeholder_select')}
                      disabled={!isChecked}
                      value={mealEntry?.time || EMPTY_STRING}
                      onChange={(timeValue: string) => {
                        const newValue = value?.map((entry) =>
                          entry.meal === mealTime.value ? { ...entry, time: timeValue } : entry
                        );
                        onChange(newValue);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      />

      <div className={cx('mealsItem')}>
        <label className={cx('label')}>{t('care_plan_meal_others_label')}</label>
        <div className={cx('mealsControl')}>
          <Controller
            name='meal.other'
            control={control}
            render={({ field: { value, onChange } }) => (
              <MultipleCheckbox
                id='others'
                options={othersMealOptions}
                type='flex-col'
                value={value || []}
                onChange={onChange}
                messageError={errors.meal?.other?.message}
              />
            )}
          />
        </div>
      </div>

      <div className={cx('favoriteFoodWrap')}>
        <label className={cx('label')}>{t('care_plan_meal_favorite_food_label')}</label>

        <div className={cx('favoriteFood')}>
          <Controller
            name='meal.favoriteBreakfastFoods'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='favoriteBreakfastFoods'
                label={t('care_plan_meal_breakfast_label')}
                value={value || EMPTY_STRING}
                onChange={onChange}
                messageError={errors.meal?.favoriteBreakfastFoods?.message}
              />
            )}
          />

          <Controller
            name='meal.favoriteLunchFoods'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='favoriteLunchFoods'
                label={t('care_plan_meal_lunch_label')}
                value={value || EMPTY_STRING}
                onChange={onChange}
                messageError={errors.meal?.favoriteLunchFoods?.message}
              />
            )}
          />

          <Controller
            name='meal.favoriteSnackFoods'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='favoriteSnackFoods'
                label={t('care_plan_meal_snack_label')}
                value={value || EMPTY_STRING}
                onChange={onChange}
                messageError={errors.meal?.favoriteSnackFoods?.message}
              />
            )}
          />

          <Controller
            name='meal.favoriteDinnerFoods'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='favoriteDinnerFoods'
                label={t('care_plan_meal_dinner_label')}
                value={value || EMPTY_STRING}
                onChange={onChange}
                messageError={errors.meal?.favoriteDinnerFoods?.message}
              />
            )}
          />
        </div>
      </div>

      <Controller
        name='meal.notes'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseTextarea
            id='notes'
            label={t('care_plan_meal_notes_label')}
            value={value || EMPTY_STRING}
            onChange={onChange}
            messageError={errors.meal?.notes?.message}
            height={90}
          />
        )}
      />
    </div>
  );
};

export default CarePlanMeals;
