// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BasePagination,
  BaseTable,
  ImageCircle,
  ModalUnderDevelopment,
  StatusLabel,
  ThreeDotTableOptions,
  ToolBar,
} from '~/components';
// Others
import { ColumnTableType, IListQueryParams, IPaginationResponse } from '~/utils/interface/common';
import { CurrencyEnum, ImageCircleTypeEnum, TranslationEnum } from '~/utils/enum';
import { DEFAULT_CURRENT_PAGE, DEFAULT_LIMIT_PAGE, EMPTY_STRING } from '~/utils/constants/common';
import { formatCurrency, getUserName } from '~/utils/helper';
import { IInvoice } from '~/mockData/mockInterface';
import { mockDataInvoice } from '~/mockData';
// Styles, images, icons
import styles from './AccountingInvoice.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columnsInvoice = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  onViewQuickBooks: (record: IInvoice) => void
): ColumnTableType<IInvoice>[] => {
  return [
    {
      key: 'id',
      title: t('invoice_table_invoice_id_label'),
      dataIndex: 'id',
      render(value, record, index) {
        return <>{record.id ? value : EMPTY_STRING}</>;
      },
    },
    {
      key: 'client',
      title: t('invoice_table_client_label'),
      dataIndex: 'client',
      render: (_, record) => {
        return !record.firstName && !record.lastName ? (
          <>{EMPTY_STRING}</>
        ) : (
          <>
            <ImageCircle
              type={record?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
              imageUrl={record?.avatarUrl}
              firstName={record?.firstName}
              lastName={record?.lastName}
              width={24}
              height={24}
              fontSize={10}
              margin={'0 6px 0 0'}
            />
            <span>{getUserName(record?.firstName, record?.lastName, record?.middleName)}</span>
          </>
        );
      },
    },
    {
      key: 'email',
      title: t('invoice_table_email_label'),
      dataIndex: 'email',
      render(value, record, index) {
        return <>{record.email ? record.email : EMPTY_STRING}</>;
      },
    },
    {
      key: 'amount',
      title: t('invoice_table_amount_label'),
      render(value, record, index) {
        return <>{record.amount ? formatCurrency(CurrencyEnum.USD, record.amount) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'status',
      title: t('invoice_table_status_label'),
      render(value, record, index) {
        return (
          <div className={cx('buttonStatus')}>
            {record.status ? <StatusLabel borderRadius={100} label={record.status} bgOpacity={0.1} /> : EMPTY_STRING}
          </div>
        );
      },
    },
    {
      key: 'action',
      title: t('invoice_table_actions_label'),
      render(value, record, index) {
        return (
          <ThreeDotTableOptions
            data={record}
            permissions={{
              isView: false,
              isViewOnQuickBooks: true,
              isCall: false,
              isSendMessage: false,
              isSendEmail: false,
              isEdit: false,
              isDelete: false,
              isDeactivate: false,
            }}
            onViewOnQuickBooks={() => onViewQuickBooks(record)}
          />
        );
      },
      width: 50,
    },
  ];
};

const AccountingInvoice = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [dataInvoice, setDataInvoice] = useState<IInvoice[]>(mockDataInvoice);
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const [paramObject, setParamObject] = useState<IListQueryParams>({
    page: pageSelected,
    limit: DEFAULT_LIMIT_PAGE,
  });
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleShowUnderDevelopment = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const onViewQuickBooks = (record: IInvoice) => {
    handleShowUnderDevelopment();
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IListQueryParams = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({ ...params, page: page.toString(), limit: `${DEFAULT_LIMIT_PAGE}` });
  };

  const handleInvoiceRowClick = (data: IInvoice) => {
    handleShowUnderDevelopment();
  };
  //#endregion Handle Function

  return (
    <div id='accountingInvoice' className={cx('container')}>
      <ToolBar title={t('menu_invoice')}></ToolBar>
      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable
            columns={columnsInvoice(t, onViewQuickBooks)}
            dataSource={dataInvoice}
            onClickRow={handleInvoiceRowClick}
          />
        </div>
        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            defaultCurrentPage={pageSelected || DEFAULT_CURRENT_PAGE}
            totalItems={pagination?.totalItems || DEFAULT_CURRENT_PAGE}
            totalPages={pagination?.totalPages || DEFAULT_CURRENT_PAGE}
          />
        </div>
      </div>
      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleShowUnderDevelopment} />}
    </div>
  );
};

export default AccountingInvoice;
