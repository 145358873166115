// Libs
import classNames from 'classnames/bind';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseButton, CarePlanQuickLink } from '~/components';
import { carePlanDefaultValues, carePlanSchema, quickLinkItems } from '~/components/specific/carePlanTab/helper';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { createCarePlan } from '~/thunks/crm/clients/clientsThunk';
import { IBodyCarePlan, IFormCarePlan, IQuickLinkItem } from '~/utils/interface/crm/carePlan';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum } from '~/utils/enum';
import { LoadingData } from '~/context';
import { getDetailCarePlan } from '~/thunks/crm/clients/clientsThunk';
// Styles, images, icons
import styles from './CarePlanTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CarePlanTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { clientId } = useParams();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const quickLinkContentRef = useRef<HTMLDivElement | null>(null);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    trigger,
    formState: { errors },
  } = useForm<IFormCarePlan>({
    resolver: yupResolver(carePlanSchema(t)),
    defaultValues: carePlanDefaultValues,
  });

  const watchQuickLinkValues = watch('quickLink');
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [carePlanItems, setCarePlanItems] = useState<IQuickLinkItem[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!clientId) return;

    handleGetDetailCarePlan(clientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  useEffect(() => {
    if (!watchQuickLinkValues) return;

    const selectedItems = quickLinkItems?.filter((item) => watchQuickLinkValues[item?.key] === true);
    setCarePlanItems((prevItems) => {
      const newItems = selectedItems.filter((newItem) => !prevItems.some((prevItem) => prevItem.key === newItem.key));

      // if (newItems?.length > DEFAULT_NUMBER_ZERO && quickLinkContentRef.current) {
      //   quickLinkContentRef.current.scrollTop = DEFAULT_NUMBER_ZERO;
      // }

      return [...prevItems.filter((prevItem) => watchQuickLinkValues[prevItem.key] === true), ...newItems];
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watchQuickLinkValues)]);

  useEffect(() => {
    if (!watchQuickLinkValues) return;

    const unselectItems = quickLinkItems?.filter((item) => watchQuickLinkValues[item?.key] === false);
    unselectItems?.forEach((item) => {
      const key = item?.defaultValue;

      if (key && key !== 'quickLink') {
        reset((prevValues) => ({ ...prevValues, [key]: carePlanDefaultValues[key] }));
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carePlanItems]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetailCarePlan = (clientId: string) => {
    loading?.show();

    dispatch(getDetailCarePlan(clientId))
      .unwrap()
      .then((res) => {
        const { data } = res;
        if (!data) return;

        const { id, quickLinks, ...rest } = data;

        const formatData: IFormCarePlan = {
          ...rest,
          quickLink: quickLinks.reduce((acc, item) => {
            acc[item] = true;
            return acc;
          }, {} as Record<string, boolean>),
        };

        reset(formatData);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleSubmitCarePlan = (data: IFormCarePlan) => {
    if (!data) return;

    const convertedQuickLinks = carePlanItems?.filter((item) => item?.defaultValue)?.map((item) => item?.key);
    const { quickLink, ...rest } = data;

    if (
      !carePlanItems ||
      carePlanItems?.length === DEFAULT_NUMBER_ZERO ||
      convertedQuickLinks?.length === DEFAULT_NUMBER_ZERO
    ) {
      handleCreateCarePlan({ quickLinks: [] });
      return;
    }

    const payload: IBodyCarePlan = {
      quickLinks: convertedQuickLinks,
      ...rest,
    };

    handleCreateCarePlan(payload);
  };

  const handleCreateCarePlan = (body: IBodyCarePlan) => {
    if (!clientId) return;

    loading?.show();
    dispatch(createCarePlan({ clientId, body }))
      .unwrap()
      .then((res) => {
        return handleGetDetailCarePlan(clientId);
      })
      .catch((error) => {})
      .finally(() => {
        loading?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='carePlanTabComponent' className={cx('container')}>
      <FormProvider
        {...({
          control,
          watch,
          setValue,
          trigger,
          reset,
          formState: { errors },
        } as UseFormReturn<IFormCarePlan>)}
      >
        <form className={cx('form')} onSubmit={handleSubmit(handleSubmitCarePlan)}>
          <div className={cx('quickLinkGroup')}>
            <div className={cx('quickLink')}>
              <CarePlanQuickLink />
            </div>

            <div className={cx('carePlanActions')}>
              <BaseButton type='submit' text={t('common_submit_label')} typeStyle={ButtonTypeEnum.PRIMARY} width={80} />
            </div>
          </div>

          <div className={cx('quickLinkContent')} ref={quickLinkContentRef}>
            {carePlanItems?.map((item) => {
              const Component = item?.component;

              return (
                <div className={cx('carePlanItem')} key={item?.key}>
                  <h3 className={cx('carePlanItemTitle', { noBody: !Component })}>{t(item?.title) || EMPTY_STRING}</h3>

                  {Component && (
                    <div className={cx('carePlanItemBody')}>
                      <Component />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CarePlanTab;
