// Libs
import classNames from 'classnames/bind';
import { CustomHeader, Interval } from 'react-calendar-timeline';
import { Views } from 'react-big-calendar';
// Components, Layouts, Pages
// Others
import { TCalendarViewMode } from '~/utils/type/schedule';
// Styles, images, icons
import styles from './HeaderTimeline.module.scss';

const cx = classNames.bind(styles);

type Props = {
  height?: number;
  view: TCalendarViewMode;
};

const HeaderTimeline = (props: Props) => {
  const { height = 50, view } = props;

  return (
    <CustomHeader height={height}>
      {(props) => {
        if (!props) return null;
        const {
          headerContext: { intervals },
          getRootProps,
          getIntervalProps,
        } = props;

        if (!intervals || intervals.length === 0) {
          console.log('No intervals available');
          return <div>No intervals to display</div>;
        }

        return (
          <div {...getRootProps()}>
            {intervals.map((interval: Interval, index: number) => (
              <div {...getIntervalProps({ interval, style: { height } })} className={cx('dateBlock')} key={index}>
                {view === Views.WEEK && (
                  <>
                    <div className={cx('dateText')}>{interval.startTime.format('DD')}</div>
                    <div className={cx('dateText', 'dateOfWeek')}>{interval.startTime.format('dddd')}</div>
                  </>
                )}
                {view === Views.DAY && <div className={cx('dateText')}>{interval.startTime.format('h A')}</div>}
              </div>
            ))}
          </div>
        );
      }}
    </CustomHeader>
  );
};

export default HeaderTimeline;
