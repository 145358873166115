// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Checkbox, Field, Label } from '@headlessui/react';
import { useEffect, useState } from 'react';
// Components, Layouts, Pages
// Others
import { EMPTY_STRING } from '~/utils/constants/common';
import { CommonIconCheck } from '~/assets/svgComponents';
import { WHITE } from '~/utils/constants/color';
// Styles, images, icons
import styles from './Checkbox.module.scss';

type Props = {
  label?: string;
  width?: string | number;
  height?: string | number;
  borderRadius?: string | number;
  name?: string;
  fontSize?: number;
  value?: boolean;
  messageError?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean, name?: string) => void;
};

const cx = classNames.bind(styles);

const CheckboxSingle = (props: Props) => {
  //#region Destructuring Props
  const { name, value, width, height, label, messageError, borderRadius, required, disabled, onChange, fontSize } =
    props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isChecked, setIsChecked] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    setIsChecked(value || false);
  }, [value]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleCheckbox = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    onChange && onChange(newCheckedState, name);
  };
  //#endregion Handle Function

  return (
    <Field id='checkboxSingleComponent' disabled={disabled} className={cx('container')} style={{ width }}>
      <div className={cx('checkboxSingleWrap')} style={{ width, height }}>
        <Checkbox
          name={name}
          value={value}
          disabled={disabled}
          checked={isChecked}
          onChange={handleCheckbox}
          className={cx('checkbox')}
        >
          {({ checked, disabled }) => (
            <div
              style={{ width, height, borderRadius }}
              className={cx('checkboxSingle', checked && 'checked', disabled && 'disabled')}
            >
              <CommonIconCheck width={14} height={14} strokePath={checked ? WHITE : EMPTY_STRING} />
            </div>
          )}
        </Checkbox>
        {label && (
          <Label style={{ fontSize }} className={cx('label')}>
            {label}
          </Label>
        )}
      </div>

      {messageError && <p className={cx('messageError')}>{messageError}</p>}
    </Field>
  );
};

export default CheckboxSingle;
