// Libs
import classNames from 'classnames/bind';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { DEFAULT_UPLOAD_FILE_LIST } from '~/utils/constants/component';
import { generateUniqueId } from '~/utils/helper';
import { IUploadFile } from '~/utils/interface/common';
import { ALLOWED_FILE_TYPE, EMPTY_STRING, fileTypeTaskIcons, MAX_FILE_SIZE_BYTES } from '~/utils/constants/common';
// Styles, images, icons
import styles from './UploadFile.module.scss';
import { icons } from '~/assets';

type Props = {
  height?: number | string;
  width?: number | string;
  label?: string;
  textBtn?: string;
  maxUpload?: number;
  errorMessage?: string;
  defaultFileList?: IUploadFile[];
  multiple?: boolean;
  onChange?: (fileList: IUploadFile[]) => void;
};

const cx = classNames.bind(styles);

const UploadFile = (props: Props) => {
  //#region Destructuring Props
  const {
    width,
    height,
    maxUpload,
    defaultFileList = DEFAULT_UPLOAD_FILE_LIST,
    label,
    textBtn,
    errorMessage,
    multiple = false,
    onChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [fileList, setFileList] = useState<IUploadFile[]>([]);
  const [uploadError, setUploadError] = useState<string | null>(null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!defaultFileList?.length) return;
    setFileList(defaultFileList);
  }, [defaultFileList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleUploadChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files) return;

    const newFiles: IUploadFile[] = [];
    const errors: string[] = [];

    Array.from(files).map((file) => {
      if (file.size > MAX_FILE_SIZE_BYTES) {
        errors.push(
          t('error_upload_file_max_size', {
            fileName: file.name,
          })
        );
      } else {
        newFiles.push({
          id: generateUniqueId(),
          file: file,
          name: file.name,
        });
      }
    });

    if (errors.length > 0) {
      setUploadError(errors.join(' '));
    } else {
      setUploadError(null);
      const updatedFileList = [...fileList, ...newFiles];
      setFileList(updatedFileList);
      event.target.value = ''; // Reset DOM when user selects the same file in react
      onChange && onChange(updatedFileList);
    }
  };

  const handleRemoveImage = (indexRemove: number) => {
    const newImageList: IUploadFile[] = fileList.filter((_, index) => index !== indexRemove);

    onChange && onChange(newImageList);
    setFileList(newImageList);
    setUploadError(null);
  };

  const handleDownloadFile = () => {
    // TODO: Handle Logic Later
  };

  const checkFileType = (fileType: IUploadFile) => {
    if (!fileType || !fileType.name) {
      return EMPTY_STRING;
    }

    const fileExtension = fileType.name.split('.').pop()?.toLowerCase();
    if (!fileExtension) {
      return EMPTY_STRING;
    }

    return fileTypeTaskIcons[fileExtension] || fileTypeTaskIcons.default;
  };

  const renderTypeFileName = (fileName?: string) => {
    const fileExtension = fileName?.split('.').pop()?.toUpperCase();
    return fileExtension || EMPTY_STRING;
  };
  //#endregion Handle Function

  return (
    <div id='uploadFileComponent' className={cx('container')} style={{ width, height }}>
      {label && <div className={cx('label')}>{label}</div>}

      <div className={cx('uploadFileContainer')}>
        <div className={cx('uploadFile')}>
          {fileList.map((file: IUploadFile, index: number) => (
            <div key={index} className={cx('filePreviewWrap')} style={{ width }}>
              <div className={cx('files')}>
                <img src={checkFileType(file)} alt={t('common_img_text_alt')} className={cx('imgFile')} />

                <div className={cx('file')}>
                  <p className={cx('fileName')}>{file.name}</p>
                  <p className={cx('typeFile')}>
                    <span>{renderTypeFileName(file.name)}</span>
                    {defaultFileList.length > 0 && (
                      <span onClick={handleDownloadFile}> - {t('common_text_download')}</span>
                    )}
                  </p>
                </div>
              </div>

              <div className={cx('iconClose')} onClick={() => handleRemoveImage(index)}>
                <img src={icons.commonIconModalClose} alt={t('common_img_text_alt')} className={cx('iconCloseImg')} />
              </div>
            </div>
          ))}
        </div>

        <label htmlFor='uploadFile' className={cx('btnUpload')} style={{ height: height }}>
          <img src={icons.commonIconAdd} alt={t('common_img_text_alt')} className={cx('uploadIcon')} />

          {textBtn && (
            <p>
              {t('common_component_upload_file_btn')}
              <span className={cx('browseBtn')}>{t('common_component_upload_browse')}</span>
            </p>
          )}

          <input
            id='uploadFile'
            type='file'
            accept={ALLOWED_FILE_TYPE.join(', ')}
            multiple={multiple}
            className={cx('inputUpload')}
            onChange={handleUploadChange}
            max={maxUpload}
          />
        </label>
      </div>

      {errorMessage && <div className={cx('errMessage')}>{errorMessage}</div>}
      {uploadError && <div className={cx('errMessage')}>{uploadError}</div>}
    </div>
  );
};

export default UploadFile;
