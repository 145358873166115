// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
// Components, Layouts, Pages
// Others
// Styles, images, icons
import styles from './ToolBar.module.scss';

type Props = {
  title: string | ReactNode;
  children?: ReactNode;
  fontSize?: number;
};

const cx = classNames.bind(styles);

const ToolBar = (props: Props) => {
  //#region Destructuring Props
  const { title, children, fontSize } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='toolBarComponent' className='toolBar'>
      <div className={cx('toolbarWrap')}>
        <div style={{ fontSize }} className={cx('titleToolBar')}>
          {title}
        </div>
        <div className={cx('childrenToolBar')}>{children}</div>
      </div>
    </div>
  );
};

export default ToolBar;
