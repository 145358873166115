// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { TFunction } from 'i18next';
// Components, Layouts, Pages
import { BaseButton, BaseInput } from '~/components';
// Others
import { IFormResetPassword, INewPasswordReq, IVerifyCode } from '~/utils/interface/auth';
import { EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum, InputTypeEnum, NewPasswordNameEnum } from '~/utils/enum';
import { authRouteAbsolute } from '~/utils/constants/route';
import { useAppDispatch } from '~/redux/hooks';
import { createNewPassword } from '~/thunks/auth/authThunk';
import { LoadingContext } from '~/context';
// Styles, images, icons
import styles from './NewPassword.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      newPassword: yup
        .string()
        .trim()
        .required(t('change_password_new_password_required'))
        .min(8, t('change_password_password_min'))
        .max(100, t('change_password_password_max'))
        .notOneOf([yup.ref('currentPassword')], t('change_password_different_current_password')),
      confirmNewPassword: yup
        .string()
        .trim()
        .required(t('change_password_confirm_new_password_required'))
        .oneOf([yup.ref('newPassword')], t('new_password_confirm_new_password_not_match')),
    })
    .required();
};

const defaultValues: IFormResetPassword = {
  newPassword: EMPTY_STRING,
  confirmNewPassword: EMPTY_STRING,
};

const NewPassword = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingContext);
  const { state }: Location<IVerifyCode> = useLocation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormResetPassword>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!state?.code) navigate(`${authRouteAbsolute.forgotPassword}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.code]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleResetPassword = (data: IFormResetPassword) => {
    if (!data) return;

    const payload: INewPasswordReq = {
      newPassword: data.newPassword,
      code: state?.code,
    };

    loading?.show();
    dispatch(createNewPassword(payload))
      .unwrap()
      .then((res) => {
        if (!res) return;

        navigate(`${authRouteAbsolute.changePasswordSuccess}`);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <form id='newPasswordComponent' className={cx('container')} onSubmit={handleSubmit(handleResetPassword)}>
      <h3 className={cx('title')}>{t('new_password_title')}</h3>

      <p className={cx('desc')}>{t('new_password_desc')}</p>

      <div className={cx('formResetPassword')}>
        <Controller
          name='newPassword'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseInput
              label={t('new_password_input_new_password_label')}
              id={NewPasswordNameEnum.NEW_PASSWORD}
              type={InputTypeEnum.PASSWORD}
              value={value}
              onChange={onChange}
              required
              //placeholder={t('new_password_input_new_password_placeholder')}
              height={36}
              messageError={errors.newPassword?.message}
            />
          )}
        />

        <Controller
          name='confirmNewPassword'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseInput
              label={t('new_password_input_confirm_new_password_label')}
              id={NewPasswordNameEnum.CONFIRM_NEW_PASSWORD}
              type={InputTypeEnum.PASSWORD}
              onChange={onChange}
              value={value}
              required
              //placeholder={t('new_password_input_confirm_new_password_placeholder')}
              height={36}
              messageError={errors.confirmNewPassword?.message}
            />
          )}
        />
      </div>

      <BaseButton
        text={t('new_password_button_reset_password')}
        width={'100%'}
        type='submit'
        typeStyle={ButtonTypeEnum.PRIMARY}
      />
    </form>
  );
};

export default NewPassword;
