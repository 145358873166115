// Libs
import classNames from 'classnames/bind';
import moment from 'moment';
import { memo, useMemo } from 'react';
import { Event, EventProps, View, Views } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
// Components, Layouts, Pages
import { BaseSchedule, ReactPortal } from '~/components';
import { ScheduleAssignee } from '~/components';
// Others
import { BaseCalendarProps } from '~/utils/type/schedule';
import { DEFAULT_SCHEDULE_EVENT_COLOR } from '~/utils/constants/component';
import { IScheduleEvent } from '~/utils/interface/schedule';
import { prepareMonthEventsData } from '../helper';
import { DEFAULT_NUMBER_ONE, DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { generateGuid } from '~/utils/helpers/common';
// Styles, images, icons
import styles from './MySchedule.module.scss';
import './MySchedule.scss';

type Props = Omit<BaseCalendarProps, 'view' | 'date' | 'onSelectEvent' | 'events'> & {
  view: View;
  date: string;
  events: IScheduleEvent[];
  onSelectEvent: (event: IScheduleEvent) => void;
};

const cx = classNames.bind(styles);

const CollapseMonthView = memo((props: { event: IScheduleEvent }) => {
  const { event } = props;
  const eventColor = event.color || DEFAULT_SCHEDULE_EVENT_COLOR;
  const id = generateGuid();
  return (
    <div
      className={cx('eventContainer', 'eventContainerCollapse')}
      style={{ borderWidth: 1, backgroundColor: eventColor }}
      data-tooltip-id={`taskName-${id}`}
      data-tooltip-place='top'
    >
      <div className={cx('eventName')}>{event.tasks?.[0]?.name || EMPTY_STRING}</div>
      <ReactPortal wrapperId='taskTooltip'>
        <Tooltip id={`taskName-${id}`} className={cx('tooltipWrap')}>
          <div className={cx('tooltip')}>
            {event.tasks?.length > DEFAULT_NUMBER_ZERO
              ? event.tasks?.map((task, index) => (
                  <span className={cx('tooltipText')} key={index}>
                    {task?.name || EMPTY_STRING}
                  </span>
                ))
              : EMPTY_STRING}
          </div>
        </Tooltip>
      </ReactPortal>
    </div>
  );
});

const ExpandMonthView = memo((props: { event: IScheduleEvent }) => {
  const { event } = props;
  const eventColor = event.color || DEFAULT_SCHEDULE_EVENT_COLOR;
  const id = generateGuid();
  return (
    <div className={cx('eventContainer', 'eventContainerExpand')} style={{ backgroundColor: eventColor }}>
      <div className={cx('eventName')} data-tooltip-id={`taskName-${id}`} data-tooltip-place='top'>
        {event.tasks?.[0]?.name || EMPTY_STRING}
      </div>
      <div className={cx('eventTime')}>
        {moment(event.startTime).format('hh:mm a')} - {moment(event.endTime).format('hh:mm a')}
      </div>
      <div className={cx('assigneeContainer')}>
        {event.assignees && <ScheduleAssignee assignees={event.assignees} eventColor={eventColor} />}
      </div>

      <ReactPortal wrapperId='taskTooltip'>
        <Tooltip id={`taskName-${id}`} className={cx('tooltipWrap')}>
          <div className={cx('tooltip')}>
            {event.tasks?.length > DEFAULT_NUMBER_ZERO
              ? event.tasks?.map((task, index) => (
                  <span className={cx('tooltipText')} key={index}>
                    {task?.name || EMPTY_STRING}
                  </span>
                ))
              : EMPTY_STRING}
          </div>
        </Tooltip>
      </ReactPortal>
    </div>
  );
});

const ExpandWeekView = memo((props: { event: IScheduleEvent }) => {
  const { event } = props;
  const eventColor: string = event.color || DEFAULT_SCHEDULE_EVENT_COLOR;
  const id = generateGuid();
  return (
    <div
      className={cx('expandWeekViewContainer')}
      style={{
        backgroundColor: eventColor,
      }}
    >
      <div className={cx('eventName')} data-tooltip-id={`taskName-${id}`} data-tooltip-place='top'>
        {event.tasks?.[0]?.name || EMPTY_STRING}
      </div>
      <div className={cx('eventTime')}>
        {moment(event.startTime).format('hh:mm a')} - {moment(event.endTime).format('hh:mm a')}
      </div>
      <div className={cx('assigneeContainer')}>
        {event.assignees && <ScheduleAssignee assignees={event.assignees} eventColor={eventColor} />}
      </div>
      <ReactPortal wrapperId='taskTooltip'>
        <Tooltip id={`taskName-${id}`} className={cx('tooltipWrap')}>
          <div className={cx('tooltip')}>
            {event.tasks?.length > DEFAULT_NUMBER_ZERO
              ? event.tasks?.map((task, index) => (
                  <span className={cx('tooltipText')} key={index}>
                    {task?.name || EMPTY_STRING}
                  </span>
                ))
              : EMPTY_STRING}
          </div>
        </Tooltip>
      </ReactPortal>
    </div>
  );
});

const CollapseWeekView = memo((props: { event: IScheduleEvent }) => {
  const { event } = props;
  const eventColor: string = event.color || DEFAULT_SCHEDULE_EVENT_COLOR;
  const id = generateGuid();
  return (
    <div
      className={cx('collapseEventContainer', 'eventContainer')}
      style={{
        backgroundColor: eventColor,
      }}
    >
      <div className={cx('eventName')} data-tooltip-id={`taskName-${id}`} data-tooltip-place='top'>
        {event.tasks?.[0]?.name || EMPTY_STRING}
      </div>
      <ReactPortal wrapperId='taskTooltip'>
        <Tooltip id={`taskName-${id}`} className={cx('tooltipWrap')}>
          <div className={cx('tooltip')}>
            {event.tasks?.length > DEFAULT_NUMBER_ZERO
              ? event.tasks?.map((task, index) => (
                  <span className={cx('tooltipText')} key={index}>
                    {task?.name || EMPTY_STRING}
                  </span>
                ))
              : EMPTY_STRING}
          </div>
        </Tooltip>
      </ReactPortal>
    </div>
  );
});

const ExpandDayView = memo((props: { event: IScheduleEvent }) => {
  const { event } = props;
  const eventColor: string = event.color || DEFAULT_SCHEDULE_EVENT_COLOR;
  const id = generateGuid();
  return (
    <div
      className={cx(`eventExpandDayContainer`)}
      style={{
        backgroundColor: eventColor,
      }}
    >
      <div className={cx('eventContent')}>
        <div className={cx('eventName')} data-tooltip-id={`taskName-${id}`} data-tooltip-place='top'>
          {event.tasks?.[0]?.name || EMPTY_STRING}
        </div>
        <div className={cx('eventTime')}>
          {moment(event.startTime).format('hh:mm a')} - {moment(event.endTime).format('hh:mm a')}
        </div>
      </div>
      <div className={cx('assigneeContainer')}>
        {event.assignees && <ScheduleAssignee assignees={event.assignees} eventColor={eventColor} />}
      </div>
      <ReactPortal wrapperId='taskTooltip'>
        <Tooltip id={`taskName-${id}`} className={cx('tooltipWrap')}>
          <div className={cx('tooltip')}>
            {event.tasks?.length > DEFAULT_NUMBER_ZERO
              ? event.tasks?.map((task, index) => (
                  <span className={cx('tooltipText')} key={index}>
                    {task?.name || EMPTY_STRING}
                  </span>
                ))
              : EMPTY_STRING}
          </div>
        </Tooltip>
      </ReactPortal>
    </div>
  );
});

const CollapseDayView = memo((props: { event: IScheduleEvent }) => {
  const { event } = props;
  const eventColor: string = event.color || DEFAULT_SCHEDULE_EVENT_COLOR;
  const id = generateGuid();
  return (
    <div
      className={cx(`eventCollapseDayContainer`)}
      style={{
        backgroundColor: eventColor,
      }}
    >
      <div className={cx('eventName')} data-tooltip-id={`taskName-${id}`} data-tooltip-place='top'>
        {event.tasks?.[0]?.name || EMPTY_STRING}
      </div>
      <div className={cx('eventTime')}>
        {moment(event.startTime).format('hh:mm a')} - {moment(event.endTime).format('hh:mm a')}
      </div>
      <div className={cx('assigneeContainer')}>
        {event.assignees && <ScheduleAssignee assignees={event.assignees} eventColor={eventColor} />}
      </div>
      <ReactPortal wrapperId='taskTooltip'>
        <Tooltip id={`taskName-${id}`} className={cx('tooltipWrap')}>
          <div className={cx('tooltip')}>
            {event.tasks?.length > DEFAULT_NUMBER_ZERO
              ? event.tasks?.map((task, index) => (
                  <span className={cx('tooltipText')} key={index}>
                    {task?.name || EMPTY_STRING}
                  </span>
                ))
              : EMPTY_STRING}
          </div>
        </Tooltip>
      </ReactPortal>
    </div>
  );
});

const MySchedule = (props: Props) => {
  //#region Destructuring Props
  const { ref, view, date, events, onSelectEvent, ...restProps } = props;
  //#endregion Destructuring Props

  const prepareDayEventsData = (events: Array<IScheduleEvent>): Array<Event> => {
    return events.map((event) => {
      const startTime = moment(event.startTime);
      const endTime = moment(event.endTime);
      const startAt = moment(event.startDate).hour(startTime.hour()).minute(startTime.minute());
      const endAt = moment(event.startDate).hour(endTime.hour()).minute(endTime.minute());
      return {
        start: startAt.toDate(),
        end: endAt.toDate(),
        resource: [event],
      };
    });
  };

  //#region Declare Hook
  const { t } = useTranslation();
  const preparedEvents = useMemo(() => {
    if (props.view === Views.MONTH) {
      return prepareMonthEventsData(props.events);
    }

    return prepareDayEventsData(props.events);
  }, [view, events]);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='myScheduleComponent' className={cx('container')}>
      {preparedEvents && (
        <div
          className={cx(
            { 'h-[780px]': view === Views.MONTH },
            { myScheduleWeekView: view === Views.WEEK },
            { myScheduleDayView: view === Views.DAY }
          )}
        >
          <BaseSchedule
            events={preparedEvents}
            selectable={true}
            toolbar={false}
            date={date}
            view={view}
            components={{
              event: ({ event }: EventProps) => {
                if (view === Views.MONTH) {
                  return (
                    <div className={cx('monthEventWrap')}>
                      {event?.resource?.map((eventData: IScheduleEvent, index: number) => (
                        <div key={index} onClick={() => onSelectEvent(eventData)}>
                          {event.resource.length > DEFAULT_NUMBER_ONE ? (
                            <CollapseMonthView event={eventData} />
                          ) : (
                            <ExpandMonthView event={eventData} />
                          )}
                        </div>
                      ))}
                    </div>
                  );
                }

                if (view === Views.WEEK) {
                  const eventData = event.resource[0] as IScheduleEvent;
                  const duration = moment(event.end).diff(moment(event.start), 'minutes');
                  return (
                    <div className={cx(`weekEventWrap`)} onClick={() => onSelectEvent(eventData)}>
                      {duration > 60 ? <ExpandWeekView event={eventData} /> : <CollapseWeekView event={eventData} />}
                    </div>
                  );
                }

                if (view === Views.DAY) {
                  const eventData = event.resource[0] as IScheduleEvent;
                  const duration = moment(event.end).diff(moment(event.start), 'minutes');
                  return (
                    <div className={cx(`weekEventWrap`)} onClick={() => onSelectEvent(eventData)}>
                      {duration > 60 ? <ExpandDayView event={eventData} /> : <CollapseDayView event={eventData} />}
                    </div>
                  );
                }
              },
            }}
            {...restProps}
          />
        </div>
      )}
    </div>
  );
};

export default memo(MySchedule);
