// Libs
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Field, Label } from '@headlessui/react';
// Components, Layouts, Pages
// Others
import { ASTERISK_SYMBOL, DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { INumberOptionMultiple } from '~/utils/interface/common';
import { CommonIconCheck } from '~/assets/svgComponents';
import { WHITE } from '~/utils/constants/color';
// Styles, images, icons
import styles from './MultipleCheckboxWeek.module.scss';

type Props = {
  id: string;
  label?: string;
  name?: string;
  value?: number[];
  options: INumberOptionMultiple[];
  messageError?: string;
  required?: boolean;
  onChange?: (value: number[], name?: string) => void;
};

const cx = classNames.bind(styles);

const MultipleCheckboxWeek = (props: Props) => {
  //#region Destructuring Props
  const { id, label, name, value, messageError, onChange, required, options } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [selectOption, setSelectOption] = useState<INumberOptionMultiple[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!value || value.length === DEFAULT_NUMBER_ZERO) return;

    const optionMatchWithValue = options.filter((item) => value.includes(item.value));

    setSelectOption(optionMatchWithValue || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleCheckbox = (optionValue: number) => {
    let updatedOptions: INumberOptionMultiple[];
    if (selectOption.some((option) => option.value === optionValue)) {
      updatedOptions = selectOption.filter((option) => option.value !== optionValue);
    } else {
      const selectedOption = options.find((option) => option.value === optionValue);
      if (selectedOption) {
        updatedOptions = [...selectOption, selectedOption];
      } else {
        return;
      }
    }

    setSelectOption(updatedOptions);

    if (onChange) {
      const selectedValues = updatedOptions.map((option) => option.value);
      onChange(selectedValues, name);
    }
  };

  const isSelected = (value: number) => {
    return selectOption.find((el) => el.value === value) ? true : false;
  };
  //#endregion Handle Function

  return (
    <Field id='weekCheckboxComponent'>
      {label && (
        <Label className={cx('label')}>
          {label} {required && <span className={cx('required')}>{ASTERISK_SYMBOL}</span>}
        </Label>
      )}

      <div className={cx('multipleCheckboxWrap')}>
        <div className={cx('multipleCheckboxGroup')}>
          {options.length > DEFAULT_NUMBER_ZERO ? (
            options.map((item, index) => {
              const uniqueId = `${id}-${index}`;
              const isChecked = isSelected(item.value);
              return (
                <div className={cx('multipleCheckboxContent')} key={index}>
                  <Checkbox
                    name={name}
                    value={item.value}
                    checked={isChecked}
                    id={uniqueId}
                    onChange={() => handleCheckbox(item.value)}
                  >
                    {({ checked }) => (
                      <span className={cx('multipleCheckbox', checked && 'checked')}>
                        <CommonIconCheck width={14} height={14} strokePath={checked ? WHITE : EMPTY_STRING} />
                      </span>
                    )}
                  </Checkbox>
                  <label htmlFor={uniqueId} className={cx('checkboxName')}>
                    {item.label}
                  </label>
                </div>
              );
            })
          ) : (
            <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>
          )}
        </div>
      </div>

      {messageError && <p className={cx('inputBaseMessageError')}>{messageError}</p>}
    </Field>
  );
};

export default MultipleCheckboxWeek;
