// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
// Others
import { LoadingContext } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getToken } from '~/thunks/quickBook/quickBookThunk';
import { IQueryGetTokenQuickBook } from '~/utils/interface/quickBook';
import { MESSAGE_CONNECTED_QUICK_BOOK } from '~/utils/constants/common';
// Styles, images, icons
import styles from './QuickBookCallBack.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const QuickBookCallBack = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingContext = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const payload: IQueryGetTokenQuickBook = {
      code: params?.code,
      state: params?.state,
      realmId: params?.realmId,
    };

    handleGetTokenQuickBook(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetTokenQuickBook = (payload: IQueryGetTokenQuickBook) => {
    loadingContext?.show();

    dispatch(getToken(payload))
      .unwrap()
      .then((res) => {
        window.opener.postMessage(MESSAGE_CONNECTED_QUICK_BOOK, '*');
        window.close();
      })
      .catch((error) => {})
      .finally(() => {
        loadingContext?.hide();
      });
  };
  //#endregion Handle Function

  return <>{t('common_loading')}</>;
};

export default QuickBookCallBack;
