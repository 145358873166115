// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { TFunction } from 'i18next';
// Components, Layouts, Pages
import { BaseTable, ImageCircle, ModalUnderDevelopment } from '~/components';
// Others
import { ImageCircleTypeEnum, TimeFormatEnum, TranslationEnum } from '~/utils/enum';
import { ColumnTableType } from '~/utils/interface/common';
import { IActivity } from '~/mockData/mockInterface';
import { EMPTY_VALUE } from '~/utils/constants/common';
import { convertDateToFormatTime, getUserName } from '~/utils/helper';
// Styles, images, icons
import styles from './ActivityTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  onOpenDevelopment: () => void
): ColumnTableType<IActivity>[] => {
  return [
    {
      key: 'name',
      title: t('caregiver_detail_activity_tab_table_label_name'),
      render(value, record: IActivity) {
        return !record.firstName && !record.lastName ? (
          <>{EMPTY_VALUE}</>
        ) : (
          <>
            <ImageCircle
              type={record?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
              imageUrl={record?.avatarUrl}
              firstName={record?.firstName}
              lastName={record?.lastName}
              width={24}
              height={24}
              fontSize={10}
              margin={'0 6px 0 0'}
            />
            <span>{getUserName(record?.firstName, record?.lastName)}</span>
          </>
        );
      },
      width: '20%',
    },
    {
      key: 'activity',
      title: t('caregiver_detail_activity_tab_table_label_activity'),
      dataIndex: 'activityName',
      render(value, record, index) {
        return <>{record.activityName ? record.activityName : EMPTY_VALUE}</>;
      },
      width: '20%',
    },
    {
      key: 'date',
      title: t('caregiver_detail_activity_tab_table_label_date'),
      dataIndex: 'date',
      render(value, record, index) {
        return <>{record.date ? convertDateToFormatTime(record.date, TimeFormatEnum.MM_DD_YYYY) : EMPTY_VALUE}</>;
      },
      width: '20%',
    },
    {
      key: 'time',
      title: t('caregiver_detail_activity_tab_table_label_time'),
      dataIndex: 'time',
      render(value, record, index) {
        return (
          <>{record.time ? convertDateToFormatTime(record.time, TimeFormatEnum.HOUR_MINUTE_AM_PM) : EMPTY_VALUE}</>
        );
      },
      width: '20%',
    },
    {
      key: 'action',
      title: t('caregiver_detail_activity_tab_table_label_action'),
      render(value, record, index) {
        return (
          <button className={cx('dots')} onClick={onOpenDevelopment}>
            <span className={cx('dot')}></span>
            <span className={cx('dot')}></span>
            <span className={cx('dot')}></span>
          </button>
        );
      },
      width: 50,
    },
  ];
};

const ActivityTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isOpenDevelopment, setIsOpenDevelopment] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangePage = (page: number) => {
    // Handle Change Page Later
  };

  const handleOpenDevelopment = () => {
    setIsOpenDevelopment(!isOpenDevelopment);
  };

  const handleActivityRowClick = (data: IActivity) => {
    handleOpenDevelopment();
  };
  //#endregion Handle Function

  return (
    <div id='activityTabComponent' className={cx('activityTabComponent')}>
      <div className={cx('bodyWrap')}>
        <div className={cx('statisticTable')}>
          <BaseTable columns={columns(t, handleOpenDevelopment)} dataSource={[]} onClickRow={handleActivityRowClick} />
        </div>
        <div className={cx('paginationTable')}>{/* Handle Pagination Later Use PageNavigator */}</div>
      </div>

      {isOpenDevelopment && <ModalUnderDevelopment onClose={handleOpenDevelopment} />}
    </div>
  );
};

export default ActivityTab;
