// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Field, Input, Label } from '@headlessui/react';
import { ChangeEvent, useEffect, useState } from 'react';
// Components, Layouts, Pages
// Others
import { InputTypeEnum } from '~/utils/enum';
import { ASTERISK_SYMBOL } from '~/utils/constants/common';
// Styles, images, icons
import styles from './BaseInput.module.scss';
import { icons } from '~/assets';

type Props = {
  id: string;
  label?: string;
  width?: string | number;
  height?: string | number;
  name?: string;
  value?: string | number;
  disabled?: boolean;
  placeholder?: string;
  type?: InputTypeEnum;
  pattern?: string;
  typeStyle?: string;
  messageError?: string;
  required?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const cx = classNames.bind(styles);

const BaseInput = (props: Props) => {
  //#region Destructuring Props
  const {
    id,
    width = '100%',
    height = '34px',
    type = InputTypeEnum.TEXT,
    label,
    name,
    value,
    placeholder,
    disabled,
    typeStyle,
    messageError,
    required,
    onChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [inputType, setInputType] = useState<InputTypeEnum>(type);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (type === InputTypeEnum.PASSWORD) {
      const newType = isShowPassword ? InputTypeEnum.TEXT : InputTypeEnum.PASSWORD;
      setInputType(newType);
    }
  }, [type, isShowPassword]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
  };

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };
  //#endregion Handle Function

  return (
    <div id='baseInput'>
      <Field disabled={disabled} className={cx('containerBaseInput', typeStyle)} style={{ width }}>
        {label && (
          <Label className={cx('inputBaseLabel')} htmlFor={id}>
            {label} {required && <span className={cx('inputBaseLabelRequired')}>{ASTERISK_SYMBOL}</span>}
          </Label>
        )}

        <div className={cx('inputBaseContent')}>
          {type === InputTypeEnum.PASSWORD && (
            <img src={icons.commonIconLock} alt={t('common_img_text_alt')} className={cx('iconLeft')} />
          )}
          <Input
            type={inputType}
            id={id}
            placeholder={placeholder}
            name={name}
            style={{ width, height }}
            value={value}
            className={cx('inputBaseInput', typeStyle, type === InputTypeEnum.PASSWORD && 'inputBasePassword')}
            onChange={handleChangeInput}
          />
          {type === InputTypeEnum.PASSWORD && (
            <span className={cx('inputBaseIcon', typeStyle)}>
              <img
                className={cx('inputBaseIconImage', typeStyle)}
                src={isShowPassword ? icons.signInIconEyeShow : icons.signInIconEyeHide}
                alt={t('common_img_text_alt')}
                onClick={handleShowPassword}
              />
            </span>
          )}
        </div>

        {messageError && <p className={cx('inputBaseMessageError', typeStyle)}>{messageError}</p>}
      </Field>
    </div>
  );
};

export default BaseInput;
