// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogPanel, Transition } from '@headlessui/react';
import { Fragment } from 'react/jsx-runtime';
// Components, Layouts, Pages
import { BaseButton } from '~/components';
// Others
import { DIV_ELEMENT, EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './RelateDeletedModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  subTitle?: string;
};

const cx = classNames.bind(styles);

const RelateDeletedModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, subTitle = EMPTY_STRING } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook
  //#region Selector
  //#endregion Selector
  //#region Declare State
  //#endregion Declare State
  //#region Implement Hook
  //#endregion Implement Hook
  //#region Handle Function
  const handleCloseModal = () => {
    onClose && onClose();
  };
  //#endregion Handle Function

  return (
    <Transition
      show={isOpen}
      enter='transition-opacity duration-300'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='transition-opacity duration-200'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <Dialog
        id='underDevelopmentModalComponent'
        className={cx('modalContainer')}
        as={DIV_ELEMENT}
        open={isOpen}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-in-out duration-300'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
          leave='ease-in-out duration-200'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-95'
        >
          <DialogPanel className={cx('modalContent')}>
            <div className={cx('bodyContent')}>
              <div className={cx('textDevelopment')}>{`${t('activity_assignee_task_deleted')} ${subTitle}`}</div>
            </div>

            <div className={cx('formButtonWrap')}>
              <div className={cx('buttonGroup')}>
                <BaseButton onClick={handleCloseModal} width={125} height={32} text={t('common_btn_ok')} />
              </div>
            </div>
          </DialogPanel>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default RelateDeletedModal;
