// Libs
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
// Components, Layouts, Pages
// Others
import { ColorType } from '~/utils/type/common';
// Styles, images, icons
import styles from './BaseStatus.module.scss';

type Props = {
  label: string;
  width?: number | string;
  height?: number | string;
  color?: ColorType;
  borderRadius?: number;
  fontSize?: number;
  fontWeight?: number;
};

const cx = classNames.bind(styles);

const BaseStatus = (props: Props) => {
  //#region Destructuring Props
  const { width = '100%', height = 20, label, color, borderRadius, fontSize, fontWeight } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div
      id='labelStatus'
      className={cx('originStyle', color)}
      style={{ width, height, borderRadius, fontSize: fontSize, fontWeight: fontWeight }}
    >
      <span className={cx('buttonStatusLabel')}>{t(label)}</span>
    </div>
  );
};

export default BaseStatus;
