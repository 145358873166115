// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Views } from 'react-big-calendar';
import moment from 'moment';
// Components, Layouts, Pages
import { BaseButton, DetailScheduleModal, FormScheduleModal, FullSchedule, MySchedule } from '~/components';
// Others
import { TCalendarViewMode, TScheduleMode } from '~/utils/type/schedule';
import { AccountRoleCodesEnum, ButtonTypeEnum, DateFormatEnum } from '~/utils/enum';
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { scheduleActions, selectIsRefreshScheduleList } from '~/thunks/schedule/scheduleSlice';
import { dateControl } from '~/mockData';
import { capitalizeFirstLetter, checkTimelineMode } from '../helper';
import { CommonIconNext, CommonIconPrev } from '~/assets/svgComponents';
import { WHITE } from '~/utils/constants/color';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_PAGE_SCHEDULE,
  DEFAULT_VIEW_MODE,
  EMPTY_STRING,
  SCHEDULE_MODE_FULL,
  SCHEDULE_MODE_MYSELF,
} from '~/utils/constants/common';
import { IParamsGetScheduleClient, IScheduleEvent, IScheduleTimelineData } from '~/utils/interface/schedule';
import { IListDataResponse } from '~/utils/interface/common';
import { getListSchedule, getScheduleClient } from '~/thunks/schedule/scheduleThunk';
import { IQueryParamGetListSchedule } from '~/utils/interface/crm/shiftSchedule';
import { IClientDetail } from '~/utils/interface/crm/clients';
// Styles, images, icons
import styles from './ScheduleComponent.module.scss';
import { icons } from '~/assets';

type Props = {
  mode: TScheduleMode;
  isShowFormSchedule?: boolean;
  userId?: string;
  data?: IClientDetail;
  isShowDetail?: boolean;
  role?: AccountRoleCodesEnum;
  isClient?: boolean;
};

const cx = classNames.bind(styles);

const ScheduleComponent = (props: Props) => {
  //#region Destructuring Props
  const { mode, isShowFormSchedule, userId, data, isShowDetail = false, role, isClient = false } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  const isRefreshScheduleList = useSelector(selectIsRefreshScheduleList);
  //#endregion Selector

  //#region Declare State
  const [scheduleEvents, setScheduleEvents] = useState<IScheduleEvent[]>([]);
  const [timelineEvents, setTimelineEvents] = useState<IScheduleTimelineData[]>([]);
  const [scheduleId, setScheduleId] = useState<string>();
  const [isOpenFormSchedule, setIsOpenFormSchedule] = useState<boolean>(false);
  const [isOpenEditSchedule, setIsOpenEditSchedule] = useState<boolean>(false);
  const [isOpenDetailSchedule, setIsOpenDetailSchedule] = useState<boolean>(false);
  const isFullMode = mode === 'full';
  const isMySelfMode = mode === 'my';
  // const isFullModeWithoutUser = mode === 'full' && !userId;
  const [scheduleMode, setScheduleMode] = useState<string>(EMPTY_STRING);
  const [calendarViewMode, setCalendarViewMode] = useState<TCalendarViewMode>(DEFAULT_VIEW_MODE);
  const [currentTime, setCurrentTime] = useState<string>(moment().format(DateFormatEnum.YYYY_MM_DD));

  const isTimelineMode = useMemo(
    () => checkTimelineMode(calendarViewMode, scheduleMode),
    [calendarViewMode, scheduleMode]
  );
  const timeValue = useMemo(() => {
    switch (calendarViewMode) {
      case Views.WEEK:
        const startOfWeek = moment(currentTime).startOf('week');
        const endOfWeek = moment(currentTime).endOf('week');
        const formattedWeek = `${startOfWeek.format('MMMM DD')} - ${endOfWeek.format('DD, YYYY')}`;
        return formattedWeek;
      case Views.DAY:
        return moment(currentTime).format('dddd, MMMM YYYY');
      default:
        return moment(currentTime).format('MMMM, YYYY');
    }
  }, [calendarViewMode, currentTime]);
  const eventsList = isTimelineMode ? timelineEvents : scheduleEvents;
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!mode) return;
    handleChangeScheduleMode(mode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    if (!scheduleMode) return;

    if (isClient) {
      return handleGetScheduleClient();
    }

    handleGetListSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime, calendarViewMode, scheduleMode, isClient]);

  useEffect(() => {
    if (!isRefreshScheduleList) return;

    if (isClient) {
      return handleGetScheduleClient();
    }

    handleGetListSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshScheduleList, isClient]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListSchedule = () => {
    loading?.show();

    const startDate = moment(currentTime).startOf(calendarViewMode).format(DateFormatEnum.YYYY_MM_DD);
    const endDate = moment(currentTime).endOf(calendarViewMode).format(DateFormatEnum.YYYY_MM_DD);
    let type = isFullMode ? SCHEDULE_MODE_FULL.toUpperCase() : SCHEDULE_MODE_MYSELF.toUpperCase();
    const view = calendarViewMode.toUpperCase();
    const page = DEFAULT_CURRENT_PAGE;
    const limit = DEFAULT_LIMIT_PAGE_SCHEDULE;

    const params: IQueryParamGetListSchedule = {
      startDate,
      endDate,
      type,
      view,
    };

    if (calendarViewMode === Views.WEEK || calendarViewMode === Views.DAY) {
      params.page = page;
      params.limit = limit;
    }

    dispatch(getListSchedule(params))
      .unwrap()
      .then((res) => {
        loading?.hide();
        if (isFullMode) {
          if (isTimelineMode) {
            const { responses } = res as IListDataResponse<IScheduleTimelineData[]>;
            setTimelineEvents(responses);
          } else {
            const responses = res as IScheduleEvent[];
            setScheduleEvents(responses);
          }
        }

        if (isMySelfMode) {
          const responses = res as IScheduleEvent[];
          setScheduleEvents(responses);
        }

        dispatch(scheduleActions.setRefreshScheduleList(false));
      })
      .catch((error) => {
        loading?.hide();
      });
  };

  const handleGetScheduleClient = () => {
    if (!userId) return;
    loading?.show();

    const startDate = moment(currentTime).startOf(calendarViewMode).format(DateFormatEnum.YYYY_MM_DD);
    const endDate = moment(currentTime).endOf(calendarViewMode).format(DateFormatEnum.YYYY_MM_DD);

    const params: IParamsGetScheduleClient = {
      accountId: userId,
      endDate: endDate,
      startDate: startDate,
    };

    if (calendarViewMode === Views.WEEK || calendarViewMode === Views.DAY) {
      params.page = DEFAULT_CURRENT_PAGE;
      params.limit = DEFAULT_LIMIT_PAGE_SCHEDULE;
    }

    dispatch(getScheduleClient(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses } = res.data;
        setScheduleEvents(responses);
        dispatch(scheduleActions.setRefreshScheduleList(false));
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const goPreviousTime = () => {
    const previousTime = moment(currentTime).subtract(1, calendarViewMode);
    setCurrentTime(previousTime.format(DateFormatEnum.YYYY_MM_DD));
  };

  const goNextTime = () => {
    const nextTime = moment(currentTime).add(1, calendarViewMode);
    setCurrentTime(nextTime.format(DateFormatEnum.YYYY_MM_DD));
  };

  const goToday = () => {
    setCurrentTime(moment().format(DateFormatEnum.YYYY_MM_DD));
  };

  const handleChangeScheduleMode = (newValue: TScheduleMode) => {
    setScheduleMode(newValue);
  };

  const handleChangeCalendarView = (newValue: TCalendarViewMode): void => {
    setCalendarViewMode(newValue);
  };

  const handleShowFormSchedule = () => {
    setIsOpenFormSchedule(true);
  };

  const handleShowEditSchedule = (scheduleId?: string) => {
    setScheduleId(scheduleId);
    setIsOpenEditSchedule(true);
  };

  const handleCloseForm = () => {
    scheduleId && setScheduleId(EMPTY_STRING);
    isOpenEditSchedule && setIsOpenEditSchedule(false);
    isOpenFormSchedule && setIsOpenFormSchedule(false);
  };

  const handleShowDetailSchedule = (scheduleId?: string) => {
    setScheduleId(scheduleId);
    setIsOpenDetailSchedule(true);
  };

  const handleCloseDetail = () => {
    setIsOpenDetailSchedule(false);
  };
  //#endregion Handle Function

  return (
    <div id='scheduleComponent' className={cx('scheduleComponent')}>
      <div className={cx('headerWrap')}>{/* Update Later */}</div>
      <div className={cx('bodyWrap')}>
        <div className={cx('toolbarWrapper')}>
          <div className={cx('toolbarLeft')}>
            <div className={cx('dateControl')}>
              <button className={cx('dateControlBtn')} onClick={goPreviousTime}>
                <CommonIconPrev strokePath={WHITE} />
              </button>
              <button className={cx('dateControlBtn')} onClick={goNextTime}>
                <CommonIconNext strokePath={WHITE} />
              </button>
            </div>
            <button className={cx('dateControlBtnToday')} onClick={goToday}>
              {t('common_text_today')}
            </button>
          </div>

          <div className={cx('label')}>{timeValue}</div>

          <div className={cx('toolbarRight')}>
            {/* {isFullModeWithoutUser && (
              <div className={cx('btnGroup')}>
                <button
                  className={cx('btnCalendar', scheduleMode === SCHEDULE_MODE_CLIENTS && 'active')}
                  onClick={() => handleChangeScheduleMode(SCHEDULE_MODE_CLIENTS)}
                >
                  {t('title_clients_schedule')}
                </button>
                <button
                  className={cx('btnCalendar', scheduleMode === SCHEDULE_MODE_CAREGIVER && 'active')}
                  onClick={() => handleChangeScheduleMode(SCHEDULE_MODE_CAREGIVER)}
                >
                  {t('title_caregiver_schedule')}
                </button>
              </div>
            )} */}

            <div className={cx('modeViewCalendar')}>
              {dateControl.map((date, index) => {
                return (
                  <button
                    key={index}
                    className={cx('btnCalendar', calendarViewMode === date && 'active')}
                    onClick={() => handleChangeCalendarView(date)}
                  >
                    {capitalizeFirstLetter(date)}
                  </button>
                );
              })}
            </div>

            {isShowFormSchedule && (
              <div className={cx('btnSchedule')}>
                <BaseButton
                  typeStyle={ButtonTypeEnum.PRIMARY}
                  iconLeft={icons.commonIconPlus}
                  text={t('crm_client_detail_schedule_tab_btn_add_schedule')}
                  onClick={handleShowFormSchedule}
                  height={36}
                />
              </div>
            )}
          </div>
        </div>

        <div className={cx('scheduleWrap')}>
          {isFullMode ? (
            <FullSchedule
              view={calendarViewMode}
              date={currentTime}
              events={Array.isArray(eventsList) ? eventsList : []}
              onSelectEvent={(events) =>
                isShowDetail ? handleShowDetailSchedule(events.id) : handleShowEditSchedule(events.id)
              }
            />
          ) : (
            <MySchedule
              view={calendarViewMode}
              date={currentTime}
              events={Array.isArray(scheduleEvents) ? scheduleEvents : []}
              onSelectEvent={(events) =>
                isShowDetail ? handleShowDetailSchedule(events.id) : handleShowEditSchedule(events.id)
              }
            />
          )}
        </div>
        {(isOpenEditSchedule || isOpenFormSchedule) && (
          <FormScheduleModal
            isOpen={isOpenEditSchedule || isOpenFormSchedule}
            onClose={handleCloseForm}
            scheduleId={scheduleId}
            dataDetail={data}
            isGetListClient={role === AccountRoleCodesEnum.EMPLOYEE || false}
          />
        )}

        {isOpenDetailSchedule && (
          <DetailScheduleModal isOpen={isOpenDetailSchedule} onClose={handleCloseDetail} scheduleId={scheduleId} />
        )}
      </div>
    </div>
  );
};

export default ScheduleComponent;
