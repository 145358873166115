// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { TFunction } from 'i18next';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseTable,
  ButtonStatus,
  ImageCircle,
  InputSearch,
  ModalUnderDevelopment,
  ToolBar,
} from '~/components';
// Others
import {
  ButtonStatusEnum,
  ButtonTypeEnum,
  ImageCircleTypeEnum,
  statusEnum,
  TimeFormatEnum,
  TranslationEnum,
} from '~/utils/enum';
import { ColumnTableType } from '~/utils/interface/common';
import { IHr } from '~/mockData/mockInterface';
import { EMPTY_STRING } from '~/utils/constants/common';
import { convertDateToFormatTime, getUserName } from '~/utils/helper';
import { mockDataHistory, mockDataHr } from '~/mockData';
// Styles, images, icons
import styles from './Hr.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columnsApprovalRequest = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  onApproved: () => void,
  onReject: () => void
): ColumnTableType<IHr>[] => {
  return [
    {
      key: 'employeeName',
      title: t('hr_table_employee_name_label'),
      render: (_, record) => {
        return !record.firstName && !record.lastName ? (
          <>{EMPTY_STRING}</>
        ) : (
          <>
            <ImageCircle
              type={record?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
              imageUrl={record?.avatarUrl}
              firstName={record?.firstName}
              lastName={record?.lastName}
              width={24}
              height={24}
              fontSize={10}
              margin={'0 6px 0 0'}
            />
            <span>{getUserName(record?.firstName, record?.lastName)}</span>
          </>
        );
      },
    },
    {
      key: 'employeeId',
      title: t('hr_table_employee_id_label'),
      dataIndex: 'employeeId',
      render(value, record, index) {
        return <>{record.employeeId ? record.employeeId : EMPTY_STRING}</>;
      },
    },
    {
      key: 'email',
      title: t('hr_table_email_label'),
      dataIndex: 'email',
      render(value, record, index) {
        return <>{record.email ? record.email : EMPTY_STRING}</>;
      },
    },
    {
      key: 'date',
      title: t('hr_table_date_label'),
      dataIndex: 'date',
      render(value, record, index) {
        return <>{record.date ? convertDateToFormatTime(record.date, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'status',
      title: t('hr_table_status_label'),
      render(value, record, index) {
        return (
          <div className={cx('buttonStatus')} onClick={(e) => e.stopPropagation()}>
            <ButtonStatus width={72} borderRadius={6} color='teal800' label={statusEnum.APPROVE} onClick={onApproved} />
            <ButtonStatus width={72} borderRadius={6} color='gray300' label={statusEnum.REJECT} onClick={onReject} />
          </div>
        );
      },
      width: '12%',
    },
  ];
};

const columnsHistory = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  onShowDevelopment: () => void
): ColumnTableType<IHr>[] => {
  return [
    {
      key: 'employeeName',
      title: t('hr_table_history_name_label'),
      width: '30%',
      render: (_, record) => {
        return !record.firstName && !record.lastName ? (
          <>{EMPTY_STRING}</>
        ) : (
          <>
            <ImageCircle
              type={record?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
              imageUrl={record?.avatarUrl}
              firstName={record?.firstName}
              lastName={record?.lastName}
              width={24}
              height={24}
              fontSize={10}
              margin={'0 6px 0 0'}
            />
            <span>{getUserName(record?.firstName, record?.lastName)}</span>
          </>
        );
      },
    },
    {
      key: 'employeeId',
      title: t('hr_table_history_id_label'),
      dataIndex: 'employeeId',
      width: '15%',
      render(value, record, index) {
        return <>{record.employeeId ? record.employeeId : EMPTY_STRING}</>;
      },
    },
    {
      key: 'email',
      title: t('hr_table_email_label'),
      dataIndex: 'email',
      width: '30%',
      render(value, record, index) {
        return <>{record.email ? record.email : EMPTY_STRING}</>;
      },
    },
    {
      key: 'status',
      title: t('hr_table_status_label'),
      dataIndex: 'status',
      width: '15%',
      render(value, record, index) {
        switch (record.status) {
          case ButtonStatusEnum.APPROVED:
            return <ButtonStatus width={85} borderRadius={6} color='greenPea700' label={record.status} />;

          case ButtonStatusEnum.PENDING:
            return <ButtonStatus width={85} borderRadius={6} color='finn900' label={record.status} />;

          case ButtonStatusEnum.REJECTED:
            return <ButtonStatus width={85} borderRadius={6} color='red600' label={record.status} />;

          default:
            return EMPTY_STRING;
        }
      },
    },
    {
      key: 'action',
      title: t('hr_table_action_label'),
      render(value, record, index) {
        return (
          <button className={cx('dots')} onClick={onShowDevelopment}>
            <span className={cx('dot')}></span>
            <span className={cx('dot')}></span>
            <span className={cx('dot')}></span>
          </button>
        );
      },
    },
  ];
};

const Hr = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isDevelopment, setIsDevelopment] = useState<boolean>(false);
  const [dataHR, setDataHr] = useState<IHr[]>(mockDataHr);
  const [dataHistory, setDataHistory] = useState<IHr[]>(mockDataHistory);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangeSearch = () => {
    // Handle Update Later
  };

  const handleAddUser = () => {
    // Handle Update Later
    handleShowDevelopment();
  };

  const handlePaginationChangeApprovalRequest = (page: number) => {
    // Handle Update later
  };

  const handlePaginationChangeHistory = (page: number) => {
    // Handle Update later
  };

  const handleApproved = () => {
    // Handle Update Later
    handleShowDevelopment();
  };

  const handleRejected = () => {
    // Handle Update Later
    handleShowDevelopment();
  };

  const handleShowDevelopment = () => {
    setIsDevelopment(!isDevelopment);
  };

  const handleApprovalRequestRowClick = (data: IHr) => {
    handleShowDevelopment();
  };

  const handleHistoryRowClick = (data: IHr) => {
    handleShowDevelopment();
  };
  //#endregion Handle Function

  return (
    <div id='hrPage' className={cx('container')}>
      <ToolBar title={t('hr_title_page')}>
        <InputSearch height={36} placeholder={t('common_placeholder_search')} onChange={handleChangeSearch} />
        <BaseButton
          typeStyle={ButtonTypeEnum.PRIMARY}
          iconLeft={icons.commonIconPlus}
          text={t('hr_add_user_button')}
          onClick={handleAddUser}
          height={36}
        />
      </ToolBar>

      <div className={cx('content')}>
        <div className={cx('approvalRequest')}>
          <ToolBar title={t('hr_approval_request_title')} fontSize={20} />

          <div className={cx('table')}>
            <BaseTable
              columns={columnsApprovalRequest(t, handleApproved, handleRejected)}
              dataSource={dataHR}
              onClickRow={handleApprovalRequestRowClick}
            />
          </div>
          <div className={cx('pagination')}>{/* Handle Pagination Later */}</div>
        </div>

        <div className={cx('history')}>
          <ToolBar title={t('hr_history_title')} fontSize={20} />
          <div className={cx('table')}>
            <BaseTable
              columns={columnsHistory(t, handleShowDevelopment)}
              dataSource={dataHistory}
              onClickRow={handleHistoryRowClick}
            />
          </div>
          <div className={cx('pagination')}>{/* Handle Pagination Later */}</div>
        </div>
      </div>
      {isDevelopment && <ModalUnderDevelopment onClose={handleShowDevelopment} />}
    </div>
  );
};

export default Hr;
