// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

// Components, Layouts, Pages
import { BaseSelectMultiple, BaseTextarea } from '~/components';

// Others
import {
  medicalConditionsHearingOptions,
  medicalConditionsOtherOptions,
  medicalConditionsSpeechOptions,
  medicalConditionsSwallowingOptions,
  medicalConditionsVisionOptions,
} from '~/mockData';
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
import { EMPTY_STRING } from '~/utils/constants/common';

// Styles, images, icons
import styles from './MedicalConditionDayCenterClient.module.scss';

type Props = {
  content: string;
};

const cx = classNames.bind(styles);

const MedicalConditionDayCenterClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='medicalConditionDayCenterClientComponent' className={cx('container')}>
      <Controller
        name='medicalCondition.secondaryDiagnosis'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseTextarea
            id='medicalConditionSecondaryDiagnosis'
            label={t('medical_conditions_day_center_client_secondary_diagnosis_label')}
            value={value || EMPTY_STRING}
            onChange={onChange}
            messageError={errors.medicalCondition?.secondaryDiagnosis?.message}
          />
        )}
      />

      <Controller
        name='medicalCondition.hearing'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseSelectMultiple
            placeholder={t('common_placeholder_select')}
            label={t('medical_conditions_day_center_client_hearing_label')}
            options={medicalConditionsHearingOptions || []}
            value={value || []}
            onChange={onChange}
            errorMessage={errors.medicalCondition?.hearing?.message}
          />
        )}
      />

      <Controller
        name='medicalCondition.speech'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseSelectMultiple
            placeholder={t('common_placeholder_select')}
            label={t('medical_conditions_day_center_client_speech_label')}
            options={medicalConditionsSpeechOptions || []}
            value={value || []}
            onChange={onChange}
            errorMessage={errors.medicalCondition?.speech?.message}
          />
        )}
      />

      <Controller
        name='medicalCondition.vision'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseSelectMultiple
            placeholder={t('common_placeholder_select')}
            label={t('medical_conditions_day_center_client_vision_label')}
            options={medicalConditionsVisionOptions || []}
            value={value || []}
            onChange={onChange}
            errorMessage={errors.medicalCondition?.vision?.message}
          />
        )}
      />

      <Controller
        name='medicalCondition.swallowing'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseSelectMultiple
            placeholder={t('common_placeholder_select')}
            label={t('medical_conditions_day_center_client_swallowing_label')}
            options={medicalConditionsSwallowingOptions || []}
            value={value || []}
            onChange={onChange}
            errorMessage={errors.medicalCondition?.swallowing?.message}
          />
        )}
      />

      <Controller
        name='medicalCondition.other'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseSelectMultiple
            placeholder={t('common_placeholder_select')}
            label={t('medical_conditions_day_center_client_other_label')}
            options={medicalConditionsOtherOptions || []}
            value={value || []}
            onChange={onChange}
            errorMessage={errors.medicalCondition?.other?.message}
          />
        )}
      />
    </div>
  );
};

export default MedicalConditionDayCenterClient;
