import { urlApiAuth } from '~/utils/constants/api';
import axiosClient from './axiosClient';
import {
  IBodyChangePassword,
  ILoginPayload,
  ILoginRes,
  INewPasswordReq,
  INewPasswordRes,
  IVerifyCode,
} from '~/utils/interface/auth';
import { IForgotPasswordPayload, IForgotPasswordResponse } from '~/utils/interface/auth';
import { BaseResponse } from '~/utils/interface/common';

const authApi = {
  login(params: ILoginPayload) {
    const url = `${urlApiAuth.login}`;
    return axiosClient.post<BaseResponse<ILoginRes>>(url, params);
  },

  forgotPassword(payload: IForgotPasswordPayload) {
    const url = `${urlApiAuth.forgotPassword}`;
    return axiosClient.post<BaseResponse<IForgotPasswordResponse>>(url, payload);
  },

  createNewPassword(payload: INewPasswordReq) {
    const url = `${urlApiAuth.newPassword}`;
    return axiosClient.post<BaseResponse<INewPasswordRes>>(url, payload);
  },

  changePassword(payload: IBodyChangePassword) {
    const url = `${urlApiAuth.changePassword}`;
    return axiosClient.put<BaseResponse>(url, payload);
  },

  verifyCode(body: IVerifyCode) {
    const url = `${urlApiAuth.verifyCode}`;
    return axiosClient.post<BaseResponse>(url, body);
  },
};

export default authApi;
