// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import quickBookApi from '~/apis/quickBook';
import { IQueryGetTokenQuickBook } from '~/utils/interface/quickBook';
import { DISCONNECT_QUICK_BOOK, GET_AUTH_URL, GET_TOKEN_QUICK_BOOK } from '~/utils/constants/actionType';

export const getAuthUrl = createAsyncThunk(GET_AUTH_URL, async () => {
  try {
    const res = await quickBookApi.getAuthUrl();
    return res.data;
  } catch (error) {
    throw error;
  }
});

export const getToken = createAsyncThunk(GET_TOKEN_QUICK_BOOK, async (payload: IQueryGetTokenQuickBook) => {
  try {
    const res = await quickBookApi.getTokenQuickBook(payload);
    return res.data;
  } catch (error) {
    throw error;
  }
});

export const disconnectQuickBooks = createAsyncThunk(DISCONNECT_QUICK_BOOK, async () => {
  try {
    const res = await quickBookApi.disconnect();
    return res.data;
  } catch (error) {
    throw error;
  }
});
