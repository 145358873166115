// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, ClipboardEvent, FocusEvent, KeyboardEvent, useContext, useEffect, useRef, useState } from 'react';
// Components, Layouts, Pages
import { BaseButton } from '~/components';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '~/redux/hooks';
// Others
import { ButtonTypeEnum, KeyboardEnum } from '~/utils/enum';
import {
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  NUMBER_COLUMNS_VERIFY,
  RegExp,
} from '~/utils/constants/common';
import { authRouteAbsolute } from '~/utils/constants/route';
import { validateWithRegex } from '~/utils/helper';
import { LoadingContext } from '~/context';
import { forgotPassword, verifyCode } from '~/thunks/auth/authThunk';
import { IForgotPasswordPayload } from '~/utils/interface/auth';
// Styles, images, icons
import styles from './VerifyCode.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const VerifyCode = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingContext = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [dataOTP, setDataOTP] = useState<string[]>(Array(NUMBER_COLUMNS_VERIFY).fill(EMPTY_STRING));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const { state }: Location<IForgotPasswordPayload> = useLocation();
  const [hasErrorMessage, setHasErrorMessage] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!state?.email) navigate(`${authRouteAbsolute.forgotPassword}`);
  }, [state?.email]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const index = inputRefs.current.indexOf(event.currentTarget);

    if (value) {
      setDataOTP((prevOtp) => {
        const newOtp = [
          ...prevOtp.slice(DEFAULT_NUMBER_ZERO, index),
          value,
          ...prevOtp.slice(index + DEFAULT_NUMBER_ONE),
        ];

        if (newOtp.every((otp) => otp !== EMPTY_STRING)) {
          setHasErrorMessage(false);
        }

        return newOtp;
      });

      if (index < dataOTP.length - DEFAULT_NUMBER_ONE) {
        inputRefs.current[index + DEFAULT_NUMBER_ONE]?.focus();
      }
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      !validateWithRegex(event.key, RegExp.NUMBER) &&
      event.key !== KeyboardEnum.BACKSPACE &&
      event.key !== KeyboardEnum.DELETE &&
      event.key !== KeyboardEnum.TAB &&
      !event.metaKey
    ) {
      event.preventDefault();
    }

    if (event.key === KeyboardEnum.BACKSPACE || event.key === KeyboardEnum.DELETE) {
      const index = inputRefs.current.indexOf(event.currentTarget);

      if (index >= DEFAULT_NUMBER_ZERO) {
        setDataOTP((prevOtp) => [
          ...prevOtp.slice(DEFAULT_NUMBER_ZERO, index),
          EMPTY_STRING,
          ...prevOtp.slice(index + DEFAULT_NUMBER_ONE),
        ]);
        inputRefs.current[index - DEFAULT_NUMBER_ONE]?.focus();
      }
    }
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    event.currentTarget.select();
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const text = event.clipboardData.getData('text');

    if (!validateWithRegex(text, RegExp.NUMBER_MAX_LENGTH_SIX)) {
      return;
    }

    const digits = text.split(EMPTY_STRING);
    setDataOTP(digits);
  };

  const handleRefInput = (element: HTMLInputElement, index: number) => {
    inputRefs.current[index] = element;
  };

  const handleSubmit = () => {
    const hasError = validateOTP(dataOTP);
    setHasErrorMessage(hasError);
    if (hasError) return;

    const valueOTP = dataOTP.join(EMPTY_STRING);

    const payload = { code: valueOTP };

    loadingContext?.show();

    dispatch(verifyCode(payload))
      .unwrap()
      .then((res) => {
        navigate(authRouteAbsolute.newPassword, {
          state: payload,
        });
      })
      .catch((error) => {})
      .finally(() => {
        loadingContext?.hide();
      });
  };

  const handleResend = () => {
    if (!state?.email) return;

    const payload: IForgotPasswordPayload = {
      email: state.email,
    };

    loadingContext?.show();
    dispatch(forgotPassword(payload))
      .unwrap()
      .then((res) => {
        if (!res) return;
      })
      .catch((error) => {})
      .finally(() => {
        loadingContext?.hide();
      });
  };

  const validateOTP = (otpInput: string[]) => {
    return otpInput.some((otp) => otp === EMPTY_STRING);
  };
  //#endregion Handle Function

  return (
    <div id='verifyCode' className={cx('container')}>
      <h3 className={cx('title')}>{t('verify_code_title')}</h3>
      <p className={cx('desc')}>
        {state?.email
          ? t('verify_code_desc', {
              email: state.email || EMPTY_STRING,
            })
          : EMPTY_STRING}
      </p>
      <div className={cx('formVerifyCode')}>
        <div className={cx('verifyCodeWrap')}>
          {dataOTP.map((value, index) => (
            <input
              key={index}
              ref={(element: HTMLInputElement) => handleRefInput(element, index)}
              className={cx('inputVerifyCode')}
              type='text'
              maxLength={1}
              value={value}
              onChange={handleChangeValue}
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
              onPaste={handlePaste}
            />
          ))}
        </div>

        {hasErrorMessage && <span className={cx('errMessage')}>{t('auth_verify_code_validate_err_message')}</span>}
      </div>

      <BaseButton
        text={t('verify_code_button_reset_password')}
        width={'100%'}
        typeStyle={ButtonTypeEnum.PRIMARY}
        onClick={handleSubmit}
      />
      <p className={cx('resendText')}>
        {t('verify_code_otp_resend_text')}
        <button className={cx('resendLink')} onClick={handleResend}>
          {t('verify_code_otp_resend_link')}
        </button>
      </p>
    </div>
  );
};

export default VerifyCode;
