// Libs
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { DEFAULT_FILTER_HEIGHT_BTN, DEFAULT_FILTER_WIDTH_BTN } from '~/utils/constants/component';
import { IFilterValueChange } from '~/utils/interface/common';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './BaseFilter.module.scss';

type ChildrenActions<T> = {
  valueFilter: T | undefined;
  onChange: (data: IFilterValueChange<T>) => void;
};

type Props<T> = {
  widthBtn?: string | number;
  heightBtn?: string | number;
  valueFilter?: T;
  defaultValue?: T;
  children: (actions: ChildrenActions<T>) => React.ReactNode;
  onApply?: (appliedFilter: T | undefined) => void;
  onClear?: () => void;
};

const cx = classNames.bind(styles);

const BaseFilter = <T,>(props: Props<T>) => {
  //#region Destructuring Props
  const {
    children,
    widthBtn = DEFAULT_FILTER_WIDTH_BTN,
    heightBtn = DEFAULT_FILTER_HEIGHT_BTN,
    valueFilter,
    defaultValue,
    onApply,
    onClear,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isDisableApply, setIsDisableApply] = useState<boolean>(false);
  const [rootValueFilter, setRootValueFilter] = useState<T | undefined>(defaultValue);
  const [tempValueFilter, setTempValueFilter] = useState<T | undefined>(defaultValue);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!valueFilter) {
      return;
    }

    const isDisableBtnApply = JSON.stringify(tempValueFilter) === JSON.stringify(rootValueFilter);

    setIsDisableApply(isDisableBtnApply);
  }, [tempValueFilter, rootValueFilter]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleClear = (closeFn: Function) => {
    setRootValueFilter(defaultValue);
    onClear && onClear();
    closeFn();
  };

  const handleApply = (closeFn: Function) => {
    setRootValueFilter(tempValueFilter);
    onApply && onApply(tempValueFilter);
    closeFn();
  };

  const onValueChange = (data: IFilterValueChange<T>) => {
    const { name, value } = data;
    const updatedValueFilter = {
      ...tempValueFilter,
      [name]: value,
    };
    setTempValueFilter(updatedValueFilter as T);
  };

  const handleClose = (closeFn: Function) => {
    setTempValueFilter(rootValueFilter);
    closeFn();
  };
  //#endregion Handle Function

  return (
    <div id='baseFilterComponent'>
      <Popover className='relative'>
        <PopoverButton className={cx('btnGroup')} style={{ height: heightBtn, width: widthBtn }}>
          <img src={icons.commonIconSort} alt={t('common_img_text_alt')} className={cx('btnImg')} />
          <div className={cx('btnLabel')}>{t('common_text_filter')}</div>
        </PopoverButton>

        <PopoverPanel transition anchor={{ to: 'bottom end', gap: '10px' }} className={cx('popupFilterContainer')}>
          {({ open, close }) => {
            if (!open) handleClose(close);
            return (
              <>
                <h3 className={cx('popTitle')}>{t('common_text_filter')}</h3>

                <div className={cx('popContent')}>
                  {children ? (
                    children({
                      valueFilter: tempValueFilter,
                      onChange: onValueChange,
                    })
                  ) : (
                    <p className={cx('emptyData')}>{t('common_empty_data')}</p>
                  )}
                </div>

                <div className={cx('popBtnGroup')}>
                  <button className={cx('popBtnApply')} disabled={isDisableApply} onClick={() => handleApply(close)}>
                    {t('common_component_btn_filter_apply')}
                  </button>
                </div>
              </>
            );
          }}
        </PopoverPanel>
      </Popover>
    </div>
  );
};

export default BaseFilter;
