// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
// Components, Layouts, Pages
import { MultipleCheckbox, BaseTextarea, RadioCheckbox } from '~/components';
// Others
import {
  medicalConditionFunctionalLimitationOptions,
  medicalConditionOtherOptions,
  optionsMedicalConditionHearing,
  optionsMedicalConditionSpeech,
  optionsMedicalConditionSwallowing,
  optionsMedicalConditionVision,
} from '~/mockData';
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './MedicalCondition.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const MedicalCondition = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='medicalCondition' className={cx('container')}>
      <Controller
        name='medicalCondition.chronicAcuteConditions'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseTextarea
            height={90}
            id='chronicAcuteCondition'
            label={t('care_plan_medical_condition_list_any_chronic_or_acute_condition')}
            value={value || EMPTY_STRING}
            onChange={onChange}
            messageError={errors.medicalCondition?.chronicAcuteConditions?.message}
          />
        )}
      />

      <Controller
        name='medicalCondition.hearing'
        control={control}
        render={({ field: { value, onChange } }) => (
          <RadioCheckbox
            id='medicalConditionHearing'
            name='medicalConditionHearing'
            label={t('care_plan_medical_condition_hearing_label')}
            options={optionsMedicalConditionHearing}
            type='flex-col'
            value={value || []}
            onChange={onChange}
            isBordered
            messageError={errors.medicalCondition?.hearing?.message}
          />
        )}
      />

      <Controller
        name='medicalCondition.speech'
        control={control}
        render={({ field: { value, onChange } }) => (
          <RadioCheckbox
            id='medicalConditionSpeech'
            name='medicalConditionSpeech'
            label={t('care_plan_medical_condition_speech_label')}
            options={optionsMedicalConditionSpeech}
            type='flex-col'
            value={value || []}
            onChange={onChange}
            isBordered
            messageError={errors.medicalCondition?.speech?.message}
          />
        )}
      />

      <Controller
        name='medicalCondition.vision'
        control={control}
        render={({ field: { value, onChange } }) => (
          <RadioCheckbox
            id='medicalConditionVision'
            name='medicalConditionVision'
            label={t('care_plan_medical_condition_vision_label')}
            options={optionsMedicalConditionVision}
            type='flex-col'
            value={value || []}
            onChange={onChange}
            isBordered
            messageError={errors.medicalCondition?.vision?.message}
          />
        )}
      />

      <Controller
        name='medicalCondition.swallowing'
        control={control}
        render={({ field: { value, onChange } }) => (
          <RadioCheckbox
            id='medicalConditionSwallowing'
            name='medicalConditionSwallowing'
            label={t('care_plan_medical_condition_swallowing_label')}
            options={optionsMedicalConditionSwallowing}
            type='flex-col'
            value={value || []}
            onChange={onChange}
            isBordered
            messageError={errors.medicalCondition?.swallowing?.message}
          />
        )}
      />

      <div className={cx('medicalConditionItem')}>
        <label className={cx('label')}>{t('care_plan_medical_condition_other_label')}</label>
        <div className={cx('medicalConditionControl')}>
          <Controller
            name='medicalCondition.other'
            control={control}
            render={({ field: { value, onChange } }) => (
              <MultipleCheckbox
                type='flex-col'
                id='others'
                options={medicalConditionOtherOptions}
                value={value || []}
                onChange={onChange}
                messageError={errors.medicalCondition?.other?.message}
              />
            )}
          />
        </div>
      </div>

      <Controller
        name='medicalCondition.functionalLimitations'
        control={control}
        render={({ field: { value, onChange } }) => (
          <MultipleCheckbox
            type='flex-col'
            id='functionalLimitation'
            options={medicalConditionFunctionalLimitationOptions}
            label={t('care_plan_medical_condition_functional_limitations_label')}
            col={3}
            value={value || []}
            onChange={onChange}
            messageError={errors.medicalCondition?.functionalLimitations?.message}
          />
        )}
      />
    </div>
  );
};

export default MedicalCondition;
