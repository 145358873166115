// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import { getListCareAlert } from '~/thunks/careAlert/careAlertThunk';

export interface CareALertState {
  isRefreshCareAlert: boolean;
}

const initialState: CareALertState = {
  isRefreshCareAlert: false,
};

const careAlertSlice = createSlice({
  name: 'careAlertState',
  initialState: initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshCareAlert = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list Caregiver
    builder
      .addCase(getListCareAlert.pending, (state) => {})
      .addCase(getListCareAlert.fulfilled, (state) => {})
      .addCase(getListCareAlert.rejected, (state) => {});
  },
});

// Actions
export const careAlertActions = careAlertSlice.actions;

// Selectors
export const selectIsRefreshCareAlertList = (state: RootState) => state.careAlertState.isRefreshCareAlert;

// Reducer
const careAlertReducer = careAlertSlice.reducer;
export default careAlertReducer;
