// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { CheckboxSingle } from '~/components';
// Others
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
// Styles, images, icons
import styles from './CarePlanQuickLink.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CarePlanQuickLink = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { control } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='carePlanQuickLinkComponent' className={cx('container')}>
      <Controller
        name={'quickLink.MATCH_CRITERIA'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_match_criteria')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.CARE_NEEDS'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_care_needs')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.DEMOGRAPHICS'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_demographics')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.ADLS'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_adls')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.IADLS'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_iadls')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.ICD9_CODES'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_icd9_codes')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.ICD10_CODES'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_icd10_codes')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.UB04_CODES'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_ub_04_codes')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.MEDICAL_CONDITIONS'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_medical_conditions')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.MENTAL_BEHAVIOR_CONDITIONS'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_mental_behavior')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.ALLERGIES'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_allergies')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.ELIMINATION'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_elimination')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.MEDICATIONS'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_medications')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.AMBULATION'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_ambulation')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.TRANSFERS'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_transfers')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.BATHING_GROOMING'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_bathing_grooming')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.MEALS'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_meals')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.DRIVING'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_driving')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.EXERCISE'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_exercise')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.SLEEP_PATTERNS'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_sleep_patterns')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.EQUIPMENT'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_equipment')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.PET_CARE'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_pet_care')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />

      <Controller
        name={'quickLink.DAILY_ROUTINE'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            label={t('care_plan_quick_link_title_daily_routine')}
            value={value}
            onChange={(checked: boolean, name?: string) => onChange(checked)}
          />
        )}
      />
    </div>
  );
};

export default CarePlanQuickLink;
