// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
// Components, Layouts, Pages
import { BaseSelectSingle } from '~/components';
// Others
import { optionsDropdownChart } from '~/mockData';
import { configDonutChart } from '~/utils/constants/configChart';
import { DEFAULT_HEIGHT_DONUT_CHART, DEFAULT_NUMBER_ZERO, DEFAULT_WIDTH_DONUT_CHART } from '~/utils/constants/common';
// Styles, images, icons
import styles from './DonutChart.module.scss';
import { icons } from '~/assets';

type Props = {
  data: ApexOptions['series'];
  title: string;
  labels: string[];
  colors: string[];
  width?: number;
  height?: number;
};

const cx = classNames.bind(styles);

const DonutChart = (props: Props) => {
  //#region Destructuring Props
  const { title, data, labels, colors, width = DEFAULT_WIDTH_DONUT_CHART, height = DEFAULT_HEIGHT_DONUT_CHART } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [configChart, setConfigChart] = useState<ApexOptions | null>(null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!data) return;

    const config = configDonutChart({
      labels: labels,
      colors: colors,
    });

    setConfigChart(config);
  }, [data, labels]);
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='donutChartComponent' className={cx('container')}>
      <div className={cx('headerChart')}>
        <div className={cx('title')}>{title}</div>
        <BaseSelectSingle options={optionsDropdownChart} icon={icons.commonIconArrowBottom} minWidth={73} />
      </div>

      {data && data.length > DEFAULT_NUMBER_ZERO ? (
        configChart && (
          <div className={cx('donutChart')}>
            <ReactApexChart type='donut' series={data} options={configChart} width={width} height={height} />
          </div>
        )
      ) : (
        <div className={cx('noData')}>{t('common_empty_data')}</div>
      )}

      {data && data.length > DEFAULT_NUMBER_ZERO && (
        <div className={cx('footerChart')}>
          <img src={icons.commonIconStar} alt={t('common_img_text_alt')} />
          <span className={cx('serviceForRating')}>{t('dashboard_donut_chart_service_for_rating')}</span>
        </div>
      )}
    </div>
  );
};

export default DonutChart;
