// Libs
// Others
import { urlApiCrm } from '~/utils/constants/api';
import axiosClient from '~/apis/axiosClient';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import { IListProspect, IListQueryParamsProspect } from '~/utils/interface/crm/prospect';

const prospectApi = {
  getListProspect(params: IListQueryParamsProspect) {
    const url = `${urlApiCrm.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IListProspect[]>>>(url, { params });
  },
};

export default prospectApi;
