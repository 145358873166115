// Libs
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '~/redux/store';
// Others
import { changeStatusAccount, getDetailProfile, getDetailUser, getListAssignee } from '~/thunks/user/userThunk';
import { IUserAccount } from '~/utils/interface/user';
import { QuickBooksStatusEnum } from '~/utils/enum';

export interface UserState {
  userProfile: IUserAccount;
  isRefresh: boolean;
}

const initialState: UserState = {
  userProfile: {} as IUserAccount,
  isRefresh: false,
};

const userSlice = createSlice({
  name: 'userState',
  initialState: initialState,
  reducers: {
    connectedQuickBook(state) {
      state.userProfile.quickBook.status = QuickBooksStatusEnum.CONNECTED;
    },
    disconnectedQuickBook(state) {
      state.userProfile.quickBook.status = QuickBooksStatusEnum.DISCONNECTED;
    },
    setIsRefresh(state, action) {
      state.isRefresh = action.payload;
    },
  },
  extraReducers(builder) {
    // Get Detail Account User
    builder
      .addCase(getDetailUser.pending, (state) => {})
      .addCase(getDetailUser.fulfilled, (state) => {})
      .addCase(getDetailUser.rejected, (state) => {});

    // Get profile
    builder
      .addCase(getDetailProfile.pending, (state) => {
        state.userProfile = {} as IUserAccount;
      })
      .addCase(getDetailProfile.fulfilled, (state, action) => {
        state.userProfile = action?.payload?.data;
      })
      .addCase(getDetailProfile.rejected, (state) => {});

    // Change Status Account
    builder
      .addCase(changeStatusAccount.pending, (state) => {})
      .addCase(changeStatusAccount.fulfilled, (state) => {})
      .addCase(changeStatusAccount.rejected, (state) => {});

    // Get List Assignee
    builder
      .addCase(getListAssignee.pending, (state) => {})
      .addCase(getListAssignee.fulfilled, (state) => {})
      .addCase(getListAssignee.rejected, (state) => {});
  },
});

// Actions
export const userActions = userSlice.actions;

export const selectUserProfile = (state: RootState) => state.userState.userProfile;
export const selectUserProfileRefresh = (state: RootState) => state.userState.isRefresh;

// Reducer
const userReducer = userSlice.reducer;
export default userReducer;
