// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
// Components, Layouts, Pages
import { BaseUploadFile } from '~/components';
// Others
import { IUploadFile } from '~/utils/interface/common';
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { ALLOWED_FILE_TYPE, DEFAULT_NUMBER_ZERO } from '~/utils/constants/common';
import { fileActions } from '~/thunks/file/fileSlice';
import { uploadDocument } from '~/thunks/file/fileThunk';
import { TypeFilesDocumentEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './AddDocument.module.scss';
import { icons } from '~/assets';

type Props = {
  accountId?: string;
};

const cx = classNames.bind(styles);

const AddDocument = (props: Props) => {
  //#region Destructuring Props
  const { accountId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [fileList, setFileList] = useState<IUploadFile[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (fileList.length > DEFAULT_NUMBER_ZERO) {
      handleUploadChange(fileList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleUploadChange = (uploadList: IUploadFile[]) => {
    if (!accountId) return;

    const newFileList: File[] = [];
    uploadList.forEach((uploadItem: IUploadFile) => {
      if (uploadItem?.file) {
        newFileList.push(uploadItem.file);
      }
    });

    if (newFileList.length === DEFAULT_NUMBER_ZERO) return;

    const formData = new FormData();
    formData.append('businessId', accountId);
    formData.append('type', TypeFilesDocumentEnum.CLIENT_DOCUMENT);

    newFileList.forEach((file: File) => {
      formData.append('files', file);
    });

    loading?.show();

    dispatch(uploadDocument(formData))
      .unwrap()
      .then((response) => {
        setFileList([]);
        dispatch(fileActions.setRefreshList(true));
      })
      .catch((error) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const onHandleChangeFile = (uploadList: IUploadFile[]) => {
    setFileList(uploadList);
  };
  //#endregion Handle Function

  return (
    <div id='addDocumentComponent' className={cx('addDocumentComponent')}>
      <div className={cx('headTitle')}>
        <h3 className={cx('title')}>{t('add_document_title')}</h3>
        <button>
          <img className={cx('icon')} src={icons.commonIconArrowBottom} alt={t('common_img_text_alt')} />
        </button>
      </div>

      <div className={cx('content')}>
        <BaseUploadFile
          key={fileList.length}
          label={t('common_component_upload_document_label')}
          height={64}
          defaultFileList={fileList}
          onChange={onHandleChangeFile}
          acceptFile={ALLOWED_FILE_TYPE}
          id='documents'
        />
      </div>
    </div>
  );
};

export default AddDocument;
