// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
// Components, Layouts, Pages
import { MenuItem, ReactPortal } from '~/components';
// Others
import { CURRENT, DEFAULT_HEIGHT_SUB_MENU, DEFAULT_NUMBER_ZERO, PIXELS } from '~/utils/constants/common';
import { HTMLEventEnum } from '~/utils/enum';
import { IChildRouteModel } from '~/utils/interface/common';
// Styles, images, icons
import styles from './DropdownMenu.module.scss';

type Props = {
  subMenus: IChildRouteModel[];
  isDropdownMenu: boolean;
  depthLevel: number;
};

const cx = classNames.bind(styles);

const DropdownMenu = forwardRef<HTMLLIElement, Props>((props: Props, ref) => {
  //#region Destructuring Props
  const { subMenus, isDropdownMenu, depthLevel } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const depthLevelDropdown = useMemo(() => {
    return depthLevel + 1;
  }, [depthLevel]);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [position, setPosition] = useState({ top: DEFAULT_NUMBER_ZERO, left: DEFAULT_NUMBER_ZERO });
  const [openSubmenuIndex, setOpenSubmenuIndex] = useState<number | null>(null);
  const [isPositionReady, setIsPositionReady] = useState(false);
  //#endregion Declare State

  //#region Implement Hook
  const updatePosition = useCallback(() => {
    if (ref && CURRENT in ref && ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setPosition({ top: rect.bottom, left: rect.left });

      if (depthLevel > DEFAULT_NUMBER_ZERO) {
        setPosition({ top: rect.top - DEFAULT_HEIGHT_SUB_MENU, left: rect.right });
      }

      setIsPositionReady(true);
    }
  }, [ref]);

  useEffect(() => {
    if (isDropdownMenu) {
      updatePosition();
      window.addEventListener(HTMLEventEnum.RESIZE, updatePosition);
      window.addEventListener(HTMLEventEnum.SCROLL, updatePosition);
    } else {
      window.removeEventListener(HTMLEventEnum.RESIZE, updatePosition);
      window.removeEventListener(HTMLEventEnum.SCROLL, updatePosition);
    }

    return () => {
      window.removeEventListener(HTMLEventEnum.RESIZE, updatePosition);
      window.removeEventListener(HTMLEventEnum.SCROLL, updatePosition);
    };
  }, [isDropdownMenu, updatePosition]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleMouseEnter = useCallback(
    (index: number) => {
      setOpenSubmenuIndex(index);
      setIsPositionReady(false);
      updatePosition();
    },
    [updatePosition]
  );

  //#endregion Handle Function

  return (
    isPositionReady && (
      <ReactPortal wrapperId='subMenuPortal'>
        <div
          id='dropdownMenu'
          className={cx('subMenu', depthLevel > DEFAULT_NUMBER_ZERO && 'dropdownSubMenu')}
          style={{
            top: `${position.top}${PIXELS}`,
            left: `${position.left}${PIXELS}`,
          }}
        >
          <ul className={cx('dropdownMenu')}>
            {subMenus.map((submenu, index) => (
              <MenuItem
                menu={submenu}
                key={index}
                depthLevel={depthLevelDropdown}
                menuIndex={index}
                isDropdownMenu={openSubmenuIndex === index}
                onMouseEnter={handleMouseEnter}
              />
            ))}
          </ul>
        </div>
      </ReactPortal>
    )
  );
});

export default DropdownMenu;
