// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
// Components, Layouts, Pages
import { BaseButton, TextEditor, BaseInput, Modal, UploadFileSendMail, Loading } from '~/components';
// Others
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { sendEmailToClient } from '~/thunks/crm/clients/clientsThunk';
import { IFormSendMail, IQuerySendEmailToClient } from '~/utils/interface/crm/clients';
import { ButtonTypeEnum, ModeTextEditorEnum } from '~/utils/enum';
import {
  ACCEPT_ALL_FILES,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  FILE_UPLOAD_LIMIT_50_MB,
  NEGATIVE_ONE,
  RegExp,
} from '~/utils/constants/common';
// Styles, images, icons
import styles from './FormSendEmailModal.module.scss';

type Props<T> = {
  dataDefault: T | null;
  isOpen: boolean;
  onClose: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      from: yup
        .string()
        .required(t('form_send_mail_from_required'))
        .email(t('common_validate_invalid_email'))
        .matches(RegExp.EMAIL, t('common_validate_invalid_email')),
      to: yup
        .string()
        .required(t('form_send_mail_to_required'))
        .email(t('common_validate_invalid_email'))
        .matches(RegExp.EMAIL, t('common_validate_invalid_email')),
      cc: yup
        .string()
        .optional()
        .test('isValidEmail', t('common_validate_invalid_email'), (value) => {
          if (value && !RegExp.EMAIL.test(value)) return false;
          return true;
        }),
      bcc: yup
        .string()
        .email(t('common_validate_invalid_email'))
        .optional()
        .test('isValidEmail', t('common_validate_invalid_email'), (value) => {
          if (value && !RegExp.EMAIL.test(value)) return false;
          return true;
        }),
      subject: yup
        .string()
        .transform((value) => value.trim())
        .required(t('form_send_mail_subject_required')),
      content: yup.string().required(t('form_send_mail_content_required')),
      files: yup
        .array()
        .of(
          yup.mixed<File>().test('fileSize', t('form_send_mail_files_size_limit'), (value) => {
            if (value && value?.size <= FILE_UPLOAD_LIMIT_50_MB) return true;
            return false;
          })
        )
        .nullable()
        .optional(),
    })
    .required();
};

const defaultValues: IFormSendMail = {
  from: EMPTY_STRING,
  to: EMPTY_STRING,
  cc: EMPTY_STRING,
  bcc: EMPTY_STRING,
  subject: EMPTY_STRING,
  content: EMPTY_STRING,
  files: null,
};

const FormSendEmailModal = <T extends { id: string; email: string }>(props: Props<T>) => {
  //#region Destructuring Props
  const { dataDefault, isOpen, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm<IFormSendMail>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });

  //#endregion Declare Hook

  //#region Selector
  const profile = useAppSelector((state) => state.userState.userProfile);
  //#endregion Selector

  //#region Declare State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!profile || !profile?.email) return;

    setValue('from', profile?.email);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (!dataDefault || !dataDefault?.email) return;

    setValue('to', dataDefault?.email);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDefault]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleCloseFormSendMail = () => {
    onClose();
    reset(defaultValues);
  };

  const handleSendEmail = async (data: IFormSendMail) => {
    if (!dataDefault || !dataDefault?.id) return;

    const formData = new FormData();

    const formDataFields = {
      from: data?.from || EMPTY_STRING,
      to: data?.to || EMPTY_STRING,
      cc: data?.cc || EMPTY_STRING,
      bcc: data?.bcc || EMPTY_STRING,
      subject: data?.subject || EMPTY_STRING,
      content: data?.content || EMPTY_STRING,
    };

    Object.entries(formDataFields).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });

    if (data?.files) {
      data?.files?.forEach((file) => {
        file && formData.append('files', file);
      });
    }

    const payload: IQuerySendEmailToClient = {
      clientId: dataDefault?.id,
      body: formData,
    };

    setIsLoading(true);

    dispatch(sendEmailToClient(payload))
      .unwrap()
      .then((res) => {
        handleCloseFormSendMail();
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  //#endregion Handle Function

  return (
    <Modal isOpen={isOpen} onClose={handleCloseFormSendMail} title={t('form_send_mail_title')}>
      <form id='formSendEmailModalComponent' className={cx('formContainer')} onSubmit={handleSubmit(handleSendEmail)}>
        <div className={cx('formBody')}>
          <Controller
            name='from'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='from'
                value={value}
                label={t('form_send_mail_from_label')}
                //placeholder={t('common_email_placeholder')}
                onChange={onChange}
                required
                messageError={errors.from?.message}
              />
            )}
          />

          <div className={cx('threeCol')}>
            <Controller
              name='to'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='to'
                  value={value}
                  label={t('form_send_mail_to_label')}
                  //placeholder={t('common_email_placeholder')}
                  onChange={onChange}
                  required
                  messageError={errors.to?.message}
                />
              )}
            />

            <Controller
              name='cc'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='cc'
                  value={value}
                  label={t('form_send_mail_cc_label')}
                  //placeholder={t('common_email_placeholder')}
                  onChange={onChange}
                  messageError={errors.cc?.message}
                />
              )}
            />

            <Controller
              name='bcc'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='bcc'
                  value={value}
                  label={t('form_send_mail_bcc_label')}
                  //placeholder={t('common_email_placeholder')}
                  onChange={onChange}
                  messageError={errors.bcc?.message}
                />
              )}
            />
          </div>

          <Controller
            name='subject'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='subject'
                value={value}
                label={t('form_send_mail_subject_label')}
                onChange={onChange}
                required
                messageError={errors.subject?.message}
              />
            )}
          />

          <Controller
            name='content'
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextEditor
                mode={ModeTextEditorEnum.CREATE}
                height={200}
                label={t('form_send_mail_content_label')}
                value={value}
                onChange={(textEditor: string) => onChange(textEditor)}
                required
                errorMessage={errors.content?.message}
              />
            )}
          />

          <Controller
            name='files'
            control={control}
            render={({ field: { value, onChange } }) => {
              const errorMessages =
                errors.files && Array.isArray(errors.files) && errors.files.length > DEFAULT_NUMBER_ZERO
                  ? [...new Set(errors.files.filter((error) => error?.message).map((error) => error?.message))].join(
                      ', '
                    )
                  : EMPTY_STRING;

              const errorIndexes =
                errors.files && Array.isArray(errors.files) && errors.files.length > 0
                  ? errors.files
                      .map((error, index) => (error?.message ? index : NEGATIVE_ONE))
                      .filter((index) => index !== NEGATIVE_ONE)
                  : [];

              return (
                <UploadFileSendMail
                  multiple
                  accept={ACCEPT_ALL_FILES}
                  onFilesChange={(files: File[]) => {
                    onChange(files);
                    trigger('files');
                  }}
                  errorMessage={errorMessages}
                  errorIndex={errorIndexes}
                />
              );
            }}
          />
        </div>

        <div className={cx('formFooter')}>
          <BaseButton type='button' text={t('common_cancel_label')} width={65} onClick={handleCloseFormSendMail} />

          <BaseButton type='submit' text={t('common_save_label')} typeStyle={ButtonTypeEnum.PRIMARY} width={80} />
        </div>
      </form>

      {isLoading && <Loading />}
    </Modal>
  );
};

export default FormSendEmailModal;
