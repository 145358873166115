import { Views } from 'react-big-calendar';
import {
  EmployeeMock,
  ICareAlerts,
  ICaregiver,
  IRequestApplicant,
  ICaregiverReferral,
  IEmployeeDetail,
  IOptionTimeZone,
  IActivity,
  IHr,
  ISkill,
  ICertification,
  IListReferralSource,
  IListSummary,
  IMockListCareAlert,
  IInvoice,
} from '~/mockData/mockInterface';
import {
  AmbulationAidsEnum,
  AmbulationArmsOrHandsEnum,
  CLientStatusEnum,
  CarePlanPrognosisEnum,
  DayOfWeekEnum,
  MedicalConditionEnum,
  MessageStatusEnum,
  MessageTypeEnum,
  MonthlyOccurrenceEnum,
  RecurrenceEnum,
  StatusEnum,
  StorageEnum,
  TaskIncludeInEnum,
} from '~/utils/enum';
import { getTimezone } from '~/utils/helper';
import {
  IAddAssignee,
  IBaseOption,
  INumberOptionMultiple,
  IOptionCheckboxMultiple,
  IOptionRadioCheckbox,
  IUserInfo,
} from '~/utils/interface/common';
import { IConversation, IConversationMember } from '~/utils/interface/conversation';
import { ICustomerList, IStatisticCard } from '~/utils/interface/dashboard';
import { IListDocument } from '~/utils/interface/file';
import { IMessage } from '~/utils/interface/message';
import { IScheduleEvent, IScheduleTimelineData } from '~/utils/interface/schedule';
import { IFormEditTimeClock } from '~/utils/interface/timeClock';
import { TCalendarViewMode } from '~/utils/type/schedule';

const accountId = localStorage.getItem(StorageEnum.USER_ID);

export const employeeData: EmployeeMock[] = [
  {
    id: '61',
    firstName: 'Robert',
    lastName: 'Miller',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'robert.miller@gmail.com',
    joinDate: 'June 24, 2024',
    role: '00002',
  },
  {
    id: '62',
    firstName: 'Son',
    lastName: 'HunMin',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'son.min@gmail.com',
    joinDate: 'June 21, 2024',
    role: '00001',
  },
  {
    id: '63',
    firstName: 'Alex',
    lastName: 'Na',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'alex.na@gmail.com',
    joinDate: 'June 12, 2024',
    role: '00003',
  },
];

export const userInfo: IUserInfo = {
  avatarUrl:
    'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
};

export const requestApplicantData: IRequestApplicant[] = [
  {
    id: '61',
    firstName: 'Robert',
    lastName: 'Miller',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'robert.miller@gmail.com',
    source: 'Referral',
    address: 'Manchester, Kentucky 39495',
    phoneNumber: '01234 4567 881',
  },
  {
    id: '62',
    firstName: 'Son',
    lastName: 'HunMin',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    source: 'Prospects',
    email: 'alex.na@gmail.com',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '63',
    firstName: 'Alex',
    lastName: 'Na',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'alex.na@gmail.com',
    source: 'Referral',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '61',
    firstName: 'Robert',
    lastName: 'Miller',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'robert.miller@gmail.com',
    source: 'Referral',
    address: 'Manchester, Kentucky 39495',
    phoneNumber: '01234 4567 881',
  },
  {
    id: '62',
    firstName: 'Son',
    lastName: 'HunMin',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    source: 'Prospects',
    email: 'alex.na@gmail.com',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '63',
    firstName: 'Alex',
    lastName: 'Na',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'alex.na@gmail.com',
    source: 'Referral',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '61',
    firstName: 'Robert',
    lastName: 'Miller',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'robert.miller@gmail.com',
    source: 'Referral',
    address: 'Manchester, Kentucky 39495',
    phoneNumber: '01234 4567 881',
  },
  {
    id: '62',
    firstName: 'Son',
    lastName: 'HunMin',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    source: 'Prospects',
    email: 'alex.na@gmail.com',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '63',
    firstName: 'Alex',
    lastName: 'Na',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'alex.na@gmail.com',
    source: 'Referral',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '61',
    firstName: 'Robert',
    lastName: 'Miller',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'robert.miller@gmail.com',
    source: 'Referral',
    address: 'Manchester, Kentucky 39495',
    phoneNumber: '01234 4567 881',
  },
  {
    id: '62',
    firstName: 'Son',
    lastName: 'HunMin',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    source: 'Prospects',
    email: 'alex.na@gmail.com',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '63',
    firstName: 'Alex',
    lastName: 'Na',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'alex.na@gmail.com',
    source: 'Referral',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '61',
    firstName: 'Robert',
    lastName: 'Miller',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'robert.miller@gmail.com',
    source: 'Referral',
    address: 'Manchester, Kentucky 39495',
    phoneNumber: '01234 4567 881',
  },
  {
    id: '62',
    firstName: 'Son',
    lastName: 'HunMin',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    source: 'Prospects',
    email: 'alex.na@gmail.com',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '63',
    firstName: 'Alex',
    lastName: 'Na',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'alex.na@gmail.com',
    source: 'Referral',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '61',
    firstName: 'Robert',
    lastName: 'Miller',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'robert.miller@gmail.com',
    source: 'Referral',
    address: 'Manchester, Kentucky 39495',
    phoneNumber: '01234 4567 881',
  },
  {
    id: '62',
    firstName: 'Son',
    lastName: 'HunMin',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    source: 'Prospects',
    email: 'alex.na@gmail.com',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '63',
    firstName: 'Alex',
    lastName: 'Na',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'alex.na@gmail.com',
    source: 'Referral',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '61',
    firstName: 'Robert',
    lastName: 'Miller',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'robert.miller@gmail.com',
    source: 'Referral',
    address: 'Manchester, Kentucky 39495',
    phoneNumber: '01234 4567 881',
  },
  {
    id: '62',
    firstName: 'Son',
    lastName: 'HunMin',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    source: 'Prospects',
    email: 'alex.na@gmail.com',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '63',
    firstName: 'Alex',
    lastName: 'Na',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'alex.na@gmail.com',
    source: 'Referral',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '61',
    firstName: 'Robert',
    lastName: 'Miller',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'robert.miller@gmail.com',
    source: 'Referral',
    address: 'Manchester, Kentucky 39495',
    phoneNumber: '01234 4567 881',
  },
  {
    id: '62',
    firstName: 'Son',
    lastName: 'HunMin',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    source: 'Prospects',
    email: 'alex.na@gmail.com',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '63',
    firstName: 'Alex',
    lastName: 'Na',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'alex.na@gmail.com',
    source: 'Referral',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '61',
    firstName: 'Robert',
    lastName: 'Miller',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'robert.miller@gmail.com',
    source: 'Referral',
    address: 'Manchester, Kentucky 39495',
    phoneNumber: '01234 4567 881',
  },
  {
    id: '62',
    firstName: 'Son',
    lastName: 'HunMin',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    source: 'Prospects',
    email: 'alex.na@gmail.com',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '63',
    firstName: 'Alex',
    lastName: 'Na',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'alex.na@gmail.com',
    source: 'Referral',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '61',
    firstName: 'Robert',
    lastName: 'Miller',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'robert.miller@gmail.com',
    source: 'Referral',
    address: 'Manchester, Kentucky 39495',
    phoneNumber: '01234 4567 881',
  },
  {
    id: '62',
    firstName: 'Son',
    lastName: 'HunMin',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    source: 'Prospects',
    email: 'alex.na@gmail.com',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '63',
    firstName: 'Alex',
    lastName: 'Na',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'alex.na@gmail.com',
    source: 'Referral',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '61',
    firstName: 'Robert',
    lastName: 'Miller',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'robert.miller@gmail.com',
    source: 'Referral',
    address: 'Manchester, Kentucky 39495',
    phoneNumber: '01234 4567 881',
  },
  {
    id: '62',
    firstName: 'Son',
    lastName: 'HunMin',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    source: 'Prospects',
    email: 'alex.na@gmail.com',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
  {
    id: '63',
    firstName: 'Alex',
    lastName: 'Na',
    avatarUrl: 'https://localhost:5000/api/assets/1719384952149_avatar_default.png',
    email: 'alex.na@gmail.com',
    source: 'Referral',
    phoneNumber: '01234 4567 881',
    address: 'Manchester, Kentucky 39495',
  },
];

export const caregiverCareAlertsData: ICareAlerts[] = [];

export const caregiverCareAlertsShowAllData: ICareAlerts[] = [];

export const caregiverShowAllData: ICaregiver[] = [
  {
    id: '1',
    avatarUrl: 'https://i.pravatar.cc/300',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: false,
  },
  {
    id: '2',
    avatarUrl: 'https://i.pravatar.cc/301',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: true,
  },
  {
    id: '3',
    avatarUrl: 'https://i.pravatar.cc/302',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: false,
  },
  {
    id: '4',
    avatarUrl: 'https://i.pravatar.cc/101',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: false,
  },
  {
    id: '5',
    avatarUrl: 'https://i.pravatar.cc/305',
    firstName: 'Alex',
    lastName: 'Na',
    isOnline: true,
  },
  {
    id: '6',
    avatarUrl: 'https://i.pravatar.cc/306',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: false,
  },
  {
    id: '7',
    avatarUrl: 'https://i.pravatar.cc/307',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: false,
  },
  {
    id: '8',
    avatarUrl: 'https://i.pravatar.cc/308',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: true,
  },
  {
    id: '9',
    avatarUrl: 'https://i.pravatar.cc/321',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: true,
  },
  {
    id: '10',
    avatarUrl: 'https://i.pravatar.cc/221',
    firstName: 'Alex',
    lastName: 'Na',
    isOnline: true,
  },
  {
    id: '11',
    avatarUrl: 'https://i.pravatar.cc/371',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: false,
  },
  {
    id: '12',
    avatarUrl: 'https://i.pravatar.cc/911',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: true,
  },
  {
    id: '13',
    avatarUrl: 'https://i.pravatar.cc/213',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: true,
  },
  {
    id: '14',
    avatarUrl: 'https://i.pravatar.cc/113',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: true,
  },
  {
    id: '15',
    avatarUrl: 'https://i.pravatar.cc/112',
    firstName: 'Oleo',
    lastName: 'Na',
    isOnline: true,
  },
  {
    id: '16',
    avatarUrl: 'https://i.pravatar.cc/119',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: true,
  },
  {
    id: '17',
    avatarUrl: 'https://i.pravatar.cc/513',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: true,
  },
  {
    id: '18',
    avatarUrl: 'https://i.pravatar.cc/111',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: true,
  },
  {
    id: '19',
    avatarUrl: 'https://i.pravatar.cc/713',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: true,
  },
  {
    id: '20',
    avatarUrl: 'https://i.pravatar.cc/123',
    firstName: 'Oleo',
    lastName: 'Na',
    isOnline: true,
  },
  {
    id: '21',
    avatarUrl: 'https://i.pravatar.cc/413',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: true,
  },
  {
    id: '22',
    avatarUrl: 'https://i.pravatar.cc/999',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: true,
  },
];

export const caregiverData: ICaregiver[] = [
  {
    id: '1',
    avatarUrl: 'https://i.pravatar.cc/991',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: true,
  },
  {
    id: '2',
    avatarUrl: 'https://i.pravatar.cc/992',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: true,
  },
  {
    id: '3',
    avatarUrl: 'https://i.pravatar.cc/993',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: true,
  },
  {
    id: '4',
    avatarUrl: 'https://i.pravatar.cc/9994',
    firstName: 'Oleo',
    lastName: 'Bone',
    isOnline: true,
  },
  {
    id: '5',
    avatarUrl: 'https://i.pravatar.cc/995',
    firstName: 'Oleo',
    lastName: 'Na',
    isOnline: true,
  },
];

export const dateControl = [Views.MONTH, Views.WEEK, Views.DAY];

export const dateControlOfficeAndShiftSchedule: TCalendarViewMode[] = [Views.MONTH, Views.WEEK, Views.DAY];

export const caregiverReferralData: ICaregiverReferral[] = [];

export const caregiverReferralShowAllData: ICaregiverReferral[] = [
  {
    id: '3',
    avatarUrl: 'https://i.pravatar.cc/993',
    firstName: 'Steward',
    lastName: 'Darrell',
    email: 'robert.miller@gmail.com',
    address: 'Manchester, Kentucky 39495',
    phoneNumber: '01234 4567 881',
    referralID: 'ABD12345C',
  },
];

export const caregiverReferralDataRecord: ICaregiverReferral = {
  id: '1',
  avatarUrl: 'https://i.pravatar.cc/991',
  firstName: 'Oleo',
  lastName: 'Bone',
  email: 'robert.miller@gmail.com',
  address: 'Manchester, Kentucky 39495',
  phoneNumber: '01234 4567 881',
  referralID: 'ABD12345C',
};

export const dataSelectCaregiverReferral = [{ label: 'Tracking', value: 'tracking' }];

export const totalCustomer: IStatisticCard = {
  // percent: 0,
  // quantity: 2500,
};

export const totalService: IStatisticCard = {
  // percent: 0,
  // quantity: 10,
};

export const totalRating: IStatisticCard = {
  // percent: 0,
  // quantity: 656,
};

export const satisfiedCustomers: IStatisticCard = {
  // percent: 0,
  // quantity: 10333,
};

export const optionsDropdownChart = [
  {
    label: 'Month',
    value: 'Month',
  },
  {
    label: 'Week',
    value: 'Week',
  },
  {
    label: 'Year',
    value: 'Year',
  },
];

export const donutChartData = [
  // 50, 30, 20
];

export const chartColumnDashboardData = [
  // {
  //   data: [780, 590, 1000, 600, 780, 600, 780, 600, 1000, 600, 780, 600],
  // },
  // {
  //   data: [620, 480, 820, 480, 620, 480, 620, 480, 820, 480, 620, 480],
  // },
];

export const lineChartData = [
  {
    data: [0, 100, 300, 200, 400],
  },
];

export const optionsRoleEmployee = [
  {
    label: 'Admin',
    value: '00001',
  },
  {
    label: 'Staff',
    value: '00002',
  },
  {
    label: 'Manager',
    value: '00005',
  },
];

export const optionsMockProjectStatus = [
  {
    label: 'Complete',
    value: 'COMPLETE',
  },
  {
    label: 'Not Complete',
    value: 'NOT_COMPLETE',
  },
  {
    label: "Won't Complete",
    value: 'WONT_COMPLETE',
  },
];

export const optionsMockRecurrence = [
  {
    label: 'None',
    value: RecurrenceEnum.NONE,
  },
  {
    label: 'Daily',
    value: RecurrenceEnum.DAILY,
  },
  {
    label: 'Weekly',
    value: RecurrenceEnum.WEEKLY,
  },
  {
    label: 'Monthly',
    value: RecurrenceEnum.MONTHLY,
  },
];

export const optionsMockPriority = [
  {
    label: 'High',
    value: 'HIGH',
  },
  {
    label: 'Medium',
    value: 'MEDIUM',
  },
  {
    label: 'Low',
    value: 'LOW',
  },
];

export const optionsMockSelect = [
  {
    label: 'Never',
    value: 'NEVER',
  },
  {
    label: 'Until',
    value: 'UNTIL',
  },
];

export const customerListData: ICustomerList[] = [
  // {
  //   id: '1',
  //   firstName: 'Oleo',
  //   lastName: 'Bone',
  //   avatarUrl: 'https://i.pravatar.cc/997',
  //   billingDate: 'June 24, 2024',
  //   plan: 'IN-HOME',
  //   amount: '$25,542,00',
  //   totalUser: '10',
  // },
  // {
  //   id: '2',
  //   firstName: 'Oleo',
  //   lastName: 'Bone',
  //   avatarUrl: 'https://i.pravatar.cc/997',
  //   billingDate: 'June 24, 2024',
  //   plan: 'DAY CENTER',
  //   amount: '$25,542,00',
  //   totalUser: '10',
  // },
  // {
  //   id: '3',
  //   firstName: 'Oleo',
  //   lastName: 'Bone',
  //   avatarUrl: 'https://i.pravatar.cc/997',
  //   billingDate: 'June 24, 2024',
  //   plan: 'TRANSPORTATION',
  //   amount: '$25,542,00',
  //   totalUser: '10',
  // },
  // {
  //   id: '4',
  //   firstName: 'Oleo',
  //   lastName: 'Bone',
  //   avatarUrl: 'https://i.pravatar.cc/997',
  //   billingDate: 'June 24, 2024',
  //   plan: 'IN-HOME',
  //   amount: '$25,542,00',
  //   totalUser: '10',
  // },
  // {
  //   id: '5',
  //   firstName: 'Oleo',
  //   lastName: 'Bone',
  //   avatarUrl: 'https://i.pravatar.cc/997',
  //   billingDate: 'June 24, 2024',
  //   plan: 'IN-HOME',
  //   amount: '$25,542,00',
  //   totalUser: '10',
  // },
  // {
  //   id: '6',
  //   firstName: 'Oleo',
  //   lastName: 'Bone',
  //   avatarUrl: 'https://i.pravatar.cc/997',
  //   billingDate: 'June 24, 2024',
  //   plan: 'IN-HOME',
  //   amount: '$25,542,00',
  //   totalUser: '10',
  // },
  // {
  //   id: '7',
  //   firstName: 'Oleo',
  //   lastName: 'Bone',
  //   avatarUrl: 'https://i.pravatar.cc/997',
  //   billingDate: 'June 24, 2024',
  //   plan: 'IN-HOME',
  //   amount: '$25,542,00',
  //   totalUser: '10',
  // },
];

export const employeeDetailData: IEmployeeDetail = {
  id: '61',
  firstName: 'Bone',
  lastName: 'Oleo',
  avatarUrl: 'https://i.pravatar.cc/322',
  position: 'Project Manager',
  contactInformation: {
    secondaryEmail: 'oleo@gmail.com',
    alternatePhoneNumber: '(276) 927-1041',
    phoneNumber: '(276) 927-1040',
  },
  workInformation: {
    employeeId: 'A0B1C222',
    department: 'Manager',
  },
  personalInformation: {
    firstName: 'Bone',
    lastName: 'Oleo',
    gender: 'Male',
    address: '37221 Wilderman Flats Suite 788',
    city: 'Port Kileystad',
    stateCountry: 'Louisiana, USA',
    zipCode: '29235-7701',
    nationality: 'U.S. citizenship',
    nriResident: 'Yes',
  },
};

export const conversationData: IConversation[] = [
  {
    id: '1',
    countUnseen: 1,
    account: {
      id: '1',
      firstName: 'Duy',
      lastName: 'Duong',
      avatarUrl: 'https://i.pravatar.cc/997',
    },
    lastMessage: {
      id: '1',
      content: "Hi, this is progress update for this week's. Don't you feel bored? Let's go on vacation tomorrow",
      senderId: '1',
      type: MessageTypeEnum.TEXT,
      status: MessageStatusEnum.DELIVERED,
      createdAt: new Date(),
    },
  },
  {
    id: '2',
    countUnseen: 1,
    account: {
      id: '1',
      firstName: 'Duy',
      lastName: 'Duong',
      avatarUrl: 'https://i.pravatar.cc/997',
    },
    lastMessage: {
      id: '2',
      content: "Hi, this is progress update for this week's. Don't you feel bored? Let's go on vacation tomorrow",
      senderId: '1',
      type: MessageTypeEnum.TEXT,
      status: MessageStatusEnum.SEEN,
      createdAt: new Date(),
    },
  },
  {
    id: '3',
    countUnseen: 1,
    account: {
      id: '1',
      firstName: 'Duy',
      lastName: 'Duong',
      avatarUrl: 'https://i.pravatar.cc/997',
    },
    lastMessage: {
      id: '2',
      content: "Hi, this is progress update for this week's. Don't you feel bored? Let's go on vacation tomorrow",
      senderId: '1',
      type: MessageTypeEnum.TEXT,
      status: MessageStatusEnum.DELIVERED,
      createdAt: new Date(),
    },
  },
];

export const mockDataMessage: IMessage[] = [
  {
    id: '1',
    content:
      "I have a good idea, how about we see the green scenery in my grandmother's hometown, so we can fresh there",
    type: MessageTypeEnum.TEXT,
    status: MessageStatusEnum.SEEN,
    createdAt: '2024-08-03T06:00:00Z',
    senderId: '2',
    senderAvatarUrl:
      'https://images.unsplash.com/photo-1541290431335-1f4c2152e899?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    senderFirstName: 'Marvin McKinney',
    url: 'http://',
  },
  {
    id: '2',
    content: 'Hello World!',
    type: MessageTypeEnum.TEXT,
    status: MessageStatusEnum.SEEN,
    createdAt: '2024-08-03T06:00:00Z',
    senderId: '257',
    senderAvatarUrl:
      'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    senderFirstName: 'Marvin McKinney',
    url: 'http://',
  },
  {
    id: '3',
    content:
      "I have a good idea, how about we see the green scenery in my grandmother's hometown, so we can fresh there",
    type: MessageTypeEnum.TEXT,
    status: MessageStatusEnum.SEEN,
    createdAt: '2024-08-03T06:00:00Z',
    senderId: '2',
    senderAvatarUrl:
      'https://images.unsplash.com/photo-1541290431335-1f4c2152e899?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    senderFirstName: 'Brooklyn Simmons',
    url: 'http://',
  },
  {
    id: '4',
    content: 'Hello World!',
    type: MessageTypeEnum.TEXT,
    status: MessageStatusEnum.SEEN,
    createdAt: '2024-08-03T06:00:00Z',
    senderId: '257',
    senderAvatarUrl:
      'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    senderFirstName: 'Marvin McKinney',
    url: 'http://',
  },
  {
    id: '5',
    content: 'Hello World!',
    type: MessageTypeEnum.TEXT,
    status: MessageStatusEnum.SEEN,
    createdAt: '2024-08-03T06:00:00Z',
    senderId: '257',
    senderAvatarUrl:
      'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    senderFirstName: 'Marvin McKinney',
    url: 'http://',
  },
];

export const mockDataConversationUserInfo: IConversationMember = {
  id: '1',
  avatarUrl:
    'https://images.unsplash.com/photo-1541290431335-1f4c2152e899?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  firstName: 'Brooklyn',
  lastName: 'Simmons',
  isActive: true,
};

export const mockDataClientDetail = {
  id: '1',
  firstName: 'Oleo',
  middleName: '',
  lastName: 'Bone',
  avatarUrl:
    'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  dob: '32 Years',
  address: 'Manchester, Kentucky 39495',
  secondaryAddress: '',
  city: '',
  state: '',
  postalCode: '',
  notes: 'Elite Home Care give services with a focus on day',
  ssn: '',
  homePhone: '',
  mobilePhone: '',
  workPhone: '',
  email: '',
  location: 'Rock Hill Day Center',
  externalId: '',
  referrerId: 'Leslie Alexander',
  timezone: '',
  status: CLientStatusEnum.ACTIVE,
};

export const mockOptionReferrer = [
  {
    label: 'None',
    value: 'None',
  },
  {
    label: 'Self',
    value: accountId || 'Self',
  },
];

export const mockOptionLocation = [
  {
    label: 'Aiken Day Center',
    value: 'Aiken Day Center',
  },
  {
    label: 'Rock Hill Day Center',
    value: 'Rock Hill Day Center',
  },
  {
    label: 'HC Team',
    value: 'HC Team',
  },
  {
    label: 'Anderson Home Care',
    value: 'Anderson Home Care',
  },
  {
    label: 'Darlington Day Center',
    value: 'Darlington Day Center',
  },
  {
    label: 'Columbia Day Center',
    value: 'Columbia Day Center',
  },
  {
    label: 'Columbia Home Care',
    value: 'Columbia Home Care',
  },
  {
    label: 'Florence Day Center',
    value: 'Florence Day Center',
  },
  {
    label: 'Conway Home Care',
    value: 'Conway Home Care',
  },
  {
    label: 'Georgetown Day Center',
    value: 'Georgetown Day Center',
  },
];

export const mockOptionsTimezone: IOptionTimeZone[] = [
  { value: 'US/Alaska', label: 'US/Alaska' },
  { value: 'US/Aleutian', label: 'US/Aleutian' },
  { value: 'US/Arizona', label: 'US/Arizona' },
  { value: 'US/Central', label: 'US/Central' },
  { value: 'US/East-Indiana', label: 'US/East-Indiana' },
  { value: 'US/Eastern', label: 'US/Eastern' },
  { value: 'US/Hawaii', label: 'US/Hawaii' },
  { value: 'US/Indiana-Starke', label: 'US/Indiana-Starke' },
  { value: 'US/Michigan', label: 'US/Michigan' },
  { value: 'US/Mountain', label: 'US/Mountain' },
  { value: 'US/Pacific', label: 'US/Pacific' },
  { value: 'US/Samoa', label: 'US/Samoa' },
  { value: 'America/New_York', label: 'America/New_York' },
  { value: 'America/Chicago', label: 'America/Chicago' },
  { value: 'America/Denver', label: 'America/Denver' },
  { value: 'America/Los_Angeles', label: 'America/Los_Angeles' },
  { value: 'America/Anchorage', label: 'America/Anchorage' },
  { value: 'America/Phoenix', label: 'America/Phoenix' },
  { value: 'America/Adak', label: 'America/Adak' },
  { value: 'Pacific/Honolulu', label: 'Pacific/Honolulu' },
];

export const mockDataState = [
  { label: 'Alabama', value: 'Alabama' },
  { label: 'Alaska', value: 'Alaska' },
  { label: 'Arizona', value: 'Arizona' },
  { label: 'Arkansas', value: 'Arkansas' },
  { label: 'California', value: 'California' },
  { label: 'Colorado', value: 'Colorado' },
  { label: 'Connecticut', value: 'Connecticut' },
  { label: 'Delaware', value: 'Delaware' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Hawaii', value: 'Hawaii' },
  { label: 'Idaho', value: 'Idaho' },
  { label: 'Illinois', value: 'Illinois' },
  { label: 'Indiana', value: 'Indiana' },
  { label: 'Iowa', value: 'Iowa' },
  { label: 'Kansas', value: 'Kansas' },
  { label: 'Kentucky', value: 'Kentucky' },
  { label: 'Louisiana', value: 'Louisiana' },
  { label: 'Maine', value: 'Maine' },
  { label: 'Maryland', value: 'Maryland' },
  { label: 'Massachusetts', value: 'Massachusetts' },
  { label: 'Michigan', value: 'Michigan' },
  { label: 'Minnesota', value: 'Minnesota' },
  { label: 'Mississippi', value: 'Mississippi' },
  { label: 'Missouri', value: 'Missouri' },
  { label: 'Montana', value: 'Montana' },
  { label: 'Nebraska', value: 'Nebraska' },
  { label: 'Nevada', value: 'Nevada' },
  { label: 'New Hampshire', value: 'New Hampshire' },
  { label: 'New Jersey', value: 'New Jersey' },
  { label: 'New Mexico', value: 'New Mexico' },
  { label: 'New York', value: 'New York' },
  { label: 'North Carolina', value: 'North Carolina' },
  { label: 'North Dakota', value: 'North Dakota' },
  { label: 'Ohio', value: 'Ohio' },
  { label: 'Oklahoma', value: 'Oklahoma' },
  { label: 'Oregon', value: 'Oregon' },
  { label: 'Pennsylvania', value: 'Pennsylvania' },
  { label: 'Rhode Island', value: 'Rhode Island' },
  { label: 'South Carolina', value: 'South Carolina' },
  { label: 'South Dakota', value: 'South Dakota' },
  { label: 'Tennessee', value: 'Tennessee' },
  { label: 'Texas', value: 'Texas' },
  { label: 'Utah', value: 'Utah' },
  { label: 'Vermont', value: 'Vermont' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Washington', value: 'Washington' },
  { label: 'West Virginia', value: 'West Virginia' },
  { label: 'Wisconsin', value: 'Wisconsin' },
  { label: 'Wyoming', value: 'Wyoming' },
];

export const mockDataClientCrm = {
  id: '1',
  firstName: 'Duong',
  middleName: 'Van',
  lastName: 'Duy',
  avatarUrl: 'https://i.pravatar.cc/997',
  dob: '24/12/2003',
  address: 'NB',
  secondaryAddress: 'HN',
  city: 'HN',
  state: '',
  postalCode: '',
  notes: 'Duy Duong',
  ssn: '',
  homePhone: '0342782299',
  mobilePhone: '0342782299',
  workPhone: '',
  email: 'duy@gmail.com',
  location: '',
  externalId: '',
  referrerId: '',
  timezone: '',
};

export const mockDataActivity: IActivity[] = [
  {
    id: '1',
    activityName: ' Oleo Bone',
    avatarUrl:
      'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    firstName: 'Bone',
    lastName: 'Oleo',
    date: '2024-08-03T06:00:00Z',
    time: '2024-08-03T06:00:00Z',
  },
  {
    id: '2',
    activityName: ' Oleo Bone',
    avatarUrl:
      'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    firstName: 'Bone',
    lastName: 'Oleo',
    date: '2024-08-03T06:00:00Z',
    time: '2024-08-03T06:00:00Z',
  },
  {
    id: '3',
    activityName: ' Oleo Bone',
    avatarUrl:
      'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    firstName: 'Bone',
    lastName: 'Oleo',
    date: '2024-08-03T06:00:00Z',
    time: '2024-08-03T06:00:00Z',
  },
  {
    id: '4',
    activityName: ' Oleo Bone',
    avatarUrl:
      'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    firstName: 'Bone',
    lastName: 'Oleo',
    date: '2024-08-03T06:00:00Z',
    time: '2024-08-03T06:00:00Z',
  },
  {
    id: '5',
    activityName: ' Oleo Bone',
    avatarUrl:
      'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    firstName: 'Bone',
    lastName: 'Oleo',
    date: '2024-08-03T06:00:00Z',
    time: '2024-08-03T06:00:00Z',
  },
  {
    id: '6',
    activityName: ' Oleo Bone',
    avatarUrl:
      'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    firstName: 'Bone',
    lastName: 'Oleo',
    date: '2024-08-03T06:00:00Z',
    time: '2024-08-03T06:00:00Z',
  },
  {
    id: '7',
    activityName: ' Oleo Bone',
    avatarUrl:
      'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    firstName: 'Bone',
    lastName: 'Oleo',
    date: '2024-08-03T06:00:00Z',
    time: '2024-08-03T06:00:00Z',
  },
  {
    id: '8',
    activityName: ' Oleo Bone',
    avatarUrl:
      'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    firstName: 'Bone',
    lastName: 'Oleo',
    date: '2024-08-03T06:00:00Z',
    time: '2024-08-03T06:00:00Z',
  },
  {
    id: '9',
    activityName: ' Oleo Bone',
    avatarUrl:
      'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    firstName: 'Bone',
    lastName: 'Oleo',
    date: '2024-08-03T06:00:00Z',
    time: '2024-08-03T06:00:00Z',
  },
  {
    id: '10',
    activityName: ' Oleo Bone',
    avatarUrl:
      'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    firstName: 'Bone',
    lastName: 'Oleo',
    date: '2024-08-03T06:00:00Z',
    time: '2024-08-03T06:00:00Z',
  },
];

export const mockDataHr: IHr[] = [
  {
    id: '1',
    avatarUrl: 'https://i.pravatar.cc/993',
    firstName: 'Brooklyn',
    lastName: 'Simmons',
    employeeId: '222',
    email: 'oleo@gmail.com',
    driverLicense: 'DR-02568212',
    carInsurance: 'NO',
    fingerprints: '',
    legalDocuments: {
      id: '1',
      url: 'https://',
      name: 'PDF-0056.pdf',
    },
    contracts: '2 Years',
    employeeDocuments: {
      id: '1',
      url: 'https://',
      name: 'DOC-0056.docx',
    },
    files: {
      id: '1',
      url: 'https://',
      name: 'File-01',
    },
    certifications: {
      id: '1',
      url: 'https://',
      name: 'Certificate',
    },
    task: 'Elite Home Care',
    renewalDates: '2024-08-03T06:00:00Z',
    expirationDates: '2024-08-03T06:00:00Z',
    daysOff: '3 Days',
    sickDays: '2 Days',
    pto: '26 hours',
    date: '2024-08-03T06:00:00Z',
    status: 'PENDING',
  },
];

export const mockDataHistory: IHr[] = [
  {
    id: '2',
    avatarUrl:
      'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    firstName: 'Annette',
    lastName: 'Black',
    employeeId: '333',
    email: 'michael@gmail.com',
    driverLicense: 'DR-02568212',
    carInsurance: 'NO',
    fingerprints: '',
    legalDocuments: {
      id: '1',
      url: 'https://',
      name: 'PDF-0056.pdf',
    },
    contracts: '2 Years',
    employeeDocuments: {
      id: '1',
      url: 'https://',
      name: 'DOC-0056.docx',
    },
    files: {
      id: '1',
      url: 'https://',
      name: 'File-01',
    },
    certifications: {
      id: '1',
      url: 'https://',
      name: 'Certificate',
    },
    task: 'Elite Home Care',
    renewalDates: '2024-08-03T06:00:00Z',
    expirationDates: '2024-08-03T06:00:00Z',
    daysOff: '3 Days',
    sickDays: '2 Days',
    pto: '26 hours',
    date: '2024-08-03T06:00:00Z',
    status: 'PENDING',
  },
];

export const mockDataSkill: ISkill[] = [
  {
    id: '1',
    skillName: 'Web Theme Design',
  },
  {
    id: '2',
    skillName: 'Mobile Apps Design',
  },
  {
    id: '3',
    skillName: 'Adobe Creative Suite',
  },
  {
    id: '4',
    skillName: 'Branding & Identity',
  },
  {
    id: '5',
    skillName: 'Adobe Photoshop',
  },
  {
    id: '6',
    skillName: 'User Interface Design',
  },
  {
    id: '7',
    skillName: 'Web Theme Design',
  },
  {
    id: '8',
    skillName: 'Web Theme Design',
  },
  {
    id: '9',
    skillName: 'Web Theme Design',
  },
  {
    id: '10',
    skillName: 'Web Theme Design',
  },
];

export const mockDataCertification: ICertification[] = [
  {
    id: '1',
    certificationTitle: 'UX Design Fundamental',
    courseName: 'UX Design',
  },
  {
    id: '2',
    certificationTitle: 'UX Design Fundamental',
    courseName: 'UX Design',
  },
  {
    id: '3',
    certificationTitle: 'UX Design Fundamental',
    courseName: 'UX Design',
  },
  {
    id: '4',
    certificationTitle: 'UX Design Fundamental',
    courseName: 'UX Design',
  },
  {
    id: '5',
    certificationTitle: 'UX Design Fundamental',
    courseName: 'UX Design',
  },
];

export const mockDataDocumentCrm = [
  {
    id: '1',
    name: 'Elite Home Care',
    type: 'PDF',
    size: '32 KB',
    updateTime: '2024-08-13T06:00:00Z',
  },
  {
    id: '2',
    name: 'Elite Home Care',
    type: 'PDF',
    size: '32 KB',
    updateTime: '2024-08-03T06:00:00Z',
  },
  {
    id: '3',
    name: 'Elite Home Care',
    type: 'FILE_FOLDER',
    size: '32 KB',
    updateTime: '2024-08-03T06:00:00Z',
  },
  {
    id: '4',
    name: 'Elite Home Care -  Day Centers & Transportation',
    type: 'PDF',
    size: '32 KB',
    updateTime: '2024-08-03T06:00:00Z',
  },
  {
    id: '5',
    name: 'Elite Home Care -  Day Centers & Transportation',
    type: 'FILE_FOLDER',
    size: '32 KB',
    updateTime: '2024-08-03T06:00:00Z',
  },
  {
    id: '6',
    name: 'Elite Home Care',
    type: 'PDF',
    size: '32 KB',
    updateTime: '2024-08-03T06:00:00Z',
  },
];

export const mockDataActivitiesCrm = [
  {
    id: '1',
    firstName: 'Oleo',
    lastName: 'Bone',
    avatarUrl:
      'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    activity: 'View file Oleo Bone',
    date: '2024-08-13T00:00:00Z',
    time: '2024-08-13T08:00:00Z',
  },
  {
    id: '2',
    firstName: 'Oleo',
    lastName: 'Bone',
    activity: 'View file Oleo Bone',
    avatarUrl:
      'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    date: '2024-08-13T00:00:00Z',
    time: '2024-08-13T08:00:00Z',
  },
  {
    id: '3',
    firstName: 'Oleo',
    lastName: 'Bone',
    activity: 'View file Oleo Bone',
    date: '2024-08-13T00:00:00Z',
    time: '2024-08-13T08:00:00Z',
  },
  {
    id: '4',
    firstName: 'Oleo',
    lastName: 'Bone',
    avatarUrl:
      'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    activity: 'View file Oleo Bone',
    date: '2024-08-13T00:00:00Z',
    time: '2024-08-13T08:00:00Z',
  },
  {
    id: '5',
    firstName: 'Oleo',
    lastName: 'Bone',
    activity: 'View file Oleo Bone',
    date: '2024-08-13T00:00:00Z',
    time: '2024-08-13T08:00:00Z',
  },
];

export const mockDataListReferralSource: IListReferralSource[] = [
  {
    id: '1',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
  {
    id: '2',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
  {
    id: '3',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
  {
    id: '4',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
  {
    id: '5',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
  {
    id: '6',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
  {
    id: '7',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
  {
    id: '8',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
  {
    id: '9',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
  {
    id: '10',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
  {
    id: '11',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
  {
    id: '12',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
  {
    id: '13',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
  {
    id: '14',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
  {
    id: '15',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
  {
    id: '16',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
  {
    id: '17',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
  {
    id: '18',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
  {
    id: '19',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
  {
    id: '20',
    organization: 'organization',
    name: 'name',
    owner: 'owner',
    prospects: 999,
    workPhone: '(276) 927-1040',
  },
];

export const mockOptionsTypeCrm: IOptionTimeZone[] = [
  {
    value: 'Adult Day Care',
    label: 'Adult Day Care',
  },
  {
    value: 'Advertising',
    label: 'Advertising',
  },
  {
    value: 'Affiliated Org. (Corp HQ, Peer Agency, etc)',
    label: 'Affiliated Org. (Corp HQ, Peer Agency, etc)',
  },
  {
    value: 'Alz Association or Support Group',
    label: 'Alz Association or Support Group',
  },
  {
    value: 'Area Office on Aging',
    label: 'Area Office on Aging',
  },
  {
    value: 'Assisted Living',
    label: 'Assisted Living',
  },
  {
    value: 'Branding-Sign/Building/Vehicle',
    label: 'Branding-Sign/Building/Vehicle',
  },
  {
    value: 'Caregivers',
    label: 'Caregivers',
  },
];

export const mockOptionsOwnerCrm: IOptionTimeZone[] = [
  {
    value: 'Alyse Acebal',
    label: 'Alyse Acebal',
  },
  {
    value: 'Florence Administrator',
    label: 'Florence Administrator',
  },
  {
    value: 'Chris Arthurs',
    label: 'Chris Arthurs',
  },
  {
    value: 'Pickens Member Assistant',
    label: 'Pickens Member Assistant',
  },
  {
    value: 'Allison Atkins',
    label: 'Allison Atkins',
  },
  {
    value: 'Erica Balderas',
    label: 'Erica Balderas',
  },
  {
    value: 'Alisa Barbare',
    label: 'Alisa Barbare',
  },
  {
    value: 'Lauren Batson',
    label: 'Lauren Batson',
  },
  {
    value: 'Leigh Belk',
    label: 'Leigh Belk',
  },
];

export const mockDataOptionTask = [
  {
    label: 'Elite Home Care - Web App',
    value: 'Elite Home Care - Web App',
  },
  {
    label: 'Elite Home Care - Mobile App',
    value: 'Elite Home Care - Mobile App',
  },
  {
    label: 'Elite Home Care - Design System',
    value: 'Elite Home Care - Design System',
  },
  {
    label: 'Elite Home Care - Style Guide',
    value: 'Elite Home Care - Style Guide',
  },
  {
    label: 'Elite Home Care - User Flow',
    value: 'Elite Home Care - User Flow',
  },
];

export const mockDataDetailTimeClock: IFormEditTimeClock = {
  id: '1',
  checkIn: '08:45 am',
  duration: '6h',
  totalBreaks: '00:30',
  userName: 'Oleo Bone',
  taskName: 'Elite Home Care - User Flow',
  projectName: 'Elite Home Care',
  location: 'Paris',
  lat: 34655,
  lng: 89325,
};

export const optionsAllDaySchedule = [
  {
    label: 'No',
    value: 'NO',
  },
  {
    label: 'Yes',
    value: 'YES',
  },
];

export const optionsRecurrenceSchedule = [
  {
    label: 'None',
    value: 'NONE',
  },
  {
    label: 'Daily',
    value: 'DAILY',
  },
  {
    label: 'Weekly',
    value: 'WEEKLY',
  },
  {
    label: 'Monthly',
    value: 'MONTHLY',
  },
];

export const optionsRecurrenceCaregiverRate = [
  {
    label: 'Hourly',
    value: 'HOURLY',
  },
  {
    label: 'Per Visit',
    value: 'PER_VISIT',
  },
  {
    label: 'Live in',
    value: 'LIVE_IN',
  },
  {
    label: 'Not billable',
    value: 'NOT_BILLABLE',
  },
];

export const mockDataTaskDetail = {
  id: '1',
  name: 'Elite Home Care - Web App',
  description: 'Task Description',
  status: 'INPROGRESS',
  tags: ['Urgent', 'Design'],
  assignees: [
    {
      id: '1',
      firstName: 'Dat',
      lastName: 'Do',
      avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
    },
    {
      id: '2',
      firstName: 'Dat',
      lastName: 'Do',
      avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
    },
    {
      id: '2',
      firstName: 'Dat',
      lastName: 'Do',
      avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
    },
    {
      id: '2',
      firstName: 'Dat',
      lastName: 'Do',
      avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
    },
    {
      id: '2',
      firstName: 'Dat',
      lastName: 'Do',
      avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
    },
  ],
};

export const assigneeList: IAddAssignee[] = [
  {
    id: '345',
    firstName: 'Oleo',
    lastName: 'Bone',
    email: 'oleo@gmail.com',
  },
  {
    id: '352',
    firstName: 'Eleanor',
    lastName: 'Pena',
    email: 'eleanor@gmail.com',
  },
  {
    id: '340',
    firstName: 'Robert',
    lastName: 'Fox',
    email: 'robert@gmail.com',
  },
  {
    id: '339',
    firstName: 'Ronald',
    lastName: 'Richards',
    email: 'ronald@gmail.com',
  },
  {
    id: '338',
    firstName: 'Beck',
    lastName: 'Pena',
    email: 'beck@gmail.com',
  },
  {
    id: '304',
    firstName: 'David',
    lastName: 'Fox',
    email: 'david@gmail.com',
  },
  {
    id: '301',
    firstName: 'Neyma',
    lastName: 'Richards',
    email: 'neyma@gmail.com',
  },
];

export const optionsMockTags = [
  {
    label: 'Design',
    value: 'Design',
  },
  {
    label: 'Product',
    value: 'Product',
  },
  {
    label: 'Marketing',
    value: 'Marketing',
  },
  {
    label: 'Design System',
    value: 'Design System',
  },
];

export const inCludeInMockOption = [
  { label: 'Include in calendars', value: TaskIncludeInEnum.CALENDARS },
  { label: 'Include in caregiver portal', value: TaskIncludeInEnum.CAREGIVER_PORTAL },
  { label: 'Include in printed calendars', value: TaskIncludeInEnum.PRINTED_CALENDARS },
  { label: 'Include in family room', value: TaskIncludeInEnum.FAMILY_ROOM },
];

export const recurrenceMockOption = [
  { label: 'Open Shift', value: 'Open Shidt' },
  { label: 'Jenny Wilson', value: 'Jenny Wilson' },
  { label: 'Dinanne Russell', value: 'Dinanne Russell' },
];

export const adminScheduleMock = [
  {
    id: '1',
    taskName: 'Multi-day Event Schedule',
    startDate: new Date(2024, 8, 14, 1, 0, 0),
    endDate: new Date(2024, 8, 16, 2, 0, 0),
    startTime: '2024-08-09T10:00:00Z',
    endTime: '2024-08-09T11:00:00Z',
    assignees: [
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
    ],
  },
  {
    id: '1',
    taskName: 'Multi-day Event Schedule',
    startDate: new Date(2024, 8, 14, 1, 0, 0),
    endDate: new Date(2024, 8, 16, 2, 0, 0),
    startTime: '2024-08-09T10:00:00Z',
    endTime: '2024-08-09T11:00:00Z',
    assignees: [
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
    ],
  },
  {
    id: '1',
    taskName: 'Multi-day Event Schedule',
    startDate: new Date(2024, 8, 14, 1, 0, 0),
    endDate: new Date(2024, 8, 16, 2, 0, 0),
    startTime: '2024-08-09T10:00:00Z',
    endTime: '2024-08-09T11:00:00Z',
    assignees: [
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
    ],
  },
  {
    id: '1',
    taskName: 'Multi-day Event Schedule',
    startDate: new Date(2024, 8, 14, 1, 0, 0),
    endDate: new Date(2024, 8, 16, 2, 0, 0),
    startTime: '2024-08-09T10:00:00Z',
    endTime: '2024-08-09T11:00:00Z',
    assignees: [
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
    ],
  },
  {
    id: '1',
    taskName: 'Multi-day Event Schedule',
    startDate: new Date(2024, 8, 14, 1, 0, 0),
    endDate: new Date(2024, 8, 16, 2, 0, 0),
    startTime: '2024-08-09T10:00:00Z',
    endTime: '2024-08-09T11:00:00Z',
    assignees: [
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
    ],
  },
  {
    id: '1',
    taskName: 'Multi-day Event Schedule',
    startDate: new Date(2024, 8, 14, 1, 0, 0),
    endDate: new Date(2024, 8, 16, 2, 0, 0),
    startTime: '2024-08-09T10:00:00Z',
    endTime: '2024-08-09T11:00:00Z',
    assignees: [
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
    ],
  },
  {
    id: '1',
    taskName: 'Multi-day Event Schedule',
    startDate: new Date(2024, 8, 14, 1, 0, 0),
    endDate: new Date(2024, 8, 16, 2, 0, 0),
    startTime: '2024-08-09T10:00:00Z',
    endTime: '2024-08-09T11:00:00Z',
    assignees: [
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
    ],
  },
  {
    id: '1',
    taskName: 'Multi-day Event Schedule',
    startDate: new Date(2024, 8, 14, 1, 0, 0),
    endDate: new Date(2024, 8, 16, 2, 0, 0),
    startTime: '2024-08-09T10:00:00Z',
    endTime: '2024-08-09T11:00:00Z',
    assignees: [
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
    ],
  },
  {
    id: '1',
    taskName: 'Multi-day Event Schedule',
    startDate: new Date(2024, 8, 14, 1, 0, 0),
    endDate: new Date(2024, 8, 16, 2, 0, 0),
    startTime: '2024-08-09T10:00:00Z',
    endTime: '2024-08-09T11:00:00Z',
    assignees: [
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
    ],
  },
  {
    id: '1',
    taskName: 'Multi-day Event Schedule',
    startDate: new Date(2024, 8, 14, 1, 0, 0),
    endDate: new Date(2024, 8, 16, 2, 0, 0),
    startTime: '2024-08-09T10:00:00Z',
    endTime: '2024-08-09T11:00:00Z',
    assignees: [
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
      {
        id: '1',
        firstName: 'Alpha',
        lastName: 'Org',
        avatarUrl: 'https://inventory.s3.amazonaws.com/inventory',
      },
    ],
  },
];

export const fileCaregivers: IListDocument[] = [
  {
    id: '1',
    name: 'Caregiver_John_Doe_Report.pdf',
    updatedAt: '2024-09-10T08:30:00Z',
    mimetype: 'PDF',
    size: '1.5 MB',
  },
  {
    id: '2',
    name: 'Caregiver_Jane_Smith_Contract.docx',
    updatedAt: '2024-09-12T10:15:00Z',
    mimetype: 'DOCX',
    size: '2.1 MB',
  },
  {
    id: '3',
    name: 'Caregiver_Mary_Johnson_Photo.jpg',
    updatedAt: '2024-09-14T14:20:00Z',
    mimetype: 'JPG',
    size: '3.8 MB',
  },
  {
    id: '4',
    name: 'Caregiver_Annual_Report.xlsx',
    updatedAt: '2024-09-18T12:00:00Z',
    mimetype: 'XLSX',
    size: '2.5 MB',
  },
  {
    id: '5',
    name: 'Caregiver_Profile_Update.pdf',
    updatedAt: '2024-09-20T09:40:00Z',
    mimetype: 'PDF',
    size: '1.2 MB',
  },
];

export const mockOptionTimeCareAlert = [
  {
    label: 'Last 2 Hours',
    value: 'Last 2 Hours',
  },
];

export const mockOptionAlert = [
  {
    label: 'All Alerts',
    value: 'ALL_ALERTS',
  },
  {
    label: 'Missed Clock-In',
    value: 'MISSED_CLOCK_IN',
  },
  {
    label: 'Late Clock-Ins',
    value: 'LATE_CLOCK_INS',
  },
  {
    label: 'Late Clock-Outs',
    value: 'LATE_CLOCK_OUTS',
  },
  {
    label: 'Unverified Clock-Ins',
    value: 'UNVERIFIED_CLOCK_INS',
  },
  {
    label: 'Incomplete Tasks',
    value: 'INCOMPLETE_TASKS',
  },
];

//This function fix Time with Timezone mock for mockDataCalendar (Remove when DATA BE Available)
const getTodayAtFixedTime = (hour: number, minute: number): string => {
  const timezone = getTimezone();
  const today = new Date();
  today.setUTCHours(hour, minute, 0, 0);

  const localDate = new Date(today.toLocaleString('en-US', { timeZone: timezone }));

  return (
    localDate.toISOString().replace('Z', localDate.getTimezoneOffset() > 0 ? '-' : '+') +
    String(Math.abs(localDate.getTimezoneOffset()) / 60).padStart(2, '0') +
    ':' +
    String(Math.abs(localDate.getTimezoneOffset()) % 60).padStart(2, '0')
  );
};

export const mockDataCalendar: IScheduleTimelineData[] = [
  {
    id: '1',
    firstName: 'McKinney',
    lastName: 'Marvin',
    avatarUrl: 'https://elite-home-care.com/avatar_default_1.png',
    events: [
      {
        id: '55',
        startDate: new Date().toDateString(),
        endDate: new Date().toDateString(),
        startTime: getTodayAtFixedTime(11, 0),
        endTime: getTodayAtFixedTime(13, 0),
        status: 'IN PROGRESS',

        assignees: [
          {
            id: '1',
            lastName: 'Flores',
            avatarUrl:
              'https://s3-alpha-sig.figma.com/img/7787/8151/6dfeebbe54d7bdf51333ec599c341876?Expires=1730678400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ocW9AS4xdMImPu-wALHAZ5omW2ql-IxOAdGyjyMUcsC41qJLvPvpkxL7Fbborges~lu2HklWYdKZh0P0UKKSGiYbf2QJpeBXQ8Y72UIGztfxCCsEs2gFD5s9-qpL9Ma16W~NiW-GomET8AFLnhbZqtw8uLiR3l0G1kspxBc49JRM-jD8uUDVNHSuBPcNw1j0kVtWE9NZ-2ML6nKIfwA~ywQL5g0EKvFB0IfGdHX5rkGvBy~iorQh1fVEl-nhdSLyuf4VNgHiKggopFeGjw9LtbljG0FgffGZn1yVDZkkSJIGrg6plzCxBaWfegTdGXZzvRR0iXBOFR7OvbfbO015rA__',
            firstName: 'Albert',
            phoneNumber: '(603) 555-0123(m)',
            email: 'albert.holt@example.com',
          },
        ],
        tasks: [],
      },
    ],
  },
  {
    id: '2',
    firstName: 'Edwards',
    lastName: 'Ralph',
    avatarUrl: 'https://elite-home-care.com/avatar_default_2.png',
    events: [
      {
        id: '56',
        startDate: new Date().toDateString(),
        endDate: new Date().toDateString(),
        startTime: getTodayAtFixedTime(9, 30),
        endTime: getTodayAtFixedTime(10, 30),
        status: 'COMPLETED',
        assignees: [
          {
            id: '2',
            firstName: 'Annette',
            lastName: 'Black',
            avatarUrl:
              'https://s3-alpha-sig.figma.com/img/abcb/2f42/b08714a304f30437b02f2f738ca0c873?Expires=1730678400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=qlXq0KH4v9C1D2F4txRDVWWG0rERYiIYqbIsDPwWmOo2Ls~o5VEHv7eVivVnZyOrTBzpJ6G2IN5fCH9ng5PuyHC~amXrptP2IUsF8ue-n9S0r8TFDPgdR2Z9uUtByiMBdbScd0xtdWqA1NXaK22fAJudNSE1mAzOIV0Z8qzxMGLiiPZI0o-QowoaR0TG52K4oMqIeSL95Wc~YVe9MCM2gSeO0BBeC0~nKW~h41fV95Sa4OFtQ0xL6IO4WLAVSwkynx7VmKJcsKfeRDlNDj4NlUmp4Q-LeC8y2XzKJMU0-BZhKEqHeBWICb9XzoS~VUZGFFzI2p2JHYFN7Ogm4YJcLA__',
            phoneNumber: '(603) 7875-0123(m)',
            email: 'annette.holt@example.com',
          },
        ],
        tasks: [],
      },
    ],
  },
];

export const mockDataOfficeAndOpenShiftCalendar: IScheduleEvent[] = [
  {
    id: '12',
    tasks: [
      {
        id: '1',
        name: "Unavailability has been requested, occurs once all day on 10/02/2024. Please go to the Caregiver's profile to approve or deny the request.",
        description: '',
      },
    ],
    startDate: '2024-10-20T09:40:00Z',
    endDate: '2024-10-20T09:40:00Z',
    startTime: getTodayAtFixedTime(11, 0),
    endTime: getTodayAtFixedTime(13, 0),
    type: StatusEnum.GENERAL,
  },
  {
    id: '1',
    tasks: [
      {
        id: '2',
        name: 'Halloween',
        description: '',
      },
    ],
    startDate: new Date().toDateString(),
    endDate: new Date().toDateString(),
    startTime: getTodayAtFixedTime(11, 0),
    endTime: getTodayAtFixedTime(13, 0),
    type: StatusEnum.HOLIDAY,
  },
  {
    id: '2',
    tasks: [
      {
        id: '3',
        name: 'Meeting With Albert Flores',
        description: '',
      },
    ],
    startDate: '2024-10-21T09:40:00Z',
    endDate: '2024-10-21T09:40:00Z',
    startTime: getTodayAtFixedTime(11, 0),
    endTime: getTodayAtFixedTime(13, 0),
    type: StatusEnum.MEETING,
  },
  {
    id: '3',
    tasks: [
      {
        id: '4',
        name: 'Please ensure services begin on this day. If unable to, please call the daughter immediately.',
        description: '',
      },
    ],
    startDate: '2024-10-22T09:40:00Z',
    endDate: '2024-10-22T09:40:00Z',
    startTime: getTodayAtFixedTime(11, 0),
    endTime: getTodayAtFixedTime(13, 0),
    type: StatusEnum.TASK,
  },
  {
    id: '4',
    tasks: [
      {
        id: '5',
        name: 'Office Vacation',
        description: '',
      },
    ],
    startDate: '2024-10-23T09:40:00Z',
    endDate: '2024-10-23T09:40:00Z',
    startTime: getTodayAtFixedTime(11, 0),
    endTime: getTodayAtFixedTime(13, 0),
    type: StatusEnum.VACATION_TIME_OFF,
  },
  {
    id: '5',
    tasks: [
      {
        id: '6',
        name: 'Elite Home Care -  Day Centers & Transportation',
        description: '',
      },
    ],
    startDate: '2024-10-24T09:40:00Z',
    endDate: '2024-10-24T09:40:00Z',
    startTime: getTodayAtFixedTime(11, 0),
    endTime: getTodayAtFixedTime(13, 0),
    type: StatusEnum.TASK,
  },
  {
    id: '6',
    tasks: [
      {
        id: '7',
        name: 'Elite Home Care -  Day Centers & Transportation',
        description: '',
      },
    ],
    startDate: '2024-10-24T09:40:00Z',
    endDate: '2024-10-24T09:40:00Z',
    startTime: getTodayAtFixedTime(11, 0),
    endTime: getTodayAtFixedTime(13, 0),
    type: StatusEnum.TASK,
  },
  {
    id: '7',
    tasks: [
      {
        id: '8',
        name: 'Elite Home Care -  Day Centers & Transportation',
        description: '',
      },
    ],
    startDate: '2024-10-24T09:40:00Z',
    endDate: '2024-10-24T09:40:00Z',
    startTime: getTodayAtFixedTime(11, 0),
    endTime: getTodayAtFixedTime(13, 0),
    type: StatusEnum.TASK,
  },
];

export const locationMapMockData = '531 W COLLEGE ST LOS ANGELES CA 90012-2385 USA';

export const mockDataTabNotes = {
  date: '2024-09-20T09:40:00Z',
  description: `"Called CG to see why she wasn't clocked in, VM picked up. Sent CG a text. KJS",`,
  createdBy: 'Katrina Self, 08/05/24, 09:16 a.m.',
};

export const dataSummaryMock: IListSummary = {
  id: '1',
  dates: '2024-09-20T09:40:00Z',
  status: 'Scheduled',
  tasks: [
    {
      id: '1',
      nameTask: 'Personal Care HHA:',
      descriptionTask: ' Assist with Bathing, apply lotion to legs and feet, and Dressing',
    },
    {
      id: '2',
      nameTask: 'VA HHA:',
      descriptionTask:
        ' Limited Personal Home Assistance-Personal Laundry, Tidying of Personal Living Area (NOT SHARED SPACES) and Meal Prep ONLY. NO TRANSPORTATION',
    },
  ],
  schedule:
    ' Repeats weekly on Monday, Wednesday starting on Oct 16,2024 until Dec 03,2024 from 09:00 Am EDT to 02:00 PM EDT',
  authorization: 'Auth: #VA0099493485 - VA-HHA or Respite - 31.32',
  rateName: 'VA HHA or Respite',
};

export const selectAllOptions: IBaseOption[] = [
  {
    value: StatusEnum.IN_PROGRESS,
    label: 'In Progress',
  },
  {
    value: StatusEnum.COMPLETED,
    label: 'Completed',
  },
  {
    value: StatusEnum.MISSED_CHECK_IN,
    label: 'Missed Check-in',
  },
  {
    value: StatusEnum.MISSED_CHECK_OUT,
    label: 'Missed Check-out',
  },
  {
    value: StatusEnum.OPEN_SHIFT,
    label: 'Open Shift',
  },
  {
    value: StatusEnum.CANCELLED_BY_CLIENT,
    label: 'Cancelled by Client',
  },
  {
    value: StatusEnum.CANCELLED_BY_CAREGIVER,
    label: 'Cancelled by Caregiver',
  },
  {
    value: StatusEnum.CANCELLED_BY_OFFICE,
    label: 'Cancelled by Office',
  },
  {
    value: StatusEnum.NOT_SCHEDULED,
    label: 'Not Scheduled',
  },
  {
    value: StatusEnum.PENDING_CONFIRMATION,
    label: 'Pending Confirmation',
  },
  {
    value: StatusEnum.ATTENTION_REQUIRED,
    label: 'Attention Required',
  },
];

export const selectAllTypesOptions: IBaseOption[] = [
  {
    value: StatusEnum.SHOW_ALL_TYPES,
    label: 'Show All Types',
  },
  {
    value: StatusEnum.GENERAL,
    label: 'General',
  },
  {
    value: StatusEnum.MEETING,
    label: 'Meeting',
  },
  {
    value: StatusEnum.VACATION_TIME_OFF,
    label: 'Vacation/Time Off',
  },
  {
    value: StatusEnum.HOLIDAY,
    label: 'Holiday',
  },
  {
    value: StatusEnum.TASK,
    label: 'Task',
  },
];

export const repeatWeekOptions: INumberOptionMultiple[] = [
  {
    value: DayOfWeekEnum.SUN,
    label: 'Sun',
  },
  {
    value: DayOfWeekEnum.MON,
    label: 'Mon',
  },
  {
    value: DayOfWeekEnum.TUE,
    label: 'Tue',
  },
  {
    value: DayOfWeekEnum.WED,
    label: 'Wed',
  },
  {
    value: DayOfWeekEnum.THU,
    label: 'Thu',
  },
  {
    value: DayOfWeekEnum.FRI,
    label: 'Fri',
  },
  {
    value: DayOfWeekEnum.SAT,
    label: 'Sat',
  },
];

export const repeatMonthOptions: IBaseOption[] = [
  {
    value: MonthlyOccurrenceEnum.DAY_OF_MONTH,
    label: 'Day',
  },
];

export const listCareAlertMock: IMockListCareAlert[] = [
  {
    id: '1',
    date: '2024-11-13T02:00:00Z',
    time: '2024-11-13T02:00:00Z',
    alert: 'Larry Bishop missed a clock-in for Transportation Elite.',
  },
];

export const selectExerciseOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
];

export const genderDemographicOptions = [
  {
    label: 'Female',
    value: 'FEMALE',
  },
  {
    label: 'Male',
    value: 'MALE',
  },
];

export const maritalStatusDemographicOptions = [
  {
    label: 'Married',
    value: 'MARRIED',
  },
  {
    label: 'Cohabitating',
    value: 'COHABITATING',
  },
  {
    label: 'Domestic Partnership',
    value: 'DOMESTIC_PARTNERSHIP',
  },
  {
    label: 'Single',
    value: 'SINGLE',
  },
  {
    label: 'Separated',
    value: 'SEPARATED',
  },
  {
    label: 'Divorced',
    value: 'DIVORCED',
  },
  {
    label: 'Widowed',
    value: 'WIDOWED',
  },
];

export const religionDemographicOptions = [
  {
    label: 'Christianity',
    value: 'CHRISTIANITY',
  },
  {
    label: 'Judaism',
    value: 'JUDAISM',
  },
  {
    label: 'Buddhism',
    value: 'BUDDHISM',
  },
  {
    label: 'Islam',
    value: 'ISLAM',
  },
  {
    label: 'Hinduism',
    value: 'HINDUISM',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
  {
    label: 'Unaffiliated',
    value: 'UNAFFILIATED',
  },
];

export const attendsServiceDemographicOptions = [
  { label: 'Unknown', value: 'UNKNOWN' },
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
];

export const languageDemographicOptions = [
  { label: 'Spanish', value: 'Spanish' },
  { label: 'Arabic', value: 'Arabic' },
  { label: 'English', value: 'English' },
  { label: 'Armenian', value: 'Armenian' },
  { label: 'Mandarin', value: 'Mandarin' },
  { label: 'French', value: 'French' },
  { label: 'French Creole', value: 'French Creole' },
  { label: 'Korean', value: 'Korean' },
  { label: 'Russian', value: 'Russian' },
  { label: 'Portuguese', value: 'Portuguese' },
  { label: 'German', value: 'German' },
  { label: 'Italian', value: 'Italian' },
  { label: 'Ukrainian', value: 'Ukrainian' },
  { label: 'Icelandic', value: 'Icelandic' },
  { label: 'Indonesian ', value: 'Indonesian' },
  { label: 'Chinese ', value: 'Chinese' },
  { label: 'Japanese ', value: 'Japanese' },
  { label: 'Polish ', value: 'Polish' },
  { label: 'Swedish ', value: 'Swedish' },
  { label: 'Other', value: 'Other' },
];

export const activitiesPermittedOptions = [
  {
    label: 'Complete Bedrest',
    value: 'Complete Bedrest',
  },
  {
    label: 'Bedrest BRP',
    value: 'Bedrest BRP',
  },
  {
    label: 'Up as Tolerated',
    value: 'Up as Tolerated',
  },
  {
    label: 'Transfer Bed/Chair',
    value: 'Transfer Bed/Chair',
  },
  {
    label: 'Exercises Prescribed',
    value: 'Exercises Prescribed',
  },
  {
    label: 'Partial Weight Bearing',
    value: 'Partial Weight Bearing',
  },
  {
    label: 'Independent at Home',
    value: 'Independent at Home',
  },
  {
    label: 'Crutches',
    value: 'Crutches',
  },
  {
    label: 'Cane',
    value: 'Cane',
  },
  {
    label: 'Wheelchair',
    value: 'Wheelchair',
  },
  {
    label: 'Walker',
    value: 'Walker',
  },
  {
    label: 'No Restrictions',
    value: 'No Restrictions',
  },
];

export const assistanceMealOptions = [
  {
    label: 'Cooking',
    value: 'Cooking',
  },
  {
    label: 'Preparation',
    value: 'Preparation',
  },
  {
    label: 'Feeding',
    value: 'Feeding',
  },
];

export const appetiteMealOptions = [
  {
    label: 'Poor',
    value: 'POOR',
  },
  {
    label: 'Good',
    value: 'GOOD',
  },
];

export const dietMealOption = [
  {
    label: 'Poor Nutrition',
    value: 'POOR_NUTRITION',
  },
  {
    label: 'Desires Improved Nutrition',
    value: 'DESIRES_IMPROVED_NUTRITION',
  },
  {
    label: 'Special Diet',
    value: 'SPECIAL_DIET',
  },
];

export const timesMealOPtions = [
  {
    label: 'Breakfast',
    value: 'Breakfast',
  },
  {
    label: 'Lunch',
    value: 'Lunch',
  },
  {
    label: 'Dinner',
    value: 'Dinner',
  },
  {
    label: 'Snack',
    value: 'Snack',
  },
];

export const othersMealOptions = [
  {
    label: 'Swallowing Issues',
    value: 'Swallowing Issues',
  },
  {
    label: 'Encourage Liquids',
    value: 'Encourage Liquids',
  },
];

export const leftAloneWandererOptions = [
  {
    label: 'Unknown',
    value: 'UNKNOWN',
  },
  {
    label: 'Yes',
    value: 'YES',
  },
  {
    label: 'No',
    value: 'NO',
  },
];

export const dementiaOptions = [
  {
    label: 'No',
    value: 'NO',
  },
  {
    label: 'Forgetful',
    value: 'FORGETFUL',
  },
  {
    label: 'Mild',
    value: 'MILD',
  },
  {
    label: 'Moderate',
    value: 'MODERATE',
  },
  {
    label: 'Severe',
    value: 'SEVERE',
  },
];

export const symptomsOptions = [
  {
    label: 'Frequent Mood Changes',
    value: 'Frequent Mood Changes',
  },
  {
    label: 'Hallucinations',
    value: 'Hallucinations',
  },
  {
    label: 'Problem Solving',
    value: 'Problem Solving',
  },
  {
    label: 'Short Term Memory Loss',
    value: 'Short Term Memory Loss',
  },
  {
    label: 'Completing Tasks',
    value: 'COMPLETING_TASK',
  },
  {
    label: 'Sundowning',
    value: 'Sundowning',
  },
  {
    label: 'Spatial/Visual Relationships',
    value: 'Spatial/Visual Relationships',
  },
  {
    label: 'Misplacing Items',
    value: 'Misplacing Items',
  },
  {
    label: 'Poor Eating',
    value: 'Poor Eating',
  },
  {
    label: 'Speaking/Conversing',
    value: 'Speaking/Conversing',
  },
  {
    label: 'Poor Judgment',
    value: 'Poor Judgment',
  },
  {
    label: 'Sleeping Problems',
    value: 'Sleeping Problems',
  },
  {
    label: 'Anxiety',
    value: 'Anxiety',
  },
  {
    label: 'Agitation',
    value: 'Agitation',
  },
  {
    label: 'Fear',
    value: 'Fear',
  },
  {
    label: 'Paranola',
    value: 'Paranola',
  },
  {
    label: 'Suspicion',
    value: 'Suspicion',
  },
  {
    label: 'Aggression',
    value: 'Aggression',
  },
  {
    label: 'Confusion of Time/Place',
    value: 'Confusion of Time/Place',
  },
  {
    label: 'Withdrawal',
    value: 'Withdrawal',
  },
  {
    label: 'Depression',
    value: 'Depression',
  },
  {
    label: 'Repetition',
    value: 'Repetition',
  },
  {
    label: 'Wandering',
    value: 'Wandering',
  },
  {
    label: 'Oriented',
    value: 'Oriented',
  },
  {
    label: 'Comatose',
    value: 'Comatose',
  },
];

export const mentalHealthIssuesOptions = [
  { label: 'Depression', value: 'Depression' },
  { label: 'Lethargy', value: 'Lethargy' },
  { label: 'Past/Current Substance Abuse', value: 'Past/Current Substance Abuse' },
];

export const medicalConditionOtherOptions = [
  {
    label: 'Smoker',
    value: 'SMOKER',
  },
  {
    label: 'Sensitive to Smell',
    value: 'SENSITIVE_SMELL',
  },
  {
    label: 'On Oxygen',
    value: 'ON_OXYGEN',
  },
  {
    label: 'Colostomy Bag',
    value: 'COLOSTOMY_BAG',
  },
  {
    label: 'Feeding Tube',
    value: 'FEEDING_TUBE',
  },
];

export const medicalConditionFunctionalLimitationOptions = [
  {
    label: 'Amputation',
    value: 'AMPUTATION',
  },
  {
    label: 'Bowel/Bladder (Incontinence)',
    value: 'BOWEL_BLADDER',
  },
  {
    label: 'Contracture',
    value: 'CONTRACTURE',
  },
  {
    label: 'Hearing',
    value: 'HEARING',
  },
  {
    label: 'Paralysis',
    value: 'PARALYSIS',
  },
  {
    label: 'Endurance',
    value: 'ENDURANCE',
  },
  {
    label: 'Ambulation',
    value: 'AMBULATION',
  },
  {
    label: 'Speech',
    value: 'SPEECH',
  },
  {
    label: 'Legally Blind',
    value: 'LEGALLY_BLIND',
  },
  {
    label: 'Dyspnea with Minimal Exertion',
    value: 'DYSPNEA_MINIMAL_EXERTION',
  },
];

export const prognosisOptions: IBaseOption[] = [
  {
    value: CarePlanPrognosisEnum.POOR,
    label: 'Poor',
  },
  {
    value: CarePlanPrognosisEnum.GUARDED,
    label: 'Guarded',
  },
  {
    value: CarePlanPrognosisEnum.FAIR,
    label: 'Fair',
  },
  {
    value: CarePlanPrognosisEnum.GOOD,
    label: 'Good',
  },
  {
    value: CarePlanPrognosisEnum.EXCELLENT,
    label: 'Excellent',
  },
];

export const incontinenceOptions = [
  {
    label: 'Urination',
    value: 'URINATION',
  },
  {
    label: 'Bowels',
    value: 'BOWELS',
  },
];

export const issuesOptions = [
  {
    label: 'Constipation',
    value: 'CONSTIPATION',
  },
  {
    label: 'Diarrhea',
    value: 'DIARRHEA',
  },
  {
    label: 'Urination',
    value: 'URINATION',
  },
];

export const mockWearsBriefOptions = [
  { label: 'Accident Protection', value: 'ACCIDENT_PROTECTION' },
  { label: 'Full Incontinence', value: 'FULL_INCONTINENCE' },
];

export const optionCheckBoxAids: IOptionCheckboxMultiple[] = [
  {
    value: AmbulationAidsEnum.CANE,
    label: 'Cane',
  },
  {
    value: AmbulationAidsEnum.WALKER,
    label: 'Walker',
  },
  {
    value: AmbulationAidsEnum.WHEEL_CHAIR,
    label: 'Wheel Chair',
  },
  {
    value: AmbulationAidsEnum.GERI_CHAIR,
    label: 'Geri-Chair',
  },
  {
    value: AmbulationAidsEnum.SCOOTER,
    label: 'Scooter',
  },
];

export const optionCheckBoxArmsOrHands: IOptionCheckboxMultiple[] = [
  {
    value: AmbulationArmsOrHandsEnum.LEFT,
    label: 'Left',
  },
  {
    value: AmbulationArmsOrHandsEnum.RIGHT,
    label: 'Right',
  },
];

export const mockOptionAllergies = [
  {
    label: 'ACE Inhibitors',
    value: 'ACE_INHIBITORS',
  },
  {
    label: 'Aciphex',
    value: 'ACIPHEX',
  },
  {
    label: 'Actonel',
    value: 'ACTONEL',
  },
  {
    label: 'Actos',
    value: 'ACTOS',
  },
  {
    label: 'Adhesive Tape',
    value: 'ADHESIVE_TAPE',
  },
  {
    label: 'Albuterol',
    value: 'ALBULTEROL',
  },
  {
    label: 'Allegra',
    value: 'ALLEGRA',
  },
  {
    label: 'Allopurinol',
    value: 'ALLOPURINOL',
  },
  {
    label: 'Almonds',
    value: 'ALMONDS',
  },
];

export const mockDataInvoice: IInvoice[] = [
  {
    id: 'EHC - 6985',
    avatarUrl: 'https://i.pravatar.cc/993',
    firstName: 'Brooklyn',
    lastName: 'Simmons',
    middleName: '',
    email: 'jackson.graham@example.com',
    amount: 9615.0,
    status: 'PENDING',
  },
  {
    id: 'EHC - 6984',
    avatarUrl:
      'https://images.pexels.com/photos/4769490/pexels-photo-4769490.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    firstName: 'Wade',
    lastName: 'Warren',
    middleName: '',
    email: 'tim.jennings@example.com',
    amount: 9983.0,
    status: 'PAID',
  },
];

export const clientLeftAloneOptions = [
  {
    label: 'Yes',
    value: 'YES',
  },
  {
    label: 'No',
    value: 'NO',
  },
];

export const outingsAllowedOptions = [
  {
    label: 'Yes',
    value: 'YES',
  },
  {
    label: 'No',
    value: 'NO',
  },
];

export const photosAllowedOptions = [
  {
    label: 'Yes',
    value: 'YES',
  },
  {
    label: 'No',
    value: 'NO',
  },
];

export const codeStatusOptions = [
  {
    label: 'Full Code',
    value: 'FULL_CODE',
  },
  {
    label: 'DNR',
    value: 'DNR',
  },
];

export const desiredOutcomeOptions = [
  {
    label: 'Member will be free of falls and/or injury',
    value: 'Member will be free of falls and/or injury',
  },
  {
    label: 'Member will remain clean and dry',
    value: 'Member will remain clean and dry',
  },
  {
    label: 'Member will be well nourished',
    value: 'Member will be well nourished',
  },
  {
    label: 'Member will maintain highest level of independence',
    value: 'Member will maintain highest level of independence',
  },
  {
    label: 'Nurse will ensure medication and/or treatments given per MD order, if applicable',
    value: 'Nurse will ensure medication and/or treatments given per MD order, if applicable',
  },
  {
    label: 'Member will be encouraged to participate in activities offered at center',
    value: 'Member will be encouraged to participate in activities offered at center',
  },
  {
    label: 'Member will have nursing oversight while at day center',
    value: 'Member will have nursing oversight while at day center',
  },
];

export const icdTenCodesOptions = [
  {
    label: 'R86.89',
    value: 'R86.89',
  },
  {
    label: 'Option 2',
    value: 'Option 2',
  },
];

export const optionDemoGraphicsGender = [
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
];

export const optionDemoGraphicsMaritalStatus = [
  {
    label: 'Married',
    value: 'MARRIED',
  },
  {
    label: 'Single',
    value: 'SINGLE',
  },
  {
    label: 'Widowed',
    value: 'WIDOWED',
  },
  {
    label: 'Separated',
    value: 'SEPARATED',
  },
  {
    label: 'Divorced',
    value: 'DIVORCED',
  },
];

export const optionDemoGraphicsRace = [
  {
    label: 'Caucasian',
    value: 'CAUCASIAN',
  },
  {
    label: 'African American',
    value: 'AFRICAN_AMERICAN',
  },
  {
    label: 'Asian',
    value: 'ASIAN',
  },
  {
    label: 'American Indian',
    value: 'AMERICAN_INDIAN',
  },
  {
    label: 'Pacific Islander',
    value: 'PACIFIC_ISLANDER',
  },
  {
    label: 'Hispanic',
    value: 'HISPANIC',
  },
  {
    label: 'Non-Hispanic',
    value: 'NON_HISPANIC',
  },
];

export const optionDemoGraphicsLivesWith = [
  {
    label: 'Spouse',
    value: 'SPOUSE',
  },
  {
    label: 'Parents',
    value: 'PARENTS',
  },
  {
    label: 'Family',
    value: 'FAMILY',
  },
  {
    label: 'Group Home',
    value: 'GROUP_HOME',
  },
  {
    label: 'Guardian/Other',
    value: 'GUARDIAN_OTHER',
  },
];

export const optionDemoGraphicsReligion = [
  {
    label: 'Christianity',
    value: 'CHRISTIANITY',
  },
  {
    label: 'Judaism',
    value: 'JUDAISM',
  },
  {
    label: 'Buddhism',
    value: 'BUDDHISM',
  },
  {
    label: 'Islam',
    value: 'ISLAM',
  },
  {
    label: 'Hinduism',
    value: 'HINDUISM',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const dietOptions = [
  {
    label: 'Regular Diet',
    value: 'REGULAR_DIET',
  },
  {
    label: 'Special Diet',
    value: 'SPECIAL_DIET',
  },
  {
    label: 'NPO',
    value: 'NPO',
  },
  {
    label: 'Enteral Feeding',
    value: 'ENTERAL_FEEDING',
  },
];

export const textureOptions = [
  {
    label: 'Regular',
    value: 'Regular',
  },
  {
    label: 'Chopped',
    value: 'Chopped',
  },
  {
    label: 'Soft',
    value: 'Soft',
  },
  {
    label: 'Pureed',
    value: 'Pureed',
  },
  {
    label: 'Liquid',
    value: 'Liquid',
  },
  {
    label: 'NPO',
    value: 'NPO',
  },
];

export const assistanceOptions = [
  {
    label: 'Independent',
    value: 'Independent',
  },
  {
    label: 'Fed by Staff',
    value: 'Fed by Staff',
  },
];

export const restrictionsOptions = [
  {
    label: 'No',
    value: 'NO',
  },
  {
    label: 'Yes, See Notes',
    value: 'YES_SEE_NOTES',
  },
];

export const lunchSnackProvidedOptions = [
  {
    label: 'Catered by ADHC',
    value: 'Catered by ADHC',
  },
  {
    label: 'Bring from home',
    value: 'Bring from home',
  },
  {
    label: 'NA',
    value: 'NA',
  },
];

export const optionActivityEncourage = [
  {
    label: 'Physical activity',
    value: 'PHYSICAL_ACTIVITY',
  },
  {
    label: 'Socialization',
    value: 'SOCIALIZATION',
  },
  {
    label: 'Emotional activities',
    value: 'EMOTIONAL_ACTIVITIES',
  },
  {
    label: 'Vocational activities',
    value: 'VOCATIONAL_ACTIVITIES',
  },
  {
    label: 'Spiritual activities',
    value: 'SPIRITUAL_ACTIVITIES',
  },
  {
    label: 'Intellectual activities',
    value: 'INTELLECTUAL_ACTIVITIES',
  },
  {
    label: 'Rest period as needed',
    value: 'REST_PERIOD_AS_NEEDED',
  },
  {
    label: 'Individual Activities',
    value: 'INDIVIDUAL_ACTIVITIES',
  },
  {
    label: 'Group Activities',
    value: 'GROUP_ACTIVITIES',
  },
  {
    label: 'Community Outings',
    value: 'COMMUNITY_OUTINGS',
  },
];

export const optionActivityEnjoys = [
  {
    label: 'Yes',
    value: 'YES',
  },
  {
    label: 'No',
    value: 'NO',
  },
];

export const optionActivityAssistance = [
  {
    label: 'Independent',
    value: 'INDEPENDENT',
  },
  {
    label: 'Set up/Direction',
    value: 'SET_UP_DIRECTION',
  },
  {
    label: 'Staff Member, hands on assist',
    value: 'STAFF_MEMBER_HANDS_ON_ASSIST',
  },
  {
    label: 'Total',
    value: 'TOTAL',
  },
];

export const carePlanReviewedOptions = [
  {
    label: 'Member',
    value: 'Member',
  },
  {
    label: 'Family Member/RP',
    value: 'Family Member/RP',
  },
  {
    label: 'Staff/Caregiver',
    value: 'Staff/Caregiver',
  },
];

export const memberRightReviewOptions = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
  {
    label: 'Copy sent home with member',
    value: 'Copy sent home with member',
  },
];

export const grievanceReviewedOptions = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
  {
    label: 'Copy sent home with member',
    value: 'Copy sent home with member',
  },
];

export const optionEliminationIncontinence = [
  {
    label: 'Bowel',
    value: 'BOWEL',
  },
  {
    label: 'Bladder',
    value: 'BLADDER',
  },
  {
    label: 'Total',
    value: 'TOTAL',
  },
  {
    label: 'Continent',
    value: 'CONTINENT',
  },
  {
    label: 'Urostomy',
    value: 'UROSTOMY',
  },
  {
    label: 'Colostomy',
    value: 'COLOSTOMY',
  },
  {
    label: 'Catheter',
    value: 'CATHETER',
  },
  {
    label: 'Incontinent at times',
    value: 'INCONTINENT AT TIMES',
  },
];

export const optionEliminationToileting = [
  {
    label: 'Independent',
    value: 'INDEPENDENT',
  },
  {
    label: 'Staff Assistance',
    value: 'STAFF_ASSISTANCE',
  },
  {
    label: 'Total Care',
    value: 'TOTAL_CARE',
  },
  {
    label: 'Prompting',
    value: 'PROMPTING',
  },
];

export const transportOptions = [
  {
    label: 'Modivcare',
    value: 'Modivcare',
  },
  {
    label: 'Family',
    value: 'Family',
  },
  {
    label: 'Group Home',
    value: 'Group Home',
  },
  {
    label: 'Elite Transportation (PP, VA or Voucher)',
    value: 'Elite Transportation (PP, VA or Voucher)',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const behaviorsOptions = [
  {
    label: 'Oriented',
    value: 'Oriented',
  },
  {
    label: 'Memory Loss',
    value: 'Memory Loss',
  },
  {
    label: 'Sensory Issues',
    value: 'Sensory Issues',
  },
  {
    label: 'Agitation',
    value: 'Agitation',
  },
  {
    label: 'Aggression',
    value: 'Aggression',
  },
  {
    label: 'Anxiety',
    value: 'Anxiety',
  },
  {
    label: 'Paranoia',
    value: 'Paranoia',
  },
  {
    label: 'Hallucinations',
    value: 'Hallucinations',
  },
  {
    label: 'Past/Current Substance Abuse',
    value: 'Past/Current Substance Abuse',
  },
  {
    label: 'Confusion of Time',
    value: 'Confusion of Time',
  },
  {
    label: 'Confusion of Place',
    value: 'Confusion of Place',
  },
  {
    label: 'Confusion of Person',
    value: 'Confusion of Person',
  },
  {
    label: 'Confusion of Situation',
    value: 'Confusion of Situation',
  },
  {
    label: 'Depression',
    value: 'Depression',
  },
  {
    label: 'Withdrawal',
    value: 'Withdrawal',
  },
  {
    label: 'Attention Seeking',
    value: 'Attention Seeking',
  },
  {
    label: 'Manipulation',
    value: 'Manipulation',
  },
  {
    label: 'Sexual Behaviors',
    value: 'Sexual Behaviors',
  },
];

export const exitSeekingOptions = [
  {
    label: 'Yes',
    value: 'YES',
  },
  {
    label: 'No',
    value: 'NO',
  },
];

export const dementiaDayCenterClientOptions = [
  {
    label: 'Yes',
    value: 'YES',
  },
  {
    label: 'No',
    value: 'NO',
  },
];

export const medicalConditionsHearingOptions = [
  {
    label: 'Good',
    value: MedicalConditionEnum.GOOD,
  },
  {
    label: 'Poor',
    value: MedicalConditionEnum.POOR,
  },
  {
    label: 'Deaf',
    value: MedicalConditionEnum.DEAF,
  },
  {
    label: 'Heading Aid',
    value: MedicalConditionEnum.HEARING_AID,
  },
];

export const medicalConditionsSpeechOptions = [
  {
    label: 'Good',
    value: MedicalConditionEnum.GOOD,
  },
  {
    label: 'Poor',
    value: MedicalConditionEnum.POOR,
  },
  {
    label: 'Nonverbal',
    value: MedicalConditionEnum.NONVERBAL,
  },
  {
    label: 'Sign Language',
    value: MedicalConditionEnum.SIGN_LANGUAGE,
  },
];

export const medicalConditionsVisionOptions = [
  {
    label: 'Good',
    value: MedicalConditionEnum.GOOD,
  },
  {
    label: 'Poor',
    value: MedicalConditionEnum.POOR,
  },
  {
    label: 'Blind',
    value: MedicalConditionEnum.BLIND,
  },
  {
    label: 'Glasses',
    value: MedicalConditionEnum.GLASSES,
  },
];

export const medicalConditionsSwallowingOptions = [
  {
    label: 'Good',
    value: MedicalConditionEnum.GOOD,
  },
  {
    label: 'Dysphagia Dx',
    value: MedicalConditionEnum.DYSPHAGIA_DX,
  },
  {
    label: 'None',
    value: MedicalConditionEnum.NONE,
  },
];

export const medicalConditionsOtherOptions = [
  {
    label: 'Smoker',
    value: MedicalConditionEnum.SMOKER,
  },
  {
    label: 'On Oxygen',
    value: MedicalConditionEnum.ON_OXYGEN,
  },
  {
    label: 'Feeding Tube',
    value: MedicalConditionEnum.FEEDING_TUBE,
  },
  {
    label: 'Colostomy',
    value: MedicalConditionEnum.COLOSTOMY,
  },
  {
    label: 'Urinary Catheter - Any',
    value: MedicalConditionEnum.URINARY_CATHETER_ANY,
  },
  {
    label: 'Trachestomy',
    value: MedicalConditionEnum.TRACHESTOMY,
  },
];

export const optionMedicationsTakesMedication = [
  {
    label: 'Whole',
    value: 'WHOLE',
  },
  {
    label: 'Crushed',
    value: 'CRUSHED',
  },
  {
    label: 'Via G-Tube',
    value: 'VIA_G_TUBE',
  },
  {
    label: 'Mixed with food/drink',
    value: 'MIXED_WITH_FOOD_DRINK',
  },
];

export const optionMedicationsTakenAtCenter = [
  {
    label: 'Yes',
    value: 'YES',
  },
  {
    label: 'No',
    value: 'NO',
  },
];

export const optionPrimaryDiagnosisDayCenterClient = [
  {
    label: 'Alzheimers/Dementia',
    value: 'ALZHEIMERS_DEMENTIA',
  },
  {
    label: 'Down Syndrome',
    value: 'DOWN_SYNDROME',
  },
  {
    label: 'Autism',
    value: 'AUTISM',
  },
  {
    label: 'Intellectual Disability',
    value: 'INTELLECTUAL_DISABILITY',
  },
  {
    label: 'CHF',
    value: 'CHF',
  },
  {
    label: 'COPD',
    value: 'COPD',
  },
  {
    label: 'Diabetes',
    value: 'DIABETES',
  },
  {
    label: 'Heart Disease',
    value: 'HEART_DISEASE',
  },
  {
    label: 'Stroke',
    value: 'STROKE',
  },
  {
    label: 'Cancer',
    value: 'CANCER',
  },
];

export const optionAmbulationLevel = [
  {
    label: 'Ambulatory',
    value: 'AMBULATORY',
  },
  {
    label: 'Non-Ambulatory',
    value: 'NON-AMBULATORY',
  },
  {
    label: 'Amputation',
    value: 'AMPUTATION',
  },
  {
    label: 'Paralysis',
    value: 'PARALYSIS',
  },
];

export const optionAmbulationDevices = [
  {
    label: 'None',
    value: 'NONE',
  },
  {
    label: 'Walker',
    value: 'WALKER',
  },
  {
    label: 'Rollator',
    value: 'ROLLATOR',
  },
  {
    label: 'Cane',
    value: 'CANE',
  },
  {
    label: 'Standard WC',
    value: 'STANDARD_WC',
  },
  {
    label: 'Power WC',
    value: 'POWER_WC',
  },
  {
    label: 'Geri-Chair',
    value: 'GERI_CHAIR',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const optionAmbulationAssistance = [
  {
    label: 'Yes',
    value: 'YES',
  },
  {
    label: 'No',
    value: 'NO',
  },
  {
    label: 'At Times',
    value: 'AT_TIME',
  },
];

export const optionAmbulationTransfers = [
  {
    label: 'Independent',
    value: 'INDEPENDENT',
  },
  {
    label: 'With Staff Assistance',
    value: 'WITH_STAFF_ASSISTANCE',
  },
  {
    label: 'Gait Belt',
    value: 'GAIT_BELT',
  },
  {
    label: 'Hoyer Lift',
    value: 'HOYER_LIFT',
  },
];

export const optionAmbulationFallRisk = [
  {
    label: 'Yes',
    value: 'YES',
  },

  {
    label: 'No',
    value: 'NO',
  },
];

export const optionAmbulationElopementRisk = [
  {
    label: 'Yes',
    value: 'YES',
  },

  {
    label: 'No',
    value: 'NO',
  },
];

export const optionsMedicalConditionHearing: IOptionRadioCheckbox[] = [
  {
    label: 'Good',
    value: MedicalConditionEnum.GOOD,
    type: 'radio',
  },
  {
    label: 'Poor',
    value: MedicalConditionEnum.POOR,
    type: 'radio',
  },
  {
    label: 'Deaf',
    value: MedicalConditionEnum.DEAF,
    type: 'radio',
  },
  {
    label: 'Heading Aid',
    value: MedicalConditionEnum.HEARING_AID,
    type: 'checkbox',
  },
];

export const optionsMedicalConditionSpeech: IOptionRadioCheckbox[] = [
  {
    label: 'Good',
    value: MedicalConditionEnum.GOOD,
    type: 'radio',
  },
  {
    label: 'Poor',
    value: MedicalConditionEnum.POOR,
    type: 'radio',
  },
  {
    label: 'None',
    value: MedicalConditionEnum.NONE,
    type: 'radio',
  },
];

export const optionsMedicalConditionVision: IOptionRadioCheckbox[] = [
  {
    label: 'Good',
    value: MedicalConditionEnum.GOOD,
    type: 'radio',
  },
  {
    label: 'Poor',
    value: MedicalConditionEnum.POOR,
    type: 'radio',
  },
  {
    label: 'Blind',
    value: MedicalConditionEnum.BLIND,
    type: 'radio',
  },
  {
    label: 'Glasses',
    value: MedicalConditionEnum.GLASSES,
    type: 'checkbox',
  },
];

export const optionsMedicalConditionSwallowing: IOptionRadioCheckbox[] = [
  {
    label: 'Good',
    value: MedicalConditionEnum.GOOD,
    type: 'radio',
  },
  {
    label: 'Poor',
    value: MedicalConditionEnum.POOR,
    type: 'radio',
  },
  {
    label: 'None',
    value: MedicalConditionEnum.NONE,
    type: 'radio',
  },
];
