// Libs
import classNames from 'classnames/bind';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { DropdownMenu } from '~/components';
// Others
import { IChildRouteModel, IRouteModel } from '~/utils/interface/common';
import { DEFAULT_NUMBER_ZERO } from '~/utils/constants/common';
import { hasActiveChild } from '~/utils/helper';
// Styles, images, icons
import styles from './MenuItem.module.scss';
import { icons } from '~/assets';

type Props = {
  menu: IChildRouteModel;
  isSearchExpanded?: boolean;
  depthLevel: number;
  isDropdownMenu: boolean;
  menuIndex?: number;
  onMouseEnter?: (index: number) => void;
};

const cx = classNames.bind(styles);

const MenuItem = (props: Props) => {
  //#region Destructuring Props
  const { menu, isSearchExpanded, depthLevel = 0, isDropdownMenu, onMouseEnter, menuIndex } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const menuRef = useRef<HTMLLIElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isDropdown, setIsDropdown] = useState<boolean>(false);
  const [isActiveSubmenu, setIsActiveSubmenu] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    setIsDropdown(isDropdownMenu);
  }, [isDropdownMenu]);

  useEffect(() => {
    if (!menu.children || !location) {
      return;
    }

    const isActiveInitial = hasActiveChild(menu, location.pathname);
    setIsActiveSubmenu(isActiveInitial);
    setIsDropdown(false);
  }, [location, menu.children]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleMouseEnter = () => {
    if (onMouseEnter) {
      onMouseEnter(menuIndex!);
    }

    setIsDropdown(true);
  };

  const findFirstPathInChild = (children: IRouteModel[]): string | null => {
    if (!Array.isArray(children) || children.length === DEFAULT_NUMBER_ZERO) return null;

    const firstChild = children[DEFAULT_NUMBER_ZERO];

    if (firstChild.children) {
      return findFirstPathInChild(firstChild.children);
    }

    if (firstChild.path) return firstChild.path;

    return null;
  };

  const handleButtonClick = () => {
    if (menu?.children && Array.isArray(menu?.children)) {
      const firstPath = findFirstPathInChild(menu.children);

      firstPath && navigate(firstPath);
    }

    setIsDropdown((prev) => !prev);
  };

  const handleMouseLeave = () => {
    setIsDropdown(false);
  };
  //#endregion Handle Function

  return (
    <li className={cx('menuItem')} ref={menuRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {menu.children ? (
        <>
          <button
            onClick={handleButtonClick}
            aria-expanded={isDropdown}
            type='button'
            className={cx(
              'navLink',
              depthLevel > DEFAULT_NUMBER_ZERO && 'btnSubMenu',
              isSearchExpanded && 'expanded',
              isActiveSubmenu && 'navLinkActive',
              isActiveSubmenu && depthLevel > DEFAULT_NUMBER_ZERO && 'navLinkMenuActive'
            )}
          >
            <p className={cx('itemView')}>
              {t(menu.name as string)}
              {depthLevel > DEFAULT_NUMBER_ZERO && (
                <img className={cx('iconArrowMenu')} src={icons.commonIconArrowMenu} alt={t('common_img_text_alt')} />
              )}
            </p>
          </button>

          {isDropdown && menu.children && (
            <DropdownMenu ref={menuRef} subMenus={menu.children} isDropdownMenu={isDropdown} depthLevel={depthLevel} />
          )}
        </>
      ) : (
        <NavLink
          key={menu.path}
          to={menu.path}
          end={menu.index}
          className={({ isActive }) =>
            cx(
              'navLink',
              (isActive || isActiveSubmenu) && 'navLinkActive',
              isSearchExpanded && 'expanded',
              depthLevel > DEFAULT_NUMBER_ZERO && 'btnSubMenu',
              isActive && depthLevel > DEFAULT_NUMBER_ZERO && 'navLinkMenuActive'
            )
          }
        >
          {t(menu.name as string)}
        </NavLink>
      )}
    </li>
  );
};

export default MenuItem;
