import { urlApiDashboard } from '~/utils/constants/api';
import { BaseResponse } from '~/utils/interface/common';
import axiosClient from './axiosClient';
import { IStatisticTotal } from '~/utils/interface/dashboard';

const dashboardApi = {
  getTotal() {
    const url = `${urlApiDashboard.statistics}`;
    return axiosClient.get<BaseResponse<IStatisticTotal>>(url);
  },
};

export default dashboardApi;
