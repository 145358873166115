// Libs
import classNames from 'classnames/bind';
// Components, Layouts, Pages
import { AvatarOverlap } from '~/components';
// Others
import { IEventSchedule } from '~/utils/interface/schedule';
import { convertTime } from '~/utils/helper';
import { TimeFormatEnum } from '~/utils/enum';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './CustomEvent.module.scss';

type Props = {
  event: IEventSchedule;
};

const cx = classNames.bind(styles);

const CustomEvent = (props: Props) => {
  //#region Destructuring Props
  const { event } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='customEventMonth' className={cx('customEventMonth', event.color)}>
      <div className={cx('customEventMonthWrap')}>
        <div className={cx('titleGroup', event.color)}>
          <p className={cx('title', event.color)}> {event.title || EMPTY_STRING}</p>

          <p className={cx('eventTime', event.color)}>
            {`${convertTime(event.start, TimeFormatEnum.HOUR_MINUTE_AM_PM)} - ${convertTime(
              event.end,
              TimeFormatEnum.HOUR_MINUTE_AM_PM
            )}`}
          </p>
        </div>

        {event.assignee && (
          <div className={cx('assignee', event.color)}>
            <AvatarOverlap assignees={event.assignee} color={event.color} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomEvent;
