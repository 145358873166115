//Libs
import { createSlice } from '@reduxjs/toolkit';

import {
  createShiftSchedule,
  getListSchedule,
  deleteSchedule,
  getDetailSchedule,
  updateSchedule,
  getScheduleClient,
  getOfficeSchedule,
  getListShiftSchedule,
} from '~/thunks/schedule/scheduleThunk';
import { RootState } from '~/redux/store';

export interface ScheduleState {
  isRefreshScheduleList: boolean;
  isRefreshOfficeSchedule: boolean;
}

const initialState: ScheduleState = {
  isRefreshScheduleList: false,
  isRefreshOfficeSchedule: false,
};

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setRefreshScheduleList(state, action) {
      state.isRefreshScheduleList = action.payload;
    },
    setRefreshOfficeSchedule(state, action) {
      state.isRefreshOfficeSchedule = action.payload;
    },
  },
  extraReducers(builder) {
    // Create Shift Schedule
    builder
      .addCase(createShiftSchedule.pending, (state) => {})
      .addCase(createShiftSchedule.fulfilled, (state) => {})
      .addCase(createShiftSchedule.rejected, (state) => {});

    // Get List Shift Schedule
    builder
      .addCase(getListShiftSchedule.pending, (state) => {})
      .addCase(getListShiftSchedule.fulfilled, (state) => {})
      .addCase(getListShiftSchedule.rejected, (state) => {});

    // Get: list Schedule
    builder
      .addCase(getListSchedule.pending, (state, action) => {})
      .addCase(getListSchedule.fulfilled, (state, action) => {})
      .addCase(getListSchedule.rejected, (state, action) => {});

    // Delete Schedule
    builder
      .addCase(deleteSchedule.pending, (state) => {})
      .addCase(deleteSchedule.fulfilled, (state) => {})
      .addCase(deleteSchedule.rejected, (state) => {});

    // Detail Schedule
    builder
      .addCase(getDetailSchedule.pending, (state) => {})
      .addCase(getDetailSchedule.fulfilled, (state) => {})
      .addCase(getDetailSchedule.rejected, (state) => {});

    // Update Schedule
    builder
      .addCase(updateSchedule.pending, (state) => {})
      .addCase(updateSchedule.fulfilled, (state) => {})
      .addCase(updateSchedule.rejected, (state) => {});

    // Get Schedule Client
    builder
      .addCase(getScheduleClient.pending, (state) => {})
      .addCase(getScheduleClient.fulfilled, (state) => {})
      .addCase(getScheduleClient.rejected, (state) => {});

    // Get Office Schedule
    builder
      .addCase(getOfficeSchedule.pending, (state) => {})
      .addCase(getOfficeSchedule.fulfilled, (state) => {})
      .addCase(getOfficeSchedule.rejected, (state) => {});
  },
});

export const scheduleActions = scheduleSlice.actions;

export const selectIsRefreshScheduleList = (state: RootState) => state.scheduleState.isRefreshScheduleList;

const scheduleReducer = scheduleSlice.reducer;

export default scheduleReducer;
