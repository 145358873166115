// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Components, Layouts, Pages
import {
  AddApplicantModal,
  BaseButton,
  BaseFilter,
  BasePagination,
  BaseTable,
  ButtonStatus,
  ImageCircle,
  InputSearch,
  Modal,
  ModalUnderDevelopment,
  ToolBar,
} from '~/components';
// Others
import {
  ColumnTableType,
  IFilterValueChange,
  IListDataResponse,
  IListQueryParams,
  IPaginationResponse,
} from '~/utils/interface/common';
import {
  ButtonTypeEnum,
  FieldTypeApplicantEnum,
  FilterApplicantEnum,
  ImageCircleTypeEnum,
  statusEnum,
  TranslationEnum,
} from '~/utils/enum';
import { convertEnumToString, getUserName } from '~/utils/helper';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_FILTER_APPLICANT,
  DEFAULT_LIMIT_PAGE,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { IApplicant, IDefaultFilterApplicant, IQueryListApplicant } from '~/utils/interface/applicant';
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import useDebounce from '~/utils/customHook';
import { getListApplicant } from '~/thunks/applicant/applicantThunk';
import { applicantActions, selectIsRefreshApplicantList } from '~/thunks/applicant/applicantSlice';
// Styles, images, icons
import styles from './RequestApplicant.module.scss';
import { icons } from '~/assets';

type Props = {
  role: string;
};

const cx = classNames.bind(styles);

const columns = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<IApplicant>[] => {
  return [
    {
      key: 'name',
      title: t('caregiver_table_title_name'),
      render(_, record: IApplicant) {
        return !record.firstName && !record.lastName ? (
          <>{EMPTY_STRING}</>
        ) : (
          <>
            <ImageCircle
              type={record?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
              imageUrl={record?.avatarUrl}
              firstName={record?.firstName}
              lastName={record?.lastName}
              width={24}
              height={24}
              fontSize={10}
              margin={'0 6px 0 0'}
            />
            <span>{getUserName(record?.firstName, record?.lastName)}</span>
          </>
        );
      },
    },
    {
      key: 'address',
      title: t('caregiver_table_title_address'),
      dataIndex: 'address',
      render(_, record: IApplicant) {
        return (
          <div className={cx('textBase')}>
            {record?.address ? <span>{record.address}</span> : <div>{EMPTY_STRING}</div>}
          </div>
        );
      },
    },
    {
      key: 'email',
      title: t('caregiver_table_title_email'),
      dataIndex: 'email',
      render(_, record: IApplicant) {
        return (
          <div className={cx('textBase')}>
            {record?.email ? <span>{record.email}</span> : <div>{EMPTY_STRING}</div>}
          </div>
        );
      },
    },
    {
      key: 'source',
      title: t('caregiver_table_title_source'),
      dataIndex: 'source',
      render(_, record: IApplicant) {
        return (
          <div className={cx('textBase')}>
            {record?.source ? <span>{record.source}</span> : <div>{EMPTY_STRING}</div>}
          </div>
        );
      },
    },
    {
      key: 'phoneNumber',
      title: t('caregiver_table_title_phone_number'),
      dataIndex: 'phoneNumber',
      render(_, record: IApplicant) {
        return (
          <div className={cx('textBase')}>
            {record?.phoneNumber ? <span>{record.phoneNumber}</span> : <div>{EMPTY_STRING}</div>}
          </div>
        );
      },
    },
    {
      key: 'status',
      title: t('caregiver_table_title_phone_status'),
      render(_) {
        return (
          <div className={cx('buttonBase')} onClick={(e) => e.stopPropagation()}>
            <ButtonStatus width={72} borderRadius={6} color='teal800' label={statusEnum.APPROVE} />
            <ButtonStatus width={72} borderRadius={6} color='gray300' label={statusEnum.REJECT} />
          </div>
        );
      },
      width: '12%',
    },
  ];
};

const RequestApplicant = (props: Props) => {
  //#region Destructuring Props
  const { role } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  //#endregion Declare Hook

  //#region Selector
  const isRefreshApplicantList = useSelector(selectIsRefreshApplicantList);
  //#endregion Selector

  //#region Declare State
  const [hasInteracted, setHasInteracted] = useState<boolean>(false);
  const [isOpenAddApplication, setIsOpenAddApplication] = useState<boolean>(false);
  const [applicantList, setApplicantList] = useState<IApplicant[]>([]);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  const renderSearchKey = useMemo(() => {
    if (!hasInteracted && textSearchParams) {
      return textSearchParams;
    }

    if (hasInteracted && searchKey === EMPTY_STRING) {
      return EMPTY_STRING;
    }

    if (searchKey) {
      return searchKey;
    }

    return EMPTY_STRING;
  }, [searchKey, textSearchParams, hasInteracted]);
  const [filterParams, setFilterParams] = useState<IQueryListApplicant>(DEFAULT_FILTER_APPLICANT);
  const debouncedSearchKey = useDebounce<string>(renderSearchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  const [paramObject, setParamObject] = useState<IListQueryParams>({
    page: pageSelected,
    limit: DEFAULT_LIMIT_PAGE,
  });

  const fieldsText = useMemo(() => {
    return filterParams.fields
      ? filterParams.fields.map((field) => convertEnumToString(field)).join(', ')
      : EMPTY_STRING;
  }, [filterParams.fields]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const newParamObject: IListQueryParams = {
      ...paramObject,
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
    };
    handleGetListApplicant(newParamObject);
    const newParam = debouncedSearchKey
      ? {
          ...params,
          page: paramObject.page.toString(),
          limit: DEFAULT_LIMIT_PAGE.toString(),
          //textSearch: debouncedSearchKey,
        }
      : { page: paramObject.page.toString(), limit: DEFAULT_LIMIT_PAGE.toString() };

    setSearchParams(newParam);
  }, [paramObject]);

  useEffect(() => {
    if (!pagination) return;
    setParamObject({
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
      ...(filterParams ? { ...filterParams } : {}),
    });
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshApplicantList) {
      return;
    }

    handleGetListApplicant(paramObject);
    dispatch(applicantActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshApplicantList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListApplicant = (payload: IListQueryParams) => {
    loading?.show();

    dispatch(getListApplicant(payload))
      .unwrap()
      .then((res) => {
        loading?.hide();
        const { responses, pagination }: IListDataResponse<IApplicant[]> = res?.data;
        setApplicantList(responses);
        setPagination(pagination);
      })
      .catch((err) => {
        loading?.hide();
      });
  };

  // const handleBack = () => {
  //   navigate(getRoutesByRole(role)?.caregiver ?? EMPTY_STRING);
  // };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
    setHasInteracted(true);
  };

  const handleAddApplicant = () => {
    // TODO: Handle add applicant
    setIsOpenAddApplication(!isOpenAddApplication);
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IListQueryParams = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({ ...params, page: page.toString(), limit: `${DEFAULT_LIMIT_PAGE}` });
  };

  const handleFilterChange = (appliedFilter: IDefaultFilterApplicant | undefined) => {
    if (!appliedFilter) {
      setFilterParams({});
      return;
    }

    setFilterParams({
      ...appliedFilter,
    });

    if (debouncedSearchKey)
      setParamObject({
        ...paramObject,
        ...appliedFilter,
      });
  };

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    onChange: (data: IFilterValueChange<IDefaultFilterApplicant>) => void,
    valueFilter: IDefaultFilterApplicant | undefined
  ) => {
    const { name, value, checked } = event.target;
    const newFields = {
      ...filterParams,
      fields: checked
        ? [...(valueFilter?.fields ?? []), value]
        : (valueFilter?.fields ?? []).filter((field) => field !== value),
    };

    if (name === FilterApplicantEnum.FIELDS) {
      onChange({ name: FilterApplicantEnum.FIELDS, value: newFields.fields });
    }
  };

  const handleClickUnderdevelopment = () => {
    setIsShowUnderDevelopment(!isShowUnderDevelopment);
  };

  const handleApplicantRowClick = (data: IApplicant) => {
    handleClickUnderdevelopment();
  };
  //#endregion Handle Function

  return (
    <div id='requestApplicantPage' className={cx('requestApplicantContainer')}>
      <div className={cx('headerToolBar')}>
        <ToolBar title={t('caregiver_table_title_page_request_applicant')}>
          {/* <BaseButton iconLeft={icons.commonIconSort} text={t('common_text_filter')} width={67} height={36} /> */}
          <InputSearch
            height={36}
            placeholder={t('caregiver_page_search_title_request_applicant', {
              params: fieldsText,
            })}
            onChange={handleChangeSearch}
            //value={textSearchParams}
          />

          <BaseFilter<IDefaultFilterApplicant>
            onApply={handleFilterChange}
            defaultValue={DEFAULT_FILTER_APPLICANT}
            valueFilter={filterParams}
          >
            {({ valueFilter, onChange }) => {
              return (
                <div className={cx('filterWrap')}>
                  <div className={cx('filterItemCheckbox')}>
                    <input
                      id='address'
                      type='checkbox'
                      name='fields'
                      onChange={(e) => {
                        handleCheckboxChange(e, onChange, valueFilter);
                      }}
                      value={FieldTypeApplicantEnum.ADDRESS}
                      checked={valueFilter?.fields?.includes(FieldTypeApplicantEnum.ADDRESS) || false}
                    />
                    <label htmlFor='address' className={cx('labelCheckbox')}>
                      {t('applicant_btn_filter_address_label')}
                    </label>
                  </div>

                  <div className={cx('filterItemCheckbox')}>
                    <input
                      id='email'
                      type='checkbox'
                      name='fields'
                      onChange={(e) => {
                        handleCheckboxChange(e, onChange, valueFilter);
                      }}
                      value={FieldTypeApplicantEnum.EMAIL}
                      checked={valueFilter?.fields?.includes(FieldTypeApplicantEnum.EMAIL) || false}
                    />
                    <label htmlFor='email' className={cx('labelCheckbox')}>
                      {t('applicant_btn_filter_email_label')}
                    </label>
                  </div>

                  <div className={cx('filterItemCheckbox')}>
                    <input
                      id='source'
                      type='checkbox'
                      name='fields'
                      onChange={(e) => {
                        handleCheckboxChange(e, onChange, valueFilter);
                      }}
                      value={FieldTypeApplicantEnum.SOURCE}
                      checked={valueFilter?.fields?.includes(FieldTypeApplicantEnum.SOURCE) || false}
                    />
                    <label htmlFor='source' className={cx('labelCheckbox')}>
                      {t('applicant_btn_filter_source_label')}
                    </label>
                  </div>

                  <div className={cx('filterItemCheckbox')}>
                    <input
                      id='phoneNumber'
                      type='checkbox'
                      name='fields'
                      onChange={(e) => {
                        handleCheckboxChange(e, onChange, valueFilter);
                      }}
                      value={FieldTypeApplicantEnum.PHONE_NUMBER}
                      checked={valueFilter?.fields?.includes(FieldTypeApplicantEnum.PHONE_NUMBER) || false}
                    />
                    <label htmlFor='phoneNumber' className={cx('labelCheckbox')}>
                      {t('applicant_btn_filter_phone_number_label')}
                    </label>
                  </div>
                </div>
              );
            }}
          </BaseFilter>

          <BaseButton
            typeStyle={ButtonTypeEnum.PRIMARY}
            iconLeft={icons.commonIconPlus}
            text={t('caregiver_button_title_add_applicant')}
            onClick={handleAddApplicant}
            width={130}
            height={36}
          />
        </ToolBar>
      </div>

      <div className={cx('baseTable')}>
        <BaseTable columns={columns(t)} dataSource={applicantList} onClickRow={handleApplicantRowClick} />
      </div>

      <div className={cx('paginationTable')}>
        <BasePagination
          onChange={handlePaginationChange}
          defaultCurrentPage={pageSelected}
          totalItems={pagination?.totalItems}
          totalPages={pagination?.totalPages}
        />
      </div>

      <Modal
        title={t('caregiver_applicant_modal_add_applicant_title')}
        isOpen={isOpenAddApplication}
        onClose={handleAddApplicant}
      >
        <AddApplicantModal onClose={handleAddApplicant} />
      </Modal>

      {isShowUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderdevelopment} />}
    </div>
  );
};

export default RequestApplicant;
