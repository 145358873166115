// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ReactCalendarGroupRendererProps, ReactCalendarItemRendererProps } from 'react-calendar-timeline';
import { EventProps, View, Views } from 'react-big-calendar';
// Components, Layouts, Pages
import { ScheduleTimeline, GroupTimeline, EventDailyTimeline, BaseSchedule, ReactPortal } from '~/components';
// Others
import { BaseCalendarProps, TCalendarViewMode, TPageViewSchedule } from '~/utils/type/schedule';
import {
  IOfficeSchedule,
  IScheduleEvent,
  IScheduleTimelineData,
  IScheduleTimelineEvent,
  ITimelineEvent,
  ITimelineGroup,
  ITimelinePreparedData,
} from '~/utils/interface/schedule';
import { handleBackgroundColor, prepareMonthOfficeEventsData, prepareTimelineEventsData } from '../helper';
import { DEFAULT_NUMBER_ONE, EMPTY_STRING } from '~/utils/constants/common';
import { Tooltip } from 'react-tooltip';
import { PageViewScheduleEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './ViewSchedule.module.scss';
import './ViewSchedule.scss';

type Props = Omit<BaseCalendarProps, 'view' | 'date' | 'onSelectEvent' | 'events'> & {
  view: View | TCalendarViewMode;
  date: string;
  pageView?: TPageViewSchedule;
  events: IScheduleEvent[] | IScheduleTimelineData[] | IOfficeSchedule[];
  onSelectEvent?: (event: IScheduleEvent | IScheduleTimelineEvent | IOfficeSchedule) => void;
};

const cx = classNames.bind(styles);

const CollapseMonthView = (props: { event: IOfficeSchedule }) => {
  const { event } = props;

  const eventName = event.task?.description || EMPTY_STRING;
  const id = event.id || EMPTY_STRING;
  const backgroundColor = handleBackgroundColor({ label: event.type, bgOpacity: 0.1 });
  const color = handleBackgroundColor({ label: event.type });

  return (
    <div
      className={cx('eventContainer', 'eventContainerCollapse')}
      style={{ borderColor: color, backgroundColor: backgroundColor }}
      data-tooltip-id={`taskName-${id}`}
      data-tooltip-place='top'
    >
      <div className={cx('eventName')}>{eventName}</div>
      <ReactPortal wrapperId='taskTooltip'>
        <Tooltip id={`taskName-${id}`} className={cx('tooltipWrap')}>
          <div className={cx('tooltip')}>{eventName}</div>
        </Tooltip>
      </ReactPortal>
    </div>
  );
};

const ExpandMonthView = (props: { event: IOfficeSchedule }) => {
  const { event } = props;

  const eventName = event.task?.description || EMPTY_STRING;
  const id = event.id || EMPTY_STRING;
  const backgroundColor = handleBackgroundColor({ label: event.type, bgOpacity: 0.1 });
  const color = handleBackgroundColor({ label: event.type });
  return (
    <div
      className={cx('eventContainer', 'eventContainerExpand')}
      style={{ borderColor: color, backgroundColor: backgroundColor }}
    >
      <div className={cx('eventNameExpand')} data-tooltip-id={`taskName-${id}`} data-tooltip-place='top'>
        {eventName}
      </div>

      <ReactPortal wrapperId='taskTooltip'>
        <Tooltip id={`taskName-${id}`} className={cx('tooltipWrap')}>
          <div className={cx('tooltip')}>{eventName}</div>
        </Tooltip>
      </ReactPortal>
    </div>
  );
};

const ViewSchedule = (props: Props) => {
  //#region Destructuring Props
  const { view, date, events, onSelectEvent, pageView, ...restProps } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const preparedMonthEvents = useMemo(() => {
    if (view !== Views.MONTH) return;
    switch (pageView) {
      case PageViewScheduleEnum.OFFICE:
        return prepareMonthOfficeEventsData(events as IOfficeSchedule[]);

      case PageViewScheduleEnum.OPEN_SHIFT:
        return prepareMonthOfficeEventsData(events as IOfficeSchedule[]);

      default:
        break;
    }
  }, [view, events, pageView]);

  const preparedTimelineEvents: ITimelinePreparedData | undefined = useMemo(() => {
    if (view !== Views.WEEK && view !== Views.DAY) return;
    return prepareTimelineEventsData(props.events as IScheduleTimelineData[], view);
  }, [view, events]);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const renderScheduleByView = () => {
    if (
      preparedMonthEvents &&
      view === Views.MONTH &&
      (pageView === PageViewScheduleEnum.OFFICE || pageView === PageViewScheduleEnum.OPEN_SHIFT)
    ) {
      return (
        <div className={cx({ 'h-[780px]': view === Views.MONTH })}>
          {
            <BaseSchedule
              {...(restProps as Omit<typeof restProps, 'ref'>)}
              events={preparedMonthEvents}
              selectable={true}
              toolbar={false}
              date={date}
              view={view}
              components={{
                event: ({ event }: EventProps) => {
                  return (
                    <>
                      {view === Views.MONTH && (
                        <div className={cx('monthEventWrap')}>
                          {event?.resource?.map((eventData: IScheduleEvent, index: number) => (
                            <div key={index} onClick={() => onSelectEvent && onSelectEvent(eventData)}>
                              {event.resource.length > DEFAULT_NUMBER_ONE ? (
                                <CollapseMonthView event={eventData} />
                              ) : (
                                <ExpandMonthView event={eventData} />
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  );
                },
              }}
            />
          }
        </div>
      );
    } else if (
      preparedTimelineEvents &&
      (view === Views.WEEK || view === Views.DAY) &&
      (pageView === PageViewScheduleEnum.CLIENTS || pageView === PageViewScheduleEnum.CAREGIVER)
    ) {
      return (
        <ScheduleTimeline
          date={date}
          view={view}
          items={preparedTimelineEvents.event}
          groups={preparedTimelineEvents.group}
          itemRenderer={(props: ReactCalendarItemRendererProps<ITimelineEvent>) => (
            <>
              <EventDailyTimeline
                eventData={props.item}
                viewType={view}
                onSelectEvent={(data) => onSelectEvent && onSelectEvent(data)}
                {...props}
              />
            </>
          )}
          groupRenderer={(props: ReactCalendarGroupRendererProps<ITimelineGroup>) => (
            <GroupTimeline {...props} groupData={props?.group} />
          )}
        />
      );
    } else {
      // Handle Later
      return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
    }
  };
  //#endregion Handle Function
  return (
    <div id='viewScheduleComponent' className={cx('container')}>
      {renderScheduleByView()}
    </div>
  );
};

export default ViewSchedule;
