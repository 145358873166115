// Libs
import classNames from 'classnames/bind';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
// Components, Layouts, Pages
import { BaseButton, BaseInput } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { changePassword } from '~/thunks/auth/authThunk';
import { IFormChangePassword } from '~/utils/interface/auth';
import { EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum, InputTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './ChangePassword.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      currentPassword: yup
        .string()
        .trim()
        .required(t('change_password_current_password_required'))
        .min(8, t('change_password_password_min'))
        .max(100, t('change_password_password_max'))
        .test('not-same-as-new', t('change_password_different_new_password'), function (value) {
          const { newPassword } = this.parent;
          return !(value && newPassword && value === newPassword);
        }),
      newPassword: yup
        .string()
        .trim()
        .required(t('change_password_new_password_required'))
        .min(8, t('change_password_password_min'))
        .max(100, t('change_password_password_max'))
        .test('not-same-as-current', t('change_password_different_current_password'), function (value) {
          const { currentPassword } = this.parent;
          if (value && value === currentPassword) {
            return false;
          }
          return true;
        }),
      confirmNewPassword: yup
        .string()
        .trim()
        .required(t('change_password_confirm_new_password_required'))
        .test('not-same-as-new', t('new_password_confirm_new_password_not_match'), function (value) {
          const { newPassword } = this.parent;
          return value && newPassword && value === newPassword;
        }),
    })
    .required();
};

const defaultValues: IFormChangePassword = {
  currentPassword: EMPTY_STRING,
  newPassword: EMPTY_STRING,
  confirmNewPassword: EMPTY_STRING,
};

const ChangePassword = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingData = useContext(LoadingData);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormChangePassword>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleResetForm = () => {
    reset(defaultValues);
  };

  const handleChangePassword = (data: IFormChangePassword) => {
    if (!data) return;

    const { confirmNewPassword, ...rest } = data;

    loadingData?.show();

    dispatch(changePassword(rest))
      .unwrap()
      .then((res) => {
        handleResetForm();
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='changePasswordPage' className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>{t('change_password_title')}</div>
      </div>

      <div className={cx('body')}>
        <form className={cx('form')} onSubmit={handleSubmit(handleChangePassword)}>
          <Controller
            name='currentPassword'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='currentPassword'
                type={InputTypeEnum.PASSWORD}
                value={value}
                label={t('change_password_label_current_password')}
                onChange={onChange}
                required
                messageError={errors.currentPassword?.message}
              />
            )}
          />

          <Controller
            name='newPassword'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='newPassword'
                type={InputTypeEnum.PASSWORD}
                value={value}
                label={t('change_password_label_new_password')}
                onChange={onChange}
                required
                messageError={errors.newPassword?.message}
              />
            )}
          />

          <Controller
            name='confirmNewPassword'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='confirmNewPassword'
                type={InputTypeEnum.PASSWORD}
                value={value}
                label={t('change_password_label_confirm_new_password')}
                onChange={onChange}
                required
                messageError={errors.confirmNewPassword?.message}
              />
            )}
          />

          <div className={cx('formFooter')}>
            <BaseButton type='button' text={t('common_cancel_label')} width={65} onClick={handleResetForm} />

            <BaseButton type='submit' text={t('common_save_label')} typeStyle={ButtonTypeEnum.PRIMARY} width={80} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
