// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
// Components, Layouts, Pages
import { MultipleCheckbox, BaseSelect, BaseTextarea } from '~/components';
// Others
import { incontinenceOptions, issuesOptions, mockWearsBriefOptions } from '~/mockData';
import { IBaseOption } from '~/utils/interface/common';
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './CarePlanElimination.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CarePlanElimination = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='elimination' className={cx('container')}>
      <Controller
        name='elimination.wearsBriefs'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseSelect
            options={mockWearsBriefOptions}
            label={t('elimination_wears_brief_title')}
            placeholder={t('common_select_placeholder')}
            onChange={(optionSelected: IBaseOption) => {
              onChange(optionSelected?.value);
            }}
            value={value || EMPTY_STRING}
            errorMessage={errors.elimination?.wearsBriefs?.message}
          />
        )}
      />

      <div className={cx('twoCol')}>
        <div className={cx('eliminationItem')}>
          <label className={cx('label')}>{t('elimination_incontinence_title')}</label>
          <div className={cx('eliminationControl')}>
            <Controller
              name='elimination.incontinence'
              control={control}
              render={({ field: { value, onChange } }) => (
                <MultipleCheckbox
                  id='incontinenceCheckbox'
                  value={value || []}
                  options={incontinenceOptions}
                  onChange={onChange}
                  type='flex-col'
                  messageError={errors.elimination?.incontinence?.message}
                />
              )}
            />
          </div>
        </div>

        <div className={cx('eliminationItem')}>
          <label className={cx('label')}>{t('elimination_issues_title')}</label>
          <div className={cx('eliminationControl')}>
            <Controller
              name='elimination.issues'
              control={control}
              render={({ field: { value, onChange } }) => (
                <MultipleCheckbox
                  id='issuesCheckbox'
                  value={value || []}
                  options={issuesOptions}
                  onChange={onChange}
                  type='flex-col'
                  messageError={errors.elimination?.issues?.message}
                />
              )}
            />
          </div>
        </div>
      </div>

      <Controller
        name='elimination.notes'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseTextarea
            id='notes'
            name='notes'
            label={t('elimination_note_title_label')}
            onChange={onChange}
            value={value || EMPTY_STRING}
            messageError={errors.elimination?.notes?.message}
            height={90}
          />
        )}
      />
    </div>
  );
};

export default CarePlanElimination;
