// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

// Components, Layouts, Pages
import { BaseSelect, BaseDatePicker, BaseSelectMultiple } from '~/components';

// Others
import {
  optionDemoGraphicsGender,
  optionDemoGraphicsLivesWith,
  optionDemoGraphicsMaritalStatus,
  optionDemoGraphicsRace,
  optionDemoGraphicsReligion,
} from '~/mockData';
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
import { EMPTY_STRING } from '~/utils/constants/common';

// Styles, images, icons
import styles from './DemographicsDayCenterClient.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const DemographicsDayCenterClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='demographicsDayCenterClientComponent' className={cx('container')}>
      <div className={cx('viewGroupInput')}>
        <Controller
          name='demographic.dateOfBirth'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseDatePicker
              label={t('care_plan_demo_graphics_date_of_birth_label')}
              placeholderText={t('common_placeholder_select')}
              value={value || EMPTY_STRING}
              onDateSelected={onChange}
              errorMessage={errors.demographic?.dateOfBirth?.message}
            />
          )}
        />

        <Controller
          name='demographic.gender'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              options={optionDemoGraphicsGender}
              label={t('care_plan_demo_graphics_gender_label')}
              placeholder={t('common_select_placeholder')}
              value={value || EMPTY_STRING}
              onChange={({ value }) => {
                onChange(value);
              }}
              errorMessage={errors.demographic?.gender?.message}
            />
          )}
        />

        <Controller
          name='demographic.maritalStatus'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              options={optionDemoGraphicsMaritalStatus}
              label={t('care_plan_demo_graphics_marital_status_label')}
              placeholder={t('common_select_placeholder')}
              value={value || EMPTY_STRING}
              onChange={({ value }) => {
                onChange(value);
              }}
              errorMessage={errors.demographic?.maritalStatus?.message}
            />
          )}
        />

        <Controller
          name='demographic.race'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectMultiple
              label={t('care_plan_demo_graphics_race_label')}
              options={optionDemoGraphicsRace}
              placeholder={t('common_placeholder_select')}
              value={value || []}
              onChange={onChange}
              errorMessage={errors.demographic?.race?.message}
            />
          )}
        />

        <Controller
          name='demographic.religion'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              options={optionDemoGraphicsReligion}
              label={t('care_plan_demo_graphics_religion_label')}
              placeholder={t('common_select_placeholder')}
              value={value || EMPTY_STRING}
              onChange={({ value }) => {
                onChange(value);
              }}
              errorMessage={errors.demographic?.religion?.message}
            />
          )}
        />

        <Controller
          name='demographic.livesWith'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectMultiple
              label={t('care_plan_demo_graphics_lives_with_label')}
              options={optionDemoGraphicsLivesWith}
              placeholder={t('common_placeholder_select')}
              value={value || []}
              onChange={onChange}
              errorMessage={errors.demographic?.livesWith?.message}
            />
          )}
        />
      </div>
    </div>
  );
};

export default DemographicsDayCenterClient;
