import { Libraries } from '@react-google-maps/api';
import { ViewsProps } from 'react-big-calendar';
import { icons } from '~/assets';
import {
  FieldTypeEmployeesEnum,
  FieldTypeApplicantEnum,
  FieldTypeEnum,
  TabNameTransKeyEnum,
  TabQueryClientDetailEnum,
  TabQueryEventEnum,
  TabQuerySettingsEnum,
  TabQueryTaskDetailEnum,
  TabQueryValueEnum,
} from '~/utils/enum';
import { ITabItem } from '~/utils/interface/common';
import { IDefaultFilterTask } from '~/utils/interface/task';
import { IDefaultFilterEmployees } from '~/utils/interface/employees';
import { IDefaultFilterApplicant } from '~/utils/interface/applicant';

export const Languages = ['en'];

// Default Value
export const DEFAULT_DOT_TABLE_OPTION = {
  isDeactivate: false,
  isDelete: true,
  isEdit: true,
  isSendMessage: false,
  isView: true,
};
export const DEFAULT_MIN_YEAR = 1900;
export const MAX_COL_NUMBER = 10;
export const MIN_WIDTH_NUMBER = 150;
export const DEFAULT_NUMBER_ZERO = 0;
export const DEFAULT_NUMBER_ONE = 1;
export const COMMA_SEPARATOR = ',';
export const DEFAULT_NUMBER_RECORD_TO_FETCH = 10;
export const DEFAULT_NUMBER_LIMIT_CAREGIVER = 5;
export const DEFAULT_NUMBER_LIMIT_APPLICANT = 3;
export const DEFAULT_NUMBER_LIMIT_CARE_ALERT = 3;
export const EMPTY_VALUE = '--';
export const EMPTY_STRING = '';
export const QUERY_KEY_TAB = 'tab';
export const HEIGHT_FULL = '100%';
export const WIDTH_FULL = '100%';
export const MAX_VISIBLE_IMAGE = 4;
export const MIN_VISIBLE_IMAGE = 0;
export const AND = '&';
export const PLUS = '+';
export const ASTERISK_SYMBOL = '*';
export const AT_SYMBOL = '@';
export const DOLLAR = '$';
export const DEFAULT_WIDTH_DONUT_CHART = 240;
export const DEFAULT_HEIGHT_DONUT_CHART = 240;
export const DEFAULT_DELAY_TIME = 1000;
export const DEFAULT_TIMEOUT_RECONNECT_WSS = 1000;
export const DEFAULT_TIMEOUT_SEARCH = 1000;
export const CODE_CLOSE_WSS_LOGOUT = 4999;
export const REASON_CLOSE_WSS_LOGOUT = 'LOGOUT';
export const LABEL = 'LABEL';
export const timeCloseToast = 3000;
export const CONTENT_TYPE_FORM_DATA = 'multipart/form-data';
export const DEBOUNCE_SEEN_MESSAGE_DELAY = 1000;
export const DEFAULT_CATEGORIES_YEAR = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Spt',
  'Oct',
  'Nov',
  'Dec',
];
export const DIV_ELEMENT = 'div';
export const ATTRIBUTE_ID = 'id';
export const HIDDEN = 'hidden';
export const UNSET = 'unset';
export const WRAPPER_ID = 'wrapperId';
export const PIXELS = 'px';
export const ENTER_KEY = 'Enter';
export const DEFAULT_UPLOAD_ACCEPT_IMAGE = 'image/*';
export const DEFAULT_UPLOAD_ACCEPT_FILE = '*/*';
export const RANDOM_RANGE = 1000;
export const DEFAULT_HEIGHT_INPUT_MESSAGE = 18;
export const DEFAULT_PASSWORD_MIN = 8;
export const CURRENT = 'current';
export const DEFAULT_ID_ACTIVE_ADD_CRM = '1';
export const MAX_FILE_SIZE_MB = 5;
export const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
export const VALID_HEX = /^#?([a-fA-F\d]{2})([a-fA-F\d]{2})([a-fA-F\d]{2})$/;
export const NUMBER_BINARY_SYSTEM_FILE = 1024;
export const LIMIT_TAG_ASSIGNEE = 3;
export const PLUS_CHARACTER = '+';
export const DEFAULT_HEIGHT_ASSIGNEE_MODAL = 375;
export const MS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_UNIT = 'hrs';
export const SECOND = 's';
export const HOUR = 'hour';
export const MIN = 'min';
export const DEFAULT_MAP_ZOOM = 17;
export const DEFAULT_STATUS_OK = 'OK';
export const OBJECT = 'object';
export const A_ELEMENT = 'a';
export const TIME_CLOSE_TOAST = 4000;
export const TIME_OUT_SHOW_TOAST = 1000;
export const TIME_OUT_SHOW_TOAST_ERROR = 200;
export const TIME_OUT_SHOW_TOAST_SUCCESS = 1000;
export const DEFAULT_HEIGHT_SUB_MENU = 17;
export const DEFAULT_BORDER_ASSIGNEE = 4;
export const DEFAULT_VALUE_EMPTY_RICH_EDITOR = '<p><br></p>';
export const ACCEPT_UPLOAD_DOCUMENT_PURCHASE_ORDER = '.pdf, .jpg, .jpeg, .png';
export const ACCEPT_ALL_FILES = '*/*';
export const FILE_IMAGE_TYPE = 'image/';
export const FILE_VIDEO_TYPE = 'video/';
export const FILE_UPLOAD_LIMIT_50_MB = 52428800;
export const NEGATIVE_ONE = -1;
export const NAVIGATE_BACK = -1;
export const MAX_LENGTH_NOTIFICATION = 99;
export const NEW_NOTIFICATION_THRESHOLD = 10;
export const DEFAULT_PADDING_OPTION_SELECT = 28;
export const PADDING_OPTION_SELECT_MULTIPLE = 34;
export const CODE_MESSAGE_EMPLOYEE_DELETED = 13015;
export const CODE_MESSAGE_CLIENT_DELETED = 21013;
export const CODE_MESSAGE_TASK_DELETED = 24021;

export const LINE_CHART_CATEGORIES = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const DEFAULT_QUALITY_IMAGE = 0.6;
export const DEFAULT_MAX_HEIGHT_IMAGE = 720;
export const DEFAULT_MAX_WIDTH_IMAGE = 1280;

export const NUMBER_COLUMNS_VERIFY = 6;

export const MESSAGE_CONNECTED_QUICK_BOOK = 'MESSAGE_CONNECTED_QUICK_BOOK';

export const SECONDS_IN_A_MINUTE = 60;
export const HOURS_IN_A_DAY = 24;
export const ONE_SECOND = 1;

// Schedule
export const DEFAULT_SCHEDULE_VIEWS: ViewsProps = { month: true, week: true, day: true };
export const DEFAULT_VIEW_MODE = 'month';
export const DEFAULT_SCHEDULE_EVENT_COLOR = '#E1F9F1';
export const SCHEDULE_MODE_FULL = 'full';
export const SCHEDULE_MODE_MYSELF = 'my';
export const SCHEDULE_MODE_CLIENTS = 'clients';
export const SCHEDULE_MODE_CAREGIVER = 'caregiver';

// Pagination
export const defaultCurrentPage = 1;
export const DEFAULT_CURRENT_PAGE = 1;
export const DEFAULT_LIMIT_PAGE = 10;
export const MAX_VISIBLE_PAGE = 4;
export const SYMBOL_THREE_DOTS = '•••';
export const DEFAULT_TOTAL_PAGE = 0;
export const DEFAULT_TOTAL_ITEM = 0;
export const DEFAULT_LIMIT_MESSAGE = 20;
export const DEFAULT_LIMIT_MAX_ITEM = 1000;
export const DEFAULT_LIMIT_PAGE_SCHEDULE = 100;
export const DEFAULT_SHORT_LIMIT_PAGE = 5;

// Tabs
export const caregiverDetailTabs: ITabItem[] = [
  { tabName: TabNameTransKeyEnum.DAILY_CARE, tabParam: TabQueryValueEnum.DAILY_CARE },
  { tabName: TabNameTransKeyEnum.SCHEDULE, tabParam: TabQueryValueEnum.SCHEDULE },
  { tabName: TabNameTransKeyEnum.ACTIVITIES, tabParam: TabQueryValueEnum.ACTIVITIES },
  { tabName: TabNameTransKeyEnum.FILES, tabParam: TabQueryValueEnum.FILES },
  { tabName: TabNameTransKeyEnum.SKILL, tabParam: TabQueryValueEnum.SKILL },
  { tabName: TabNameTransKeyEnum.PAYROLL, tabParam: TabQueryValueEnum.PAYROLL },
  { tabName: TabNameTransKeyEnum.CHECK, tabParam: TabQueryValueEnum.CHECK },
  { tabName: TabNameTransKeyEnum.TRAINING, tabParam: TabQueryValueEnum.TRAINING },
];

export const clientDetailTabs: ITabItem[] = [
  { tabName: TabNameTransKeyEnum.CARE_PLAN, tabParam: TabQueryClientDetailEnum.CARE_PLAN },
  { tabName: TabNameTransKeyEnum.DOCUMENTS, tabParam: TabQueryClientDetailEnum.DOCUMENTS },
  { tabName: TabNameTransKeyEnum.SHIFT_SCHEDULE, tabParam: TabQueryClientDetailEnum.SHIFT_SCHEDULE },
  { tabName: TabNameTransKeyEnum.ACTIVITIES, tabParam: TabQueryClientDetailEnum.ACTIVITIES },
];

export const prospectDetailTabs: ITabItem[] = [
  { tabName: TabNameTransKeyEnum.CARE_PLAN, tabParam: TabQueryClientDetailEnum.CARE_PLAN },
  { tabName: TabNameTransKeyEnum.DOCUMENTS, tabParam: TabQueryClientDetailEnum.DOCUMENTS },
  { tabName: TabNameTransKeyEnum.ACTIVITIES, tabParam: TabQueryClientDetailEnum.ACTIVITIES },
];

export const taskDetailTabs: ITabItem[] = [
  { tabName: TabNameTransKeyEnum.COMMENTS, tabParam: TabQueryTaskDetailEnum.COMMENTS },
  { tabName: TabNameTransKeyEnum.ACTIVITY, tabParam: TabQueryTaskDetailEnum.ACTIVITY },
];

export const eventPopupTabs: ITabItem[] = [
  { tabName: TabNameTransKeyEnum.SUMMARY, tabParam: TabQueryEventEnum.SUMMARY },
  { tabName: TabNameTransKeyEnum.NOTES, tabParam: TabQueryEventEnum.NOTES },
  { tabName: TabNameTransKeyEnum.EDIT, tabParam: TabQueryEventEnum.EDIT },
  { tabName: TabNameTransKeyEnum.COPY, tabParam: TabQueryEventEnum.COPY },
  { tabName: TabNameTransKeyEnum.DELETE, tabParam: TabQueryEventEnum.DELETE },
];

export const settingsTabs: ITabItem[] = [
  { tabName: TabNameTransKeyEnum.INTEGRATION, tabParam: TabQuerySettingsEnum.INTEGRATION },
];

export const COUNT_FORMATS = [
  {
    letter: '',
    limit: 1e3,
  },
  {
    letter: 'K',
    limit: 1e6,
  },
  {
    letter: 'M',
    limit: 1e9,
  },
  {
    letter: 'B',
    limit: 1e12,
  },
  {
    letter: 'T',
    limit: 1e15,
  },
];

export const planText = {
  IN_HOME: 'IN-HOME',
  TRANSPORTATION: 'TRANSPORTATION',
  DAY_CENTER: 'DAY CENTER',
};

export const MONTHS_THREE_CHARACTER = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const optionsButtonAddCRM = [
  {
    value: '1',
    label: 'crm_add_client_title',
  },
  {
    value: '2',
    label: 'crm_add_prospect_title',
  },
  {
    value: '3',
    label: 'crm_add_referral_title',
  },
];

export const DEFAULT_VALUE_BUTTON_ADD_CRM = 'crm_add_client_title';

// Regex
export const RegExp = {
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  ONLY_DIGITS_REGEX: /^\d+$/,
  ISO_8601: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z?$/,
  NUMBER: /^[0-9]*$/,
  NUMBER_MAX_LENGTH_SIX: /^[0-9]{6}$/,
};

// Login Type
export const LoginType = {
  STANDARD: 'STANDARD',
};

export const fileTypeIcons: { [key: string]: string } = {
  docx: icons.commonIconDocsFile,
  pdf: icons.commonIconPdfFile,
  default: icons.commonIconSharedFolder,
};

export const fileTypeTaskIcons: { [key: string]: string } = {
  // Update Later
  docx: icons.commonIconDocsFile,
  txt: icons.commonIconDocsFile,
  pdf: icons.taskIconPdfFile,
  default: icons.commonIconSharedFolder,
};

export const ALLOWED_FILE_TYPE = [
  '.pdf',
  '.xlsx',
  '.xls',
  '.xlsm',
  '.xltx',
  '.xltm',
  '.xlam',
  '.xlsb',
  '.docx',
  '.doc',
  '.dotx',
  '.docm',
  '.dotm',
  '.pptx',
  '.ppt',
  '.pot',
  '.pps',
  '.ppa',
  '.potx',
  '.ppsx',
  '.ppam',
  '.pptm',
  '.potm',
  '.ppsm',
];

// Google Map
export const DEFAULT_GG_MAP_LOAD_SCRIPT_LIB: Libraries = ['places'];
export const GEOLOCATION_TIMEOUT = 5000;
export const GEOLOCATION_MAXIMUM_AGE = 0;
export const GOOGLE_MAPS_URL = (address: string) =>
  `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
export const BLANK_GOOGLE_MAP = '_blank';

export const DEFAULT_FILTER_TASK: IDefaultFilterTask = {
  fields: [FieldTypeEnum.ASSIGNEE],
};

// Options Rich Editor
export const toolbarOptionsRichEditor = [
  [
    { header: [1, 2, 3, 4, 5, 6, false] },
    'bold',
    'italic',
    'underline',
    'strike',
    {
      color: [
        '#000000',
        '#e60000',
        '#ff9900',
        '#ffff00',
        '#008a00',
        '#0066cc',
        '#9933ff',
        '#ffffff',
        '#facccc',
        '#ffebcc',
        '#ffffcc',
        '#cce8cc',
        '#cce0f5',
        '#ebd6ff',
        '#bbbbbb',
        '#f06666',
        '#ffc266',
        '#ffff66',
        '#66b966',
        '#66a3e0',
        '#c285ff',
        '#888888',
        '#a10000',
        '#b26b00',
        '#b2b200',
        '#006100',
        '#0047b2',
        '#6b24b2',
        '#444444',
        '#5c0000',
        '#663d00',
        '#666600',
        '#003700',
        '#002966',
        '#3d1466',
      ],
    },
    { list: 'ordered' },
    { list: 'bullet' },
    { indent: '-1' },
    { indent: '+1' },
    { script: 'sub' },
    { script: 'super' },
    'link',
    'image',
  ],
];

export const TIME_MEAL_CARE_PLAN = [
  {
    label: 'care_plan_meal_meal_time_breakfast_label',
    value: 'BREAKFAST',
  },
  {
    label: 'care_plan_meal_meal_time_lunch_label',
    value: 'LUNCH',
  },
  {
    label: 'care_plan_meal_meal_time_dinner_label',
    value: 'DINNER',
  },
  {
    label: 'care_plan_meal_meal_time_snacks_label',
    value: 'SNACKS',
  },
];

export const TIME_OPTIONS = [
  '00:00',
  '00:15',
  '00:30',
  '00:45',
  '01:00',
  '01:15',
  '01:30',
  '01:45',
  '02:00',
  '02:15',
  '02:30',
  '02:45',
  '03:00',
  '03:15',
  '03:30',
  '03:45',
  '04:00',
  '04:15',
  '04:30',
  '04:45',
  '05:00',
  '05:15',
  '05:30',
  '05:45',
  '06:00',
  '06:15',
  '06:30',
  '06:45',
  '07:00',
  '07:15',
  '07:30',
  '07:45',
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:14',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00',
  '19:15',
  '19:30',
  '19:45',
  '20:00',
  '20:15',
  '20:30',
  '20:45',
  '21:00',
  '21:15',
  '21:30',
  '21:45',
  '22:00',
  '22:15',
  '22:30',
  '22:45',
  '23:00',
  '23:15',
  '23:30',
  '23:45',
];

export const DEFAULT_FILTER_EMPLOYEES: IDefaultFilterEmployees = {
  fields: [FieldTypeEmployeesEnum.NAME],
};

export const DEFAULT_FILTER_APPLICANT: IDefaultFilterApplicant = {
  fields: [FieldTypeApplicantEnum.NAME],
};
// Ring Central Start
/// Message
export const RING_CENTRAL_TYPE_ACTIVE_CALL_NOTIFY = 'rc-active-call-notify';
export const RING_CENTRAL_TYPE_CALL_END_NOTIFY = 'rc-call-end-notify';
export const RING_CENTRAL_TYPE_CONNECTION_STATUS_NOTIFY = 'rc-webphone-connection-status-notify';
export const RING_CENTRAL_TYPE_LOGIN_STATUS_NOTIFY = 'rc-login-status-notify';

/// Status
export const RING_CENTRAL_TYPE_CONNECTED_STATUS = 'connectionStatus-connected';
export const RING_CENTRAL_TYPE_CONNECTING_STATUS = 'connectionStatus-connecting';
export const RING_CENTRAL_TYPE_DISCONNECTED_STATUS = 'connectionStatus-disconnected';
export const RING_CENTRAL_TYPE_RINGING_TELEPHONY_STATUS = 'Ringing';

/// Adapter
export const RING_CENTRAL_TYPE_REGISTER_SERVICE_ADAPTER = 'rc-adapter-register-third-party-service';
export const RING_CENTRAL_TYPE_NEW_CALL_ADAPTER = 'rc-adapter-new-call';
export const RING_CENTRAL_TYPE_LOGIN_ADAPTER = 'rc-adapter-login';
export const RING_CENTRAL_TYPE_LOGOUT_ADAPTER = 'rc-adapter-logout';
export const RING_CENTRAL_TYPE_NEW_SMS_ADAPTER = 'rc-adapter-new-sms';
export const RING_CENTRAL_TYPE_WIDGET_FRAME_ADAPTER = '#rc-widget-adapter-frame';

/// Env
export const ringCentralEmbeddableUrl = process.env.REACT_APP_RC_EMBEDDABLE_URL;
export const ringCentralClientId = process.env.REACT_APP_RC_APP_CLIENT_ID;
export const ringCentralServer = process.env.REACT_APP_RC_SERVER_URL;
export const ringCentralClientSecret = process.env.REACT_APP_RC_APP_CLIENT_SECRET;
// Ring Central End
