// Libs
import classNames from 'classnames/bind';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { DEFAULT_DATE_PICKER_HEIGHT, DEFAULT_DATE_PICKER_WIDTH } from '~/utils/constants/component';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './DatePickerFieldControl.module.scss';

export type DatePickerFieldControlProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  value?: string;
  placeholderText?: string;
  errorMessage?: string;
  height?: number | string;
  width?: number | string;
  disable?: boolean;
};

const cx = classNames.bind(styles);

const DatePickerFieldControl = forwardRef<HTMLButtonElement, DatePickerFieldControlProps>((props, ref) => {
  //#region Destructuring Props
  const {
    width = DEFAULT_DATE_PICKER_WIDTH,
    height = DEFAULT_DATE_PICKER_HEIGHT,
    value,
    placeholderText,
    errorMessage,
    disable = false,
    onClick,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='fieldControlComponent' className={cx('container')} style={{ width }}>
      <button
        type='button'
        ref={ref}
        style={{ height }}
        className={cx('fieldControl', disable && 'bgDisable')}
        onClick={onClick}
      >
        {value ? (
          <div className={cx('valueField')}>{value}</div>
        ) : (
          <div className={cx('valueField', { placeholderText: placeholderText })}>{placeholderText}</div>
        )}
        <div>
          <img className={cx('icon')} src={icons.commonIconCalendar} alt={t('common_img_text_alt')} />
        </div>
      </button>

      {errorMessage && <div className={cx('errMessage')}>{errorMessage}</div>}
    </div>
  );
});

export default DatePickerFieldControl;
