// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import {
  changeStatusTimeClock,
  createTimeClock,
  deleteTimeClock,
  getDetailTimeClock,
  getListTimeClock,
} from '~/thunks/timeClock/timeClockThunk';
import { RootState } from '~/redux/store';

export interface TimeClockState {
  isRefreshTimeClockList: boolean;
}

const initialState: TimeClockState = {
  isRefreshTimeClockList: false,
};

const timeClockSlice = createSlice({
  name: 'timeClockState',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshTimeClockList = action.payload;
    },
  },
  extraReducers(builder) {
    // Create Time Clock
    builder
      .addCase(createTimeClock.pending, (state) => {})
      .addCase(createTimeClock.fulfilled, (state) => {})
      .addCase(createTimeClock.rejected, (state) => {});

    // Get list Time Clock
    builder
      .addCase(getListTimeClock.pending, (state) => {})
      .addCase(getListTimeClock.fulfilled, (state) => {})
      .addCase(getListTimeClock.rejected, (state) => {});

    // Get Detail Time Clock
    builder
      .addCase(getDetailTimeClock.pending, (state) => {})
      .addCase(getDetailTimeClock.fulfilled, (state) => {})
      .addCase(getDetailTimeClock.rejected, (state) => {});

    // Change Status Time Clock
    builder
      .addCase(changeStatusTimeClock.pending, (state) => {})
      .addCase(changeStatusTimeClock.fulfilled, (state) => {})
      .addCase(changeStatusTimeClock.rejected, (state) => {});

    // Delete Time Clock
    builder
      .addCase(deleteTimeClock.pending, (state) => {})
      .addCase(deleteTimeClock.fulfilled, (state) => {})
      .addCase(deleteTimeClock.rejected, (state) => {});
  },
});

// Actions
export const timeClockActions = timeClockSlice.actions;

// Selectors
export const selectIsRefreshTimeClockList = (state: RootState) => state.timeClockState.isRefreshTimeClockList;

// Reducer
const timeClockReducer = timeClockSlice.reducer;
export default timeClockReducer;
