// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
// Components, Layouts, Pages
import { ToolBar, BaseButton, CertificationItem, ModalUnderDevelopment, SkillItem } from '~/components';
// Others
import { ICertification, ISkill } from '~/mockData/mockInterface';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './SkillTab.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const SkillTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [dataSkill, setDataSkill] = useState<ISkill[]>([]);
  const [dataCertification, setDataCertification] = useState<ICertification[]>([]);
  const [isDevelopment, setIsDevelopment] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleAddSkill = () => {
    // Handle Update Later
    handleShowDevelopment();
  };

  const handleAddCertification = () => {
    // Handle Update Later
    handleShowDevelopment();
  };

  const handleDeleteSkill = (id: string) => {
    const newSkill = dataSkill.filter((skill) => skill.id !== id);
    setDataSkill(newSkill);
  };

  const handleShowCertification = (id?: string) => {
    // Handle Update Later
    setIsDevelopment(!isDevelopment);
  };

  const handleShowDevelopment = () => {
    setIsDevelopment(!isDevelopment);
  };
  //#endregion Handle Function

  return (
    <div id='skillTabComponent' className={cx('skillTabContainer')}>
      <div className={cx('skillWrap')}>
        <ToolBar title={t('caregiver_detail_skill_tab_title_skill')} fontSize={20}>
          <BaseButton
            typeStyle={ButtonTypeEnum.PRIMARY}
            iconLeft={icons.commonIconPlus}
            text={t('caregiver_detail_skill_tab_btn_add_skill')}
            onClick={handleAddSkill}
            height={31}
          />
        </ToolBar>

        <div className={cx('skillContent')}>
          {dataSkill.length > DEFAULT_NUMBER_ZERO ? (
            dataSkill.map((skill) => {
              return skill.skillName ? (
                <SkillItem key={skill.id} data={skill} onDelete={handleDeleteSkill} />
              ) : (
                EMPTY_STRING
              );
            })
          ) : (
            <p className={cx('noDataAvailable')}>{t('common_empty_data')}</p>
          )}
        </div>

        <div></div>
      </div>

      <div className={cx('certificationWrap')}>
        <ToolBar title={t('caregiver_detail_skill_tab_title_certifications')} fontSize={20}>
          <BaseButton
            typeStyle={ButtonTypeEnum.PRIMARY}
            iconLeft={icons.commonIconPlus}
            text={t('caregiver_detail_skill_tab_btn_add_certifications')}
            onClick={handleAddCertification}
            height={31}
          />
        </ToolBar>

        <div className={cx('certificationContent')}>
          {dataCertification.length > DEFAULT_NUMBER_ZERO ? (
            dataCertification.map((certification) => {
              return certification.courseName ? (
                <CertificationItem key={certification.id} data={certification} onShow={handleShowCertification} />
              ) : (
                EMPTY_STRING
              );
            })
          ) : (
            <p className={cx('noDataAvailable')}>{t('common_empty_data')}</p>
          )}
        </div>
      </div>
      {isDevelopment && <ModalUnderDevelopment onClose={handleShowDevelopment} />}
    </div>
  );
};

export default SkillTab;
