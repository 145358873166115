// Libs
import classNames from 'classnames/bind';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { GRAY_D5D5D5 } from '~/utils/constants/color';
import { ImageCircleTypeEnum } from '~/utils/enum';
import { getInitialsName } from '~/utils/helper';
// Styles, images, icons
import styles from './ImageCircle.module.scss';

type Props = {
  type?: ImageCircleTypeEnum;
  imageUrl?: string;
  firstName?: string;
  lastName?: string;
  width?: number | string;
  height?: number | string;
  fontSize?: number;
  backgroundColor?: string;
  color?: string;
  margin?: number | string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const cx = classNames.bind(styles);

const ImageCircle = (props: Props) => {
  //#region Destructuring Props
  const {
    imageUrl,
    firstName,
    lastName,
    width = 24,
    height = 24,
    fontSize,
    backgroundColor = GRAY_D5D5D5,
    margin,
    onClick,
    onMouseEnter,
    onMouseLeave,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isUrlError, setIsUrlError] = useState<boolean>(false);
  const commonProps = {
    className: cx('imageCircle'),
    style: { width, height, margin },
    onMouseEnter,
    onMouseLeave,
    onClick,
  };
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleImageError = () => {
    setIsUrlError(true);
  };

  const handleImageSuccess = () => {
    setIsUrlError(false);
  };
  //#endregion Handle Function

  return isUrlError || !imageUrl ? (
    <div
      {...commonProps}
      style={{
        ...commonProps.style,
        fontSize,
        ...(firstName || lastName ? { backgroundColor } : {}),
      }}
    >
      <span style={{ fontSize }} className={cx('imageText')}>
        {getInitialsName(firstName, lastName)}
      </span>
    </div>
  ) : (
    <img
      {...commonProps}
      alt={t('common_img_text_alt')}
      src={imageUrl}
      onError={handleImageError}
      onLoad={handleImageSuccess}
    />
  );
};

export default ImageCircle;
