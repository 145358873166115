// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
// Components, Layouts, Pages
import { RingCentralConfirmModal } from '~/components';
// Others
import { RingCentralContext } from '~/context';
import { EMPTY_STRING } from '~/utils/constants/common';
import { ConnectionStatusEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './SettingRingCentral.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const SettingRingCentral = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { ringCentralStatus, connect, disconnect } = useContext(RingCentralContext);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [openDisconnectConfirm, setOpenDisconnectConfirm] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const connectQuickBooks = () => {
    connect && connect();
  };

  const disconnectQuickBooks = () => {
    disconnect && disconnect();
  };

  const handleCloseDisconnectConfirm = () => {
    setOpenDisconnectConfirm(false);
  };

  const handleDisconnectConfirm = () => {
    setOpenDisconnectConfirm(false);
    disconnectQuickBooks();
  };

  const handleOpenDisconnectConfirm = () => {
    setOpenDisconnectConfirm(true);
  };

  const handleConnectRingCentral = () => {
    if (ringCentralStatus === ConnectionStatusEnum.CONNECTING) return;

    connectQuickBooks();
  };

  const renderStatus = (ringCentralStatus: string) => {
    switch (ringCentralStatus) {
      case ConnectionStatusEnum.CONNECTED:
        return t('ring_central_connected');

      case ConnectionStatusEnum.CONNECTING:
        return t('ring_central_connecting');

      case ConnectionStatusEnum.DISCONNECTED:
        return t('ring_central_not_connected');

      default:
        return EMPTY_STRING;
    }
  };
  //#endregion Handle Function

  return (
    <div id='settingRingCentralPage' className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('title')}>{t('ring_central_title')}</div>
      </div>

      <div className={cx('body')}>
        <div className={cx('ringCentralBox')}>
          <span className={cx('ringCentralTitle')}>{t('ring_central_status')}</span>

          <span className={cx('ringCentralStatus', ringCentralStatus)}>{renderStatus(ringCentralStatus)}</span>

          {ringCentralStatus === ConnectionStatusEnum.CONNECTED ? (
            <button className={cx('ringCentralConnect', 'disconnected')} onClick={handleOpenDisconnectConfirm}>
              <img src={icons.ringCentralIconDisconnect} alt={t('common_img_text_alt')} className={cx('btnIcon')} />
              <span>{t('ring_central_btn_disconnect')}</span>
            </button>
          ) : (
            <button
              className={cx('ringCentralConnect')}
              onClick={handleConnectRingCentral}
              disabled={ringCentralStatus === ConnectionStatusEnum.CONNECTING}
            >
              {ringCentralStatus === ConnectionStatusEnum.CONNECTING ? (
                <img src={icons.ringCentralIconConnecting} alt={t('common_img_text_alt')} className={cx('btnIcon')} />
              ) : (
                <img src={icons.ringCentralIconConnect} alt={t('common_img_text_alt')} className={cx('btnIcon')} />
              )}
              <span>{t('ring_central_btn_connect')}</span>
            </button>
          )}
        </div>
      </div>

      {openDisconnectConfirm && (
        <RingCentralConfirmModal
          isOpen={openDisconnectConfirm}
          title={t('ring_central_confirm_title')}
          description={t('ring_central_confirm_description')}
          confirm={t('ring_central_confirm_confirm')}
          onClose={handleCloseDisconnectConfirm}
          onConfirm={handleDisconnectConfirm}
        />
      )}
    </div>
  );
};

export default SettingRingCentral;
