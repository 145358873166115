// Libs
import { IconSvgProps } from '~/utils/interface/common';
// Components, Layouts, Pages
// Others
// Styles, images, icons

const CommonIconPrev = (props: IconSvgProps) => {
  //#region Destructuring Props
  const { width = 14, height = 14, strokePath, fill = 'none' } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <svg width={width} height={height} viewBox='0 0 14 14' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.45832 9.91668L5.54166 7.00001L8.45832 4.08334'
        stroke={strokePath}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CommonIconPrev;
