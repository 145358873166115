// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import ImageCircle from '../imageCircle/ImageCircle';
import { ImageCircleTypeEnum } from '~/utils/enum';
import { AT_SYMBOL, EMPTY_VALUE } from '~/utils/constants/common';
import { ICaregiverReferral } from '~/mockData/mockInterface';
// Styles, images, icons
import styles from './CaregiverReferralItem.module.scss';

type Props = {
  data?: ICaregiverReferral;
};

const cx = classNames.bind(styles);

const CaregiverReferralItem = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='caregiverReferralItemComponent' className={cx('container')}>
      <div className={cx('imageCircleWrap')}>
        <ImageCircle
          type={data?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
          imageUrl={data?.avatarUrl}
          width={35}
          height={35}
          firstName={data?.firstName || EMPTY_VALUE}
          lastName={data?.lastName || EMPTY_VALUE}
          fontSize={16}
        />
      </div>

      <div className={cx('infoContent')}>
        <div className={cx('name')}>
          {data?.lastName || data?.firstName ? `${data.lastName} ${data.firstName}` : EMPTY_VALUE}
        </div>
        <div className={cx('tagName')}>{`${AT_SYMBOL}${data?.tagName}`}</div>
      </div>
    </div>
  );
};

export default CaregiverReferralItem;
