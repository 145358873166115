// Libs
import { CSSProperties, Fragment, useEffect, useState } from 'react';
import { ReactCalendarItemRendererProps } from 'react-calendar-timeline';
import { Views } from 'react-big-calendar';
import { Tooltip } from 'react-tooltip';
// Components, Layouts, Pages
import { ReactPortal, ScheduleAssignee } from '~/components';
// Others
import { DEFAULT_NUMBER_ONE, DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { DEFAULT_SCHEDULE_EVENT_COLOR } from '~/utils/constants/component';
import { IScheduleTimelineEvent, ITimelineEvent } from '~/utils/interface/schedule';
import { generateGuid } from '~/utils/helpers/common';
// Styles, images, icons
import './EventTimeline.scss';

type Props = Omit<ReactCalendarItemRendererProps, 'item'> & {
  eventData: ITimelineEvent;
  viewType: string;
  onSelectEvent: (data: IScheduleTimelineEvent) => void;
};

const CollapseEvent = (props: {
  resourceData: IScheduleTimelineEvent;
  onClickEvent: (data: IScheduleTimelineEvent) => void;
}) => {
  const { resourceData, onClickEvent } = props;
  const { tasks } = resourceData;
  const eventColor = DEFAULT_SCHEDULE_EVENT_COLOR;
  const eventName = tasks?.[0]?.name || EMPTY_STRING;
  const id = generateGuid();
  return (
    <div
      className='eventGroup collapseEventContainer'
      style={{ borderWidth: 1, backgroundColor: eventColor }}
      onClick={() => onClickEvent(resourceData)}
      data-tooltip-id={`taskName-${id}`}
      data-tooltip-place='left'
    >
      <div className='eventName'>{eventName}</div>
      <ReactPortal wrapperId='taskTooltip'>
        <Tooltip id={`taskName-${id}`} className={'tooltipWrap'}>
          <div className='tooltip'>
            {tasks?.length > DEFAULT_NUMBER_ZERO
              ? tasks?.map((task, index) => (
                  <span className='tooltipText' key={index}>
                    {task?.name || EMPTY_STRING}
                  </span>
                ))
              : EMPTY_STRING}
          </div>
        </Tooltip>
      </ReactPortal>
    </div>
  );
};

const ExpandEvent = (props: {
  resourceData: IScheduleTimelineEvent;
  onClickEvent: (data: IScheduleTimelineEvent) => void;
  viewType: string;
}) => {
  const { resourceData, viewType, onClickEvent } = props;
  const { tasks, assignees } = resourceData;
  const eventColor = DEFAULT_SCHEDULE_EVENT_COLOR;
  const eventName = tasks?.[0]?.name || EMPTY_STRING;
  const id = generateGuid();
  return (
    <div
      className={`eventGroup expandEventContainer ${viewType === Views.DAY && 'expandEventDay'}`}
      style={{ borderWidth: 1, backgroundColor: eventColor }}
      onClick={() => onClickEvent(resourceData)}
    >
      <div className='eventName' data-tooltip-id={`taskName-${id}`} data-tooltip-place='left'>
        {eventName}
      </div>
      <div className='eventTime'>
        {resourceData.startTime} - {resourceData.endTime}
      </div>

      <div className={viewType === Views.DAY ? 'assigneeDayContainer' : 'assigneeContainer'}>
        {assignees && <ScheduleAssignee assignees={assignees} eventColor={eventColor} />}
      </div>
      <ReactPortal wrapperId='taskTooltip'>
        <Tooltip id={`taskName-${id}`} className={'tooltipWrap'}>
          <div className='tooltip'>
            {tasks?.length > DEFAULT_NUMBER_ZERO
              ? tasks?.map((task, index) => (
                  <span className='tooltipText' key={index}>
                    {task?.name || EMPTY_STRING}
                  </span>
                ))
              : EMPTY_STRING}
          </div>
        </Tooltip>
      </ReactPortal>
    </div>
  );
};

const EventTimeLine = (props: Props) => {
  //#region Destructuring Props
  const { eventData, viewType, getItemProps, onSelectEvent } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [initStyle, setInitStyle] = useState<CSSProperties>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!eventData) return;

    const newStyle: CSSProperties = {};
    if (eventData?.resource?.length && eventData.resource.length > DEFAULT_NUMBER_ONE) {
      newStyle.overflowY = 'auto';
    }

    setInitStyle(newStyle);
  }, [eventData]);
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div
      {...getItemProps({
        style: { ...initStyle },
        className: 'eventContainer',
      })}
      key={eventData.id}
    >
      {eventData?.resource?.map((data: IScheduleTimelineEvent, index: number) => (
        <Fragment key={index}>
          {eventData?.resource?.length && eventData.resource.length > DEFAULT_NUMBER_ONE ? (
            <CollapseEvent resourceData={data} onClickEvent={onSelectEvent} />
          ) : (
            <ExpandEvent resourceData={data} onClickEvent={onSelectEvent} viewType={viewType} />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default EventTimeLine;
