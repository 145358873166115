// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
// Components, Layouts, Pages
import { BaseSelectMultiple, BaseTextarea } from '~/components';
// Others
import { optionEliminationIncontinence, optionEliminationToileting } from '~/mockData';
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './EliminationDayCenterClient.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const EliminationDayCenterClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='eliminationDayCenterClientComponent' className={cx('container')}>
      <div className={cx('viewGroupInput')}>
        <Controller
          name='elimination.incontinence'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectMultiple
              value={value || []}
              onChange={onChange}
              label={t('care_plan_elimination_incontinence')}
              options={optionEliminationIncontinence}
              placeholder={t('common_placeholder_select')}
              errorMessage={errors.elimination?.incontinence?.message}
            />
          )}
        />

        <Controller
          name='elimination.toileting'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectMultiple
              value={value || []}
              onChange={onChange}
              label={t('care_plan_elimination_toileting')}
              options={optionEliminationToileting}
              placeholder={t('common_placeholder_select')}
              errorMessage={errors.elimination?.toileting?.message}
            />
          )}
        />
      </div>

      <Controller
        name='elimination.notes'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseTextarea
            label={t('care_plan_elimination_notes')}
            height={90}
            id='eliminationNotes'
            onChange={onChange}
            value={value || EMPTY_STRING}
            messageError={errors.elimination?.notes?.message}
          />
        )}
      />
    </div>
  );
};

export default EliminationDayCenterClient;
