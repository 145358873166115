// Libs
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
// Components, Layouts, Pages
// Others
import { convertEnumToString, hexToRgba } from '~/utils/helper';
import { LABEL } from '~/utils/constants/common';
import { StatusEnum, TagsEnum } from '~/utils/enum';
import { statusColor } from '~/utils/constants/color';
import { OpacityRange } from '~/utils/type/common';
// Styles, images, icons
import styles from './StatusLabel.module.scss';

type Props = {
  label: string;
  width?: number | string;
  height?: number | string;
  borderRadius?: number;
  dotColor?: string;
  fontSize?: number;
  fontWeight?: number;
  isDot?: boolean;
  bgOpacity?: OpacityRange;
};

const cx = classNames.bind(styles);

const StatusLabel = (props: Props) => {
  //#region Destructuring Props
  const {
    width = 'fit-content',
    height = 20,
    label = LABEL,
    borderRadius,
    fontSize,
    fontWeight,
    dotColor,
    isDot,
    bgOpacity = 1,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleColor = () => {
    switch (label) {
      case StatusEnum.INITIAL_PHONE_CALL:
      case StatusEnum.COMPLETED:
        return statusColor.TEAL_24604D;
      case StatusEnum.NEW_LEAD:
      case StatusEnum.IN_PROGRESS:
        return statusColor.AZURE_RADIANCE_0682e3;
      case t('task_detail_tag_design_label'):
        return statusColor.AZURE_RADIANCE_0682e3;
      case StatusEnum.ASSESSMENT_SCHEDULED:
      case TagsEnum.DESIGN_SYSTEM:
        return statusColor.PURPLE_HEART_682ee4;
      case StatusEnum.ASSESSMENT_PERFORMED:
      case StatusEnum.BREAK:
      case TagsEnum.DESIGN:
      case StatusEnum.PENDING:
        return statusColor.FINN900;
      case TagsEnum.MARKETING:
      case t('task_detail_tag_urgent_label'):
      case StatusEnum.REFUND:
        return statusColor.RED_FF0000;

      default:
        return statusColor.TEAL_24604D;
    }
  };

  const handleBackgroundColor = () => {
    switch (label) {
      case StatusEnum.INITIAL_PHONE_CALL:
      case StatusEnum.COMPLETED:
        return hexToRgba(statusColor.TEAL_24604D, bgOpacity);
      case StatusEnum.NEW_LEAD:
      case StatusEnum.IN_PROGRESS:
        return hexToRgba(statusColor.AZURE_RADIANCE_0682e3, bgOpacity);
      case TagsEnum.PRODUCT:
      case t('task_detail_tag_design_label'):
        return hexToRgba(statusColor.AZURE_RADIANCE_0682e3, bgOpacity);
      case StatusEnum.ASSESSMENT_SCHEDULED:
      case TagsEnum.DESIGN_SYSTEM:
        return hexToRgba(statusColor.PURPLE_HEART_682ee4, bgOpacity);
      case StatusEnum.ASSESSMENT_PERFORMED:
      case StatusEnum.BREAK:
      case StatusEnum.PENDING:
      case TagsEnum.DESIGN:
        return hexToRgba(statusColor.FINN900, bgOpacity);
      case TagsEnum.MARKETING:
      case t('task_detail_tag_urgent_label'):
      case StatusEnum.REFUND:
        return hexToRgba(statusColor.RED_FF0000, bgOpacity);

      default:
        return hexToRgba(statusColor.TEAL_24604D, bgOpacity);
    }
  };
  //#endregion Handle Function

  return (
    <button
      id='statusLabel'
      className={cx('originStyle')}
      style={{
        width,
        height,
        borderRadius,
        fontSize,
        fontWeight,
        backgroundColor: handleBackgroundColor(),
        color: handleColor(),
      }}
    >
      {isDot && <p className={cx('dot')} style={{ backgroundColor: dotColor || handleColor() }}></p>}

      {convertEnumToString(label)}
    </button>
  );
};

export default StatusLabel;
