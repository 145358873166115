// Libs
import classNames from 'classnames/bind';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { DEFAULT_UPLOAD_ACCEPT_IMAGE } from '~/utils/constants/common';
import {
  DEFAULT_UPLOAD_IMAGE_HEIGHT,
  DEFAULT_UPLOAD_IMAGE_LIMIT,
  DEFAULT_UPLOAD_IMAGE_LIST,
  DEFAULT_UPLOAD_IMAGE_WIDTH,
} from '~/utils/constants/component';
import { generateUniqueId } from '~/utils/helper';
import { IUploadImage } from '~/utils/interface/common';
// Styles, images, icons
import styles from './BaseUploadImage.module.scss';
import { icons } from '~/assets';

type Props = {
  height?: number | string;
  width?: number | string;
  label?: string;
  textBtn?: string;
  maxUpload?: number;
  errorMessage?: string;
  defaultImageList?: IUploadImage[];
  onChange?: (imageList: IUploadImage[]) => void;
};

const cx = classNames.bind(styles);

const BaseUploadImage = (props: Props) => {
  //#region Destructuring Props
  const {
    width = DEFAULT_UPLOAD_IMAGE_WIDTH,
    height = DEFAULT_UPLOAD_IMAGE_HEIGHT,
    maxUpload = DEFAULT_UPLOAD_IMAGE_LIMIT,
    defaultImageList = DEFAULT_UPLOAD_IMAGE_LIST,
    label,
    textBtn,
    errorMessage,
    onChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [imageList, setImageList] = useState<IUploadImage[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!defaultImageList?.length) return;
    setImageList(defaultImageList);
  }, [defaultImageList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleUploadChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files) return;
    const imageFile = files[0];
    const imageURL = URL.createObjectURL(imageFile);
    const idImage = generateUniqueId();
    const newImage: IUploadImage = { id: idImage, file: imageFile, url: imageURL };

    setImageList((prevFiles) => [...prevFiles, newImage]);
    onChange && onChange([...imageList, newImage]);
    event.target.value = ''; // Reset DOM when user select the same file in react
  };

  const handleRemoveImage = (indexRemove: number) => {
    const newImageList: IUploadImage[] = imageList.filter((_, index) => index !== indexRemove);

    onChange && onChange(newImageList);
    setImageList(newImageList);
  };
  //#endregion Handle Function

  return (
    <div id='baseUploadImageComponent' className={cx('container')} style={{ width: width }}>
      {label && <div className={cx('label')}>{label}</div>}

      <div className={cx('uploadImgContainer')}>
        {imageList.map((image: IUploadImage, index: number) => (
          <div key={index} className={cx('imgPreviewWrap')} style={{ width: height, height: height }}>
            <img src={image?.url} alt={t('common_img_text_alt')} className={cx('imgPreview')} />

            <div className={cx('iconClose')} onClick={() => handleRemoveImage(index)}>
              <img src={icons.commonIconModalClose} alt={t('common_img_text_alt')} className={cx('iconCloseImg')} />
            </div>
          </div>
        ))}

        {imageList.length < maxUpload && (
          <label htmlFor='uploadFile' className={cx('btnUpload')} style={{ height: height }}>
            <img src={icons.commonIconUpload} alt={t('common_img_text_alt')} className={cx('uploadIcon')} />
            <>{textBtn ?? t('common_component_upload_image_btn')}</>

            <input
              id='uploadFile'
              type='file'
              accept={DEFAULT_UPLOAD_ACCEPT_IMAGE}
              className={cx('inputUpload')}
              onChange={handleUploadChange}
            />
          </label>
        )}
      </div>

      {errorMessage && <div className={cx('errMessage')}>{errorMessage}</div>}
    </div>
  );
};

export default BaseUploadImage;
