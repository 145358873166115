// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import dashboardApi from '~/apis/dashboard';
import { GET_STATISTIC_TOTAL } from '~/utils/constants/actionType';

export const getTotalDashboard = createAsyncThunk(GET_STATISTIC_TOTAL, async (_, { rejectWithValue }) => {
  try {
    const res = await dashboardApi.getTotal();
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
