// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
// Components, Layouts, Pages
import { BaseSelectMultiple } from '~/components';
// Others
import { carePlanReviewedOptions, grievanceReviewedOptions, memberRightReviewOptions } from '~/mockData';
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
// Styles, images, icons
import styles from './ReviewDayCenterClient.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const ReviewDayCenterClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='reviewDayCenterClient' className={cx('container')}>
      <div className={cx('viewGroupInput')}>
        <Controller
          name='review.carePlanReviewed'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectMultiple
              value={value || []}
              onChange={onChange}
              options={carePlanReviewedOptions}
              label={t('review_day_center_client_review_with_title')}
              placeholder={t('common_placeholder_select')}
              errorMessage={errors.review?.carePlanReviewed?.message}
            />
          )}
        />

        <Controller
          name='review.memberRightsReviewed'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectMultiple
              value={value || []}
              onChange={onChange}
              options={memberRightReviewOptions}
              label={t('review_day_center_client_member_right_review_title')}
              placeholder={t('common_placeholder_select')}
              errorMessage={errors.review?.memberRightsReviewed?.message}
            />
          )}
        />

        <Controller
          name='review.grievanceReviewed'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectMultiple
              value={value || []}
              onChange={onChange}
              options={grievanceReviewedOptions}
              label={t('review_day_center_client_grievance_reviewed_title')}
              placeholder={t('common_placeholder_select')}
              errorMessage={errors.review?.grievanceReviewed?.message}
            />
          )}
        />
      </div>
    </div>
  );
};

export default ReviewDayCenterClient;
