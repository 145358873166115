// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import { GET_LIST_CARE_ALERT } from '~/utils/constants/actionType';
import { IListQueryParams } from '~/utils/interface/common';
import careAlertApi from '~/apis/careAlert';

export const getListCareAlert = createAsyncThunk(
  GET_LIST_CARE_ALERT,
  async (payload: IListQueryParams, { rejectWithValue }) => {
    try {
      const res = await careAlertApi.getList(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
