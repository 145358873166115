import commonLogoFull from '~/assets/images/logo_elite_full.svg';

const images = {
  commonLogoFull,
  signInImageBackground: require('./sign_in_image_background.png'),
  signInImageLogoFull: require('./sign_in_image_logo_full.png'),
  changePasswordImageEmoji: require('./change_password_success_image_emoji.png'),
};

export default images;
