// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
// Components, Layouts, Pages
import { Conversation, MessageList, ToolBar } from '~/components';
// Others
import { IPayloadChangeConversation } from '~/utils/interface/message';
import { IConversation } from '~/utils/interface/conversation';
import { WebSocketEvent } from '~/utils/enum';
import { useAppDispatch } from '~/redux/hooks';
import { getListConversation } from '~/thunks/conversation/conversationThunk';
import { DEFAULT_NUMBER_RECORD_TO_FETCH, defaultCurrentPage, EMPTY_STRING } from '~/utils/constants/common';
import { joinListConversation } from '~/utils/helpers/wss';
import { IListDataResponse, IListQueryParams } from '~/utils/interface/common';
import { WebSocketContext } from '~/context/websocketContext/WebSocketContext';
import { LoadingContext } from '~/context';
import { selectUserProfile } from '~/thunks/user/userSlice';
import { IUserAccount } from '~/utils/interface/user';
// Styles, images, icons
import styles from './Message.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const Message = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { wss } = useContext(WebSocketContext);
  const loadingContext = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const { message: messageWSS } = useContext(WebSocketContext);
  //#endregion Declare Hook

  //#region Selector
  const userInfo: IUserAccount = useSelector(selectUserProfile);
  //#endregion Selector

  //#region Declare State
  const [conversations, setConversations] = useState<IConversation[]>([]);
  const [totalPages, setTotalPages] = useState<number>(defaultCurrentPage);
  const [currentPage, setCurrentPage] = useState<number>(defaultCurrentPage);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isSearchConversation, setIsSearchConversation] = useState<boolean>(false);
  const [textSearchConversation, setTextSearchConversation] = useState<string>(EMPTY_STRING);
  const currentPageRef = useRef(currentPage);
  const totalPagesRef = useRef(totalPages);
  const conversationsRef = useRef(conversations);
  const isLoadingMoreRef = useRef(isLoadingMore);
  const isSearchConversationRef = useRef(isSearchConversation);
  const textSearchConversationRef = useRef(textSearchConversation);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    currentPageRef.current = currentPage;
    totalPagesRef.current = totalPages;
    conversationsRef.current = conversations;
    isLoadingMoreRef.current = isLoadingMore;
    isSearchConversationRef.current = isSearchConversation;
    textSearchConversationRef.current = textSearchConversation;
  }, [currentPage, totalPages, conversations, isLoadingMore, isSearchConversation, textSearchConversation]);

  useEffect(() => {
    if (wss) {
      joinListConversation(wss);
    }
  }, [wss]);

  useEffect(() => {
    const params = {
      page: currentPage,
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
    };
    handleGetListConversation(params);
  }, []);

  useEffect(() => {
    if (!messageWSS) {
      return;
    }

    switch (messageWSS.type) {
      case WebSocketEvent.SEND_MESSAGE:
        handleRefreshConversation();
        break;
      case WebSocketEvent.TYPING:
        setConversations(
          conversationsRef.current.map((conversation) =>
            conversation.id === messageWSS?.data?.conversationId?.toString()
              ? { ...conversation, isTyping: true }
              : conversation
          )
        );
        break;
      case WebSocketEvent.STOP_TYPING:
        setConversations(
          conversationsRef.current.map((conversation) =>
            conversation.id === messageWSS?.data?.conversationId?.toString()
              ? { ...conversation, isTyping: false }
              : conversation
          )
        );
        break;
      case WebSocketEvent.SEEN_MESSAGE:
        handleRefreshConversation();
        break;
      default:
        break;
    }
  }, [messageWSS]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangeConversation = (payload: IPayloadChangeConversation) => {
    const { conversationId } = payload;
    setSearchParams({ ...params, conversationId: conversationId.toString() });
  };

  const handleGetListConversation = (params: IListQueryParams) => {
    // loadingContext?.show();
    dispatch(getListConversation(params))
      .unwrap()
      .then((res) => {
        const { responses, pagination }: IListDataResponse<IConversation[]> = res?.data;
        setTotalPages(pagination.totalPages);

        if (isLoadingMoreRef.current) {
          setCurrentPage(pagination.page);
          setConversations([...conversationsRef.current, ...responses]);
          return;
        }

        setConversations(responses);
      })
      .catch((error) => {})
      .finally(() => {
        loadingContext?.hide();
        setIsLoadingMore(false);
        setIsSearchConversation(false);
      });
  };

  const handleLoadMore = () => {
    if (!isLoadingMoreRef.current && currentPageRef.current < totalPagesRef.current) {
      setIsLoadingMore(true);
      const params: IListQueryParams = {
        page: currentPageRef.current + 1,
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
      };
      if (textSearchConversationRef.current) {
        params.textSearch = textSearchConversationRef.current;
      }

      handleGetListConversation(params);
    }
  };

  const handleSearchConversation = (textSearch: string) => {
    if (!isSearchConversationRef.current) {
      setTextSearchConversation(textSearch);
      setIsSearchConversation(true);
      setCurrentPage(defaultCurrentPage);
      setConversations([]);

      const params: IListQueryParams = {
        page: defaultCurrentPage,
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
      };
      if (textSearch) {
        params.textSearch = textSearch;
      }

      handleGetListConversation(params);
    }
  };

  const handleRefreshConversation = () => {
    setCurrentPage(defaultCurrentPage);
    const params: IListQueryParams = {
      page: defaultCurrentPage,
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
    };
    if (textSearchConversationRef.current) {
      params.textSearch = textSearchConversationRef.current;
    }

    handleGetListConversation(params);
  };
  //#endregion Handle Function

  return (
    <div id='messagePage' className={cx('container')}>
      <div className={cx('headerToolBar')}>
        <ToolBar title={t('message_title_page_label')}></ToolBar>
      </div>

      <div className={cx('messageContent')}>
        <div className={cx('messageMenu')}>
          <MessageList
            allConversation={conversations}
            onChangeConversation={handleChangeConversation}
            onLoadMore={handleLoadMore}
            isLoadingMore={isLoadingMore}
            isSearching={isSearchConversation}
            onSearch={handleSearchConversation}
          />
        </div>
        <div className={cx('messageConversation')}>
          {params.conversationId ? (
            <Conversation
              conversationId={params.conversationId}
              accountInformation={userInfo}
              onRefreshConversation={handleRefreshConversation}
            />
          ) : (
            <span className={cx('conversationNotSelected')}>{t('common_conversation_not_selected')}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;
