// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseStatus, ImageCircle, ThreeDotOptionsCareAlerts } from '~/components';
// Others
import { ImageCircleTypeEnum, statusEnum, statusLabelEnum, TimeFormatEnum } from '~/utils/enum';
import { EMPTY_VALUE } from '~/utils/constants/common';
import { convertDateToFormatTime, convertIsoDateToFormattedDate } from '~/utils/helper';
import { ICareAlerts } from '~/utils/interface/careAlert';
// Styles, images, icons
import styles from './CareAlertsItem.module.scss';
import { icons } from '~/assets';

type Props = {
  data?: ICareAlerts;
  isShowAll?: boolean;
};

const cx = classNames.bind(styles);

const CareAlertsItem = (props: Props) => {
  //#region Destructuring Props
  const { data, isShowAll } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='careAlertsItemComponent' className={cx('container', isShowAll && 'containerShowAll')}>
      <div className={cx('headButton')}>
        <BaseStatus
          width={data?.status === statusEnum.COMPLETE ? 80 : 102}
          height={25}
          borderRadius={100}
          color={data?.status === statusEnum.COMPLETE ? 'teal800' : 'red600'}
          label={data?.status === statusEnum.COMPLETE ? statusLabelEnum.COMPLETE : statusLabelEnum.IN_COMPLETE}
          fontWeight={700}
          fontSize={12}
        />

        <ThreeDotOptionsCareAlerts />
      </div>

      <div className={cx('contentWrap')}>
        <div className={cx('contentTitle')}>{data?.taskName || EMPTY_VALUE}</div>
        <div className={cx('contentDescription')}>{data?.comments || EMPTY_VALUE}</div>
      </div>

      {isShowAll && (
        <div className={cx('contentShowAll')}>
          <div className={cx('contentWrap')}>
            <div className={cx('contentTime')}>
              <div className={cx('timeWrap')}>
                <img src={icons.commonIconClock} alt={t('common_img_text_alt')} />
                <div className={cx('timeGroup')}>
                  <span className={cx('timeTitle')}>{`${t('care_alerts_item_clock_in_label')}: `}</span>
                  <span className={cx('timeValue')}>
                    {data?.clockIn
                      ? convertDateToFormatTime(data?.clockIn, TimeFormatEnum.HOUR_MINUTE_AM_PM)
                      : EMPTY_VALUE}
                  </span>
                </div>
              </div>

              <div className={cx('timeWrap')}>
                <img src={icons.commonIconClock} alt={t('common_img_text_alt')} />
                <div className={cx('timeGroup')}>
                  <span className={cx('timeTitle')}>{`${t('care_alerts_item_clock_out_label')}: `}</span>
                  <span className={cx('timeValue')}>
                    {data?.clockOut
                      ? convertDateToFormatTime(data?.clockOut, TimeFormatEnum.HOUR_MINUTE_AM_PM)
                      : EMPTY_VALUE}
                  </span>
                </div>
              </div>
            </div>

            <div className={cx('contentNote')}>
              <div className={cx('noteTitle')}>{t('care_alerts_item_note_title')}</div>
              <div className={cx('noteDescriptions')}>{data?.note || EMPTY_VALUE}</div>
            </div>
          </div>

          <div className={cx('contentFooter')}>
            <div className={cx('imageCircleWrap')}>
              <ImageCircle
                type={data?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
                imageUrl={data?.avatarUrl}
                width={24}
                height={24}
                firstName={data?.firstName || EMPTY_VALUE}
                lastName={data?.lastName || EMPTY_VALUE}
                fontSize={12}
              />
              <div className={cx('nameCaregiver')}>
                {data?.lastName || data?.firstName ? `${data.lastName} ${data.firstName}` : EMPTY_VALUE}
              </div>
            </div>

            <div className={cx('contentBirthDay')}>
              <img src={icons.commonIconCalculator} alt={t('common_img_text_alt')} />
              <div className={cx('timeGroup')}>
                <span className={cx('timeTitle')}>{`${t('care_alerts_item_birthday_label')}: `}</span>
                <span className={cx('timeValue')}>
                  {data?.dob ? convertIsoDateToFormattedDate(data?.dob) : EMPTY_VALUE}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CareAlertsItem;
