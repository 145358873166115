// Libs
import classNames from 'classnames/bind';
import { Views } from 'react-big-calendar';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// Components, Layouts, Pages
import { FormTaskModal, ViewSchedule } from '~/components';
// Others
import { AccountRoleCodesEnum, DateFormatEnum, PageViewScheduleEnum, StatusEnum } from '~/utils/enum';
import { DEFAULT_VIEW_MODE, EMPTY_STRING } from '~/utils/constants/common';
import { CommonIconNext, CommonIconPrev } from '~/assets/svgComponents';
import { capitalizeFirstLetter, checkTimelineMode } from '~/components/specific/scheduleModules/helper';
import {
  IOfficeSchedule,
  IParamGetOfficeSchedule,
  IScheduleEvent,
  IScheduleTimelineData,
  IScheduleTimelineEvent,
} from '~/utils/interface/schedule';
import { dateControlOfficeAndShiftSchedule } from '~/mockData';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { WHITE } from '~/utils/constants/color';
import { getOfficeSchedule } from '~/thunks/schedule/scheduleThunk';
import { scheduleActions } from '~/thunks/schedule/scheduleSlice';
import { TCalendarViewMode } from '~/utils/type/schedule';
// Styles, images, icons
import styles from './OfficeAndShiftSchedule.module.scss';

type Props = {
  role?: AccountRoleCodesEnum;
  pageView?: PageViewScheduleEnum;
};

const cx = classNames.bind(styles);

const OfficeAndShiftSchedule = (props: Props) => {
  //#region Destructuring Props
  const { role, pageView } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  const isRefreshOfficeSchedule = useAppSelector((state) => state.scheduleState.isRefreshOfficeSchedule);
  //#endregion Selector

  //#region Declare State
  const [timelineEvents, setTimelineEvents] = useState<IScheduleTimelineData[]>();
  const [scheduleEvents, setScheduleEvents] = useState<IOfficeSchedule[]>();
  const [scheduleMode, setScheduleMode] = useState<string>(EMPTY_STRING);
  const [calendarViewMode, setCalendarViewMode] = useState<TCalendarViewMode>(DEFAULT_VIEW_MODE);
  const [currentTime, setCurrentTime] = useState<string>(moment().format(DateFormatEnum.YYYY_MM_DD));
  const [isModalOpenFormEditTask, setIsModalOpenFormEditTask] = useState<boolean>(false);
  const [selectedTaskId, setSelectedTaskId] = useState<string>(EMPTY_STRING);

  const timeValue = useMemo(() => {
    switch (calendarViewMode) {
      case Views.WEEK:
        const startOfWeek = moment(currentTime).startOf('week');
        const endOfWeek = moment(currentTime).endOf('week');
        const formattedWeek = `${startOfWeek.format('MMMM DD')} - ${endOfWeek.format('DD, YYYY')}`;
        return formattedWeek;
      case Views.DAY:
        return moment(currentTime).format('dddd, MMMM DD, YYYY');

      default:
        return moment(currentTime).format('MMMM YYYY');
    }
  }, [calendarViewMode, currentTime]);
  const isTimelineMode = useMemo(
    () => checkTimelineMode(calendarViewMode, scheduleMode),
    [calendarViewMode, scheduleMode]
  );
  const eventsList = isTimelineMode ? timelineEvents : scheduleEvents;
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!pageView) return;

    switch (pageView) {
      case PageViewScheduleEnum.OFFICE:
        handleGetListOfficeSchedule();
        return;
      case PageViewScheduleEnum.OPEN_SHIFT:
        handleGetListOfficeSchedule();
        return;
    }
  }, [currentTime, pageView, calendarViewMode]);

  useEffect(() => {
    if (!isRefreshOfficeSchedule) return;

    handleGetListOfficeSchedule();
    dispatch(scheduleActions.setRefreshOfficeSchedule(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshOfficeSchedule]);
  //#endregion Implement Hook

  //#region Handle Function
  const goPreviousTime = () => {
    const previousTime = moment(currentTime).subtract(1, calendarViewMode);
    setCurrentTime(previousTime.format(DateFormatEnum.YYYY_MM_DD));
  };

  const goNextTime = () => {
    const nextTime = moment(currentTime).add(1, calendarViewMode);
    setCurrentTime(nextTime.format(DateFormatEnum.YYYY_MM_DD));
  };

  const goToday = () => {
    setCurrentTime(moment().format(DateFormatEnum.YYYY_MM_DD));
  };

  const handleChangeCalendarView = (newValue: TCalendarViewMode): void => {
    setCalendarViewMode(newValue as TCalendarViewMode);
  };

  const handleShowEvent = (event: IScheduleEvent | IScheduleTimelineEvent | IOfficeSchedule) => {
    if ('task' in event) {
      const taskId = event.task?.id;
      setSelectedTaskId(taskId || EMPTY_STRING);
      setIsModalOpenFormEditTask(true);
    }
  };

  const handleCloseModalEditTask = () => {
    setIsModalOpenFormEditTask(false);
    setSelectedTaskId(EMPTY_STRING);
  };

  const handleGetListOfficeSchedule = () => {
    loading?.show();

    const startDate = moment(currentTime).startOf(calendarViewMode).format(DateFormatEnum.YYYY_MM_DD);
    const endDate = moment(currentTime).endOf(calendarViewMode).format(DateFormatEnum.YYYY_MM_DD);
    const params: IParamGetOfficeSchedule = {
      startDate,
      endDate,
      view: calendarViewMode.toUpperCase(),
      // TODO: Type Update Later
      type: StatusEnum.TASK,
    };
    dispatch(getOfficeSchedule(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        setScheduleEvents(res.data);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='officeAndShiftSchedule' className={cx('officeAndShiftScheduleContainer')}>
      <div className={cx('bodyWrap')}>
        <div className={cx('toolbarWrapper')}>
          <div className={cx('toolbarLeft')}>
            <div className={cx('dateControl')}>
              <button className={cx('dateControlBtn')} onClick={goPreviousTime}>
                <CommonIconPrev strokePath={WHITE} />
              </button>
              <button className={cx('dateControlBtn')} onClick={goNextTime}>
                <CommonIconNext strokePath={WHITE} />
              </button>
            </div>
            <button className={cx('dateControlBtnToday')} onClick={goToday}>
              {t('common_text_today')}
            </button>
          </div>

          <div className={cx('label')}>{timeValue}</div>

          <div className={cx('toolbarRight')}>
            <div className={cx('modeViewCalendar')}>
              {dateControlOfficeAndShiftSchedule.map((date, index) => {
                return (
                  <button
                    key={index}
                    className={cx('btnCalendar', calendarViewMode === date && 'active')}
                    onClick={() => handleChangeCalendarView(date)}
                  >
                    {capitalizeFirstLetter(date)}
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        <div className={cx('scheduleWrap')}>
          <ViewSchedule
            view={calendarViewMode}
            date={currentTime}
            pageView={pageView}
            events={Array.isArray(eventsList) ? eventsList : []}
            onSelectEvent={handleShowEvent}
          />
        </div>

        {isModalOpenFormEditTask && (
          <FormTaskModal
            taskId={selectedTaskId}
            isOpen={isModalOpenFormEditTask}
            type='SCHEDULE'
            onClose={handleCloseModalEditTask}
          />
        )}
      </div>
    </div>
  );
};

export default OfficeAndShiftSchedule;
