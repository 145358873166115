// Components, Layouts, Pages
import {
  AccountingPage,
  CrmPage,
  CaregiverPage,
  HrPage,
  ManageEmployee,
  RequestApplicantPage,
  ReportingPage,
  TimeClockPage,
  CareAlertsShowAll,
  CaregiverShowAll,
  CaregiverDetailPage,
  CaregiverReferralShowAll,
  MessagePage,
  SignIn,
  ForgotPassword,
  NewPasswordPage,
  ChangePasswordSuccessPage,
  VerifyCode,
  SchedulePageStaff,
  CrmPageStaff,
  CaregiverPageStaff,
  ReportingPageStaff,
  TaskPageStaff,
  FormPageStaff,
  DocumentPageStaff,
  TimeClockPageStaff,
  InvoicePageStaff,
  HrPageStaff,
  HrPageCaregiver,
  TimeClockPageCaregiver,
  CrmPageCaregiver,
  SchedulePageCaregiver,
  HomeStaff,
  HomeAdmin,
  HomeCaregiver,
  RequestApplicantStaff,
  CaregiverShowAllStaff,
  CareAlertsShowAllStaff,
  CaregiverReferralShowAllStaff,
  MessageStaff,
  TaskPage,
  TaskPageCaregiver,
  SignUp,
  SchedulePageAdmin,
  OfficeCalendarPage,
  OpenShiftsCalendarPage,
  DailyClientsPage,
  WeeklyClientsPage,
  WeeklyCaregiversPage,
  DailyCaregiversPage,
  DailyClientsPageStaff,
  DailyCaregiversPageStaff,
  WeeklyClientsPageStaff,
  WeeklyCaregiversPageStaff,
  OfficeCalendarPageStaff,
  OpenShiftsCalendarPageStaff,
  CrmClientDetailPage,
  CrmReferralSourceDetailPage,
  CrmProspectDetailPage,
  CrmClientAdminPage,
  CrmProspectAdminPage,
  CrmReferralSourceAdminPage,
  ChangePasswordAdminPage,
  ChangePasswordPageStaff,
  ChangePasswordPageCaregiver,
  SettingRingCentralAdminPage,
  SettingsAdminPage,
  InvoicePageAdmin,
  QuickBookCallBackTemplate,
} from '~/pages';
// Others
import { IRouteModel } from '../interface/common';
import { MenuNameTransKeyEnum } from '~/utils/enum';

// Router && Path
export const publicRoute = {
  base: '/',
  auth: 'auth',
  forgotPassword: '/forgot-password',
  verifyCode: '/verify-code',
  newPassword: '/new-password',
  changePasswordSuccess: '/change-password-success',
  signUp: '/sign-up',
  quickBookCallback: '/quick-book/call-back',
};

export const adminRoute = {
  base: '/admin',
  home: '/home',
  schedule: '/schedule',
  employees: '/employees',
  hr: '/hr',
  crm: '/crm',
  crmClient: '/client',
  crmProspect: '/prospect',
  crmReferralSource: '/referral-source',
  task: '/task',
  timeClock: '/time-clock',
  reporting: '/reporting',
  accounting: '/accounting',
  caregiver: '/caregiver',
  caregiverDetail: ':caregiverId',
  requestApplicant: 'request-applicant',
  careAlertsShowAll: 'care-alerts',
  caregiverShowAll: 'caregivers',
  caregiverReferralShowAll: 'caregiver-referral',
  messages: '/messages',
  conversationId: ':conversationId',
  clientId: ':clientId',
  officeCalendar: '/office-calendar',
  openShiftsCalendar: '/open-shifts-calendar',
  dailyClients: '/daily-clients',
  dailyCaregivers: '/daily-caregivers',
  weeklyClients: '/weekly-clients',
  weeklyCaregivers: '/weekly-caregivers',
  changePassword: '/change-password',
  invoice: '/invoice',
  // ringCentral: '/ring-central',
  settings: '/settings',
};

export const staffRoute = {
  base: '/staff',
  home: '/home',
  schedule: '/schedule',
  task: '/task',
  crm: '/crm',
  crmClient: '/client',
  crmProspect: '/prospect',
  crmReferralSource: '/referral-source',
  clientId: ':clientId',
  form: '/form',
  reporting: '/reporting',
  document: '/document',
  caregiver: '/caregiver',
  timeClock: '/time-clock',
  invoice: '/invoice',
  hr: '/hr',
  caregiverDetail: ':caregiverId',
  requestApplicant: 'request-applicant',
  careAlertsShowAll: 'care-alerts',
  caregiverShowAll: 'caregivers',
  caregiverReferralShowAll: 'caregiver-referral',
  messages: '/messages',
  conversationId: ':conversationId',
  officeCalendar: '/office-calendar',
  openShiftsCalendar: '/open-shifts-calendar',
  dailyClients: '/daily-clients',
  dailyCaregivers: '/daily-caregivers',
  weeklyClients: '/weekly-clients',
  weeklyCaregivers: '/weekly-caregivers',
  changePassword: '/change-password',
};

export const caregiverRoute = {
  base: '/caregiver',
  home: '/home',
  schedule: '/schedule',
  crm: '/crm',
  crmClient: '/client',
  crmProspect: '/prospect',
  crmReferralSource: '/referral-source',
  clientId: ':clientId',
  timeClock: '/time-clock',
  hr: '/hr',
  task: '/task',
  changePassword: '/change-password',
};

//#region Auth Routes
export const authRoutes: IRouteModel[] = [
  {
    path: `${publicRoute.base}${publicRoute.auth}`,
    component: SignIn,
  },
  {
    path: `${publicRoute.base}${publicRoute.auth}${publicRoute.forgotPassword}`,
    component: ForgotPassword,
  },
  {
    path: `${publicRoute.base}${publicRoute.auth}${publicRoute.newPassword}`,
    component: NewPasswordPage,
  },
  {
    path: `${publicRoute.base}${publicRoute.auth}${publicRoute.changePasswordSuccess}`,
    component: ChangePasswordSuccessPage,
  },
  {
    path: `${publicRoute.base}${publicRoute.auth}${publicRoute.signUp}`,
    component: SignUp,
  },
  {
    path: `${publicRoute.base}${publicRoute.auth}${publicRoute.verifyCode}`,
    component: VerifyCode,
  },
];
//#endregion Auth Routes

//#region Public Routes
export const publicRoutes: IRouteModel[] = [
  {
    path: `${publicRoute.quickBookCallback}`,
    component: QuickBookCallBackTemplate,
  },
];
//#endregion Public Routes

//#region Private Routes
export const privateAdminRoutes: IRouteModel[] = [
  {
    index: true,
    name: MenuNameTransKeyEnum.HOME,
    path: adminRoute.base,
    component: HomeAdmin,
  },
  {
    name: MenuNameTransKeyEnum.TIME_CLOCK,
    path: `${adminRoute.base}${adminRoute.timeClock}`,
    component: TimeClockPage,
  },
  {
    name: MenuNameTransKeyEnum.TASK,
    path: `${adminRoute.base}${adminRoute.task}`,
    component: TaskPage,
  },
  {
    name: MenuNameTransKeyEnum.SCHEDULE,
    path: `${adminRoute.base}${adminRoute.schedule}`,
    component: SchedulePageAdmin,
    children: [
      {
        index: true,
        name: MenuNameTransKeyEnum.LIVE_VIEW,
        path: `${adminRoute.base}${adminRoute.schedule}`,
        component: SchedulePageAdmin,
        children: [
          {
            index: true,
            name: MenuNameTransKeyEnum.DAILY_CLIENTS,
            path: `${adminRoute.base}${adminRoute.schedule}${adminRoute.dailyClients}`,
            component: DailyClientsPage,
          },
          {
            name: MenuNameTransKeyEnum.DAILY_CAREGIVERS,
            path: `${adminRoute.base}${adminRoute.schedule}${adminRoute.dailyCaregivers}`,
            component: DailyCaregiversPage,
          },
          {
            name: MenuNameTransKeyEnum.WEEKLY_CLIENTS,
            path: `${adminRoute.base}${adminRoute.schedule}${adminRoute.weeklyClients}`,
            component: WeeklyClientsPage,
          },
          {
            name: MenuNameTransKeyEnum.WEEKLY_CAREGIVERS,
            path: `${adminRoute.base}${adminRoute.schedule}${adminRoute.weeklyCaregivers}`,
            component: WeeklyCaregiversPage,
          },
        ],
      },
      {
        name: MenuNameTransKeyEnum.OFFICE_CALENDAR,
        path: `${adminRoute.base}${adminRoute.schedule}${adminRoute.officeCalendar}`,
        component: OfficeCalendarPage,
      },
      {
        name: MenuNameTransKeyEnum.OPEN_SHIFT_CALENDAR,
        path: `${adminRoute.base}${adminRoute.schedule}${adminRoute.openShiftsCalendar}`,
        component: OpenShiftsCalendarPage,
      },
    ],
  },
  {
    name: MenuNameTransKeyEnum.EMPLOYEES,
    path: `${adminRoute.base}${adminRoute.employees}`,
    component: ManageEmployee,
  },
  {
    name: MenuNameTransKeyEnum.HR,
    path: `${adminRoute.base}${adminRoute.hr}`,
    component: HrPage,
  },
  {
    name: MenuNameTransKeyEnum.CAREGIVERS,
    path: `${adminRoute.base}${adminRoute.caregiver}`,
    component: CaregiverPage,
    children: [
      {
        name: MenuNameTransKeyEnum.CAREGIVER_REQUEST_APPLICANT,
        path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.requestApplicant}`,
        component: RequestApplicantPage,
      },
      {
        name: MenuNameTransKeyEnum.CAREGIVER,
        path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverShowAll}`,
        component: CaregiverShowAll,
      },
      {
        name: MenuNameTransKeyEnum.CAREGIVER_REFERRAL,
        path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverReferralShowAll}`,
        component: CaregiverReferralShowAll,
      },
    ],
  },
  {
    path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverShowAll}/${adminRoute.caregiverDetail}`,
    component: CaregiverDetailPage,
  },
  {
    name: MenuNameTransKeyEnum.CRM,
    path: `${adminRoute.base}${adminRoute.crm}`,
    component: CrmPage,
    children: [
      {
        name: MenuNameTransKeyEnum.CLIENT,
        path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmClient}`,
        component: CrmClientAdminPage,
      },
      {
        name: MenuNameTransKeyEnum.PROSPECT,
        path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmProspect}`,
        component: CrmProspectAdminPage,
      },
      {
        name: MenuNameTransKeyEnum.REFERRAL_SOURCE,
        path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmReferralSource}`,
        component: CrmReferralSourceAdminPage,
      },
    ],
  },
  {
    name: MenuNameTransKeyEnum.REPORTING,
    path: `${adminRoute.base}${adminRoute.reporting}`,
    component: ReportingPage,
  },
  {
    name: MenuNameTransKeyEnum.ACCOUNTING,
    path: `${adminRoute.base}${adminRoute.accounting}`,
    component: AccountingPage,
    children: [
      {
        index: true,
        name: MenuNameTransKeyEnum.INVOICE,
        path: `${adminRoute.base}${adminRoute.accounting}${adminRoute.invoice}`,
        component: InvoicePageAdmin,
      },
    ],
  },
  {
    path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmClient}/${adminRoute.clientId}`,
    component: CrmClientDetailPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmProspect}/${adminRoute.clientId}`,
    component: CrmProspectDetailPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmReferralSource}/${adminRoute.clientId}`,
    component: CrmReferralSourceDetailPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.messages}/${adminRoute.conversationId}`,
    component: MessagePage,
  },
  {
    path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.requestApplicant}`,
    component: RequestApplicantPage,
  },
  {
    path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.careAlertsShowAll}`,
    component: CareAlertsShowAll,
  },
  {
    path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverShowAll}`,
    component: CaregiverShowAll,
  },
  {
    path: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverReferralShowAll}`,
    component: CaregiverReferralShowAll,
  },
  {
    path: `${adminRoute.base}/${adminRoute.careAlertsShowAll}`,
    component: CareAlertsShowAll,
  },
  {
    path: `${adminRoute.base}${adminRoute.messages}`,
    component: MessagePage,
  },
  {
    path: `${adminRoute.base}${adminRoute.changePassword}`,
    component: ChangePasswordAdminPage,
  },
  // {
  //   path: `${adminRoute.base}${adminRoute.ringCentral}`,
  //   component: SettingRingCentralAdminPage,
  // },
  {
    path: `${adminRoute.base}${adminRoute.settings}`,
    component: SettingsAdminPage,
  },
];

export const privateStaffRoutes: IRouteModel[] = [
  {
    index: true,
    name: MenuNameTransKeyEnum.HOME,
    path: staffRoute.base,
    component: HomeStaff,
  },
  {
    name: MenuNameTransKeyEnum.TIME_CLOCK,
    path: `${staffRoute.base}${staffRoute.timeClock}`,
    component: TimeClockPageStaff,
  },
  {
    name: MenuNameTransKeyEnum.TASK,
    path: `${staffRoute.base}${staffRoute.task}`,
    component: TaskPageStaff,
  },
  {
    name: MenuNameTransKeyEnum.SCHEDULE,
    path: `${staffRoute.base}${staffRoute.schedule}`,
    component: SchedulePageStaff,
    children: [
      {
        index: true,
        name: MenuNameTransKeyEnum.LIVE_VIEW,
        path: `${staffRoute.base}${staffRoute.schedule}`,
        component: SchedulePageStaff,
        children: [
          {
            index: true,
            name: MenuNameTransKeyEnum.DAILY_CLIENTS,
            path: `${staffRoute.base}${staffRoute.schedule}${staffRoute.dailyClients}`,
            component: DailyClientsPageStaff,
          },
          {
            name: MenuNameTransKeyEnum.DAILY_CAREGIVERS,
            path: `${staffRoute.base}${staffRoute.schedule}${staffRoute.dailyCaregivers}`,
            component: DailyCaregiversPageStaff,
          },
          {
            name: MenuNameTransKeyEnum.WEEKLY_CLIENTS,
            path: `${staffRoute.base}${staffRoute.schedule}${staffRoute.weeklyClients}`,
            component: WeeklyClientsPageStaff,
          },
          {
            name: MenuNameTransKeyEnum.WEEKLY_CAREGIVERS,
            path: `${staffRoute.base}${staffRoute.schedule}${staffRoute.weeklyCaregivers}`,
            component: WeeklyCaregiversPageStaff,
          },
        ],
      },
      {
        name: MenuNameTransKeyEnum.OFFICE_CALENDAR,
        path: `${staffRoute.base}${staffRoute.schedule}${staffRoute.officeCalendar}`,
        component: OfficeCalendarPageStaff,
      },
      {
        name: MenuNameTransKeyEnum.OPEN_SHIFT_CALENDAR,
        path: `${staffRoute.base}${staffRoute.schedule}${staffRoute.openShiftsCalendar}`,
        component: OpenShiftsCalendarPageStaff,
      },
    ],
  },
  {
    name: MenuNameTransKeyEnum.CRM,
    path: `${staffRoute.base}${staffRoute.crm}`,
    component: CrmPageStaff,
  },
  {
    name: MenuNameTransKeyEnum.CAREGIVERS,
    path: `${staffRoute.base}${staffRoute.caregiver}`,
    component: CaregiverPageStaff,
    children: [
      {
        index: true,
        name: MenuNameTransKeyEnum.CAREGIVER_REQUEST_APPLICANT,
        path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.requestApplicant}`,
        component: RequestApplicantStaff,
      },
      {
        name: MenuNameTransKeyEnum.CAREGIVER,
        path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverShowAll}`,
        component: CaregiverShowAllStaff,
      },
      {
        name: MenuNameTransKeyEnum.CAREGIVER_REFERRAL,
        path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverReferralShowAll}`,
        component: CaregiverReferralShowAllStaff,
      },
    ],
  },
  {
    path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverShowAll}/${staffRoute.caregiverDetail}`,
    component: CaregiverDetailPage,
  },
  {
    name: MenuNameTransKeyEnum.REPORTING,
    path: `${staffRoute.base}${staffRoute.reporting}`,
    component: ReportingPageStaff,
  },
  {
    name: MenuNameTransKeyEnum.FORM,
    path: `${staffRoute.base}${staffRoute.form}`,
    component: FormPageStaff,
  },
  {
    name: MenuNameTransKeyEnum.DOCUMENT,
    path: `${staffRoute.base}${staffRoute.document}`,
    component: DocumentPageStaff,
  },
  {
    name: MenuNameTransKeyEnum.INVOICE,
    path: `${staffRoute.base}${staffRoute.invoice}`,
    component: InvoicePageStaff,
  },
  {
    name: MenuNameTransKeyEnum.HR,
    path: `${staffRoute.base}${staffRoute.hr}`,
    component: HrPageStaff,
  },
  {
    path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.requestApplicant}`,
    component: RequestApplicantStaff,
  },
  {
    path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverShowAll}`,
    component: CaregiverShowAllStaff,
  },
  {
    path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.careAlertsShowAll}`,
    component: CareAlertsShowAllStaff,
  },
  {
    path: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverReferralShowAll}`,
    component: CaregiverReferralShowAllStaff,
  },
  {
    path: `${staffRoute.base}${staffRoute.messages}/${staffRoute.conversationId}`,
    component: MessageStaff,
  },
  {
    path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmClient}/${staffRoute.clientId}`,
    component: CrmClientDetailPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmProspect}/${staffRoute.clientId}`,
    component: CrmProspectDetailPage,
  },
  {
    path: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmReferralSource}/${staffRoute.clientId}`,
    component: CrmReferralSourceDetailPage,
  },
  {
    path: `${staffRoute.base}/${staffRoute.careAlertsShowAll}`,
    component: CareAlertsShowAllStaff,
  },
  {
    path: `${staffRoute.base}${staffRoute.messages}`,
    component: MessageStaff,
  },
  {
    path: `${staffRoute.base}${staffRoute.changePassword}`,
    component: ChangePasswordPageStaff,
  },
];

export const privateCaregiverRoutes: IRouteModel[] = [
  {
    index: true,
    name: MenuNameTransKeyEnum.HOME,
    path: caregiverRoute.base,
    component: HomeCaregiver,
  },
  {
    name: MenuNameTransKeyEnum.SCHEDULE,
    path: `${caregiverRoute.base}${caregiverRoute.schedule}`,
    component: SchedulePageCaregiver,
  },
  {
    name: MenuNameTransKeyEnum.CRM,
    path: `${caregiverRoute.base}${caregiverRoute.crm}`,
    component: CrmPageCaregiver,
  },
  {
    name: MenuNameTransKeyEnum.TIME_CLOCK,
    path: `${caregiverRoute.base}${caregiverRoute.timeClock}`,
    component: TimeClockPageCaregiver,
  },
  {
    name: MenuNameTransKeyEnum.TASK,
    path: `${caregiverRoute.base}${caregiverRoute.task}`,
    component: TaskPageCaregiver,
  },
  {
    name: MenuNameTransKeyEnum.HR,
    path: `${caregiverRoute.base}${caregiverRoute.hr}`,
    component: HrPageCaregiver,
  },
  {
    path: `${caregiverRoute.base}${caregiverRoute.crm}${caregiverRoute.crmClient}/${caregiverRoute.clientId}`,
    component: CrmClientDetailPage,
  },
  {
    path: `${caregiverRoute.base}${caregiverRoute.crm}${caregiverRoute.crmProspect}/${caregiverRoute.clientId}`,
    component: CrmProspectDetailPage,
  },
  {
    path: `${caregiverRoute.base}${caregiverRoute.crm}${caregiverRoute.crmReferralSource}/${caregiverRoute.clientId}`,
    component: CrmReferralSourceDetailPage,
  },
  {
    path: `${caregiverRoute.base}${caregiverRoute.changePassword}`,
    component: ChangePasswordPageCaregiver,
  },
];
//#endregion Private Routes

//#region Absolute: Router && Path
export const authRouteAbsolute = {
  signIn: `${publicRoute.base}${publicRoute.auth}`,
  forgotPassword: `${publicRoute.base}${publicRoute.auth}${publicRoute.forgotPassword}`,
  verifyCode: `${publicRoute.base}${publicRoute.auth}${publicRoute.verifyCode}`,
  newPassword: `${publicRoute.base}${publicRoute.auth}${publicRoute.newPassword}`,
  changePasswordSuccess: `${publicRoute.base}${publicRoute.auth}${publicRoute.changePasswordSuccess}`,
  signUp: `${publicRoute.base}${publicRoute.auth}${publicRoute.signUp}`,
};

export const adminRouteAbsolute = {
  home: `${adminRoute.base}`,
  caregiver: `${adminRoute.base}${adminRoute.caregiver}`,
  requestApplicant: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.requestApplicant}`,
  careAlertsShowAll: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.careAlertsShowAll}`,
  caregiverShowAll: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverShowAll}`,
  caregiverReferralShowAll: `${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverReferralShowAll}`,
  crm: `${adminRoute.base}${adminRoute.crm}`,
  crmClient: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmClient}`,
  crmProspect: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmProspect}`,
  crmReferralSource: `${adminRoute.base}${adminRoute.crm}${adminRoute.crmReferralSource}`,
  messages: `${adminRoute.base}${adminRoute.messages}`,
  weeklyClients: `${adminRoute.base}${adminRoute.schedule}${adminRoute.weeklyClients}`,
  weeklyCaregivers: `${adminRoute.base}${adminRoute.schedule}${adminRoute.weeklyCaregivers}`,
  changePassword: `${adminRoute.base}${adminRoute.changePassword}`,
  // ringCentral: `${adminRoute.base}${adminRoute.ringCentral}`,
  settings: `${adminRoute.base}${adminRoute.settings}`,
  task: `${adminRoute.base}${adminRoute.task}`,
};

export const staffRouteAbsolute = {
  home: `${staffRoute.base}`,
  caregiver: `${staffRoute.base}${staffRoute.caregiver}`,
  requestApplicant: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.requestApplicant}`,
  careAlertsShowAll: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.careAlertsShowAll}`,
  caregiverShowAll: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverShowAll}`,
  caregiverReferralShowAll: `${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverReferralShowAll}`,
  messages: `${staffRoute.base}${staffRoute.messages}`,
  weeklyClients: `${staffRoute.base}${staffRoute.schedule}${staffRoute.weeklyClients}`,
  weeklyCaregivers: `${staffRoute.base}${staffRoute.schedule}${staffRoute.weeklyCaregivers}`,
  crm: `${staffRoute.base}${staffRoute.crm}`,
  crmClient: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmClient}`,
  crmProspect: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmProspect}`,
  crmReferralSource: `${staffRoute.base}${staffRoute.crm}${staffRoute.crmReferralSource}`,
  changePassword: `${staffRoute.base}${staffRoute.changePassword}`,
  task: `${staffRoute.base}${staffRoute.task}`,
};

export const caregiverRouteAbsolute = {
  home: `${caregiverRoute.base}`,
  crm: `${caregiverRoute.base}${caregiverRoute.crm}`,
  crmClient: `${caregiverRoute.base}${caregiverRoute.crm}${caregiverRoute.crmClient}`,
  crmProspect: `${caregiverRoute.base}${caregiverRoute.crm}${caregiverRoute.crmProspect}`,
  crmReferralSource: `${caregiverRoute.base}${caregiverRoute.crm}${caregiverRoute.crmReferralSource}`,
  changePassword: `${caregiverRoute.base}${caregiverRoute.changePassword}`,
  task: `${caregiverRoute.base}${caregiverRoute.task}`,
};

export const publicRouteAbsolute = {};
//#endregion Absolute: Router && Path
