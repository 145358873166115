// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
// Components, Layouts, Pages
import { BaseSelect, MultipleCheckbox, BaseTextarea } from '~/components';
// Others
import { dementiaOptions, leftAloneWandererOptions, mentalHealthIssuesOptions, symptomsOptions } from '~/mockData';
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './MentalBehaviorCondition.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const MentalBehaviorCondition = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='mentalBehaviorCondition' className={cx('container')}>
      <Controller
        name='mentalBehaviorCondition.diagnosedDisordersMedications'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseTextarea
            value={value || EMPTY_STRING}
            onChange={onChange}
            messageError={errors.mentalBehaviorCondition?.diagnosedDisordersMedications?.message}
            id='diagnosedDisorders'
            label={t('care_plan_mental_behavior_conditions_diagnosed_disorders_medications')}
            height={90}
          />
        )}
      />

      <Controller
        name='mentalBehaviorCondition.mentalHealthIssues'
        control={control}
        render={({ field: { value, onChange } }) => (
          <MultipleCheckbox
            value={value || []}
            id='mentalHealthIssues'
            options={mentalHealthIssuesOptions}
            onChange={onChange}
            messageError={errors.mentalBehaviorCondition?.mentalHealthIssues?.message}
          />
        )}
      />

      <div className={cx('threeCol')}>
        <Controller
          name='mentalBehaviorCondition.alone'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              placeholder={t('common_placeholder_select')}
              options={leftAloneWandererOptions}
              label={t('care_plan_mental_behavior_conditions_left_alone_label')}
              value={value || EMPTY_STRING}
              onChange={({ value }) => onChange(value)}
              errorMessage={errors.mentalBehaviorCondition?.alone?.message}
            />
          )}
        />

        <Controller
          name='mentalBehaviorCondition.wanderer'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              placeholder={t('common_placeholder_select')}
              options={leftAloneWandererOptions}
              label={t('care_plan_mental_behavior_conditions_wanderer_label')}
              value={value || EMPTY_STRING}
              onChange={({ value }) => onChange(value)}
              errorMessage={errors.mentalBehaviorCondition?.wanderer?.message}
            />
          )}
        />

        <Controller
          name='mentalBehaviorCondition.dementia'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              placeholder={t('common_placeholder_select')}
              options={dementiaOptions}
              label={t('care_plan_mental_behavior_conditions_dementia_label')}
              value={value || EMPTY_STRING}
              onChange={({ value }) => onChange(value)}
              errorMessage={errors.mentalBehaviorCondition?.dementia?.message}
            />
          )}
        />
      </div>

      <Controller
        name='mentalBehaviorCondition.symptoms'
        control={control}
        render={({ field: { value, onChange } }) => (
          <MultipleCheckbox
            id='symptoms'
            options={symptomsOptions}
            label={t('care_plan_mental_behavior_conditions_symptoms_label')}
            col={3}
            value={value || []}
            onChange={onChange}
            type='flex-col'
            messageError={errors.mentalBehaviorCondition?.symptoms?.message}
          />
        )}
      />

      <Controller
        name='mentalBehaviorCondition.triggers'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseTextarea
            id='triggers'
            label={t('care_plan_mental_behavior_triggers_label')}
            value={value || EMPTY_STRING}
            onChange={onChange}
            messageError={errors.mentalBehaviorCondition?.triggers?.message}
            height={90}
          />
        )}
      />
    </div>
  );
};

export default MentalBehaviorCondition;
