// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { createEmployee, getListEmployee } from '~/thunks/employee/employeeThunk';
import { RootState } from '~/redux/store';

export interface EmployeesState {
  isRefreshEmployeeList: boolean;
}

const initialState: EmployeesState = {
  isRefreshEmployeeList: false,
};

const employeeSlice = createSlice({
  name: 'employeeState',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshEmployeeList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list employee
    builder
      .addCase(getListEmployee.pending, (state) => {})
      .addCase(getListEmployee.fulfilled, (state) => {})
      .addCase(getListEmployee.rejected, (state) => {});

    // Create Employees
    builder
      .addCase(createEmployee.pending, (state) => {})
      .addCase(createEmployee.fulfilled, (state) => {})
      .addCase(createEmployee.rejected, (state) => {});
  },
});

// Actions
export const employeeActions = employeeSlice.actions;

// Selectors
export const selectIsRefreshEmployeeList = (state: RootState) => state.employeeState.isRefreshEmployeeList;

// Reducer
const employeeReducer = employeeSlice.reducer;
export default employeeReducer;
