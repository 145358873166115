// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

// Components, Layouts, Pages
import { BaseTextarea } from '~/components';

// Others
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
import { EMPTY_STRING } from '~/utils/constants/common';

// Styles, images, icons
import styles from './CarePlanAllergiesDayCenterClient.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CarePlanAllergiesDayCenterClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='carePlanAllergiesDayCenterClient' className={cx('container')}>
      <Controller
        name='allergies.notes'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseTextarea
            height={90}
            id='allergiesNotes'
            value={value || EMPTY_STRING}
            onChange={onChange}
            messageError={errors.allergies?.notes?.message}
          />
        )}
      />
    </div>
  );
};

export default CarePlanAllergiesDayCenterClient;
