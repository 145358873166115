// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
// Components, Layouts, Pages
import { BaseSelect, BaseSelectMultiple, BaseTextarea } from '~/components';
// Others
import { optionActivityAssistance, optionActivityEncourage, optionActivityEnjoys } from '~/mockData';
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './ActivityDayCenterClient.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const ActivityDayCenterClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='activityDayCenterClientComponent' className={cx('container')}>
      <div className={cx('viewGroupInput')}>
        <Controller
          name='activity.encourageParticipate'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectMultiple
              value={value || []}
              onChange={onChange}
              label={t('care_plan_activity_encourage_to_participate_in_label')}
              options={optionActivityEncourage}
              placeholder={t('common_placeholder_select')}
              errorMessage={errors.activity?.encourageParticipate?.message}
            />
          )}
        />

        <Controller
          name='activity.enjoysGroupActivities'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              value={value || EMPTY_STRING}
              onChange={({ value }) => onChange(value)}
              label={t('care_plan_activity_encourage_enjoys_group_activities_label')}
              options={optionActivityEnjoys}
              placeholder={t('common_placeholder_select')}
              errorMessage={errors.activity?.enjoysGroupActivities?.message}
            />
          )}
        />

        <Controller
          name='activity.assistance'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectMultiple
              value={value || []}
              onChange={onChange}
              label={t('care_plan_activity_assistance_label')}
              options={optionActivityAssistance}
              placeholder={t('common_placeholder_select')}
              errorMessage={errors.activity?.assistance?.message}
            />
          )}
        />
      </div>

      <Controller
        name={'activity.favoriteActivities'}
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <BaseTextarea
            label={t('care_plan_activity_favorite_activities_label')}
            height={90}
            id='activitiesFavoriteNotes'
            onChange={onChange}
            name={name}
            value={value || EMPTY_STRING}
            messageError={errors.activity?.favoriteActivities?.message}
          />
        )}
      />

      <Controller
        name={'activity.activityGoal'}
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <BaseTextarea
            label={t('care_plan_activity_activity_goal_label')}
            height={90}
            id='activityGoalNotes'
            onChange={onChange}
            name={name}
            value={value || EMPTY_STRING}
            messageError={errors.activity?.activityGoal?.message}
          />
        )}
      />
    </div>
  );
};

export default ActivityDayCenterClient;
