// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useState } from 'react';
// Components, Layouts, Pages
import { BaseStatus, BaseTable, ModalUnderDevelopment, StackedAvatars } from '~/components';
// Others
import { StatusEnum, TimeFormatEnum, TranslationEnum } from '~/utils/enum';
import { convertDateToFormatTime } from '~/utils/helper';
import { ColumnTableType } from '~/utils/interface/common';
import { IDailyCare } from '~/mockData/mockInterface';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './DailyCare.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columns = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<IDailyCare>[] => {
  return [
    {
      key: 'taskName',
      title: t('caregiver_profile_daily_care_table_label_task_name'),
      dataIndex: 'taskName',
      render(value, record, index) {
        return <>{record.taskName || EMPTY_STRING}</>;
      },
    },
    {
      key: 'dueDate',
      title: t('caregiver_profile_daily_care_table_label_due_date'),
      dataIndex: 'dueDate',
      render(value, record, index) {
        return (
          <>{record.dueDate ? convertDateToFormatTime(record.dueDate, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>
        );
      },
    },
    {
      key: 'Note',
      title: t('caregiver_profile_daily_care_table_label_note'),
      dataIndex: 'note',
      render(value, record, index) {
        return <>{record.note || EMPTY_STRING}</>;
      },
    },
    {
      key: 'assignee',
      title: t('caregiver_profile_daily_care_table_label_assignee'),
      render(value, record, index) {
        if (record.assignee.length === DEFAULT_NUMBER_ZERO || !record.assignee) {
          return EMPTY_STRING;
        }

        return <StackedAvatars color='green' avatars={record.assignee} id={record.id} />;
      },
    },
    {
      key: 'status',
      title: t('caregiver_profile_daily_care_table_label_status'),
      dataIndex: 'status',
      render(value, record, index) {
        if (!record.status) return EMPTY_STRING;

        if (record.status === StatusEnum.COMPLETED)
          return <BaseStatus height={23} width={94} label={t('common_status_completed')} borderRadius={100} />;

        return <BaseStatus height={23} width={94} label={record.status} borderRadius={100} />;
      },
    },
  ];
};

const DailyCare = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleDailyCareRowClick = (data: IDailyCare) => {
    setIsShowModalUnderDevelopment(true);
  };
  //#endregion Handle Function

  return (
    <div id='dailyCareComponent' className={cx('dailyCareComponent')}>
      <div className={cx('bodyWrap')}>
        <div className={cx('statisticTable')}>
          <BaseTable columns={columns(t)} dataSource={[]} onClickRow={handleDailyCareRowClick} />
        </div>
        <div className={cx('paginationTable')}>{/* Handle Pagination Later */}</div>
      </div>

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default DailyCare;
