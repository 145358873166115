// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
// Components, Layouts, Pages
import { BaseSelect, BaseDatePicker, BaseInput, BaseTextarea } from '~/components';
// Others
import { prognosisOptions } from '~/mockData';
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './CareNeeds.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CareNeeds = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='CareNeedsComponent' className={cx('container')}>
      <div className={cx('viewGroupInput')}>
        <Controller
          name={'careNeed.initialContact'}
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <BaseInput
              id={name}
              height={34}
              onChange={onChange}
              name={name}
              value={value || EMPTY_STRING}
              label={t('care_needs_initial_contact_label')}
              messageError={errors.careNeed?.initialContact?.message}
            />
          )}
        />

        <Controller
          name='careNeed.startDate'
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <BaseDatePicker
              label={t('care_needs_start_date_label')}
              onDateSelected={onChange}
              value={value || EMPTY_STRING}
              name={name}
              placeholderText={t('common_placeholder_select')}
              errorMessage={errors.careNeed?.startDate?.message}
            />
          )}
        />

        <Controller
          name='careNeed.prognosis'
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <BaseSelect
              name={name}
              value={value || EMPTY_STRING}
              onChange={({ value }) => onChange(value)}
              options={prognosisOptions}
              placeholder={t('common_placeholder_select')}
              label={t('care_needs_prognosis_label')}
              errorMessage={errors.careNeed?.prognosis?.message}
            />
          )}
        />
      </div>

      <Controller
        name='careNeed.careGoals'
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <BaseTextarea
            id='careGoals'
            height={90}
            label={t('care_needs_care_goals_label')}
            onChange={onChange}
            name={name}
            value={value || EMPTY_STRING}
            messageError={errors.careNeed?.careGoals?.message}
          />
        )}
      />

      <Controller
        name='careNeed.proposedSchedule'
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <BaseTextarea
            id='proposedSchedule'
            height={90}
            label={t('care_needs_proposed_schedule_label')}
            onChange={onChange}
            name={name}
            value={value || EMPTY_STRING}
            messageError={errors.careNeed?.proposedSchedule?.message}
          />
        )}
      />

      <Controller
        name='careNeed.careCoordinationNotes'
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <BaseTextarea
            id='coordinationNotes'
            height={90}
            label={t('care_needs_care_coordination_notes_label')}
            onChange={onChange}
            name={name}
            value={value || EMPTY_STRING}
            messageError={errors.careNeed?.careCoordinationNotes?.message}
          />
        )}
      />
    </div>
  );
};

export default CareNeeds;
