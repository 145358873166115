import { urlApiCaregiver } from '~/utils/constants/api';
import { BaseResponse, IListDataResponse, IListQueryParams } from '~/utils/interface/common';
import axiosClient from '~/apis/axiosClient';
import { ICaregiver } from '~/utils/interface/caregiver';
import { CONTENT_TYPE_FORM_DATA } from '~/utils/constants/common';

const caregiverApi = {
  getList(params: IListQueryParams) {
    const url = `${urlApiCaregiver.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<ICaregiver[]>>>(url, { params });
  },

  createCaregiver(body: FormData) {
    const url = `${urlApiCaregiver.createCaregiver}`;
    return axiosClient.post<BaseResponse>(url, body, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  getDetailCaregiver(accountId: string) {
    const url = `${urlApiCaregiver.getDetail(accountId)}`;
    return axiosClient.get<BaseResponse<ICaregiver>>(url);
  },

  deleteCaregiver(accountId: string) {
    const url = `${urlApiCaregiver.deleteCaregiver(accountId)}`;
    return axiosClient.delete<BaseResponse>(url);
  },
};

export default caregiverApi;
