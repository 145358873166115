// Libs
// Others
import { urlApiFile } from '~/utils/constants/api';
import axiosClient from '~/apis/axiosClient';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import {
  IDetailDocument,
  IListDocument,
  IListQueryParamsDocument,
  IPayloadUpdateDocument,
} from '~/utils/interface/file';
import { CONTENT_TYPE_FORM_DATA } from '~/utils/constants/common';

const fileApi = {
  getListDocuments(params: IListQueryParamsDocument) {
    const url = `${urlApiFile.getListDocuments}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IListDocument[]>>>(url, { params });
  },

  deleteDocument(fileId: string) {
    const url = `${urlApiFile.deleteDocument(fileId)}`;
    return axiosClient.delete<BaseResponse>(url);
  },

  downloadDocument(fileId: string) {
    const url = `${urlApiFile.downloadDocument(fileId)}`;
    return axiosClient.get<BaseResponse>(url);
  },

  updateDocument({ fileId, body }: IPayloadUpdateDocument) {
    const url = `${urlApiFile.updateDocument(fileId)}`;
    return axiosClient.put<BaseResponse>(url, body, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  getDetailDocument(fileId: string) {
    const url = `${urlApiFile.getDetailDocument(fileId)}`;
    return axiosClient.get<BaseResponse<IDetailDocument>>(url);
  },

  uploadDocument(body: FormData) {
    const url = `${urlApiFile.uploadDocument}`;
    return axiosClient.post<BaseResponse>(url, body, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },
};

export default fileApi;
