// Libs
import classNames from 'classnames/bind';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
// Components, Layouts, Pages
import { ImageCircle, ReactPortal } from '~/components';
// Others
import {
  DEFAULT_ASSIGNEE_MAX_DISPLAY_ITEM,
  DEFAULT_ASSIGNEE_NUMBER_MORE,
  DEFAULT_ASSIGNEE_NUMBER_POSITION_LEFT,
  DEFAULT_ASSIGNEE_SLICE_START,
} from '~/utils/constants/component';
import { ImageCircleTypeEnum } from '~/utils/enum';
import { generateGuid } from '~/utils/helpers/common';
import { IAddAssignee } from '~/utils/interface/common';
import { DEFAULT_BORDER_ASSIGNEE } from '~/utils/constants/common';
// Styles, images, icons
import styles from './Assignee.module.scss';

type Props = {
  assignees: IAddAssignee[];
  eventColor: string;
  width?: number;
  height?: number;
};

const cx = classNames.bind(styles);

const Assignee = (props: Props) => {
  //#region Destructuring Props
  const { assignees, eventColor, width = 26, height = 26 } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Declare Memo
  const displayedAssignee = useMemo(() => {
    if (!assignees) return;
    const listAssignee: IAddAssignee[] = assignees.slice(
      DEFAULT_ASSIGNEE_SLICE_START,
      DEFAULT_ASSIGNEE_MAX_DISPLAY_ITEM
    );
    const numberMoreAssignee: number =
      assignees.length > DEFAULT_ASSIGNEE_MAX_DISPLAY_ITEM
        ? assignees.length - DEFAULT_ASSIGNEE_MAX_DISPLAY_ITEM
        : DEFAULT_ASSIGNEE_NUMBER_MORE;

    return { listAssignee, numberMoreAssignee };
  }, [assignees]);

  //#endregion Declare Memo

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const id = generateGuid();
  //#endregion Handle Function

  return (
    displayedAssignee && (
      <div id='assigneeComponent' className={cx('container')}>
        {displayedAssignee.listAssignee.map((assignee: IAddAssignee, index: number) => {
          return (
            <div
              key={index}
              className={cx('eventAssigneeContainer')}
              style={{
                left: DEFAULT_ASSIGNEE_NUMBER_POSITION_LEFT * index,
                width: width + DEFAULT_BORDER_ASSIGNEE,
                height,
                borderColor: eventColor,
              }}
              data-tooltip-id={`singleAvatar-${index}-${assignee.id}`}
              data-tooltip-place='bottom'
            >
              <ImageCircle
                type={assignee?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
                imageUrl={assignee?.avatarUrl}
                width={width}
                height={height}
                fontSize={13}
                firstName={assignee?.firstName}
                lastName={assignee?.lastName}
              />

              <ReactPortal wrapperId='singleAvatar'>
                <Tooltip id={`singleAvatar-${index}-${assignee.id}`} className={cx('tooltipWrap')}>
                  <span className={cx('tooltipText')}>{`${assignee.firstName} ${assignee.lastName}`}</span>
                </Tooltip>
              </ReactPortal>
            </div>
          );
        })}

        {displayedAssignee.numberMoreAssignee > 0 && (
          <div
            className={cx('eventAssigneeMoreContainer')}
            style={{
              left: DEFAULT_ASSIGNEE_NUMBER_POSITION_LEFT * displayedAssignee.listAssignee.length,
              backgroundColor: eventColor,
            }}
            data-tooltip-id={`multipleAvatar-${id}`}
            data-tooltip-place='bottom'
          >
            <div className={cx('eventAssigneeMore')}>+{displayedAssignee.numberMoreAssignee}</div>

            <ReactPortal wrapperId='multipleAvatar'>
              <Tooltip id={`multipleAvatar-${id}`} className={cx('tooltipWrap')}>
                {assignees.slice(DEFAULT_ASSIGNEE_MAX_DISPLAY_ITEM).map((assignee) => {
                  return (
                    <span
                      key={assignee.id}
                      className={cx('tooltipText')}
                    >{`${assignee.firstName} ${assignee.lastName}`}</span>
                  );
                })}
              </Tooltip>
            </ReactPortal>
          </div>
        )}
      </div>
    )
  );
};

export default Assignee;
