// Libs
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Field, Label } from '@headlessui/react';
// Components, Layouts, Pages
// Others
import { ASTERISK_SYMBOL, DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { CommonIconCheck } from '~/assets/svgComponents';
import { IOptionRadioCheckbox } from '~/utils/interface/common';
import { WHITE } from '~/utils/constants/color';
import { TRadioCheckbox, TRadioCheckboxType } from '~/utils/type/common';
import { RadioCheckboxTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './RadioCheckbox.module.scss';

type Props = {
  id: string;
  label?: string;
  name: string;
  value?: (number | string)[];
  options: IOptionRadioCheckbox[];
  messageError?: string;
  required?: boolean;
  col?: number;
  type?: TRadioCheckboxType;
  isBordered?: boolean;
  onChange?: (value: (number | string)[], name?: string) => void;
};

const cx = classNames.bind(styles);

const RadioCheckbox = (props: Props) => {
  //#region Destructuring Props
  const { id, label, name, value, messageError, onChange, required, options, col, type, isBordered } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [selectOption, setSelectOption] = useState<IOptionRadioCheckbox[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!value || value.length === DEFAULT_NUMBER_ZERO) return;

    const optionMatchWithValue = options.filter((item) => value.includes(item.value));

    setSelectOption(optionMatchWithValue || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangeRadioCheckbox = (optionValue: number | string, type: TRadioCheckbox) => {
    let updatedOptions: IOptionRadioCheckbox[];

    const selectedOption = options.find((option) => option.value === optionValue);

    if (!selectedOption) return;

    if (type === RadioCheckboxTypeEnum.RADIO) {
      updatedOptions = [
        ...selectOption.filter((option) => option.type === RadioCheckboxTypeEnum.CHECKBOX),
        selectedOption,
      ];
    } else if (type === RadioCheckboxTypeEnum.CHECKBOX) {
      if (selectOption.some((option) => option.value === optionValue)) {
        updatedOptions = selectOption.filter((option) => option.value !== optionValue);
      } else {
        updatedOptions = [...selectOption, selectedOption];
      }
    } else {
      return;
    }

    setSelectOption(updatedOptions);

    if (onChange) {
      const selectedValues = updatedOptions.map((option) => option.value);
      onChange(selectedValues, name);
    }
  };

  const isSelected = (value: number | string) => {
    return selectOption.find((el) => el.value === value) ? true : false;
  };
  //#endregion Handle Function

  return (
    <Field id='radioCheckboxComponent' className={cx('radioCheckboxComponent', type)}>
      {label && (
        <Label className={cx('label')}>
          {label} {required && <span className={cx('required')}>{ASTERISK_SYMBOL}</span>}
        </Label>
      )}

      <div className={cx('radioCheckboxWrap', isBordered && 'borderWrap')}>
        <div className={cx('radioCheckboxGroup')}>
          {options.length > DEFAULT_NUMBER_ZERO ? (
            options.map((item, index) => {
              const uniqueId = `${id}-${index}`;
              const isChecked = isSelected(item.value);
              return (
                <div
                  className={cx('radioCheckboxContent')}
                  key={index}
                  style={{ flexBasis: `calc((100% / ${col}) - 20px)` }}
                >
                  {item.type === RadioCheckboxTypeEnum.CHECKBOX ? (
                    <>
                      <Checkbox
                        name={name}
                        value={item.value}
                        checked={isChecked}
                        id={uniqueId}
                        onChange={() => handleChangeRadioCheckbox(item.value, item.type)}
                      >
                        {({ checked }) => (
                          <span className={cx('checkbox', checked && 'checked')}>
                            <CommonIconCheck width={14} height={14} strokePath={checked ? WHITE : EMPTY_STRING} />
                          </span>
                        )}
                      </Checkbox>

                      <Label htmlFor={uniqueId} className={cx('textName')}>
                        {item.label}
                      </Label>
                    </>
                  ) : (
                    <>
                      <div className={cx('radioWrap')}>
                        <input
                          type={item.type}
                          name={name}
                          className={cx('radioInput')}
                          id={uniqueId}
                          value={item.value}
                          checked={isChecked}
                          onChange={() => handleChangeRadioCheckbox(item.value, item.type)}
                        />
                      </div>

                      <label htmlFor={uniqueId} className={cx('textName')}>
                        {item.label}
                      </label>
                    </>
                  )}
                </div>
              );
            })
          ) : (
            <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>
          )}
        </div>
      </div>

      {messageError && <p className={cx('messageError')}>{messageError}</p>}
    </Field>
  );
};

export default RadioCheckbox;
