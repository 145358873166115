// Libs
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseSelectSingle } from '~/components';
// Others
import { optionsDropdownChart } from '~/mockData';
import { configColumChart } from '~/utils/constants/configChart';
import { DEFAULT_NUMBER_ZERO } from '~/utils/constants/common';
// Styles, images, icons
import styles from './ColumnChart.module.scss';
import { icons } from '~/assets';

type Props = {
  title: string;
  data: ApexOptions['series'];
  categories: string[];
  colors: string[];
  colWidth?: number;
};

const cx = classNames.bind(styles);

const ColumnChart = (props: Props) => {
  //#region Destructuring Props
  const { title, data, categories, colors, colWidth } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [configChart, setConfigChart] = useState<ApexOptions | null>(null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!data) {
      return;
    }

    const config = configColumChart({
      categories: categories,
      colors: colors,
      colWidth: colWidth,
    });

    setConfigChart(config);
  }, [data]);
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='columnChartComponent' className={cx('container')}>
      <div className={cx('headerChart')}>
        <div className={cx('title')}>{title}</div>
        <BaseSelectSingle options={optionsDropdownChart} icon={icons.commonIconArrowBottom} minWidth={73} />
      </div>

      {data && data.length > DEFAULT_NUMBER_ZERO ? (
        configChart && (
          <div className={cx('columnChart')}>
            <ReactApexChart type='bar' series={data} options={configChart} height={280} />
          </div>
        )
      ) : (
        <div className={cx('noData')}>{t('common_empty_data')}</div>
      )}
    </div>
  );
};

export default ColumnChart;
