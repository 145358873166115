import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '~/redux/store';

import { changePassword, createNewPassword, forgotPassword, handleLogin } from '~/thunks/auth/authThunk';
import { StorageEnum } from '~/utils/enum';

export interface AuthState {
  accessToken: string | null | undefined;
  accountId?: string;
}

const initialState: AuthState = {
  accessToken: localStorage.getItem(StorageEnum.ACCESS_TOKEN)! || null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    handleLogout() {
      localStorage.removeItem(StorageEnum.ACCESS_TOKEN);
    },
  },
  extraReducers(builder) {
    // Login
    builder
      .addCase(handleLogin.pending, (state, action) => {})
      .addCase(handleLogin.fulfilled, (state, action) => {
        state.accessToken = action.payload.data.token.access;
      })
      .addCase(handleLogin.rejected, (state, action) => {});

    // Forgot Password
    builder
      .addCase(forgotPassword.pending, (state, action) => {})
      .addCase(forgotPassword.fulfilled, (state, action) => {})
      .addCase(forgotPassword.rejected, (state, action) => {});

    // Create New Password
    builder
      .addCase(createNewPassword.pending, (state, action) => {})
      .addCase(createNewPassword.fulfilled, (state, action) => {})
      .addCase(createNewPassword.rejected, (state, action) => {});

    // Change Password
    builder
      .addCase(changePassword.pending, (state, action) => {})
      .addCase(changePassword.fulfilled, (state, action) => {})
      .addCase(changePassword.rejected, (state, action) => {});
  },
});

export const authActions = authSlice.actions;

export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectAccountId = (state: RootState) => state.auth.accountId;

const authReducer = authSlice.reducer;
export default authReducer;
