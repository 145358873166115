// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
// Components, Layouts, Pages
import { BaseButton, ImageCircle, RelateDeletedModal } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { getDetailClients } from '~/thunks/crm/clients/clientsThunk';
import { IClientDetail } from '~/utils/interface/crm/clients';
import { CODE_MESSAGE_CLIENT_DELETED, EMPTY_STRING, NAVIGATE_BACK } from '~/utils/constants/common';
import { ButtonTypeEnum, CRMEnum, ImageCircleTypeEnum } from '~/utils/enum';
import { calculateAge, getUserName } from '~/utils/helper';
import { ErrorData } from '~/utils/interface/common';
// Styles, images, icons
import styles from './CrmReferralResourceDetail.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const CrmReferralResourceDetail = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  const { clientId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [dataProfileClient, setDataProfileClient] = useState<IClientDetail>();
  const [isShowClientDeleted, setIsShowClientDeleted] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!clientId) return;

    handleGetDetailClient(clientId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleBack = () => {
    navigate(NAVIGATE_BACK);
  };

  const handleGetDetailClient = (clientId: string) => {
    loading?.show();

    dispatch(getDetailClients(clientId))
      .unwrap()
      .then((res) => {
        const responses: IClientDetail = res?.data;
        setDataProfileClient(responses);
      })
      .catch((error) => {
        const { code } = error?.response?.data as ErrorData;

        if (code === CODE_MESSAGE_CLIENT_DELETED) setIsShowClientDeleted(!isShowClientDeleted);
      })
      .finally(() => {
        loading?.hide();
      });
  };

  const handleShowClientDeleted = () => {
    setIsShowClientDeleted(!isShowClientDeleted);

    navigate(NAVIGATE_BACK);
  };
  //#endregion Handle Function

  return (
    <div id='crmReferralResourceDetailPage' className={cx('container')}>
      <div className={cx('mainBody')}>
        {dataProfileClient && (
          <>
            <BaseButton
              onClick={handleBack}
              text={t('common_text_back')}
              typeStyle={ButtonTypeEnum.ONLY_TEXT}
              iconLeft={icons.commonIconPrev}
              height={17}
            />

            <section className={cx('headGroup')}>
              <div className={cx('basicInfoGroup')}>
                <ImageCircle
                  type={dataProfileClient?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
                  width={91}
                  height={91}
                  imageUrl={dataProfileClient?.avatarUrl}
                  fontSize={45}
                  firstName={dataProfileClient?.firstName}
                  lastName={dataProfileClient?.lastName}
                />

                <div className={cx('basicInfo')}>
                  <div className={cx('nameGroup')}>
                    <div className={cx('fullName')}>
                      {getUserName(dataProfileClient?.firstName, dataProfileClient?.lastName)}
                    </div>
                    <p className={cx('valueGroup')}>
                      {t('client_detail_label_age')}
                      <span className={cx('value')}>
                        {dataProfileClient?.dob
                          ? t('client_detail_label_years', { age: calculateAge(dataProfileClient.dob) })
                          : EMPTY_STRING}
                      </span>
                    </p>
                    <p className={cx('valueGroup')}>
                      {t('client_detail_label_referred_by')}
                      <span className={cx('value')}>
                        {dataProfileClient?.referrer?.firstName || dataProfileClient?.referrer?.lastName
                          ? `${dataProfileClient?.referrer?.firstName} ${dataProfileClient?.referrer?.lastName}`
                          : EMPTY_STRING}
                      </span>
                    </p>
                  </div>

                  <div className={cx('locationGroup')}>
                    <p className={cx('valueGroup')}>
                      {t('client_detail_label_address')}
                      <span className={cx('value')}>
                        {dataProfileClient?.address ? dataProfileClient.address : EMPTY_STRING}
                      </span>
                    </p>
                    <p className={cx('valueGroup')}>
                      {t('client_detail_label_location')}
                      <span className={cx('value')}>
                        {dataProfileClient?.location ? dataProfileClient.location : EMPTY_STRING}
                      </span>
                    </p>
                    <p className={cx('valueGroup')}>
                      {dataProfileClient?.type === CRMEnum.CLIENT ? (
                        <>
                          {t('client_detail_label_note')}
                          <span className={cx('value')}>
                            {dataProfileClient?.notes ? dataProfileClient.notes : EMPTY_STRING}
                          </span>
                        </>
                      ) : (
                        <>
                          {t('prospect_detail_label_status')}
                          <span className={cx('value')}>
                            {dataProfileClient?.clientStatus
                              ? dataProfileClient?.clientStatus?.join(', ')
                              : EMPTY_STRING}
                          </span>
                        </>
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div className={cx('navTabGroup')}>{/* Tabs */}</div>
            </section>

            <section className={cx('tabContent')}>{/* Tab Content */}</section>
          </>
        )}
      </div>

      <RelateDeletedModal isOpen={isShowClientDeleted} onClose={handleShowClientDeleted} />
    </div>
  );
};

export default CrmReferralResourceDetail;
