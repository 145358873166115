// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseButton } from '~/components';
// Others
import { ButtonTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './RingCentralConfirmModal.module.scss';
import { icons } from '~/assets';

type Props = {
  isOpen: boolean;
  title: string;
  description?: string;
  confirm: string;
  onClose: () => void;
  onConfirm: () => void;
};

const cx = classNames.bind(styles);

const RingCentralConfirmModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, title, description, confirm, onClose, onConfirm } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='ringCentralConfirmModalComponent' className={cx('container')}>
      <div className={cx('modalBody')}>
        <div className={cx('modalIcon')}>
          <img src={icons.ringCentralIconWarning} alt={t('common_img_text_alt')} className={cx('modalIconImg')} />
        </div>

        <h3 className={cx('modalTitle')}>{title}</h3>

        {description && <p className={cx('modalDescription')}>{description}</p>}

        <div className={cx('modalFooter')}>
          <BaseButton type='button' width={'100%'} text={t('common_cancel_label')} onClick={onClose} />

          <BaseButton
            type='button'
            width={'100%'}
            text={confirm}
            typeStyle={ButtonTypeEnum.PRIMARY}
            onClick={onConfirm}
          />
        </div>
      </div>
    </div>
  );
};

export default RingCentralConfirmModal;
