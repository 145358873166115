// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import { CREATE_APPLICANT } from '~/utils/constants/actionType';
import { GET_LIST_APPLICANT } from '~/utils/constants/actionType';
import { IListQueryParams } from '~/utils/interface/common';
import applicantApi from '~/apis/applicant';

export const createApplicant = createAsyncThunk(CREATE_APPLICANT, async (body: FormData, { rejectWithValue }) => {
  try {
    const res = await applicantApi.createApplicant(body);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListApplicant = createAsyncThunk(
  GET_LIST_APPLICANT,
  async (payload: IListQueryParams, { rejectWithValue }) => {
    try {
      const res = await applicantApi.getList(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
