// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
// Components, Layouts, Pages
import { ImageCircle } from '~/components';
// Others
import { MAX_VISIBLE_IMAGE, MIN_VISIBLE_IMAGE, PLUS } from '~/utils/constants/common';
import { ImageCircleTypeEnum } from '~/utils/enum';
import { ColorStackedAvatar } from '~/utils/type/common';
// Styles, images, icons
import styles from './StackedAvatars.module.scss';
import { IAssignee } from '~/utils/interface/schedule';

type Props = {
  avatars: IAssignee[];
  maxVisibleImage?: number;
  backgroundColor?: string;
  color?: ColorStackedAvatar;
  id?: string;
};

const cx = classNames.bind(styles);

const StackedAvatars = (props: Props) => {
  //#region Destructuring Props
  const { avatars, maxVisibleImage = MAX_VISIBLE_IMAGE, backgroundColor, color, id } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function
  //   <img key={index} src={avatar} className={cx('avatar')} alt={t('common_img_text_alt')} />;
  return (
    <div id='stackedAvatarsComponent' className={cx('container')}>
      {avatars.slice(MIN_VISIBLE_IMAGE, maxVisibleImage)?.map((avatar, index) => {
        return (
          <div
            className={cx('avatar', color)}
            key={index}
            data-tooltip-id={`singleAvatar-${index}-${id}`}
            data-tooltip-place='bottom'
            data-tooltip-content={`${avatar.firstName} ${avatar.lastName}`}
          >
            <ImageCircle
              width={26}
              height={26}
              imageUrl={avatar.avatarUrl}
              type={avatar ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
              firstName={avatar.firstName}
              lastName={avatar.lastName}
            />
            <Tooltip id={`singleAvatar-${index}-${id}`} className={cx('tooltipText')} />
          </div>
        );
      })}

      {avatars.length > maxVisibleImage && (
        <div
          className={cx('avatarMore', color)}
          style={{ backgroundColor }}
          data-tooltip-id={`multipleAvatar-${id}`}
          data-tooltip-place='bottom'
        >
          <span>
            {PLUS}
            {avatars.slice(maxVisibleImage).length}
          </span>
          <Tooltip id={`multipleAvatar-${id}`} className={cx('tooltipText')}>
            <div className={cx('tooltip')}>
              {avatars.slice(maxVisibleImage).map((avatar, index) => (
                <span key={index}>
                  {avatar.firstName} {avatar.lastName}
                </span>
              ))}
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default StackedAvatars;
