// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import {
  deleteDocument,
  downloadDocument,
  getDetailDocument,
  getListDocuments,
  uploadDocument,
  updateDocument,
} from '~/thunks/file/fileThunk';

export interface FileState {
  isRefreshFileList: boolean;
}

const initialState: FileState = {
  isRefreshFileList: false,
};

const fileSlice = createSlice({
  name: 'fileState',
  initialState: initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshFileList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list document
    builder
      .addCase(getListDocuments.pending, (state) => {})
      .addCase(getListDocuments.fulfilled, (state) => {})
      .addCase(getListDocuments.rejected, (state) => {});

    // Delete Document
    builder
      .addCase(deleteDocument.pending, (state) => {})
      .addCase(deleteDocument.fulfilled, (state) => {})
      .addCase(deleteDocument.rejected, (state) => {});

    // Download Document
    builder
      .addCase(downloadDocument.pending, (state) => {})
      .addCase(downloadDocument.fulfilled, (state) => {})
      .addCase(downloadDocument.rejected, (state) => {});

    // Update Document
    builder
      .addCase(updateDocument.pending, (state) => {})
      .addCase(updateDocument.fulfilled, (state) => {})
      .addCase(updateDocument.rejected, (state) => {});

    // Get Detail Document
    builder
      .addCase(getDetailDocument.pending, (state) => {})
      .addCase(getDetailDocument.fulfilled, (state) => {})
      .addCase(getDetailDocument.rejected, (state) => {});

    // Upload Document
    builder
      .addCase(uploadDocument.pending, (state) => {})
      .addCase(uploadDocument.fulfilled, (state) => {})
      .addCase(uploadDocument.rejected, (state) => {});
  },
});

// Actions
export const fileActions = fileSlice.actions;

// Selectors
export const selectIsRefreshFileList = (state: RootState) => state.fileState.isRefreshFileList;

// Reducer
const fileReducer = fileSlice.reducer;
export default fileReducer;
