// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import { getListConversation } from './conversationThunk';
import { createConversation } from './conversationThunk';

export interface ConversationState {
  isRefreshConversationList: boolean;
}

const initialState: ConversationState = {
  isRefreshConversationList: false,
};

const conversationSlice = createSlice({
  name: 'conversationState',
  initialState: initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshConversationList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list Conversation
    builder
      .addCase(getListConversation.pending, (state) => {})
      .addCase(getListConversation.fulfilled, (state) => {})
      .addCase(getListConversation.rejected, (state) => {});

    // Create Conversation
    builder
      .addCase(createConversation.pending, (state) => {})
      .addCase(createConversation.fulfilled, (state) => {})
      .addCase(createConversation.rejected, (state) => {});
  },
});

// Actions
export const conversationActions = conversationSlice.actions;

// Selectors
export const selectIsRefreshConversationList = (state: RootState) => state.conversationState.isRefreshConversationList;

// Reducer
const conversationReducer = conversationSlice.reducer;
export default conversationReducer;
