// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
// Components, Layouts, Pages
import { BaseButton, BaseInput, BaseUploadImage, Loading } from '~/components';
// Others
import { ButtonTypeEnum } from '~/utils/enum';
import { IFormAddApplicant } from '~/utils/interface/applicant';
import { DEFAULT_NUMBER_ZERO } from '~/utils/constants/common';
import { IUploadImage } from '~/utils/interface/common';
import { useAppDispatch } from '~/redux/hooks';
import { createApplicant } from '~/thunks/applicant/applicantThunk';
import { applicantActions } from '~/thunks/applicant/applicantSlice';
// Styles, images, icons
import styles from './AddApplicant.module.scss';

type Props = {
  onClose: () => void;
};

const cx = classNames.bind(styles);

const AddApplicantModal = (props: Props) => {
  //#region Destructuring Props
  const { onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [formState, setFormState] = useState<IFormAddApplicant>({});
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleUploadChange = (imageUploadList: IUploadImage[]) => {
    const fileList: File[] = [];
    imageUploadList.forEach((imageUploadItem: IUploadImage) => {
      if (!imageUploadItem?.file) return;
      fileList.push(imageUploadItem.file);
    });
    setFiles(fileList);
  };

  const handleCreateApplicant = () => {
    const formData = new FormData();
    if (files?.length > DEFAULT_NUMBER_ZERO) {
      files.forEach((file: File) => {
        formData.append('avatarApplicant', file);
      });
    }

    Object.keys(formState).forEach((key: string) => {
      if (!formState[key as keyof IFormAddApplicant]) return;
      formData.append(key, formState[key as keyof IFormAddApplicant] as string);
    });

    setIsLoading(true);
    dispatch(createApplicant(formData))
      .unwrap()
      .then((res) => {
        onClose && onClose();
        dispatch(applicantActions.setRefreshList(true));
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  //#endregion Handle Function

  return (
    <div id='addApplicantComponent' className={cx('addApplicantComponent')}>
      <div className={cx('contentModal')}>
        <BaseInput
          id='firstName'
          name='firstName'
          label={t('caregiver_applicant_modal_add_application_first_name_label')}
          //placeholder={t('caregiver_applicant_modal_add_application_first_name_label')}
          onChange={handleChangeInput}
        />

        <BaseUploadImage
          label={t('caregiver_applicant_modal_add_application_profile_image_label')}
          textBtn={t('caregiver_applicant_modal_add_application_choose_file_label')}
          height={64}
          onChange={handleUploadChange}
        />

        <div className={cx('inputContent')}>
          <BaseInput
            id='address'
            name='address'
            label={t('caregiver_applicant_modal_add_application_address_label')}
            //placeholder={t('caregiver_applicant_modal_add_application_address_label')}
            onChange={handleChangeInput}
          />
          <BaseInput
            id='email'
            name='email'
            label={t('caregiver_applicant_modal_add_application_email_label')}
            //placeholder={t('caregiver_applicant_modal_add_application_email_label')}
            onChange={handleChangeInput}
          />
        </div>

        <div className={cx('inputContent')}>
          <BaseInput
            id='phoneNumber'
            name='phoneNumber'
            label={t('caregiver_applicant_modal_add_application_phone_number_label')}
            //placeholder={t('caregiver_applicant_modal_add_application_phone_number_label')}
            onChange={handleChangeInput}
          />
          <BaseInput
            id='source'
            name='source'
            label={t('caregiver_applicant_modal_add_application_source_label')}
            //placeholder={t('caregiver_applicant_modal_add_application_source_label')}
            onChange={handleChangeInput}
          />
        </div>
      </div>

      <div className={cx('footerButton')}>
        <BaseButton text={t('common_cancel_label')} width={65} onClick={onClose} />
        <BaseButton
          text={t('common_save_label')}
          typeStyle={ButtonTypeEnum.PRIMARY}
          width={80}
          onClick={handleCreateApplicant}
        />
      </div>

      {isLoading && <Loading />}
    </div>
  );
};

export default AddApplicantModal;
