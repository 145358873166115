// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
// Components, Layouts, Pages
import { BaseSelect, BaseSelectMultiple } from '~/components';
// Others
import {
  optionAmbulationAssistance,
  optionAmbulationDevices,
  optionAmbulationElopementRisk,
  optionAmbulationFallRisk,
  optionAmbulationLevel,
  optionAmbulationTransfers,
} from '~/mockData';
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './AmbulationDayCenterClient.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const AmbulationDayCenterClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='ambulationDayCenterClientComponent' className={cx('container')}>
      <div className={cx('viewGroupInput')}>
        <Controller
          name='ambulation.ambulationLevel'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectMultiple
              value={value || []}
              onChange={onChange}
              label={t('care_plan_ambulation_level_label')}
              options={optionAmbulationLevel}
              placeholder={t('common_placeholder_select')}
              errorMessage={errors.ambulation?.ambulationLevel?.message}
            />
          )}
        />

        <Controller
          name='ambulation.devices'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectMultiple
              value={value || []}
              onChange={onChange}
              label={t('care_plan_ambulation_devices_label')}
              options={optionAmbulationDevices}
              placeholder={t('common_placeholder_select')}
              errorMessage={errors.ambulation?.devices?.message}
            />
          )}
        />

        <Controller
          name='ambulation.assistance'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectMultiple
              value={value || []}
              onChange={onChange}
              label={t('care_plan_ambulation_assistance_label')}
              options={optionAmbulationAssistance}
              placeholder={t('common_placeholder_select')}
              errorMessage={errors.ambulation?.assistance?.message}
            />
          )}
        />

        <Controller
          name='ambulation.transfers'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectMultiple
              value={value || []}
              onChange={onChange}
              options={optionAmbulationTransfers}
              label={t('care_plan_ambulation_transfers_label')}
              placeholder={t('common_select_placeholder')}
              errorMessage={errors.ambulation?.transfers?.message}
            />
          )}
        />

        <Controller
          name='ambulation.fallRisk'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              value={value || EMPTY_STRING}
              onChange={({ value }) => {
                onChange(value);
              }}
              options={optionAmbulationFallRisk}
              label={t('care_plan_ambulation_fall_risk_label')}
              placeholder={t('common_select_placeholder')}
              errorMessage={errors.ambulation?.fallRisk?.message}
            />
          )}
        />

        <Controller
          name='ambulation.elopementRisk'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              value={value || EMPTY_STRING}
              onChange={({ value }) => {
                onChange(value);
              }}
              label={t('care_plan_ambulation_elopement_risk_label')}
              options={optionAmbulationElopementRisk}
              placeholder={t('common_placeholder_select')}
              errorMessage={errors.ambulation?.elopementRisk?.message}
            />
          )}
        />
      </div>
    </div>
  );
};

export default AmbulationDayCenterClient;
