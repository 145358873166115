// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { useEffect, useState } from 'react';
// Components, Layouts, Pages
// Others
import { SelectTypeEnum } from '~/utils/enum';
import { Option } from '~/utils/interface/common';
// Styles, images, icons
import styles from './BaseSelectSingle.module.scss';

type Props = {
  typeStyle?: SelectTypeEnum;
  width?: number | string;
  minWidth?: number | string;
  height?: number;
  iconWidthHeight?: number | string;
  icon?: string;
  borderColor?: string;
  options: Option[];
  onChange?: (value: Option) => void;
};

const cx = classNames.bind(styles);

const BaseSelectSingle = (props: Props) => {
  //#region Destructuring Props
  const {
    typeStyle,
    width = 'fit-content',
    height = 36,
    minWidth,
    iconWidthHeight,
    icon,
    borderColor,
    options,
    onChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [selectedData, setSelectedData] = useState<Option>(options[0]);

  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (onChange && onChange) {
      onChange && onChange(selectedData);
    }
  }, [selectedData, onChange]);
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='baseSelectSingle' className={cx('container')}>
      <Listbox value={selectedData} onChange={setSelectedData}>
        <ListboxButton
          className={cx('defaultSelect', typeStyle)}
          style={{ width: width, height: height, borderColor: borderColor, minWidth }}
        >
          <span className={cx('textSelect')}>{selectedData?.label}</span>
          {icon && (
            <img
              src={icon}
              alt={t('common_img_text_alt')}
              style={{ width: iconWidthHeight, height: iconWidthHeight }}
              className={cx('iconSelect')}
            />
          )}
        </ListboxButton>
        <ListboxOptions
          anchor='bottom start'
          className={cx('optionsSelect')}
          style={{ width: width, borderColor: borderColor, minWidth }}
        >
          {options?.length > 0 ? (
            options.map((data) => (
              <ListboxOption key={data.value} value={data} className={cx('valueSelect', typeStyle)}>
                {data.label}
              </ListboxOption>
            ))
          ) : (
            <div className={cx('noData', typeStyle)}>{t('common_empty_data_select')}</div>
          )}
        </ListboxOptions>
      </Listbox>
    </div>
  );
};

export default BaseSelectSingle;
