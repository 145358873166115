// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
// Components, Layouts, Pages
import { BaseButton, FormScheduleModal, ModalUnderDevelopment, Schedule, ToolBar } from '~/components';
// Others
import { ButtonTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './Schedule.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const SchedulePage = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='schedulePage' className={cx('container')}>
      <ToolBar title={t('tab_schedule')} />

      <div className={cx('content')}>
        <Schedule mode='my' isShowDetail />
      </div>
    </div>
  );
};

export default SchedulePage;
