// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  AddDocument,
  BaseButton,
  CarePlanDayCenterClientTab,
  CarePlanHomeCareClientTab,
  CarePlanTab,
  DetailActivities,
  DocumentsTab,
  ImageCircle,
  RelateDeletedModal,
  ShiftDocumentTab,
  Tab,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getDetailClients } from '~/thunks/crm/clients/clientsThunk';
import { CODE_MESSAGE_CLIENT_DELETED, EMPTY_STRING, NAVIGATE_BACK, QUERY_KEY_TAB } from '~/utils/constants/common';
import {
  ButtonTypeEnum,
  CareTypeClientEnum,
  CRMEnum,
  ImageCircleTypeEnum,
  TabNameTransKeyEnum,
  TabQueryClientDetailEnum,
} from '~/utils/enum';
import { calculateAge, getUserName } from '~/utils/helper';
import { IClientDetail } from '~/utils/interface/crm/clients';
import { ErrorData, ITabItem } from '~/utils/interface/common';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './CrmClientDetail.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CrmDetail = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get(QUERY_KEY_TAB);
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [dataProfileClient, setDataProfileClient] = useState<IClientDetail>();
  const [isShowClientDeleted, setIsShowClientDeleted] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!clientId) return;

    handleGetDetailClient(clientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  const clientDetailTabs: ITabItem[] = useMemo(() => {
    return [
      {
        tabName: TabNameTransKeyEnum.CARE_PLAN,
        tabParam: TabQueryClientDetailEnum.CARE_PLAN,
        disable: dataProfileClient?.careClientType === CareTypeClientEnum.TRANSPORTATION,
      },
      { tabName: TabNameTransKeyEnum.DOCUMENTS, tabParam: TabQueryClientDetailEnum.DOCUMENTS },
      { tabName: TabNameTransKeyEnum.SHIFT_SCHEDULE, tabParam: TabQueryClientDetailEnum.SHIFT_SCHEDULE },
      { tabName: TabNameTransKeyEnum.ACTIVITIES, tabParam: TabQueryClientDetailEnum.ACTIVITIES },
    ];
  }, [dataProfileClient]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleBack = () => {
    navigate(NAVIGATE_BACK);
  };

  const handleGetDetailClient = (clientId: string) => {
    loading?.show();

    dispatch(getDetailClients(clientId))
      .unwrap()
      .then((res) => {
        const responses: IClientDetail = res?.data;
        setDataProfileClient(responses);
      })
      .catch((error) => {
        const { code } = error?.response?.data as ErrorData;

        if (code === CODE_MESSAGE_CLIENT_DELETED) setIsShowClientDeleted(!isShowClientDeleted);
      })
      .finally(() => {
        loading?.hide();
      });
  };

  const handleShowClientDeleted = () => {
    setIsShowClientDeleted(!isShowClientDeleted);

    navigate(NAVIGATE_BACK);
  };
  //#endregion Handle Function
  const renderCarePlanTab = (data?: IClientDetail) => {
    if (!data?.careClientType) return;

    switch (data?.careClientType) {
      case CareTypeClientEnum.DAY_CENTER:
        return <CarePlanDayCenterClientTab />;

      case CareTypeClientEnum.OTHER:
        return <CarePlanTab />;

      case CareTypeClientEnum.HOME_CARE:
        return <CarePlanHomeCareClientTab />;

      default:
        return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
    }
  };

  const renderTabContent = useMemo(() => {
    const tabToRender = currentTab || TabQueryClientDetailEnum.CARE_PLAN;
    switch (tabToRender) {
      case TabQueryClientDetailEnum.CARE_PLAN:
        return renderCarePlanTab(dataProfileClient);

      case TabQueryClientDetailEnum.DOCUMENTS:
        return clientId && <DocumentsTab clientId={clientId} />;

      case dataProfileClient?.type === CRMEnum.CLIENT && TabQueryClientDetailEnum.SHIFT_SCHEDULE:
        return clientId && <ShiftDocumentTab clientId={clientId} data={dataProfileClient} />;

      case TabQueryClientDetailEnum.ACTIVITIES:
        return <DetailActivities />;

      default:
        // Handle Return Tab Later
        return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
    }
  }, [currentTab, clientId, dataProfileClient, t]);

  return (
    <div id='clientDetailPage' className={cx('container')}>
      <div className={cx('mainBody')}>
        {dataProfileClient && (
          <>
            <BaseButton
              onClick={handleBack}
              text={t('common_text_back')}
              typeStyle={ButtonTypeEnum.ONLY_TEXT}
              iconLeft={icons.commonIconPrev}
              height={17}
            />

            <section className={cx('headGroup')}>
              <div className={cx('basicInfoGroup')}>
                <ImageCircle
                  type={dataProfileClient?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
                  width={91}
                  height={91}
                  imageUrl={dataProfileClient?.avatarUrl}
                  fontSize={45}
                  firstName={dataProfileClient?.firstName}
                  lastName={dataProfileClient?.lastName}
                />

                <div className={cx('basicInfo')}>
                  <div className={cx('nameGroup')}>
                    <div className={cx('fullName')}>
                      {getUserName(dataProfileClient?.firstName, dataProfileClient?.lastName)}
                    </div>
                    <p className={cx('valueGroup')}>
                      {t('client_detail_label_age')}
                      <span className={cx('value')}>
                        {dataProfileClient?.dob
                          ? t('client_detail_label_years', { age: calculateAge(dataProfileClient.dob) })
                          : EMPTY_STRING}
                      </span>
                    </p>
                    <p className={cx('valueGroup')}>
                      {t('client_detail_label_referred_by')}
                      <span className={cx('value')}>
                        {dataProfileClient?.referrer?.firstName || dataProfileClient?.referrer?.lastName
                          ? `${dataProfileClient?.referrer?.firstName} ${dataProfileClient?.referrer?.lastName}`
                          : EMPTY_STRING}
                      </span>
                    </p>
                  </div>

                  <div className={cx('locationGroup')}>
                    <p className={cx('valueGroup')}>
                      {t('client_detail_label_address')}
                      <span className={cx('value')}>
                        {dataProfileClient?.address ? dataProfileClient.address : EMPTY_STRING}
                      </span>
                    </p>
                    <p className={cx('valueGroup')}>
                      {t('client_detail_label_location')}
                      <span className={cx('value')}>
                        {dataProfileClient?.location ? dataProfileClient.location : EMPTY_STRING}
                      </span>
                    </p>
                    <p className={cx('valueGroup')}>
                      {dataProfileClient?.type === CRMEnum.CLIENT ? (
                        <>
                          {t('client_detail_label_note')}
                          <span className={cx('value')}>
                            {dataProfileClient?.notes ? dataProfileClient.notes : EMPTY_STRING}
                          </span>
                        </>
                      ) : (
                        <>
                          {t('prospect_detail_label_status')}
                          <span className={cx('value')}>
                            {dataProfileClient?.clientStatus
                              ? dataProfileClient?.clientStatus?.join(', ')
                              : EMPTY_STRING}
                          </span>
                        </>
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div className={cx('navTabGroup')}>
                <Tab items={clientDetailTabs} paddingBottom={30} />
              </div>
            </section>

            <section className={cx('tabContent')}>{renderTabContent}</section>
          </>
        )}
      </div>

      {dataProfileClient && currentTab === TabQueryClientDetailEnum.DOCUMENTS && (
        <AddDocument accountId={dataProfileClient?.id} />
      )}

      <RelateDeletedModal isOpen={isShowClientDeleted} onClose={handleShowClientDeleted} />
    </div>
  );
};

export default CrmDetail;
