// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
// Components, Layouts, Pages
import { BaseSelectMultiple, BaseSelect, BaseTextarea } from '~/components';
// Others
import {
  assistanceOptions,
  dietOptions,
  lunchSnackProvidedOptions,
  restrictionsOptions,
  textureOptions,
} from '~/mockData';
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './MealsDietDayCenterClient.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const MealsDietDayCenterClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook\
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='mealsDietDayCenterClient' className={cx('container')}>
      <div className={cx('viewGroupInput')}>
        <Controller
          name='meal.diet'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectMultiple
              value={value || []}
              onChange={onChange}
              label={t('meals_diet_day_center_diet_title')}
              placeholder={t('common_placeholder_select')}
              options={dietOptions}
              errorMessage={errors.meal?.diet?.message}
            />
          )}
        />

        <Controller
          name='meal.texture'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectMultiple
              value={value || []}
              onChange={onChange}
              label={t('meals_diet_day_center_texture_title')}
              placeholder={t('common_placeholder_select')}
              options={textureOptions}
              errorMessage={errors.meal?.texture?.message}
            />
          )}
        />

        <Controller
          name='meal.assistance'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              value={value?.[0] || EMPTY_STRING}
              onChange={({ value }) => onChange([value])}
              label={t('meals_diet_day_center_assistance_title')}
              placeholder={t('common_placeholder_select')}
              options={assistanceOptions}
              errorMessage={errors.meal?.assistance?.message}
            />
          )}
        />

        <Controller
          name='meal.restrictions'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              value={value || EMPTY_STRING}
              onChange={({ value }) => onChange(value)}
              label={t('meals_diet_day_center_restrictions_title')}
              placeholder={t('common_placeholder_select')}
              options={restrictionsOptions}
              errorMessage={errors.meal?.restrictions?.message}
            />
          )}
        />
      </div>

      <Controller
        name='meal.lunchSnackProvided'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseSelectMultiple
            value={value || []}
            onChange={onChange}
            label={t('meals_diet_day_center_lunch_snack_provided_title')}
            placeholder={t('common_placeholder_select')}
            options={lunchSnackProvidedOptions}
            errorMessage={errors.meal?.lunchSnackProvided?.message}
          />
        )}
      />

      <Controller
        name='meal.restrictionsDislikes'
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <BaseTextarea
            label={t('meals_diet_day_center_restrictions_dislikes_title')}
            id='restrictionsDislikeNote'
            onChange={onChange}
            name={name}
            value={value || EMPTY_STRING}
            messageError={errors.meal?.restrictionsDislikes?.message}
          />
        )}
      />

      <Controller
        name='meal.favoriteFoods'
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <BaseTextarea
            label={t('meals_diet_day_center_favorite_foods_title')}
            id='favoriteFoodNotes'
            name={name}
            value={value || EMPTY_STRING}
            onChange={onChange}
            messageError={errors.meal?.favoriteFoods?.message}
          />
        )}
      />
    </div>
  );
};

export default MealsDietDayCenterClient;
