// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Controller, useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
// Components, Layouts, Pages
import { BaseSelect, MultipleCheckboxWeek } from '~/components';
// Others
import { repeatMonthOptions, repeatWeekOptions } from '~/mockData';
import { IFormTask } from '~/utils/interface/task';
import { IBaseOption } from '~/utils/interface/common';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { MonthlyOccurrenceEnum, RecurrenceEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './RepeatOn.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const RepeatOn = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<IFormTask>();

  const watchRecurrence = watch('recurrence');
  const watchFromDate = watch('fromDate');
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [repeatMonthOption, setRepeatMonthOption] = useState<IBaseOption[]>(repeatMonthOptions);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (watchRecurrence === RecurrenceEnum.MONTHLY) {
      setValue('repeatMonthOn', MonthlyOccurrenceEnum.DAY_OF_MONTH);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchRecurrence]);

  useEffect(() => {
    if (!watchFromDate) return;

    if (watchRecurrence === RecurrenceEnum.MONTHLY) {
      handleFormatRepeatMonthOption();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchFromDate, watchRecurrence]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleFormatRepeatMonthOption = () => {
    const formattedDay = moment(watchFromDate).format('Do');
    setRepeatMonthOption((pre) => [
      {
        ...pre[DEFAULT_NUMBER_ZERO],
        label: t('task_modal_repeat_month_label', { dayMonth: formattedDay || EMPTY_STRING }),
      },
    ]);
  };
  //#endregion Handle Function

  return (
    <div id='RepeatOnComponent'>
      {watchRecurrence === RecurrenceEnum.WEEKLY && (
        <Controller
          name='repeatWeekOn'
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <MultipleCheckboxWeek
                id='multipleCheckboxWeek'
                label={t('task_modal_task_repeat_on_label')}
                options={repeatWeekOptions}
                value={value || []}
                onChange={onChange}
                required
                messageError={errors.repeatWeekOn?.message}
              />
            );
          }}
        />
      )}

      {watchRecurrence === RecurrenceEnum.MONTHLY && (
        <div className={cx('twoCol')}>
          <Controller
            name='repeatMonthOn'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                value={value || EMPTY_STRING}
                label={t('task_modal_task_repeat_on_label')}
                placeholder={t('common_placeholder_select')}
                options={repeatMonthOption}
                onChange={(optionSelected: IBaseOption) => {
                  onChange(optionSelected?.value);
                }}
                required
                errorMessage={errors.repeatMonthOn?.message}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

export default RepeatOn;
