// Libs
import classNames from 'classnames/bind';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { Modal, BaseButton, BaseInput, BaseUploadImage, BaseSelect, RadioCrm } from '~/components';
// Others
import { ButtonTypeEnum, InputCRMEnum, RadioCrmEnum } from '~/utils/enum';
import { IUploadImage, Option } from '~/utils/interface/common';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { mockDataState, mockOptionsOwnerCrm, mockOptionsTypeCrm } from '~/mockData';
import { convertCamelCaseToTitleCase, isEmptyObject, validateFormatNumber } from '~/utils/helper';
import {
  IBodyIEditReferralsPayload,
  IFormEditReferralSourceIndividualCrm,
  IFormEditReferralSourceOrganizationCrm,
  IFormUpdateReferrals,
} from '~/utils/interface/crm/referralSource';
import { LoadingContext } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getDetailReferrals, updateReferrals } from '~/thunks/crm/referral/referralThunk';
import { referralActions } from '~/thunks/crm/referral/referralSlice';
// Styles, images, icons
import styles from './CrmEditReferralSource.module.scss';

type Props = {
  referralSourceId: string;
  isOpen: boolean;
  onClose: () => void;
};

const cx = classNames.bind(styles);

const CrmEditReferralSource = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, referralSourceId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [dataFormReferralSourceIndividualCrm, setDataFormReferralSourceIndividualCrm] =
    useState<IFormEditReferralSourceIndividualCrm>({
      firstNameIndividual: EMPTY_STRING,
      lastNameIndividual: EMPTY_STRING,
      addressIndividual: EMPTY_STRING,
      cityIndividual: EMPTY_STRING,
      stateIndividual: EMPTY_STRING,
      postalCodeIndividual: EMPTY_STRING,
      mobilePhoneIndividual: EMPTY_STRING,
      typeIndividual: EMPTY_STRING,
    });
  const [formIndividualErrors, setFormIndividualErrors] = useState<IFormEditReferralSourceIndividualCrm>({
    firstNameIndividual: EMPTY_STRING,
    lastNameIndividual: EMPTY_STRING,
    addressIndividual: EMPTY_STRING,
    cityIndividual: EMPTY_STRING,
    stateIndividual: EMPTY_STRING,
    postalCodeIndividual: EMPTY_STRING,
    mobilePhoneIndividual: EMPTY_STRING,
    typeIndividual: EMPTY_STRING,
  });

  const [dataFormReferralSourceOrganizationCrm, setDataFormReferralSourceOrganizationCrm] =
    useState<IFormEditReferralSourceOrganizationCrm>({
      nameOrganization: EMPTY_STRING,
      addressOrganization: EMPTY_STRING,
      cityOrganization: EMPTY_STRING,
      stateOrganization: EMPTY_STRING,
      postalCodeOrganization: EMPTY_STRING,
      mobilePhoneOrganization: EMPTY_STRING,
      typeOrganization: EMPTY_STRING,
    });
  const [formOrganizationErrors, setFormOrganizationErrors] = useState<IFormEditReferralSourceOrganizationCrm>({
    nameOrganization: EMPTY_STRING,
    addressOrganization: EMPTY_STRING,
    cityOrganization: EMPTY_STRING,
    stateOrganization: EMPTY_STRING,
    postalCodeOrganization: EMPTY_STRING,
    mobilePhoneOrganization: EMPTY_STRING,
    typeOrganization: EMPTY_STRING,
  });
  const [images, setImages] = useState<IUploadImage[]>([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState<string>(RadioCrmEnum.INDIVIDUAL);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!referralSourceId) return;
    handleGetDetailReferralSource(referralSourceId);
  }, [referralSourceId, selectedCheckbox]);
  //#endregion Implement Hook

  //#region Handle Function
  const getUpdatedValue = (name: string, value: string): string => {
    const isValid = value === EMPTY_STRING || validateFormatNumber(value);

    switch (name) {
      case InputCRMEnum.HOME_PHONE_INDIVIDUAL:
        return isValid ? value : dataFormReferralSourceIndividualCrm.homePhoneIndividual || EMPTY_STRING;
      case InputCRMEnum.MOBILE_PHONE_INDIVIDUAL:
        return isValid ? value : dataFormReferralSourceIndividualCrm.mobilePhoneIndividual || EMPTY_STRING;
      case InputCRMEnum.POSTAL_CODE_INDIVIDUAL:
        return isValid ? value : dataFormReferralSourceIndividualCrm.postalCodeIndividual || EMPTY_STRING;
      case InputCRMEnum.WORK_PHONE_INDIVIDUAL:
        return isValid ? value : dataFormReferralSourceIndividualCrm.workPhoneIndividual || EMPTY_STRING;
      case InputCRMEnum.FAX_NUMBER_INDIVIDUAL:
        return isValid ? value : dataFormReferralSourceIndividualCrm.faxNumberIndividual || EMPTY_STRING;

      case InputCRMEnum.HOME_PHONE_ORGANIZATION:
        return isValid ? value : dataFormReferralSourceOrganizationCrm.homePhoneOrganization || EMPTY_STRING;
      case InputCRMEnum.MOBILE_PHONE_ORGANIZATION:
        return isValid ? value : dataFormReferralSourceOrganizationCrm.mobilePhoneOrganization || EMPTY_STRING;
      case InputCRMEnum.POSTAL_CODE_ORGANIZATION:
        return isValid ? value : dataFormReferralSourceOrganizationCrm.postalCodeOrganization || EMPTY_STRING;
      case InputCRMEnum.WORK_PHONE_ORGANIZATION:
        return isValid ? value : dataFormReferralSourceOrganizationCrm.workPhoneOrganization || EMPTY_STRING;
      case InputCRMEnum.FAX_NUMBER_ORGANIZATION:
        return isValid ? value : dataFormReferralSourceOrganizationCrm.faxNumberOrganization || EMPTY_STRING;

      default:
        return value;
    }
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    if (value.startsWith(' ')) {
      return;
    }

    const updatedValue = getUpdatedValue(name, value);

    if (selectedCheckbox === RadioCrmEnum.INDIVIDUAL) {
      setDataFormReferralSourceIndividualCrm((prevState) => {
        const updatedState = { ...prevState, [name]: updatedValue };

        if (!value) {
          setFormIndividualErrors((prevErrors) => ({
            ...prevErrors,
            [name]: t('common_error_message_required', {
              message: convertCamelCaseToTitleCase(name),
            }),
          }));
        } else {
          setFormIndividualErrors((prevErrors) => {
            const updatedError = {
              ...prevErrors,
            };
            delete updatedError[name as keyof IFormEditReferralSourceIndividualCrm];
            return updatedError;
          });
        }

        return updatedState;
      });
    } else {
      setDataFormReferralSourceOrganizationCrm((prevState) => {
        const updatedState = { ...prevState, [name]: updatedValue };

        if (!value) {
          setFormOrganizationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: t('common_error_message_required', {
              message: convertCamelCaseToTitleCase(name),
            }),
          }));
        } else {
          setFormOrganizationErrors((prevErrors) => {
            const updatedError = {
              ...prevErrors,
            };
            delete updatedError[name as keyof IFormEditReferralSourceOrganizationCrm];
            return updatedError;
          });
        }

        return updatedState;
      });
    }
  };

  const handleChangeSelect = (option: Option, name?: string) => {
    if (!name) return;

    const { value } = option;

    if (selectedCheckbox === RadioCrmEnum.INDIVIDUAL) {
      setDataFormReferralSourceIndividualCrm((prevState) => {
        const updatedState = { ...prevState, [name]: value };

        if (!value) {
          setFormIndividualErrors((prevErrors) => ({
            ...prevErrors,
            [name]: t('common_error_message_required', {
              message: convertCamelCaseToTitleCase(name),
            }),
          }));
        } else {
          setFormIndividualErrors((prevErrors) => {
            const updatedError = {
              ...prevErrors,
            };
            delete updatedError[name as keyof IFormEditReferralSourceIndividualCrm];
            return updatedError;
          });
        }

        return updatedState;
      });
    } else {
      setDataFormReferralSourceOrganizationCrm((prevState) => {
        const updatedState = { ...prevState, [name]: value };

        if (!value) {
          setFormOrganizationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: t('common_error_message_required', {
              message: convertCamelCaseToTitleCase(name),
            }),
          }));
        } else {
          setFormOrganizationErrors((prevErrors) => {
            const updatedError = {
              ...prevErrors,
            };
            delete updatedError[name as keyof IFormEditReferralSourceOrganizationCrm];
            return updatedError;
          });
        }

        return updatedState;
      });
    }
  };

  const handleUploadChange = (imageUploadList: IUploadImage[]) => {
    setImages(imageUploadList);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCheckbox(event.target.value);
  };

  const validateForm = (
    data: IFormEditReferralSourceIndividualCrm | IFormEditReferralSourceOrganizationCrm,
    setErrors: React.Dispatch<
      React.SetStateAction<IFormEditReferralSourceIndividualCrm | IFormEditReferralSourceOrganizationCrm>
    >
  ) => {
    const errors: Record<string, string> = {};

    const validFields: (keyof IFormEditReferralSourceIndividualCrm | keyof IFormEditReferralSourceOrganizationCrm)[] = [
      'firstNameIndividual',
      'lastNameIndividual',
      'addressIndividual',
      'cityIndividual',
      'stateIndividual',
      'postalCodeIndividual',
      'mobilePhoneIndividual',
      'typeIndividual',
      'nameOrganization',
      'addressOrganization',
      'cityOrganization',
      'stateOrganization',
      'postalCodeOrganization',
      'mobilePhoneOrganization',
      'typeOrganization',
    ];

    Object.keys(data).forEach((key) => {
      if (validFields.includes(key as keyof typeof data) && !data[key as keyof typeof data]) {
        errors[key] = t('common_error_message_required', {
          message: convertCamelCaseToTitleCase(key),
        });
      }
    });

    setErrors(errors);
    return errors;
  };

  const handleGetDetailReferralSource = (accountId: string) => {
    loading?.show();
    const isIndividual = selectedCheckbox === RadioCrmEnum.INDIVIDUAL;

    dispatch(getDetailReferrals(accountId))
      .unwrap()
      .then((response) => {
        const formattedData: IFormEditReferralSourceOrganizationCrm | IFormEditReferralSourceIndividualCrm =
          isIndividual
            ? {
                profileClientIndividual: response.data.avatarUrl,
                firstNameIndividual: response.data.firstName,
                lastNameIndividual: response.data.lastName,
                middleNameIndividual: response.data.middleName,
                addressIndividual: response.data.address,
                secondaryAddressIndividual: response.data.secondaryAddress,
                cityIndividual: response.data.city,
                stateIndividual: response.data.state,
                postalCodeIndividual: response.data.postalCode,
                mobilePhoneIndividual: response.data.mobilePhone,
                workPhoneIndividual: response.data.workPhone,
                emailIndividual: response.data.email,
                faxNumberIndividual: response.data.faxNumber,
                ownerIndividual: response.data.owner,
                typeIndividual: response.data.type,
                webAddressIndividual: response.data.webAddress,
              }
            : {
                addressOrganization: response.data.address,
                secondaryAddressOrganization: response.data.secondaryAddress,
                cityOrganization: response.data.city,
                stateOrganization: response.data.state,
                postalCodeOrganization: response.data.postalCode,
                mobilePhoneOrganization: response.data.mobilePhone,
                workPhoneOrganization: response.data.workPhone,
                emailOrganization: response.data.email,
                faxNumberOrganization: response.data.faxNumber,
                nameOrganization: response.data.organization,
                ownerOrganization: response.data.owner,
                typeOrganization: response.data.type,
                webAddressOrganization: response.data.webAddress,
              };

        if (response?.data?.avatarUrl) {
          setImages([{ url: response?.data?.avatarUrl }]);
        }

        isIndividual
          ? setDataFormReferralSourceIndividualCrm(formattedData)
          : setDataFormReferralSourceOrganizationCrm(formattedData);

        setSelectedCheckbox(response.data.referralRole);
      })
      .catch((error) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleSaveLogic = (
    data: IFormEditReferralSourceIndividualCrm | IFormEditReferralSourceOrganizationCrm,
    errors: IFormEditReferralSourceIndividualCrm | IFormEditReferralSourceOrganizationCrm
  ) => {
    const isIndividual = selectedCheckbox === RadioCrmEnum.INDIVIDUAL;

    if (isEmptyObject(errors)) {
      if (referralSourceId) {
        const formData = new FormData();

        if (images?.length > DEFAULT_NUMBER_ZERO) {
          images.forEach((image: IUploadImage) => {
            if (image.file) {
              formData.append('avatarAccount', image.file);
            }
          });
        }

        if (data as IFormEditReferralSourceOrganizationCrm) {
          const updatePayload: IBodyIEditReferralsPayload = isIndividual
            ? {
                firstName: dataFormReferralSourceIndividualCrm.firstNameIndividual || EMPTY_STRING,
                lastName: dataFormReferralSourceIndividualCrm.lastNameIndividual || EMPTY_STRING,
                middleName: dataFormReferralSourceIndividualCrm.middleNameIndividual || EMPTY_STRING,
                address: dataFormReferralSourceIndividualCrm.addressIndividual || EMPTY_STRING,
                secondaryAddress: dataFormReferralSourceIndividualCrm.secondaryAddressIndividual || EMPTY_STRING,
                city: dataFormReferralSourceIndividualCrm.cityIndividual || EMPTY_STRING,
                state: dataFormReferralSourceIndividualCrm.stateIndividual || EMPTY_STRING,
                homePhone: dataFormReferralSourceIndividualCrm.homePhoneIndividual || EMPTY_STRING,
                postalCode: dataFormReferralSourceIndividualCrm.postalCodeIndividual || EMPTY_STRING,
                mobilePhone: dataFormReferralSourceIndividualCrm.mobilePhoneIndividual || EMPTY_STRING,
                workPhone: dataFormReferralSourceIndividualCrm.workPhoneIndividual || EMPTY_STRING,
                email: dataFormReferralSourceIndividualCrm.emailIndividual || EMPTY_STRING,
                faxNumber: dataFormReferralSourceIndividualCrm.faxNumberIndividual || EMPTY_STRING,
                webAddress: dataFormReferralSourceIndividualCrm.webAddressIndividual || EMPTY_STRING,
                owner: dataFormReferralSourceIndividualCrm.ownerIndividual || EMPTY_STRING,
                type: dataFormReferralSourceIndividualCrm.typeIndividual || EMPTY_STRING,
              }
            : {
                address: dataFormReferralSourceOrganizationCrm.addressOrganization || EMPTY_STRING,
                city: dataFormReferralSourceOrganizationCrm.cityOrganization || EMPTY_STRING,
                email: dataFormReferralSourceOrganizationCrm.emailOrganization || EMPTY_STRING,
                faxNumber: dataFormReferralSourceOrganizationCrm.faxNumberOrganization || EMPTY_STRING,
                mobilePhone: dataFormReferralSourceOrganizationCrm.mobilePhoneOrganization || EMPTY_STRING,
                organization: dataFormReferralSourceOrganizationCrm.nameOrganization || EMPTY_STRING,
                owner: dataFormReferralSourceOrganizationCrm.ownerOrganization || EMPTY_STRING,
                postalCode: dataFormReferralSourceOrganizationCrm.postalCodeOrganization || EMPTY_STRING,
                secondaryAddress: dataFormReferralSourceOrganizationCrm.secondaryAddressOrganization || EMPTY_STRING,
                state: dataFormReferralSourceOrganizationCrm.stateOrganization || EMPTY_STRING,
                type: dataFormReferralSourceOrganizationCrm.typeOrganization || EMPTY_STRING,
                webAddress: dataFormReferralSourceOrganizationCrm.webAddressOrganization || EMPTY_STRING,
                workPhone: dataFormReferralSourceOrganizationCrm.workPhoneOrganization || EMPTY_STRING,
              };

          Object.entries(updatePayload).forEach(([key, value]) => {
            if (value) formData.append(key, value);
          });

          const payload: IFormUpdateReferrals = {
            accountId: referralSourceId,
            body: formData,
          };

          loading?.show();
          dispatch(updateReferrals(payload))
            .unwrap()
            .then((response) => {
              onClose && onClose();
              dispatch(referralActions.setRefreshReferralsList(true));
            })
            .catch((error) => {})
            .finally(() => {
              loading?.hide();
            });
        }
      }
    }
  };

  const handleSave = () => {
    if (selectedCheckbox === RadioCrmEnum.INDIVIDUAL) {
      const errors = validateForm(dataFormReferralSourceIndividualCrm, setFormIndividualErrors);
      handleSaveLogic(dataFormReferralSourceIndividualCrm, errors);
    } else {
      const errors = validateForm(dataFormReferralSourceOrganizationCrm, setFormOrganizationErrors);
      handleSaveLogic(dataFormReferralSourceOrganizationCrm, errors);
    }
  };
  //#endregion Handle Function

  return (
    <Modal title={t('crm_edit_referral_source_modal_title')} isOpen={isOpen} onClose={onClose}>
      <div id='crmModalComponent' className={cx('crmModalComponent')}>
        <div className={cx('checkboxContainer')}>
          <RadioCrm
            id={'individualId'}
            name={'individual'}
            label={t('crm_edit_modal_individual_label_radio')}
            value={RadioCrmEnum.INDIVIDUAL}
            checked={selectedCheckbox === RadioCrmEnum.INDIVIDUAL}
            onChange={handleRadioChange}
            disabled={selectedCheckbox === RadioCrmEnum.ORGANIZATION}
          />
          <RadioCrm
            id={'organizationId'}
            name={'organization'}
            label={t('crm_edit_modal_organization_label_radio')}
            value={RadioCrmEnum.ORGANIZATION}
            checked={selectedCheckbox === RadioCrmEnum.ORGANIZATION}
            onChange={handleRadioChange}
            disabled={selectedCheckbox === RadioCrmEnum.INDIVIDUAL}
          />
        </div>
        {selectedCheckbox === RadioCrmEnum.INDIVIDUAL ? (
          <div className={cx('contentModal')}>
            <BaseUploadImage
              label={t('crm_add_modal_profile_image_label')}
              textBtn={t('crm_add_modal_profile_image_placeholder')}
              height={64}
              onChange={handleUploadChange}
              defaultImageList={images}
            />

            <div className={cx('threeCol')}>
              <BaseInput
                id='firstNameIndividual'
                label={t('crm_add_modal_first_name_label')}
                name='firstNameIndividual'
                //placeholder={t('crm_add_modal_first_name_label')}
                value={dataFormReferralSourceIndividualCrm?.firstNameIndividual || EMPTY_STRING}
                onChange={handleChangeInput}
                messageError={formIndividualErrors.firstNameIndividual}
                required={true}
              />
              <BaseInput
                id='middleNameIndividual'
                label={t('crm_add_modal_middle_name_label')}
                name='middleNameIndividual'
                //placeholder={t('crm_add_modal_middle_name_label')}
                value={dataFormReferralSourceIndividualCrm?.middleNameIndividual || EMPTY_STRING}
                onChange={handleChangeInput}
              />
              <BaseInput
                id='lastNameIndividual'
                label={t('crm_add_modal_last_name_label')}
                name='lastNameIndividual'
                //placeholder={t('crm_add_modal_last_name_label')}
                value={dataFormReferralSourceIndividualCrm?.lastNameIndividual || EMPTY_STRING}
                onChange={handleChangeInput}
                messageError={formIndividualErrors.lastNameIndividual}
                required={true}
              />
            </div>

            <div className={cx('twoCol')}>
              <BaseInput
                id='addressIndividual'
                label={t('crm_add_modal_address_label')}
                name='addressIndividual'
                //placeholder={t('crm_add_modal_address_label')}
                value={dataFormReferralSourceIndividualCrm?.addressIndividual || EMPTY_STRING}
                onChange={handleChangeInput}
                messageError={formIndividualErrors.addressIndividual}
                required={true}
              />

              <BaseInput
                id='secondaryAddressIndividual'
                label={t('crm_add_modal_secondary_address_label')}
                name='secondaryAddressIndividual'
                //placeholder={t('crm_add_modal_secondary_address_label')}
                value={dataFormReferralSourceIndividualCrm?.secondaryAddressIndividual || EMPTY_STRING}
                onChange={handleChangeInput}
              />
            </div>

            <div className={cx('threeCol')}>
              <BaseInput
                id='cityIndividual'
                label={t('crm_add_modal_city_label')}
                name='cityIndividual'
                //placeholder={t('crm_add_modal_city_label')}
                value={dataFormReferralSourceIndividualCrm?.cityIndividual || EMPTY_STRING}
                onChange={handleChangeInput}
                messageError={formIndividualErrors.addressIndividual}
                required={true}
              />
              <BaseSelect
                options={mockDataState || []}
                name='stateIndividual'
                label={t('crm_add_modal_state_label')}
                placeholder={t('common_select_placeholder')}
                onChange={handleChangeSelect}
                errorMessage={formIndividualErrors.stateIndividual}
                value={dataFormReferralSourceIndividualCrm?.stateIndividual || EMPTY_STRING}
                required={true}
              />
              <BaseInput
                id='postalCodeIndividual'
                label={t('crm_add_modal_postal_code_label')}
                name='postalCodeIndividual'
                //placeholder={t('crm_add_modal_postal_code_label')}
                value={dataFormReferralSourceIndividualCrm?.postalCodeIndividual || EMPTY_STRING}
                onChange={handleChangeInput}
                messageError={formIndividualErrors.postalCodeIndividual}
                required={true}
              />
            </div>

            <div className={cx('twoCol')}>
              <BaseInput
                id='mobilePhoneIndividual'
                label={t('crm_add_modal_mobile_phone_label')}
                name='mobilePhoneIndividual'
                //placeholder={t('crm_add_modal_mobile_phone_label')}
                value={dataFormReferralSourceIndividualCrm?.mobilePhoneIndividual || EMPTY_STRING}
                onChange={handleChangeInput}
                messageError={formIndividualErrors.mobilePhoneIndividual}
                required={true}
              />

              <BaseInput
                id='workPhoneIndividual'
                label={t('crm_add_modal_work_phone_label')}
                name='workPhoneIndividual'
                //placeholder={t('crm_add_modal_work_phone_label')}
                value={dataFormReferralSourceIndividualCrm?.workPhoneIndividual || EMPTY_STRING}
                onChange={handleChangeInput}
              />
            </div>

            <BaseInput
              id='emailIndividual'
              label={t('crm_add_modal_email_label')}
              name='emailIndividual'
              //placeholder={t('crm_add_modal_email_label')}
              value={dataFormReferralSourceIndividualCrm?.emailIndividual || EMPTY_STRING}
              onChange={handleChangeInput}
            />

            <div className={cx('twoCol')}>
              <BaseInput
                id='faxNumberIndividual'
                label={t('crm_edit_modal_fax_number_label')}
                name='faxNumberIndividual'
                //placeholder={t('crm_edit_modal_fax_number_label')}
                value={dataFormReferralSourceIndividualCrm?.faxNumberIndividual || EMPTY_STRING}
                onChange={handleChangeInput}
              />

              <BaseInput
                id='webAddressIndividual'
                label={t('crm_edit_modal_web_address_label')}
                name='webAddressIndividual'
                //placeholder={t('crm_edit_modal_web_address_label')}
                value={dataFormReferralSourceIndividualCrm?.webAddressIndividual || EMPTY_STRING}
                onChange={handleChangeInput}
              />
            </div>

            <div className={cx('twoCol')}>
              <BaseSelect
                options={mockOptionsTypeCrm || []}
                name='typeIndividual'
                label={t('crm_edit_modal_type_label')}
                placeholder={t('common_select_placeholder')}
                onChange={handleChangeSelect}
                value={dataFormReferralSourceIndividualCrm?.typeIndividual || EMPTY_STRING}
                errorMessage={formIndividualErrors.typeIndividual}
                required={true}
              />

              <BaseSelect
                options={mockOptionsOwnerCrm || []}
                name='ownerIndividual'
                label={t('crm_edit_modal_owner_label')}
                placeholder={t('common_select_placeholder')}
                onChange={handleChangeSelect}
                value={dataFormReferralSourceIndividualCrm?.ownerIndividual || EMPTY_STRING}
              />
            </div>
          </div>
        ) : (
          <div className={cx('contentModal')}>
            <BaseInput
              id='nameOrganization'
              label={t('crm_edit_modal_organization_label')}
              name='nameOrganization'
              //placeholder={t('crm_edit_modal_organization_label')}
              value={dataFormReferralSourceOrganizationCrm?.nameOrganization || EMPTY_STRING}
              onChange={handleChangeInput}
              messageError={formOrganizationErrors.nameOrganization}
              required={true}
            />

            <div className={cx('twoCol')}>
              <BaseInput
                id='addressOrganization'
                label={t('crm_add_modal_address_label')}
                name='addressOrganization'
                //placeholder={t('crm_add_modal_address_label')}
                value={dataFormReferralSourceOrganizationCrm?.addressOrganization || EMPTY_STRING}
                onChange={handleChangeInput}
                messageError={formOrganizationErrors.addressOrganization}
                required={true}
              />

              <BaseInput
                id='secondaryAddressOrganization'
                label={t('crm_add_modal_secondary_address_label')}
                name='secondaryAddressOrganization'
                //placeholder={t('crm_add_modal_secondary_address_label')}
                value={dataFormReferralSourceOrganizationCrm?.secondaryAddressOrganization || EMPTY_STRING}
                onChange={handleChangeInput}
              />
            </div>

            <div className={cx('threeCol')}>
              <BaseInput
                id='cityOrganization'
                label={t('crm_add_modal_city_label')}
                name='cityOrganization'
                //placeholder={t('crm_add_modal_city_label')}
                value={dataFormReferralSourceOrganizationCrm?.cityOrganization || EMPTY_STRING}
                onChange={handleChangeInput}
                messageError={formOrganizationErrors.cityOrganization}
                required={true}
              />
              <BaseSelect
                options={mockDataState || []}
                name='stateOrganization'
                label={t('crm_add_modal_state_label')}
                placeholder={t('common_select_placeholder')}
                onChange={handleChangeSelect}
                errorMessage={formOrganizationErrors.stateOrganization}
                value={dataFormReferralSourceOrganizationCrm?.stateOrganization || EMPTY_STRING}
                required={true}
              />
              <BaseInput
                id='postalCodeOrganization'
                label={t('crm_add_modal_postal_code_label')}
                name='postalCodeOrganization'
                //placeholder={t('crm_add_modal_postal_code_label')}
                value={dataFormReferralSourceOrganizationCrm?.postalCodeOrganization || EMPTY_STRING}
                onChange={handleChangeInput}
                messageError={formOrganizationErrors.postalCodeOrganization}
                required={true}
              />
            </div>

            <div className={cx('twoCol')}>
              <BaseInput
                id='mobilePhoneOrganization'
                label={t('crm_add_modal_mobile_phone_label')}
                name='mobilePhoneOrganization'
                //placeholder={t('crm_add_modal_mobile_phone_label')}
                value={dataFormReferralSourceOrganizationCrm?.mobilePhoneOrganization || EMPTY_STRING}
                onChange={handleChangeInput}
                messageError={formOrganizationErrors.mobilePhoneOrganization}
                required={true}
              />

              <BaseInput
                id='workPhoneOrganization'
                label={t('crm_add_modal_work_phone_label')}
                name='workPhoneOrganization'
                //placeholder={t('crm_add_modal_work_phone_label')}
                value={dataFormReferralSourceOrganizationCrm?.workPhoneOrganization || EMPTY_STRING}
                onChange={handleChangeInput}
              />
            </div>

            <BaseInput
              id='emailOrganization'
              label={t('crm_add_modal_email_label')}
              name='emailOrganization'
              //placeholder={t('crm_add_modal_email_label')}
              value={dataFormReferralSourceOrganizationCrm?.emailOrganization || EMPTY_STRING}
              onChange={handleChangeInput}
            />

            <div className={cx('twoCol')}>
              <BaseInput
                id='faxNumberOrganization'
                label={t('crm_edit_modal_fax_number_label')}
                name='faxNumberOrganization'
                //placeholder={t('crm_edit_modal_fax_number_label')}
                value={dataFormReferralSourceOrganizationCrm?.faxNumberOrganization || EMPTY_STRING}
                onChange={handleChangeInput}
              />

              <BaseInput
                id='webAddressOrganization'
                label={t('crm_edit_modal_web_address_label')}
                name='webAddressOrganization'
                //placeholder={t('crm_edit_modal_web_address_label')}
                value={dataFormReferralSourceOrganizationCrm?.webAddressOrganization || EMPTY_STRING}
                onChange={handleChangeInput}
              />
            </div>

            <div className={cx('twoCol')}>
              <BaseSelect
                options={mockOptionsTypeCrm || []}
                name='typeOrganization'
                label={t('crm_edit_modal_type_label')}
                placeholder={t('common_select_placeholder')}
                onChange={handleChangeSelect}
                value={dataFormReferralSourceOrganizationCrm?.typeOrganization || EMPTY_STRING}
                errorMessage={formOrganizationErrors.typeOrganization}
                required={true}
              />

              <BaseSelect
                options={mockOptionsOwnerCrm || []}
                name='ownerOrganization'
                label={t('crm_edit_modal_owner_label')}
                placeholder={t('common_select_placeholder')}
                onChange={handleChangeSelect}
                value={dataFormReferralSourceOrganizationCrm?.ownerOrganization || EMPTY_STRING}
              />
            </div>
          </div>
        )}

        <div className={cx('footerButton')}>
          <BaseButton text={t('common_cancel_label')} width={65} onClick={onClose} />
          <BaseButton
            text={t('common_save_label')}
            typeStyle={ButtonTypeEnum.PRIMARY}
            width={80}
            onClick={handleSave}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CrmEditReferralSource;
