// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { ICertification } from '~/mockData/mockInterface';
// Styles, images, icons
import styles from './CertificationItem.module.scss';
import { icons } from '~/assets';

type Props = {
  data: ICertification;
  onShow: (id: string) => void;
};

const cx = classNames.bind(styles);

const CertificationItem = (props: Props) => {
  //#region Destructuring Props
  const { data, onShow } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='certificationItemComponent' className={cx('certificationItemComponent')}>
      <h3 className={cx('title')}>{data.certificationTitle}</h3>
      <div className={cx('courseName')}>
        <span>{t('caregiver_detail_skill_tab_certification_coursera')}</span>
        <span className={cx('dot')}></span>
        <span>{data.courseName}</span>
      </div>

      <button className={cx('btnCertificate')} onClick={() => onShow(data.id)}>
        {t('caregiver_detail_skill_tab_btn_show_certificate')}
        <img src={icons.commonIconArrowBottomWhite} alt={t('common_img_text_alt')} className={cx('icon')} />
      </button>
    </div>
  );
};

export default CertificationItem;
