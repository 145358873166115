// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import userApi from '~/apis/user';
import {
  CHANGE_STATUS_ACCOUNT,
  DELETE_USER,
  GET_DETAIL_PROFILE,
  GET_DETAIL_USER,
  GET_LIST_ASSIGNEE,
} from '~/utils/constants/actionType';
import { IListQueryParams } from '~/utils/interface/common';
import { IFormUpdateStatusAccount } from '~/utils/interface/user';

export const getDetailUser = createAsyncThunk(GET_DETAIL_USER, async (accountId: string, { rejectWithValue }) => {
  try {
    const res = await userApi.getDetail(accountId);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDetailProfile = createAsyncThunk(GET_DETAIL_PROFILE, async (_, { rejectWithValue }) => {
  try {
    const res = await userApi.getDetailProfile();
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const changeStatusAccount = createAsyncThunk(
  CHANGE_STATUS_ACCOUNT,
  async (body: IFormUpdateStatusAccount, { rejectWithValue }) => {
    try {
      const res = await userApi.changeStatusAccount(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListAssignee = createAsyncThunk(
  GET_LIST_ASSIGNEE,
  async (payload: IListQueryParams, { rejectWithValue }) => {
    try {
      const res = await userApi.getListAssignee(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteUser = createAsyncThunk(DELETE_USER, async (accountId: string, { rejectWithValue }) => {
  try {
    const res = await userApi.deleteUser(accountId);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
