// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
// Components, Layouts, Pages
import { BaseSelect, BaseSelectMultiple } from '~/components';
// Others
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
import { optionMedicationsTakenAtCenter, optionMedicationsTakesMedication } from '~/mockData';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './MedicationsDayCenterClient.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const MedicationsDayCenterClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='medicationsDayCenterClientComponent' className={cx('container')}>
      <div className={cx('viewGroupInput')}>
        <Controller
          name={'medication.medicationTakenAtCenter'}
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              value={value || EMPTY_STRING}
              onChange={({ value }) => onChange(value)}
              options={optionMedicationsTakenAtCenter}
              label={t('care_plan_medications_medication_taken_at_center')}
              placeholder={t('common_select_placeholder')}
              errorMessage={errors.medication?.medicationTakenAtCenter?.message}
            />
          )}
        />

        <Controller
          name={'medication.takesMedication'}
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectMultiple
              value={value || []}
              onChange={onChange}
              label={t('care_plan_takes_medication')}
              options={optionMedicationsTakesMedication}
              placeholder={t('common_placeholder_select')}
              errorMessage={errors.medication?.takesMedication?.message}
            />
          )}
        />
      </div>
    </div>
  );
};

export default MedicationsDayCenterClient;
