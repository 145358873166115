// Libs
import classNames from 'classnames/bind';
// Components, Layouts, Pages
// Others
// Styles, images, icons
import styles from './RadioCrm.module.scss';

type Props = {
  id: string;
  name: string;
  width?: number;
  height?: number;
  fontSize?: number;
  checked?: boolean;
  label?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  color?: string;
  labelClassName?: string;
};

const cx = classNames.bind(styles);

const RadioCrm = (props: Props) => {
  //#region Destructuring Props
  const { id, checked, name, label, onChange, value, disabled, fontSize, width, height, color, labelClassName } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='radioCrm'>
      <div className={cx('radioCrmContainer')}>
        <div className={cx('radioCrmWrap')}>
          <input
            style={{ width, height }}
            type='radio'
            name={name}
            className={cx('radioInput')}
            id={id}
            value={value}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
        </div>

        <label
          htmlFor={id}
          style={{ fontSize, color }}
          className={cx('radioCrmLabel', disabled && 'disabled', labelClassName)}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

export default RadioCrm;
