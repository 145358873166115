// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { ChangeEvent } from 'react';
// Components, Layouts, Pages
import { BaseDatePicker, BaseInput, BaseSelect, BaseSelectMultiple, RadioCrm } from '~/components';
// Others
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
import { EMPTY_STRING } from '~/utils/constants/common';
import { DRNDemographicEnum } from '~/utils/enum';
import { PRIMARY_SIROCCO } from '~/utils/constants/color';
import {
  attendsServiceDemographicOptions,
  genderDemographicOptions,
  languageDemographicOptions,
  maritalStatusDemographicOptions,
  religionDemographicOptions,
} from '~/mockData';
// Styles, images, icons
import styles from './CarePlanDemographic.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CarePlanDemographic = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='carePlanDemographic' className={cx('container')}>
      <div className={cx('threeCol')}>
        <Controller
          name='demographic.dateOfBirth'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseDatePicker
              value={value || EMPTY_STRING}
              label={t('care_plan_demographic_date_of_birth_label')}
              placeholderText={t('common_placeholder_select')}
              onDateSelected={onChange}
              errorMessage={errors.demographic?.dateOfBirth?.message}
            />
          )}
        />

        <Controller
          name='demographic.height'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseInput
              id='height'
              label={t('care_plan_demographic_height_label')}
              value={value || EMPTY_STRING}
              onChange={onChange}
              messageError={errors.demographic?.height?.message}
            />
          )}
        />

        <Controller
          name='demographic.weight'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseInput
              id='weight'
              label={t('care_plan_demographic_weight_label')}
              value={value || EMPTY_STRING}
              onChange={onChange}
              messageError={errors.demographic?.weight?.message}
            />
          )}
        />
      </div>

      <div className={cx('threeCol')}>
        <Controller
          name='demographic.dateOfDeath'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseDatePicker
              label={t('care_plan_demographic_date_of_death_label')}
              placeholderText={t('common_placeholder_select')}
              onDateSelected={onChange}
              value={value || EMPTY_STRING}
              errorMessage={errors.demographic?.dateOfDeath?.message}
            />
          )}
        />

        <Controller
          name='demographic.gender'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              value={value || EMPTY_STRING}
              options={genderDemographicOptions}
              placeholder={t('common_placeholder_select')}
              label={t('care_plan_demographic_gender_label')}
              onChange={({ value }) => {
                onChange(value);
              }}
              errorMessage={errors.demographic?.gender?.message}
            />
          )}
        />

        <Controller
          name='demographic.maritalStatus'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              value={value || EMPTY_STRING}
              options={maritalStatusDemographicOptions}
              placeholder={t('common_placeholder_select')}
              label={t('care_plan_demographic_marital_status_label')}
              onChange={({ value }) => {
                onChange(value);
              }}
              errorMessage={errors.demographic?.maritalStatus?.message}
            />
          )}
        />
      </div>

      <div className={cx('threeCol')}>
        <Controller
          name='demographic.spouseName'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseInput
              id='spouseName'
              label={t('care_plan_demographic_spouse_name_label')}
              value={value || EMPTY_STRING}
              onChange={onChange}
              messageError={errors.demographic?.spouseName?.message}
            />
          )}
        />

        <Controller
          name='demographic.livesWith'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseInput
              id='liveWith'
              label={t('care_plan_demographic_live_with_label')}
              value={value?.[0] || EMPTY_STRING}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChange([event.target.value])}
              messageError={errors.demographic?.livesWith?.message}
            />
          )}
        />

        <Controller
          name='demographic.religion'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              value={value || EMPTY_STRING}
              options={religionDemographicOptions}
              placeholder={t('common_placeholder_select')}
              label={t('care_plan_demographic_religion_label')}
              onChange={({ value }) => {
                onChange(value);
              }}
              errorMessage={errors.demographic?.religion?.message}
            />
          )}
        />
      </div>

      <div className={cx('threeCol')}>
        <Controller
          name='demographic.attendsServices'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              value={value || EMPTY_STRING}
              options={attendsServiceDemographicOptions}
              placeholder={t('common_placeholder_select')}
              label={t('care_plan_demographic_attends_service_label')}
              onChange={({ value }) => {
                onChange(value);
              }}
              errorMessage={errors.demographic?.attendsServices?.message}
            />
          )}
        />

        <Controller
          name='demographic.languages'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectMultiple
              value={value || []}
              options={languageDemographicOptions}
              placeholder={t('common_placeholder_select')}
              label={t('care_plan_demographic_language_label')}
              onChange={onChange}
              errorMessage={errors.demographic?.languages?.message}
            />
          )}
        />

        <Controller
          name='demographic.pastProfession'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseInput
              id='pastProfession'
              label={t('care_plan_demographic_past_profession_label')}
              value={value || EMPTY_STRING}
              onChange={onChange}
              messageError={errors.demographic?.pastProfession?.message}
            />
          )}
        />
      </div>

      <Controller
        name='demographic.dnr'
        control={control}
        render={({ field: { value, onChange } }) => (
          <div className={cx('dnrWrap')}>
            <label className={cx('label')}>{t('care_plan_demographic_dnr_label')}</label>

            <div className={cx('dnr')}>
              <RadioCrm
                color={PRIMARY_SIROCCO}
                labelClassName={cx('radioLabel')}
                id='dnrYes'
                name='dnr'
                value={DRNDemographicEnum.YES}
                checked={value === DRNDemographicEnum.YES}
                onChange={onChange}
                label={t('care_plan_demographic_dnr_yes_label')}
              />

              <RadioCrm
                color={PRIMARY_SIROCCO}
                labelClassName={cx('radioLabel')}
                id='dnrNo'
                name='dnr'
                value={DRNDemographicEnum.NO}
                checked={value === DRNDemographicEnum.NO}
                onChange={onChange}
                label={t('care_plan_demographic_dnr_no_label')}
              />

              <RadioCrm
                color={PRIMARY_SIROCCO}
                labelClassName={cx('radioLabel')}
                id='dnrUnknown'
                name='dnr'
                value={DRNDemographicEnum.UNKNOWN}
                checked={value === DRNDemographicEnum.UNKNOWN}
                onChange={onChange}
                label={t('care_plan_demographic_dnr_un_known_label')}
              />
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default CarePlanDemographic;
