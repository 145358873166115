// Libs
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
// Components, Layouts, Pages
import { Loading } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { IDetailTimeClock } from '~/utils/interface/timeClock';
import { DEFAULT_GG_MAP_LOAD_SCRIPT_LIB, EMPTY_STRING } from '~/utils/constants/common';
import { convertTime, formatMinutesToHourMinute } from '~/utils/helper';
import { TimeFormatEnum } from '~/utils/enum';
import { getDetailTimeClock } from '~/thunks/timeClock/timeClockThunk';
import { googleMapApiKey } from '~/utils/constants/env';
// Styles, images, icons
import styles from './TimeClockDetail.module.scss';

type Props = {
  timeClockId?: string;
};

const cx = classNames.bind(styles);

const TimeClockDetail = (props: Props) => {
  //#region Destructuring Props
  const { timeClockId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [dataTimeClockDetail, setDataTimeClockDetail] = useState<IDetailTimeClock>();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapApiKey ?? EMPTY_STRING,
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!timeClockId) return;

    handleGetDetailTimeCock(timeClockId);
  }, [timeClockId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetailTimeCock = (timeClockId: string) => {
    setIsLoading(true);

    dispatch(getDetailTimeClock(+timeClockId))
      .unwrap()
      .then((response) => {
        const responses: IDetailTimeClock = response?.data;
        setDataTimeClockDetail(responses);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  //#endregion Handle Function

  return (
    <div id='timeClockDetailComponent' className={cx('timeClockDetailComponent')}>
      <div className={cx('headerWrap')}>
        <h2 className={cx('taskName')}>{dataTimeClockDetail?.task?.name || EMPTY_STRING}</h2>

        <p className={cx('line')}></p>
      </div>

      <div className={cx('contentWrap')}>
        <div className={cx('rowDetail')}>
          <p className={cx('labelDetail')}>{t('time_clock_detail_location_label')}</p>
          <p className={cx('valueDetail')}>{dataTimeClockDetail?.location || EMPTY_STRING}</p>
        </div>
        <div className={cx('rowDetail')}>
          <p className={cx('labelDetail')}>{t('time_clock_detail_check_in_label')}</p>
          <p className={cx('valueDetail')}>
            {dataTimeClockDetail?.startTime
              ? convertTime(dataTimeClockDetail?.startTime, TimeFormatEnum.HOUR_MINUTE_AM_PM)
              : EMPTY_STRING}
          </p>
        </div>
        <div className={cx('rowDetail')}>
          <p className={cx('labelDetail')}>{t('time_clock_detail_check_out_label')}</p>
          <p className={cx('valueDetail')}>
            {dataTimeClockDetail?.endTime
              ? convertTime(dataTimeClockDetail?.endTime, TimeFormatEnum.HOUR_MINUTE_AM_PM)
              : EMPTY_STRING}
          </p>
        </div>
        <div className={cx('rowDetail')}>
          <p className={cx('labelDetail')}>{t('time_clock_detail_duration_label')}</p>
          <p className={cx('valueDetail')}>
            {dataTimeClockDetail?.duration ? formatMinutesToHourMinute(dataTimeClockDetail?.duration) : EMPTY_STRING}
          </p>
        </div>
        <div className={cx('rowDetail')}>
          <p className={cx('labelDetail')}>{t('time_clock_detail_total_break')}</p>
          <p className={cx('valueDetail')}>
            {dataTimeClockDetail?.totalBreak
              ? formatMinutesToHourMinute(dataTimeClockDetail?.totalBreak)
              : EMPTY_STRING}
          </p>
        </div>
        {/* <div className={cx('mapSection')}>
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: 400 }}
              center={{
                lat: Number(dataTimeClockDetail?.lat) || DEFAULT_NUMBER_ZERO,
                lng: Number(dataTimeClockDetail?.lng) || DEFAULT_NUMBER_ZERO,
              }}
              zoom={DEFAULT_MAP_ZOOM}
              options={{
                gestureHandling: 'cooperative',
              }}
            >
              {Number(dataTimeClockDetail?.lat) && Number(dataTimeClockDetail?.lng) && (
                <Marker
                  position={{
                    lat: Number(dataTimeClockDetail?.lat) || DEFAULT_NUMBER_ZERO,
                    lng: Number(dataTimeClockDetail?.lng) || DEFAULT_NUMBER_ZERO,
                  }}
                />
              )}
            </GoogleMap>
          )} */}
        <div className={cx('mapView')}>
          <label className={cx('label')} htmlFor='mapView'>
            {t('modal_add_time_clock_map_view_label')}
          </label>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5736.254409334056!2d-76.17743605275045!3d43.029276073883025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d9f3f0124b6a67%3A0xf83a21dd8fc44ddc!2sThe%20Highland!5e0!3m2!1sen!2s!4v1726109271087!5m2!1sen!2s'
            width='100%'
            height='450'
            style={{ border: 0 }}
            allowFullScreen
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          ></iframe>
        </div>
      </div>

      {isLoading && <Loading />}
    </div>
  );
};

export default TimeClockDetail;
