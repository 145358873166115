// Libs
import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import applicantReducer from '~/thunks/applicant/applicantSlice';
// Others
import authReducer from '~/thunks/auth/authSlice';
import careAlertReducer from '~/thunks/careAlert/careAlertSlice';
import caregiverReducer from '~/thunks/caregiver/caregiverSlice';
import conversationReducer from '~/thunks/conversation/conversationSlice';
import employeeReducer from '~/thunks/employee/employeeSlice';
import toastReducer from '~/thunks/toast/toastSlice';
import userReducer from '~/thunks/user/userSlice';
import prospectReducer from '~/thunks/crm/prospect/prospectSlice';
import clientReducer from '~/thunks/crm/clients/clientsSlice';
import referralReducer from '~/thunks/crm/referral/referralSlice';
import fileReducer from '~/thunks/file/fileSlice';
import taskReducer from '~/thunks/task/taskSlice';
import timeClockReducer from '~/thunks/timeClock/timeClockSlice';
import scheduleReducer from '~/thunks/schedule/scheduleSlice';
import dashboardReducer from '~/thunks/dashboard/dashboardSlice';
import activityReducer from '~/thunks/activity/activitySlice';
import quickBookReducer from '~/thunks/quickBook/quickBookSlice';
import notificationsReducer from '~/thunks/notifications/notificationsSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  employeeState: employeeReducer,
  caregiverState: caregiverReducer,
  userState: userReducer,
  toastState: toastReducer,
  applicantState: applicantReducer,
  conversationState: conversationReducer,
  careAlertState: careAlertReducer,
  clientState: clientReducer,
  prospectState: prospectReducer,
  referralState: referralReducer,
  fileState: fileReducer,
  taskState: taskReducer,
  timeClockState: timeClockReducer,
  scheduleState: scheduleReducer,
  dashboardState: dashboardReducer,
  activityState: activityReducer,
  quicBookState: quickBookReducer,
  notifications: notificationsReducer,
});

export function makeStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== 'production',
  });
}

const store = makeStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
