// Libs
import classNames from 'classnames/bind';
import { ReactCalendarGroupRendererProps } from 'react-calendar-timeline';
import { Tooltip } from 'react-tooltip';
import { useRef } from 'react';
// Components, Layouts, Pages
import { ImageCircle } from '~/components';
// Others
import { ITimelineGroup } from '~/utils/interface/schedule';
import { ImageCircleTypeEnum } from '~/utils/enum';
import { EMPTY_STRING } from '~/utils/constants/common';
import { generateGuid } from '~/utils/helpers/common';
import { useTextOverflow } from '~/utils/customHook';
// Styles, images, icons
import styles from './GroupTimeline.module.scss';

type Props = Omit<ReactCalendarGroupRendererProps, 'group'> & {
  groupData: ITimelineGroup;
};

const cx = classNames.bind(styles);

const GroupTimeline = (props: Props) => {
  //#region Destructuring Props
  const { groupData } = props;
  //#endregion Destructuring Props

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const contentRefs = useRef<HTMLDivElement | null>(null);
  const overflowMap = useTextOverflow(contentRefs.current);
  const id = generateGuid();
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div className={cx('groupContainer')}>
      <ImageCircle
        type={groupData.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
        imageUrl={groupData.avatarUrl}
        firstName={groupData.title}
        width={32}
        height={32}
      />
      <div
        data-tooltip-id={`name-${id}`}
        ref={contentRefs}
        data-tooltip-content={groupData?.title || EMPTY_STRING}
        className={cx('groupName')}
      >
        {groupData?.title || EMPTY_STRING}
      </div>

      {overflowMap.singleRef && <Tooltip id={`name-${id}`} className={cx('tooltipText')} />}
    </div>
  );
};

export default GroupTimeline;
