// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

// Components, Layouts, Pages
import { BaseSelectMultiple, BaseSelect, BaseTextarea } from '~/components';

// Others
import { behaviorsOptions, dementiaDayCenterClientOptions, exitSeekingOptions } from '~/mockData';
import { IFormCarePlan } from '~/utils/interface/crm/carePlan';
import { EMPTY_STRING } from '~/utils/constants/common';

// Styles, images, icons
import styles from './MentalBehaviorDayCenterClient.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const MentalBehaviorDayCenterClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormCarePlan>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='mentalBehaviorDayCenterClient' className={cx('container')}>
      <div className={cx('viewGroupInput')}>
        <Controller
          name='mentalBehaviorCondition.behaviors'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectMultiple
              label={t('mental_behavior_day_center_behaviors_title')}
              options={behaviorsOptions}
              placeholder={t('common_placeholder_select')}
              value={value || []}
              onChange={onChange}
              errorMessage={errors.mentalBehaviorCondition?.behaviors?.message}
            />
          )}
        />

        <Controller
          name='mentalBehaviorCondition.exitSeeking'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              options={exitSeekingOptions}
              label={t('mental_behavior_day_center_exit_seeking_title')}
              placeholder={t('common_placeholder_select')}
              value={value || EMPTY_STRING}
              onChange={({ value }) => {
                onChange(value);
              }}
              errorMessage={errors.mentalBehaviorCondition?.exitSeeking?.message}
            />
          )}
        />

        <Controller
          name='mentalBehaviorCondition.dementia'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              options={dementiaDayCenterClientOptions}
              label={t('mental_behavior_day_center_dementia_title')}
              placeholder={t('common_placeholder_select')}
              value={value || EMPTY_STRING}
              onChange={({ value }) => {
                onChange(value);
              }}
              errorMessage={errors.mentalBehaviorCondition?.dementia?.message}
            />
          )}
        />
      </div>

      <Controller
        name='mentalBehaviorCondition.diagnosedDisordersMedications'
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseTextarea
            label={t('mental_behavior_day_center_disorders_title')}
            height={90}
            id='mentalBehaviorDisorders'
            value={value || EMPTY_STRING}
            onChange={onChange}
            messageError={errors.mentalBehaviorCondition?.diagnosedDisordersMedications?.message}
          />
        )}
      />
    </div>
  );
};

export default MentalBehaviorDayCenterClient;
