// Libs
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
// Components, Layouts, Pages
// Others
import { GRAY_EBF4F2 } from '~/utils/constants/color';
// Styles, images, icons
import styles from './ButtonThreeDot.module.scss';
import { icons } from '~/assets';

type Props = {
  width?: number | string;
  minWidth?: number | string;
  height?: number;
  borderRadius?: number | string;
  icon?: string;
  iconWidthHeight?: string | number;
  backgroundColor?: string;
  onView?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
};

const cx = classNames.bind(styles);

const ButtonThreeDot = (props: Props) => {
  //#region Destructuring Props
  const {
    width = 'fit-content',
    minWidth,
    height = 24,
    icon = icons.commonIconThreeDotVertical,
    borderRadius,
    backgroundColor = GRAY_EBF4F2,
    onView,
    onEdit,
    onDelete,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleView = (closeFn: Function) => () => {
    onView && onView();
    closeFn();
  };

  const handleEdit = (closeFn: Function) => () => {
    onEdit && onEdit();
    closeFn();
  };

  const handleDelete = (closeFn: Function) => () => {
    onDelete && onDelete();
    closeFn();
  };
  //#endregion Handle Function

  return (
    <div id='buttonThreeDotComponent'>
      <Popover className='relative'>
        <PopoverButton className={cx('btnGroup')}>
          <img
            src={icon}
            alt={t('common_img_text_alt')}
            className={cx('btnImg')}
            style={{ width, height, minWidth, borderRadius, backgroundColor: backgroundColor }}
          />
        </PopoverButton>

        <PopoverPanel transition anchor={{ to: 'bottom end', gap: '4px' }} className={cx('popupContainer')}>
          {({ close }) => (
            <div className={cx('popBtnGroup')}>
              {onView && (
                <button onClick={handleView(close)} className={cx('buttonOption')}>
                  <img src={icons.signInIconEyeShow} alt={t('common_text_view')} width={16} height={16} />
                  <span className={cx('labelText')}>{t('common_action_view')}</span>
                </button>
              )}
              {onEdit && (
                <button onClick={handleEdit(close)} className={cx('buttonOption')}>
                  <img src={icons.commonIconEdit} alt={t('common_img_text_alt')} width={16} height={16} />
                  <span className={cx('labelText')}>{t('common_action_edit')}</span>
                </button>
              )}
              {onDelete && (
                <button onClick={handleDelete(close)} className={cx('buttonDelete')}>
                  <img src={icons.commonIconTrash} alt={t('common_img_text_alt')} width={16} height={16} />
                  <span className={cx('labelDelete')}>{t('common_delete_label')}</span>
                </button>
              )}

              {!onView && !onEdit && !onDelete && (
                <p className={cx('labelText')}>{t('common_label_no_action_available')}</p>
              )}
            </div>
          )}
        </PopoverPanel>
      </Popover>
    </div>
  );
};

export default ButtonThreeDot;
