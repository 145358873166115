// Libs
import classNames from 'classnames/bind';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { ButtonThreeDot } from '~/components';
// Others
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './ThreeDotOptionsCaregiverReferral.module.scss';
import { icons } from '~/assets';

const cx = classNames.bind(styles);

const ThreeDotOptionsCaregiverReferral = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const modalRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const handleToggleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleEdit = () => {
    //TODO HuyPahmGRF-ADB: Handle logic edit
  };

  const handleDelete = () => {
    //TODO HuyPahmGRF-ADB: Handle logic delete
  };
  //#endregion Handle Function

  return (
    <div id='threeDotReferralComponent' className={cx('container')} ref={modalRef}>
      <ButtonThreeDot
        iconWidthHeight={20}
        width={20}
        height={20}
        icon={icons.commonIconThreeDotHorizontal}
        backgroundColor={EMPTY_STRING}
      />

      {isOpen && (
        <div className={cx('viewButtonOptions')}>
          <button onClick={handleEdit} className={cx('buttonOption')}>
            <img src={icons.commonIconEdit} alt={t('common_img_text_alt')} width={16} height={16} />
            <span className={cx('labelEdit')}>{t('common_edit_label')}</span>
          </button>

          <button onClick={handleDelete} className={cx('buttonOption')}>
            <img src={icons.commonIconTrash} alt={t('common_img_text_alt')} width={16} height={16} />
            <span className={cx('labelDelete')}>{t('common_delete_label')}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default ThreeDotOptionsCaregiverReferral;
