// Libs
import classNames from 'classnames/bind';
import { Navigate, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
// Components, Layouts, Pages
// Others
import { adminRouteAbsolute } from '~/utils/constants/route';
import { AccountRoleCodesEnum, StorageEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './Auth.module.scss';
import { images } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const Auth = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [accessToken] = useState<string | null>(localStorage.getItem(StorageEnum.ACCESS_TOKEN)! || null);
  const [currentRole] = useState<string | null>(localStorage.getItem(StorageEnum.ROLE) || null);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleNavigateByRole = () => {
    switch (currentRole) {
      case AccountRoleCodesEnum.ADMIN:
        return <Navigate to={`${adminRouteAbsolute.home}`} />;

      case AccountRoleCodesEnum.EMPLOYEE:
        return <Navigate to={`${adminRouteAbsolute.home}`} />;

      default:
        localStorage.clear();
        break;
    }
  };
  //#endregion Handle Function

  return accessToken ? (
    handleNavigateByRole()
  ) : (
    <div id='authPage' className={cx('container')}>
      <section className={cx('logoSection')}>
        <div className={cx('authBackgroundImage')}></div>

        <img src={images.signInImageLogoFull} alt={t('common_img_text_alt')} className={cx('logo')} />
      </section>

      <section className={cx('formSection')}>
        <Outlet />
      </section>
    </div>
  );
};

export default Auth;
