// Libs
import classNames from 'classnames/bind';
import { Field, Label, Textarea } from '@headlessui/react';
import { ChangeEvent } from 'react';
// Components, Layouts, Pages
// Others
import { ASTERISK_SYMBOL } from '~/utils/constants/common';
// Styles, images, icons
import styles from './Textarea.module.scss';

type Props = {
  id: string;
  label?: string;
  width?: string | number;
  height?: string | number;
  name?: string;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  messageError?: string;
  required?: boolean;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
};

const cx = classNames.bind(styles);

const BaseTextarea = (props: Props) => {
  //#region Destructuring Props
  const {
    id,
    width = '100%',
    height = 108,
    label,
    name,
    value,
    placeholder,
    disabled,
    messageError,
    onChange,
    required,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangeInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(event);
  };
  //#endregion Handle Function

  return (
    <div id='baseTextarea'>
      <Field disabled={disabled} className={cx('baseTextareaContainer')} style={{ width }}>
        {label && (
          <Label className={cx('baseTextareaLabel')} htmlFor={id}>
            {label} {required && <span className={cx('required')}>{ASTERISK_SYMBOL}</span>}
          </Label>
        )}

        <div className={cx('baseTextareaContent')} style={{ height }}>
          <Textarea
            id={id}
            placeholder={placeholder}
            name={name}
            style={{ width }}
            value={value}
            disabled={disabled}
            className={cx('textarea')}
            onChange={handleChangeInput}
          />
        </div>

        {messageError && <p className={cx('baseTextareaError')}>{messageError}</p>}
      </Field>
    </div>
  );
};

export default BaseTextarea;
